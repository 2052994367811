<template>
  <v-dialog v-model="active" v-if="_bid" max-width="400" @click:outside="deleteBid = false">
    <div v-if="_companyType !== 2 && ['open', 'confirm'].includes(_bid.status)">
      <v-card>
        <v-container>
         
          <div class="header">
            <v-card-title class="pl-4 pr-0">
              Bid Review
              <v-spacer />
              <v-btn text icon @click="active = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title> <v-banner v-if="_bid.status === 'confirm'" class="confirm-bid-banner">
            <v-icon small color="#008cba">mdi-alert-circle-outline</v-icon>
            Bid pending vendor confirmation
          </v-banner>
            <v-card-subtitle class="caption">
              The bid amount below has been posted by an undisclosed vendor. You
              can either accept the offer by clicking the 'Award' button, or
              reject. Please keep in mind that neither action is reversable, and
              choosing to accept this offer will subsequently reject all other
              current offers.</v-card-subtitle>
            <div class="mt-4" justify="center" align="center">
              <v-row no-gutters>
                <v-card-subtitle class="company-name py-2" :class="_bid.bid_per_pound !== null ? 'green--text' : 'red--text'
                  ">
                  ${{
                    _bid.bid_per_pound !== null
                      ? _bid.bid_per_pound
                      : _bid.bid_buy_out
                  }}
                </v-card-subtitle>

                <v-spacer />
                <v-card-subtitle class="company-name py-2">
                  {{ _bid.bid_per_pound !== null ? `Price/lb` : `You Owe` }}
                </v-card-subtitle>
              </v-row>
              <v-divider />
              <v-card-subtitle class="company-name py-2"></v-card-subtitle> 
              <tc class="px-3" @accepted="accepted = !accepted" :disabled_chk="_bid.status == 'confirm'" />
              <v-row no-gutters class="mt-4" v-if="_bid.status !== 'confirm'">
                <v-btn rounded color="error" elevation="0" height="35" class="white--text" :loading="rejectLoading"
                  @click="handleReject">Reject</v-btn>
                <v-spacer />
                <v-btn rounded :disabled="!accepted" color="secondary" elevation="0" :loading="awardLoading" height="35"
                  class="white--text" @click="handleAward">Award</v-btn>
              </v-row>
            </div>
          </div>
        </v-container>
      </v-card>
    </div>
    <div v-else-if="_bid.status === 'open'">
      <v-card flat v-if="!deleteBid">
        <v-card-title>Bid Review</v-card-title>
        <v-card-subtitle v-if="_bid.company_id === _company.id">This bid was placed by you or someone else within your
          company/organization. You can not edit this bid at this time. You can,
          however, delete this bid and place another as long as the bid duration
          has not expired.
        </v-card-subtitle>
        <v-card-subtitle v-else>This bid was placed by another vendor in your area.</v-card-subtitle>
        <v-card-title class="headline mt-0 pt-0 text-center">
          {{ _bid.bid_buy_out ? `All In : ` : `Bid Per LB: ` }}
          <span class="primary--text ml-1">${{
            _bid.bid_per_pound ? _bid.bid_per_pound : _bid.bid_buy_out
          }}</span>
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" rounded :disabled="_bid.company_id !== _company.id" @click="deleteBid = true">Delete
            Bid</v-btn>
          <v-spacer />
          <v-btn color="primary" rounded @click="close()">Continue</v-btn>
        </v-card-actions>
      </v-card>
      <v-card flat v-else>
        <v-card-title>Are You Sure?</v-card-title>
        <v-card-text>Deleting this bid will be irreversable.</v-card-text>
        <v-card-actions>
          <v-btn color="primary" rounded @click="close()">CANCEL</v-btn>
          <v-spacer />
          <v-btn rounded color="error" @click="removeBid()">Delete Bid </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import tc from '@/components/app/Terms&Conditions.vue';
import config from '../../config';
export default {
  components: {
    tc
  },
  data() {
    return {
      accepted: false,
      deleteBid: false,
      bidPOC: undefined,
      awardLoading: false,
      rejectLoading: false
    };
  },
  computed: {
    ...mapGetters({
      _active: 'bids/getAdminBidReview',
      _bid: 'bids/getCurrentBid',
      _bids: 'bids/getBids',
      _bidCompany: 'bids/getCurrentBidCompany',
      _job: 'jobs/getCurrentJob',
      _company: 'company/getCompany',
      _companyType: 'company/getCompanyType',
      _loading: 'bids/getLoading'
    }),
    active: {
      get() {
        return this._active;
      },
      set(val) {
        this._activateBidReview(val);
      }
    }
    // _bidPOC() {
    //   return this._user ?
    //     this._user(this._bid.poc_id)
    //     : null;
    // }
  },
  watch: {
    _bid() {
      if (
        ![config.companyType.VENDOR, config.companyType.CUSTOMER].includes(
          this._companyType
        ) &&
        !this.bidPOC
      ) {
        this.bidPOC = this._user(this._job.poc_id);
      }
    },

    _job() {
      if (this._job.status === 'awarded') {
        this.active = false;
      }
    }
  },
  methods: {
    ...mapMutations({
      _activateBidReview: 'bids/toggleAdminReview',
      _setLoading: 'jobs/toggleLoading'
    }),
    ...mapActions({
      _updateBid: 'bids/updateBid',
      _updateJob: 'jobs/updateJob',
      _sendEmailAwardedJobInfo: 'jobs/sendEmailAwardedJobInfo',
      _removeBid: 'bids/deleteBid',
      _user: 'user/getById'
    }),
    removeBid() { 
      this._removeBid(this._bid.id).then(res => {
        this.close();
        setTimeout(() => {
          this.active = false;
        }, 200);
      });

      // this._removeBid(this._bid.id);
      // if (this._bids.length <= 1) {
      //   this._updateJob({
      //     id: this._bid.job_id,
      //     status: 'open',
      //     bid_status: 'none'
      //   }).then(() => {
      //     this.active = false;
      //   });
      // }
      // this.close();
    },
    close() {
      this.deleteBid = false;
      this.active = false;
    },
    handleReject() {
      // this._setLoading(true);
      this.rejectLoading = true;
      var options = {
        id: this._bid.id,
        status: 'rejected'
      };
      this._updateBid(options).then(() => {
        this.active = false;
        this.rejectLoading = false;
      });
    },
    async handleAward() {
      this.awardLoading = true;
      var options = {
        id: this._bid.id,
        status: 'awarded',
        vendor_attachments: this._bid.attachments || []
      };
      this._updateBid(options).then(() => {
        for (var i = 0; i < this._bids.length; i++) {
          if (this._bids[i].bid_id !== this._bid.id) {
            options = {
              id: this._bids[i].bid_id,
              status: 'rejected'
            };
            this._updateBid(options);
          }
        }
        this._updateJob({
          id: this._bid.job_id,
          status: 'awarded',
          bid_status: 'awarded'
        }).then(() => {
          this._sendEmailAwardedJobInfo({
            id: this._bid.job_id,
            vendor_id: this._bid.company_id
          });
          this.awardLoading = false;
          //console.log("close popup bid review");
          this.active = false;
          // this.close();
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.flex-container {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  width: 80%;
}

.location {
  grid-column: 1;
  grid-row: 1;
}

.number {
  grid-column: 2;
  grid-row: 1;
}

.duration {
  grid-column: 3;
  grid-row: 1;
}

.weight {
  grid-column: 1;
  grid-row: 2;
}

.pallets {
  grid-column: 1;
  grid-row: 3;
}

.recycling {
  grid-column: 2;
  grid-row: 2 / span 2;
}

.attachments {
  grid-column: 3;
  grid-row: 2 / span 2;
}

.access {
  grid-column: 1;
  grid-row: 4;
}

.lift {
  grid-column: 3;
  grid-row: 4;
}

.title-font {
  font-family: Roboto;
  font-size: 17px;
  color: #2e2e2e;
}

.subtitle-font {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #5d5d5d;
}

.vendor {
  grid-column: 1;
}

.address {
  grid-column: 2;
}

.phone {
  grid-column: 3;
}

.email {
  grid-column: 4;
}

.company-name {
  font-family: Roboto;
  font-size: 23px;
  color: #2e2e2e;
}

.center {
  align-self: center;
}

.text-center {
  text-align: center;
}

.link-color {
  color: #0091ff;
}

a {
  text-decoration: none;
}

.confirm-bid-banner {
  background-color: #d6e8f1 !important;
}

.confirm-bid-banner::v-deep .v-banner__wrapper {
  border: none !important;
}
</style>
