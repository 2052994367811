import api from '../api';

export function saveJob(job) {
  return api.post(`/user/job`, job);
}
export function sendEmailAwardedJob(job_id, data) {
  return api.post('/job/send-email-awarded-job-info/' + job_id, data);
}
export function unSaveJob(job) {
  return api.delete(`/user/job/${job}`);
}

export function deleteJob(job) {
  return api.delete(`/job/${job}`);
}

export function sendEmailAccountingWhenBillingUpdated() {
  return api.post('/job/send-email-accounting-billing-updated/');
}

export function upsertJobInterest(data) {
  return api.post(`/job/vendor/interest`, data);
}
export function createJobHistory(jobHistory) {
  return api.post('/job/createJobHistory', jobHistory);
}
