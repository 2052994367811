<template>
  <div style="height: 80vh">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" sm="12" md="12" justify="center" align="center">
          <v-card v-if="!forgotPassword" width="450" class="mx-0 pa-0" style="margin: 0 auto" id="moo">
            <v-card tile flat class="pa-5">
              <v-img width="250" class="mx-auto" contain :src="require('@/assets/rgx-black-lockup-1.png')" />
            </v-card>
            <v-divider />
            <v-card flat class="mx-0 pa-0">
              <v-card-title @click="openAccountStatusDialog()" class="">Sign In</v-card-title>
              <v-card-text>
                <v-form v-model="login">
                  <v-text-field
                    v-model="user.email"
                    prepend-icon="mdi-email"
                    type="email"
                    label="Email"
                    :rules="[rules.emailMatch, rules.required]"
                  />

                  <v-text-field
                    v-model="user.password"
                    :rules="[rules.length, rules.required, rules.specialChar]"
                    prepend-icon="mdi-lock"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    label="Password"
                    @click:append="showPass = !showPass"
                    @keyup.enter="auth()"
                  />
                </v-form>
                <v-row class="mt-1" no-gutters>
                  <!-- <v-btn
								:block="$vuetify.breakpoint.mdAndDown"
								rounded
								color="error"
								@click.native="cancelLogIn()"
								>cancel</v-btn
							>
							<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer> -->
                  <v-btn
                    :block="true"
                    rounded
                    :class="$vuetify.breakpoint.mdAndDown ? 'mt-2' : ''"
                    color="primary"
                    :disabled="!valid"
                    :loading="_loading"
                    @click.native="auth()"
                  >
                    Sign In
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider />

              <v-row no-gutters>
                <v-btn
                  id="create"
                  color="primary"
                  rounded
                  :block="$vuetify.breakpoint.mdAndDown"
                  text
                  @click="$emit(goTo('register/basic'))"
                >
                  Create New Account
                </v-btn>
                <v-spacer />
                <v-btn color="primary" @click="forgotPassword = !forgotPassword" :block="$vuetify.breakpoint.mdAndDown" text>
                  Forgot Password
                </v-btn>
              </v-row>
            </v-card>
          </v-card>
          <v-card v-if="forgotPassword" class="mx-0" style="margin: 0 auto" width="450" flat>
            <v-card tile flat color="" class="pa-5">
              <v-img width="250" contain class="mx-auto" :src="require('@/assets/rgx-black-lockup-1.png')" />
            </v-card>
            <v-divider />
            <v-card-title>Forgot Password?</v-card-title>
            <v-card-subtitle class="mt-2 text-left">
              Enter your email address below and if we find it in our system we will send you a message with a link to reset your password.
              <br />
              Thanks,
              <br />
              RGX Staff
            </v-card-subtitle>
            <v-card-text class="mt-4">
              <v-form v-model="forgotForm">
                <v-text-field
                  v-model="user.email"
                  type="email"
                  :rules="[rules.emailMatch, rules.required]"
                  @keyup.enter="forgotPasswordRequest()"
                  prepend-icon="mdi-email"
                  label="Email"
                />
                <recaptcha v-if="isShowRecaptcha" ref="recaptcha" @verify="submitCapt" @epx="epx"></recaptcha>
                <br />
                <!--vue-recaptcha
                    theme="light"
                    size="normal"
                    :tabindex="0"
                    @widgetId="recaptchaWidget = $event"
                    @verify="callbackVerify($event)"
                    @expired="callbackExpired()"
                    @fail="callbackFail()"
                  /-->
              </v-form>

              <!--v-img  :src="capt_url" width="180" />
              <v-form v-model="forgotForm">
                <v-text-field
                  v-model="capt"
                  type="text"
                  :rules="[ rules.required]"
                  @keyup.enter="forgotPasswordRequest()"
                  prepend-icon="mdi-human-child"
                  label="Enter the characters in the picture"
                />
              </v-form-->
              <v-row class="mt-1" :class="$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''" no-gutters>
                <v-btn :block="$vuetify.breakpoint.mdAndDown" rounded color="error" @click.native="forgotPassword = !forgotPassword">
                  Cancel
                </v-btn>
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn
                  :block="$vuetify.breakpoint.mdAndDown"
                  rounded
                  color="primary"
                  :disabled="!forgotForm || !captResponse"
                  :loading="_loading"
                  :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''"
                  @click.native="forgotPasswordRequest(), resetRecaptcha()"
                >
                  Request Password Reset
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <account-status-dialog />
      <confirm-popup-v2
        v-if="isShowPopupInfoInactiveUser"
        :isShow="isShowPopupInfoInactiveUser"
        :title="'Inactive Account'"
        :message="messagePopupInfoInactiveUser"
        :isHideButtonAction="true"
        :clickOutside="
          () => {
            isShowPopupInfoInactiveUser = false;
          }
        "
        :maxWidth="'420'"
        :isPersistent="false"
        :closePopup="
          () => {
            isShowPopupInfoInactiveUser = false;
          }
        "
      />
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Recaptcha from '@/components/app/Recaptcha.vue';
import AccountStatusDialog from '@/components/app/AccountStatusDialog.vue';
import ConfirmPopupV2 from '@/components/common/ConfirmPopupV2.vue';
export default {
  props: {
    open: Boolean
  },
  components: { Recaptcha, AccountStatusDialog, ConfirmPopupV2 },
  data() {
    return {
      captResponse: null,
      forgotForm: false,
      rules: {
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        required: v => !!v || 'Required.',
        confirmMatch: v => this.newUser.password === this.newUser.confirm_pass || 'The passwords you entered don’t match',
        length: v => (v && v.length >= 8) || 'Passwords must be at least 8 characters in length',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(v) || 'Passwords require at least one special character'
      },
      showPass: false,
      password: undefined,
      userName: undefined,
      forgotPassword: false,
      resetRequest: false,
      login: false,
      capt_url: '',
      capt: '',
      form: {
        // Some form values...
        recaptcha: ''
      },
      isShowPopupInfoInactiveUser: false,
      messagePopupInfoInactiveUser: `<div style="text-align:left;padding-left:10px;color: black">Your user account is inactive. You cannot log onto the RGX platform.</div> <div style="text-align:left;padding-left:10px;color: black">Please contact <a href='mailto:info@recyclegx.com' style="color: #007bff; text-decoration: underline;">info@recyclegx.com</a> with any questions.</div>`,
      isShowRecaptcha: true
    };
  },
  computed: {
    ...mapGetters({
      _user: 'authenticate/getUser',
      _capt: 'authenticate/getCapt',
      _loading: 'authenticate/getLoading'
    }),
    valid() {
      return this.login;
    },
    user: {
      get() {
        return this._user;
      },
      set(newValue) {
        this._setUser(newValue);
      }
    }
  },
  methods: {
    ...mapMutations({
      _setUser: 'authenticate/setUser',
      _setShowAccountStatusDialog: 'company/setShowAccountStatusDialog'
    }),
    ...mapActions({
      _forgotPassword: 'authenticate/forgotPassword',
      _auth: 'authenticate/authenticate',
      _getCaptcha: 'authenticate/getCaptcha'
    }),
    openAccountStatusDialog() {
      // console.log("_setShowAccountStatusDialog(true)");
      // this._setShowAccountStatusDialog( true);
      // this._t(true);
    },
    forgotPasswordRequest() {
      this._forgotPassword({ email: this.user.email, capt: this.captResponse })
        .then(res => {
          this.forgotPassword = false;
          this.resetRequest = true;
          this.captResponse = null;

          //this.$refs.recaptcha.reset();
        })
        .catch(err => {
          this.user.email = undefined;
          //this.$refs.recaptcha.reset();
        });
    },
    submitCapt(results) {
      this.captResponse = results;
      console.log('submitCapt', results);
    },
    epx() {
      this.captResponse = null;
      console.log('grecaptchareset');
    },
    cancelLogIn() {
      this.$emit('close');
    },
    auth() {
      this._auth(this.user).then(res => {
        if (res.status == 200 && res.data.isExistUser) {
          if (res.data?.input?.type_id == 4) {
            this.isShowPopupInfoInactiveUser = true;
          }
        } else {
          console.log('auth()'), (this.user = {});
        }
      });
    },
    goTo(page) {
      this.$router.push({ path: '/' + page });
      this.$emit('close');
    },
    close() {
      this.forgotPassword = false;
      this.$emit('close');
    },
    resetRecaptcha() {
      setTimeout(() => {
        this.isShowRecaptcha = false;
        setTimeout(() => {
          this.isShowRecaptcha = true;
        }, 1000);
      }, 3000);
    }
  },
  mounted() {
    this.forgotPassword = !!this.$route.query.recover;
    this.captResponse = null;
    // var c=this._getCaptcha();
    // console.log("_getCaptcha",c);
    // this.capt_url=this._capt;
    //     console.log("this._capt",this._capt);//this.$refs.recaptcha.execute()
    if (window.location.href.indexOf('?401') > 0) {
      //   this.company_status=undefined;
      //   this._showAccountStatusDialog=true;
      //
      this._setShowAccountStatusDialog(true);
    }
  }
};
</script>
