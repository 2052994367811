import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: "mdi", // default - only for display purposes
	},
	theme: {
		themes: {
			light: {
				primary: "#143E55",
				save: "#0091ff",
				secondary: "#4caf50",
				accent: "#8c9eff",
				error: "#e53935",
				navBackground: "#263238",
				mainBg: "#eeeeee",
			},
		},
	},
});
