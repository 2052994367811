import { get, getAll, create, update, remove } from '../../api';

// initial customer state
const state = {
  sites: undefined,
  sitesForDropdown: [],
  dialog: false,
  newSite: {},
  siteToEdit: {},
  siteToCreate: {},
  createdSite: {},
  editedSite: {},
  deletedSite: {},
  create: false,
  edit: false,
  delete: false,
  currentSite: {},
  error: false,
  loading: false,
  vendorSites: undefined
};

// getters
const getters = {
  edit: state => {
    return state.edit;
  },
  createdSite: state => {
    return state.createdSite;
  },
  deletedSite: state => {
    return state.deletedSite;
  },
  editedSite: state => {
    return state.editedSite;
  },
  siteDialog: state => {
    return state.dialog;
  },
  getSitesForDropdown: state => {
    return state.sitesForDropdown;
  },
  getSites: state => {
    return state.sites;
  },
  currentSite: state => {
    return state.currentSite;
  },
  loading: state => {
    return state.loading;
  },
  getVendorSites: state => {
    return state.vendorSites;
  }
};

const route = '/site';

// actions
const actions = {
  getById: async ({ commit }, payload) => {
    commit('toggleLoading', true);

    try {
      return get(route, payload)
        .then(res => {
          commit('setCurrentSite', res.data.data); // Need to check res object
        })
        .catch(err => {
          commit('setCurrentSite', undefined);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getAll: async ({ commit, rootState }, payload) => {
    try {
      // let token = await VueCookies.get("token");
      commit('toggleLoading', true);
      payload.company_id = rootState.company.company.id;
      const constructedRoute =
        route + '?options=' + encodeURIComponent(JSON.stringify(payload));
      return getAll(constructedRoute)
        .then(res => {
          commit('setSites', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', 'Load data failed', {
            root: true
          });

          commit('setSites', []);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      // commit("notification/setNotification", true, { root: true });
      // commit("notification/setNotificationMsg", err.response.data.message, {
      // 	root: true,
      // });
      // commit("notification/setNotificationType", "error", { root: true });
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getAllSitesForDropdown: async ({ commit, rootState }, payload) => {
    try {
      // let token = await VueCookies.get("token");
      commit('toggleLoading', true);
      payload.company_id = rootState.company.company.id;
      const constructedRoute =
        route + '?options=' + encodeURIComponent(JSON.stringify(payload));
      return await getAll(constructedRoute)
        .then(res => {
          commit('setSitesForDropdown', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', 'Load data failed', {
            root: true
          });

          commit('setSitesForDropdown', []);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getResults: async ({ commit, rootState }, payload) => {
    try {
      commit('setSites', []);
      commit('toggleLoading', true);
      const constructedRoute =
        route + '?options=' + encodeURIComponent(JSON.stringify(payload));
      return getAll(constructedRoute)
        .then(res => {
          commit('setSites', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Get data failed', {
            root: true
          });
          commit('setSites', []);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  createSite: async ({ commit, rootState, dispatch }, payload) => {
    commit('toggleLoading', true);
    try {
      payload.company_id = rootState.company.company.id;
      return create(route, payload)
        .then(res => {
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('setCreated', res.data.data);
          commit('toggleLoading', false);
          var siteOptions = {
            // itemsPerPage: 15,
            itemsPerPage: 99999,
            page: 1,
            search: undefined,
            sortBy: ['site_id'],
            sortDesc: [true]
          };
          dispatch('getAll', siteOptions);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Create failed', {
            root: true
          });
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  },
  deleteSite: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    try {
      return remove(route, payload)
        .then(res => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('setDeletedSite', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Delete failed', {
            root: true
          });
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  },
  updateSite: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    delete payload.total;
    delete payload.created_at;
    delete payload.updated_at;
    try {
      return update(route, payload)
        .then(res => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('setEditedSite', res.data.data);
          commit('toggleLoading', false);
          return res.data;
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Update failed', {
            root: true
          });
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  },
  toggleEdit: async ({ commit, dispatch }, payload) => {
    console.log('toggleEdit', payload);
    commit('toggleLoading', true);
    dispatch('getById', payload.id).then(async () => {
      commit('toggleEdit', payload.toggle);
      commit('toggleDialog', payload.toggle);
      commit('toggleLoading', false);
    });
    commit('toggleLoading', false);
  },
  toggleCreate: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    await commit('setCurrentSite', {});
    commit('toggleCreate', payload);
    commit('toggleDialog', payload);

    commit('toggleLoading', false);
  },
  getVendorSites: async ({ commit, rootState }, payload) => {
    try {
      commit('toggleLoading', true);
      const constructedRoute = `${route}/vendor?options=${encodeURIComponent(
        JSON.stringify(payload)
      )}`;
      return getAll(constructedRoute)
        .then(res => {
          commit('setVendorSites', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Get data failed', {
            root: true
          });
          commit('setVendorSites', []);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  }
};

// mutations
const mutations = {
  toggleDialog: (state, dialog) => {
    console.log('set toggleDialog', dialog);
    state.dialog = dialog;
  },
  toggleCreate: (state, create) => {
    state.create = create;
  },
  toggleEdit: (state, payload) => {
    console.log('set toggleEdit', payload);
    state.edit = payload;
  },
  setCreated: (state, site) => {
    state.createdSite = site;
  },
  setEditedSite: (state, site) => {
    state.editedSite = site;
  },
  setDeletedSite: (state, site) => {
    state.deletedSite = site;
    state.dialog = false;
  },
  setSitesForDropdown: (state, sitesForDropdown) => {
    state.sitesForDropdown = sitesForDropdown;
  },
  setSites: (state, sites) => {
    state.sites = sites;
  },
  setError: (state, error) => {
    state.error = error;
  },
  toggleLoading: (state, loading) => {
    state.loading = loading;
  },
  setCurrentSite: (state, currentSite) => {
    state.currentSite = currentSite;
  },
  setVendorSites: (state, sites) => {
    state.vendorSites = sites;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
