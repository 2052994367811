<template>
  <v-card class="mainBg" flat>
    <customer-dash v-if="_company && _company.type_id === 1" />
    <vendor-dash v-if="_company && _company.type_id === 2" />
    <admin-dash v-if="_company && _company.type_id === 3" />
  </v-card>
</template>
<script>
import VendorDash from "@/components/dashboard/VendorBase.vue";
import CustomerDash from "@/components/dashboard/CustomerBase.vue";
import AdminDash from "@/components/dashboard/AdminBase.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    VendorDash,
    CustomerDash,
    AdminDash,
  },
  computed: {
    ...mapGetters({
      _company: "company/getCompany",
    }),
  },
};
</script>
