<template>
  <div>
    <v-layout class="d-flex column" v-if="!_loading && _job && _compType">
      <v-row no-gutters justify="start" align="center">
        <v-col sm="6" md="6">
          <v-btn text small class="my-3" @click="handleClick">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-col>
        <v-col sm="6" md="6">
          <v-row class="d-flex flex-row-nowrap px-2 py-2 text-right justify-end" align="end" justify="end">
            <div class="d-flex flex-row-nowrap px-2" v-if="_compType === 2">
              <!-- <v-btn
                icon
                small
                text
                v-if="_job.saved === null"
                color="grey"
                class="mr-2"
                :loading="_loading"
                title="Add Favorite"
                dark
                @click="saveJob"
              >
                <v-icon>mdi-star-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                v-else
                color="yellow darker-1"
                class="mr-2"
                :loading="_loading"
                dark
                title="Remove Favorite"
                @click="unSaveJob()"
              >
                <v-icon>mdi-star</v-icon>
              </v-btn> -->
            </div>
            <div v-if="1 > 2" class="d-flex flex-row-nowrap px-2">
              <v-btn
                v-if="_job.status !== 'dispute'"
                small
                rounded
                text
                color="grey"
                class="mr-2"
                dark
                title="Dispute Job"
                @click="_setDisputeConfirm(true)"
              >
                <v-icon>mdi-flag-outline</v-icon>
              </v-btn>
              <v-btn
                v-else
                rounded
                text
                small
                color="error"
                class="mr-2"
                dark
                title="Dispute Job"
                @click="_compType === 1 ? _setDisputeConfirm(true) : ''"
              >
                <v-icon>mdi-flag</v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-row-nowrap px-2" v-if="_compType === 1">
              <!--
              <v-btn
                rounded
                small
                text
                :color="_job.status === 'closed' ? 'warning' : 'grey'"
                class="mr-2"
                dark
                v-if="_job.status === 'open' || _job.status === 'dispute'"
                title="Close Job"
                @click="_setCloseConfirm(true)"
                ><v-icon>mdi-cancel</v-icon></v-btn
              > -->
              <!-- Vu updated -->
              <!-- <v-btn
                rounded
                color="secondary"
                class="mr-2 elevation-0 mb-2"
                dark
                v-if="
                  (_job.status === 'open' || _job.status === 'draft') &&
                    _compType === 1 &&
                    _job.bid_status === 'none'
                "
                title="Edit Job"
                @click="editOpenJob"
              >
                <v-icon>mdi-pencil</v-icon>
                &nbsp;Edit Job
              </v-btn> -->

              <!-- <v-btn
                rounded
                color="error"
                class="mr-2 elevation-0 mb-2"
                dark
                v-if="
                  _job.status === 'open' ||
                    _job.status === 'dispute' ||
                    _job.status === 'draft'
                "
                title="Cancel Job"
                @click="_setCloseConfirm(true)"
              >
                <v-icon>mdi-cancel</v-icon>
                &nbsp;Cancel Job
              </v-btn> -->

              <v-btn
                rounded
                color="error"
                class="mr-2 elevation-0 mb-2"
                dark
                v-if="_job.status === 'open' || _job.status === 'dispute' || _job.status === 'draft'"
                title="Delete Job"
                @click="() => (this.isShowConfirmDeletePopup = true)"
              >
                <v-icon>mdi-cancel</v-icon>
                &nbsp;Delete Job
              </v-btn>
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />

      <!-- Huy updated title for RGX-158-->

      <v-card-title
        class="ma-2 pa-2"
        v-if="_job.status === 'rejected' && _job.bid_status === 'rejected' && _compType == 2"
        style="color:red"
      >
        Reopened job
      </v-card-title>
      <v-card
        v-if="_job.status === 'rejected' && _job.bid_status === 'rejected' && _compType == 2"
        height="calc(100% - 16px);"
        style="border-color:red"
        class="ma-2 pa-2"
        outlined
      >
        <v-row class="d-flex flex-wrap no-gutters justify-space-between">
          <v-col cols="12" sm="12" class="ma-0 ma-0 px-0 py-1">
            <v-card-text class="py-0" v-if="_bid">
              The customer has rejected this job’s closing details. The reason(s) provided are:
              <br />
              {{ _bid.rejected_reason }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>

      <job-closing v-if="!_loading && (_job.status === 'awarded' || _job.status === 'rejected') && _compType == 2" />
      <awarded-details
        v-if="
          (_job.status === 'awarded' ||
          _job.status === 'closing' ||
          _job.status === 'resale' ||
          _job.status === 'rejected' || //huy added
            _job.status === 'dispute') &&
            _compType == 1
        "
      />
      <awarded-details
        v-if="
          (_job.status === 'closing' ||
            _job.status === 'resale' ||
            // _job.status === 'rejected'||//huy added
            _job.status === 'dispute') &&
            _compType == 2
        "
      />
      <v-card v-if="(_job.status === 'open' || _job.status === 'draft') && _compType" class="" flat>
        <v-row>
          <v-col cols="12" sm="12" md="8" lg="8">
            <v-card-title>Open Job Details</v-card-title>
            <v-card-subtitle class="mx-0">
              This job is currently open for bidding. You can either place a bid below or save it for later...
            </v-card-subtitle>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" class="align-self-center pl-1 ml-0 pr-4" v-if="_compType != config.COMPANY_TYPE.CUSTOMER">
            <!-- <table style="width:100%"><tr>
              <td style="padding-right: 10px;padding-right: 10px; vertical-align: bottom;height: 58px" >
               
              
               </td>
            </tr></table> -->

            <div v-if="_compType != config.COMPANY_TYPE.CUSTOMER" style="text-align: right; padding-right: 10px;">
              <span style="color: #5d5d5d;font-size: 18px;font-weight: bold;">Bid End Date:</span>
              <span style="margin: 2px 4px;">{{ formatDate(_job.bid_due_date) }}</span>
            </div>
          </v-col>

          <!-- <v-col cols="12" sm="1" md="1" v-if="_compType == config.COMPANY_TYPE.CUSTOMER">
          </v-col> -->
          <v-col cols="12" sm="12" md="4" lg="4" class="align-self-center" v-if="_compType == config.COMPANY_TYPE.CUSTOMER">
            <v-row no-gutters>
              <v-col cols="6" sm="6" md="6" class="mr-0 pr-0 text-right">
                <v-card-subtitle class="t0 pt-5" style="padding: 0px;margin-right: 10px;">
                  Bid End Date
                </v-card-subtitle>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="ml-0 pl-0">
                <v-menu
                  ref="menu"
                  v-model="startMenu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="320px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="bid_due_date_field"
                      v-model="_job.bid_due_date"
                      :rules="[rules.required, rules.afterToday]"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="_job.bid_due_date"
                    no-title
                    :min="today"
                    @input="startMenu = false"
                    @change="onChangeBidEndDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="" :class="$vuetify.breakpoint.lgAndUp ? 'd-flex row no-gutters flex-nowrap' : 'd-flex column flex-wrap'">
          <v-col lg="8" xl="8" sm="12" md="12" class="pb-0 ma-0 order-0">
            <v-card v-if="_job" outlined height="calc(100% - 16px)" class="order-0 pa-2 ml-2">
              <v-row class="no-gutters">
                <v-col lg="9" xl="9" sm="9" md="9" class="ma-0 px-0 py-1">
                  <v-card-title class="pb-0" style="color:#4CAF50;font-weight:bold">
                    <span :class="checkDifferentHighlight('name')">RGX Job {{ _job.job_id }}: {{ _job.name || `no name` }}</span>
                  </v-card-title>
                </v-col>

                <v-col
                  lg="3"
                  xl="3"
                  sm="3"
                  md="3"
                  v-if="_compType == config.COMPANY_TYPE.CUSTOMER && _job.status == config.JOB_STATUS.OPEN"
                >
                  <v-btn rounded color="secondary" dark title="Edit Job Specs" @click="showEditCriticalJobSpecsPopup" class="mt-4">
                    <!-- <v-icon>mdi-pencil</v-icon> -->
                    &nbsp;Edit Job Specs
                  </v-btn>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Equipment Removal Deadline
                  </v-card-subtitle>
                  <v-card-text class="pb-0 py-0">
                    <span :class="checkDifferentHighlight('logistics')">
                      {{ getReviewValueByValue(config.LOGISTICS_OPTIONS, _job.logistics) }}
                    </span>
                    <!-- {{
                      getReviewValueByValue(
                        config.LOGISTICS_OPTIONS,
                        _job.logistics
                      )
                    }} -->
                  </v-card-text>
                  <v-card-text class="pb-0 py-0">
                    <span :class="checkDifferentHighlight('end_date')">
                      {{ `${formatDate(_job.end_date)}` }}
                    </span>

                    <span :class="checkDifferentHighlight('exact_removal_date')">
                      {{ `${getReviewValueByValue(config.EXACT_REMOVE_DATE_OPTIONS, _job.exact_removal_date)}` }}
                    </span>
                    <!-- {{
                      `${formatDate(_job.end_date)} ${getReviewValueByValue(
                        config.EXACT_REMOVE_DATE_OPTIONS,
                        _job.exact_removal_date
                      )}`
                    }} -->
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">Location</v-card-subtitle>
                  <!-- <v-card-text v-if="_job.status !== 'open'" class="pb-0">{{ `${_job.location}` }}</v-card-text> -->
                  <v-card-text v-if="_job.status === 'open'" class="pb-0">
                    <span :class="checkDifferentHighlight('site_id')">
                      {{ `${_job.location} ${_job.zip}` }}
                    </span>
                    <!-- {{ `${_job.location} ${_job.zip}` }} -->
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1" v-if="_compType !== config.COMPANY_TYPE.VENDOR">
                  <!-- <v-card-subtitle class="pb-0 t0">
                    Internal Job ID
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.number || 'N/A' }}
                  </v-card-text> -->
                </v-col>

                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Bid End Date
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.bid_due_date }}
                  </v-card-text>
                </v-col> -->

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Description
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <!-- {{ _job.description || "No Description" }} -->
                    <RenderTextArea
                      :textAreaData="_job.description || 'No Description'"
                      :styles="checkDifferentHighlight('description')"
                    ></RenderTextArea>
                  </v-card-text>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Vendor Personnel Restrictions
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <span :class="checkDifferentHighlight('vendor_restrictions')">
                      {{ _job.vendor_restrictions || 'No Restrictions' }}
                    </span>
                    <!-- {{ _job.vendor_restrictions || 'No Restrictions' }} -->
                  </v-card-text>
                </v-col>

                <v-col v-if="true" cols="12" sm="4" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Equipment
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <!--If field is null display “Est lbs. not available”-->

                    <span :class="checkDifferentHighlight('number_of_pallets')">
                      {{ _job.pallets ? 'Est ' + _job.pallets + ' pallets' : 'Est pallets not available' }}
                    </span>
                    <br />
                    <span :class="checkDifferentHighlight('est_weight')">
                      {{ _job.est_weight ? 'Est ' + _job.est_weight + ' lbs.' : 'Est lbs. not available' }}
                    </span>

                    <br />

                    <span style="text-transform: capitalize;" :class="checkDifferentHighlight('asset_type')">
                      Asset types: {{ getAssetTypeReview(_job.asset_type) }}
                    </span>
                  </v-card-text>
                </v-col>

                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Estimated Weight
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.est_weight || 'Not Available' }}
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Number of Pallets
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.pallets || 'Not Available' }}
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Asset Types
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ `${getAssetTypeReview(_job.asset_type)}` }}
                  </v-card-text>
                </v-col> -->

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Vehicle Access Restrictions
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <span :class="checkDifferentHighlight('vehicle_restrictions')">
                      {{ _job.vehicle_restrictions || 'No Restrictions' }}
                    </span>
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Lift Gate Required
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <span :class="checkDifferentHighlight('lift_gate')">
                      {{ _job.lift_gate === true ? 'Yes' : 'No' }}
                    </span>
                    <!-- {{ _job.lift_gate === true ? 'Yes' : 'No' }} -->
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1" v-if="_job.recycle_specs">
                  <v-card-subtitle class="pb-0 t0">
                    Recycling Specs
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <span :class="checkDifferentHighlight('rec_spec.resale')">
                      Equipment Available for Resale:
                      {{ _job.recycle_specs && _job.recycle_specs.resale === true ? 'Yes' : 'No' }}
                    </span>
                  </v-card-text>
                  <v-card-text v-if="_job.recycle_specs" class="py-0">
                    <span :class="checkDifferentHighlight('rec_spec.certified_recycle')">
                      Vendor Certification Required:
                      {{ _job.recycle_specs.certified_recycle === true ? 'Yes' : 'No' }}
                    </span>
                    <!-- Vendor Certification Required:
                    {{
                      _job.recycle_specs.certified_recycle === true
                        ? 'Yes'
                        : 'No'
                    }} -->
                  </v-card-text>
                  <v-card-text v-if="_job.commodity_report" class="py-0">
                    <span :class="checkDifferentHighlight('commodity_report')">
                      Commodity Report Required:
                      {{ _job.commodity_report ? 'Yes' : 'No' }}
                    </span>
                    <!-- Commodity Report Required:
                    {{ _job.commodity_report ? 'Yes' : 'No' }} -->
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    <span :class="checkDifferentHighlight('attachments')">
                      Attachments
                    </span>
                  </v-card-subtitle>
                  <!-- <div
                    v-for="(attachment, index) in _job.attachments"
                    :key="index"
                    class="px-2 pb-0"
                  >
                    <p class="attachment-indent mb-0 pl-4">
                      <a :href="attachment.location">
                        <v-icon v-if="attachment.location" class="small">
                          mdi-paperclip
                        </v-icon>
                      </a>
                      <a>
                        <span
                          v-on:click="showGoogleDocIndex(index)"
                          class="link-color"
                        >
                          {{ attachment.original_name }}
                        </span>
                      </a>
                      <v-btn icon v-on:click="showGoogleDocIndex(index)">
                        <v-icon color="secondary">mdi-file-find</v-icon>
                      </v-btn>
                    </p>
                  </div> -->
                  <v-card-text class="py-0 px-2">
                    <v-list-item-group>
                      <v-list-item v-for="(att, index) in _job.attachments" :key="index">
                        <v-list-item-icon>
                          <v-icon color="secondary" v-if="att.location">
                            mdi-paperclip
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-on:click="showGoogleDocIndex(index)">
                            {{ att.original_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ att.catagory.replace(/_/g, ' ') }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row no-gutters>
                            <v-btn icon :href="att.location" :download="att.original_name">
                              <v-icon color="secondary">mdi-download</v-icon>
                            </v-btn>
                            <v-btn icon v-on:click="showGoogleDocIndex(index)">
                              <v-icon color="secondary">mdi-file-find</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="false">
                        <v-list-item-icon>
                          <v-icon color="secondary">mdi-paperclip</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-on:click="exportToPDF()">RGX Pickup Receipt Job {{ _job.job_id }}</v-list-item-title>
                          <v-list-item-subtitle>
                            pickup receipt
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row no-gutters>
                            <v-btn icon v-on:click="exportToPDF()">
                              <v-icon color="secondary">mdi-download</v-icon>
                            </v-btn>
                            <v-btn icon v-on:click="exportToPDF()">
                              <v-icon color="secondary">mdi-file-find</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-card-text>
                </v-col>

                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">Attachments 22222</v-card-subtitle>
                  <div
                    v-for="(attachment, index) in _job.attachments"
                    :key="index"
                    class="px-2 pb-0"
                  >
                    <p class="attachment-indent mb-0 pl-4">
                      <a :href="attachment.location">
                        <v-icon v-if="attachment.location" class="small">
                          mdi-paperclip
                        </v-icon>
                      </a>
                      <a>
                        <span
                          v-on:click="showGoogleDocIndex(index)"
                          class="link-color"
                        >
                          {{ attachment.original_name }}
                        </span>
                      </a>
                      <v-btn icon v-on:click="showGoogleDocIndex(index)">
                        <v-icon color="secondary">mdi-file-find</v-icon>
                      </v-btn>
                    </p>
                  </div>
                </v-col> -->

                <!-- <v-col cols="12" sm="4" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="py-0">
                    Vendor Attachments sssssss 
                  </v-card-subtitle>
                  <v-card-text class="py-0">
                    <v-list-item-group>
                      <v-list-item
                        v-for="(att, index) in _job.attachments"
                        :key="index"
                      >
                        <v-list-item-icon>
                          <v-icon color="secondary" v-if="att.location">
                            mdi-paperclip
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-on:click="showGoogleDocIndex(index)"
                          >
                            {{ att.original_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ att.catagory.replace(/_/g, ' ') }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row no-gutters>
                            <v-btn
                              icon
                              :href="att.location"
                              :download="att.original_name"
                            >
                              <v-icon color="secondary">mdi-download</v-icon>
                            </v-btn>
                            <v-btn icon v-on:click="showGoogleDocIndex(index)">
                              <v-icon color="secondary">mdi-file-find</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="false">
                        <v-list-item-icon>
                          <v-icon color="secondary">mdi-paperclip</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-on:click="exportToPDF()">
                            RGX Pickup Receipt Job {{ _job.job_id }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            pickup receipt
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row no-gutters>
                            <v-btn icon v-on:click="exportToPDF()">
                              <v-icon color="secondary">mdi-download</v-icon>
                            </v-btn>
                            <v-btn icon v-on:click="exportToPDF()">
                              <v-icon color="secondary">mdi-file-find</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-card-text>
                </v-col> -->

                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t0">
                    Equipment
                  </v-card-subtitle>
                  <v-card-text class="py-0">
                    {{
                      _job.est_weight
                        ? 'Est ' + _job.est_weight + ' lbs.'
                        : 'Est lbs. not available'
                    }}
                  </v-card-text>
                  <v-card-text class="py-0">
                    {{
                      _job.pallets
                        ? 'Est ' + _job.pallets + ' pallets'
                        : 'Est pallets not available'
                    }}
                  </v-card-text>
                  <v-card-text class="py-0">
                    {{ `Asset types: ${getAssetTypeReview(_job.asset_type)}` }}
                  </v-card-text>
                </v-col> -->

                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1"> 
                  <v-card-subtitle class="pb-0 t0">Equipment</v-card-subtitle>
                  <v-card-text class="py-0">
                    {{ _job.est_weight ? 'Est ' + _job.est_weight + ' lbs.' : 'Est lbs. not available' }}
                  </v-card-text> 
                  <v-card-text class="py-0">
                    {{ _job.pallets ? 'Est ' + _job.pallets + ' pallets' : 'Est pallets not available' }}
                  </v-card-text> 
                  
                </v-col> -->
              </v-row>
            </v-card>
          </v-col>

          <v-col
            lg="4"
            xl="4"
            sm="12"
            md="12"
            class="pb-0 ma-0 order-0"
            v-if="_job && _compType == config.COMPANY_TYPE.CUSTOMER && _job.status == config.JOB_STATUS.OPEN"
          >
            <v-card outlined height="calc(100% - 16px)" class="order-0 pa-2 mr-2" style="margin-left: 8px;">
              <v-row class="no-gutters pb-4">
                <v-col lg="7" xl="7" sm="12" md="7">
                  <v-card-subtitle class="pb-0 t0 mb-0" style="text-align:center">
                    Internal Job Details
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-0 text-center mt-0 pt-0">
                    Not visible to vendors bidding on the job
                  </v-card-subtitle>
                </v-col>
                <v-col lg="5" xl="5" sm="12" md="5" class="ml-0 pl-2">
                  <v-btn rounded color="secondary" dark title="Edit Internal" @click="showEditJobInternalDetailPopup" class="mt-4">
                    <!-- <v-icon>mdi-pencil</v-icon> -->
                    &nbsp;Edit Internal
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12" class="pb-4">
                  <v-card-subtitle class="subtitle-1 ml-0">Job submitted by: {{ _job.created_email }}</v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">Internal Job ID: {{ _job.number || 'N/A' }}</v-card-subtitle>
                </v-col>

                <v-col cols="12" class="ma-0 px-0 py-1 pb-4">
                  <v-card-subtitle class="pb-2 t0">
                    Project Manager Contact
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.project_manager_fname }}
                    {{ _job.project_manager_lname }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.project_manager_email }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.project_manager_phone }}
                  </v-card-subtitle>
                </v-col>

                <v-col cols="12" class="pb-4">
                  <v-card-subtitle class="pb-2 t0 ">
                    Logistics Contact
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.poc_fname }}
                    {{ _job.poc_lname }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.poc_email }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.poc_phone }}
                  </v-card-subtitle>
                </v-col>

                <v-col cols="12" class="pb-6" v-if="_job.vendorsAssignedInfo && _job.vendorsAssignedInfo.length > 0">
                  <v-card-subtitle class="pb-2 t0">
                    Vendor Identified
                  </v-card-subtitle>

                  <v-card-subtitle class="subtitle-1 ml-0" v-for="vendor in _job.vendorsAssignedInfo" :key="vendor.id">
                    {{ vendor.id }} - {{ vendor.name }}
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col
            v-if="_compType === config.COMPANY_TYPE.VENDOR"
            lg="4"
            xl="4"
            sm="12"
            md="12"
            class="pb-0 pa-0 order-0 pr-4"
            style="margin-left: 8px;margin-bottom: 15px;"
          >
            <div style="background-color: #f5e9d0;" v-if="isShowWarningBidConfirm && _showVendorReviewBox">
              <v-banner class="warning-confirm-bid-banner">
                <v-icon color="warning">mdi-alert-outline</v-icon>
                <span style="font-size: 1.1rem;">
                  &nbsp;&nbsp;Bid inactive pending confirmation.
                </span>
              </v-banner>
            </div>
            <skeleton-detail v-show="_showVendorReviewBox === null" />

            <bidding-box
              v-show="!_showVendorReviewBox"
              :bidJob="bidJob"
              @bidJob="bidJob = !bidJob"
              @show-vendor-review-box="_setShowVendorBidReviewBox(true)"
            />
            <vendor-bid-review-box v-show="_showVendorReviewBox" @show-vendor-review-box="_setShowVendorBidReviewBox(false)" />
          </v-col>
        </div>
        <v-card v-show="_compType != config.COMPANY_TYPE.VENDOR" v-if="_job.status !== 'draft'" class="ma-2 mb-4 pa-2" outlined>
          <bid-table />
        </v-card>
      </v-card>
    </v-layout>
    <div v-else-if="_loading" class="ma-5">
      <skeleton-detail />
    </div>
    <dispute-confirm />
    <close-confirm />
    <bid-confirm />
    <bid-review />
    <vendor-bid-review />
    <customer-bid-review />
    <job-closing-conf />
    <edit-job-form :statusJob="'open'" />
    <job-no-longer-open-dialog />
    <job-bid-no-longer-open-dialog />
    <google-doc />
    <!-- Only open and draft job can edit, in here statusJob always is open -->
    <confirm-popup
      :isShow="isShowConfirmDeletePopup"
      :title="'DELETE PERMANENTLY'"
      :message="'This job will be permanently deleted.<br/>This <strong>cannot</strong> be undone.'"
      :titleButtonConfirm="'Delete'"
      :color="'error'"
      :confirm="confirmDeleteJob"
      :clickOutside="
        () => {
          this.isShowConfirmDeletePopup = false;
        }
      "
      :closePopup="
        () => {
          this.isShowConfirmDeletePopup = false;
        }
      "
    />
    <edit-job-internal-details-form
      v-if="showDialogEditInternalDetails"
      :job="internalJobEditInfo"
      :isVisible="showDialogEditInternalDetails"
      @close="showDialogEditInternalDetails = false"
    />

    <edit-critical-job-specs-form
      v-if="showDialogEditCriticalJobSpecsPopup"
      :_specsJob="criticalJobSpecsEditInfo"
      :isVisible="showDialogEditCriticalJobSpecsPopup"
      @close="showDialogEditCriticalJobSpecsPopup = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import SkeletonDetail from '@/components/skeleton/Detail.vue';
import BidTable from '@/components/bids/Table.vue';
import JobClosingConf from '@/components/jobs/JobClosingConf.vue';
import BidReview from '@/components/bids/Review.vue';
import CustomerBidReview from '@/components/bids/CustomerReview.vue';
import VendorBidReview from '@/components/bids/VendorReview.vue';
import VendorBidReviewBox from '@/components/bids/VendorReviewBox.vue';
import BiddingBox from '@/components/bids/BiddingBox.vue';
import JobClosing from '@/components/jobs/JobClosing.vue';
import BidConfirm from '@/components/bids/BidConfirm.vue';
import DisputeConfirm from '@/components/jobs/DisputeConfirm.vue';
import CloseConfirm from '@/components/jobs/CloseConfirm.vue';
import AwardedDetails from '@/components/jobs/AwardedDetails.vue';
import EditJobForm from '@/components/jobs/EditJobForm.vue'; // Vu added
import JobNoLongerOpenDialog from '@/components/jobs/JobNoLongerOpenDialog.vue'; //huy added
import JobBidNoLongerOpenDialog from '@/components/jobs/JobBidNoLongerOpenDialog.vue'; //huy added
import GoogleDoc from '@/components/app/GoogleDoc.vue'; //huy added
import config from '@/config';
import * as Helpers from '../helpers/helpers';
import RenderTextArea from '@/components/common/RenderTextArea.vue';
import ConfirmPopup from '@/components/common/ConfirmPopup.vue';
import EditJobInternalDetailsForm from '@/components/jobs/EditJobInternalDetailsForm.vue';
import EditCriticalJobSpecsForm from '../components/jobs/EditCriticalJobSpecsForm.vue';
import _ from 'lodash';
export default {
  components: {
    JobClosingConf,
    BiddingBox,
    BidTable,
    BidReview,
    CustomerBidReview,
    VendorBidReview,
    JobClosing,
    BidConfirm,
    DisputeConfirm,
    CloseConfirm,
    SkeletonDetail,
    AwardedDetails,
    EditJobForm,
    VendorBidReviewBox,
    GoogleDoc,
    JobNoLongerOpenDialog,
    JobBidNoLongerOpenDialog,
    RenderTextArea,
    ConfirmPopup,
    EditJobInternalDetailsForm,
    EditCriticalJobSpecsForm
  },
  data() {
    return {
      rules: {
        afterToday: v => moment(v) >= moment() || "Must be later than today's date",
        required: v => !!v || 'Required.'
      },
      bidJob: false,
      init: false,
      config: config,
      isShowConfirmDeletePopup: false,
      showDialogEditInternalDetails: false,
      showDialogEditCriticalJobSpecsPopup: false,
      internalJobEditInfo: {},
      criticalJobSpecsEditInfo: {},
      today: Helpers.formatDate(new Date(), config.dateFormatTypes.isoDate),
      jobHistory: null,
      jobHistoryFieldChanged: [],
      isShowWarningBidConfirm: false
    };
  },
  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      _compType: 'company/getCompanyType',
      _compId: 'company/getCompanyId',
      _user: 'user/getUser',
      _type: 'jobs/getStatus',
      _loading: 'jobs/loading',
      // _bid: "bids/bid",
      _bid: 'bids/getCurrentBidInfo',
      _bidLoading: 'bids/getLoading',
      _newBid: 'bids/getNewBid',
      _bidConfirm: 'bids/confirm',
      _showVendorReviewBox: 'bids/getVendorBidReviewBox',
      _showJobNoLongerOpenDialog: 'jobs/getShowJobNoLongerOpenDialog',
      _showJobBidNoLongerOpenDialog: 'bids/getShowJobBidNoLongerOpenDialog',
      _currentBid: 'bids/getCurrentBid'
    })
  },
  mounted() {
    if (!this.init && this._user) {
      this._setJob(this.$route.params.id).then(rs => (this.init = true));
      this._setShowVendorBidReviewBox(null);
    }
  },
  watch: {
    _user() {
      if (!this.init) {
        this._setJob(this.$route.params.id).then(rs => (this.init = true));
      }
    },
    _job(newVal) {
      if (newVal && newVal.status === config.JOB_STATUS.OPEN && this._compType === config.COMPANY_TYPE.VENDOR) {
        for (let data of newVal.history_info) {
          this.jobHistory = {
            ...this.jobHistory,
            ...data.history
          };
        }
        this.jobHistoryFieldChanged = this.jobHistory != null ? Object.keys(this.jobHistory) : [];
        // console.log('11122');
        // console.log('11122', Object.keys(this.jobHistory));
      }
    },
    _currentBid(newVal) {
      if (_.isNil(newVal)) {
        this.isShowWarningBidConfirm = false;
      } else {
        this.isShowWarningBidConfirm =
          this._compType === config.COMPANY_TYPE.VENDOR &&
          this._job.status === config.JOB_STATUS.OPEN &&
          newVal.status === config.BID_STATUS.CONFIRM;
      }
    }
  },
  methods: {
    ...Helpers,
    handleClick() {
      let options = JSON.parse(localStorage.getItem('options/Jobs'));
      const params = {};
      if (options.page) {
        params['pageSize'] = options.page;
      }

      if (options.itemsPerPage) {
        params['pageItems'] = options.itemsPerPage;
      }

      options.sortBy.forEach((item, index) => {
        params[item] = options.sortDesc[index];
      });
      // if (window.history.length > 2) {
      //   this.$router.go(-1);
      // } else {
      //   this.$router.push('/job');
      // }
      //window.location.href="/job";
      this.$router.push({ name: 'Jobs', query: params }).catch(() => {});
    },
    ...mapActions({
      _setJob: 'jobs/setCurrent',
      _saveJob: 'jobs/saveJob',
      _unSaveJob: 'jobs/unSaveJob',
      _updateJob: 'jobs/updateJob',
      _toggleLoading: 'jobs/toggleLoading',
      _deleteJob: 'jobs/deleteJob',
      _updateJob_v2: 'jobs/updateJob_v2'
    }),
    ...mapMutations({
      _setDisputeConfirm: 'jobs/setDisputeConfirm',
      _setCloseConfirm: 'jobs/setCloseConfirm',
      _setEditNewJob: 'jobs/setEditNewJob', // Vu added
      _setShowVendorBidReviewBox: 'bids/setVendorBidReviewBox',
      _setGoogleDoc: 'googledoc/setShowGoogleDocDialog',
      _setIndex: 'googledoc/setIndex',
      _setList: 'googledoc/setList'
    }),
    showGoogleDocIndex(index) {
      this._setList(this._job.attachments);
      this._setIndex(index);
      this._setGoogleDoc(true);
    },
    saveJob() {
      this._saveJob({ job_id: this._job.job_id, user_id: this._user.id }).then(() => {
        this._setJob(this.$route.params.id);
      });
    },
    unSaveJob() {
      this._unSaveJob(this._job.saved).then(() => {
        this._setJob(this.$route.params.id);
      });
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    // Vu added
    editOpenJob() {
      this._setEditNewJob(true);
    },
    confirmDeleteJob() {
      this._deleteJob(this._job.job_id).then(() => {
        this.isShowConfirmDeletePopup = false;
        setTimeout(() => {
          this.$router.replace('/job');
        }, 500);
      });
    },
    onChangeBidEndDate: _.debounce(async function() {
      if (this.$refs.bid_due_date_field.validate()) {
        let data = {
          id: this._job.job_id,
          bid_due_date: this._job.bid_due_date
        };
        let requestData = {
          payload: data,
          disableLoading: true
        };
        await this._updateJob_v2(requestData);
      }
    }, 100),
    showEditJobInternalDetailPopup() {
      this.internalJobEditInfo = {
        id: this._job.job_id,
        number: this._job.number,
        project_manager_id: this._job.project_manager_id,
        poc_id: this._job.poc_id,
        checked_vendor: this._job.vendor_codes && this._job.vendor_codes.length > 0,
        vendorsAssignedInfo: this._job.vendorsAssignedInfo,
        vendor_codes: this._job.vendor_codes,
        required_cert: this._job.recycle_specs.certified_recycle
      };
      this.showDialogEditInternalDetails = true;
    },
    showEditCriticalJobSpecsPopup() {
      this.criticalJobSpecsEditInfo = _.cloneDeep(this._job);
      if (!this.criticalJobSpecsEditInfo.site_id && !!this._job.site_id_bk) {
        this.criticalJobSpecsEditInfo.site_id = this._job.site_id_bk;
      }
      this.showDialogEditCriticalJobSpecsPopup = true;
    },
    checkDifferentHighlight(fieldName) {
      if (this._compType === config.COMPANY_TYPE.VENDOR && this._job.status === config.JOB_STATUS.OPEN) {
        if (this.jobHistoryFieldChanged.includes(fieldName)) {
          return 'highlight-different';
        }
      }
      return '';
    }
    // showHideVendorReviewBox(isShow) {
    //   this._setShowVendorBidReviewBox(isShow);
    // }
  }
};
</script>
<style scoped>
.attachment-indent {
  text-indent: -17px;
}

.highlight-different {
  background-color: #c7e0ff;
}

/* // .attachment-indent:first-line {
//     text-indent: 0;
// } */

.bidTotal {
  font-size: 26px;
}

a {
  text-decoration: none;
}

.link-color {
  color: #0091ff;
}

@media only screen and (max-width: 960px) {
}

div.v-card__subtitle.t0 {
  font-size: 18px;
  font-weight: bold;
}

.subtitle-1 {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
  line-height: 1.5rem;
}

.v-banner {
  background-color: #f5e9d0;
  /* Adjust the background color */
  color: #6e6e6e;
  /* Adjust the text color */
}

.v-banner .v-icon {
  color: #f5e9d0;
  /* Adjust the icon color */
}

.warning-confirm-bid-banner::v-deep .v-banner__wrapper {
  border: none !important;
}
</style>
