import api from "../api";

/** Create
 * @param { String: "/route" } route
 * @param { Object } data
 */
export function create(route, data) {
	return api.post(route, { ...data });
}

/** Get All
 * @param { String: "/route" } route
 */
export function getAll(route) {
	return api.get(route);
}

/** Get
 * @param { String: "/route" } route
 * @param { Number: 1 } IDBCursor
 */
export function get(route, id, user) {
	return api.get(`${route}/${id}${user || ""}`);
}

/** Update
 * @param { String: "/route" } route
 * @param { Object } data
 */
export function update(route, data) {
	return api.put(`${route}/${data.id}`, {
		...data,
	});
}

/** Delete
 * @param { String: "/route" } route
 * @param { Number: 1 } id
 */
export function remove(route, id) {
	return api.delete(`${route}/${id}`);
}

/** Upload
 * @param { String: "/route" } route
 * @param { File }
 */
export function upload(route, name, file) {
	return api.post(route, { file, name });
}

/** PDF Generator
 * @param { String: "/route" } route
 * @param { Job }
 */
export function pdfGenerate(route, job) {
	return api.post(route, job);
}
