<template>
	<sign-in />
</template>
<script>
import SignIn from "@/components/app/Login.vue";
export default {
	components: {
		SignIn,
	},
};
</script>
