<template>
  <div id="viewer" class="d-flex flex-column position-relative">
    <div
      id="content"
      class="d-flex flex-column overflow-auto"
      :style="!interactable ? 'padding-bottom: 0px' : ''"
    ></div>
    <div
      v-if="loaded && !reachedBottom && interactable"
      class="position-absolute d-flex align-center justify-center sticky-bottom instruction-1"
    >
      You must scroll to the bottom of the document before accepting.
    </div>
    <div
      v-if="loaded && reachedBottom && interactable"
      class="position-absolute d-flex align-center justify-center sticky-bottom instruction-2"
    >
      <div class="pl-4 pr-2">
        By clicking "Agree", you are indicating that you understand and agree to
        <span style="border-bottom: 1px dotted white; padding-bottom: 2px;"
          >Terms & Conditions</span
        >
        .
      </div>
      <v-btn
        x-large
        tile
        color="secondary"
        elevation="0"
        class="ml-auto"
        height="100%"
        @click="onAgree"
      >
        AGREE
      </v-btn>
    </div>
  </div>
</template>

<script>
import pdfjsLib from 'pdfjs-dist/build/pdf';
import vuetify from '../../plugins/vuetify';

export default {
  props: {
    docPath: String,
    interactable: Boolean
  },
  data() {
    return {
      reachedBottom: false,
      doc: null,
      loaded: false
    };
  },
  computed: {
    breakpoint() {
      return vuetify.framework.breakpoint;
    },
    scale() {
      const { width, mdAndUp, smAndUp } = this.breakpoint;
      if (mdAndUp) return 1.2;
      if (smAndUp) return 0.8;
      if (width > 470) return 0.6;
      return 0.5;
    }
  },
  created() {
    pdfjsLib.getDocument(this.docPath).promise.then(res => {
      this.doc = res;
      this.render();
    });
  },
  watch: {
    reachedBottom: {
      handler() {
        this.$emit('scrollToBottom');
      },
      once: true
    }
  },

  methods: {
    initContainer() {
      const wrapper = document.getElementById('content');
      for (const child of wrapper.children) {
        if (child.tagName !== 'CANVAS') {
          continue;
        }
        wrapper.removeChild(child);
      }
      return wrapper;
    },
    render() {
      const container = this.initContainer();
      this.loaded = false;
      const doc = this.doc;
      const scale = this.scale;
      const onload = () => (this.loaded = true);
      for (let i = 1; i <= doc.numPages; i++) {
        doc.getPage(i).then(function(page) {
          const viewport = page.getViewport({ scale });

          var canvasId = 'pdf-viewer-' + i;
          $('#content').append(
            $('<canvas/>', { id: canvasId, style: 'padding: 15px' })
          );
          const canvas = document.getElementById(canvasId);
          const ctx = canvas.getContext('2d');

          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: ctx,
            viewport
          };
          page.render(renderContext);
          if (i === doc.numPages) {
            onload();
          }
        });
      }

      container.addEventListener('scroll', e => this.trackUserScroll(e.target));
    },
    trackUserScroll(container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const isBottom = scrollHeight - scrollTop - clientHeight < 1;
      if (isBottom) {
        this.reachedBottom = true;
      }
    },
    onAgree(e) {
      e.target.disabled = true;
      this.$emit('agree');
    }
  }
};
</script>

<style scoped>
#viewer {
  /* overflow-y: scroll; */
  /* height: calc(100vh / 2); */
  background-color: rgba(0, 0, 0, 0.5);
}

#content {
  height: calc(100vh / 2);
  overflow: auto;
  padding-bottom: 45px;
}

.sticky-bottom {
  background-color: #43566a;
  height: 50px;
  color: white;
  bottom: 10px;
  width: fill-available;
  margin: 0 15px;
}

.instruction-1 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.instruction-2 {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
}
</style>
