<template>
  <div>
    <!-- <div v-if="!loading && user" class="mx-0"> -->
    <div v-if="true" class="mx-0">
      <v-card-title class="ma-0 pa-0" v-if="$vuetify.breakpoint.smAndDown">
        Edit Job Review
      </v-card-title>
      <div class="flex-container">
        <v-card-subtitle class="primary--text no-padding card-subtitle-review">
          Job Overview
        </v-card-subtitle>
        <v-spacer />
        <v-btn v-if="!isAdmin" text x-small color="primary" @click="
          $emit('step', 1);
        $emit('startValidate');
        ">
          <v-icon size="15">mdi-pencil</v-icon>
          Edit
        </v-btn>
      </div>

      <v-card-subtitle class="no-padding pa-0 under-line">
        <span :class="{ 'error-title': !_job.name }">Job Name*</span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ _job.name ? _job.name : '' }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Internal Job ID
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ _job.number ? _job.number : 'none' }}
      </v-card-text>

      <!-- <v-card-subtitle class="no-padding pt-2 pb-0">Description</v-card-subtitle>
      <v-card-text class="no-padding pa-0">{{
        _job.description ? _job.description : "none"
      }}</v-card-text> -->

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Description
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        <!-- {{_job.description ? _job.description : "none"}} -->
        <RenderTextArea :textAreaData="_job.description || 'none'"></RenderTextArea>
      </v-card-text>

      <!-- <div class="flex-container top-padding">
        <v-card-subtitle class="primary--text no-padding">Job Readiness</v-card-subtitle>
        <v-spacer />
        <v-btn v-if="!isAdmin" text x-small color="primary" @click="$emit('step', 1)">
          <v-icon size="15">mdi-pencil</v-icon>Edit
        </v-btn>
      </div> -->

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !_job.logistics }">Logistics*</span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ getReviewValueByValue(config.LOGISTICS_OPTIONS, _job.logistics) }}
      </v-card-text>

      <!-- <v-card-subtitle class="no-padding pt-2 pb-0">Vendor Restrictions</v-card-subtitle>
      <v-card-text class="no-padding pa-0">{{
        _job.vendor_restrictions
      }}</v-card-text> -->

      <!-- <v-card-subtitle class="no-padding pt-2 pb-0">Bid Due Date</v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        <v-icon>mdi-calendar-blank</v-icon>
        {{ _job.bid_due_date && formatDate(_job.bid_due_date) }}
      </v-card-text> -->

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !bidDueDateIsValid }">
          Bid Due Date*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        <v-icon>mdi-calendar-blank</v-icon>
        {{ _job.bid_due_date && formatDate(_job.bid_due_date) }}
      </v-card-text>

      <!-- <v-card-subtitle class="no-padding pt-2 pb-0">Equipment Removal Date</v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        <v-icon>mdi-calendar-blank</v-icon>
        {{ _job.end_date && formatDate(_job.end_date) }}
      </v-card-text> -->

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <!-- <span
          :class="{ 'error-title': !_job.end_date || !_job.exact_removal_date }"
        >
          Equipment Removal Date*
        </span> -->
        <span :class="{ 'error-title': !equipmentRemovalDateIsValid }">
          Equipment Removal Date*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        <span>
          {{
            getReviewValueByValue(
              config.EXACT_REMOVE_DATE_OPTIONS,
              _job.exact_removal_date
            )
          }}
        </span>
      </v-card-text>
      <v-card-text class="no-padding pa-0">
        <v-icon>mdi-calendar-blank</v-icon>
        {{ _job.end_date && formatDate(_job.end_date) }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !_job.project_manager_id }">
          Project Manager Email*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{
          this.isAdmin
            ? _job.project_manager_email
            : (getUserById(this._users, _job.project_manager_id) || {}).email
        }}
      </v-card-text>

      <div class="flex-container top-padding">
        <v-card-subtitle class="primary--text no-padding card-subtitle-review">
          Equipment
        </v-card-subtitle>
        <v-spacer />
        <v-btn v-if="!isAdmin" text x-small color="primary" @click="
          $emit('step', 2);
        $emit('startValidate');
        ">
          <v-icon size="15">mdi-pencil</v-icon>
          Edit
        </v-btn>
      </div>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Resale?*
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{
          _job.recycle_specs && _job.recycle_specs.resale === true
            ? 'Yes'
            : 'No'
        }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Certified Recycling Required?*
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{
          _job.recycle_specs && _job.recycle_specs.certified_recycle === true
            ? 'Yes'
            : 'No'
        }}
      </v-card-text>

      <v-card-subtitle class="no-padding pa-0 pt-2 under-line">
        Number of Pallets
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ _job.number_of_pallets }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Estimated Weight (lbs.)
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">{{ _job.est_weight }}</v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !checkAssetTypeRequired(_job.asset_type) }">
          Asset Type*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ getAssetTypeReview(_job.asset_type) }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0  under-line">
        <span :class="{
          'error-title':
            !_job.attachments ||
            getFilesByCategory(_job.attachments, config.KEY.Equipment_List)
              .length == 0
        }">
          Uploaded Documents*
        </span>
      </v-card-subtitle>
      <v-card max-height="200" style="overflow-x: auto" v-if="_job.attachments" flat>
        <v-list-item v-for="(attachment, index) in _job.attachments" :key="index"
          v-show="attachment.catagory !== 'commodity_report_template'">
          <v-list-item-icon>
            <v-icon color="secondary">mdi-paperclip</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ attachment.original_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ attachment.catagory.replace(/_/g, ' ')
              }}{{
                attachment.catagory == config.KEY.Equipment_List ? '*' : ''
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row no-gutters>
              <v-btn icon :href="attachment.location" :download="attachment.original_name">
                <v-icon color="secondary">mdi-download</v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-card>

      <div class="flex-container top-padding">
        <v-card-subtitle class="primary--text no-padding card-subtitle-review">
          Location
        </v-card-subtitle>
        <v-spacer />
        <v-btn v-if="!isAdmin" text x-small color="primary" @click="
          $emit('step', 3);
        $emit('startValidate');
        ">
          <v-icon size="15">mdi-pencil</v-icon>
          Edit
        </v-btn>
      </div>

      <!-- <v-card-subtitle class="no-padding pa-0">Site Name</v-card-subtitle>
      <v-card-text class="no-padding pa-0">{{ _job.site_name }}</v-card-text> -->

      <v-card-subtitle class="no-padding pa-0 under-line">
        <span :class="{ 'error-title': !_job.site_name }">Site Name*</span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ this._job.site_name }}
        <!-- {{ (getSiteById(this._sites, _job.site_id) || {}).name }} -->
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !_job.poc_id }">
          Logistics POC Email*
        </span>
      </v-card-subtitle>

      <v-card-text class="no-padding pa-0">
        {{
          this.isAdmin
            ? _job.poc_email
            : (getUserById(this._users, _job.poc_id) || {}).email
        }}
        <!-- {{ _job.poc_email ? _job.poc_email : undefined }} -->
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Lift Gate Required?
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ _job.lift_gate ? 'Yes' : 'No' }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pa-0 under-line">
        Vehicle Asset Restrictions
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ _job.vehicle_restrictions }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pa-0 under-line">
        Vendor Restrictions
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ _job.vendor_restrictions }}
      </v-card-text>

      <div>
        <div class="flex-container top-padding" v-if="_job.commodity_report || !_job.send_mail_to_vendors">
          <v-card-subtitle class="primary--text no-padding card-subtitle-review">
            Uncommon Request
          </v-card-subtitle>
          <v-spacer />
          <!-- <v-btn text x-small color="primary" @click="$emit('step', 3)">
            <v-icon size="15">mdi-pencil</v-icon>
            Edit
          </v-btn> -->
        </div>

        <div v-if="_job.commodity_report">
          <v-card-subtitle class="no-padding pt-2 pb-0 review-title under-line">
            Commodity Report?
          </v-card-subtitle>
          <v-card-text class="no-padding pa-0">
            {{ _job.commodity_report ? 'Yes' : 'No' }}
          </v-card-text>
        </div>

        <v-card max-height="200" style="overflow-x:auto;" v-if="_job.attachments" flat>
          <v-list-item v-for="(attachment, index) in _job.attachments" :key="index"
            v-show="attachment.catagory === 'commodity_report_template'">
            <v-list-item-icon>
              <v-icon color="secondary" v-if="attachment.location">
                mdi-paperclip
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ attachment.original_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ attachment.catagory.replace(/_/g, ' ') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row no-gutters>
                <v-btn icon :href="attachment.location" :download="attachment.original_name">
                  <v-icon color="secondary">mdi-download</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-card>
        <v-card flat>
          <a :style="{
            color: this.isValidHttpsUrl(_job.commodity_report_link)
              ? 'green'
              : 'red',
            marginLeft: '70px'
          }" target="_blank" :href="_job.commodity_report_link" v-on="on">
            {{
              this.isValidHttpsUrl(_job.commodity_report_link)
                ? _job.commodity_report_link
                : 'Invalid URL'
            }}
          </a>
        </v-card>

        <div v-if="!_job.send_mail_to_vendors">
          <v-card-subtitle class="no-padding pt-2 pb-0 review-title under-line">
            Alert Vendor?
          </v-card-subtitle>
          <v-card-text class="no-padding pa-0">
            {{ _job.send_mail_to_vendors ? 'Yes' : 'No' }}
          </v-card-text>
        </div>

        <div v-if="!isAdmin && _job.vendor_codes">
          <v-card-subtitle class="no-padding pt-2 pb-0 review-title under-line">
            <span :class="[{ 'error-title': _job.vendor_codes.length == 0 }]">
              Vendor Codes*
            </span>

          </v-card-subtitle>
          <div>
            <tr v-for="vendor in _job.vendorsAssignedInfo" :key="vendor.id" class="table-row-spacing">
              <td style="text-align:right;padding-right: 5px;">{{ vendor.id }}</td>
              <td> - {{ vendor.name }} </td>
            </tr>
          </div>
        </div>

        <div v-if="isAdmin && _job.vendor_codes && _job.vendor_codes.length > 0">
          <v-card-subtitle class="no-padding pt-2 pb-0 review-title under-line">
            <span :class="[{ 'error-title': _job.vendor_codes.length == 0 }]">
              Vendor Codes*
            </span>

          </v-card-subtitle>
          <div>
            <tr v-for="vendor in _job.vendorsAssignedInfo" :key="vendor.id" class="table-row-spacing">
              <td style="text-align:right;padding-right: 5px;">{{ vendor.id }}</td>
              <td> - {{ vendor.name }} </td>
            </tr>
          </div>
        </div>
      </div>
      <terms-conditions v-if="!isAdmin" class="pt-8" @accepted="accepted = !accepted" />
      <v-divider class="my-4" />
      <v-card flat>
        <v-row no-gutters class="mt-4 ma-3">
          <!-- <v-btn
            rounded
            color="error"
            v-if="!isCustomer"
            @click="$emit('cancel')"
          >
            Cancel
          </v-btn> -->
          <span v-if="isCustomer" class="error-title pointer mt-2" @click="$emit('showConfirmDelete')">
            Delete
          </span>
          <v-spacer />
          <div>
            <v-btn v-if="!isAdmin && statusJob === 'draft' && !isHideBtnSaveDraft" rounded :loading="_jobLoading"
              color="#2e384e" height="35" class="white--text mr-3" @click="
                isHideBtnSave = true;
              $emit('saveAsDraft');
              ">
              Save Draft
            </v-btn>

            <v-btn v-if="!isAdmin && !isHideBtnSave" rounded :disabled="!accepted || !enablePostJob"
              :loading="_jobLoading" color="secondary" height="35" class="white--text" @click="
                isHideBtnSaveDraft = true;
              $emit('post');
              ">
              {{ statusJob === 'draft' ? 'Post Job' : 'Save' }}
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </div>
    <v-progress-linear indeterminate color="green" v-if="loading"></v-progress-linear>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import TermsConditions from '@/components/app/Terms&Conditions.vue';
import { get } from '@/api';
import config from '../../config';
import * as Helpers from '@/helpers/helpers';
import RenderTextArea from '@/components/common/RenderTextArea.vue';
import _ from 'lodash';

export default {
  props: {
    spec: Array,
    enablePostJob: Boolean,
    statusJob: String
  },
  components: {
    TermsConditions,
    RenderTextArea
  },
  data() {
    return {
      siteName: undefined,
      user: undefined,
      loading: false,
      accepted: false,
      isAdmin: false,
      isCustomer: false,
      isSaveDraft: false,
      isHideBtnSave: false,
      isHideBtnSaveDraft: false,
      config: config,
      assetTypeLabelReview: config.ASSET_TYPE_LABEL_REVIEW,
      vendor_name: ''
    };
  },
  mounted() {
    //$emit('step', 1)
    this.isAdmin = this._companyType === config.companyType.MASTER;
    this.isCustomer = this._companyType === config.companyType.CUSTOMER;

    setTimeout(() => {
      if (this.isAdmin) {
        // this._getVendorById(this._job.vendor_id).then(res => {
        //   if (!res) {
        //     this.vendor_name = '';
        //   } else {
        //     this.vendor_name = res.data.name;
        //   }
        // });
      }
    }, 1000);

    /* Vu comment out
    if (this.newJob.site_id && !this.siteName) {
      this.loading = true;
      get("/site", this.newJob.site_id).then((res) => {
        this.siteName = res.data.data.name;
      });
    }
    if (this.newJob.poc_id && !this.user) {
      this.loading = true;
      get("/user", this.newJob.poc_id).then((res) => {
        this.user = res.data.data;
        setTimeout(() => {
          this.loading = false;
        }, 5000);
      });
    } */
  },
  methods: {
    ...Helpers,
    ...mapActions({
      _getVendorById: 'company/getVendorById'
    }),
    getUserName(id) {
      return id;
    },
    getUserEmail(id) {
      return id;
    },
    getUserPhone(id) {
      return id;
    },
    getUserAltPhone(id) {
      return id;
    },
    submitJob() {
      return;
    },
    checkAssetTypeRequired(assetType) {
      if (assetType.other_checked && assetType.other.length === 0) {
        return false;
      }
      for (let key in assetType) {
        if (assetType[key] === true) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    /* Vu comment out
    newJob: {
      handler() {
        if (this.newJob.site_id && !this.siteName) {
          get("/site", this.newJob.site_id).then((res) => {
            this.siteName = res.data.data.name;
          });
        }
        if (this.newJob.poc_id && !this.user) {
          get("/user", this.newJob.poc_id).then((res) => {
            this.user = res.data.data;
          });
        }
      },
      deep: true,
    }, */
  },
  computed: {
    ...mapGetters({
      _jobLoading: 'jobs/loading',
      _jobLoadingForDraft: 'jobs/loadingForDraft',
      // _newJob: "jobs/getNewJob",
      _job: 'jobs/getCurrentJob', // Vu added
      _company: 'company/getCompany',
      _companyType: 'company/getCompanyType',
      _users: 'user/getUsers',
      _sites: 'sites/getSites'
    }),
    bidDueDateIsValid() {
      return (
        !_.isNil(this._job.bid_due_date) &&
        moment(this._job.bid_due_date) >= moment()
      );
    },
    equipmentRemovalDateIsValid() {
      return (
        moment(this._job.bid_due_date).isBefore(moment(this._job.end_date)) &&
        !!this._job.exact_removal_date
      );
    }

    /*
    poc() {
      return this.user;
    },
    newJob() {
      return this._newJob;
    },
    getSiteName() {
      return this.siteName;
    },
    */
  }
};
</script>
<style>
.under-line {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.error-title {
  text-decoration: underline;
  color: #e53935;
}

.card-subtitle-review {
  color: #5d5d5d;
  font-size: 1.25em;
  font-weight: bold;
}

.table-row-spacing td {
  padding-bottom: 8px;
  padding-right: 16px;
}

.table-row-spacing td tr {
  padding-right: 10px;
}
</style>
