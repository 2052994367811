<template>
  <v-dialog v-model="_showAccountStatusDialog" @click:outside="closeDialog()" max-width="420px">
    <v-card class="pb-4" style="text-align:center;border: 1px; border-style: solid; border-color: #898989">
      <v-card-title class="pr-0 pl-6">
        <div style="float:left;width:33%">&nbsp;</div>
        <div style="float:left;width:33%"><img :src="require('@/assets/warning.png')" /></div>
        <div style="float:left;width:33%;text-align:right">
          <v-btn style="-webkit-box-shadow: none;box-shadow: none;background:white;border:0px " id="btnClose" @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card class="pa-1" flat v-if="company_status == 'New'">
        <h2 style="color:#0091ff;text-transform: uppercase;">Account Under Review</h2>
        <div style="text-align:left;padding:5px">
          Your new account is pending RGX approval. Contact
          <a style="color:rgb(0, 145, 255);text-decoration: underline;" href="mailto:info@recyclegx.com">info@recyclegx.com</a>
          with any questions.
          <br />
          <br />
          Eager to get started? Complete your vendor profile while waiting for full access.

          <br />
          <br />
        </div>

        <v-btn color="secondary" @click="gotoProfile()">Complete Profile</v-btn>
        <br />
      </v-card>
      <v-card class="pa-1" flat v-if="company_status == 'On Hold'">
        <h2 style="color:#0091ff !important;text-transform: uppercase;">Account On Hold</h2>
        <div style="text-align:left;padding:10px">
          Your company account is on hold. Your access is restricted to jobs already in progress and your job history.
          <br />
          <br />
          Please contact
          <a style="color:rgb(0, 145, 255);text-decoration: underline;" href="mailto:info@recyclegx.com">info@recyclegx.com</a>
          with any questions or for assistance.
          <br />
          <br />
        </div>
      </v-card>
      <v-card class="pa-1" flat v-if="company_status == 'Archive'">
        <h2 style="color:#0091ff !important;text-transform: uppercase;">Account Archived</h2>
        <div style="text-align:left;padding:10px">
          Your company account is currently archived and scheduled for deletion.
          <br />
          <br />
          Please contact
          <a style="color:rgb(0, 145, 255);text-decoration: underline;" href="mailto:info@recyclegx.com">info@recyclegx.com</a>
          with any questions or for assistance.
          <br />
          <br />
        </div>
      </v-card>
      <v-card class="pa-1" flat v-if="company_status == 'Delete'">
        <h2 style="color:#0091ff !important;text-transform: uppercase;">Account Deleted</h2>
        <div style="text-align:left;padding:10px">
          Your company account no longer exists.
          <br />
          <br />
          Please contact
          <a style="color:rgb(0, 145, 255);text-decoration: underline;" href="mailto:info@recyclegx.com">info@recyclegx.com</a>
          with any questions or for assistance.
          <br />
          <br />
        </div>
      </v-card>

      <v-card class="pa-1" flat v-if="!company_status">
        <h2 style="color:#0091ff;text-transform: uppercase;">You've Been Logged Out</h2>
        <div style="text-align:left;padding:5px;text-align:center">
          Please log back in.
          <br />
          <br />
        </div>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import VueRouter from '../../router';
export default {
  props: {
    confirm: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      error: false,
      company_status: ''
    };
  },

  computed: {
    ...mapGetters({
      _showAccountStatusDialog: 'company/getShowAccountStatusDialog',
      _getLoading: 'company/getLoading',
      _company: 'company/getCompany'
    })
  },
  watch: {
    _showAccountStatusDialog() {
      //   console.log("current status", this._currentCompanyStatus);
      console.log('current _company', this._company);
      if (this._company) this.company_status = this._company.status;
      if (window.location.href.indexOf('?401') > 0) {
        this.company_status = undefined;
        // //  this._showAccountStatusDialog=true;
      }
    }
  },
  mounted() {
    if (window.location.href.indexOf('?401') > 0) {
      this.company_status = undefined;
    }
  },
  methods: {
    ...mapMutations({
      _setShowAccountStatusDialog: 'company/setShowAccountStatusDialog',
      _setClosingConf: 'company/setClosingConf'
      //_setCompanyStatusChange: "company/setCompanyStatusChange",
    }),

    ...mapActions({
      // _updateStatus: "company/updateCompanyStatus",
      _getCompany: 'company/getCurrent',
      //_updateCompany: "company/updateCompany",
      _logOut: 'authenticate/logOut'
    }),
    gotoProfile() {
      VueRouter.push('/register/vendor');
    },
    closeDialog() {
      //  if(this.company_status=='Archive')
      if (this.company_status == 'On Hold') {
        VueRouter.push('/profile');
      } else {
        this._logOut();
        window.location.href = '/';
      }

      //  this._setClosingConf(false);
      // this.error=false;
      // this._setShowAccountStatusDialog(false);
      // this._showAccountStatusDialog=false;
    }
    // openDialog() {
    //   this._setShowAccountStatusDialog(true);
    // },
  }
};
</script>
