<template>
	<v-tooltip v-model="show" max-width="300" bottom>
		<template v-slot:activator="{ on }">
			<v-icon @click="show = !show" v-on="on">{{ icon }}</v-icon>
		</template>
		<span>{{ text }}</span>
	</v-tooltip>
</template>
<script>
export default {
	props: {
		text: String,
		icon: {
			default: "mdi-information",
			type: String,
		},
	},
	data() {
		return {
			show: false,
		};
	},
};
</script>
