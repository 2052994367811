<template>
	<v-card
		:width="
			$vuetify.breakpoint.mdAndUp ? 'calc(100% - 16px)' : 'calc(100% - 16px)'
		"
		v-if="total > 0"
		class="mx-2 my-2 pa-0"
		outlined
	>
		<v-row class="flex-nowrap no-gutters">
			<v-col class="text-left" sm="6" md="4">
				<v-card-subtitle>Daily Revenue</v-card-subtitle>
			</v-col>

			<v-col v-if="$vuetify.breakpoint.mdAndUp" :class="'text-right'" sm="2">
				<v-card-subtitle>Single Day High: ${{ sdh }}</v-card-subtitle>
			</v-col>
			<v-col v-if="$vuetify.breakpoint.mdAndUp" :class="'text-right'" sm="2">
				<v-card-subtitle>Single Day Low: ${{ sdl }}</v-card-subtitle>
			</v-col>
			<v-col v-if="$vuetify.breakpoint.mdAndUp" :class="'text-right'" sm="2">
				<v-card-subtitle>Daily Average: ${{ average }}</v-card-subtitle>
			</v-col>

			<v-col :class="'text-right'" sm="6" md="2">
				<v-card-subtitle>Total: ${{ total }}</v-card-subtitle>
			</v-col>
		</v-row>
		<v-spacer />
		<div style="height: 100%; overflow: auto" class="pa-4">
			<v-sparkline
				class="ma-2"
				style="overflow: auto;"
				:value="values"
				:gradient="gradient"
				:smooth="radius || false"
				:height="$vuetify.breakpoint.mdAndUp ? '30' : '100'"
				:padding="padding"
				:label-size="$vuetify.breakpoint.mdAndUp ? '2' : '10'"
				:labels="finalLables"
				:line-width="lineWidth"
				:stroke-linecap="lineCap"
				:gradient-direction="gradientDirection"
				:fill="fill"
				:type="type"
				auto-draw
				:show-labels="showLabels"
			>
				<template v-slot:label="item">
					<tspan>
						<tspan dy="-1.0em">${{ values[item.index].toFixed(2) }}</tspan>
						<tspan dx="-3.7em" dy="1.2em">{{ item.value }}</tspan>
					</tspan>
				</template>
			</v-sparkline>
		</div>
	</v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
	data: () => ({
		showLabels: true,
		lineWidth: 2,
		labelSize: 7,
		radius: 0,
		padding: 2,
		lineCap: "round",
		gradient: ["#8cd65d", "rgba(140, 214, 93, 0.05)"],
		labels: [
			"Jan",
			"Feb",
			"March",
			"April",
			"May",
			"June",
			"July",
			"Aug",
			"Sept",
			"Oct",
			"Nov",
			"Dec",
		],
		gradientDirection: "top",
		gradients: ["#8cd65d", "rgba(140, 214, 93, 0.28)"],
		fill: false,
		type: "trend",
		autoLineWidth: true,
	}),
	mounted() {},
	methods: {},
	wathc: {
		_bpm() {
		},
	},
	computed: {
		...mapGetters({
			_bpm: "dashboard/bpm",
		}),
		values() {
			if (this._bpm) {
				let values = this._bpm
					.filter((o) => o.d && o.m && o.y)
					.map((o) => ({
						count: (Number(o.bbo) || 0) + Number(o.bpp) || 0,
						date: o.m + "/" + o.d + "/" + o.y,
					}))
					.sort((a, b) => moment(b.date) - moment(a.date))
					.map((o) => {
						return o.count;
					});

				return values.reverse();
			} else {
				return [];
			}
		},
		average() {
			let totalList = this._bpm
				? this._bpm
						.filter((o) => o.m && o.y && o.d)
						.map((o) => {
							return Number(o.bbo) + Number(o.bpp);
						})
				: [];
			return (totalList.reduce((a, b) => a + b, 0) / totalList.length).toFixed(
				2
			);
		},
		sdl() {
			let totalList = this._bpm
				? this._bpm
						.filter((o) => o.m && o.y && o.d)
						.map((o) => {
							return Number(o.bbo) + Number(o.bpp);
						})
				: [];
			return Math.min.apply(null, totalList);
		},
		sdh() {
			let totalList = this._bpm
				? this._bpm
						.filter((o) => o.m && o.y && o.d)
						.map((o) => {
							return Number(o.bbo) + Number(o.bpp);
						})
				: [];
			return Math.max.apply(null, totalList).toFixed(2);
		},
		total() {
			let totalList = this._bpm
				? this._bpm
						.filter((o) => o.m && o.y && o.d)
						.map((o) => {
							return Number(o.bbo) + Number(o.bpp);
						})
				: [];
			let total = 0;
			totalList.forEach((i) => {
				total += i;
			});
			return String(total.toFixed(2));
		},
		finalLables() {
			if (this._bpm) {
				let labels = this._bpm
					.filter((o) => o.m && o.y && o.d)
					.map((o) => ({
						count: Number(o.count),
						date: o.m + "/" + o.d + "/" + o.y,
					}))
					.sort((a, b) => moment(b.date) - moment(a.date))
					.map((o) => {
						return o.date;
					});
				// if (labels.length < 2) {
				// 	labels.unshift("Start");
				// }
				return labels.reverse();
			} else {
				return [];
			}
		},
	},
};
</script>
