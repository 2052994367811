<template>
  <v-dialog v-model="_active" @click:outside="_setActive(false)" width="400">
    <v-card>
      <!--
			<v-card-title class="primary--text pt-4">
				{{ closed ? `Job Closed` : `Close Job?` }}
			</v-card-title>
			-->
      <v-toolbar flat>
        <v-card-title class="primary--text pt-4">
          {{ closed ? `Job Closed` : `Cancel Job?` }}
        </v-card-title>
        <v-btn class="btn-dismiss-dialog" text @click="_setActive(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        {{
          closed
            ? ``
            : `This can not be undone. Closing jobs with unawarded bids may result in cancelation fees.`
        }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!-- Vu comment out
				<v-btn
					rounded
					v-if="!closed"
					color="error"
					class="mr-2 elevation-0 mb-2"
					dark
					@click="_setActive(false)"
					>Cancel</v-btn
				> -->
        <v-btn
          rounded
          v-if="!closed"
          color="secondary"
          class="mr-2 elevation-0 mb-2"
          :loading="_loading"
          dark
          @click="close()"
        >
          confirm
        </v-btn>
        <v-btn
          rounded
          v-if="closed"
          color="secondary"
          class="mr-2 elevation-0 mb-2"
          dark
          @click="closeDialog()"
        >
          Close
        </v-btn>
        <v-spacer v-if="closed" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  data() {
    return {
      closed: false
    };
  },
  methods: {
    ...mapMutations({
      _setActive: 'jobs/setCloseConfirm',
      _setJob: 'jobs/setCurrentJob'
    }),
    ...mapActions({
      _close: 'jobs/updateJob'
    }),
    close() {
      // Vu updated 11/7/2021 for RGX-149 (added closed_date)
      var today = new Date();
      // var currentDateTime = today.toISOString();
      var payload = {
        status: 'closed',
        bid_status: 'closed',
        id: this.job.job_id,
        closed_date: today.toISOString()
      };
      this._close(payload).then(() => {
        this.closed = true;
        this.$router.push('/history');
        this.closeDialog();
      });
    },
    closeDialog() {
      this._setActive(false);
    }
  },
  computed: {
    ...mapGetters({
      _loading: 'jobs/loading',
      _active: 'jobs/getCloseConfirm',
      _job: 'jobs/getCurrentJob'
    }),
    job: {
      get() {
        return this._job;
      },
      set(newVal) {
        this._setJob(newVal);
      }
    }
  }
};
</script>
