<template>
  <div>
    <v-container>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-card flat>
          <v-card-title>
            <span class="headline">Create Member</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="firstName"
                    prepend-icon="mdi-account-circle"
                    :rules="[nameRules.required]"
                    label="*First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="lastName"
                    prepend-icon="mdi-account-circle"
                    :rules="[nameRules.required]"
                    label="*Last Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="role"
                    :rules="[nameRules.required]"
                    prepend-icon="mdi-office-building"
                    label="*Company Role"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    prepend-icon="mdi-email"
                    label="*Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="number"
                    v-mask="'+#(###)###-####'"
                    :rules="[nameRules.required]"
                    type="tel"
                    prepend-icon="mdi-phone"
                    label="*Cell Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-show="showNumberField"
                    v-model="addNumber"
                    v-mask="'+#(###)###-####'"
                    type="tel"
                    prepend-icon="mdi-phone"
                    label="Work Number"
                  ></v-text-field>
                </v-col>
                <v-btn fab x-small @click="showNumberField = true"><v-icon>mdi-plus-box-outline</v-icon></v-btn>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn :disabled="validated" @click="submitUser">Save</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
    <v-container>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-card flat>
          <v-card-title>
            <span class="headline">Create Member</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="firstName"
                    :rules="[nameRules.required]"
                    prepend-icon="mdi-account-circle"
                    label="*First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="lastName"
                    :rules="[nameRules.required]"
                    prepend-icon="mdi-account-circle"
                    label="*Last Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="role"
                    :rules="[nameRules.required]"
                    prepend-icon="mdi-office-building"
                    label="*Company Role"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    prepend-icon="mdi-email"
                    label="*Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="number"
                    v-mask="'+#(###)###-####'"
                    :rules="[nameRules.required]"
                    type="tel"
                    prepend-icon="mdi-phone"
                    label="*Cell Number"
                  ></v-text-field>
                  <v-btn fab x-small @click="showNumberField = true"><v-icon>mdi-plus-box-outline</v-icon></v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-show="showNumberField"
                    v-model="addNumber"
                    v-mask="'+#(###)###-####'"
                    type="tel"
                    prepend-icon="mdi-phone"
                    label="Work Number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn :disabled="validated" @click="submitUser">Save</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapActions } from 'vuex';
export default {
  directives: {
    mask
  },
  data() {
    return {
      search: '',
      showNumberField: false,
      addNumber: undefined,
      firstName: undefined,
      lastName: undefined,
      role: undefined,
      email: undefined,
      number: undefined,
      valid: true,
      modal: false,
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      nameRules: {
        required: v => !!v || 'Required.'
      }
    };
  },
  computed: {
    validated() {
      if (this.firstName && this.lastName && this.role && this.email && this.number) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions({
      createUser: 'user/createUserRole'
    }),
    validate() {
      if (this.form.validate()) {
        this.validated = true;
      }
    },
    submitUser() {
      const newUser = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        cell_number: this.number,
        company_role: this.role
      };
      if (this.modal) {
        this.$router.push({ path: '/teammember' });
      } else {
        this.$emit('closeModal');
      }
      return this.createUser(newUser);
    }
  }
};
</script>
