import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: []
      }
    };
  },
  computed: {
    tab() {
      return localStorage.getItem('jobTab');
    }
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        localStorage.setItem('options/' + this.$route.name, JSON.stringify(oldVal));
      },
      deep: true
    },
    tab() {
      this.options.sortBy = [];
      this.options.sortDesc = [];
    }
  },
  mounted() {
    if (localStorage.getItem('options/' + this.$route.name + localStorage.getItem('jobTab') ? localStorage.getItem('jobTab') : '')) {
      this.options = JSON.parse(localStorage.getItem('options/' + this.$route.name));
    }
  }
};
