<template>
  <v-row no-gutters>
    <v-col
      v-show="isShowTitle"
      :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12"
      class="section-title"
      >Terms & Conditions</v-col
    >
    <v-col
      v-if="acceptedEula && _loggedInCompany?.id"
      :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12"
    >
      <v-row no-gutters class="align-end" style="gap: 0.8rem">
        <span class="eula-acceptor mr-6">Read and Accepted</span>
        <v-btn icon :href="eulaSrc.src" :download="eulaSrc.original_name"
          ><v-icon color="secondary">mdi-download</v-icon></v-btn
        >
        <v-btn icon @click="previewDoc"
          ><v-icon color="secondary">mdi-file-find</v-icon></v-btn
        >
        <br />
      </v-row>
      <span class="eula-acceptor" v-html="eulaAcceptorInfo"></span>
    </v-col>
    <v-col
      v-else-if="_loggedInCompany?.id"
      :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12"
    >
      <v-card
        v-if="_company"
        :color="
          this.$route.query.new && !_company.vendor_eula
            ? 'grey lighten-3'
            : reachedPdfBottom && interactable
            ? 'green lighten-3'
            : 'red lighten-3'
        "
        flat
      >
        <v-card-text>
          Terms & Conditions - Vendor Registration
          <span class="mt-0 pt-0 caption"
            >(this is required to place a bid on job)</span
          >
        </v-card-text>

        <v-card-text>
          <eula
            isProfile
            :interactable="interactable"
            @agree="onAgree"
            @scrollToBottom="reachedPdfBottom = true"
          />
        </v-card-text>
        <!-- <v-checkbox
          v-model="_company.vendor_eula"
          :disabled="!reachedPdfBottom"
          class="ml-3 pb-4"
          color="secondary"
          label="I Certify that I have read and accept"
          messages="This is required to place a bid on job"
        ></v-checkbox> -->
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import eula from '@/components/app/TCView.vue';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    eula
  },
  props: {
    edit: Boolean,
    acceptedEula: Boolean,
    isShowTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      reachedPdfBottom: false,
      acceptor: undefined
    };
  },
  mounted() {
    if (!this._loggedInCompany?.id) {
      this._checkToken();
    }
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany',
      _loggedInCompany: 'company/getLoggedInCompany',
      _loggedInUser: 'user/getLoggedInUser'
    }),
    acceptorId() {
      return this._company.accepted_by;
    },
    eulaAcceptorInfo() {
      if (!this.acceptedEula) return '';
      if (!this._company?.accepted_at || !this._company?.accepted_by) return '';

      return [
        moment(this._company.accepted_at).format('MM/DD/YYYY'),
        this.acceptor
      ].join('<br />');
    },
    eulaSrc() {
      const isLocal = location.host.includes('localhost');
      const host = isLocal ? 'http://vndev.recyclegx.com' : location.host;
      const src = require('@/assets/pdf/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf');
      return Object.assign(
        {},
        {
          location: `${host}${src}`,
          src,
          original_name: 'End User Services Agreement (Platform Agreement).pdf',
          type: 'pdf'
        }
      );
    },
    interactable() {
      return this._loggedInUser?.type_id === 1;
    }
  },
  watch: {
    acceptorId: {
      async handler(val) {
        if (!val || this.acceptor) return;

        const user = await this._findUser(val);
        this.acceptor = `${user.first_name || ''} ${user.last_name || ''}`;
      },
      deep: true,
      immediate: true,
      once: true
    }
  },
  methods: {
    ...mapActions({
      _findUser: 'user/findById',
      _checkToken: 'authenticate/checkToken'
    }),
    ...mapMutations({
      _setGoogleDoc: 'googledoc/setShowGoogleDocDialog',
      _setSrc: 'googledoc/setSrc',
      _setIndex: 'googledoc/setIndex',
      _setList: 'googledoc/setList'
    }),
    onAgree() {
      this.reachedPdfBottom = true;
      this.$emit('agree');
    },
    formatEulaDate(date) {
      return moment(date).format('M/DD/YYYY');
    },
    previewDoc() {
      this._setList([this.eulaSrc]);
      this._setIndex(0);
      this._setGoogleDoc(true);
    }
  }
};
</script>
<style scoped>
.eula-acceptor {
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
}
</style>
