<template>
  <v-dialog v-model="active" v-if="_bid" max-width="500">
    <v-card class="pa-1" flat v-if="company_status == 'On Hold'" style="text-align:center;">
      <v-card-title class="pr-0 pl-6">
        <div style="float:left;width:33%">&nbsp;</div>
        <div style="float:left;width:33%">
          <img :src="require('@/assets/warning.png')" />
        </div>
        <div style="float:left;width:33%;text-align:right">
          <v-btn style="-webkit-box-shadow: none;box-shadow: none;background:white;border:0px " id="btnClose"
            @click="active = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <h2 style="color:#0091ff;text-transform: uppercase;">Account On Hold</h2>
      <div style="text-align:left;padding:5px">
        Your company account is on hold. Your access is restricted to jobs
        already in progress and your job history.
        <br />
        Please contact
        <a href="mailto:info@recyclegx.com">info@recyclegx.com</a>
        with any questions or for assistance.
        <br />
        <br />
      </div>
    </v-card>
    <div v-if="
      _companyType == 1 &&
      ['open', 'confirm'].includes(_bid.status) &&
      company_status != 'On Hold'
    ">
      <v-card>
        <v-container>
          <v-banner v-if="_bid.status === 'confirm'" class="confirm-bid-banner">
            <v-icon small color="#008cba">mdi-alert-circle-outline</v-icon>
            Bid pending vendor confirmation
          </v-banner>
          <div class="header">
            <v-card-title class="pl-4 pr-0">
              Bid Review
              <v-spacer />
              <v-btn text icon @click="active = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <div justify="center" align="center" style="margin-top: -10px;">
              <div v-if="
                this._job.logistics == 2 &&
                !this._bid.has_pickup_fee &&
                !this._bid.has_purchase_scrap
              ">
                <v-row align="center" justify="center" class="my-md-1 justify-space-around">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Vendor Bid All-in
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Min. Purchase Price
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            0.00
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" style="padding-top: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Customer
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Max. Fees
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="_bid.bid_buy_out ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            0.00
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <!-- <v-card color class="ma-5 bid-overview" outlined> -->
              <v-card-text style="padding-left: 0; padding-top: 0; padding-bottom: 0" v-if="
                !(
                  this._job.logistics == 2 &&
                  !this._bid.has_pickup_fee &&
                  !this._bid.has_purchase_scrap
                )
              ">
                <!-- Job has no weight for Case 1a and Case 3a -->
                <v-row v-if="
                  shouldShow() === 'CASE_1a' || shouldShow() === 'CASE_3a'
                " align="center" justify="center" class="my-md-1 justify-space-around">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Vendor
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Min. Purchase Bid
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              `${_bid.bidType
                                ? _bid.bid_per_pound
                                : _bid.bid_all_in_purchase
                              }`
                            }}
                          </div>
                          <div style="align-self: center; font-size: 0.9em" class="pl-2" v-if="_bid.bidType">
                            Price/lb
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" style="padding-top: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Customer
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Max. Fees
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="_bid.bid_buy_out ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              _bid.bid_buy_out
                                ? '(' + numberWithCommas(_bid.bid_buy_out) + ')'
                                : numberWithCommas(_bid.bid_buy_out)
                            }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- Case 1-->
                <v-row v-if="shouldShow() === 'CASE_1'" align="center" justify="center"
                  class="my-md-1 justify-space-around">
                  <v-col cols="12" style="padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1">
                          Price/lb Bid
                        </v-card-title>
                      </v-col>

                      <v-col cols="6" sm="4" class="pr-1" :class="!_bid.hasJobWeight ? 'alignSelfCenter' : null">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="font-size: 1.2em">
                            {{ _bid.bid_per_pound }}
                          </div>
                          <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                            Price/lb
                          </div>
                        </v-layout>

                        <v-layout style="width:144px!important">
                          <div style="font-size: 1.2em">
                            <v-icon x-small class="pl-2">mdi-close</v-icon>
                            {{
                              numberWithCommas(
                                _job.weight ? _job.weight.slice(0, -4) : 1
                              )
                            }}
                          </div>
                          <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                            Est. lb
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Vendor
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Min. Purchase Price
                        </v-card-subtitle>
                      </v-col>
                      <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 18px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              numberWithCommas(
                                calculate(
                                  _bid.bid_per_pound,
                                  _job.weight ? _job.weight.slice(0, -4) : 1
                                ).toFixed(2)
                              )
                            }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Customer
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Max. Fees
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 32px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="_bid.bid_buy_out ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              _bid.bid_buy_out
                                ? '(' + numberWithCommas(_bid.bid_buy_out) + ')'
                                : numberWithCommas(_bid.bid_buy_out)
                            }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="_bid.hasJobWeight" cols="12" style="padding-top: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Net Estimate
                        </v-card-title>
                      </v-col>

                      <v-col cols="6" sm="4" style="align-self: center">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{ calculateNetEstimate() }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- Case 3 -->
                <v-row v-if="shouldShow() === 'CASE_3'" align="center" justify="center"
                  class="my-md-1 justify-space-around">
                  <v-col cols="12" style="padding-bottom: 0" v-if="_bid.bidType">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1">
                          Price/lb Bid
                        </v-card-title>
                      </v-col>

                      <v-col cols="5" sm="4" class="pr-1" :class="!_bid.hasJobWeight ? 'alignSelfCenter' : null">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="font-size: 1.2em">
                            {{ _bid.bid_per_pound }}
                          </div>
                          <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                            Price/lb
                          </div>
                        </v-layout>

                        <v-layout style="width:144px!important">
                          <div style="font-size: 1.2em; pl-xs-3">
                            <v-icon x-small class="pl-sm-2">mdi-close</v-icon>
                            {{
                              numberWithCommas(
                                _job.weight ? _job.weight.slice(0, -4) : 1
                              )
                            }}
                          </div>
                          <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                            Est. lb
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Vendor
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.9em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Min. Purchase Price
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 18px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              numberWithCommas(
                                calculate(
                                  _bid.bid_per_pound,
                                  _job.weight ? _job.weight.slice(0, -4) : 1
                                ).toFixed(2)
                              )
                            }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="!_bid.bidType" cols="12" style="padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Vendor Bid All-in
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Min. Purchase Price
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{ calculateVendorBid() }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Customer
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Max. Fees
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="_bid.bid_buy_out ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              _bid.bid_buy_out
                                ? '(' + numberWithCommas(_bid.bid_buy_out) + ')'
                                : numberWithCommas(_bid.bid_buy_out)
                            }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Net Estimate
                        </v-card-title>
                      </v-col>

                      <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0" sm="6">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="netEstTextColorRed ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{ calculateNetEstimate() }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="!_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Customer
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Max. Fees
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="4" sm="4" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="_bid.bid_buy_out ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              _bid.bid_buy_out
                                ? '(' + numberWithCommas(_bid.bid_buy_out) + ')'
                                : numberWithCommas(_bid.bid_buy_out)
                            }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="!_bid.bidType" cols="12" style="padding-top: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Net Estimate
                        </v-card-title>
                      </v-col>

                      <v-col cols="4" sm="4" style="align-self: center">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="netEstTextColorRed ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{ calculateNetEstimate() }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- Case 2, Case 4 -->
                <v-row v-if="shouldShow() === 'CASE_2' || shouldShow() === 'CASE_4'" align="center" justify="center"
                  class="my-md-1 justify-space-around">
                  <v-col cols="12" style="padding-bottom: 0px">
                    <v-row no-gutters>
                      <v-col cols="10" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Vendor Bid All-in
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Min. Purchase Price
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{ calculateVendorBid() }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Customer
                        </v-card-title>
                        <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                          Max. Fees
                        </v-card-subtitle>
                      </v-col>

                      <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 33px">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div :class="_bid.bid_buy_out ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{
                              _bid.bid_buy_out
                                ? '(' + numberWithCommas(_bid.bid_buy_out) + ')'
                                : numberWithCommas(_bid.bid_buy_out)
                            }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" style="padding-top: 0">
                    <v-row no-gutters>
                      <v-col cols="6" sm="6">
                        <v-card-title class="subtitle-1" style="padding-bottom: 0">
                          Net Estimate
                        </v-card-title>
                      </v-col>

                      <v-col cols="4" sm="5" style="align-self: center">
                        <v-layout>
                          <v-icon>mdi-currency-usd</v-icon>
                          <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null" style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                            {{ calculateNetEstimate() }}
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- </v-card> -->

              <v-row>
                <table style="width:95%;margin-left:12px;background:#f2f2f2;margin-right: 14px;" class="pa-5">
                  <tr style="vertical-align: top;">
                    <td class="pa-5" colspan="3">
                      <b style="font-size:1rem">RGX Transaction Fees</b>
                    </td>
                  </tr>
                  <tr style="vertical-align: top;">
                    <td class="pa-5" style="width:48%;padding-top: 0px !important;padding-bottom: 0px !important;">
                      <div>
                        <b style="font-size:1rem">Scrap Fee</b>
                        <span>= Greater of</span>
                      </div>
                      <div style="text-align:right;padding-right:30px; ">
                        OR
                      </div>
                    </td>
                    <td class="pa-5"
                      style="padding-top: 0px !important;padding-left: 0px !important;padding-bottom: 0px !important;">
                      <div style="float:left;width:30px">
                        <br />
                        <v-icon style="margin-top:4px">mdi-currency-usd</v-icon>
                      </div>
                      <div style="float:left;width:60px;font-size: 1.2em;">
                        <u>{{ _bid.scrap_purchase_percentage }}</u>
                        %
                        <br />
                        <u>{{ _bid.scrap_per_pound }}</u>
                      </div>
                      <div style="float:left;line-height: 1.8em;padding-left: 10px;">
                        Scrap Proceeds
                        <br />
                        Fee/lb
                      </div>
                    </td>
                  </tr>
                  <tr style="vertical-align: top;padding-top: 0px !important;padding-left: 10px;">
                    <td class="pa-5" style="font-size:1rem">
                      <b>Resale Fee</b>
                    </td>
                    <td class="pa-5" style="padding-left: 0px !important;">
                      <div style="float:left;width:30px">&nbsp;</div>
                      <div style="float:left;width:60px;font-size: 1.2em;">
                        <u>{{ _bid.equipment_purchase_percentage }}</u>
                        %
                      </div>
                      <div style="float:left;line-height: 1.8em;padding-left: 10px;">
                        Resale Price
                      </div>
                    </td>
                  </tr>
                </table>
              </v-row>

              <v-row no-gutters class="pa-0 ma-0">
                <v-card-text style="text-align:left">
                  Contact
                  <a href="mailto:info@recyclegx.com?subject=Urgent: Transaction Fee Question" style="color: green;">
                    RGX
                  </a>
                  with any questions about our transaction fees.
                </v-card-text>
              </v-row>
              <v-row no-gutters class="pa-0 ma-0">
                <v-checkbox class="pa-0 mt-3 ml-0"  :disabled="_bid.status == 'confirm'" dense inverse v-model="accepted">
                  <template v-slot:label>
                    <div>
                      I accept the
                      <template>
                        <a style="color: green" target="_blank"
                          href="https://www.recyclegx.com/wp-content/uploads/2021/07/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf"
                          @click.stop>
                          Terms of Service
                        </a>
                      </template>
                      and
                      <template>
                        <a style="color: green" target="_blank"
                          href="https://www.recyclegx.com/support/what-costs-are-charged-to-utilize-rgx/" @click.stop>
                          RGX fees.
                        </a>
                      </template>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                &nbsp;
                <v-card-title class="pa-0 subtitle-1" style="color: green">
                  Note:
                </v-card-title>
              </v-row>
              <v-row no-gutters>
                <v-card-subtitle class="ma-0" style="padding-top: 0">
                  Accepting this job will reject all other offers.
                  <br />
                  Rejecting the offer will permanently delete it.
                </v-card-subtitle>
              </v-row>

              <v-row no-gutters class="mt-4" v-if="_bid.status !== 'confirm'">
                <v-btn rounded color="error" elevation="0" height="35" class="white--text" :loading="rejectLoading"
                  @click="handleReject">
                  Reject
                </v-btn>
                <v-spacer />
                <v-btn rounded :disabled="!accepted" color="secondary" elevation="0" :loading="awardLoading" height="35"
                  class="white--text" @click="handleAward">
                  Award
                </v-btn>
              </v-row>
            </div>
          </div>
        </v-container>
        <div style="display:none ;">
          <v-btn rounded height="35" @click="testPDF">testPDF</v-btn>
          <div id="element-to-convert" style="letter-spacing: normal;padding:20px">
            <span style="display:none">
              RGX Pickup Receipt Job {{ _job.job_id }}
            </span>
            <table style="width:98%;margin: 0 auto; color:#514d4e">
              <tr>
                <td style="padding:15px;width:50%">
                  <img src="/img/rgx-black-lockup-1.d1d964a2.png" style="max-width:150px" />
                </td>
                <td
                  style="width:50%;padding:15px;color: rgb(76, 175, 80); font-weight: bold;text-align:right;font-size:23px;">
                  Equipment Transfer Receipt
                </td>
              </tr>
              <tr>
                <td colspan="2" style="padding:15px;">
                  <hr />
                </td>
              </tr>
              <tr>
                <td style="padding:15px;color: rgb(76, 175, 80); font-weight: bold;font-size:20px;vertical-align:top">
                  RGX Job {{ _job.job_id }}:
                  {{ _job.number ? _job.number : _job.name }}
                </td>
                <td style="padding:15px;text-align:right">
                  <span style="font-weight: bold;">Transfer Date:</span>
                  <b>
                    {{ _job.end_date ? formatPickupDueDate(_job.end_date) : ''
                    }}{{ _job.end_date ? ', ' : ''
                    }}{{
                      getReviewValueByValue(
                        config.EXACT_REMOVE_DATE_OPTIONS,
                        _job.exact_removal_date
                      )
                    }}
                  </b>
                  <br />
                  <span style="font-weight: bold;">Estimated:&nbsp;</span>
                  <u>{{ _job.pallets || 'NA' }}</u>
                  &nbsp;
                  <b>pallets</b>
                  ,
                  <u>
                    {{
                      _job.est_weight
                        ? Number(_job.est_weight).toLocaleString('en')
                        : 'NA'
                    }}
                  </u>
                  &nbsp;
                  <b>lbs.</b>
                </td>
              </tr>
              <tr>
                <td style="padding:15px;vertical-align:top">
                  <table style="width:100%;border:1px solid #d5d0d0;letter-spacing: normal;">
                    <tr style="background:#d5d0d0">
                      <td style="font-weight:bold;padding:15px">
                        Sender Information
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align:top;padding:15px">
                        <div>
                          <b><u>Equipment Location</u></b>
                        </div>
                        <div>{{ _job.site_name }}</div>
                        <div>
                          {{ _job.street
                          }}{{ _job.suite ? ', Suite ' + _job.suite : '' }}
                        </div>
                        <div>
                          {{ _job.city }}, {{ _job.state }}
                          {{ _job.zip }}
                        </div>
                        <div>
                          <b><u>On-Site Contact</u></b>
                        </div>

                        <div>
                          {{ _job.poc_fname || ''
                          }}{{ _job.poc_lname ? ' ' + _job.poc_lname : '' }}
                        </div>
                        <div>{{ _job.poc_email }}</div>
                        <div>{{ _job.poc_phone }}</div>
                      </td>
                    </tr>
                  </table>
                </td>
                <td style="padding:15px;vertical-align:top">
                  <table style="width:100%;border:1px solid #d5d0d0;letter-spacing: normal;">
                    <tr style="background:#d5d0d0">
                      <td style="font-weight:bold;padding:15px;">
                        Consignee/Recipient Information
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align:top;padding:15px">
                        <div>
                          <b><u>Vendor Location</u></b>
                          <br />
                          {{ _bid.name }}
                        </div>
                        <div>
                          {{ _bid.site_street
                          }}{{
                            _bid.site_suite ? ', Suite ' + _bid.site_suite : ''
                          }}
                        </div>
                        <div>
                          {{ _bid.site_city }}, {{ _bid.site_state }}
                          {{ _bid.site_zip }}
                        </div>

                        <div>
                          <b><u>Vendor Contact</u></b>
                        </div>
                        <div>
                          {{ _bid.poc_first_name || ''
                          }}{{
                            _bid.poc_last_name ? ' ' + _bid.poc_last_name : ''
                          }}
                        </div>
                        <div>{{ _bid.poc_email }}</div>
                        <div>{{ _bid.poc_phone }}</div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="padding:15px;" colspan="2">
                  <table style="width:100%;border:1px solid #d5d0d0;letter-spacing: normal;">
                    <tr style="background:#d5d0d0">
                      <td style="font-weight:bold;padding:15px">
                        Equipment Transfer Information
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align:top;padding:5px">
                        <div style="clear:both;height:20px">
                          <div style="float:left"><b>Method:</b></div>
                          <div style="float:left;margin-left: 1px;margin-right: 15px; margin-top: -19px;">
                            <v-radio-group label="" v-model="_job.exact_removal_date">
                              <v-radio class="small-radio" :value="'novalue'" :label="config.EQUIPMENT_TRANSFER_LOGISTICS_OPTIONS
                                .PICKUP.title
                                " style="transform: scale(0.8); font-size: 10px; margin-bottom: 0px;" />
                              <v-radio class="small-radio" :value="'novalue1'" :label="'Drop-off'"
                                style="transform: scale(0.8); font-size: 10px; margin-bottom: 0px;" />
                            </v-radio-group>
                          </div>
                          &nbsp;
                          <div style="float:left"><b>Transfer Date:</b></div>
                          <div style="width:150px;background:#ccd7ff;float:left;margin-left: 10px;margin-right: 20px;">
                            &nbsp;
                          </div>
                          <div style="width:60px;background:#ccd7ff;float:left;">
                            &nbsp;
                          </div>
                          <div style="margin-right: 15px;float:left;font-weight:bold;">
                            &nbsp;pallets
                          </div>
                          <div style="width:70px;background:#ccd7ff;float:left">
                            &nbsp;
                          </div>
                          <div style="float:left;font-weight:bold;">
                            &nbsp;lbs.
                          </div>
                        </div>
                        <div style="clear:both;height:30px">
                          <b>Description of Equipment:</b>
                        </div>
                        <div style="background:#ccd7ff;height:120px">
                          &nbsp;
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="padding:10px;">
                  <div style="clear:both;height:30px;">
                    <div style="float:left;text-align:right;width:48%;font-weight:bold;padding-right:5px">
                      Sender Signature:
                    </div>
                    <div style="width:44%;background:#ccd7ff;float:left;">
                      &nbsp;
                    </div>
                  </div>
                  <div style="clear:both;height:30px;">
                    <div style="float:left;text-align:right;width:48%;font-weight:bold;padding-right:5px">
                      Name:
                    </div>
                    <div style="width:44%;background:#ccd7ff;float:left;">
                      &nbsp;
                    </div>
                  </div>
                  <div style="clear:both;height:30px;">
                    <div style="float:left;text-align:right;width:48%;font-weight:bold;padding-right:5px">
                      Date:
                    </div>
                    <div style="width:44%;background:#ccd7ff;float:left;">
                      &nbsp;
                    </div>
                  </div>
                </td>
                <td style="padding:10px;">
                  <div style="clear:both;height:30px;">
                    <div style="float:left;text-align:right;width:48%;font-weight:bold;padding-right:5px">
                      Consignee Signature:
                    </div>
                    <div style="width:44%;background:#ccd7ff;float:left;">
                      &nbsp;
                    </div>
                  </div>
                  <div style="clear:both;height:30px;">
                    <div style="float:left;text-align:right;width:48%;font-weight:bold;padding-right:5px">
                      Name:
                    </div>
                    <div style="width:44%;background:#ccd7ff;float:left;">
                      &nbsp;
                    </div>
                  </div>
                  <div style="clear:both;height:30px;">
                    <div style="float:left;text-align:right;width:48%;font-weight:bold;padding-right:5px">
                      Date:
                    </div>
                    <div style="width:44%;background:#ccd7ff;float:left;">
                      &nbsp;
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-card>
    </div>
    <WaitingIndicator :isShow="showLoader"></WaitingIndicator>
  </v-dialog>
</template>
<script>
import html2pdf from 'html2pdf.js';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import config from '../../config';
// import tc from "@/components/app/Terms&Conditions.vue";
import BidInformation from '@/mixins/BidInformation';
import * as Helpers from '@/helpers/helpers';
import moment from 'moment';
import WaitingIndicator from '@/components/common/WaitingIndicator.vue';
export default {
  components: {
    WaitingIndicator
  },
  mixins: [BidInformation],
  data() {
    return {
      company_status: false,
      accepted: false,
      deleteBid: false,
      bidPOC: undefined,
      awardLoading: false,
      rejectLoading: false,
      netEstTextColorRed: false,
      pdfstring: null,
      config: config,
      showLoader: false
    };
  },
  computed: {
    ...mapGetters({
      _active: 'bids/getCustomerBidReview',
      _bid: 'bids/getCurrentBid',
      _bids: 'bids/getBids',
      _bidCompany: 'bids/getCurrentBidCompany',
      _job: 'jobs/getCurrentJob',
      _company: 'company/getCompany',
      _companyType: 'company/getCompanyType',
      _loading: 'bids/getLoading'
    }),
    active: {
      get() {
        return this._active;
      },
      set(val) {
        this._activateBidReview(val);
      }
    }
    // _bidPOC() {
    //   return this._user ?
    //     this._user(this._bid.poc_id)
    //     : null;
    // }
  },
  watch: {
    pdfstring() {
      if (this.pdfstring) {
        this.showLoader = true;
        this.awardLoading = true;
        //console.log("call _sendEmailAwardedJobInfo",this.pdfstring);
        this._sendEmailAwardedJobInfo({
          id: this._job.job_id,
          vendor_id: this._bid.company_id,
          pdf: this.pdfstring
        }).then(() => {
          //setTimeout(() => {
          this.showLoader = false;
          this.awardLoading = false;
          //}, 600);
        });
        // this.showLoader = false;
        // this.awardLoading = false;
      }
    },
    _bid() {
      if (this._companyType === config.companyType.CUSTOMER && !this.bidPOC) {
        this.bidPOC = this._user(this._job.poc_id);
      }
    },

    _active() {
      this.company_status = this._company.status;
      if (
        this._companyType === config.companyType.CUSTOMER &&
        this._bid &&
        !this._bid.scenario
      ) {
        const information = this.addBidInformation(
          this._bid,
          this._job,
          this._companyType
        );

        this._setCurrentBid({
          ...this._bid,
          ...information
        });
      }
    },

    _job() {
      if (this._job.status === 'awarded') {
        this.active = false;
      }
    }
  },
  methods: {
    ...Helpers,
    ...mapMutations({
      _activateBidReview: 'bids/toggleCustomerReview',
      _setLoading: 'jobs/toggleLoading'
    }),
    ...mapActions({
      _updateBid: 'bids/updateBid',
      _updateJob: 'jobs/updateJob',
      _sendEmailAwardedJobInfo: 'jobs/sendEmailAwardedJobInfo',
      _removeBid: 'bids/deleteBid',
      _user: 'user/getById'
    }),
    removeBid() {
      this._removeBid(this._bid.id).then(res => {
        this.close();
        setTimeout(() => {
          this.active = false;
        }, 200);
      }); 
      // this._removeBid(this._bid.id);
      // if (this._bids.length <= 1) {
      //   this._updateJob({
      //     id: this._bid.job_id,
      //     status: 'open',
      //     bid_status: 'none'
      //   }).then(() => {
      //     this.active = false;
      //   });
      // }
      // this.close();
    },
    close() {
      this.deleteBid = false;
      this.active = false;
    },
    handleReject() {
      // this._setLoading(true);
      this.rejectLoading = true;
      var options = {
        id: this._bid.id,
        status: 'rejected'
      };
      this._updateBid(options).then(() => {
        this.active = false;
        this.rejectLoading = false;
      });
    },
    async testPDF() {
      var opt = {
        margin: 0.1,
        // filename:     'myfile.pdf',
        // image:        { type: 'jpeg', quality: 0.98 },
        // html2canvas:  { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      var element = document.getElementById('element-to-convert');
      // New Promise-based usage:
      let blobString = await html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .output('datauristring');
      console.log('call _sendEmailAwardedJobInfo', blobString);
      html2pdf()
        .set(opt)
        .from(element)
        .save();
      // html2pdf().from().toPdf();
    },
    async genPDF() {
      this.showLoader = true;
      this.awardLoading = true;
      var element = document.getElementById('element-to-convert');
      let blobString = await html2pdf()
        .from(element)
        .toPdf()
        .output('datauristring');
      //console.log("blobString",blobString);
      this.pdfstring = blobString.replace(
        'data:application/pdf;filename=generated.pdf;base64,',
        ''
      );
      this.showLoader = false;
      this.awardLoading = false;
    },
    async handleAward() {
      //this.showLoader = true;
      //this.awardLoading = true;
      this.genPDF();
      // var options = {
      //   id: this._bid.id,
      //   status: 'awarded',
      //   vendor_attachments: this._bid.attachments || []
      // };
      // this._updateBid(options).then(() => {
      //   for (var i = 0; i < this._bids.length; i++) {
      //     if (this._bids[i].bid_id !== this._bid.id) {
      //       options = {
      //         id: this._bids[i].bid_id,
      //         status: 'rejected'
      //       };
      //       this._updateBid(options);
      //     }
      //   }
      //   this._updateJob({
      //     id: this._bid.job_id,
      //     status: 'awarded',
      //     bid_status: 'awarded'
      //   }).then(() => {
      //     this.genPDF();
      //   });
      // });
    },
    // Vu added 9/18/21
    numberWithCommas(x) {
      if (x == null) {
        return '0';
      }
      // return x ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "";
      return x
        ? Number(x.toString().replaceAll(',', '')).toLocaleString('en-US', {
          minimumFractionDigits: 2
        })
        : '';
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    formatPickupDueDate(date) {
      return moment(date).format('MM/DD/YY');
    },
    calculate(str1, str2) {
      const rs = Number(str1.replace(/,/g, '')) * Number(str2 || 1);
      return rs;
    },
    calculateVendorBid() {
      // debugger;
      if (!this._bid) {
        return;
      }

      let number = this._bid.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;

      // return this._bid.scenario !== 4 ? "(" + this.numberWithCommas(number) + ")" : this.numberWithCommas(number);
      if (number == null) {
        return '0';
      }

      return this.numberWithCommas(number);
    },
    calculateNetEstimate() {
      // debugger;
      if (!this._bid || !this._job) {
        return;
      }

      // init
      // this.netEstTextColorRed = false;
      let numberAsString = this._bid.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;

      let number = numberAsString
        ? parseFloat(numberAsString.replace(/,/g, ''))
        : 0;

      // const bidBuyOut = parseFloat(this._bid.bid_buy_out.replace(/,/g, ""));
      const bidBuyOut = this._bid.bid_buy_out
        ? parseFloat(this._bid.bid_buy_out.replace(/,/g, ''))
        : 0;

      const unit = this._job.weight
        ? parseFloat(this._job.weight.slice(0, -4))
        : 1;

      if (this._bid.scenario === 1) {
        number = number * unit;
      }

      if (this._bid.scenario === 3 && this._bid.bidType) {
        number = number * unit;
      }

      const net = bidBuyOut - number;
      /*
      if (net < 0 && this._bid.scenario !== 4) {
        this.netEstTextColorRed = true;
        return "(" + this.numberWithCommas(Math.abs(net).toFixed(2)) + ")";
      } */
      if (net < 0 && this._bid.scenario !== 4) {
        this.netEstTextColorRed = false;
        return this.numberWithCommas(Math.abs(net).toFixed(2));
      }

      if (net == 0) {
        this.netEstTextColorRed = false;
        return this.numberWithCommas(net.toFixed(2));
      }

      this.netEstTextColorRed = true;
      return '(' + this.numberWithCommas(net.toFixed(2)) + ')';
    },
    // check case to show BID REVIEW
    shouldShow() {
      if (!this._bid) {
        return '';
      }

      if (this._bid.scenario === 1) {
        if (!this._bid.hasJobWeight) {
          return 'CASE_1a';
        }
        return 'CASE_1';
      }

      if (this._bid.scenario === 2) {
        return 'CASE_2';
      }

      if (this._bid.scenario === 3) {
        if (this._bid.bidType && !this._bid.hasJobWeight) {
          return 'CASE_3a';
        }

        return 'CASE_3';
      }

      if (this._bid.scenario === 4) {
        return 'CASE_4';
      }
    },
    handlePickupDueDate(dateString) { }
  }
};
</script>
<style lang="scss" scoped>
.flex-container {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  width: 80%;
}

.location {
  grid-column: 1;
  grid-row: 1;
}

.number {
  grid-column: 2;
  grid-row: 1;
}

.duration {
  grid-column: 3;
  grid-row: 1;
}

.weight {
  grid-column: 1;
  grid-row: 2;
}

.pallets {
  grid-column: 1;
  grid-row: 3;
}

.recycling {
  grid-column: 2;
  grid-row: 2 / span 2;
}

.attachments {
  grid-column: 3;
  grid-row: 2 / span 2;
}

.access {
  grid-column: 1;
  grid-row: 4;
}

.lift {
  grid-column: 3;
  grid-row: 4;
}

.title-font {
  font-size: 17px;
  color: #2e2e2e;
}

.subtitle-font {
  font-size: 14px;
  line-height: 1.43;
  color: #5d5d5d;
}

.vendor {
  grid-column: 1;
}

.address {
  grid-column: 2;
}

.phone {
  grid-column: 3;
}

.email {
  grid-column: 4;
}

.company-name {
  font-size: 23px;
  color: #2e2e2e;
}

.center {
  align-self: center;
}

.text-center {
  text-align: center;
}

.link-color {
  color: #0091ff;
}

a {
  text-decoration: none;
}

.textColorRed {
  color: #e53935;
}

.alignSelfCenter {
  align-self: center;
}

.subtitle-1 {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
}

.confirm-bid-banner {
  background-color: #d6e8f1 !important;
}

.confirm-bid-banner::v-deep .v-banner__wrapper {
  border: none !important;
}
</style>

<style scoped>
/* div {
  color: black;
  font-weight: solid 2px;
} */
</style>
