<template>
  <div>
    <v-card outlined>
      <v-card-title>Vendor Registration</v-card-title>
      <v-row no-gutters>
        <v-col sm="12" md="6">
          <v-stepper
            v-if="$vuetify.breakpoint.smAndDown"
            v-model="step"
            non-linear
            class="elevation-0"
            editable
          >
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="step > 1" step="1" @click="step = 1"
                >Abilities</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2" @click="step = 2"
                >Document Upload</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 3" step="3" @click="step = 3"
                >EULA</v-stepper-step
              >
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card flat>
                  <v-card-actions>
                    <v-card-title class="pl-0">Abilities</v-card-title>
                    <v-spacer />
                    <v-btn small text @click="step += 1">
                      Next
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-container fluid>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="(ability, i) in abilities" :key="i">
                            <td class="flex-nowrap px-0">
                              <v-subheader class="pa-0">{{
                                ability.statement
                              }}</v-subheader>
                            </td>

                            <td class="pr-0">
                              <v-radio-group v-model="ability.able" dense row>
                                <v-radio label="No" :value="false"></v-radio>
                                <v-radio label="Yes" :value="true"></v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-container>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card flat>
                  <v-card-actions>
                    <v-card-title max-width="calc(50%)" class="pl-0">
                      Documents
                    </v-card-title>
                    <v-spacer />
                    <v-btn small text @click="step -= 1">
                      <v-icon small>mdi-arrow-left</v-icon>
                      Prev
                    </v-btn>
                    <v-btn small text @click="step += 1">
                      Next
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-container fluid>
                    <v-subheader class="pl-0 mb-0"
                      >Are you R2 or e-Stewards certified?</v-subheader
                    >
                    <v-card class="pa-2 mb-2" outlined>
                      <v-radio-group
                        v-if="company"
                        class="mt-0"
                        row
                        v-model="r2"
                      >
                        <v-radio
                          class="mt-0"
                          label="Yes"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          class="mt-0"
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        v-if="company"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="dateFormatted"
                            :rules="[passRules.required, passRules.dateMax]"
                            ref="dateFormatted"
                            :disabled="!r2"
                            label="When does your certification expire?"
                            prepend-icon="mdi-calendar-month"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="r2_date"
                          :min="maxCertDate"
                          no-title
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-card>
                    <v-subheader class="pl-0 mb-0"
                      >Do you have a Certificate of Resale?</v-subheader
                    >
                    <v-card class="pa-2 mb-2" outlined>
                      <v-radio-group
                        v-if="company"
                        class="mt-0"
                        row
                        v-model="resale"
                      >
                        <v-radio
                          class="mt-0"
                          label="Yes"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          class="mt-0"
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                      <v-file-input
                        v-if="resale"
                        @change="uploadFile($event, 'resale')"
                        outlined
                        @focus="resaleFocused = !resaleFocused"
                        @blur="resaleFocused = !resaleFocused"
                        :loading="resaleFocused ? _uploading : false"
                        dense
                        label="Certificate of Resale"
                        :error-messages="resaleRequired"
                      ></v-file-input>
                    </v-card>

                    <v-file-input
                      @change="uploadFile($event, 'w9')"
                      outlined
                      @focus="w9Focused = !w9Focused"
                      @blur="w9Focused = !w9Focused"
                      :loading="w9Focused ? _uploading : false"
                      dense
                      :error-messages="w9Required"
                      label="W9 Upload"
                    ></v-file-input>
                    <v-file-input
                      @change="uploadFile($event, 'insurance')"
                      outlined
                      dense
                      @focus="insuranceFocused = !insuranceFocused"
                      @blur="insuranceFocused = !insuranceFocused"
                      :loading="insuranceFocused ? _uploading : false"
                      :error-messages="insuranceRequired"
                      label="Insurance Certificate Upload"
                    ></v-file-input>
                    <v-fade-transition>
                      <v-file-input
                        @change="uploadFile($event, 'miscellaneous', n)"
                        ref="misc"
                        :error-messages="certRequired"
                        dense
                        v-model="miscFile"
                        outlined
                        @focus="miscFocused = !miscFocused"
                        @blur="miscFocused = !miscFocused"
                        :loading="miscFocused ? _uploading : false"
                        messages="Please upload a copy of all certifications currently held by your company."
                        label="Misc. Certification"
                      ></v-file-input>
                    </v-fade-transition>

                    <!-- <v-row no-gutters>
											<v-spacer />
											<v-btn text @click="addFile()">
												Add Another file/cert<v-icon>mdi-file-plus</v-icon>
											</v-btn>
										</v-row> -->
                    <v-subheader v-if="attachments.length > 0"
                      >Uploaded Files</v-subheader
                    >
                    <v-list-item
                      width="100%"
                      class="mx-0 px-0"
                      v-for="(i, k) in attachments"
                      :key="k"
                    >
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-paperclip</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ i.original_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ i.catagory }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-textarea
                      class="mt-3"
                      label="Additional Information"
                      filled
                      messages="Please list all memberships and accreditations your company currently holds."
                      v-model="additionalInfo"
                    />
                  </v-container>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card flat>
                  <v-card-actions>
                    <v-card-title class="pl-0">EULA</v-card-title>
                    <v-spacer />
                    <v-btn small text @click="step -= 1">
                      <v-icon small>mdi-arrow-left</v-icon>
                      Prev
                    </v-btn>
                  </v-card-actions>
                  <v-card-title>Terms and Conditions</v-card-title>

                  <eula />

                  <div class="ml-3 mt-3">
                    <a
                      href="/pdf/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf"
                      download
                      target="_blank"
                    >
                      <v-icon>mdi-download</v-icon>
                      <span>Download EULA</span>
                    </a>
                  </div>
                  <v-card-actions>
                    <v-spacer />
                    <v-checkbox
                      v-model="company.vendor_eula"
                      label="I accept"
                    />
                    <br />
                  </v-card-actions>
                </v-card>
                <v-card width="100%" flat>
                  <v-card-actions>
                    <v-col>
                      <v-btn block color="error" @click="skip()"
                        >Skip for now</v-btn
                      >
                      <v-btn
                        block
                        :disabled="validated"
                        color="primary"
                        class="mt-2"
                        :loading="_loading"
                        @click="updateCompanyInfo"
                      >
                        Add Vendor Details
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <v-stepper
            v-if="$vuetify.breakpoint.mdAndUp"
            v-model="step"
            non-linear
            class="elevation-0"
            vertical
            tile
            flat
          >
            <v-stepper-step :complete="step > 1" step="1"
              >Abilities</v-stepper-step
            >
            <v-stepper-content step="1">
              <v-container fluid>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(ability, i) in abilities" :key="i">
                        <td class="flex-nowrap">
                          <v-subheader>{{ ability.statement }}</v-subheader>
                        </td>

                        <td>
                          <v-radio-group v-model="ability.able" dense row>
                            <v-radio dense label="No" :value="false"></v-radio>
                            <v-radio :value="true" dense label="Yes"></v-radio>
                          </v-radio-group>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
              <v-spacer />
              <v-btn text class="float-right" @click="step += 1">
                Next
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-stepper-content>
            <v-stepper-step :complete="step > 2" step="2"
              >Document Upload</v-stepper-step
            >
            <v-stepper-content step="2">
              <v-row class="d-flex flex-wrap">
                <v-col>
                  <v-container fluid class="pl-0">
                    <v-subheader class="pl-0 mb-0"
                      >Are you R2 or e-Stewards Certified?</v-subheader
                    >
                    <v-card class="pa-2 mb-2" outlined id="divtestxxx">
                      <v-radio-group
                        v-if="company"
                        class="mt-0"
                        row
                        v-model="r2"
                      >
                        <v-radio
                          class="mt-0"
                          label="Yes"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          class="mt-0"
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                      <v-menu
                        id="menuxxx"
                        ref="menu"
                        v-model="menu"
                        v-if="company"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            id="r2_datexxx"
                            v-model="dateFormatted"
                            :rules="[passRules.required, passRules.dateMax]"
                            :disabled="!r2"
                            ref="dateFormatted"
                            label="When does your certification expire?"
                            prepend-icon="mdi-calendar-month"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="r2_date"
                          :min="maxCertDate"
                          no-title
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-card>

                    <v-subheader class="pl-0 mb-0"
                      >Do you have a Certificate of Resale?</v-subheader
                    >
                    <v-card class="pa-2 mb-2" outlined>
                      <v-radio-group
                        v-if="company"
                        class="mt-0"
                        row
                        v-model="resale"
                      >
                        <v-radio
                          class="mt-0"
                          label="Yes"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          class="mt-0"
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                      <v-file-input
                        v-if="resale"
                        @change="uploadFile($event, 'resale')"
                        outlined
                        @focus="resaleFocused = !resaleFocused"
                        @blur="resaleFocused = !resaleFocused"
                        :loading="resaleFocused ? _uploading : false"
                        dense
                        label="Certificate of Resale"
                        :error-messages="resaleRequired"
                      ></v-file-input>
                    </v-card>

                    <v-file-input
                      @change="uploadFile($event, 'w9')"
                      outlined
                      @focus="w9Focused = !w9Focused"
                      @blur="w9Focused = !w9Focused"
                      :loading="w9Focused ? _uploading : false"
                      dense
                      :error-messages="w9Required"
                      label="W9 Upload"
                    ></v-file-input>
                    <v-file-input
                      @change="uploadFile($event, 'insurance')"
                      outlined
                      dense
                      @focus="insuranceFocused = !insuranceFocused"
                      @blur="insuranceFocused = !insuranceFocused"
                      :loading="insuranceFocused ? _uploading : false"
                      :error-messages="insuranceRequired"
                      label="Insurance Certificate Upload"
                    ></v-file-input>

                    <v-fade-transition>
                      <v-file-input
                        @change="uploadFile($event, 'miscellaneous')"
                        dense
                        v-model="miscFile"
                        outlined
                        ref="misc"
                        :error-messages="certRequired"
                        @focus="miscFocused = !miscFocused"
                        @blur="miscFocused = !miscFocused"
                        :loading="miscFocused ? _uploading : false"
                        messages="Please upload a copy of all certifications your company currently holds."
                        label="Misc. Certification"
                      ></v-file-input>
                    </v-fade-transition>
                    <!-- <v-row no-gutters>
											<v-spacer />
											<v-btn text @click="addFile()">
												Add Another file/cert<v-icon>mdi-file-plus</v-icon>
											</v-btn>
										</v-row> -->
                    <v-subheader v-if="attachments.length > 0"
                      >Uploaded Files</v-subheader
                    >
                    <v-list-item
                      width="100%"
                      class="mx-0 px-0"
                      v-for="(i, k) in attachments"
                      :key="k"
                    >
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-paperclip</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ i.original_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ i.catagory }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-textarea
                      class="mt-3"
                      label="Additional Information"
                      filled
                      messages="Please list all memberships and accreditations your company currently holds."
                      v-model="additionalInfo"
                    />
                  </v-container>
                </v-col>
              </v-row>
              <v-card flat>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small text @click="step -= 1">
                    <v-icon>mdi-arrow-left</v-icon>
                    Prev
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" sm="12" md="6">
          <v-container>
            <v-card flat>
              <v-card-title>Terms and Conditions</v-card-title>
              <eula docType="" :interactable="eulaInteractable" />
              <v-card-actions>
                <v-spacer />
                <v-checkbox v-model="company.vendor_eula" label="I accept" />
                <br />
              </v-card-actions>
            </v-card>
            <v-card width="100%" flat>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="skip">Skip for now</v-btn>
                <v-btn
                  :disabled="validated"
                  :loading="_loading"
                  color="primary"
                  @click="updateCompanyInfo"
                >
                  Add Vendor Details
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import upload from '../../mixins/Upload';
import eula from '@/components/app/TCView.vue';
export default {
  directives: {
    mask
  },
  components: {
    eula
  },
  mixins: [upload],
  data() {
    return {
      certRequired: null,
      insuranceRequired: null,
      w9Required: null,
      resaleRequired: null,
      miscFocused: false,
      w9Focused: false,
      resaleFocused: false,
      insuranceFocused: false,
      insuranceBase64: undefined,
      addCerts: {
        file: undefined,
        type: 'certs'
      },
      addCertsBase64: undefined,
      additionalCerts: [],
      additionalUpBase64: {
        1: undefined
      },
      date: moment()
        .toISOString()
        .substr(1, 'days'),
      acceptTerms: false,
      eula: false,
      disabled: true,
      checkbox: false,
      step: 1,
      row: null,
      r2: false,
      resale: false,
      r2date: moment()
        .subtract(1, 'days')
        .toISOString(),
      menu: false,
      attCount: 0,
      attSendCount: 0,
      attUploadCount: 0,
      additionalInfo: '',
      r2_date: undefined,
      passRules: {
        required: v => !!v || 'Required.',
        confirmMatch: v =>
          this.newUser.password === this.newUser.confirm_pass ||
          'The passwords you entered don’t match',
        length: v =>
          (v && v.length >= 8) ||
          'Passwords must be at least 8 characters in length',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(
            v
          ) || 'Passwords require at least one special character',
        dateMax: v =>
          moment(v).isAfter(moment(), 'day') ||
          'Certification expiration can not be equal to or earlier than todays date.'
      },
      abilities: [
        {
          able: undefined,
          statement:
            'Are you able to perform an equipment inventory at a customer site?'
        },
        {
          able: undefined,
          statement:
            'Are you able to perform hard drive destruction at a customer site?'
        },
        {
          able: undefined,
          statement:
            'Are you able to pack and prep equipment for pickup at a customer site?'
        },
        {
          able: undefined,
          statement:
            'Do you utilize your own fleet for pickup at a customer site?'
        },
        {
          able: undefined,
          statement:
            'Are you able to perform decommission services at a customer site?'
        }
      ]
    };
  },
  computed: {
    company: {
      get() {
        return this._company;
      },
      set(newVal) {
        this._setCompany(newVal);
      }
    },
    maxCertDate() {
      return moment()
        .subtract(1, 'days')
        .toISOString();
    },

    dateFormatted: {
      get() {
        if (this.r2 && this.r2_date) {
          return moment(this.r2_date).format('MM/DD/YY');
        } else {
          return moment()
            .subtract(1, 'days')
            .format('MM/DD/YY');
        }
      },
      set(value) {
        let date = moment(value).toISOString();
        this.r2_date = date;
      }
    },
    beforeToday() {
      return moment(this.dateFormatted).isBefore(moment(), 'day');
    },
    validated() {
      if (this.company.vendor_eula) {
        return false;
      }
      return true;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    ...mapGetters({
      _company_id: 'company/getCompanyId',
      _file: 'uploads/getFile',
      _loading: 'company/getLoading',
      _uploading: 'uploads/loading',
      _company: 'company/getCompany',
      _loggedInUser: 'user/getLoggedInUser'
    }),
    eulaInteractable() {
      return this._loggedInUser?.type_id === 1;
    }
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    attachments() {
      this.resetDocRequiredMessages();
      this.checkIfRequiredDocsUploaded();
    },
    r2() {
      this.resetDocRequiredMessages();
    }
    // _file: {
    // 	handler: function(val) {
    // 		if (this._file.type === "w9") {
    // 			this.w9Base64 = this._file.file;
    // 		} else if (this._file.type === "insurance") {
    // 			this.insuranceBase64 = this._file.file;
    // 		} else if (this._file.type === "certs") {
    // 			this.addCertsBase64 = this._file.file;
    // 		} else if (this._file.type === "additional") {
    // 			this.additionalUpBase64[this.attUploadCount] = this._file.file;
    // 			this.attUploadCount++;
    // 		}
    // 	},
    // 	deep: true,
    // },
  },
  created() {},
  mounted() {
    const { vendor_abilities = [], r2_cert = {}, attachments = [], resale } =
      this.company || {};
    const { cert, date } = r2_cert;

    this.resale = !!resale;

    if (cert) {
      this.r2 = cert;
    }

    if (date) {
      this.r2_date = date;
    }

    if (vendor_abilities.length > 0) {
      this.abilities = vendor_abilities;
    }

    if (attachments.length > 0) {
      this.attachments = attachments;
    }
  },
  methods: {
    ...mapActions({
      _updateCompany: 'company/updateCompany',
      _uploadFile: 'uploads/uploadFile'
    }),
    ...mapMutations({
      _setCompany: 'company/setCompany'
    }),
    skip() {
      if (this.$route.query.new || window.history.length <= 2) {
        this.$router.push('/profile');
      } else {
        this.$router.go(-1);
      }
    },
    checkIfRequiredDocsUploaded() {
      const w9Uploaded = this.checkIfAttachmentUploaded('w9');
      const insuranceUploaded = this.checkIfAttachmentUploaded('insurance');
      const certUploaded = this.checkIfAttachmentUploaded('miscellaneous');
      const resaleUploaded = this.checkIfAttachmentUploaded('resale');

      this.w9Required = w9Uploaded ? null : 'Required.';
      this.insuranceRequired = insuranceUploaded ? null : 'Required.';
      this.certRequired = this.r2 && !certUploaded ? 'Required.' : null;
      this.resaleRequired = this.resale && !resaleUploaded ? 'Required.' : null;

      if (this.resale && !resaleUploaded) {
        return false;
      }

      return this.r2
        ? insuranceUploaded && w9Uploaded && certUploaded
        : insuranceUploaded && w9Uploaded;
    },
    checkIfAttachmentUploaded(type) {
      if (!Array.isArray(this.attachments) || this.attachments.length <= 0) {
        return false;
      }

      let found = false;
      this.attachments.map(v => {
        if (v.catagory === type) {
          found = true;
        }
      });
      return found;
    },
    resetDocRequiredMessages() {
      this.certRequired = this.insuranceRequired = this.w9Required = this.resaleRequired = null;
    },
    updateCompanyInfo() {
      const docsUploaded = this.checkIfRequiredDocsUploaded();
      if (!docsUploaded) {
        if (this.step !== 2) {
          this.step = 2;
        }
        return;
      }

      if (this.r2) {
        if (!this.$refs.dateFormatted.validate()) {
          if (this.step !== 2) {
            this.step = 2;
          }
          //this.dateFormatted=this.dateFormatted;
          // $('div#divtestxxx.pa-2.mb-2.v-card.v-sheet.v-sheet--outlined.theme--light div.v-input.v-input--has-state.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.error--text div.v-input__control div.v-text-field__details div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message').show();
          // const isValid = this.$refs.form.validate();
          //  console.log("isValid",isValid);
          return;
        }
      }

      this.company.resale = this.resale
        ? this.resale
        : this.checkIfAttachmentUploaded('resale');

      this.certRequired = null;
      this.company.attachments = this.attachments;
      this.company.additional_info = this.additionalInfo.toLowerCase();
      this.company.vendor_abilities = this.abilities;
      this.company.r2_cert = { cert: this.r2, date: this.r2_date };

      return this._updateCompany(this.company).then(() => {
        if (this.$route.query.new || window.history.length <= 2) {
          this.$router.push('/profile');
        } else {
          this.$router.go(-1);
        }
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.eula {
  overflow-y: scroll;
}

a {
  text-decoration: none;
}
</style>
