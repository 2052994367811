<style>
.wrapper {
  text-align: center;
  border-style: solid;
  border-color: #ffff;
}

.btn-discard {
  color: #2e384e;
  border: solid 1px #2e384e;
}

.btn-save-draft {
  color: white !important;
  background-color: #2e384e !important;
}

.title {
  color: #0091ff;
  text-transform: uppercase;
}

.content {
  text-align: center;
}
</style>
<template>
  <v-dialog
    persistent
    @click:outside="closePopup"
    v-model="isShow"
    max-width="400"
  >
    <v-card class="pb-4 wrapper">
      <v-card-title class="pr-0 pl-6">
        <div style="float:left;width:33%">&nbsp;</div>
        <div style="float:left;width:33%">
          <img :src="require('@/assets/warning.png')" />
        </div>
      </v-card-title>
      <v-card class="pa-1" flat>
        <h2 class="title">UNSAVED CHANGES</h2>
        <div class="content">
          <v-card-text>
            You are leaving the job form with unsaved changes.
          </v-card-text>
        </div>
      </v-card>
      <v-card-actions flat>
        <v-row no-gutters class="mt-4 ma-4">
          <v-btn rounded class="btn-discard" @click="handleDiscardChange()">
            Discard Changes
          </v-btn>
          <v-spacer />
          <v-btn
            rounded
            class="btn-save-draft"
            :loading="isSaveAsDraftLoading"
            @click="handleSaveAsDraft()"
          >
            Save Draft
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  props: {
    isShow: Boolean,
    saveAsDraft: Function,
    discardChanged: Function
  },
  data: function() {
    return {
      isSaveAsDraftLoading: false
    };
  },
  watch: {
    isShow(newVal, oldVal) {
      this.isShowPopup = newVal;
    }
  },
  methods: {
    ...mapMutations({
      _setActiveWarningDialog: 'jobs/setShowWarningUnsavedChange',
      _setActiveEditForm: 'jobs/setEditNewJob',
      _setJob: 'jobs/setCurrentJob'
    }),
    ...mapActions({
      _close: 'jobs/updateJob'
    }),
    closePopup() {
      //this.isShow = false
    },
    handleSaveAsDraft() {
      this.isSaveAsDraftLoading = true;
      this.saveAsDraft();
    },
    handleDiscardChange() {
      this.discardChanged();
    }
  }
};
</script>
