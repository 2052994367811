<template>
  <v-dialog v-model="_active" @click:outside="closeNewJob" width="500">
    <v-card
      class="pa-1"
      flat
      v-if="company_status == 'On Hold'"
      style="text-align:center;"
    >
      <v-card-title class="pr-0 pl-6">
        <div style="float:left;width:33%">&nbsp;</div>
        <div style="float:left;width:33%">
          <img :src="require('@/assets/warning.png')" />
        </div>
        <div style="float:left;width:33%;text-align:right">
          <v-btn
            style="-webkit-box-shadow: none;box-shadow: none;background:white;border:0px "
            id="btnClose"
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <h2 style="color:#0091ff;text-transform: uppercase;">Account On Hold</h2>
      <div style="text-align:left;padding:5px">
        Your company account is on hold. Your access is restricted to jobs
        already in progress and your job history.
        <br />
        Please contact info@recyclegx.com with any questions or for assistance.
        <br />
        <br />
      </div>
    </v-card>
    <v-card class="pb-4" v-if="company_status != 'On Hold'">
      <v-card-title class="pr-0 pl-6">
        New Job Pre-Check
        <v-spacer />
        <v-btn text @click="closeNewJob">
          close
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card class="pa-4" flat>
        <v-item-group>
          <v-col cols="12" sm="12">
            <v-form v-model="falsies" ref="falsies">
              <v-checkbox
                v-for="(q, i) in questions"
                :key="i"
                @change="resetFinal"
                v-model="q.answer"
                :label="q.question"
              ></v-checkbox>
            </v-form>
            <v-checkbox
              v-model="final.answer"
              :label="final.question"
              @change="resetOthers()"
            ></v-checkbox>
          </v-col>
        </v-item-group>
      </v-card>
      <v-card-actions>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-btn
          color="secondary"
          :block="!$vuetify.breakpoint.mdAndUp"
          :disabled="!valid"
          @click="createNewJob"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      company_status: '',
      falsies: false,
      questions: [
        {
          question:
            'I do NOT have an inventory of the equipment for this job and require an on-site scan and report.',
          answer: false
        },
        {
          question:
            'I am NOT able to pack and prepare the equipment for pickup and require assistance.',
          answer: false
        },
        {
          question:
            'I cannot allow the Hard Drives or other Data Bearing devices to be processed off-site and require on-site destruction.',
          answer: false
        },
        {
          question:
            'The equipment is still racked and tethered. I require assistance with turn-down and removal.',
          answer: false
        }
      ],
      final: {
        question: 'None of the above apply, let me continue!',
        answer: false
      }
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      _active: 'jobs/getJobPreCheck',
      _company: 'company/getCompany'
    }),
    valid() {
      if (this.final.answer) {
        return true;
      } else {
        for (let i = 0; i < this.questions.length; i++) {
          if (this.questions[i].answer === true) {
            return true;
          }
        }
        return false;
      }
    },
    canCreateJob() {
      if (this.q1 === 2 && this.q2 === 2 && this.q3 === 2 && this.q4 === 2) {
        return true;
      } else {
        return false;
      }
    },
    payload() {
      return {
        needInventory: this.questions[0].answer,
        needPacking: this.questions[1].answer,
        needOnSite: this.questions[2].answer,
        needRemoval: this.questions[3].answer
      };
    }
  },
  methods: {
    ...mapMutations({
      _setActive: 'jobs/setJobPreCheck',
      _setNewJob: 'jobs/setCreateNewJob',
      _setGreenGloveAlert: 'jobs/setGreenGloveAlert'
    }),
    ...mapActions({
      _sendGreenGloveEmail: 'jobs/sendGreenGloveEmail'
    }),
    resetFinal() {
      this.final.answer = false;
    },
    resetOthers() {
      this.questions.forEach(i => {
        i.answer = false;
      });
    },
    closeNewJob() {
      if (this.company_status == 'On Hold') {
        window.location.reload(true);
        // window.location.href = window.location.href;
        return;
      }
      this.$refs.falsies.reset();
      this.final.answer = false;
      this._setActive(false);
    },
    createNewJob() {
      if (this.final.answer) {
        this._setNewJob(true);
      } else {
        this._setGreenGloveAlert(true);
        this._sendGreenGloveEmail(this.payload);
      }
      this.$refs.falsies.reset();
      this.final.answer = false;
      this._setActive(false);
    }
  },
  watch: {
    _active() {
      //console.log("this._company.status",this._company.status);
      this.company_status = this._company.status;
    }
  }
};
</script>

<style>
.flex-row {
  display: flex;
}

.question-border {
  border-bottom: 1px solid grey;
}

.pre-check-container {
  padding: 0 10%;
}

.radio-buttons {
  transform: scale(0.6);
  height: 10px;
}

.question {
  padding-left: 0;
  align-self: center;
}

.justify-end {
  display: flex;
  justify-items: end;
}
</style>
