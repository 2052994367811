<template>
  <div>
    <v-dialog
      @click:outside="close()"
      v-model="_active"
      max-width="700"
      v-if="_job"
    >
      <v-card v-if="_job && !jobClosed" flat class="pt-0 pb-4">
        <div class="btn-dismiss-dialog">
          <v-btn text @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div ref="closingReceipt">
          <v-row no-gutters class="pa-2">
            <v-img
              contain
              max-width="150px"
              :src="require('@/assets/rgx-black-lockup-1.png')"
            />
            <v-spacer />
            <p class="secondary--text text-center confirmation pt-10">
              Confirmation
            </p>
          </v-row>
          <v-divider />
          <v-row no-gutters justify="center">
            <v-col cols="12" md="11">
              <section class="d-flex justify-space-between align-center">
                <v-toolbar-title style="font-weight: 500">
                  Job Final Details
                </v-toolbar-title>
                <div>
                  <v-card-subtitle class="subtitle-2 pb-0 pl-0">
                    Close Date:
                  </v-card-subtitle>
                  <v-card-text class="text-font py-0 pl-0">
                    <v-icon color="primary">mdi-calendar-blank</v-icon>
                    {{ date }}
                  </v-card-text>
                </div>
              </section>

              <v-row no-gutters justify="center" class="pa-2">
                <v-col cols="12" md="11">
                  <v-card-subtitle class="subtitle-3">
                    Job Name: {{ _job.name }}
                  </v-card-subtitle>
                  <!-- <v-card-subtitle class="subtitle-3">
                    Job #:
                    <span style="padding-left: 30px">{{ _job.number }}</span>
                  </v-card-subtitle> -->

                  <v-card-subtitle class="subtitle-3">
                    Job #:
                    <span style="padding-left: 30px">{{ _job.job_id }}</span>
                  </v-card-subtitle>

                  <v-card-subtitle class="subtitle-3 pb-0 pl-0">
                    {{
                      _job.equipment_transfer_logistics == 1
                        ? 'Pickup Date *:'
                        : 'Drop-Off Date *:'
                    }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-3 py-0 pl-0">
                    <v-icon color="primary">mdi-calendar-blank</v-icon>
                    {{ formDate(_job.actual_pickup_date) }}
                  </v-card-subtitle>

                  <v-card-subtitle class="subtitle-3 pb-0 pl-0">
                    Destruction Date *:
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-3 py-0 pl-0">
                    <v-icon color="primary">mdi-calendar-blank</v-icon>
                    {{ formDate(job.destruction_date) }}
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="11">
              <job-closing-total />
            </v-col>

            <v-col cols="12" md="10">
              <tc @accepted="accepted = !accepted" class="ma-2" />
            </v-col>
          </v-row>
        </div>

        <v-card-actions>
          <v-row no-gutters>
            <v-btn text @click="downloadWithCSS">
              <v-icon color="primary">mdi-download</v-icon>
              Confirmation PDF
            </v-btn>
            <v-spacer />
            <v-btn
              color="secondary"
              :loading="_loading"
              :disabled="jobClosed || !accepted"
              @click="confirm()"
            >
              CONFIRM AND CLOSE
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JobClosingTotal from '@/components/jobs/VendorJobClosingTotal.vue';
import tc from '@/components/app/Terms&Conditions.vue';
export default {
  components: {
    tc,
    JobClosingTotal
  },
  data() {
    return {
      jobClosed: false,
      accepted: false,
      time: moment().format('H:mm A'),
      date: moment().format('M/DD/YYYY'),
      closing: false
    };
  },
  watch: {
    job() {
      if (!this.job) this._setJob(this.$route.params.id);
      if (this.job.status === 'closing') {
        this.closing = true;
      }
      console.log('JobClosingConf this.job', this.job);
    }
  },
  computed: {
    ...mapGetters({
      _active: 'jobs/getClosingConf',
      _job: 'jobs/getCurrentJob',
      _loading: 'jobs/loading',
      _bid: 'bids/getBid'
    }),
    total() {
      var x;
      if (this._job.resale_offer && this._job.bid_per_pound === null) {
        /* x = this._job.resale_offer
          ? Number(this._job.bid_buy_out) +
            Number(this._job.resale_offer.replace(/,/g, ""))
          : this._job.bid_buy_out; */
        x = this._job.resale_offer
          ? Number(this._job.bid_buy_out) -
            Number(this._job.resale_offer.replace(/,/g, ''))
          : this._job.bid_buy_out;
        // console.log("DEBUG: vo if, x=" + x);
        return x ? x.toFixed(2) : '0.00';
      } else if (this._job && this._job.resale_offer) {
        x = this._job.resale_offer
          ? Number(this.offerValue) +
            Number(this._job.resale_offer.replace(/,/g, ''))
          : this.offerValue;
        // console.log("DEBUG: vo if-else, x=" + x);
        return x ? x.toFixed(2) : '0.00';
      } else if (this._job && this._job.bid_per_pound) {
        x = this._job.final_weight * this._job.bid_per_pound;
        // console.log("DEBUG: vo if-else 2, x=" + x);
        return x ? x.toFixed(2) : '0.00';
      } else if (this._job.bid_buy_out && this._job.resale_offer === null) {
        x = Number(this._job.bid_buy_out);
        // console.log("DEBUG: vo if-else 3, x=" + x);
        return x ? x.toFixed(2) : '0.00';
      } else {
        // console.log("DEBUG: vo else, x=" + x);
        return '0.00';
      }
    },
    offerValue() {
      let price = this._job.final_weight * this._job.bid_per_pound;
      return this._job.final_weight !== undefined ? price.toFixed(2) : 0.0;
    },
    job: {
      set() {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    }
  },
  methods: {
    ...mapActions({
      _uploadFile: 'uploads/uploadFile',
      _updateJob: 'jobs/updateJob',
      _setJob: 'jobs/setJob',
      _getClosingDocs: 'jobs/getClosingDocs',
      _updateBid: 'bids/updateBid',
      _sendGridMail: 'mail/sendGridMail'
    }),
    ...mapMutations({
      _setClosingConf: 'jobs/setClosingConf',
      _setShowCloseOutDialog: 'jobs/setShowCloseOutDialog'
    }),
    getClosingDocs() {
      this._getClosingDocs(this._job);
    },
    downloadWithCSS() {
      const doc = new jsPDF();

      html2canvas(this.$refs.closingReceipt, {
        scale: 0.9
      }).then(canvas => {
        const img = canvas.toDataURL('image/jpeg', 1);
        doc.addImage(img, 'JPEG', 30, 30);
        doc.save(this.job.name + '-(certificate of destruction).pdf');
      });
    },
    formDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    close() {
      this._setClosingConf(false);
    },
    async confirm() {
      let job = {};
      job.id = this.job.job_id;
      job.status = this._job.recycle_specs.resale ? 'resale' : 'closing';
      job.final_weight = this.job.final_weight;
      job.destruction_date = this.job.destruction_date;
      // job.end_date = this.job.end_date;
      job.actual_pickup_date = this.job.actual_pickup_date;
      job.resale_offer = this.job.resale_offer;
      job.has_purchase_equipment = this.job.has_purchase_equipment;

      if (job.has_purchase_equipment === false) {
        job.resale_offer = null;

        if (Array.isArray(job.attachments)) {
          job.attachments = job.attachments.filter(
            item => item.catagory !== 'purchase_inventory'
          );
        }
      }

      job.additional_scrap_proceeds = this.job.additional_scrap_proceeds
        ? parseFloat(this.job.additional_scrap_proceeds.replace(/,/g, ''))
        : null;
      job.attachments = this.job.attachments;

      const updates = [this._updateJob(job)];

      if (this.job.current_bid_buy_out) {
        const options = {
          id: this.job.bid_id,
          current_bid_buy_out: this.job.current_bid_buy_out
        };

        updates.push(this._updateBid(options));
      }
      if (this.job.created_email) {
        this.job.type = 'job_ready_to_close';
        this.job.to_email =this.job.created_email;
        
        this.job.job_name = this.job.name;
        this.job.customer_name = this.job.company_name;
        this.job.ready_to_close_date = new Date().toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });

        updates.push(this._sendGridMail(this.job));
      
       if(this.job.project_manager_email != null &&  this.job.created_email!= this.job.project_manager_email) 
       {
        this.job.to_email=this.job.project_manager_email;
        updates.push(this._sendGridMail(this.job));
       }
           
      }
      //console.log("send mail job_ready_to_close",this.job);

      await Promise.all(updates);

      this._setClosingConf(false);
      this._setShowCloseOutDialog(false);
    }
  }
};
</script>
<style lang="scss" scoped>
.confirmation {
  font-size: 26px;
  font-weight: bold;
  color: #4caf50;
}
.subtitle-1 {
  font-weight: 500;
  font-size: 1.3em !important;
  padding-left: 0;
  padding-bottom: 0;
}

.subtitle-3 {
  padding-left: 0;
  padding-top: 5px;
  padding-bottom: 0;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}

.v-toolbar__title {
  padding-left: 24px;
}
.v-toolbar__content {
  padding: 0;
}
</style>
