<template>
  <div class="d-flex flex-column">
    <v-row no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.smAndUp ? 2 : 12"
        class=" d-flex align-center section-title"
        >Company Documents</v-col
      >
      <v-col
        :cols="
          $vuetify.breakpoint.mdAndUp
            ? 8
            : $vuetify.breakpoint.smAndUp
            ? 10
            : 12
        "
        class="d-flex align-center"
        :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-start'"
      >
        <v-btn
          rounded
          color="secondary"
          @click="editAttachments = !editAttachments"
          v-if="edit"
          small
        >
          <v-icon>mdi-upload</v-icon>
          Upload
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 0" />
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12">
        <v-card width="100%" outlined style="border: 1.5px solid #dee0e4;">
          <!-- <v-btn
          @click="editAttachments = !editAttachments"
          small
          v-if="edit"
          absolute
          right
          top
          icon
          class="ma-0 pa-0"
          ><v-icon>mdi-upload</v-icon></v-btn
        > -->
          <div v-if="!_company.attachments?.length">
            <v-card-text>No documents</v-card-text>
          </div>
          <div v-else>
            <v-card max-height="200" style="overflow-x: auto" flat>
              <v-list-item
                class="d-flex flex-wrap"
                v-for="(attachment, index) in _company.attachments"
                :key="index"
              >
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                  <v-icon color="secondary" v-if="attachment.location"
                    >mdi-paperclip</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    attachment.original_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    mapCategory(attachment.catagory)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row no-gutters>
                    <v-btn
                      icon
                      :href="attachment.location"
                      :download="attachment.original_name"
                      :small="$vuetify.breakpoint.smAndDown"
                      ><v-icon color="secondary">mdi-download</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="showGoogleDocIndex(index)"
                      :small="$vuetify.breakpoint.smAndDown"
                    >
                      <v-icon color="secondary">mdi-file-find</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      v-if="edit"
                      @click.stop="removeAtt(index)"
                      :small="$vuetify.breakpoint.smAndDown"
                      ><v-icon color="error">mdi-trash-can</v-icon></v-btn
                    >
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </div>
          <v-dialog max-width="400" v-model="editAttachments">
            <v-card flat class="pa-4">
              <v-card-title class="mb-2">Upload New Files</v-card-title>
              <v-file-input
                @change="uploadFile($event, 'w9')"
                outlined
                @focus="w9Focused = !w9Focused"
                @blur="w9Focused = !w9Focused"
                :loading="w9Focused ? _uploading : false"
                dense
                label="W9 Upload"
              ></v-file-input>
              <v-file-input
                @change="uploadFile($event, 'insurance')"
                outlined
                dense
                @focus="insuranceFocused = !insuranceFocused"
                @blur="insuranceFocused = !insuranceFocused"
                :loading="insuranceFocused ? _uploading : false"
                label="Insurance Certificate Upload"
              ></v-file-input>

              <v-fade-transition>
                <v-file-input
                  @change="uploadFile($event, 'miscellaneous')"
                  dense
                  outlined
                  ref="misc"
                  @focus="miscFocused = !miscFocused"
                  @blur="miscFocused = !miscFocused"
                  :loading="miscFocused ? _uploading : false"
                  messages="Upload as many files as you want with this box, just one at a time please. The uploaded files will populate in a list below."
                  label="Misc. Certification"
                ></v-file-input>
              </v-fade-transition>
              <!-- <v-row no-gutters>
                    <v-spacer />
                    <v-btn text @click="addFile()">
                      Add Another file/cert<v-icon>mdi-file-plus</v-icon>
                    </v-btn>
                  </v-row> -->
              <v-subheader
                v-if="_company.attachments && _company.attachments.length > 0"
                >Uploaded Files</v-subheader
              >
              <v-card max-height="200" style="overflow-x: auto" outlined>
                <v-list-item
                  width="100%"
                  class="mx-0 px-0"
                  v-for="(i, k) in _company.attachments"
                  :key="k"
                >
                  <v-list-item-icon>
                    <v-icon color="secondary">mdi-paperclip</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ i.original_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ mapCategory(i.catagory) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-card-actions>
                <v-btn block @click="editAttachments = false" color="secondary"
                  >Done</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
    <google-doc />
  </div>
  <!-- </v-row> -->
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import GoogleDoc from '@/components/app/GoogleDoc.vue'; //huy added

export default {
  components: {
    GoogleDoc
  },
  props: {
    edit: Boolean
  },
  data() {
    return {
      miscFocused: false,
      w9Focused: false,
      insuranceFocused: false,
      editAttachments: false,
      fileUploading: {}
    };
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany',
      _file: 'uploads/getFile',
      _uploading: 'uploads/loading'
    }),
    company: {
      set(newVal) {
        this._setCompany(newVal);
      },
      get() {
        return this._company;
      }
    }
  },
  watch: {
    _file() {
      if (!this.company.attachments) {
        this.company.attachments = [];
      }
      if (Array.isArray(this.company.attachments)) {
        this.company.attachments = this.company.attachments.filter(
          att => att.catagory
        );
      }

      if (
        this._file.file.Key &&
        this.fileUploading &&
        this.fileUploading.name
      ) {
        this.company.attachments.push({
          key: this._file.file.Key,
          location: this._file.file.Location,
          file_type: this.fileUploading.type,
          catagory: this.fileUploading.catagory,
          original_name: this.fileUploading.name
        });
      }
    }
  },
  methods: {
    ...mapActions({
      _uploadFile: 'uploads/uploadFile'
    }),
    ...mapMutations({
      _setCompany: 'company/setCompany',
      _setGoogleDoc: 'googledoc/setShowGoogleDocDialog',
      _setIndex: 'googledoc/setIndex',
      _setList: 'googledoc/setList'
    }),
    removeAtt(index) {
      this.company.attachments.splice(index, 1);
      this.$emit('remove');
    },
    uploadFile(e, type) {
      if (e && e.name) {
        this.fileUploading.file = e;
        this.fileUploading.name = e.name;
        this.fileUploading.catagory = type;
        this.fileUploading.type = e.type;
        let file = {
          file: e,
          original_name: e.name,
          type: type
        };
        this._uploadFile(file);
      } else if (!e) {
        return;
      }
    },
    mapCategory(category) {
      if (!category) {
        return;
      }
      switch (category) {
        case 'resale':
          return 'Certificate of Resale';
        default:
          return category.charAt(0).toUpperCase() + category.slice(1);
      }
    },
    showGoogleDocIndex(index) {
      this._setList(this._company.attachments);
      this._setIndex(index);
      this._setGoogleDoc(true);
    }
  }
};
</script>
<style scoped>
.text-h6 {
  font-size: 1.1rem !important;
}
</style>
