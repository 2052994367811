<template>
  <v-dialog v-model="_active" @click:outside="close()" width="400" v-if="_job">
    <v-card>
      <v-card-title class="primary--text pt-4">
        {{ _job.status === 'dispute' ? `Revert Job Dispute?` : `Dispute Job?` }}
      </v-card-title>
      <v-card-text>
        {{
          _job.status === 'dispute'
            ? `This job has been disputed. You may un dispute this job yourself at any time. This action will set the job back to 'Open' status. Any awarded bids will need to be re-awarded.`
            : `You may dispute a job at any time. Once you have submitted the dispute, an RGX will contasct you as soon as possible to resolve your problem.`
        }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          v-if="!confirmed"
          color="error"
          class="mr-2 elevation-0 mb-2"
          dark
          @click="_setActive(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          rounded
          v-if="_job.status !== 'dispute' && 1 > 2"
          color="secondary"
          class="mr-2 elevation-0 mb-2"
          :loading="_loading"
          dark
          @click="confirm()"
        >
          Dispute Job
        </v-btn>
        <v-btn
          rounded
          v-if="_job.status === 'dispute'"
          color="secondary"
          class="mr-2 elevation-0 mb-2"
          :loading="_loading"
          dark
          @click="confirm()"
        >
          Un-Dispute Job
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- <v-card v-else-if="_job.status && !_loading">
			<v-card-title class="primary--text pt-4">
				{{
					_job.status !== "dispute" ? `Job Dispute Reverted` : `Job Disputed`
				}}
			</v-card-title>
			<v-card-text>
				{{
					_job.status !== "dispute"
						? `This job has been reverted to 'Open' status, any bid that was previously awarded will need to be awarded again.`
						: `This job has been disputed. An RGX representative will be in touch shortly.`
				}}
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn
					rounded
					v-if="confirmed"
					color="secondary"
					class="mr-2 elevation-0 mb-2"
					dark
					@click="close()"
					>Close</v-btn
				>
				<v-spacer v-if="confirmed" />
			</v-card-actions>
		</v-card> -->
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  data() {
    return {
      confirmed: false
    };
  },
  methods: {
    ...mapMutations({
      _setActive: 'jobs/setDisputeConfirm',
      _setJob: 'jobs/setCurrentJob'
    }),
    ...mapActions({
      _updateBid: 'bids/updateBid',
      _dispute: 'jobs/updateJob'
    }),
    confirm() {
      if (this._job.status !== 'dispute') {
        var payload = {
          status: 'dispute',
          bid_status: 'dispute',
          id: this.job.job_id
        };
        if (this._job.bid_id) {
          var options = {
            id: this._job.bid_id,
            status: 'open'
          };

          this._dispute(payload).then(() => {
            this._updateBid(options).then(() => {
              this._setActive(false);
            });
          });
        } else {
          this._dispute(payload).then(() => {
            this._setActive(false);
          });
        }
      } else {
        let payload = {
          status: 'open',
          bid_status: 'open',
          id: this.job.job_id
        };
        this._dispute(payload).then(() => {
          this._setActive(false);
        });
      }
    },
    close() {
      setTimeout(() => {
        this.confirmed = false;
      }, 1000);
    }
  },
  computed: {
    ...mapGetters({
      _loading: 'jobs/loading',
      _active: 'jobs/getDisputeConfirm',
      _job: 'jobs/getCurrentJob'
    }),
    job: {
      get() {
        return this._job;
      },
      set(newVal) {
        this._setJob(newVal);
      }
    }
  }
};
</script>
