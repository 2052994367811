<template>
	<div style="height: 80vh">
		<v-container class="fill-height" fluid>
			<v-row align="center" justify="center" style="height: 100%;" no-gutters>
				<v-card max-width="400" class="mx-auto">
					<v-card tile flat color="" class="pa-5">
						<v-img
							width="250"
							class="mx-auto"
							:src="require('@/assets/rgx-black-lockup-1.png')"
						/>
					</v-card>

					<!-- <v-card flat color="primary" tile height="10px"> </v-card> -->
					<v-card flat class="pa-4">
						<v-card-title>Enter New Password</v-card-title>
						<v-card-subtitle class="mt-2">
							Hint: The password should be at least eight characters long and
							must include at least one special character.(! " ? $ % ^ & ).
						</v-card-subtitle>
						<v-form v-model="resetValid">
							<v-text-field
								v-model="password"
								:rules="[
									passRules.required,
									passRules.length,
									passRules.specialChar,
								]"
								:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show1 ? 'text' : 'password'"
								label="Password"
								prepend-icon="mdi-lock"
								@click:append="show1 = !show1"
								loading
								ref="passwordReset"
							>
								<template v-slot:progress>
									<v-progress-linear
										v-if="validProg"
										:value="progress"
										:color="color"
										absolute
										height="7"
									></v-progress-linear>
								</template>
							</v-text-field>
							<v-text-field
								v-model="confirmPass"
								:rules="[
									passRules.required,
									passRules.length,
									passRules.confirmMatch,
								]"
								ref="confPasswordReset"
								:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
								:type="show2 ? 'text' : 'password'"
								label="Confirm Password"
								prepend-icon="mdi-lock"
								required
								@click:append="show2 = !show2"
							></v-text-field>
						</v-form>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								rounded
								@click="setNewPassword()"
								:loading="_loading"
								:disabled="!resetValid"
								>Submit</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-card>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
	data() {
		return {
			resetValid: false,
			show1: false,
			show2: false,
			validProg: true,
			password: "",
			confirmPass: "",
			passRules: {
				required: (v) => !!v || "Required.",
				confirmMatch: () =>
					this.password === this.confirmPass ||
					"The passwords you entered don't match",
				length: (v) =>
					(v && v.length >= 8) ||
					"Passwords must be at least 8 characters in length",
				specialChar: (v) =>
					new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g).test(
						v
					) || "Passwords require at least one special character",
			},
		};
	},
	computed: {
		...mapGetters({
			_loading: "authenticate/getLoading",
		}),
		code() {
			return this.$route.params.code;
		},
		progress() {
			return Math.min(100, this.password.length * 12.5);
		},
		submitDisabled() {
			if (
				!!this.password &&
				!!this.confirmPass &&
				this.confirmPass === this.password
			) {
				return false;
			} else {
				return true;
			}
		},
		color() {
			return ["error", "warning", "success"][Math.floor(this.progress / 40)];
		},
	},
	methods: {
		...mapActions({
			_setNewPassword: "authenticate/setNewPassword",
		}),
		setNewPassword() {
			this._setNewPassword({
				password: this.password,
				confirmation: this.code,
			});
		},
	},
};
</script>
