<template>
  <v-card class="pa-4" flat>
    <v-row no-gutters>
      <v-spacer />
      <v-btn
        rounded
        height="48"
        color="secondary"
        v-if="_user && _user.type_id === 1 && $vuetify.breakpoint.mdAndUp"
        @click.native="addUser()"
      >
        <v-icon>mdi-account-plus</v-icon>Add Team Member
      </v-btn>
      <v-btn
        fab
        top
        right
        absolute
        color="secondary"
        class="my-2"
        v-if="_user && _user.type_id === 1 && $vuetify.breakpoint.smAndDown"
        @click.native="addUser()"
      >
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
    </v-row>
    <team-table tableTitle="Team List" @edit="editUser($event)" />
    <create-user
      :create="createUser"
      :add="addNewUser"
      :editMe="edit"
      :editUser="editableUser"
      @close="closeCreate()"
    />
  </v-card>
</template>
<script>
import TeamTable from "@/components/team/Table.vue";
import CreateUser from "@/components/team/CreateUser.vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  components: {
    TeamTable,
    CreateUser,
  },
  data() {
    return {
      createUser: false,
      edit: false,
      editableUser: undefined,
      addNewUser: false,
    };
  },
  computed: {
    ...mapGetters({
      _user: "user/getUser",
    }),
  },
  methods: {
    ...mapActions({
      _getUsers: "user/getAll",
    }),
    ...mapMutations({
      _toggleCreate: "user/toggleCreate",
    }),
    addUser() {
      this._toggleCreate(true);
      this.addNewUser = true;
    },
    editUser(e) {
      this.edit = true;
      this.editableUser = e;
    },
    closeCreate() {
      // this._getUsers();
      this.edit = false;
    },
  },
};
</script>
