var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"80vh"}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400"}},[_c('v-card',{staticClass:"pa-5",attrs:{"tile":"","flat":"","color":""}},[_c('v-img',{staticClass:"mx-auto",attrs:{"width":"250","src":require('@/assets/rgx-black-lockup-1.png')}})],1),_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Enter New Password")]),_c('v-card-subtitle',{staticClass:"mt-2"},[_vm._v(" Hint: The password should be at least eight characters long and must include at least one special character.(! \" ? $ % ^ & ). ")]),_c('v-form',{model:{value:(_vm.resetValid),callback:function ($$v) {_vm.resetValid=$$v},expression:"resetValid"}},[_c('v-text-field',{ref:"passwordReset",attrs:{"rules":[
								_vm.passRules.required,
								_vm.passRules.length,
								_vm.passRules.specialChar,
							],"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"label":"Password","prepend-icon":"mdi-lock","loading":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [(_vm.validProg)?_c('v-progress-linear',{attrs:{"value":_vm.progress,"color":_vm.color,"absolute":"","height":"7"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{ref:"confPasswordReset",attrs:{"rules":[
								_vm.passRules.required,
								_vm.passRules.length,
								_vm.passRules.confirmMatch,
							],"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"label":"Confirm Password","prepend-icon":"mdi-lock","required":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.confirmPass),callback:function ($$v) {_vm.confirmPass=$$v},expression:"confirmPass"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","rounded":"","loading":_vm._loading,"disabled":!_vm.resetValid},on:{"click":function($event){return _vm.setNewPassword()}}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }