import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./_store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import Sortable from "vue-sortable";
import VuePlaceAutocomplete from "vue-place-autocomplete";
import VueGooglePlaces from "vue-google-places";
import money from "v-money";
import VueApexCharts from "vue-apexcharts";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
// Import Bootstrap and BootstrapVue CSS files 


Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
// register directive v-money and component <money>
Vue.use(money, { precision: 2 });

Vue.use(VueGooglePlaces);

Vue.use(VuePlaceAutocomplete);

Vue.use(Sortable);

Vue.use(VueCookies);

Vue.config.productionTip = true;
Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})
// Vue.use(VueRecaptcha, {
//     siteKey: "6LcSaFslAAAAAPMYdYYxMpGBFsytr4DhSGxBfQsA",
//     alterDomain: false, // default: false
//   })
new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
