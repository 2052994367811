const state = {
  notification: false,
  notificationMsg: '',
  notificationType: undefined
}

const getters = {
  getNotification: (state) => {
    return state.notification;
  },
  getNotificationMsg: (state) => {
    return state.notificationMsg;
  },
  getNotificationType: (state) => {
    return state.notificationType;
  }
}

const mutations = {
  setNotification: (state, notification) => {
    state.notification = notification;
  },
  setNotificationMsg: (state, notificationMsg) => {
    state.notificationMsg = notificationMsg;
  },
  setNotificationType: (state, notificationType) => {
    state.notificationType = notificationType;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};