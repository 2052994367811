<template>
  <v-card flat class="d-flex flex-column mt-8 infoBox">
    <v-list class="pa-0 infoContainer">
      <v-row no-gutters>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12">
          <vendor-logo :edit="edit" />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndUp ? 5 : 12">
          <v-list-item class="pa-0" two-line>
            <v-list-item-content class="pt-0">
              <v-list-item-subtitle>Company Name</v-list-item-subtitle>
              <v-list-item-title class="mt-2" v-if="!edit">{{
                _company.name
              }}</v-list-item-title>
              <div v-else>
                <v-text-field
                  class="mt-0 pt-0"
                  height="29px"
                  @keyup.enter="$emit('save')"
                  v-model="_company.name"
                  :autofocus="edit"
                  min-width="200"
                  hide-details
                ></v-text-field>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndUp ? 5 : 12">
          <v-list-item class="pa-0" two-line>
            <v-list-item-content class="pt-0">
              <v-list-item-subtitle class="mb-2"
                >Main Address</v-list-item-subtitle
              >
              <v-list-item-title>
                <div class="mb-1">{{ _company.street }}</div>
                <div>
                  {{ `${_company.city}, ${_company.state} ${_company.zip}` }}
                </div>
                <div v-if="edit" class="mt-2" style="font-size:14px">
                  <a
                    href="javascript:"
                    @click="editMainAddress = !editMainAddress"
                    class="secondary--text"
                  >
                    Edit Address
                  </a>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
    <edit-address-dialog
      @close="editMainAddress = false"
      :visible="editMainAddress"
    />
  </v-card>
</template>
<script>
import VendorLogo from './VendorLogo.vue';
import EditAddressDialog from './EditAddressDialog.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    VendorLogo,
    EditAddressDialog
  },
  props: {
    edit: Boolean
  },
  data() {
    return {
      editMainAddress: false
    };
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany'
    })
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
}

.v-list-item {
  width: 80%;
}

.v-list-item__subtitle {
  font-size: 1rem;
}

.v-list-item__title {
  font-size: 1.1rem;
}
</style>
