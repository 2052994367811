<template>
  <v-card class="pa-4" flat>
    <admin-vendor-tabs @switchTab="onSwitchTab" />
    <div v-if="tab === 'company'">
      <div v-if="_vendorsLoading && !_vendors">
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="false"
          type="table"
          class="mx-0"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <div class="titleBar">
          <v-card-title
            v-html="`List`"
            style="font-size:26px;"
            class="save--text"
            v-if="$vuetify.breakpoint.smAndUp"
          ></v-card-title>

          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <v-text-field
            v-model="search"
            style="max-width: 300px; margin: 16px;"
            label="Search"
            :append-icon="search ? `mdi-close` : `mdi-briefcase-search`"
            @keyup="throttledMethod()"
            @click:append="clearSearch()"
          />
        </div>
        <v-slide-x-transition>
          <v-data-table
            height="calc(100vh - 350px)"
            v-if="_vendors"
            v-model="selected"
            :options.sync="options"
            :server-items-length="total"
            no-data-text="No Vendors right now."
            :headers="headers"
            :items="_vendors"
            :loading="_vendorsLoading"
            multi-sort
            @update:options="getData(options)"
            @click:row="handleClick"
          >
            <template #header.r2_cert>
              {{ 'CERTIFIED' }}
            </template>
            <template #[`header.name`]>
              {{ 'COMPANY NAME' }}
            </template>
            <template #item.r2_cert="{ item }">
              {{ item.r2_cert ? 'Y' : 'N' }}
            </template>
            <template #[`item.status`]="{ item }">
              <span v-html="item.status"></span>
            </template>
          </v-data-table>

          <v-banner v-else single-line transition="slide-y-transition">
            Sorry No Vendors Found
            <template v-slot:actions="{}">
              <v-btn
                text
                color="primary"
                :loading="_vendorsLoading"
                @click="throttledMethod()"
                >Retry</v-btn
              >
            </template>
          </v-banner>
        </v-slide-x-transition>
      </div>
    </div>
    <div v-if="tab === 'site'">
      <create-site :edit="false" />
      <div v-if="_sitesLoading && !_sites">
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="false"
          type="table"
          class="mx-0"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <div class="titleBar">
          <v-card-title
            v-html="`List`"
            style="font-size:26px;"
            class="save--text"
            v-if="$vuetify.breakpoint.smAndUp"
          ></v-card-title>

          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <v-text-field
            v-model="search"
            style="max-width: 300px; margin: 16px;"
            label="Search"
            :append-icon="search ? `mdi-close` : `mdi-briefcase-search`"
            @keyup="throttledMethod()"
            @click:append="clearSearch()"
          />
        </div>
        <v-slide-x-transition>
          <v-data-table
            height="calc(100vh - 350px)"
            v-if="_sites"
            v-model="selected"
            :options.sync="options"
            :server-items-length="total"
            no-data-text="No Sites right now."
            :headers="headers"
            :items="_sites"
            :loading="_sitesLoading"
            multi-sort
            @update:options="getData(options)"
            @click:row="handleClick"
          >
            <template #header.r2_cert>
              {{ 'CERTIFIED' }}
            </template>
            <template #item.r2_cert="{ item }">
              {{ item.r2_cert ? 'Y' : 'N' }}
            </template>
            <template #[`item.status`]="{ item }">
              <span v-html="item.status"></span>
            </template>
          </v-data-table>

          <v-banner v-else single-line transition="slide-y-transition">
            Sorry No Sites Found
            <template v-slot:actions="{}">
              <v-btn
                text
                color="primary"
                :loading="_sitesLoading"
                @click="throttledMethod()"
                >Retry</v-btn
              >
            </template>
          </v-banner>
        </v-slide-x-transition>
      </div>
    </div>
  </v-card>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Options from '@/mixins/Options'; //Needs to be made unique for vendors
import AdminVendorTabs from '@/components/vendor/Tabs.vue';
import CreateSite from '@/components/site/Create.vue';

export default {
  components: {
    AdminVendorTabs,
    CreateSite
  },
  mixins: [Options],
  data() {
    return {
      search: undefined,
      page: 1,
      limit: 15,
      selected: null,
      filterStart: null,
      filterEnd: null,
      weightRange: [null, null],
      palletRange: [null, null],
      saved: 'All',
      specSearch: null,
      locationSearch: null,
      mileRange: [null, null],
      tab: undefined
    };
  },
  computed: {
    ...mapGetters({
      _vendors: 'vendors/getVendors',
      _vendorsLoading: 'vendors/getLoading',
      _sites: 'sites/getVendorSites',
      _sitesLoading: 'sites/loading'
    }),
    total() {
      if (this.tab === 'company' && this._vendors && this._vendors[0]) {
        return this._vendors[0].total || 0;
      }
      if (this.tab === 'site' && this._sites && this._sites[0]) {
        return this._sites[0].total || 0;
      }
      return 0;
    },

    headers() {
      let keys;
      if (this.tab === 'company' && this._vendors && this._vendors[0]) {
        keys = Object.keys(this._vendors[0]);
      } else if (this.tab === 'site' && this._sites && this._sites[0]) {
        keys = Object.keys(this._sites[0]);
      } else {
        return undefined;
      }

      const headers = [];
      keys.forEach(i => {
        if (
          i !== 'created_at' &&
          i !== 'updated_at' &&
          i !== 'total' &&
          i !== 'saved'
        ) {
          let obj = {
            text: i.replace(/_/g, ' ').toUpperCase(),
            value: i,
            sortable: true,
            filterable: true,
            divider: false,
            align: 'start'
          };
          headers.push(obj);
          this.itemIndex++;
        }
      });
      return headers;
    },
    pagination() {
      return {
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10
      };
    }
  },
  watch: {
    tab() {
      this.search = '';
      this.options.sortBy = [];
      this.options.sortDesc = [];
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.options.search = '';
      this.getData();
    }
  },
  mounted() {
    this.tab = localStorage.getItem('vendorTab') || 'company';
    if (this._companyId) {
      this.getData();
    } else {
      setTimeout(() => {
        this.getData();
      }, 1000);
    }
    this.throttledMethod = _.debounce(() => {
      this.getData();
    }, 1000);
  },
  methods: {
    ...mapActions({
      _getVendors: 'vendors/acquireVendors',
      _getVendorSites: 'sites/getVendorSites',
      _toggleEdit: 'sites/toggleEdit'
    }),
    ...mapMutations({
      _setLoading: 'vendors/toggleLoading',
      _toggleDialog: 'sites/toggleDialog'
    }),
    getData() {
      const options = {
        ...this.options,
        search: this.search
      };
      this.tab === 'company'
        ? this._getVendors(options)
        : this._getVendorSites(options);
    },
    clearSearch() {
      this.search = '';
      this.getData();
    },
    handleClick(value) {
      if (this.tab === 'company') {
        this.$router.push('/vendor/' + value.id);
        return;
      }

      this._toggleEdit({ id: value.id, toggle: true });
    },
    onSwitchTab(val) {
      this.tab = val;
    }
  }
};
</script>

<style scoped>
.titleBar {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.highlighted-button {
  width: 112px;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
}
.gray-button {
  color: #5d5d5d;
  background-color: transparent !important;
  height: 16px;
  font-size: 14px;
}
.mobile-button {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
  color: #ffffff !important;
}
</style>
