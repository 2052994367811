<template> 
  <v-card width="" height="calc(100% - 16px)" outlined class="ma-2 black--text">
    <v-toolbar flat>
      <v-toolbar-title class="" style="font-weight: 500">Job Documents</v-toolbar-title>
      <v-btn
        @click="editAttachments = !editAttachments"
        small
        v-if="
          isAdminOrVendor(_company.type_id) &&
          confirm &&
          _job.status !== 'dispute'
        "
        absolute
        right
        top
        text
        class="ma-0 pa-0"
        >Upload Docs*<v-icon class="ml-1">mdi-upload</v-icon></v-btn
      >
    </v-toolbar>
    <div v-if="job.attachments">
      <v-divider />
      <v-card max-height="200" style="overflow-x: auto" flat>
        <v-list-item-group v-if="job.attachments && job.attachments.length > 0">
          <v-list-item
            v-for="(attachment, index) in job.attachments"
            :key="index"
          >
            <v-list-item-icon>
              <v-icon color="secondary" v-if="attachment.location"
                >mdi-paperclip</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title  v-on:click="showGoogleDocIndex(index)">{{
                attachment.original_name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                attachment.catagory.replace(/_/g, " ")
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row no-gutters>
                <v-btn
                  icon
                  :href="attachment.location"
                  :download="attachment.original_name"
                  ><v-icon color="secondary">mdi-download</v-icon>
                </v-btn>
                 <v-btn
                            icon
                              v-on:click="showGoogleDocIndex(index)"
                            ><v-icon color="secondary">mdi-file-find</v-icon>
                          </v-btn> 
                <v-btn
                  icon
                  v-if="
                    isAdmin(_company.type_id) &&
                    confirm &&
                    _job.status !== 'dispute'
                  "
                  @click.stop="removeAtt(index)"
                  ><v-icon color="error">mdi-trash-can</v-icon></v-btn
                >
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
        <v-list-item v-else>
          <v-list-item-icon>
            <v-icon color="secondary">mdi-cancel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ "No Files Available..." }}</v-list-item-title>
            <v-list-item-subtitle>{{ "N/A" }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </v-card>
    </div>
    <v-dialog max-width="400" v-model="editAttachments">
      <v-card flat class="pa-4">
        <v-card-title class="mb-2">Upload New Files
          <v-spacer />
          <v-btn text icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-file-input
          v-if="job.recycle_specs && job.recycle_specs.resale == true"
          @change="uploadFile($event, 'purchase_inventory')"
          outlined
          dense
          @focus="equipFocused = !equipFocused"
          @blur="equipFocused = !equipFocused"
          :loading="equipFocused ? _uploading : false"
          label="Inventory of Equipment to Purchase"
        ></v-file-input>

        <v-fade-transition>
          <v-file-input
            @change="uploadFile($event, 'cod')"
            dense
            outlined
            ref="misc"
            @focus="codFocused = !codFocused"
            @blur="codFocused = !codFocused"
            :loading="codFocused ? _uploading : false"
            label="Certificate of Destruction Upload*"
          ></v-file-input>
        </v-fade-transition>
        <v-subheader v-if="job.attachments && job.attachments.length > 0"
          >Uploaded Files</v-subheader
        >
        <v-card max-height="200" style="overflow-x: auto" outlined>
          <v-list-item
            width="100%"
            class="mx-0 px-0"
            v-for="(i, k) in job.attachments"
            :key="k"
          >
            <v-list-item-icon>
              <v-icon color="secondary">mdi-paperclip</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ i.original_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ i.catagory.replace(/_/g, " ") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card-actions>
          <!-- <v-btn block @click="editAttachments = false" color="secondary">Done</v-btn> -->
          <v-btn block @click="saveJobDocuments()" color="secondary"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <google-doc  />
  </v-card>
</template> 
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import GoogleDoc from "@/components/app/GoogleDoc.vue";
export default {
  components: { 
   GoogleDoc,
  },
  props: {
    confirm: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      fileUploading: {},
      editAttachments: false,
      codFocused: false,
      equipFocused: false,
    };
  },
  computed: {
    ...mapGetters({
      _file: "uploads/getFile",
      _loading: "jobs/loading",
      _job: "jobs/getCurrentJob",
      _uploading: "uploads/loading",
      _company: "company/getCompany",
    }),
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      },
    },
  },
  watch: {
    _file() {
      if (!this.editAttachments) {
        return;
      }

      if (!this.job.attachments) {
        this.job.attachments = [];
      }
      if (this._file.file.Key && this.fileUploading) {
        this.job.attachments.push({
          key: this._file.file.Key,
          location: this._file.file.Location,
          file_type: this.fileUploading.type,
          catagory: this.fileUploading.catagory,
          original_name: this.fileUploading.name,
        });
      }
    },
  },
  methods: {
     ...mapMutations({
      _setGoogleDoc: "googledoc/setShowGoogleDocDialog",
     // _setSrc: "googledoc/setSrc",
      _setIndex: "googledoc/setIndex", 
       _setList: "googledoc/setList",  
      _setClosingConf: "jobs/setClosingConf",
    }),
    ...mapActions({
      _uploadFile: "uploads/uploadFile",
      _updateJob: "jobs/updateJob",
      _setJob: "jobs/setJob",
    }),
     showGoogleDocIndex(index) { 
      this._setList(this.job.attachments );
      this._setIndex(index);
      this._setGoogleDoc(true);
    },
    removeAtt(index) {
      const tempAttachments = [...this.job.attachments];
      this._updateJob({
        id: this.job.job_id,
        attachments: tempAttachments.splice(index, 1),
      }).then(() => {
        this.job.attachments.splice(index, 1);
      });
    },
    uploadFile(e, type) {
      if (e && e.name) {
        this.fileUploading.file = e;
        this.fileUploading.name = e.name;
        this.fileUploading.catagory = type;
        this.fileUploading.type = e.type;
        let file = {
          file: e,
          original_name: e.name,
          type: type,
        };
        this._uploadFile(file);
      } else if (!e) {
      }
    },
    isAdmin(type) {
      return type === 3;
    },
    isAdminOrVendor(type) {
      return type === 3 || type === 2;
    },
    saveJobDocuments() {
      let _job = {};
      _job.id = this.job.job_id;
      _job.attachments = this.job.attachments;
      this._updateJob(_job);
    },
    closeDialog() {
      this.editAttachments = false;
    },
  },
};
</script>
