<template>
  <v-card no-gutters flat class="pl-2">
    <v-card-title class="pb-0 mb-0 ml-0 pl-0 ">
      Required Vendor Profile
    </v-card-title>
    <h3 class="ml-0 pl-0 pt-0 pb-6">
      {{ `Completion Progress (${countCompleted}/5)` }}
    </h3>
    <!-- <v-form ref="requiredField" v-bind:disabled="isDisableAllForm" style="max-width: 1200px;"> -->
    <v-form
      ref="requiredField"
      v-bind:disabled="isDisableAllForm"
      style="max-width: 1200px;"
    >
      <div>
        <v-timeline dense align-top class="custom-v-time-line">
          <v-timeline-item
            :class="[
              'pb-0 mb-0',
              {
                invalid: !validData.r2 && !mustValidate,
                'red-warning': !validData.r2 && mustValidate
              }
            ]"
            :icon="validData.r2 ? 'mdi-check' : 'mdi-alert-outline'"
            :color="validData.r2 ? 'green' : ''"
            :fill-dot="validData.r2"
          >
            <v-row class="pb-0">
              <v-col cols="12" sm="6" md="6" lg="6" class="pb-0">
                <v-card flat class="pb-0">
                  <v-card-subtitle class="t0 pt-2 pb-0">
                    Are you R2 or e-Stewards certified?
                  </v-card-subtitle>
                  <v-card-text class="mb-0 pb-0 pt-0">
                    <v-radio-group
                      row
                      v-model="r2"
                      @change="checkValidateAndMarkSuccess"
                    >
                      <v-radio
                        color="success"
                        label="Yes"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        color="success"
                        label="No"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>

                  <v-menu
                    v-show="r2"
                    id="menuxxx"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-show="r2"
                        class="mt-0 pt-2 pl-4 pr-4"
                        id="r2_datexxx"
                        v-model="dateFormatted"
                        :rules="[rules.required, rules.dateMax]"
                        :disabled="!r2 || isDisableAllForm"
                        ref="dateFormatted"
                        label="Certification Expiration"
                        prepend-icon="mdi-calendar-month"
                        v-on="on"
                        @input="checkValidateAndMarkSuccess"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="checkValidateAndMarkSuccess"
                      v-show="r2"
                      v-model="r2_date"
                      :min="maxCertDate"
                      no-title
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-card v-show="r2" flat no-gutters class="pt-0 pb-0">
                    <v-card-text>
                      <v-file-input
                        :rules="
                          (mustValidate &&
                            checkIfAttachmentUploaded('certification')) ||
                          !mustValidate
                            ? []
                            : [rules.required]
                        "
                        dense
                        outlined
                        label="Upload Certification"
                        @change="uploadFile($event, 'certification')"
                      ></v-file-input>
                    </v-card-text>
                    <v-row flat no-gutters class="pt-0 mt-0 pb-0 pb-6 pl-4">
                      <v-col cols="12" sm="12" md="8" lg="8">
                        <v-list-item
                          class="pl-0 mr-12 pt-0"
                          width="100%"
                          dense
                          v-for="(attachment, index) in filteredAttachments(
                            'certification'
                          )"
                          :key="index"
                        >
                          <v-list-item-icon>
                            <v-icon
                              color="secondary"
                              v-if="attachment.location"
                            >
                              mdi-paperclip
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ attachment.original_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ mapCategoryAttachments(attachment.catagory) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            <v-row no-gutters>
                              <v-btn icon :href="attachment.location" :download="attachment.original_name">
                                <v-icon color="secondary">mdi-download</v-icon>
                              </v-btn>
                              <v-btn icon @click.native="deleteFile(attachment.key)">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action> -->
                        </v-list-item>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            :class="[
              'pb-0',
              {
                invalid: !validData.resale && !mustValidate,
                'red-warning': !validData.resale && mustValidate
              }
            ]"
            :color="validData.resale ? 'green' : ''"
            :icon="validData.resale ? 'mdi-check' : 'mdi-alert-outline'"
            :fill-dot="validData.resale"
          >
            <v-row class="pb-0">
              <v-col cols="12" sm="12" md="6" lg="6" class="pb-0">
                <v-card flat class="pb-0">
                  <v-card-subtitle class="t0 pt-2 pb-0">
                    Do you have a Certificate of Resale?
                  </v-card-subtitle>
                  <v-card-text class="pb-0 pt-0">
                    <v-radio-group
                      row
                      v-model="resale"
                      @change="checkValidateAndMarkSuccess"
                    >
                      <v-radio
                        color="success"
                        label="Yes"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        color="success"
                        label="No"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                  <v-card v-show="resale" flat no-gutters>
                    <v-card-text class="pb-0 pt-0 pl-4">
                      <v-file-input
                        :rules="
                          (mustValidate &&
                            checkIfAttachmentUploaded('resale')) ||
                          !mustValidate
                            ? []
                            : [rules.required]
                        "
                        @change="uploadFile($event, 'resale')"
                        outlined
                        @focus="resaleFocused = !resaleFocused"
                        @blur="resaleFocused = !resaleFocused"
                        :loading="resaleFocused ? _uploading : false"
                        dense
                        label="Certificate of Resale"
                        :error-messages="resaleRequired"
                      ></v-file-input>
                    </v-card-text>
                    <v-row flat no-gutters class="pt-0 mt-0 pb-0 pb-6 pl-4">
                      <v-col cols="12" sm="12" md="8" lg="8">
                        <v-list-item
                          class="pl-0 mr-12 pt-0"
                          width="100%"
                          dense
                          v-for="(attachment, index) in filteredAttachments(
                            'resale'
                          )"
                          :key="index"
                        >
                          <v-list-item-icon>
                            <v-icon
                              color="secondary"
                              v-if="attachment.location"
                            >
                              mdi-paperclip
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ attachment.original_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ mapCategoryAttachments(attachment.catagory) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            <v-row no-gutters>
                              <v-btn icon :href="attachment.location" :download="attachment.original_name">
                                <v-icon color="secondary">mdi-download</v-icon>
                              </v-btn>
                              <v-btn icon @click.native="deleteFile(attachment.key)">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action> -->
                        </v-list-item>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6"></v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="pb-0 pt-0">
                <v-card-subtitle class="t0 pb-6 pt-0">
                  Upload Company Documents
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            :class="[
              'pb-0 mb-0',
              {
                invalid: !validData.w9 && !mustValidate,
                'red-warning': !validData.w9 && mustValidate
              }
            ]"
            :color="validData.w9 ? 'green' : ''"
            :icon="validData.w9 ? 'mdi-check' : 'mdi-alert-outline'"
            :fill-dot="true"
          >
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-card flat no-gutters class="pt-0 mt-0">
                  <v-card-text class="pt-0 mt-0">
                    <v-file-input
                      :rules="
                        (mustValidate && checkIfAttachmentUploaded('w9')) ||
                        !mustValidate
                          ? []
                          : [rules.required]
                      "
                      dense
                      outlined
                      label="Upload W9"
                      @change="uploadFile($event, 'w9')"
                    ></v-file-input>
                  </v-card-text>
                  <v-row flat no-gutters class="pt-0 mt-0 pb-0 pb-6 pl-4">
                    <v-col cols="12" sm="12" md="8" lg="8">
                      <v-list-item
                        class="pl-0 mr-12 pt-0"
                        width="100%"
                        dense
                        v-for="(attachment, index) in filteredAttachments('w9')"
                        :key="index"
                      >
                        <v-list-item-icon>
                          <v-icon color="secondary" v-if="attachment.location">
                            mdi-paperclip
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ attachment.original_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ mapCategoryAttachments(attachment.catagory) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- <v-list-item-action>
                          <v-row no-gutters>
                            <v-btn icon :href="attachment.location" :download="attachment.original_name">
                              <v-icon color="secondary">mdi-download</v-icon>
                            </v-btn>
                            <v-btn icon @click.native="deleteFile(attachment.key)">
                              <v-icon color="error">mdi-trash-can</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            :class="[
              'pb-0 mb-0',
              {
                invalid: !validData.insurance && !mustValidate,
                'red-warning': !validData.insurance && mustValidate
              }
            ]"
            :color="validData.insurance ? 'green' : ''"
            :icon="validData.insurance ? 'mdi-check' : 'mdi-alert-outline'"
            :fill-dot="validData.insurance"
          >
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-card flat no-gutters>
                  <v-card-text class="pt-0 mt-0">
                    <v-file-input
                      :rules="
                        (mustValidate &&
                          checkIfAttachmentUploaded('insurance')) ||
                        !mustValidate
                          ? []
                          : [rules.required]
                      "
                      dense
                      outlined
                      label="Upload Insurance Certificate"
                      @change="uploadFile($event, 'insurance')"
                    ></v-file-input>
                  </v-card-text>
                  <v-row flat no-gutters class="pt-0 mt-0 pb-0 pb-6 pl-4">
                    <v-col cols="12" sm="12" md="8" lg="8">
                      <v-list-item
                        class="pl-0 mr-12 pt-0"
                        width="100%"
                        dense
                        v-for="(attachment, index) in filteredAttachments(
                          'insurance'
                        )"
                        :key="index"
                      >
                        <v-list-item-icon>
                          <v-icon color="secondary" v-if="attachment.location">
                            mdi-paperclip
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ attachment.original_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ mapCategoryAttachments(attachment.catagory) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- <v-list-item-action>
                          <v-row no-gutters>
                            <v-btn icon :href="attachment.location" :download="attachment.original_name">
                              <v-icon color="secondary">mdi-download</v-icon>
                            </v-btn>
                            <v-btn icon @click.native="deleteFile(attachment.key)">
                              <v-icon color="error">mdi-trash-can</v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            :class="[
              'pb-0 mb-0',
              {
                invalid: !validData.terms && !mustValidate,
                'red-warning': !validData.terms && mustValidate
              }
            ]"
            :color="validData.terms ? 'green' : ''"
            :icon="validData.terms ? 'mdi-check' : 'mdi-alert-outline'"
            :fill-dot="validData.terms"
          >
            <v-card flat>
              <v-card-subtitle class="t0 pb-4">
                Terms & Conditions
              </v-card-subtitle>
              <div class="ml-3 mt-3">
                <!-- <vendor-docs @attachmentRemoved="$refs.certified.checkR2Validity()" :edit="edit" class="mb-8" /> -->
                <vendor-eula
                  :isShowTitle="false"
                  class="my-4"
                  :edit="true"
                  :acceptedEula="acceptedEula"
                  @agree="onEulaAgreed"
                />
              </div>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-form>
    <google-doc :timeout="3000" />
    <WaitingIndicator :isShow="showLoader"></WaitingIndicator>
  </v-card>
</template>
<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import upload from '../../mixins/Upload';
import VendorEula from '@/components/profile/VendorEula.vue';
import axios from 'axios';
import VendorDocs from '@/components/profile/VendorDocs.vue';
import GoogleDoc from '@/components/app/GoogleDoc.vue';
import * as Helpers from '@/helpers/helpers';
import config from '@/config';
import WaitingIndicator from '@/components/common/WaitingIndicator.vue';
export default {
  directives: {
    mask
  },
  components: {
    VendorEula,
    // VendorDocs,
    GoogleDoc,
    WaitingIndicator
  },
  mixins: [upload],
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    attachments() {
      this.checkValidateAndMarkSuccess();
    },
    countCompleted(val) {
      //alert('countCompletedStep1', val);
      this.$emit('updateCountCompletedStep1', val);
    },
    acceptedEula(val) {
      //this.validData.terms = val;
      this.checkValidateAndMarkSuccess();
    },
    'company.vendor_eula'(val) {
      this.checkValidateAndMarkSuccess();
    }
  },
  data() {
    return {
      config: config,
      rules: {
        required: v => !!v || '',
        //confirmMatch: v => this.newUser.password === this.newUser.confirm_pass || 'The passwords you entered don’t match',
        length: v =>
          (v && v.length >= 8) ||
          'Passwords must be at least 8 characters in length',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(
            v
          ) || 'Passwords require at least one special character',
        dateMax: v =>
          moment(v).isAfter(moment(), 'day') ||
          'Certification expiration can not be equal to or earlier than today’s date.'
      },
      menu: false,
      r2: null,
      resale: null,
      acceptTerms: false,
      countCompleted: 0,
      validData: {
        r2: false,
        resale: false,
        w9: false,
        insurance: false,
        terms: false
      },
      r2_date: undefined,
      mustValidate: false,
      acceptedEula: false,
      currentIp: null,
      isDisableAllForm: true,
      showLoader: false
    };
  },
  async mounted() {
    this.showLoader = true;
    await this._auToSetUserAndCompanyInfo();
    if (this.$route.query.new) {
      this.mustValidate = false;
    } else {
      this.mustValidate = true;
      this.$refs.requiredField.validate();
      this.acceptedEula = this.company.vendor_eula;
    }

    this.$nextTick(() => {
      if (this.mustValidate) {
        this.r2 = this.company.r2_cert.cert;
        this.r2_date = this.company.r2_cert.date;
        this.attachments = this.company.attachments;
        this.resale = this.company.resale;
        //this.company.acceptedEula = this.company.vendor_eula;
      }
    });

    this.$emit('updateCountCompletedStep1', this.countCompleted);

    axios.get('https://api.ipify.org?format=json').then(res => {
      this.currentIp = res.data.ip;
    });

    this.isDisableAllForm = this._user.type_id != this.config.USER_TYPE.admin;
    this.showLoader = false;
  },
  computed: {
    company: {
      get() {
        return this._company;
      },
      set(newVal) {
        this._setCompany(newVal);
      }
    },
    maxCertDate() {
      return moment()
        .subtract(1, 'days')
        .toISOString();
    },
    dateFormatted: {
      get() {
        if (this.r2 && this.r2_date) {
          return moment(this.r2_date).format('MM/DD/YYYY');
        } else {
          return moment()
            .subtract(1, 'days')
            .format('MM/DD/YYYY');
        }
      },
      set(value) {
        let date = moment(value).toISOString();
        this.r2_date = date;
      }
    },
    beforeToday() {
      return moment(this.dateFormatted).isBefore(moment(), 'day');
    },
    validated() {
      if (this.company.vendor_eula) {
        return false;
      }
      return true;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    ...mapGetters({
      _company_id: 'company/getCompanyId',
      _file: 'uploads/getFile',
      _loading: 'company/getLoading',
      _uploading: 'uploads/loading',
      _company: 'company/getCompany',
      _user: 'user/getUser',
      _loggedInCompany: 'company/getLoggedInCompany'
    })
  },
  methods: {
    ...Helpers,
    ...mapActions({
      _updateCompany: 'company/updateCompany',
      _uploadFile: 'uploads/uploadFile',
      _getCompany: 'company/getCurrent',
      _auToSetUserAndCompanyInfo: 'authenticate/auToSetUserAndCompanyInfo'
    }),
    ...mapMutations({
      _setCompany: 'company/setCompany',
      _setLoggedInCompany: 'company/setLoggedInCompany'
    }),
    filteredAttachments(category) {
      return this.attachments?.filter(
        attachment => attachment.catagory === category
      );
    },
    deleteFile(key) {
      this.attachments = this.attachments.filter(
        attachment => attachment.key !== key
      ); //remove the file from the list
    },
    countCompletedRequired(obj) {
      let count = 0;
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === true) {
          count++;
        }
        console.log(key, count);
      }
      return count;
    },
    checkIfAttachmentUploaded(type) {
      if (!Array.isArray(this.attachments) || this.attachments.length <= 0) {
        return false;
      }

      let found = false;
      this.attachments.map(v => {
        if (v.catagory === type) {
          found = true;
        }
      });
      return found;
    },
    checkValidateAndMarkSuccess() {
      // this.validData.r2 =
      //   this.r2 != null && (!this.r2 || (!!this.r2 && !!this.dateFormatted && this.checkIfAttachmentUploaded('certification')));

      const r2Date = moment(this.dateFormatted, 'MM/DD/YYYY');
      const currentDate = moment(); // Get the current date
      const isR2DateValid = r2Date.isValid() && r2Date.isAfter(currentDate);

      const isR2Valid = this.r2 != null;
      const isR2FalseOrValid =
        !this.r2 ||
        (!!this.r2 &&
          !!this.dateFormatted &&
          isR2DateValid &&
          this.checkIfAttachmentUploaded('certification'));

      this.validData.r2 = isR2Valid && isR2FalseOrValid;

      this.validData.resale =
        this.resale != null &&
        (!this.resale ||
          (!!this.resale && this.checkIfAttachmentUploaded('resale')));
      this.validData.w9 = this.checkIfAttachmentUploaded('w9');
      this.validData.insurance = this.checkIfAttachmentUploaded('insurance');
      this.validData.terms = this.company.vendor_eula;

      this.countCompleted = this.countCompletedRequired(this.validData);
      //this.$emit('updateCountCompletedStep1', this.countCompleted);
    },
    async updateCompanyInfo() {
      //validate date
      const r2Date = moment(this.dateFormatted, 'MM/DD/YYYY');
      const currentDate = moment(); // Get the current date
      const isR2DateValid = r2Date.isValid() && r2Date.isAfter(currentDate);

      if (this.resale == null) {
        this.company.resale = null;
      } else {
        this.company.resale = this.resale
          ? this.resale
          : this.checkIfAttachmentUploaded('resale');
      }

      this.certRequired = null;
      this.company.attachments = this.attachments;
      //this.company.additional_info = this.additionalInfo.toLowerCase();
      //this.company.vendor_abilities = this.abilities;

      if (this.r2 != null) {
        if (this.r2 && isR2DateValid) {
          this.company.r2_cert = { cert: this.r2, date: this.r2_date };
        } else {
          this.company.r2_cert = { cert: this.r2 };
        }
      } else {
        this.company.r2_cert = null;
      }

      // if (this.company.vendor_eula && !this.company.accepted_ip) {
      //   //this.company.accepted_terms = this.company.vendor_eula;
      //   if (!this.currentIp) {
      //     const ipRes = await axios.get('https://api.ipify.org?format=json');
      //     this.company.accepted_ip = ipRes.data.ip;
      //   } else {
      //     this.company.accepted_ip = this.currentIp;
      //   }

      //   this.company.accepted_by = this._user.id;
      //   this.company.accepted_at = new Date();
      //   //delete this.company.vendor_eula;
      // }

      if (!this.acceptedEula && this.company.vendor_eula) {
        if (!this.currentIp) {
          const ipRes = await axios.get('https://api.ipify.org?format=json');
          this.company.accepted_ip = ipRes.data.ip;
        } else {
          this.company.accepted_ip = this.currentIp;
        }

        this.company.accepted_by = this._user.id;
        this.company.accepted_at = new Date();
      }

      let resUpdate = await this._updateCompany(this.company);
      this._setLoggedInCompany(this.company);
      return resUpdate;
    },
    mapCategory(category) {
      if (!category) {
        return;
      }
      switch (category) {
        case 'resale':
          return 'Certificate of Resale';
        default:
          return category.charAt(0).toUpperCase() + category.slice(1);
      }
    },
    async onEulaAgreed() {
      const tempComp = _.cloneDeep(this._loggedInCompany);
      tempComp.vendor_eula = true;

      // get ip
      const ipRes = await axios.get('https://api.ipify.org?format=json');
      tempComp.accepted_ip = ipRes.data.ip;
      tempComp.accepted_by = this._user.id;
      tempComp.accepted_at = new Date();

      this._updateCompany(tempComp).then(() => {
        this._setLoggedInCompany(tempComp);
        this.company.vendor_eula = tempComp.vendor_eula;
        this.company.accepted_ip = tempComp.accepted_ip;
        this.company.accepted_by = tempComp.accepted_by;
        this.company.accepted_at = tempComp.accepted_at;
        this.acceptedEula = this.company.vendor_eula;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-timeline::before {
  background-color: #4caf50 !important;
}
div.v-card__subtitle.t0 {
  font-size: 18px !important;
  font-weight: bold !important;
}

.v-timeline-item__dot {
  width: 30px !important;
  height: 30px !important;
}
</style>

<style scoped>
.invalid >>> .v-timeline-item__dot {
  border: 3px solid rgba(0, 0, 0, 0.142) !important;
  background-color: white !important;
  box-shadow: none !important;
}

.red-warning >>> .v-timeline-item__dot {
  border: 3px solid #e53935 !important;
  background-color: white !important;
  box-shadow: none !important;

  display: flex;
  align-items: center;
  justify-content: center;
}
.red-warning >>> .mdi-alert-outline {
  color: #e53935 !important;
  padding-bottom: 4px !important;
  border-radius: 20%;
}

.v-timeline >>> .v-timeline-item:first-child >>> .v-timeline-divider__before,
.v-timeline .v-timeline-item:last-child .v-timeline-divider__after {
  display: none;
}

.custom-v-time-line {
  padding-top: 0px;
}
</style>
