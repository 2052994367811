<template>
  <v-card class="pa-4" flat>
    <div>
      <div v-if="_loading && ! _customers">
        <v-skeleton-loader
          ref="skeleton"
          :boilerplate="false"
          type="table"
          class="mx-0"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <div class="titleBar">
          <v-card-title
            v-html="`List`"
            style="font-size:26px;"
            class="save--text"
            v-if="$vuetify.breakpoint.smAndUp"
          ></v-card-title>

          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <v-text-field
            v-model="search"
            style="max-width: 300px; margin: 16px;"
            label="Search"
            :append-icon="search ? `mdi-close` : `mdi-briefcase-search`"
            @keyup="throttledMethod()"
            @click:append="clearSearch()"
          />
        </div>
        <v-slide-x-transition>
          <v-data-table
            height="calc(100vh - 350px)"
            v-if="_customers"
            v-model="selected"
            :options.sync="options"
            :server-items-length="total"
            no-data-text="No customers right now."
            :headers="headers"
            :items="_customers"
            :loading="_loading"
            multi-sort
            @update:options="_getCustomers(options)"
            @click:row="handleClick"
          >
            <template #[`header.name`]>
              {{ 'COMPANY NAME' }}
            </template> 
			 <template #[`item.status`]="{ item }">
            <span v-html="item.status"></span>
          </template>
          </v-data-table>
          <v-banner v-else single-line transition="slide-y-transition">
            Sorry No Customers Found
            <template v-slot:actions="{  }">

              <v-btn
                text
                color="primary"
                :loading="_loading"
                @click="throttledMethod()"
                >Retry</v-btn
              >
            </template>
			
          </v-banner>
        </v-slide-x-transition>
      </div>
    </div>
  </v-card>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Options from "@/mixins/Options"; //Needs to be made unique for customers
export default {
	mixins: [Options],
	data() {
		return {
			search: undefined,
			page: 1,
			limit: 15,
			selected: null,
			filterStart: null,
			filterEnd: null,
			weightRange: [null, null],
			palletRange: [null, null],
			saved: "All",
			specSearch: null,
			locationSearch: null,
			mileRange: [null, null],
		};
	},
	computed: {
		...mapGetters({
			_customers: "customers/getCustomers",
			_loading: "customers/getLoading",
			_companyId: "company/getCompanyId",
			// _status: "customers/getStatus",
		}),
		total() {
			return this._customers[0] ? parseInt(this._customers[0].total) : 0;
		},

		headers() {
			if (this._customers) {
				const keys = Object.keys(this._customers[0]);
				var headers = [];
				var itemIndex = 0;
				keys.forEach((i) => {
					if (
						i !== "created_at" &&
						i !== "updated_at" &&
						i !== "total" &&
						i !== "saved"
					) {
						const obj = {
							text: i.replace(/_/g, " ").toUpperCase(),
							value: i,
							sortable: true,
							filterable: true,
							divider: false,
							align: "start",
						};
						headers.push(obj);
						this.itemIndex++;
					}
				});
				return headers;
			} else {
				return undefined;
			}
		},
		pagination() {
			return {
				page: this.options.page || 1,
				limit: this.options.itemsPerPage || 10,
			};
		},
		compId() {
			return this._companyId || 0;
		},
	},
	watch: {
		compId() {
			this.getData();
		},
		// _status() {
		// 	this.options.page = 1;
		// 	this.getData();
		// }
	},
	mounted() {
		if (this._companyId) {
			this._getCustomers(this.options);
		} else {
			setTimeout(() => {
				this._getCustomers(this.options);
			}, 1000);
		}
		this.throttledMethod = _.debounce(() => {
			this.getData();
		}, 1000);
	},
	methods: {
		...mapActions({
			_getCustomers: "customers/acquireCustomers",
		}),
		...mapMutations({
			_setLoading: "customers/toggleLoading",
		}),
		getData() {
			let options = this.options;
			options.search = this.search;
			this._getCustomers(options);
		},
		clearSearch() {
			this.search = "";
			this.getData();
		},
		handleClick(value) {
			this.$router.push("/customer/" + value.id);
		},
	},
};
</script>

<style scoped>
.titleBar {
	width: 100%;
	display: flex;
	flex-direction: row;
}
.highlighted-button {
	width: 112px;
	height: 48px;
	border-radius: 24px;
	background-color: #4caf50 !important;
	padding: 0px 20px !important;
	margin: 5px;
}
.gray-button {
	color: #5d5d5d;
	background-color: transparent !important;
	height: 16px;
	font-size: 14px;
}
.mobile-button {
	width: 100%;
	height: 48px;
	border-radius: 24px;
	background-color: #4caf50 !important;
	padding: 0px 20px !important;
	margin: 5px;
	color: #ffffff !important;
}
</style>