import moment from "moment";

export default {
	data() {
		return {};
	},
	computed: {
		today() {
			return moment().toISOString();
		},
	},
};
