<template>
  <v-card class="pa-4" flat>
    <jobs-tabs />
    <jobs-table :table-title="title1" />
    <new-job-pre />
    <new-job-form />
    <green-glove-alert />

    <v-dialog v-model="showDialog" persistent width="470" @click:outside="closeDialog()">
      <v-card flat style="padding: 20px 10px 20px 20px" align="center" justify="space-around">
        <div class="btn-dismiss-dialog">
          <v-btn text @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-flex>
          <v-card flat>
            <div class="notification-card">
              <div class="icon"><span class="mdi mdi-information color-title-popup-info"></span></div>
              <h2 class="pb-3 color-title-popup-info">New Account Limited Access</h2>
              <p>As a new account, you cannot bid on open jobs until RGX reviews your request to join the platform.</p>
              <br />
              <p>
                Our staff will contact you shortly to verify your company. Eager to get started? You can review the open jobs so that you
                are ready to go!
              </p>
            </div>
          </v-card>
        </v-flex>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import JobsTable from '@/components/jobs/Table.vue';
import GreenGloveAlert from '@/components/jobs/GreenGloveAlert.vue';
import JobsTabs from '@/components/jobs/Tabs.vue';
import NewJobPre from '@/components/jobs/NewJobPre.vue';
import NewJobForm from '@/components/jobs/NewJobForm.vue';
//import JobNoLongerOpenDialog from "@/components/jobs/JobNoLongerOpenDialog.vue";
import { mapGetters, mapMutations, mapActions } from 'vuex';
import config from '@/config';
export default {
  components: {
    JobsTable,
    JobsTabs,
    NewJobPre,
    NewJobForm,
    GreenGloveAlert //,JobNoLongerOpenDialog
  },
  data() {
    return {
      config: config,
      showDialog: false
    };
  },
  computed: {
    ...mapGetters({
      _type: 'jobs/getStatus',
      _loading: 'jobs/loading',
      _newJob: 'jobs/getJobPreCheck',
      _company: 'company/getCompany',
      _vendorCompanyProfileStatus: 'company/getVendorCompanyProfileStatus'
    }),
    typeFilter: {
      get() {
        return this._type;
      },
      set(newVal) {
        this._setType(newVal);
      }
    },
    title1() {
      switch (this.typeFilter) {
        case 'open':
          return 'Open Jobs';
        case 'draft':
          return 'Job Drafts';
        case 'ppo':
          return 'Pending Purchase Offer';
        case 'dispute':
          return 'Under Dispute';
        case 'awarded':
          return 'Awarded';
        case 'open_bid':
          return 'Open Bids';
        case 'awarded_bid':
          return 'Awarded Bids';
        case 'resale':
          return 'Ready for Resale';
        case 'closing':
          return 'Ready to Close';
        default:
          return 'Open Jobs';
      }
    }
  },
  watch: {
    // _vendorCompanyProfileStatus(val) {
    //   if (val && this._vendorCompanyProfileStatus) {
    //     console.log('1111111222333', this._vendorCompanyProfileStatus);
    //     let companyStatus = this._company.status;
    //     let profileIsCompleted = this._vendorCompanyProfileStatus == config.companyProfileStatus.complete;
    //     if (
    //       this._company.type_id == this.config.COMPANY_TYPE.VENDOR &&
    //       !profileIsCompleted &&
    //       companyStatus === this.config.VENDOR_STATUS.NEW
    //     ) {
    //       this.showDialog = true;
    //     } else {
    //       this.showDialog = false;
    //     }
    //   }
    // },
    _company(val) {
      if (val) {
        let companyStatus = this._company.status;
        if (this._company.type_id == this.config.COMPANY_TYPE.VENDOR && companyStatus === this.config.VENDOR_STATUS.NEW) {
          this.showDialog = true;
        } else {
          this.showDialog = false;
        }
      } else {
        this.showDialog = false;
      }
    }
  },
  async mounted() {
    if (this._company) {
      let companyStatus = this._company.status;
      if (this._company.type_id == this.config.COMPANY_TYPE.VENDOR && companyStatus === this.config.VENDOR_STATUS.NEW) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    }
    // try {
    //   setTimeout(() => {
    //     console.log('1111111222', this._vendorCompanyProfileStatus);
    //     if (this._vendorCompanyProfileStatus) {
    //       console.log('1111111222333', this._vendorCompanyProfileStatus);
    //       let companyStatus = this._company.status;
    //       let profileIsCompleted = this._vendorCompanyProfileStatus == config.companyProfileStatus.complete;
    //       if (
    //         this._company.type_id == this.config.COMPANY_TYPE.VENDOR &&
    //         !profileIsCompleted &&
    //         companyStatus === this.config.VENDOR_STATUS.NEW
    //       ) {
    //         this.showDialog = true;
    //       } else {
    //         this.showDialog = false;
    //       }
    //     }
    //   }, 2000);
    // } catch (e) {
    //   this.showDialog = false;
    // }
  },
  methods: {
    ...mapMutations({
      _setType: 'jobs/setStatus',
      _setNewJob: 'jobs/setJobPreCheck'
    }),
    ...mapActions({
      _getCompanyProfileStatus: 'company/getCompanyProfileStatus'
    }),
    createNewJob() {
      this._setNewJob(true);
    },
    closeDialog() {
      this.showDialog = false;
    }
  }
};
</script>

<style scoped>
.notification-card {
  background-color: white;
  border-radius: 8px;
  text-align: center;
}
.notification-card .icon {
  font-size: 45px;
}
.notification-card h2 {
  margin: 10px 0;
  font-size: 28px;
}
.notification-card p {
  margin: 5px 0;
  font-size: 16px;
  color: #333;
  text-align: left;
}

.color-title-popup-info {
  color: #008cba !important;
}
</style>
