<template>
  <div>
    <v-row no-gutters class="d-flex flex-wrap" :class="$vuetify.breakpoint.smAndUp ? 'flex-row' : 'flex-column'">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12" class="section-title">Certifications</v-col>
      <v-col :cols="$vuetify.breakpoint.smAndUp ? 5 : 12" class="mb-4 pr-4">
        <div class="pl-0 mb-0 text-subtitle-1 font-weight-bold">
          <span>Are you R2 or e-Stewards certified?</span>
        </div>
        <v-radio-group
          v-if="company.r2_cert"
          dense
          row
          :disabled="!edit"
          class="mt-0"
          @change="checkR2Validity"
          v-model="company.r2_cert.cert"
        >
          <v-radio color="success" class="mt-0" label="Yes" :value="true"></v-radio>
          <v-radio color="success" class="mt-0" label="No" :value="false"></v-radio>
        </v-radio-group>
        <v-menu
          ref="menu"
          v-model="menu"
          v-if="company"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormatted"
              :disabled="!edit || !company.r2_cert.cert"
              label="Certification Expiration"
              prepend-icon="mdi-calendar-month"
              v-on="on"
              style="max-width: 160px;"
            ></v-text-field>
          </template>
          <v-date-picker v-model="company.r2_cert.date" :min="maxCertDate" no-title @input="menu = false"></v-date-picker>
        </v-menu>
        <v-file-input
          v-if="company.r2_cert.cert && edit"
          :disabled="!edit"
          @change="uploadR2Cert($event)"
          outlined
          @focus="r2CertFocused = !r2CertFocused"
          @blur="r2CertFocused = !r2CertFocused"
          :loading="r2CertFocused ? _uploading : false"
          dense
          label="Upload Certificate"
          :error="r2CertRequired"
          :error-messages="r2CertRequired ? 'Required.' : ''"
          style="max-width: 250px;"
        />
      </v-col>

      <v-col :cols="$vuetify.breakpoint.smAndUp ? 5 : 12" class="mb-2 pr-4">
        <div class="pl-0 mb-0 text-subtitle-1 font-weight-bold">
          Do you have a Certificate of Resale?
        </div>
        <v-radio-group v-if="company" dense row class="mt-0" v-model="company.resale" @change="checkValidity" :disabled="!edit">
          <v-radio color="success" class="mt-0" label="Yes" :value="true"></v-radio>
          <v-radio color="success" class="mt-0" label="No" :value="false"></v-radio>
        </v-radio-group>
        <v-file-input
          v-if="company.resale && edit"
          :disabled="!edit"
          @change="uploadFile($event, 'resale')"
          outlined
          @focus="resaleFocused = !resaleFocused"
          @blur="resaleFocused = !resaleFocused"
          :loading="resaleFocused ? _uploading : false"
          dense
          label="Upload Resale Certificate"
          :error-messages="resaleRequired"
          style="max-width: 270px;"
        ></v-file-input>
        <!-- </v-card> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
export default {
  props: {
    edit: Boolean,
    setCertHasError: Function
  },
  emits: ['r2CertError'],

  data() {
    return {
      miscFocused: false,
      w9Focused: false,
      insuranceFocused: false,
      insuranceBase64: undefined,
      resaleRequired: null,
      resaleFocused: false,
      r2CertFocused: false,
      addCerts: {
        file: undefined,
        type: 'certs'
      },
      resale: false,
      addCertsBase64: undefined,
      additionalCerts: [],
      additionalUpBase64: {
        1: undefined
      },
      date: moment()
        .toISOString()
        .substr(1, 'days'),
      acceptTerms: false,
      eula: false,
      disabled: true,
      checkbox: false,
      step: 1,
      row: null,
      r2: false,
      r2date: moment()
        .subtract(1, 'days')
        .toISOString(),
      menu: false,
      attCount: 0,
      attSendCount: 0,
      attUploadCount: 0,
      additionalInfo: '',
      r2_date: undefined,
      fileUploading: {},
      r2CertRequired: false,
      uploadingR2: false
    };
  },
  mounted() {
    if (this.company) {
      this.company.resale = !!this.company.resale;
    }

    this.hadR2Cert = this.company?.r2_cert?.cert;

    setTimeout(() => {
      if (!this.company.r2_cert) {
        this.company.r2_cert = { cert: false, date: undefined };
      }
    }, 2000);
  },
  watch: {
    _file() {
      if (!this.company.attachments) {
        this.company.attachments = [];
      }
      if (this._file.file.Key && this.fileUploading) {
        this.company.attachments.push({
          key: this._file.file.Key,
          location: this._file.file.Location,
          file_type: this.fileUploading.type,
          catagory: this.fileUploading.catagory,
          original_name: this.fileUploading.name
        });

        if (this.fileUploading.catagory === 'resale') {
          this.resaleRequired = null;
          this.setCertHasError(false);
        }

        if (this.uploadingR2) {
          this.uploadingR2 = false;
          this.r2CertRequired = false;
          this.$emit('r2CertError', false);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      _updateCompany: 'company/updateCompany',
      _uploadFile: 'uploads/uploadFile'
    }),
    ...mapMutations({
      _setCompany: 'company/setCompany'
    }),

    uploadR2Cert(e, type = 'certification') {
      if (!e) return;

      const file = {
        file: e,
        original_name: e.name,
        type
      };
      this.fileUploading.file = e;
      this.fileUploading.name = e.name;
      this.fileUploading.catagory = type;
      this.fileUploading.type = e.type;
      this.uploadingR2 = true;

      this._uploadFile(file).then(res => {
        this.r2CertRequired = false;
        this.$emit('r2CertError', false);
      });
    },
    isR2Uploaded() {
      if (!this.company.attachments?.[0]) return false;

      const r2Type = 'certification';
      return this.company.attachments.some(a => a.catagory === r2Type);
    },
    checkR2Validity() {
      if (this.company.r2_cert?.cert && !this.isR2Uploaded()) {
        this.r2CertRequired = true;
        this.$emit('r2CertError', true);
        return;
      }

      this.r2CertRequired = false;
      this.$emit('r2CertError', false);
    },
    resetR2State() {
      this.r2CertRequired = false;
    },
    resetResaleState() {
      this.resaleRequired = null;
      this.setCertHasError(false);
    },

    uploadFile(e, type) {
      if (e && e.name) {
        this.fileUploading.file = e;
        this.fileUploading.name = e.name;
        this.fileUploading.catagory = type;
        this.fileUploading.type = e.type;
        let file = {
          file: e,
          original_name: e.name,
          type: type
        };
        this._uploadFile(file);
      } else if (!e) {
        return;
      }
    },
    checkValidity() {
      if (this.company.resale && !this.checkIfAttachmentUploaded('resale')) {
        this.resaleRequired = 'Required.';
        this.setCertHasError(true);
        return;
      }

      this.resaleRequired = null;
      this.setCertHasError(false);
    },
    checkIfAttachmentUploaded(type) {
      if (!Array.isArray(this.company.attachments) || this.company.attachments.length <= 0) {
        return false;
      }

      let found = false;
      this.company.attachments.map(v => {
        if (v.catagory === type) {
          found = true;
        }
      });
      return found;
    }
  },
  computed: {
    ...mapGetters({
      _company_id: 'company/getCompanyId',
      _company: 'company/getCompany',
      _file: 'uploads/getFile',
      _loading: 'company/getLoading',
      _uploading: 'uploads/loading'
      // _company: "company/getCompany",
      // _uploading: "uploads/loading",
    }),
    company: {
      get() {
        return this._company;
      },
      set(newVal) {
        this._setCompany(newVal);
      }
    },
    maxCertDate() {
      return moment()
        .subtract(1, 'days')
        .toISOString();
    },
    dateFormatted: {
      get() {
        if (this.company && this.company.r2_cert) {
          return moment(this.company.r2_cert.date).format('MM/DD/YYYY');
        } else {
          return undefined;
        }
      },
      set(value) {
        let date = moment(value).toISOString();
        this.r2_date = date;
      }
    },
    beforeToday() {
      return moment(this.dateFormatted).isBefore(moment(), 'day');
    }
  }
};
</script>
