import {
  create,
  getAll,
  get,
  deleteJob,
  saveJob,
  unSaveJob,
  sendEmailAwardedJob,
  sendEmailAccountingWhenBillingUpdated,
  update,
  pdfGenerate,
  upsertJobInterest,
  createJobHistory
} from '../../api';
import router from '@/router';
import moment from 'moment';
import { TRUE } from 'sass';
import circle from 'vue2-google-maps/dist/components/circle';

// initial job state
const state = {
  jobs: [],
  jobHistory: undefined,
  currentJob: {
    status: undefined,
    saved: undefined,
    recycle_specs: {
      resale: undefined
    }
  },
  loading: false,
  loadingForDraft: false,
  error: undefined,
  status: 'open',
  jobPreCheck: false,
  createNewJob: false,
  closingConf: false,
  disputeConfirm: false,
  closeConfirm: false,
  adminJobDetails: false,
  newJob: {},
  greenGloveAlert: false,
  editNewJob: false, // Vu added
  showWarningUnsavedChange: false, //huy added
  showCloseOutDialog: false,
  showJobNoLongerOpenDialog: false, //huy added
  showReasonRejectDialog: false //huy added
};

// getters
const getters = {
  getNewJob: state => {
    return state.newJob;
  },
  getJobs: state => {
    return state.jobs;
  },
  getJobHistory: state => {
    return state.jobHistory;
  },
  getCurrentJob: state => {
    if (state.currentJob) {
      state.currentJob.final_weight2 = state.currentJob.final_weight;

      if (state.currentJob.asset_type === null) {
        state.currentJob.asset_type = {
          network: false,
          raw: false,
          mobile: false,
          infr: false,
          other_checked: false,
          other: ''
        };
      }
      if (state.currentJob.asset_type) {
        try {
          state.currentJob.asset_type = JSON.parse(state.currentJob.asset_type);
        } catch (ex) { }
      }

      //handle issue category is undefined
      if (
        state.currentJob.attachments &&
        state.currentJob.attachments.length > 0
      ) {
        state.currentJob.attachments = state.currentJob.attachments.filter(
          att => {
            return !!att.catagory;
          }
        );
      }
    }
    return state.currentJob;
  },
  loading: state => {
    return state.loading;
  },
  loadingForDraft: state => {
    return state.loadingForDraft;
  },
  getStatus: state => {
    return state.status;
  },
  getJobPreCheck: state => {
    return state.jobPreCheck;
  },
  getCreateNewJob: state => {
    return state.createNewJob;
  },
  getEditNewJob: state => {
    // Vu added
    return state.editNewJob;
  },
  getShowWarningUnsavedChange: state => {
    // Vu added
    return state.showWarningUnsavedChange;
  },
  getClosingConf: state => {
    console.log('getClosingConf', state.closingConf);
    return state.closingConf;
  },
  getDisputeConfirm: state => {
    return state.disputeConfirm;
  },
  getCloseConfirm: state => {
    return state.closeConfirm;
  },
  getAdminJobDetails: state => {
    return state.adminJobDetails;
  },
  getGreenGloveAlert: state => {
    return state.greenGloveAlert;
  },
  getShowJobNoLongerOpenDialog: state => {
    return state.showJobNoLongerOpenDialog;
  },
  getShowCloseOutDialog: state => {
    return state.showCloseOutDialog;
  },
  getShowReasonRejectDialog: state => {
    //huy added
    console.log('getShowReasonRejectDialog', state.showReasonRejectDialog);
    return state.showReasonRejectDialog;
  }
};

const route = '/job';

// actions
const actions = {
  saveJob: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    try {
      return saveJob(payload)
        .then(() => {
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  unSaveJob: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    try {
      return unSaveJob(payload)
        .then(() => {
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  checkCurrentJobStatus: async ({ commit, rootState }, payload) => {
    console.log('call checkCurrentJobStatus', payload);
    // commit("toggleLoading", true);
    try {
      var user = '?user=' + rootState.user.user.id;
    } catch (e) {
      return;
    }

    if (!payload) {
      return;
    }

    try {
      return get(route, payload, user)
        .then(res => {
          let rs = res.response;
          console.log('checkCurrentJobStatus', res);
          //console.log("rs",rs);
          //console.log("current job",res.data.data);
          //console.log("current payload",payload);
          //console.log("current rootState",rootState);
          //console.log("current company",rootState.company.company.type_id);
          if (rs && rs.status != 200) {
            //error from api
            //  console.log("error from api",rs);
            //  commit("toggleLoading", false);
            commit('setShowJobNoLongerOpenDialog', true);
            //  commit("toggleConfirm", false);

            return new Promise((resolve, reject) => {
              //   commit("toggleLoading", false);
              resolve(false);
            });
          } else {
            return new Promise((resolve, reject) => {
              //   commit("toggleLoading", false);
              resolve(true);
            });
          }
          // if(rootState.company.company.type_id==2)//check is vendor
          // {
          //   if(res.data.data.status!="open"){

          //   }
          // }
          // if (window.history.length > 2) {
          //  this.$router.go(-1);
          //}
        })
        .catch(err => {
          console.log('checkCurrentJobStatus', err);
          commit('setShowJobNoLongerOpenDialog', true);
          return new Promise((resolve, reject) => {
            //  commit("toggleLoading", payload);
            resolve(false);
          });
        });
    } catch (err) {
      commit('setShowJobNoLongerOpenDialog', true);
      return new Promise((resolve, reject) => {
        // commit("toggleLoading", false);
        resolve(false);
      });
      //commit("setError", err);
      //commit("toggleLoading", false);
    }
  },
  setCurrent: async ({ commit, rootState }, payload) => {
    const u = rootState.user;
    commit('toggleLoading', true);
    try {
      // console.log("try setCurrent u",u);
      var user = '?user=' + rootState.user.user.id;
      // console.log("try setCurrent u",u);
    } catch (e) {
      /*   setTimeout(() => {
          console.log("error setCurrent rootState.user 871",u);
        console.log("error setCurrent rootState.user 8711",u.user);
        console.log("error setCurrent rootState.user 87111",u.user.id);
      }, 2000);

      console.log("error setCurrent rootState.user 00",u); 
      console.log("error setCurrent rootState.user 0",u.loading);
      console.log("error setCurrent rootState.user 1",u.user);
      console.log("error setCurrent rootState.user 11",typeof u.user);
      console.log("error setCurrent rootState.user 000",u); 
      console.log("error setCurrent rootState.user 0000",u['id']);
      console.log("error setCurrent rootState.user 2",u.user.id);
    */
      return;
    }

    if (!payload) {
      return;
    }

    try {
      return get(route, payload, user)
        .then(res => {
          let rs = res.response;
          //console.log("rs",rs);
          //console.log("current job",res.data.data);
          //console.log("current payload",payload);
          //console.log("current rootState",rootState);
          //console.log("current company",rootState.company.company.type_id);
          if (rs && rs.status != 200) {
            //error from api
            console.log('error from api', rs);
            commit('toggleLoading', false);
            commit('setShowJobNoLongerOpenDialog', true);
          } else {
            commit('setCurrentJob', res.data.data);
            commit('toggleLoading', false);
          }
          // if(rootState.company.company.type_id==2)//check is vendor
          // {
          //   if(res.data.data.status!="open"){

          //   }
          // }
          // if (window.history.length > 2) {
          //  this.$router.go(-1);
          //}
        })
        .catch(err => {
          console.log('current job', err);
          commit('setCurrentJob', undefined);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  exportHistory: async ({ state, commit, rootState }, payload) => {
    commit('toggleLoading', true);

    const constructedRoute = route + '?_export=true';
    try {
      return getAll(constructedRoute)
        .then(res => {
          //commit("setJobHistory", res.data.jobs);
          console.log('_exportJobs', res.data.data);
          let csv = JSONToCSVConvertor(res.data.data, 'csv report', true, true);
          console.log('csv', csv);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setJobHistory', undefined);
          commit('toggleLoading', false);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getHistory: async ({ state, commit, rootState }, payload) => {
    // console.log("getHistory state",state);
    // if(state.loading){
    //   console.log("getHistory is loading",state);
    //   return;
    // }
    commit('toggleLoading', true);
    try {
      payload.status = 'closed';
      payload.company_id = rootState.company.company.id;
      payload.bid_status = 'closed';
      payload.company_type = rootState.company.company.type_id;
      payload.user = rootState.user.user.id;
      payload.cert = rootState.company.company.r2_cert
        ? rootState.company.company.r2_cert.cert
        : false;
    } catch (err) {
      return err;
    }
    const constructedRoute =
      route + '?options=' + encodeURIComponent(JSON.stringify(payload));
    try {
      return getAll(constructedRoute)
        .then(res => {
          commit('setJobHistory', res.data.jobs);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setJobHistory', undefined);
          commit('toggleLoading', false);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getAll: async ({ state, commit, rootState }, payload) => {
    commit('toggleLoading', true);
    try {
      payload.status = state.status;
      payload.company_type = rootState.company.company.type_id;
      payload.cert = rootState.company.company.r2_cert
        ? rootState.company.company.r2_cert.cert
        : false;
      if (state.status === 'open_bid') {
        payload.status = 'open';
        payload.bid_status = 'open';
      } else if (state.status === 'open') {
        payload.bid_status = 'none';
      } else if (
        state.status == 'awarded' ||
        state.status == 'closed' ||
        state.status == 'resale' ||
        state.status == 'closing'
      ) {
        payload.bid_status = 'awarded';
      }
      if (state.status === 'dispute') {
        payload.bid_status = 'dispute';
      }
      if (state.status === 'rejected') {
        payload.bid_status = 'rejected';
      }
      if (rootState.company.company) {
        payload.company_id = rootState.company.company.id;
      } else {
        return;
      }
      if (rootState.user.user) {
        payload.user = rootState.user.user.id;
      } else {
        return;
      }
    } catch (err) {
      return err;
    }
    const constructedRoute =
      route + '?options=' + encodeURIComponent(JSON.stringify(payload));
    try {
      return getAll(constructedRoute)
        .then(res => {
          commit('setJobs', res.data.jobs);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setJobs', []);
          commit('toggleLoading', false);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
      commit('setJobs', []);
      commit('toggleLoading', false);
    }
  },
  //bid_per_pound
  createJob: async ({ commit, dispatch }, payload) => {
    commit('toggleLoading', true);
    payload.bid_status = 'none';
    try {
      let certified_recycle = payload.rec_spec.certified_recycle || false;
      let send_mail_to_vendors = payload.send_mail_to_vendors || false;
      var vendor_codes = payload.vendor_codes || [];
      return create(route, payload)
        .then(async res => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('setCurrentJob', {});
          // dispatch("setCurrent", router.currentRoute.params.id);
          let response = res.data.data.shift();
          response.company_name = payload.company_name;
          response.poc_email = payload.poc_email;
          response.company_email = payload.company_email;
          let obj = {
            id: response.id,
            certified_recycle: certified_recycle,
            send_mail_to_vendors:
              vendor_codes.length > 0 || send_mail_to_vendors ? true : false
          };
          if (payload.status !== 'draft') {
            await dispatch('sendEmailJobOpenForBid', obj);
            await dispatch('sendMailToAdmin', response);
          }
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Create failed', {
            root: true
          });
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
    }
    commit('toggleLoading', false);
  },
  sendEmailAwardedJobInfo: async ({ commit }, req) => {
    try {
      let id = req.id;
      let vendor_id = req.vendor_id;
      return sendEmailAwardedJob(id, { vendor_id: vendor_id, pdf: req.pdf })
        .then(res => {
          if (!res) {
            commit(
              'notification/setNotificationMsg',
              'Dashboard encountered an error, please try again later!',
              {
                root: true
              }
            );
            commit('notification/setNotificationType', 'error', { root: true });
            commit('notification/setNotification', true, { root: true });
          } else if (res.data.message != '') {
            commit('notification/setNotificationMsg', res.data.message, {
              root: true
            });
            commit('notification/setNotificationType', 'success', {
              root: true
            });
            commit('notification/setNotification', true, { root: true });
          }
          setTimeout(() => {
            window.location.reload();
          }, 400);

          //window.location.reload();
        })
        .catch(err => {
          commit('notification/setNotificationType', 'error', { root: true });
          // commit('notification/setNotificationMsg', 'Send email failed', {
          //   root: true
          // });
          commit(
            'notification/setNotificationMsg',
            'Dashboard encountered an error, please try again later!',
            {
              root: true
            }
          );
          commit('notification/setNotification', true, { root: true });

          setTimeout(() => {
            window.location.reload();
          }, 600);
        });
    } catch (err) { }
  },
  sendEmailJobOpenForBid: ({ commit }, payload) => {
    try {
      let requireCertifiedRecycling = payload.certified_recycle
        ? 'true'
        : 'false';
      let sendmail = payload.send_mail_to_vendors;
      if (!sendmail) {
        return;
      }
      const params =
        `send-email-job-open-for-bid?requireCertifiedRecycling=${requireCertifiedRecycling}` +
        (payload.id ? `&id=${payload.id}` : '') +
        (payload.listVendorId
          ? `&listVendorId=${payload.listVendorId}&isSendMailWhenChangeVendorCodes=true`
          : '');
      return get(route, params)
        .then(res => {
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('notification/setNotification', true, { root: true });
        })
        .catch(err => {
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Send email failed', {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
        });
    } catch (err) {
      console.log(err);
    }
  },
  sendEmailClosedJobInfo: ({ commit }, req) => {
    try {
      return get(route + '/send-email-closed-job-info', req)
        .then(res => {
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('notification/setNotification', true, { root: true });
        })
        .catch(err => {
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Send email failed', {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
        });
    } catch (err) { }
  },
  getClosingDocs: ({ commit }, payload) => {
    commit('toggleLoading', true);
    const thisRoute = '/media/cod';
    return new Promise((resolve, reject) => {
      pdfGenerate(thisRoute, payload)
        .then(res => {
          console.log(res);
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  toggleLoading: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('toggleLoading', payload);
      resolve();
    });
  },
  updateJob: ({ commit, dispatch }, payload) => {
    let disableLoading = false;

    if (payload.disableLoading) {
      disableLoading = true;
      delete payload.disableLoading;
    }

    if (!disableLoading) {
      commit('toggleLoading', true);
    }

    var certified_recycle = payload.rec_spec
      ? payload.rec_spec.certified_recycle
      : false;
    var send_mail_to_vendors = payload.send_mail_to_vendors || false;
    var isSendMail = payload.isSendMail;
    var vendor_codes = payload.vendor_codes || [];
    delete payload.isSendMail;

    var isSendMailWhenChangeVendorId = payload.isSendMailWhenChangeVendorId;
    return new Promise((resolve, reject) => {
      update(route, payload)
        .then(res => {
          dispatch('setCurrent', res.data.data.id);
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', {
            root: true
          });

          if (!disableLoading) {
            commit('toggleLoading', false);
            commit('setShowReasonRejectDialog', false);
          }
          if (isSendMail) {
            let response = res.data.data;
            response.company_name = payload.company_name;
            response.poc_email = payload.poc_email;
            response.company_email = payload.company_email;
            let obj = {
              id: response.id,
              certified_recycle: certified_recycle,
              send_mail_to_vendors:
                vendor_codes.length > 0 || send_mail_to_vendors ? true : false
            };
            if (payload.status !== 'draft') {
              dispatch('sendEmailJobOpenForBid', obj);
              dispatch('sendMailToAdmin', response);
            }
          }
          if (isSendMailWhenChangeVendorId) {
            let response = res.data.data;
            let obj = {
              id: response.id,
              certified_recycle: certified_recycle,
              send_mail_to_vendors: true,
              isSendMailWhenChangeVendorCodes: true,
              listVendorId: payload.listVendorId
            };
            dispatch('sendEmailJobOpenForBid', obj);
          }
          resolve(res);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', err.response.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'error', { root: true });
          if (!disableLoading) {
            commit('toggleLoading', false);
          }
          reject(err);
        });
    });
  },
  updateJobForCloseOutForm: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      update(route, payload)
        .then(res => {
          // commit('notification/setNotification', true, { root: true });
          // commit('notification/setNotificationMsg', res.data.message, {
          //   root: true
          // });
          // commit('notification/setNotificationType', 'success', {
          //   root: true
          // });
          //return res;
          resolve(res);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', err.response.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'error', { root: true });
          if (!disableLoading) {
            commit('toggleLoading', false);
          }
          reject(err);
        });
    });
  },
  sendMailToAdmin: async ({ commit, rootState }, payload) => {
    try {
      // let emails = process.env.VUE_APP_EMAIL_ADMIN ? process.env.VUE_APP_EMAIL_ADMIN : 'kimberly@recyclegx.com';
      // let emails = ["heckmanle@gmail.com", "vu.hoang@transxsystems.com"];
      // let emails = ["info@recyclegx.com"];
      // emails.forEach((email) => {
      //});
      let bid_due_date = moment(payload.bid_due_date).format(
        'MM/DD/YYYY hh:mm A'
      );
      let pickup_due_date = moment(payload.end_date).format(
        'MM/DD/YYYY hh:mm A'
      );
      /*let data = {
          from: payload.company_email,
          to: email,
          subject: "New Request for Bids on RGX Platform",
          html:
            "<p>RGX Team, " +
            payload.company_name +
            " has created " +
            payload.name +
            ".</p>" +
            "<p>Job Contact: " +
            payload.poc_email +
            "</p>" +
            "<p>Bid Due Date: " +
            pickup_due_date +
            "</p>" +
            "<p>Pick Up Due Date: " +
            bid_due_date +
            "</p>",
        }; return create("/media/email/send", data)*/
      let msg = {
        company_name: payload.company_name,
        job_name: payload.name,
        poc_email: payload.poc_email,
        pickup_due_date: pickup_due_date,
        bid_due_date: bid_due_date,
        customer_name: payload.company_name
      };
      return create('/media/email/sendgrid/new_request_for_bid_admin', msg)
        .then(res => {
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
        })
        .catch(err => {
          commit('notification/setNotificationMsg', err.response.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotification', true, { root: true });
        });
    } catch (err) {
      console.log(err);
    }
  },
  sendGreenGloveEmail: ({ rootGetters }, payload) => {
    return new Promise((reject, resolve) => {
      payload.company = rootGetters['company/getCompanyName'];
      payload.email = rootGetters['user/getUser'].email;
      const constructedRoute = `${route}/green?options=${encodeURIComponent(
        JSON.stringify(payload)
      )}`;
      getAll(constructedRoute)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendEmailAccountingWhenBillingUpdated: async ({ commit }, req) => {
    try {
      return sendEmailAccountingWhenBillingUpdated()
        .then(res => {
          return res.data;
        })
        .catch(err => {
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Send email failed', {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
        });
    } catch (err) { }
  },
  deleteJob: ({ commit }, payload) => {
    // commit('toggleLoading', true);
    try {
      return deleteJob(payload)
        .then(res => {
          // commit('toggleLoading', false);
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', {
            root: true
          });
          return res.data;
        })
        .catch(err => {
          commit('setError', err);
          // commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      // commit('toggleLoading', false);
    }
  },
  createJobHistory: async ({ commit, dispatch }, payload) => {
    commit('toggleLoading', true);
    try {
      let res = await createJobHistory(payload);
      commit('notification/setNotification', true, { root: true });
      // commit('notification/setNotificationMsg', res.data.message, {
      //   root: true
      // });
      commit('notification/setNotificationType', 'success', { root: true });
      return res;
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
    }
    commit('toggleLoading', false);
  },
  upsertJobVendorInterest: ({ commit }, payload) => {
    //commit('toggleLoading', true);
    try {
      return upsertJobInterest(payload)
        .then(() => {
          //commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setError', err);
          // commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      // commit('toggleLoading', false);
    }
  },
  updateJob_v2: async (
    { commit, dispatch },
    { payload, disableLoading = false }
  ) => {
    if (!disableLoading) {
      commit('toggleLoading', true);
    }

    try {
      const res = await update(route, payload);
      await dispatch('setCurrent_v2', {
        payload: res.data.data.id,
        disableLoading: disableLoading
      });

      commit('notification/setNotification', true, { root: true });
      commit(
        'notification/setNotificationMsg',
        res.data.message || res.message,
        { root: true }
      );
      commit('notification/setNotificationType', 'success', { root: true });

      return res;
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Unknown error';
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', errorMessage, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      throw err;
    } finally {
      if (!disableLoading) {
        commit('toggleLoading', false);
        commit('setShowReasonRejectDialog', false);
      }
    }
  },
  setCurrent_v2: async (
    { commit, rootState },
    { payload, disableLoading = false }
  ) => {
    const user = rootState.user;

    if (!disableLoading) {
      commit('toggleLoading', true);
    }

    if (!payload) {
      commit('toggleLoading', false);
      return;
    }

    const userQuery = `?user=${rootState.user.user.id}`;

    try {
      const res = await get(route, payload, userQuery);
      const { response: rs } = res;

      if (rs && rs.status !== 200) {
        console.log('error from api', rs);
        commit('toggleLoading', false);
        commit('setShowJobNoLongerOpenDialog', true);
      } else {
        commit('setCurrentJob', res.data.data);
      }
    } catch (err) {
      console.log('current job', err);
      commit('setCurrentJob', undefined);
      commit('setError', err);
    } finally {
      if (!disableLoading) {
        commit('toggleLoading', false);
      }
    }
  }
};

// mutations
const mutations = {
  setNewJob: (state, job) => {
    state.newJob = job;
  },
  toggleLoading: (state, loading) => {
    state.loading = loading;
  },
  setLoadingForDraft: (state, loadingForDraft) => {
    state.loadingForDraft = loadingForDraft;
  },
  setJobs: (state, jobs) => {
    state.jobs = jobs;
  },
  setJob: (state, job) => {
    state.currentJob = job;
  },
  setJobHistory: (state, jobs) => {
    state.jobHistory = jobs;
  },
  setCurrentJob: (state, currentJob) => {
    state.currentJob = currentJob;
  },
  setStatus: (state, status) => {
    state.status = status;
  },
  setError: (state, err) => {
    state.error = err;
  },
  setJobPreCheck: (state, jobPreCheck) => {
    state.jobPreCheck = jobPreCheck;
  },
  setCreateNewJob: (state, createNewJob) => {
    state.createNewJob = createNewJob;
  },
  setEditNewJob: (state, editNewJob) => {
    // Vu added
    state.editNewJob = editNewJob;
  },
  setShowWarningUnsavedChange: (state, showWarningUnsavedChange) => {
    // minhlnd
    state.showWarningUnsavedChange = showWarningUnsavedChange;
  },
  setClosingConf: (state, closingConf) => {
    console.log('setClosingConf', closingConf);
    state.closingConf = closingConf;
  },
  setDisputeConfirm: (state, disputeConfirm) => {
    state.disputeConfirm = disputeConfirm;
  },
  setCloseConfirm: (state, closeConfirm) => {
    state.closeConfirm = closeConfirm;
  },
  setAdminJobDetails: (state, boolean) => {
    state.adminJobDetails = boolean;
  },
  setGreenGloveAlert: (state, boolean) => {
    state.greenGloveAlert = boolean;
  },
  setShowJobNoLongerOpenDialog: (state, boolean) => {
    //huy added
    state.showJobNoLongerOpenDialog = boolean;
  },
  setShowCloseOutDialog: (state, boolean) => {
    state.showCloseOutDialog = boolean;
  },
  setShowReasonRejectDialog: (state, boolean) => {
    //huy added
    console.log('setShowReasonRejectDialog', boolean);
    state.showReasonRejectDialog = boolean;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
