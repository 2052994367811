<template>
  <div>
    <v-card outlined v-if="_job.logistics != 2">
      <v-row align="center" justify="center" class="bidTitleRow">
        <div class="bidTitle">
          Bid Offer
        </div>
        <div class="bidInterestBtns">
          <v-btn
            icon
            small
            text
            v-if="_job.interest === true"
            color="green"
            class="mr-2"
            :loading="_loading"
            title="Remove Thumbs Up"
            dark
            @click="toggleInterest(null)"
          >
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
          <v-btn icon small text v-else color="grey" class="mr-2" :loading="_loading" title="Thumbs Up" dark @click="toggleInterest(true)">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>

          <v-btn
            icon
            small
            text
            v-if="_job.interest === false"
            color="red"
            class="mr-2"
            :loading="_loading"
            title="Remove Thumbs Down"
            dark
            @click="toggleInterest(null)"
          >
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            text
            v-else
            color="grey"
            class="mr-2"
            :loading="_loading"
            title="Thumbs Down"
            dark
            @click="toggleInterest(false)"
          >
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
        </div>
      </v-row>

      <v-container @click="checkIfProfileIncomplete">
        <v-card flat tile>
          <v-card-subtitle class="subtitle-1 pa-0">
            Are you bidding to purchase scrap?*
            <tool-tip :text="`Pending RGX`" />
          </v-card-subtitle>

          <v-form ref="biddingToPurchaseValidRef" v-model="biddingToPurchaseValid">
            <v-radio-group row v-model="biddingToPurchase" required :rules="[radioRules.required]" @change="checkValidity()">
              <v-radio class="small-radio" :value="true" label="Yes"></v-radio>
              <v-radio class="small-radio" :value="false" label="No"></v-radio>
            </v-radio-group>
          </v-form>

          <v-container v-if="biddingToPurchase" style="padding-right: 10px!important; padding-left: 10px!important">
            <v-card flat tile>
              <v-card-subtitle class="subtitle-1 pa-0">
                Are you bidding per pound or putting in an all-in purchase price?*
              </v-card-subtitle>
              <v-radio-group row v-model="bidType" ref="bidType" @change="updateBidType()" :error-messages="errorMessages">
                <v-radio class="small-radio" :value="true" label="Bid Per Pound"></v-radio>
                <v-radio class="small-radio" :value="false" label="Bid All-In Scrap Purchase"></v-radio>
              </v-radio-group>
              <v-form ref="bidForm" v-model="vendorPayPerPoundValid">
                <v-text-field
                  v-show="bidType"
                  type="money"
                  label="Guaranteed Minimum Offer to Pay"
                  prepend-icon="mdi-currency-usd"
                  append-outer-icon="/lb"
                  v-model.lazy="vendorPayPerPound"
                  v-money="money"
                  :error-messages="vendorPayPerPoundErrorMessage"
                  :rules="[rules.payVal, rules.negativeBid]"
                  @input="checkValidity()"
                  @blur="checkBiddingType()"
                ></v-text-field>
              </v-form>
              <v-form ref="bidForm" v-model="vendorPayAllInValid">
                <v-text-field
                  v-show="bidType === false"
                  type="money"
                  prepend-icon="mdi-currency-usd"
                  label="Guaranteed Minimum Offer to Pay"
                  v-model.lazy="vendorPayAllIn"
                  :error-messages="vendorPayAllInErrorMessage"
                  :rules="[rules.paidVal, rules.negativeBid]"
                  maxlength="14"
                  v-money="money"
                  @input="checkValidity()"
                  @blur="checkBiddingType()"
                ></v-text-field>
              </v-form>
            </v-card>
          </v-container>
        </v-card>

        <v-card flat tile>
          <v-card-subtitle class="subtitle-1 pa-0">
            Is there pick up charge or other service fee?*
            <tool-tip :text="`Pending RGX`" />
          </v-card-subtitle>
          <v-form ref="pickUpChargeRef" v-model="pickUpChargeValid">
            <!-- <v-radio-group row v-model="pickUpCharge" required :rules="[radioRusles.required]"
              @change="checkValidity()">
              <v-radio class="small-radio" :value="true" label="Yes"></v-radio>
              <v-radio class="small-radio" :value="false" label="No"></v-radio>
            </v-radio-group> -->

            <v-radio-group row v-model="pickUpCharge" required :rules="[]" @change="checkValidity()">
              <v-radio class="small-radio" :value="true" label="Yes"></v-radio>
              <v-radio class="small-radio" :value="false" label="No"></v-radio>
            </v-radio-group>
          </v-form>

          <v-container fluid v-if="pickUpCharge">
            <v-form ref="bidForm" v-model="customerExpenseValid">
              <v-text-field
                type="money"
                prepend-icon="mdi-currency-usd"
                label="Guaranteed Maximum Expense"
                v-model.lazy="customerExpense"
                v-money="money"
                :error-messages="customerExpenseErrorMessage"
                :rules="[rules.paidVal, rules.negativeBid]"
                maxlength="14"
                @input="checkValidity()"
                @blur="checkPickUpCharge()"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card>

        <v-card flat tile>
          <v-card-subtitle class="subtitle-1 pa-0">
            Facility Location and Contact
          </v-card-subtitle>
          <v-container fluid>
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="12" md="8">
                <v-autocomplete
                  class="small-radio"
                  :items="_sites"
                  item-text="name"
                  :error-messages="siteErrorMessages"
                  label="Site*"
                  return-object
                  :readonly="locationExpand"
                  v-model="selectedSite"
                  :filter="customFilter"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <div style="font-size: 16px;">
                        {{ `${item.name}, ID ${item.site_id}` }}
                      </div>
                      <div style="font-size: 0.8em;  color: grey;">
                        {{ `${item.street}, ${item.city}, ${item.state} ${item.zip}` }}
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn rounded block small color="secondary" :disabled="locationExpand" @click="locationExpand = true">
                  <v-icon>mdi-plus</v-icon>
                  Add Location
                </v-btn>
              </v-col>

              <v-expand-transition>
                <div v-show="locationExpand" class="mx-0 pa-0">
                  <v-form v-model="locationValid" ref="locationForm">
                    <v-text-field label="Site Name*" :rules="[rules.required]" v-model="site.name"></v-text-field>
                    <VueGooglePlaces
                      :api-key="googleApi"
                      types="geocode"
                      style="width: 100%"
                      version="3.41"
                      country="us"
                      @placechanged="onPlaceChanged"
                    >
                      <v-text-field
                        v-model="address"
                        prepend-icon="mdi-map-search"
                        label="Address Finder..."
                        :placeholder="finderPlaceholder"
                      />
                    </VueGooglePlaces>
                    <v-card class="pl-8" flat>
                      <v-row no-gutters>
                        <v-col sm="6">
                          <v-text-field label="Address *" :rules="[addressRules.required]" dense v-model="site.street" class="pr-2" />
                        </v-col>
                        <v-col sm="6">
                          <v-text-field :rules="[cityRules.required]" dense label="City *" v-model="site.city" />
                        </v-col>

                        <v-col sm="6">
                          <v-autocomplete
                            v-model="site.state"
                            :items="usStates"
                            item-text="text"
                            item-value="value"
                            label="State *"
                            :rules="[stateRules.required]"
                            class="pr-2"
                          />
                        </v-col>

                        <v-col sm="3">
                          <v-text-field
                            dense
                            label="Zip Code *"
                            v-mask="'#####'"
                            :rules="[zipCodeRules.required, zipCodeRules.isCorrectFormat]"
                            class="pr-2"
                            v-model="site.zip"
                            style="margin-top: 22px"
                          />
                        </v-col>
                        <v-col sm="3">
                          <v-text-field dense label="Suite" v-model="site.suite" style="margin-top: 22px" />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                  <div class="justify-end">
                    <v-btn rounded color="error" elevation="0" height="35" class="white--text mb-2 mr-2" @click="cancelSiteCreation">
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="secondary"
                      elevation="0"
                      :loading="_siteLoading"
                      height="35"
                      :disabled="!locationValid"
                      class="white--text mb-2"
                      @click="createSite"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>

              <v-col cols="12" md="8">
                <v-autocomplete
                  class="small-radio"
                  label="Point of Contact*"
                  :items="_users"
                  :error-messages="userErrorMessages"
                  item-text="user_name"
                  return-object
                  v-model="selectedUser"
                  :disabled="POCExpand"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn block rounded small color="secondary" :disabled="POCExpand" @click="POCExpand = !POCExpand">
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-expand-transition>
                  <div v-show="POCExpand" class="mx-0">
                    <v-form ref="userForm" v-model="userValid">
                      <v-text-field v-show="false" label="User Name" v-model="POC.user_name"></v-text-field>
                      <div class="flex-container">
                        <v-text-field label="First Name *" :rules="[rules.required]" v-model="POC.first_name"></v-text-field>
                        <v-spacer />
                        <v-text-field label="Last Name" v-model="POC.last_name"></v-text-field>
                      </div>
                      <v-text-field label="Email *" :rules="[rules.required, rules.emailMatch]" v-model="POC.email"></v-text-field>
                      <div class="flex-container">
                        <v-text-field
                          label="Phone *"
                          v-mask="'(###)###-####x######'"
                          :rules="[rules.required, rules.phoneLength]"
                          v-model="POC.phone"
                        ></v-text-field>
                        <v-spacer />
                        <v-text-field label="Alt. Phone" v-mask="'(###)###-####'" v-model="POC.alt_phone"></v-text-field>
                      </div>
                    </v-form>

                    <div class="justify-end">
                      <v-btn rounded color="error" elevation="0" height="35" class="white--text mb-2 mr-2" @click="POCExpand = !POCExpand">
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="secondary"
                        :disabled="!userValid"
                        elevation="0"
                        height="35"
                        class="white--text mb-2"
                        @click="createUser"
                        :loading="_POCLoading"
                      >
                        Save
                      </v-btn>
                    </div>
                  </div>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card flat tile>
          <v-checkbox class="pb-0 mb-0" v-model="confirmPickUp" :error-messages="confirmErrorMessage">
            <template v-slot:label>
              <div style="font-size: 14px">
                I can pick up the equipment before the due date:
                <span class="green-text">{{ formatDate(_job.end_date) }}</span>
              </div>
            </template>
          </v-checkbox>

          <v-checkbox
            class="pt-0 mt-0"
            v-if="_job.commodity_report"
            v-model="confirmCommodityReport"
            :error-messages="confirmProvideCommodityReportErrorMessage"
          >
            <template v-slot:label>
              <div style="font-size: 14px" v-if="listCommodityReportTemplateFiles.length > 0">
                <span>I can provide the required&#160;</span>
                <span class="green-text underline" @click.stop.prevent="viewFile(0)">
                  commodity report
                </span>
                <span>.</span>
                <span>
                  <v-btn icon v-on:click.stop="viewFile(0)">
                    <v-icon color="secondary">mdi-file-find</v-icon>
                  </v-btn>
                </span>
              </div>
              <div style="font-size: 14px" v-if="listCommodityReportTemplateFiles.length == 0">
                I can provide a commodity breakout.
              </div>
            </template>
          </v-checkbox>
        </v-card>

        <v-container class="mx-2">
          <v-row v-if="!(_currentBid && _currentBid.job_id == _job.job_id && _currentBid.company_id == this._compId)">
            <v-col cols="12" sm="12">
              <v-btn
                :disabled="!valid"
                v-if="!isChecking"
                rounded
                class="elevation-0"
                block
                color="secondary"
                @click.native="confirmBid(true)"
              >
                Review Bid
              </v-btn>
              <v-btn v-if="isChecking" rounded class="elevation-0" block color="secondary">
                Processing
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="_currentBid && _currentBid.job_id == _job.job_id && _currentBid.company_id == this._compId">
            <v-col cols="3" sm="3" class="d-flex align-center justify-center">
              <span class="error-title pointer mt-2" @click="$emit('show-vendor-review-box')">
                CANCEL
              </span>
            </v-col>
            <v-col cols="9" sm="9">
              <v-btn
                :disabled="!valid"
                v-if="!isChecking"
                rounded
                class="elevation-0"
                block
                color="secondary"
                @click.native="confirmBid(true)"
              >
                Review Bid
              </v-btn>
              <v-btn v-if="isChecking" rounded class="elevation-0" block color="secondary">
                Processing
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-card>

    <v-card outlined v-if="_job.logistics == 2">
      <v-row align="center" justify="center" class="bidTitleRow">
        <div class="bidTitle">Drop-Off Bid Offer</div>
        <div class="bidInterestBtns">
          <v-btn
            icon
            small
            text
            v-if="_job.interest === true"
            color="green"
            class="mr-2"
            :loading="_loading"
            title="Remove Thumbs Up"
            dark
            @click="toggleInterest(null)"
          >
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
          <v-btn icon small text v-else color="grey" class="mr-2" :loading="_loading" title="Thumbs Up" dark @click="toggleInterest(true)">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>

          <v-btn
            icon
            small
            text
            v-if="_job.interest === false"
            color="red"
            class="mr-2"
            :loading="_loading"
            title="Remove Thumbs Down"
            dark
            @click="toggleInterest(null)"
          >
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            text
            v-else
            color="grey"
            class="mr-2"
            :loading="_loading"
            title="Thumbs Down"
            dark
            @click="toggleInterest(false)"
          >
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
        </div>
      </v-row>

      <v-container @click="checkIfProfileIncomplete">
        <v-card flat tile>
          <v-card-subtitle class="subtitle-1 pa-0">
            Are you bidding to purchase scrap?*
            <tool-tip :text="`Pending RGX`" />
          </v-card-subtitle>

          <v-form ref="biddingToPurchaseValidRef" v-model="biddingToPurchaseValid">
            <v-radio-group row v-model="biddingToPurchase" required :rules="[radioRules.required]" @change="checkValidityForDropOff()">
              <v-radio class="small-radio" :value="true" label="Yes"></v-radio>
              <v-radio class="small-radio" :value="false" label="No"></v-radio>
            </v-radio-group>
          </v-form>

          <v-container v-if="biddingToPurchase" style="padding-right: 10px!important; padding-left: 10px!important">
            <v-card flat tile>
              <v-card-subtitle class="subtitle-1 pa-0">
                Are you bidding per pound or putting in an all-in purchase price?*
              </v-card-subtitle>
              <v-radio-group row v-model="bidType" ref="bidType" @change="updateBidType()" :error-messages="errorMessages">
                <v-radio class="small-radio" :value="true" label="Bid Per Pound"></v-radio>
                <v-radio class="small-radio" :value="false" label="Bid All-In Scrap Purchase"></v-radio>
              </v-radio-group>
              <v-form ref="bidForm" v-model="vendorPayPerPoundValid">
                <v-text-field
                  v-show="bidType"
                  type="money"
                  label="Guaranteed Minimum Offer to Pay"
                  prepend-icon="mdi-currency-usd"
                  append-outer-icon="/lb"
                  v-model.lazy="vendorPayPerPound"
                  v-money="money"
                  :error-messages="vendorPayPerPoundErrorMessage"
                  :rules="[rules.payVal, rules.negativeBid]"
                  @input="checkValidityForDropOff()"
                  @blur="checkBiddingType()"
                ></v-text-field>
              </v-form>
              <v-form ref="bidForm" v-model="vendorPayAllInValid">
                <v-text-field
                  v-show="bidType === false"
                  type="money"
                  prepend-icon="mdi-currency-usd"
                  label="Guaranteed Minimum Offer to Pay"
                  v-model.lazy="vendorPayAllIn"
                  :error-messages="vendorPayAllInErrorMessage"
                  :rules="[rules.paidVal, rules.negativeBid]"
                  maxlength="14"
                  v-money="money"
                  @input="checkValidityForDropOff()"
                  @blur="checkBiddingType()"
                ></v-text-field>
              </v-form>
            </v-card>
          </v-container>
        </v-card>

        <v-card flat tile>
          <v-card-subtitle class="subtitle-1 pa-0">
            Is there a service fee?*
            <tool-tip :text="`Pending RGX`" />
          </v-card-subtitle>
          <v-form ref="pickUpChargeRef" v-model="pickUpChargeValid">
            <v-radio-group row v-model="pickUpCharge" required :rules="[radioRules.required]" @change="checkValidityForDropOff()">
              <v-radio class="small-radio" :value="true" label="Yes"></v-radio>
              <v-radio class="small-radio" :value="false" label="No"></v-radio>
            </v-radio-group>
          </v-form>

          <v-container fluid v-if="pickUpCharge">
            <v-form ref="bidForm" v-model="customerExpenseValid">
              <v-text-field
                type="money"
                prepend-icon="mdi-currency-usd"
                label="Guaranteed Maximum Expense"
                v-model.lazy="customerExpense"
                v-money="money"
                :error-messages="customerExpenseErrorMessage"
                :rules="[rules.paidVal, rules.negativeBid]"
                maxlength="14"
                @input="checkValidityForDropOff()"
                @blur="checkPickUpCharge()"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card>

        <v-card flat tile>
          <v-card-subtitle class="subtitle-1 pa-0">
            Facility Location and Contact
          </v-card-subtitle>
          <v-container fluid>
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="12" md="8">
                <v-autocomplete
                  class="small-radio"
                  :items="_sites"
                  item-text="name"
                  :error-messages="siteErrorMessages"
                  label="Site*"
                  return-object
                  :readonly="locationExpand"
                  v-model="selectedSite"
                  @change="checkValidityForDropOff()"
                  :filter="customFilter"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <div style="font-size: 16px;">
                        {{ `${item.name}, ID ${item.site_id}` }}
                      </div>
                      <div style="font-size: 0.8em;  color: grey;">
                        {{ `${item.street}, ${item.city}, ${item.state} ${item.zip}` }}
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn rounded block small color="secondary" :disabled="locationExpand" @click="locationExpand = true">
                  <v-icon>mdi-plus</v-icon>
                  Add Location
                </v-btn>
              </v-col>

              <v-expand-transition>
                <div v-show="locationExpand" class="mx-0 pa-0">
                  <v-form v-model="locationValid" ref="locationForm">
                    <v-text-field label="Site Name*" :rules="[rules.required]" v-model="site.name"></v-text-field>
                    <VueGooglePlaces
                      :api-key="googleApi"
                      types="geocode"
                      style="width: 100%"
                      version="3.41"
                      country="us"
                      @placechanged="onPlaceChanged"
                    >
                      <v-text-field
                        v-model="address"
                        prepend-icon="mdi-map-search"
                        label="Address Finder..."
                        :placeholder="finderPlaceholder"
                      />
                    </VueGooglePlaces>
                    <v-card class="pl-8" flat>
                      <v-row no-gutters>
                        <v-col sm="6">
                          <v-text-field label="Address *" :rules="[addressRules.required]" dense v-model="site.street" class="pr-2" />
                        </v-col>
                        <v-col sm="6">
                          <v-text-field :rules="[cityRules.required]" dense label="City *" v-model="site.city" />
                        </v-col>

                        <v-col sm="6">
                          <v-autocomplete
                            v-model="site.state"
                            :items="usStates"
                            item-text="text"
                            item-value="value"
                            label="State *"
                            :rules="[stateRules.required]"
                            class="pr-2"
                          />
                        </v-col>

                        <v-col sm="3">
                          <v-text-field
                            dense
                            label="Zip Code *"
                            v-mask="'#####'"
                            :rules="[zipCodeRules.required, zipCodeRules.isCorrectFormat]"
                            class="pr-2"
                            v-model="site.zip"
                            style="margin-top: 22px"
                          />
                        </v-col>
                        <v-col sm="3">
                          <v-text-field dense label="Suite" v-model="site.suite" style="margin-top: 22px" />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                  <div class="justify-end">
                    <v-btn rounded color="error" elevation="0" height="35" class="white--text mb-2 mr-2" @click="cancelSiteCreation">
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="secondary"
                      elevation="0"
                      :loading="_siteLoading"
                      height="35"
                      :disabled="!locationValid"
                      class="white--text mb-2"
                      @click="createSite"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>

              <v-col cols="12" md="8">
                <v-autocomplete
                  class="small-radio"
                  label="Point of Contact*"
                  :items="_users"
                  :error-messages="userErrorMessages"
                  item-text="user_name"
                  return-object
                  v-model="selectedUser"
                  :disabled="POCExpand"
                  @change="checkValidityForDropOff()"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn block rounded small color="secondary" :disabled="POCExpand" @click="POCExpand = !POCExpand">
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-expand-transition>
                  <div v-show="POCExpand" class="mx-0">
                    <v-form ref="userForm" v-model="userValid">
                      <v-text-field v-show="false" label="User Name" v-model="POC.user_name"></v-text-field>
                      <div class="flex-container">
                        <v-text-field label="First Name *" :rules="[rules.required]" v-model="POC.first_name"></v-text-field>
                        <v-spacer />
                        <v-text-field label="Last Name" v-model="POC.last_name"></v-text-field>
                      </div>
                      <v-text-field label="Email *" :rules="[rules.required, rules.emailMatch]" v-model="POC.email"></v-text-field>
                      <div class="flex-container">
                        <v-text-field
                          label="Phone *"
                          v-mask="'(###)###-####x######'"
                          :rules="[rules.required, rules.phoneLength]"
                          v-model="POC.phone"
                        ></v-text-field>
                        <v-spacer />
                        <v-text-field label="Alt. Phone" v-mask="'(###)###-####'" v-model="POC.alt_phone"></v-text-field>
                      </div>
                    </v-form>

                    <div class="justify-end">
                      <v-btn rounded color="error" elevation="0" height="35" class="white--text mb-2 mr-2" @click="POCExpand = !POCExpand">
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="secondary"
                        :disabled="!userValid"
                        elevation="0"
                        height="35"
                        class="white--text mb-2"
                        @click="createUser"
                        :loading="_POCLoading"
                      >
                        Save
                      </v-btn>
                    </div>
                  </div>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card flat tile>
          <v-checkbox class="pb-0 mb-0" v-model="confirmPickUp" :error-messages="confirmErrorMessage" @change="checkValidityForDropOff()">
            <template v-slot:label>
              <div style="font-size: 14px">
                I will accept this equipment
                <span style="background-color: #4CAF50; color: white;">
                  dropped off
                </span>
                :
                <span class="green-text">
                  {{ formatDate(_job.end_date) }}
                </span>
                <span class="green-text">
                  {{ getReviewValueByValue(config.EXACT_REMOVE_DATE_OPTIONS, _job.exact_removal_date) }}
                </span>
              </div>
            </template>
          </v-checkbox>

          <v-checkbox
            class="pt-0 mt-0"
            v-if="_job.commodity_report"
            v-model="confirmCommodityReport"
            @change="checkValidityForDropOff()"
            :error-messages="confirmProvideCommodityReportErrorMessage"
          >
            <template v-slot:label>
              <!-- <div style="font-size: 14px">
                I can provide the required commodity report
              </div> -->
              <div style="font-size: 14px" v-if="listCommodityReportTemplateFiles.length > 0">
                <span>I can provide the required&#160;</span>
                <span class="green-text underline" @click.stop.prevent="viewFile(0)">
                  commodity report
                </span>
                <span>.</span>
                <span>
                  <v-btn icon v-on:click.stop="viewFile(0)">
                    <v-icon color="secondary">mdi-file-find</v-icon>
                  </v-btn>
                </span>
              </div>
              <div style="font-size: 14px" v-if="listCommodityReportTemplateFiles.length == 0">
                I can provide a commodity breakout.
              </div>
            </template>
          </v-checkbox>
        </v-card>

        <v-container class="mx-2">
          <v-row v-if="!(_currentBid && _currentBid.job_id == _job.job_id && _currentBid.company_id == this._compId)">
            <v-col cols="12" sm="12">
              <v-btn
                :disabled="!validForDropOff"
                v-if="!isChecking"
                rounded
                class="elevation-0"
                block
                color="secondary"
                @click.native="confirmBid(true)"
              >
                Review Bid
              </v-btn>
              <v-btn v-if="isChecking" rounded class="elevation-0" block color="secondary">
                Processing
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="_currentBid && _currentBid.job_id == _job.job_id && _currentBid.company_id == this._compId">
            <v-col cols="3" sm="3" class="d-flex align-center justify-center">
              <span class="error-title pointer mt-2" @click="$emit('show-vendor-review-box')">
                CANCEL
              </span>
            </v-col>
            <v-col cols="9" sm="9">
              <v-btn
                :disabled="!validForDropOff"
                v-if="!isChecking"
                rounded
                class="elevation-0"
                block
                color="secondary"
                @click.native="confirmBid(true)"
              >
                Review Bid
              </v-btn>
              <v-btn v-if="isChecking" rounded class="elevation-0" block color="secondary">
                Processing
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-card>

    <v-dialog v-model="showDiaglog" persistent width="470" @click:outside="closeDialog()">
      <v-card flat style="padding: 20px 10px 20px 20px" align="center" justify="space-around" v-if="company_status == 'New'">
        <div class="btn-dismiss-dialog">
          <v-btn text @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-flex>
          <v-card flat>
            <div class="notification-card">
              <div class="icon"><span class="mdi mdi-information color-title-popup-info"></span></div>
              <h2 class="pb-3 color-title-popup-info">New Account Limited Access</h2>
              <p>As a new account, you cannot bid on open jobs until RGX reviews your request to join the platform.</p>
              <br />
              <p>
                Our staff will contact you shortly to verify your company. Eager to get started? You can review the open jobs so that you
                are ready to go!
              </p>
            </div>
          </v-card>
        </v-flex>
      </v-card>

      <v-card flat style="padding: 20px 50px" align="center" justify="space-around" v-if="company_status != 'New'">
        <div class="btn-dismiss-dialog">
          <v-btn text @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-flex>
          <!-- <v-img contain max-width="32px" :src="require('@/assets/warning.png')" /> -->
          <!-- <v-card class="pa-1" flat v-if="company_status == 'New'">
            <h2 style="color:#0091ff;text-transform: uppercase;">
              Account Under Review
            </h2>
            <div style="text-align:left;padding:5px">
              Your new account is pending RGX approval. Contact
              <a style="color:rgb(0, 145, 255);text-decoration: underline;" href="mailto:info@recyclegx.com">
                info@recyclegx.com
              </a>
              with any questions.
              <br />
              <br />
              Eager to get started? Complete your vendor profile while waiting for full access.

              <br />
              <br />
            </div>

            <v-btn rounded color="secondary" @click="toVendor('register/vendor')">
              Complete Profile
            </v-btn>
            <br />
          </v-card> -->

          <v-img contain max-width="32px" :src="require('@/assets/warning.png')" v-if="company_status != 'New'" />
          <h2 v-if="company_status != 'New'" class="mt-4 save--text">
            YOUR COMPANY PROFILE IS INCOMPLETE
          </h2>
          <p v-if="company_status != 'New'" style="text-align: left;">
            Vendors must complete their company profile before bidding on any job.
          </p>
          <p v-if="company_status != 'New' && _user && (_user.type_id === 2 || _user.type_id === 4)" style="text-align: left;">
            Contact your account administrator to complete the profile.
          </p>
          <v-btn
            v-if="company_status != 'New' && _user && _user.type_id === 1"
            class="mt-4"
            large
            rounded
            color="success"
            @click="toVendor('register/VendorOnboarding')"
          >
            Complete Profile
          </v-btn>
          <!-- Vu added the button for RGX-145 -->
          <v-btn
            v-if="company_status != 'New' && _user && (_user.type_id === 2 || _user.type_id === 4)"
            class="mt-4"
            medium
            rounded
            color="primary"
            @click="closeDialog()"
          >
            Close
          </v-btn>
        </v-flex>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ToolTip from '@/components/app/ToolTip.vue';
var UsaStates = require('usa-states').UsaStates;
import config from '../../config';
import * as Helpers from '@/helpers/helpers';
//import AccountStatusDialog from "@/components/app/AccountStatusDialog.vue";
export default {
  directives: {
    mask
  },
  components: {
    //AccountStatusDialog,
    ToolTip
  },
  data() {
    return {
      config: config,
      company_status: '',
      googleApi: 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE',
      finderPlaceholder: null,
      address: '',
      showDiaglog: false,
      locationValid: false,
      userValid: false,
      locationExpand: false,
      POCExpand: false,
      isChecking: false,
      profileIsCompleted: null,

      customerExpenseValid: false,
      vendorPayAllInValid: false,
      vendorPayPerPoundValid: false,
      biddingToPurchaseValid: false,
      pickUpChargeValid: false,

      biddingToPurchase: null,
      bidType: null,
      bidPerPound: null,
      bidAllIn: null,
      pickUpCharge: null,

      vendorPayPerPound: null,
      vendorPayAllIn: null,
      customerExpense: null,
      money: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false /* doesn't work with directive */
      },

      selectedSite: null,
      selectedUser: null,

      confirmPickUp: false,
      confirmCommodityReport: false,

      errorMessages: null,
      siteErrorMessages: null,
      userErrorMessages: null,
      confirmErrorMessage: null,
      confirmProvideCommodityReportErrorMessage: null,
      customerExpenseErrorMessage: null,
      vendorPayPerPoundErrorMessage: null,
      vendorPayAllInErrorMessage: null,

      price: 0,

      valid: false,
      validForDropOff: false,

      usStates: new UsaStates({ includeTerritories: true }).states.map(state => {
        return {
          text: state.name + ' (' + state.abbreviation + ')',
          value: state.abbreviation
        };
      }),

      defaultPOC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: undefined,
        company_id: this._compId
      },

      POC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: undefined,
        company_id: this._compId
      },

      defaultSite: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined
      },

      site: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined
      },

      siteOptions: {
        // itemsPerPage: 15,
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['name'],
        sortDesc: [false]
      },

      userOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['user_name'],
        sortDesc: [false]
      },

      radioRules: {
        required: v => v !== null || 'This is a required field.'
      },

      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v => (!new RegExp(/undefined/).test(this.site.street) && this.site.street !== undefined) || 'Full address is required'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v => new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) || 'Please enter a valid zip code'
      },

      rules: {
        payVal: v => Number(v) <= 10.0 || 'Can not exceed $10.00/lb',
        paidVal: v => String(v).replace(/,/g, '') <= 1000000000000.0 || 'Can not exceed $1000000000000.00',
        negativeBid: v => !new RegExp(/-/g).test(v) || 'Invalid bid',
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        required: v => !!v || 'This is a required field.',
        confirmMatch: v => this.newUser.password === this.newUser.confirm_pass || 'The passwords you entered don’t match',
        length: v => (v && v.length >= 8) || 'Passwords must be at least 8 characters in length',
        phoneLength: v => (v && v.length >= 13) || 'Invalid phone number.',
        afterBidStart: v =>
          moment(this.newJob.start_date).isBefore(moment(this.newJob.end_date)) || 'Equipment due date must be later than bid due date',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(v) || 'Passwords require at least one special character',
        afterToday: v => moment(v) > moment() || "Must be later than today's date"
      },
      listCommodityReportTemplateFiles: []
    };
  },
  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      _sites: 'sites/getSites',
      _newSite: 'sites/createdSite',
      _users: 'user/getUsers',
      _newPOC: 'user/getNewTeamUser',
      _compId: 'company/getCompanyId',
      _siteLoading: 'sites/loading',
      _POCLoading: 'user/loading',
      _bid: 'bids/bid',
      _bidConfirm: 'bids/confirm',
      _user: 'user/getUser', // Vu added 10/23
      _company: 'company/getCompany',
      _currentBid: 'bids/getCurrentBid'
    }),
    bid: {
      get() {
        return this._bid;
      },
      set(newVal) {
        this._setBid(newVal);
      }
    }
  },
  async mounted() {
    this.company_status = this._company.status;
    this._getSites(this.siteOptions);
    this._getUsers(this.userOptions);

    this._getCompanyProfileStatus().then(profileStatus => {
      this.profileIsCompleted = profileStatus === config.companyProfileStatus.complete;
    });

    setTimeout(() => {
      this.finderPlaceholder = ' ';
    }, 2000);

    this.listCommodityReportTemplateFiles = this.getFilesByCategory(this._job.attachments, this.config.KEY.Commodity_Report_Template);

    //await this.setDataIfBided()
  },
  watch: {
    _users() {
      if (this._newPOC) {
        if (this._newPOC.newUser) {
          console.log('watch users bid this._newPOC.newUser', this._newPOC.newUser);
          var newUser = this._newPOC.newUser;
          newUser.user_id = newUser.id;
          this.selectedUser = newUser;
        }
      }
    },
    _sites() {
      if (this._newSite) {
        if (this._newSite) {
          this._newSite.site_id = this._newSite.id;
          this.selectedSite = this._newSite;
        }
      }
    },
    _currentBid(newVal) {
      console.log('watch _currentBid', newVal);
      if (_.isNil(newVal)) return;
      this.$nextTick(() => {
        this.selectedSite = this._sites.find(site => site.site_id == this._currentBid.site_id);
        this.selectedUser = this._users.find(user => user.user_id == this._currentBid.poc_id);

        this.biddingToPurchase = this._currentBid.has_purchase_scrap;
        this.pickUpCharge = this._currentBid.has_pickup_fee;
        this.vendorPayPerPound = this._currentBid.bid_per_pound;
        this.vendorPayAllIn = this._currentBid.bid_all_in_purchase;
        this.customerExpense = this._currentBid.bid_buy_out;
        this.bidType = _.isNil(this._currentBid.bid_all_in_purchase) && !_.isNil(this._currentBid.bid_per_pound) ? true : false;

        // this.valid = true;
        // this.validForDropOff = true;
        if (this._job.logistics == 2) {
          this.validForDropOff = false;
          this.checkValidityForDropOff();
        } else {
          this.valid = false;
          this.checkValidity();
        }
        // this.checkValidityForDropOff();
        // this.checkValidity();
      });
    }
  },
  methods: {
    ...Helpers,
    ...mapActions({
      _checkCurrentJobStatus: 'jobs/checkCurrentJobStatus',
      _getSites: 'sites/getAll',
      _getUsers: 'user/getAll',
      _createSite: 'sites/createSite',
      _createUser: 'user/createUser',
      _getCompanyProfileStatus: 'company/getCompanyProfileStatus',
      _upsertJobInterest: 'jobs/upsertJobVendorInterest',
      _setJob: 'jobs/setCurrent',
      _setJob_v2: 'jobs/setCurrent_v2'
    }),
    ...mapMutations({
      _toggleConfirm: 'bids/toggleConfirm',
      _setGoogleDoc: 'googledoc/setShowGoogleDocDialog',
      _setIndex: 'googledoc/setIndex',
      _setList: 'googledoc/setList',
      _setBid: 'bids/setBid'
      // _setShowAccountStatusDialog: "company/setShowAccountStatusDialog",
    }),
    onPlaceChanged(e) {
      this.site.street = e.street_number + ' ' + e.route;
      this.site.city = e.locality;
      this.site.state = e.administrative_area_level_1;
      this.site.zip = e.postal_code;
      this.address = '';
      if (e.street_number !== undefined) {
        this.address = this.address.concat(e.street_number);
      }
      if (e.route !== undefined) {
        this.address = this.address.concat(' ' + e.route);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.locality !== undefined) {
        this.address = this.address.concat(e.locality);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.administrative_area_level_1 !== undefined) {
        this.address = this.address.concat(e.administrative_area_level_1);
      }
      if (e.postal_code !== undefined) {
        this.address = this.address.concat(' ' + e.postal_code);
      }
      this.address = this.address.concat(', USA');
    },
    cancelSiteCreation() {
      this.$refs.locationForm.reset();
      this.locationExpand = !this.locationExpand;
    },
    createSite() {
      this._createSite(this.site).then(() => {
        this.locationExpand = false;
        this.site = this.defaultSite;
        this.$refs.locationForm.reset();
      });
    },

    cancelUserCreation() {
      this.$refs.userForm.reset();
      this.POCExpand = !this.POCExpand;
    },
    createUser() {
      if (this.POC.email !== undefined) {
        this.POC.type_id = 4;
        this.POC.password = 'N/A';

        var u = this.POC;
        u.createdEmail = this._user.email;
        u.createdUser = this._user.first_name + ' ' + (this._user.last_name ? this._user.last_name : '');
        u.user_name = this.POC.email;
        //   u.send_email_set_password=true; //==> remove send the email activate_account to new user

        this._createUser(u).then(({success}) => {
          if (!success) {
            return;
          }
          this.POC = this.defaultPOC;
          this.POCExpand = !this.POCExpand;
          this.$refs.userForm.reset();

          this._getUsers(this.userOptions);
        });
      }
    },
    checkBiddingType() {
      // this._setJob(this.$route.params.id);
      if (this.biddingToPurchase && this.bidType !== null) {
        if (this.bidType) {
          if (!this.vendorPayPerPound || this.vendorPayPerPound === '0.00') {
            this.vendorPayPerPoundErrorMessage = 'This is a required field.';
            hasErrors = true;
          } else {
            this.vendorPayPerPoundErrorMessage = null;
          }
        } else {
          if (!this.vendorPayAllIn || this.vendorPayAllIn === '0.00') {
            this.vendorPayAllInErrorMessage = 'This is a required field.';
            hasErrors = true;
          } else {
            this.vendorPayAllInErrorMessage = null;
          }
        }
      }
    },
    checkPickUpCharge() {
      //this._setJob(this.$route.params.id);
      if (this.pickUpCharge) {
        if (!this.customerExpense || this.customerExpense === '0.00') {
          this.customerExpenseErrorMessage = 'This is a required field.';
          hasErrors = true;
        } else {
          this.customerExpenseErrorMessage = null;
        }
      }
    },
    confirmBid(openReview) {
      if (this._currentBid && this._currentBid.company_id == this._compId && this._currentBid.job_id == this._job.job_id) {
        this.bid.isEditBid = true;
        (this.bid.onlyClickConfirm = false), (this.bid.id = this._currentBid.id);
      } else {
        this.bid.id = undefined;
      }

      var check = false;
      this.isChecking = true;
      console.log('_checkCurrentJobStatus valid', this.valid);
      this._checkCurrentJobStatus(this.$route.params.id)
        .then(res => {
          check = res;
          this.isChecking = false;
          console.log('_checkCurrentJobStatus', check);
          if (check) {
            let hasErrors = false;
            const biddingToPurchaseValidRef = this.$refs.biddingToPurchaseValidRef.validate();
            const pickUpChargeRef = this.$refs.pickUpChargeRef.validate();

            if (!biddingToPurchaseValidRef) {
              return;
            }

            if (!pickUpChargeRef) {
              return;
            }

            //if (!this.selectedSite) {
            if (!this.selectedSite || !this.selectedSite.name) {
              this.siteErrorMessages = 'You must pick a site location for this job.';
              hasErrors = true;
            } else {
              this.siteErrorMessages = null;
            }

            if (!this.selectedUser) {
              this.userErrorMessages = 'You must pick a point of contact for this job.';
              hasErrors = true;
            } else {
              this.userErrorMessages = null;
            }

            if (!this.confirmPickUp) {
              this.confirmErrorMessage = 'You must confirm that you can pick up the equipment before the due date.';
              hasErrors = true;
            } else {
              this.confirmErrorMessage = null;
            }

            if (this._job.commodity_report && !this.confirmCommodityReport) {
              this.confirmProvideCommodityReportErrorMessage = 'You must confirm that you can provide the required commodity report.';
              hasErrors = true;
            } else {
              this.confirmProvideCommodityReportErrorMessage = null;
            }

            if (this.biddingToPurchase && this.bidType === null) {
              this.errorMessages = ['This is a required field.'];
              //console.log("test 1");
              hasErrors = true;
            } else {
              this.errorMessages = null;
            }

            if (this.pickUpCharge) {
              if (!this.customerExpense || this.customerExpense === '0.00') {
                this.customerExpenseErrorMessage = 'This is a required field.';
                //console.log("test 2");
                hasErrors = true;
              } else {
                this.customerExpenseErrorMessage = null;
              }
            }

            if (this.biddingToPurchase && this.bidType !== null) {
              if (this.bidType) {
                if (!this.vendorPayPerPound || this.vendorPayPerPound === '0.00') {
                  this.vendorPayPerPoundErrorMessage = 'This is a required field.'; //recheck
                  // console.log("This is a required field: bidType: "+this.bidType+", vendorPayPerPound: "+this.vendorPayPerPound)
                  hasErrors = true;
                } else {
                  this.vendorPayPerPoundErrorMessage = null;
                }
              } else {
                if (!this.vendorPayAllIn || this.vendorPayAllIn === '0.00') {
                  this.vendorPayAllInErrorMessage = 'This is a required field.'; //recheck
                  // console.log("This is a required field: bidType: "+this.bidType+", vendorPayPerPound: "+this.vendorPayAllIn)
                  hasErrors = true;
                } else {
                  this.vendorPayAllInErrorMessage = null;
                }
              }
            }

            if (hasErrors) {
              // console.log("test 3");
              return;
            }

            if (this.pickUpCharge && !this.customerExpenseValid) {
              //  console.log("test 4");
              return;
            }

            if (this.biddingToPurchase) {
              // console.log("test 5");
              if ((!this.bidType && !this.vendorPayAllInValid) || (this.bidType && !this.vendorPayPerPoundValid)) {
                //   console.log("test 6");
                return;
              }
            }

            this.errorMessages = null;

            if (!this.profileIsCompleted) {
              this.showDiaglog = true;
              return;
            }

            this.bid.status = 'open';
            this.bid.job_id = Number(this.$route.params.id);
            this.bid.company_id = this._compId;
            this.bid.job_address = this._job.street + ', ' + this._job.city + ', ' + this._job.state + ', ' + this._job.zip;

            this.bid.has_purchase_scrap = this.biddingToPurchase;
            this.bid.has_pickup_fee = this.pickUpCharge;

            this.bid.bid_per_pound = this.bid.has_purchase_scrap ? this.vendorPayPerPound : '0';
            this.bid.bid_all_in_purchase = this.bid.has_purchase_scrap ? this.vendorPayAllIn : '0';
            this.bid.bid_buy_out = this.pickUpCharge ? this.customerExpense : '0';

            this.bid.site_id = this.selectedSite.site_id;
            this.bid.poc_id = this.selectedUser.user_id;

            this.bid.selectedSite = this.selectedSite;
            this.bid.selectedUser = this.selectedUser;

            this.bid.bidType = this.bidType;
            this.bid.hasJobWeight = this._job.weight ? true : false;

            const bidPerPound = parseFloat(this.bid.bid_per_pound.replace(/,/g, ''));
            const bidAllPurchase = parseFloat(this.bid.bid_all_in_purchase.replace(/,/g, ''));
            const bidBuyOut = parseFloat(this.bid.bid_buy_out.replace(/,/g, ''));

            if (this.bid.has_purchase_scrap && this.bidType && bidPerPound > 0 && this.pickUpCharge && bidBuyOut > 0) {
              this.bid.scenario = 1;
              delete this.bid.bid_all_in_purchase;
            }

            if (this.bid.has_purchase_scrap && !this.bidType && bidAllPurchase > 0 && this.pickUpCharge && bidBuyOut > 0) {
              this.bid.scenario = 2;
              delete this.bid.bidPerPound;
            }

            if (this.bid.has_purchase_scrap && !this.pickUpCharge) {
              this.bid.scenario = 3;
              this.bid.bid_buy_out = '0';
            }

            if (!this.bid.has_purchase_scrap && this.pickUpCharge) {
              this.bid.scenario = 4;
              this.bid.bid_all_in_purchase = '0';
              this.bid.bid_per_pound = '0';
            }

            console.log('scenario_biddingBox', this.bid.scenario);
            console.log('scenario_bid', this.bid);
            if (openReview) this._toggleConfirm(true);
          }
        })
        .catch(err => {});
    },
    closeDialog() {
      this.showDiaglog = false;
    },
    toVendor(page) {
      this.$router.push({ path: '/' + page });
    },
    checkValidity() {
      console.log('checkValidity');
      // this._checkCurrentJobStatus(this.$route.params.id)
      if (!this.biddingToPurchase && !this.pickUpCharge) {
        this.valid = false;
        return;
      }
      if (this.pickUpCharge && this.customerExpense && this.customerExpense !== '0.00') {
        this.valid = true;
        return;
      }

      if (this.biddingToPurchase && this.bidType !== null) {
        if (this.bidType) {
          if (this.vendorPayPerPound && this.vendorPayPerPound !== '0.00') {
            this.valid = true;
            return;
          }
        } else if (this.vendorPayAllIn && this.vendorPayAllIn !== '0.00') {
          this.valid = true;
          return;
        }
      }

      this.valid = false;
      return;
    },
    checkValidityForDropOff() {
      //this._checkCurrentJobStatus(this.$route.params.id);

      let requiredFieldIsValid =
        this.selectedUser &&
        this.selectedSite &&
        this.selectedSite.name && // handle case : site is not True or False => In here site is a object
        this.confirmPickUp &&
        (this._job.commodity_report && !this.confirmCommodityReport ? false : true);

      if (!requiredFieldIsValid) {
        this.validForDropOff = false;
        return;
      }

      if (!this.biddingToPurchase && !this.pickUpCharge) {
        this.validForDropOff = true;
        return;
      }
      if (this.pickUpCharge && this.customerExpense && this.customerExpense !== '0.00') {
        this.validForDropOff = true;
        return;
      }

      if (this.biddingToPurchase && this.bidType !== null) {
        if (this.bidType) {
          if (this.vendorPayPerPound && this.vendorPayPerPound !== '0.00') {
            this.validForDropOff = true;
            return;
          }
        } else if (this.vendorPayAllIn && this.vendorPayAllIn !== '0.00') {
          this.validForDropOff = true;
          return;
        }
      }

      this.validForDropOff = false;
      return;
    },

    updateBidType() {
      //this._setJob(this.$route.params.id);
      this.errorMessages = null;

      if (this.bidType) {
        this.bidPerPound = true;
      } else {
        this.bidAllIn = true;
      }
    },

    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    checkIfProfileIncomplete() {
      // console.log('company_status',this.company_status);
      console.log('11111', this.profileIsCompleted, this.company_status);
      this.showDiaglog = !this.profileIsCompleted || this.company_status == 'New';
    },
    buildSiteItemText(item) {
      return `${item.name}, ID ${item.site_id} ${item.street}, ${item.city}, ${item.state} ${item.zip}`;
    },
    customFilter(item, queryText, itemText) {
      itemText = `${item.name}, ID ${item.site_id} ${item.street}, ${item.city}, ${item.state} ${item.zip}`;
      return itemText.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    viewFile(index) {
      this._setList(this.listCommodityReportTemplateFiles);
      this._setIndex(index);
      this._setGoogleDoc(true);
    },
    async toggleInterest(v) {
      this._upsertJobInterest({
        company_id: this._company.id,
        job_id: this._job.job_id,
        user_id: this._user.id,
        interest: v
      }).then(async () => {
        let resData = {
          payload: this.$route.params.id,
          disableLoading: true
        };
        await this._setJob_v2(resData);
      });
    }
  }
};
</script>
<style scoped>
.v-label {
  font-size: 30px;
}

.bidTitle {
  text-align: center !important;
  max-width: 275px;
  margin: 0;
  font-size: 26px;
  position: absolute;
  font-weight: 500;
  left: 50%;
  transform: translateX(-50%);
}

.bidTitleRow {
  padding: 40px 0 15px;
  position: relative;
}

.subtitle-1 {
  font-weight: 500;
}

.jobDueDate {
  color: green;
  font-weight: 600;
}

.green-text {
  color: green;
  font-weight: 600;
}

.underline {
  text-decoration: underline;
  display: inline-block;
}

.small-radio >>> label {
  font-size: 14px;
}

.bidInterestBtns {
  margin-left: auto;
  padding-right: 20px;
}

.notification-card {
  background-color: white;
  border-radius: 8px;
  text-align: center;
}
.notification-card .icon {
  font-size: 45px;
}
.notification-card h2 {
  margin: 10px 0;
  font-size: 28px;
}
.notification-card p {
  margin: 5px 0;
  font-size: 16px;
  color: #333;
  text-align: left;
}

.color-title-popup-info {
  color: #008cba !important;
}
</style>
