<template>
  <div style="padding:5px">
    <v-card flat tile>
      <!--v-card-subtitle class="subtitle-1 pa-0"
            >Facility Location and Contact
          </v-card-subtitle-->
      <!--v-btn
                  rounded
                   style="width:140px;"
                  small
                  color="secondary"
                  @click="exportToPDF()"
                >
                  <v-icon>mdi-plus</v-icon>exportToPDF
                </v-btn-->
      <v-container fluid id="element-to-convert">
        <table style="width:100%">
          <tr v-if="_company.type_id != 3">
            <td style="vertical-align: top;width:50%">
              <div
                style="color:rgba(0, 0, 0, 0.6)"
                v-if="!edit || !changeLocation"
              >
                Billing Address
              </div>
              <div v-if="!edit || !changeLocation" style="font-size: 1rem;">
                <div v-if="_company.billing_site_id && _company.s_street">
                  {{ _company.s_street
                  }}{{ _company.s_suite ? ', Suite ' + _company.s_suite : '' }}
                </div>
                <div v-if="_company.billing_site_id && _company.s_city">
                  {{ _company.s_city }}, {{ _company.s_state }}
                  {{ _company.s_zip }}
                </div>
                <div v-if="!_company.billing_site_id || !_company.s_street">
                  Not Available
                </div>
              </div>
              <div style="font-size:13px">
                <a
                  v-if="edit && !changeLocation && _company.s_street"
                  href="javascript:"
                  @click="editAddress()"
                  class="green--text text--lighten-2"
                >
                  Edit Address
                </a>
                <span v-if="edit && !changeLocation && _company.s_street">
                  |
                </span>
                <a
                  v-if="edit && !changeLocation"
                  href="javascript:"
                  style="color:#0091ff"
                  @click="changeLocation = true"
                >
                  Change Location
                </a>
              </div>
              <div
                style="padding-right:20px"
                v-if="
                  edit &&
                    changeLocation &&
                    _user.type_id == 1 &&
                    _company.type_id != 3
                "
              >
                <v-autocomplete
                  class="small-radio"
                  :items="_sites"
                  item-text="name"
                  :error-messages="siteErrorMessages"
                  label="Billing Address"
                  return-object
                  :readonly="locationExpand"
                  v-model="selectedSite"
                  :filter="customFilter"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <div style="font-size: 16px;">
                        {{ `${item.name}, ID ${item.site_id}` }}
                      </div>
                      <div style="font-size: 0.8em;  color: grey;">
                        {{
                          `${item.street}, ${item.city}, ${item.state} ${item.zip}`
                        }}
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
                <!-- <v-select
                  class="small-radio"
                  :items="_sites"
                  item-text="name"
                  :error-messages="siteErrorMessages"
                  label="Billing Address"
                  return-object
                  :readonly="locationExpand"
                  v-model="selectedSite"
                ></v-select> -->
                <v-btn
                  rounded
                  style="width:140px;float:right"
                  small
                  color="secondary"
                  :disabled="locationExpand"
                  @click="locationExpand = true"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add Location
                </v-btn>
                &nbsp;
                <v-btn
                  v-if="edit && changeLocation && !locationExpand"
                  rounded
                  style="float:right;margin-right:5px"
                  small
                  color="error"
                  @click="changeLocation = false"
                >
                  Cancel
                </v-btn>
              </div>
            </td>

            <td style="vertical-align: top;width:50%;">
              <div
                style="color:rgba(0, 0, 0, 0.6)"
                v-if="!edit || !changeContact"
              >
                Billing Contact
              </div>
              <div v-if="!edit || !changeContact" style="font-size: 1rem;">
                <div>{{ _company.first_name }} {{ _company.last_name }}</div>
                <div>{{ _company.user_email }}</div>
                <div>{{ _company.user_phone }}</div>
                <div v-if="!_company.billing_team_id || !_company.user_email">
                  Not Available
                </div>
              </div>
              <div style="font-size:13px">
                <a
                  v-if="edit && !changeContact && _company.user_email"
                  href="javascript:"
                  class="green--text text--lighten-2"
                  @click="handleClickEditUser()"
                >
                  Edit Contact
                </a>
                <span v-if="edit && !changeContact && _company.user_email">
                  |
                </span>
                <a
                  v-if="edit && !changeContact"
                  href="javascript:"
                  style="color:#0091ff"
                  @click="changeContact = true"
                >
                  Change Contact
                </a>
              </div>

              <div
                style="padding-right:20px"
                v-if="
                  edit &&
                    changeContact &&
                    _user.type_id == 1 &&
                    _company.type_id != 3
                "
              >
                <v-autocomplete
                  class="small-radio"
                  label="Billing Contact"
                  :items="_users"
                  :error-messages="userErrorMessages"
                  item-text="user_name"
                  return-object
                  v-model="selectedUser"
                  :disabled="POCExpand"
                ></v-autocomplete>
                <v-btn
                  style="width:120px;float:right"
                  rounded
                  small
                  color="secondary"
                  :disabled="POCExpand"
                  @click="POCExpand = !POCExpand"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
                <v-btn
                  v-if="edit && changeContact && !POCExpand"
                  rounded
                  style="float:right;margin-right:5px"
                  small
                  color="error"
                  @click="changeContact = false"
                >
                  Cancel
                </v-btn>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <v-expand-transition>
                <div v-show="edit && locationExpand" class="mx-0 pa-2">
                  <v-form v-model="locationValid" ref="locationForm">
                    <v-text-field
                      label="Site Name*"
                      :rules="[rules.required]"
                      v-model="site.name"
                    ></v-text-field>
                    <VueGooglePlaces
                      :api-key="googleApi"
                      types="geocode"
                      style="width: 100%"
                      version="3.41"
                      country="us"
                      @placechanged="onPlaceChanged"
                    >
                      <v-text-field
                        v-model="address"
                        prepend-icon="mdi-map-search"
                        label="Address Finder..."
                        :placeholder="finderPlaceholder"
                      />
                    </VueGooglePlaces>
                    <v-card class="pl-8" flat>
                      <v-row no-gutters style="margin-top: 18px;">
                        <v-col sm="6">
                          <v-text-field
                            label="Address *"
                            :rules="[addressRules.required]"
                            dense
                            v-model="site.street"
                            class="pr-2"
                          />
                        </v-col>
                        <v-col sm="6">
                          <v-text-field
                            :rules="[cityRules.required]"
                            dense
                            label="City *"
                            v-model="site.city"
                          />
                        </v-col>

                        <v-col sm="6">
                          <v-autocomplete
                            v-model="site.state"
                            :items="usStates"
                            item-text="text"
                            item-value="value"
                            label="State *"
                            :rules="[stateRules.required]"
                            class="pr-2"
                          />
                        </v-col>

                        <v-col sm="3">
                          <v-text-field
                            dense
                            label="Zip Code *"
                            v-mask="'#####'"
                            :rules="[
                              zipCodeRules.required,
                              zipCodeRules.isCorrectFormat
                            ]"
                            class="pr-2"
                            v-model="site.zip"
                            style="margin-top: 22px"
                          />
                        </v-col>
                        <v-col sm="3">
                          <v-text-field
                            dense
                            label="Suite"
                            v-model="site.suite"
                            style="margin-top: 22px"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                  <div class="justify-end">
                    <v-btn
                      rounded
                      color="error"
                      elevation="0"
                      height="35"
                      class="white--text mb-2 mr-2"
                      @click="cancelSiteCreation"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="secondary"
                      elevation="0"
                      :loading="_siteLoading"
                      height="35"
                      :disabled="!locationValid"
                      class="white--text mb-2"
                      @click="createSite"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>
            </td>

            <td>
              <v-expand-transition>
                <div v-show="edit && POCExpand" class="mx-0 pa-2">
                  <v-form ref="userForm" v-model="userValid">
                    <v-text-field
                      v-show="false"
                      label="User Name"
                      v-model="POC.user_name"
                    ></v-text-field>
                    <div class="flex-container">
                      <v-text-field
                        label="First Name *"
                        :rules="[rules.required]"
                        v-model="POC.first_name"
                      ></v-text-field>
                      <v-spacer style="padding:5px" />
                      <v-text-field
                        label="Last Name"
                        v-model="POC.last_name"
                      ></v-text-field>
                    </div>
                    <v-text-field
                      label="Email *"
                      :rules="[rules.required, rules.emailMatch]"
                      v-model="POC.email"
                    ></v-text-field>
                    <div class="flex-container">
                      <v-text-field
                        label="Phone *"
                        v-mask="'(###)###-####x######'"
                        :rules="[rules.required, rules.phoneLength]"
                        v-model="POC.phone"
                      ></v-text-field>
                    </div>
                    <v-text-field
                      label="Alt. Phone"
                      v-mask="'(###)###-####'"
                      v-model="POC.alt_phone"
                    ></v-text-field>
                  </v-form>

                  <div class="justify-end"></div>
                  <div class="justify-end">
                    <v-btn
                      rounded
                      color="error"
                      elevation="0"
                      height="35"
                      class="white--text mb-2 mr-2"
                      @click="POCExpand = !POCExpand"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="secondary"
                      :disabled="!userValid"
                      elevation="0"
                      height="35"
                      class="white--text mb-2"
                      @click="createUser"
                      :loading="_POCLoading"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>
            </td>
          </tr>
        </table>
      </v-container>
      <create-site :edit="editSite" />
      <create-user
        :add="addNewUser"
        :editMe="editUser"
        :editUser="editableUser"
        @close="closeCreate()"
      />
    </v-card>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
//import ToolTip from "@/components/app/ToolTip.vue";
import CreateSite from '@/components/site/Create.vue';
import CreateUser from '@/components/team/CreateUser.vue';
var UsaStates = require('usa-states').UsaStates;
import config from '../../config';
export default {
  props: {
    edit: Boolean
  },
  directives: {
    mask
  },
  components: {
    CreateUser,
    CreateSite
  },
  data() {
    return {
      // createUser: false,
      editUser: false,
      editableUser: undefined,
      addNewUser: false,
      googleApi: 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE',
      finderPlaceholder: null,
      address: '',
      editSite: true,
      changeLocation: false,
      editContact: false,
      changeContact: false,
      showDiaglog: false,
      locationValid: false,
      userValid: false,
      locationExpand: false,
      POCExpand: false,
      isChecking: false,
      profileIsCompleted: null,

      customerExpenseValid: false,
      vendorPayAllInValid: false,
      vendorPayPerPoundValid: false,
      biddingToPurchaseValid: false,
      pickUpChargeValid: false,

      biddingToPurchase: null,
      bidType: null,
      bidPerPound: null,
      bidAllIn: null,
      pickUpCharge: null,

      vendorPayPerPound: null,
      vendorPayAllIn: null,
      customerExpense: null,
      money: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false /* doesn't work with directive */
      },

      selectedSite: null,
      selectedUser: null,

      confirmPickUp: false,

      errorMessages: null,
      siteErrorMessages: null,
      userErrorMessages: null,
      confirmErrorMessage: null,
      customerExpenseErrorMessage: null,
      vendorPayPerPoundErrorMessage: null,
      vendorPayAllInErrorMessage: null,

      price: 0,

      valid: false,

      usStates: new UsaStates({ includeTerritories: true }).states.map(
        state => {
          return {
            text: state.name + ' (' + state.abbreviation + ')',
            value: state.abbreviation
          };
        }
      ),

      defaultPOC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: undefined,
        company_id: this._compId
      },

      POC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: undefined,
        company_id: this._compId
      },

      defaultSite: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined
      },

      site: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined
      },

      siteOptions: {
        // itemsPerPage: 15,
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['name'],
        sortDesc: [false]
      },

      userOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['user_name'],
        sortDesc: [false]
      },

      radioRules: {
        required: v => v !== null || 'This is a required field.'
      },

      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v =>
          (!new RegExp(/undefined/).test(this.site.street) &&
            this.site.street !== undefined) ||
          'Full address is required'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v =>
          new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) ||
          'Please enter a valid zip code'
      },

      rules: {
        payVal: v => Number(v) <= 10.0 || 'Can not exceed $10.00/lb',
        paidVal: v =>
          String(v).replace(/,/g, '') <= 1000000000000.0 ||
          'Can not exceed $1000000000000.00',
        negativeBid: v => !new RegExp(/-/g).test(v) || 'Invalid bid',
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        required: v => !!v || 'This is a required field.',
        confirmMatch: v =>
          this.newUser.password === this.newUser.confirm_pass ||
          'The passwords you entered don’t match',
        length: v =>
          (v && v.length >= 8) ||
          'Passwords must be at least 8 characters in length',
        phoneLength: v => (v && v.length >= 13) || 'Invalid phone number.',
        afterBidStart: v =>
          moment(this.newJob.start_date).isBefore(
            moment(this.newJob.end_date)
          ) || 'Equipment due date must be later than bid due date',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(
            v
          ) || 'Passwords require at least one special character',
        afterToday: v =>
          moment(v) > moment() || "Must be later than today's date"
      }
    };
  },

  computed: {
    ...mapGetters({
      _company: 'company/getCompany',
      //_job: "jobs/getCurrentJob",
      _sites: 'sites/getSitesForDropdown',
      _users: 'user/getUsersForDropdown',
      _newTeamUser: 'user/getNewTeamUser',
      _newSite: 'sites/createdSite',
      _compId: 'company/getCompanyId',
      _siteLoading: 'sites/loading',
      _POCLoading: 'user/loading',
      _editedSite: 'sites/editedSite',
      _editedUser: 'user/getEditedUser',
      _deletedSite: 'sites/deletedSite',
      _deletedUser: 'user/deletedUser',
      // _siteDialog: "sites/siteDialog",
      // _bid: "bids/bid",
      // _bidConfirm: "bids/confirm",
      _user: 'user/getUser' //
    })

    // bid: {
    //   get() {
    //     return this._bid;
    //   },
    //   set(newVal) {
    //     this._setBid(newVal);
    //   },
    // },
  },
  // async created() {
  //   await this._getSites(this.siteOptions);
  //   await this._getUsers(this.userOptions);
  // },
  async mounted() {
    await this._getSites(this.siteOptions);
    await this._getUsers(this.userOptions);

    this.$refs.locationForm.reset();
    this.$refs.userForm.reset();
    this.locationExpand = false;
    this.POCExpand = false;

    this.finderPlaceholder = ' ';

    if (this._company.billing_site_id && this._company.s_street) {
      this.selectedSite = this._sites.filter(
        o => this._company.billing_site_id === o.site_id
      )[0];
    }

    if (this._company.billing_team_id && this._company.user_email) {
      this.selectedUser = this._users.filter(
        o => this._company.billing_team_id === o.user_id
      )[0];
    }
    if (!this.selectedSite) {
      this._company.billing_site_id = '';
    }
    if (!this.selectedUser) {
      this._company.billing_team_id = '';
    }
    setTimeout(() => {
      if (!this.selectedSite)
        this.selectedSite = this._sites.filter(
          o => this._company.billing_site_id === o.site_id
        )[0];
      if (!this.selectedUser)
        this.selectedUser = this._users.filter(
          o => this._company.billing_team_id === o.user_id
        )[0];
    }, 1000);
  },
  watch: {
    _deletedSite() {
      this._getSites(this.siteOptions);
      this._getCompany(this._deletedSite.company_id);
    },
    _deletedUser() {
      this._getUsers(this.userOptions);
      this._getCompany(this._deletedUser.company_id);
    },
    _editedSite() {
      this._getSites(this.siteOptions);
      this._getCompany(this._editedSite.company_id);
    },
    _editedUser() {
      this._getUsers(this.userOptions);
      // this._getCompany(this._editedUser.company_id);
    },
    selectedSite() {
      this._company.billing_site_id = this.selectedSite.site_id;

      // console.log("selectedSite",this.selectedSite.site_id);
    },
    selectedUser() {
      this._company.billing_team_id = this.selectedUser.user_id;
      // console.log("selectedUser",this.selectedUser.user_id );
    },
    edit() {
      if (!this.edit) {
        this.changeLocation = false;
        this.changeContact = false;
      }
      // console.log("edit",this.edit);
      // console.log("_sites",this._sites);
      // console.log("_users",this._users);
      // console.log("selectedUser",this.selectedUser );
      // console.log("selectedSite",this.selectedSite);
      console.log('edit form', this.edit);
      if (!this._sites) this._getSites(this.siteOptions);
      if (!this._users) this._getUsers(this.userOptions);
      if (!this.selectedSite) {
        if (this._company.billing_site_id && this._company.s_street)
          this.selectedSite = this._sites.filter(
            o => this._company.billing_site_id === o.site_id
          )[0];
      }
      if (!this.selectedUser) {
        if (this._company.billing_team_id && this._company.user_email)
          this.selectedUser = this._users.filter(
            o => this._company.billing_team_id === o.user_id
          )[0];
      }
      //   setTimeout(() => {
      //   if(!this.selectedSite) this.selectedSite=this._sites.filter((o) => this._company.billing_site_id === o.site_id)[0];
      //   if(!this.selectedUser) this.selectedUser=this._users.filter((o) => this._company.billing_team_id === o.user_id)[0];
      // }, 2000);
    },
    _sites() {
      if (!this.selectedSite) {
        if (this._company.billing_site_id && this._company.s_street)
          this.selectedSite = this._sites.filter(
            o => this._company.billing_site_id === o.site_id
          )[0];
      }
    },
    _users() {
      if (!this.selectedUser) {
        if (this._company.billing_team_id && this._company.user_email)
          this.selectedUser = this._users.filter(
            o => this._company.billing_team_id === o.user_id
          )[0];
      }
    }
  },
  methods: {
    ...mapActions({
      _toggleDialog: 'sites/toggleDialog',
      _toggleEdit: 'sites/toggleEdit',
      //  _checkCurrentJobStatus: "jobs/checkCurrentJobStatus",
      //_getSites: 'sites/getAll',
      _getSites: 'sites/getAllSitesForDropdown',
      //	_getSite: "sites/getCurrent",
      _getCompany: 'company/getCurrent',
      _getUsers: 'user/getAllUserForDropdown',
      _createSite: 'sites/createSite',
      _createUser: 'user/createUser',
      _toggleEditing: 'user/toggleEditing'
      //_getCompanyProfileStatus: "company/getCompanyProfileStatus",
    }),
    ...mapMutations({
      //_toggleConfirm: "bids/toggleConfirm",
    }),
    exportToPDF() {
      html2pdf(document.getElementById('element-to-convert'));
    },

    editAddress() {
      // this._getSite(this.selectedSite.site_id);

      //  console.log("vo handleClick _toggleEdit",this._siteDialog);
      //this.$emit("editSite", this.selectedSite);
      // this._toggleDialog(true);
      this._toggleEdit({ id: this.selectedSite.site_id, toggle: true });
    },
    onPlaceChanged(e) {
      console.log('vo onPlaceChanged', e);
      this.site.street = e.street_number + ' ' + e.route;
      this.site.city = e.locality;
      this.site.state = e.administrative_area_level_1;
      this.site.zip = e.postal_code;
      this.address = '';
      if (e.street_number !== undefined) {
        this.address = this.address.concat(e.street_number);
      }
      if (e.route !== undefined) {
        this.address = this.address.concat(' ' + e.route);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.locality !== undefined) {
        this.address = this.address.concat(e.locality);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.administrative_area_level_1 !== undefined) {
        this.address = this.address.concat(e.administrative_area_level_1);
      }
      if (e.postal_code !== undefined) {
        this.address = this.address.concat(' ' + e.postal_code);
      }
      this.address = this.address.concat(', USA');
    },
    cancelSiteCreation() {
      this.$refs.locationForm.reset();
      this.locationExpand = !this.locationExpand;
    },
    createSite() {
      this._createSite(this.site).then(() => {
        this.locationExpand = false;
        this.site = this.defaultSite;
        this.$refs.locationForm.reset();

        this._getSites(this.siteOptions);
        //  this.selectedSite=this._newTeamUser.newUser;
        console.log('_newSite', this._newSite);
        this.selectedSite = this._newSite;
        this.selectedSite.site_id = this._newSite.id;
        this._company.billing_site_id = this.selectedSite.site_id;
      });
    },

    cancelUserCreation() {
      this.$refs.userForm.reset();
      this.POCExpand = !this.POCExpand;
    },
    createUser() {
      if (this.POC.email !== undefined) {
        this.POC.type_id = 4;
        this.POC.password = 'N/A';
        this.POC.createdEmail = this._user.email;
        this.POC.createdUser =
          this._user.first_name +
          ' ' +
          (this._user.last_name ? this._user.last_name : '');
        // this.user.companyName=this._companyName;
        this.POC.user_name = this.POC.email;
        //  this.POC.send_email_set_password=true;//==> remove send the email activate_account to new user
        this._createUser(this.POC).then(({ success }) => {
          if (!success) {
            return;
          }
          this.POC = this.defaultPOC;
          this.POCExpand = !this.POCExpand;
          this.$refs.userForm.reset();

          this._getUsers(this.userOptions);
          console.log('_newTeamUser', this._newTeamUser);
          this.selectedUser = this._newTeamUser.newUser;
          this.selectedUser.user_id = this._newTeamUser.newUser.id;
          this._company.billing_team_id = this.selectedUser.user_id;
        });
      }
    },
    checkBiddingType() {
      // this._setJob(this.$route.params.id);
      if (this.biddingToPurchase && this.bidType !== null) {
        if (this.bidType) {
          if (!this.vendorPayPerPound || this.vendorPayPerPound === '0.00') {
            this.vendorPayPerPoundErrorMessage = 'This is a required field.';
            hasErrors = true;
          } else {
            this.vendorPayPerPoundErrorMessage = null;
          }
        } else {
          if (!this.vendorPayAllIn || this.vendorPayAllIn === '0.00') {
            this.vendorPayAllInErrorMessage = 'This is a required field.';
            hasErrors = true;
          } else {
            this.vendorPayAllInErrorMessage = null;
          }
        }
      }
    },
    checkPickUpCharge() {
      //this._setJob(this.$route.params.id);
      if (this.pickUpCharge) {
        if (!this.customerExpense || this.customerExpense === '0.00') {
          this.customerExpenseErrorMessage = 'This is a required field.';
          hasErrors = true;
        } else {
          this.customerExpenseErrorMessage = null;
        }
      }
    },

    closeDialog() {
      this.showDiaglog = false;
    },

    toVendor(page) {
      this.$router.push({ path: '/' + page });
    },
    handleClickEditUser() {
      if (this._user.type_id === 1) {
        this._toggleEditing({ toggle: true, user: this.selectedUser });
      }
    },
    closeCreate() {
      // this._getUsers();
      this.editUser = false;
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    customFilter(item, queryText, itemText) {
      itemText = `${item.name}, ID ${item.site_id} ${item.street}, ${item.city}, ${item.state} ${item.zip}`;
      return itemText.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    }
  }
};
</script>
<style scoped>
.v-label {
  font-size: 30px;
}
.bidTitle {
  font-weight: 500;
  text-align: center !important;
  max-width: 175px;
  margin: 22px auto 0px auto;
  font-size: 26px;
}
.subtitle-1 {
  font-weight: 500;
}
.jobDueDate {
  color: green;
  font-weight: 600;
}
.small-radio >>> label {
  font-size: 16px;
}
</style>
