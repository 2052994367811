<template>
  <v-dialog v-model="_showCompanyRejectDialog" @click:outside="closeDialog()" max-width="550px">
    <v-card class="pb-4" v-if="_currentCompanyStatus != 'Delete' && !error">
      <v-card-title class="pr-0 pl-6">
        Reason for Status Change
        <v-spacer />
        <v-btn text @click="closeDialog()">
          close
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card class="text-center" flat>
        <v-card-subtitle>Select the reasons for the status change (*)</v-card-subtitle>
      </v-card>
      <v-card class="pa-4" flat>
        <v-item-group>
          <v-col cols="12" sm="12">
            <v-form v-model="falsies" ref="falsies">
              <v-checkbox style="margin-top: -12px;margin-bottom: -12px;" v-for="(q, i) in questions" :key="i"
                @change="selectReason" v-model="q.answer" :label="q.question"></v-checkbox>
            </v-form>
            <v-checkbox style="margin-top: -12px;margin-bottom: -12px;" v-model="final.answer" label="Other"
              @change="selectReason()"></v-checkbox>
            <v-text-field @change="selectReason()" label="" placeholder="Enter the other reason" v-if="final.answer"
              v-model="final.question" style="margin-top: 0px" />
          </v-col>
        </v-item-group>
      </v-card>
      <v-card class="pa-2" flat style="margin-top: -35px;">
        <v-item-group>
          <v-col cols="12" sm="12">
            <v-card class="text-center" flat>
              <v-card-subtitle> You've selected the reason(s)</v-card-subtitle>
            </v-card>
            <v-textarea v-model="reasons" name="content" rows="2" style="overflow-y: scroll;" :readonly="true">
            </v-textarea>
          </v-col>
        </v-item-group>
      </v-card>
      <v-card-actions>
        <v-btn rounded color="error" @click="closeDialog()">Cancel</v-btn>
        <v-spacer />
        <v-btn rounded :disabled="!valid || _getLoading" color="secondary" @click="reject()">Update</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="!error && (_currentCompanyStatus == 'Delete')" class="pb-4" style="text-align:center">


      <v-card class="pa-4" flat>
        <h2 style="color:#0091ff">DELETE PERMANENTLY</h2>
        This company will be permanently deleted. <br>This <b>cannot</b> be undone.
      </v-card>
      <v-col cols="12" sm="12">

        <v-btn rounded class='btn-archive' @click="closeDialog()">Cancel</v-btn>
        <span style="padding:5px"></span>
        <v-btn rounded :disabled="_getLoading" color="error" @click="reject()">Delete</v-btn>

      </v-col>
    </v-card>
    <v-card v-if="error" class="pb-4" style="text-align:center">
      <v-card-title class="pr-0 pl-6">

        <v-spacer />
        <v-btn text @click="closeDialog()">

          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card class="pa-1" flat style="margin-top: -20px;"> <img :src="require('@/assets/warning.png')" />
        <h2 style="color:#0091ff">ACTIVE JOBS</h2>
        You cannot archive or delete a company with <br>active jobs in Awarded or Ready to Close.
        <br><br>
      </v-card>

    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  props: {
    confirm: {
      default: true,
      type: Boolean,
    },

  },
  data() {
    return {
      reasons: '',
      falsies: false,
      questions: [
        {
          question:
            "Company Verified",
          answer: false,
        },
        {
          question:
            "Improper Use of the Platform",
          answer: false,
        },
        {
          question:
            "Violating Terms of Use",
          answer: false,
        },

      ],
      final: {
        question: "",
        answer: false,
      },
      error: false,
      isVendor: false
    };
  },

  mounted() {
    console.log("mount CompanyRejectDialog", this._showCompanyRejectDialog);
    console.log("current Company", this._company);
    console.log("current _customer", this._customer);
    console.log("current _vendor", this._vendor);
    console.log("current status", this._currentCompanyStatus);
    if (window.location.href.indexOf('vendor') >= 0) this.isVendor = true;

    //   this._getCompany(this.$route.params.id).then(
    //  (rs) =>  {console.log("current Company", this._company);}
    //  );

    //    if (this.job) {
    // //   console.log("mounted this._job ok: ",this._job);
    //   this._setBid(this.job.bid_id);
    // } else {
    //   setTimeout(() => {
    //  //   console.log("mounted this._job not ok: ",this._job);
    //    if (this.job)  this._setBid(this.job.bid_id);
    //   },1000);
    // }
  },

  computed: {
    ...mapGetters({
      _showCompanyRejectDialog: "company/getShowCompanyRejectDialog",
      _getLoading: "company/getLoading",
      _company: "company/getCompany",
      _customer: "customers/getCustomer",
      _vendor: "vendors/getVendor",
      _currentCompanyStatus: "company/getCurrentCompanyStatus",
      _updateCompanyStatusMessage: "company/getUpdateCompanyStatusMessage",

    }),
    valid() {
      return this.reasons && this.reasons != ""
    },
    today() {
      return moment().format("YYYY-MM-DD");
    },
    // job: {
    //   set(val) {
    //     this._setJob(val);
    //   },
    //   get() {
    //     return this._job;
    //   },
    // },
  },
  watch: {
    _showCompanyRejectDialog() {
      console.log("current _showCompanyRejectDialog", this._showCompanyRejectDialog);
      console.log("current status", this._currentCompanyStatus);
      if (this._showCompanyRejectDialog && this.isVendor && this._currentCompanyStatus == 'Archive' && this._vendor.closing_awarded) this.error = true;
      if (this._showCompanyRejectDialog && !this.isVendor && this._currentCompanyStatus == 'Archive' && this._customer.closing_awarded) this.error = true;

    },
    _updateCompanyStatusMessage() {
      console.log("_updateCompanyStatusMessage", this._updateCompanyStatusMessage);
      if (this._updateCompanyStatusMessage != '' && this._updateCompanyStatusMessage != 'ok') {
        console.log("set error true", this._updateCompanyStatusMessage);
        this.error = true;
      }
    },
  },

  methods: {
    ...mapMutations({
      _setShowCompanyRejectDialog: "company/setShowCompanyRejectDialog",
      _setClosingConf: "company/setClosingConf",
      _setCompanyStatusChange: "company/setCompanyStatusChange",

    }),
    ...mapActions({
      _updateStatus: "company/updateCompanyStatus",
      _getCompany: "company/getCurrent",
      _updateCompany: "company/updateCompany",
    }),
    reject() {
      let comp = {};
      comp.id = this.isVendor ? this._vendor.id : this._customer.id;
      comp.status = this._currentCompanyStatus;
      //  if(  comp.status !='Delete'){
      //    comp.status_note = "  - "+this.reasons.split(', ').join('<br>  - ');
      //     if(this.final.question){
      //       comp.status_note=comp.reason.indexOf('- '+this.final.question)>=0? comp.status_note.replace('- '+this.final.question,"- Other ("+this.final.question+")"): comp.status_note.replace(this.final.question,"Other ("+this.final.question+")");
      //     } 
      //  }
      if (comp.status != 'Delete') {
        comp.status_note = this.reasons;// ""+this.reasons.split(', ').join('');
        if (this.final.question) {
          comp.status_note = comp.status_note.indexOf('' + this.final.question) >= 0 ? comp.status_note.replace('' + this.final.question, "Other (" + this.final.question + ")") : comp.status_note.replace(this.final.question, "Other (" + this.final.question + ")");
        }
      }
      console.log("reject", comp);
      this._updateStatus(comp).then(res => {
        setTimeout(() => {
          /* eslint-disable no-self-assign */
          window.location.href = window.location.href;
        }, 500)
      });
      // this._updateCompany(comp);
    },
    closeDialog() {
      /* eslint-disable no-self-assign */
      window.location.href = window.location.href;
      // this._setShowCompanyRejectDialog(false);
      // this._setClosingConf(false);
      // this.error=false; 
    },
    openDialog() {
      this._setShowCompanyRejectDialog(true);
    },

    selectReason() {
      var t = "";
      this.questions.forEach((i) => {
        if (i.answer) t += i.question + "\n";


      });

      if (this.final.answer) t += this.final.question.trim() + "\n";
      //console.log("t",t);
      this.reasons = t.trim().replaceAll("\n", ", ");
    },
    confirmClose() {


      this._setClosingConf(true);
    },


  },
};
</script>
<style scoped>
.wrapper {
  overflow: auto;
  padding-bottom: 20px;
}

.small-radio>>>label {
  font-size: 14px;
}

.v-card__subtitle {
  font-weight: 500 !important;
  font-size: 1.2em !important;
  padding-top: 0;
  padding-bottom: 0;
}

.small-subtitle {
  font-size: 0.8em !important;
  font-weight: 400 !important;
  padding-left: 20px;
}

@media only screen and (min-width: 960px) {
  .v-card__text {
    padding-left: 35px;
  }
}
</style>
