import {get, getAll} from "../../api";
import moment from "moment";

const state = {
  vendors: undefined,
  vendor: undefined,
  loading: false
};

// getters
const getters = {
  getVendors(state) {
    return state.vendors;
  },
  getLoading(state) {
    return state.loading
  },
  getVendor(state) {
    return state.vendor;
  }
};

const route = "/master/vendor"

// actions
const actions = {
  acquireVendors: async ({ commit }, payload) => {
    commit("setLoading", true)
    const constructedRoute =
        route + "?options=" + encodeURIComponent(JSON.stringify(payload));
    try{
      return getAll(constructedRoute)
          .then(res => {
            commit("setVendors", res.data.data)
            commit("setLoading", false)
          })
          .catch(error => {
            commit("setVendors", undefined)
            commit("setLoading", false)
          })
    }catch (err){
      commit("setVendors", undefined)
      commit("setLoading", false)
    }
  },
  getVendor: async ({commit}, payload) => {
    commit("setLoading", true)
    commit("setVendor", undefined);
    try {
      return get('/company/info', payload, '')
          .then(async (res) => {
            let _jobs = [];
            let _job_ids = {};
            if( res.data.data.bids ) {
              res.data.data.bids = await res.data.data.bids.map((it) => {
                let item = it.job;
                item.start_date_text = moment(item.start_date).format('MM/DD/YYYY hh:mm A');
                item.end_date_text = moment(item.end_date).format('MM/DD/YYYY hh:mm A');
                if( !_job_ids.hasOwnProperty(item.id) ){
                  _job_ids[item.id] = 1;
                  _jobs.push(item);
                }
                return it;
              });
            }
            res.data.data._jobs = _jobs;
            commit("setVendor", res.data.data);
            commit("setLoading", false);
          })
          .catch((err) => {
            commit("setVendor", undefined);
            //commit("setError", err);
            commit("setLoading", false);
          });
    } catch (err) {
      //commit("setError", err);
      commit("setLoading", false);
    }
  }
};

// mutations
const mutations = {
  setVendors(state, payload) {
    state.vendors = payload;
  },
  setLoading(state, payload) {
    state.loading = payload
  },
  setVendor(state, payload) {
    state.vendor = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
