<template>
  <div>
    <v-dialog v-model="bidConfirm" @click:outside="close()" max-width="560">
      <v-card class="pa-1" flat v-if="company_status == 'On Hold'" style="text-align:center;">
        <v-card-title class="pr-0 pl-6">
          <div style="float:left;width:33%">&nbsp;</div>
          <div style="float:left;width:33%">
            <img :src="require('@/assets/warning.png')" />
          </div>
          <div style="float:left;width:33%;text-align:right">
            <v-btn style="-webkit-box-shadow: none;box-shadow: none;background:white;border:0px " id="btnClose"
              @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <h2 style="color:#0091ff;text-transform: uppercase;">
          Account On Hold
        </h2>
        <div style="text-align:left;padding:5px">
          Your company account is on hold. Your access is restricted to jobs
          already in progress and your job history.
          <br />
          Please contact info@recyclegx.com with any questions or for
          assistance.
          <br />
          <br />
        </div>
      </v-card>
      <v-card class="pa-1" flat v-if="company_status == 'New'" style="text-align:center;">
        <img :src="require('@/assets/warning.png')" />
        <h2 style="color:#0091ff;text-transform: uppercase;">
          Account Under Review
        </h2>
        <div style="text-align:left;padding:5px">
          Your new account is pending RGX approval. Contact info@recyclegx.com
          with any questions.
          <br />
          <br />
          Eager to get started? Complete your vendor profile while waiting for
          full access.

          <br />
          <br />
        </div>

        <v-btn color="secondary" @click="gotoProfile()">Complete Profile</v-btn>
        <br />
        <br />
      </v-card>

      <v-card v-if="
        _bid && _job && company_status != 'On Hold' && company_status != 'New'
      " flat class="pa-2">
        <v-card-title class="display-1">
          {{
            _job.logistics == 2
              ? 'Drop-Off Bid Confirmation'
              : 'Bid Confirmation'
          }}
        </v-card-title>
        <v-divider />
        <div ref="closeReceipt">
          <v-card flat>
            <v-card-subtitle class="title mt-2 mb-0 pb-0 primary--text">
              Job Overview
            </v-card-subtitle>
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-card-title class="subtitle-1 pb-0 mt-0">
                  Job Name
                </v-card-title>
                <v-card-subtitle class="subtitle-2 mt-0">
                  {{ _job.name || `No Name` }}
                </v-card-subtitle>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- <v-card-title class="subtitle-1 pb-0">
                  Internal Job ID
                </v-card-title>
                <v-card-subtitle class="subtitle-2 mt-0">
                  {{ _job.number || '###-####' }}
                </v-card-subtitle> -->
              </v-col>
            </v-row>
            <v-card-title class="subtitle-1 pb-0">Description</v-card-title>
            <!-- <v-card-subtitle class="subtitle-2 mt-0">{{
              _job.description || "No Description Available" 
            }}</v-card-subtitle> -->
            <v-card-subtitle class="subtitle-2 mt-0">
              <RenderTextArea :textAreaData="_job.description || 'No Description Available'"></RenderTextArea>
            </v-card-subtitle>

            <v-row no-gutters>
              <v-col cols="12" sm="6" v-if="_job.logistics != 2">
                <v-card-title class="subtitle-1 pb-0">
                  Vehicle Access Restrictions
                </v-card-title>
                <v-card-subtitle class="subtitle-2 mt-0">
                  {{ _job.vehicle_restrictions || 'No Restrictions' }}
                </v-card-subtitle>
              </v-col>
              <v-col cols="12" sm="6" v-if="_job.logistics != 2">
                <v-card-title class="subtitle-1 pb-0">
                  Lift Gate Required?
                </v-card-title>
                <v-card-subtitle class="subtitle-2 mt-0">
                  {{ _job.lift_gate ? `Yes` : `No` }}
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-card-title v-if="_job.pallets" class="subtitle-1 pb-0">
              Number Of Pallets
            </v-card-title>
            <v-card-subtitle v-if="_job.pallets" class="subtitle-2 mt-0">
              {{ _job.pallets }}
            </v-card-subtitle>
            <v-card-title class="subtitle-1 pb-0">Attachments</v-card-title>
            <attachment-list />
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-card-title class="subtitle-1 pb-0">
                  Purchase Allowed?
                </v-card-title>
                <v-card-subtitle v-if="_job.recycle_specs" class="subtitle-2 mt-0">
                  {{ _job.recycle_specs.resale ? `Yes` : `No` }}
                </v-card-subtitle>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card-title class="subtitle-1 pb-0">
                  Require Certified Recycling?
                </v-card-title>
                <v-card-subtitle v-if="_job.recycle_specs" class="subtitle-2 mt-0">
                  {{ _job.recycle_specs.certified_recycle ? `Yes` : `No` }}
                </v-card-subtitle>
              </v-col>
            </v-row>
            <div v-if="_job.logistics != 2">
              <v-card-title class="subtitle-1 pb-0">
                Vendor Personnel Restrictions
              </v-card-title>
              <v-card-subtitle class="subtitle-2 mt-0">
                {{ _job.vendor_restrictions || 'No Restrictions' }}
              </v-card-subtitle>
            </div>
            <v-row no-gutters>
              <v-col cols="12" sm="7">
                <!-- <v-card-title class="subtitle-1 pb-0">
                  Equipment Removal Deadline
                </v-card-title> -->
                <v-card-title class="subtitle-1 pb-0">
                  Equipment Transfer Date
                </v-card-title>
                <v-list-item dense class="subtitle-2 mt-0 pl-2">
                  <v-list-item-avatar>
                    <v-icon color="secondary">mdi-calendar</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    {{ formatDate(_job.end_date) }}
                  </v-list-item-content>
                  <v-list-item-content style="color: #4caf50; margin-left: -40px;">
                    {{
                      getReviewValueByValue(
                        config.EXACT_REMOVE_DATE_OPTIONS,
                        _job.exact_removal_date
                      )
                    }}
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-card-title class="subtitle-1 pb-0">Site</v-card-title>
            <v-card-subtitle v-if="_bid.selectedSite" class="subtitle-2 mt-0">
              {{ _bid.selectedSite.name }}
            </v-card-subtitle>

            <v-card-title class="subtitle-1 pb-0">
              Point of Contact
            </v-card-title>
            <v-card-subtitle v-if="_bid.selectedUser" class="subtitle-2 mt-0">
              {{ _bid.selectedUser.user_name || 'Unknown' }}
            </v-card-subtitle>

            <v-card-subtitle class="subtitle-1 mt-2 mb-0 pb-0 primary--text">
              Bid Overview
            </v-card-subtitle>
            <v-card color class="ma-5 bid-overview" outlined>
              <v-card-text style="padding-left: 0">
                <div v-if="
                  this._job.logistics == 2 &&
                  !this._bid.has_pickup_fee &&
                  !this._bid.has_purchase_scrap
                ">
                  <v-row align="center" justify="center" class="my-md-1 justify-space-around">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor Bid All-in
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              color: #e53935;
                              border-bottom: 1px solid black;
                            ">
                              0.00
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              0.00
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="
                  !(
                    this._job.logistics == 2 &&
                    !this._bid.has_pickup_fee &&
                    !this._bid.has_purchase_scrap
                  )
                ">
                  <!-- Job has no weight for Case 1a and Case 3a -->
                  <v-row v-if="
                    shouldShow() === 'CASE_1a' || shouldShow() === 'CASE_3a'
                  " align="center" justify="center" class="my-md-1 justify-space-around">

                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Min. Purchase Bid
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              color: #e53935;
                              border-bottom: 1px solid black;
                            ">
                              {{
                                `(${_bid.bidType
                                  ? _bid.bid_per_pound
                                  : _bid.bid_all_in_purchase
                                })`
                              }}
                            </div>
                            <div style="align-self: center; font-size: 0.9em" class="pl-2" v-if="_bid.bidType">
                              Price/lb
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Case 1-->
                  <v-row v-if="shouldShow() === 'CASE_1'" align="center" justify="center"
                    class="my-md-1 justify-space-around">
                    <v-col cols="12" style="padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1">
                            Price/lb Bid
                          </v-card-title>
                        </v-col>

                        <v-col cols="6" sm="4" class="pr-1" :class="!_bid.hasJobWeight ? 'alignSelfCenter' : null">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="font-size: 1.2em">
                              {{ _bid.bid_per_pound }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Price/lb
                            </div>
                          </v-layout>

                          <v-layout>
                            <div style="font-size: 1.2em">
                              <v-icon x-small class="pl-2">mdi-close</v-icon>
                              {{
                                numberWithCommas(
                                  _job.weight ? _job.weight.slice(0, -4) : 1
                                )
                              }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Est. lb
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 18px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              color: #e53935;
                              border-bottom: 1px solid black;
                            ">
                              {{
                                '(' +
                                numberWithCommas(
                                  calculate(
                                    _bid.bid_per_pound,
                                    _job.weight ? _job.weight.slice(0, -4) : 1
                                  ).toFixed(2)
                                ) +
                                ')'
                              }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 32px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.hasJobWeight" cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="4">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col cols="6" sm="4" style="align-self: center">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Case 3 -->
                  <v-row v-if="shouldShow() === 'CASE_3'" align="center" justify="center"
                    class="my-md-1 justify-space-around">
                    <v-col cols="12" style="padding-bottom: 0" v-if="_bid.bidType">
                      <v-row no-gutters>
                        <v-col cols="7" sm="5">
                          <v-card-title class="subtitle-1">
                            Price/lb Bid
                          </v-card-title>
                        </v-col>

                        <v-col cols="5" sm="4" class="pr-1" :class="!_bid.hasJobWeight ? 'alignSelfCenter' : null">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="font-size: 1.2em">
                              {{ _bid.bid_per_pound }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Price/lb
                            </div>
                          </v-layout>

                          <v-layout>
                            <div style="font-size: 1.2em; pl-xs-3">
                              <v-icon x-small class="pl-sm-2">mdi-close</v-icon>
                              {{
                                numberWithCommas(
                                  _job.weight ? _job.weight.slice(0, -4) : 1
                                )
                              }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Est. lb
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="7" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.9em; padding-top: 0">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 18px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              color: #e53935;
                              border-bottom: 1px solid black;
                            ">
                              {{
                                '(' +
                                numberWithCommas(
                                  calculate(
                                    _bid.bid_per_pound,
                                    _job.weight ? _job.weight.slice(0, -4) : 1
                                  ).toFixed(2)
                                ) +
                                ')'
                              }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="!_bid.bidType" cols="12" style="padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="8" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor Bid All-in
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div :class="_bid.scenario !== 4 ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateVendorBid() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="7" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="7" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col cols="5" sm="4" style="align-self: center">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="!_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="8" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="4" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="!_bid.bidType" cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="8" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col cols="4" sm="4" style="align-self: center">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Case 2, Case 4 -->
                  <v-row v-if="
                    shouldShow() === 'CASE_2' || shouldShow() === 'CASE_4'
                  " align="center" justify="center" class="my-md-1 justify-space-around">
                    <v-col cols="12" style="padding-bottom: 0px">
                      <v-row no-gutters>
                        <v-col cols="8" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor Bid All-in
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div :class="_bid.scenario !== 4 ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateVendorBid() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="8" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="font-size: 0.8em; padding-top: 0">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 33px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="8" sm="5">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-card>
        </div>
        <v-row no-gutters v-if="bidConfirm">
          <tc @accepted="termsAgreed = !termsAgreed" class="pa-5" />
        </v-row>
        <v-col class="mx-2">
          <v-btn rounded block label="Confirm" class="mb-4" :disabled="!termsAgreed" :loading="_loading"
            color="secondary" @click="createOrUpdateBid()">
            Confirm
          </v-btn>
          <v-btn rounded block color="error" @click="close()">Cancel</v-btn>
        </v-col>
        <!-- <v-btn text block class="mt-4 pb-0" @click="generatePdf">
					<v-icon color="primary">
						mdi-download
					</v-icon>
					Download PDF
				</v-btn> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AttachmentList from '@/components/jobs/AttachmentList.vue';
import moment from 'moment';
import tc from '@/components/app/Terms&Conditions.vue';
import jsPDF from 'jspdf';
import RenderTextArea from '@/components/common/RenderTextArea.vue';
import VueRouter from '../../router';
import config from '../../config';
import * as Helpers from '@/helpers/helpers';
export default {
  components: {
    tc,
    AttachmentList,
    RenderTextArea
  },
  data() {
    return {
      company_status: '',
      netEstTextColorRed: false,
      total: undefined,
      termsAgreed: false,
      config: config
    };
  },
  mounted() {
    this.company_status = this._company.status;
  },
  computed: {
    bidConfirm: {
      get() {
        return this._bidConfirm;
      },
      set(newVal) {
        this._toggleConfirm(newVal);
      }
    },
    notTotal() {
      return this._bid.bid_per_pound ? total : this._bid.bid_buy_out;
    },
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      _bidConfirm: 'bids/confirm',
      _bid: 'bids/bid',
      _loading: 'bids/getLoading',
      _company: 'company/getCompany'
    })
  },

  methods: {
    ...Helpers,
    ...mapMutations({
      _toggleConfirm: 'bids/toggleConfirm',
      _setBid: 'bids/setBid'
    }),
    ...mapActions({
      _createBid: 'bids/createBid',
      _updateBid: 'bids/updateBid',
      _updateJob: 'jobs/updateJob'
    }),
    close() {
      this.termsAgreed = false;
      this._toggleConfirm(false);
    },
    numberWithCommas(x) {
      // return x ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : ""; // not work on Safari
      return x
        ? Number(x.toString().replaceAll(',', '')).toLocaleString('en-US', {
          minimumFractionDigits: 2
        })
        : '';
    },
    generatePdf() {
      var doc = new jsPDF('p', 'pt', 'A4');
      let margins = {
        top: 140,
        bottom: 60,
        left: 40,
        width: 522
      };
      let img = new Image();
      img.src = require('@/assets/rgx-black-lockup-1.png');
      img.onload = () => {
        // doc.setFont("courier");
        doc.addImage(img, 'png', 190, 40, 200, 80);
        doc.fromHTML(this.$refs.closeReceipt, margins.left, margins.top, {
          width: margins.width
        });
        // doc.autoPrint();
        doc.save(this._job.name + '_bid_receipt.pdf');
      };
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    async createBid() {
      const data = { ...this._bid };
      if (data.selectedSite) {
        delete data.selectedSite;
      }

      if (data.selectedUser) {
        delete data.selectedUser;
      }

      if (data.scenario === 2) {
        delete data.bid_per_pound;
      }

      if (data.scenario === 3) {
        delete data.bid_buy_out;

        if (data.bidType) {
          delete data.bid_all_in_purchase;
        } else {
          delete data.bid_per_pound;
        }
      }

      if (data.scenario === 4) {
        delete data.bid_all_in_purchase;
        delete data.bid_per_pound;
      }

      if (data.scenario) {
        delete data.scenario;
      }

      delete data.hasJobWeight;
      delete data.bidType;

      if (data.bid_buy_out !== null) {
        data.current_bid_buy_out = data.bid_buy_out;
      }

      //handle case Drop-Off with two no choice
      if (
        this._job.logistics == 2 &&
        !this._bid.has_pickup_fee &&
        !this._bid.has_purchase_scrap
      ) {
        data.bid_all_in_purchase = null;
        data.bid_per_pound = null;
        data.bid_buy_out = null;
      }


      await this._createBid(data)
      this.termsAgreed = false;
      this.total = undefined;
      // this._createBid(data).then(() => {
      //   this.termsAgreed = false;
      //   this.total = undefined;
      // });
    },
    async updateBid() {
      const data = {
        ...this._bid,
        modified_at: new Date()
      };

      if (data.has_purchase_scrap) {
        if (data.bidType) {
          data.bid_all_in_purchase = null;
        }
        else {
          data.bid_per_pound = null;
        }
      }

      if (data.selectedSite) {
        delete data.selectedSite;
      }

      if (data.selectedUser) {
        delete data.selectedUser;
      }

      if (data.scenario === 2) {
        //delete data.bid_per_pound;
        data.bid_per_pound = null;
      }

      if (data.scenario === 3) {
        data.bid_buy_out = null;

        if (data.bidType) {
          //delete data.bid_all_in_purchase;
          data.bid_all_in_purchase = null;
        } else {
          //delete data.bid_per_pound;
          data.bid_per_pound = null;
        }
      }

      if (data.scenario === 4) {
        // delete data.bid_all_in_purchase;
        // delete data.bid_per_pound;

        data.bid_all_in_purchase = null;
        data.bid_per_pound = null;

      }

      if (data.scenario) {
        delete data.scenario;
      }

      delete data.hasJobWeight;
      delete data.bidType;

      if (data.bid_buy_out !== null) {
        data.current_bid_buy_out = data.bid_buy_out;
      }

      //handle case Drop-Off with two no choice
      if (
        this._job.logistics == 2 &&
        !this._bid.has_pickup_fee &&
        !this._bid.has_purchase_scrap
      ) {
        data.bid_all_in_purchase = null;
        data.bid_per_pound = null;
        data.bid_buy_out = null;
      }

      await this._updateBid(data);
      this.termsAgreed = false;
      this.total = undefined;

      // this._updateBid(data).then(() => {
      //   this.termsAgreed = false;
      //   this.total = undefined;
      // });
    },
    async createOrUpdateBid() {
      if (this._bid.isEditBid) {
        await this.updateBid();
      }
      else {
        await this.createBid();
      }
      this._toggleConfirm(false);
      setTimeout(() => {
        this.$router.go();
      }, 2000);
      // this.$router.go();
    },
    // createOrUpdateBid() {
    //   if (this._bid.isEditBid) {
    //     this.updateBid().then(res => {
    //       this._toggleConfirm(false);
    //       setTimeout(() => {
    //         this.$router.go();
    //       }, 1000);
    //     });
    //   }
    //   else {
    //     this.createBid().then(res => {
    //       this._toggleConfirm(false);
    //       setTimeout(() => {
    //         this.$router.go();
    //       }, 1000);
    //     });
    //   }
    // },
    calculate(str1, str2) {
      const rs = Number(str1.replace(/,/g, '')) * Number(str2 || 1);
      return rs;
    },

    calculateVendorBid() {
      if (!this._bid) {
        return;
      }
      let number = this._bid.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;
      return this._bid.scenario !== 4
        ? '(' + this.numberWithCommas(number) + ')'
        : this.numberWithCommas(number);
    },
    gotoProfile() {
      VueRouter.push('/profile');
    },
    calculateNetEstimate() {
      if (!this._bid || !this._job) {
        return;
      }

      let numberAsString = this._bid.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;

      let number = parseFloat(numberAsString.replace(/,/g, ''));

      const bidBuyOut = parseFloat(this._bid.bid_buy_out.replace(/,/g, ''));
      const unit = this._job.weight
        ? parseFloat(this._job.weight.slice(0, -4))
        : 1;

      if (this._bid.scenario === 1) {
        number = number * unit;
      }

      if (this._bid.scenario === 3 && this._bid.bidType) {
        number = number * unit;
      }

      const net = bidBuyOut - number;

      if (net < 0 && this._bid.scenario !== 4) {
        this.netEstTextColorRed = true;
        return '(' + this.numberWithCommas(Math.abs(net).toFixed(2)) + ')';
      }

      this.netEstTextColorRed = false;
      return this.numberWithCommas(net.toFixed(2));
    },
    shouldShow() {
      if (!this._bid) {
        return '';
      }

      if (this._bid.scenario === 1) {
        if (!this._bid.hasJobWeight) {
          return 'CASE_1a';
        }
        return 'CASE_1';
      }

      if (this._bid.scenario === 2) {
        return 'CASE_2';
      }

      if (this._bid.scenario === 3) {
        if (this._bid.bidType && !this._bid.hasJobWeight) {
          return 'CASE_3a';
        }

        return 'CASE_3';
      }

      if (this._bid.scenario === 4) {
        return 'CASE_4';
      }
    }
  }
};
</script>
<style scoped>
.textColorRed {
  color: #e53935;
}

.alignSelfCenter {
  align-self: center;
}

.subtitle-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.bid-overview .subtitle-1 {
  font-weight: 500;
}

.bid-overview .v-card__subtitle {
  line-height: 0.9em;
}
</style>
