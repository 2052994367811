<template>
  <div v-if="isShow" class="overlay">
    <div class="loader-container">
      <div class="loader"></div>
      <p class="loading-text">{{ content }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WaitingIndicator',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    content: {
      type: Text,
      default: 'Please wait...'
    }
  }
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loader-container {
  display: flex;
  align-items: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 5.6px solid #f3f3f3;
  border-top: 5.6px solid #143e55;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

.loading-text {
  font-size: 18px;
  color: white;
  margin-top: 0;
  line-height: 42px;

  display: flex;
  align-items: center;
  height: 42px;
  padding-top: 10px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
