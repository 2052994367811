var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-card',{style:(`border: 1px solid ${_vm.config.borderColor};`),attrs:{"outlined":"","color":_vm.config.color,"height":"60"},on:{"click":_vm.handleClick}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{attrs:{"width":"20"}},[_c('v-card-text',{class:_vm.$vuetify.breakpoint.lgAndDown ? `
            pl-${_vm.config.padding[0] / 2} 
            pr-${_vm.config.padding[1] - 1} 
            py-0
            font-weight-medium
          ` : `
            pl-${_vm.config.padding[0]} 
            pr-${_vm.config.padding[1]} 
            font-weight-medium
          `,style:(`color: ${_vm.config.labelColor}`)},[_vm._v(" "+_vm._s(_vm.config.text)+" ")])],1),_c('div',{staticClass:"justify-end",class:_vm.config.unit ? 'text-truncate' : ''},[_c('v-card-text',{class:_vm.$vuetify.breakpoint.lgAndDown ? `
            pr-${_vm.config.padding[0] / 2} 
            pl-${_vm.config.padding[1] - 1} 
            font-weight-bold
          ` : `
            pr-${_vm.config.padding[0]} 
            pl-${_vm.config.padding[1]} 
            font-weight-bold
          `,style:(`font-size:22px; color: ${_vm.config.valueColor}`)},[_vm._v(" "+_vm._s(_vm.config.value)+" "),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.config.unit)+" ")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }