<template>
  <v-dialog
    v-if="_job"
    v-model="_showCloseOutDialog"
    @click:outside="closeDialog()"
    max-width="550px"
  >
    <v-card flat>
      <div class="btn-dismiss-dialog">
        <v-btn text @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-container class="pl-md-8">
        <v-card flat>
          <v-card-title class="subtitle-3">Job Close Out</v-card-title>

          <v-row justify="start" no-gutters>
            <v-col cols="12" md="10">
              <v-card-subtitle>Scrap Purchase Details *</v-card-subtitle>
              <v-card-text>
                <v-form
                  v-model="scrapPurchaseDetailValid"
                  ref="scrapPurchaseDetailRef"
                >
                  <v-text-field
                    name="final_weight2"
                    v-model="job.final_weight2"
                    ref="finalWeightRef"
                    label="Scrap Final Weight (lbs) *"
                    maxlength="9"
                    v-on:keypress="isNumber($event)"
                    v-on:blur="finalWeightUpdate($event)"
                    :rules="[rules.required, rules.positiveNumber]"
                    prepend-icon="mdi-weight-pound"
                    @input="checkFormValidity('finalWeightRef')"
                  ></v-text-field>
                </v-form>
                <v-form v-model="additionalScrapValid" ref="additionalScrapRef">
                  <v-text-field
                    type="money"
                    maxlength="15"
                    label="Additional Scrap Proceeds"
                    ref="additionalScrapRef"
                    prepend-icon="mdi-currency-usd"
                    v-model="job.additional_scrap_proceeds"
                    v-model.lazy="job.additional_scrap_proceeds"
                    v-money="job.additional_scrap_proceeds"
                    :rules="[rules.negativeMoneyBid, rules.minMoney, rules.max]"
                    @input="checkFormValidity('additionalScrapRef')"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-col>

            <v-col cols="12" md="10" v-if="job.recycle_specs.resale">
              <v-card-subtitle class="subtitle-2">
                Are you purchasing equipment? *
              </v-card-subtitle>

              <v-card-text>
                <v-form
                  v-model="radioPurchaseEquipmentValid"
                  ref="radioPurchaseEquipmentRef"
                >
                  <v-radio-group
                    row
                    v-model="job.has_purchase_equipment"
                    :rules="[radioRules.required]"
                  >
                    <v-radio
                      class="small-radio"
                      :value="true"
                      label="Yes"
                    ></v-radio>
                    <v-radio
                      class="small-radio"
                      :value="false"
                      label="No"
                    ></v-radio>
                  </v-radio-group>
                </v-form>
                <div v-show="job.has_purchase_equipment">
                  <v-form
                    v-model="purchaseEquipmenValid"
                    ref="purchaseEquipmentRef"
                  >
                    <v-text-field
                      type="money"
                      maxlength="15"
                      label="Purchase Offer *"
                      prepend-icon="mdi-currency-usd"
                      v-model="job.resale_offer"
                      v-model.lazy="job.resale_offer"
                      v-money="job.resale_offer"
                      :rules="[
                        rules.required,
                        rules.negativeMoneyBid,
                        rules.minMoney,
                        rules.max
                      ]"
                      @blur="checkFormValidity('purchaseEquipmentRef')"
                      @input="checkFormValidity('purchaseEquipmentRef')"
                    ></v-text-field>
                  </v-form>
                  <v-form
                    v-model="purchaseInventoryValid"
                    ref="purchaseInventoryRef"
                  >
                    <v-file-input
                      @change="uploadFile($event, 'purchase_inventory')"
                      outlined
                      dense
                      @focus="equipFocused = !equipFocused"
                      :loading="equipFocused ? _uploading : false"
                      label="Inventory of Equipment to Purchase *"
                      :rules="[inventoryFileUploadRules.required]"
                      :value="currentPurchaseInventoryFile"
                      @blur="equipFocused = !equipFocused"
                      class="mt-2"
                    ></v-file-input>
                  </v-form>
                </div>
              </v-card-text>
            </v-col>

            <v-col cols="12" md="10" v-if="job.bid_buy_out">
              <v-card-subtitle>Customer service fee*</v-card-subtitle>
              <v-card-subtitle class="small-subtitle">
                Fee can be reduced, not increased
              </v-card-subtitle>
              <v-card-text>
                <v-row no-gutters align="center">
                  <v-col cols="5" md="4">
                    <v-form v-model="customerFeeValid" ref="customerFeeRef">
                      <v-text-field
                        type="money"
                        :disabled="customerFeeDisabled"
                        v-model="job.current_bid_buy_out"
                        v-model.lazy="job.current_bid_buy_out"
                        v-money="job.current_bid_buy_out"
                        prepend-icon="mdi-currency-usd"
                        :rules="[
                          rules.maxFee(
                            job.bid_buy_out,
                            job.current_bid_buy_out
                          ),
                          rules.required,
                          rules.negativeMoneyBid
                        ]"
                        @input="checkFormValidity('customerFeeRef')"
                      ></v-text-field>
                    </v-form>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      icon
                      color="secondary"
                      class="elevation-0"
                      rounded
                      @click="customerFeeDisabled = !customerFeeDisabled"
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>

            <v-col cols="12" md="10">
              <v-card-subtitle>Final Details</v-card-subtitle>
              <v-card-text>
                <v-form ref="finalDetailRef">
                  <v-menu
                    ref="menu"
                    v-model="pickUpMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <!--
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="job.end_date"
                        ref="endDateRef"
                        :rules="[rules.required, rules.isValidDate]"
                        label="Pickup Date*"
                        :placeholder="'YYYY-MM-DD'"
                        readonly
                        prepend-icon="mdi-calendar-blank"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="job.end_date"
                      no-title
                      :min="today"
                      @input="pickUpMenu = false"
                    ></v-date-picker> -->
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="job.actual_pickup_date"
                        ref="endDateRef"
                        :rules="[rules.required, rules.isValidDate]"
                        label="Pickup Date*"
                        :placeholder="'YYYY-MM-DD'"
                        readonly
                        prepend-icon="mdi-calendar-blank"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="job.actual_pickup_date"
                      no-title
                      @input="pickUpMenu = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menu"
                    v-model="destructMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="job.destruction_date"
                        ref="destructionDateRef"
                        label="Destruction Date*"
                        :rules="[rules.required, rules.isValidDate]"
                        readonly
                        :placeholder="'YYYY-MM-DD'"
                        prepend-icon="mdi-calendar-blank"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="job.destruction_date"
                      no-title
                      :max="today"
                      @input="destructMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-form>
              </v-card-text>
            </v-col>

            <v-col cols="11">
              <v-form v-model="certDestructionValid" ref="certDestructionRef">
                <v-file-input
                  @change="uploadFile($event, 'cod')"
                  dense
                  outlined
                  ref="misc"
                  @focus="codFocused = !codFocused"
                  @blur="codFocused = !codFocused"
                  :loading="codFocused ? _uploading : false"
                  label="Certificate of Destruction Upload *"
                  :rules="[codFileUploadRules.required]"
                  :value="currentCodFile"
                ></v-file-input>
              </v-form>
            </v-col>

            <v-col
              cols="10"
              v-if="job.attachments && job.attachments.length > 0"
            >
              <v-card-subtitle>Uploaded Files</v-card-subtitle>
              <v-list-item
                class="mx-0 px-0"
                v-for="(i, k) in job.attachments.filter(
                  w => w.catagory == 'purchase_inventory' || w.catagory == 'cod'
                )"
                :key="k"
              >
                <v-list-item-icon>
                  <v-icon color="secondary">mdi-paperclip</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ i.original_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ i.catagory.replace(/_/g, ' ') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12">
              <v-row align="center" justify="center" class="text-center">
                <v-col>
                  <v-btn
                    color="primary"
                    @click="saveAndExit()"
                    :loading="loading"
                    :disabled="Object.keys(invalidFields).length > 0"
                  >
                    Save and Exit
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn color="secondary" @click="confirmClose">
                    Review Final
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  props: {
    confirm: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      customerFeeDisabled: true,
      invalidFields: {},
      fileUploading: {},
      equipFocused: false,
      codFocused: false,
      money: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false /* doesn't work with directive */
      },

      scrapPurchaseDetailValid: false,
      additionalScrapValid: false,
      radioPurchaseEquipmentValid: false,
      purchaseEquipmenValid: false,
      purchaseInventoryValid: false,
      customerFeeValid: false,
      certDestructionValid: false,

      currentPurchaseInventoryFile: null,
      currentCodFile: null,

      pickUpMenu: false,
      destructMenu: false,
      rules: {
        required: v => !!v || 'Field is required to close.',
        minMoney: v => {
          return (
            parseFloat(v ? (v + '').replace(/,/g, '') : 0.1) >= 0.0 ||
            'Must be a positive amount.'
          );
        },
        min: v => {
          return (
            parseFloat(v ? (v + '').replace(/,/g, '') : 0.1) > 0.0 ||
            'Must be a positive number.'
          );
        },
        max: v =>
          parseFloat(v ? v.replace(/,/g, '') : 0.1) <= 1000000000000.0 ||
          'Can not exceed $1000000000000',
        maxFee(max, v) {
          return (
            parseFloat(v ? v.replace(/,/g, '') : 0.1) <=
              parseFloat(max ? max.replace(/,/g, '') : 0.1) ||
            `Value must be less than ${max}`
          );
        },
        isValidDate: v => moment(v).isValid() || 'Invalid Date.',
        negativeBid: v =>
          !new RegExp(/-/g).test(v) || 'Must be a positive number.',
        negativeMoneyBid: v =>
          !new RegExp(/-/g).test(v) || 'Must be a positive amount.',
        positiveNumber: v => Math.sign(v) == 1 || 'Must be a positive number.'
      },

      inventoryFileUploadRules: {
        required: v => !!v || 'Field is required to close.'
      },

      codFileUploadRules: {
        required: v => !!v || 'Field is required to close.'
      },

      radioRules: {
        required: v => v !== null || 'Field is required to close.'
      }
    };
  },
  mounted() {
    if (this.job && this.job.destruction_date) {
      this.job.destruction_date = this.job.destruction_date.substr(0, 10);
    }

    if (this.job && Array.isArray(this.job.attachments)) {
      const purchaseInventoryFile = this.job.attachments.find(
        item => item.catagory === 'purchase_inventory'
      );

      const codCertFile = this.job.attachments.find(
        item => item.catagory === 'cod'
      );

      if (purchaseInventoryFile) {
        this.inventoryFileUploadRules = {
          required: v => true || ''
        };
        this.currentPurchaseInventoryFile = new File(
          [purchaseInventoryFile.original_name],
          purchaseInventoryFile.original_name,
          {
            type: purchaseInventoryFile.file_type
          }
        );
      }

      if (codCertFile) {
        this.codFileUploadRules = {
          required: v => true || ''
        };
        this.currentCodFile = new File(
          [codCertFile.original_name],
          codCertFile.original_name,
          {
            type: codCertFile.file_type
          }
        );
      }

      // this.job.attachments = this.job.attachments.filter(
      //   (item) => item.catagory !== "equipment_list"
      // );
    }
  },

  computed: {
    ...mapGetters({
      _file: 'uploads/getFile',
      _showCloseOutDialog: 'jobs/getShowCloseOutDialog',
      _job: 'jobs/getCurrentJob',
      _uploading: 'uploads/loading'
    }),
    today() {
      return moment().format('YYYY-MM-DD');
    },
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    }
  },
  watch: {
    _file() {
      if (!this._showCloseOutDialog) {
        return;
      }

      if (!this.job.attachments) {
        this.job.attachments = [];
      }

      if (this._file.file.Key && this.fileUploading) {
        this.job.attachments.push({
          key: this._file.file.Key,
          location: this._file.file.Location,
          file_type: this.fileUploading.type,
          catagory: this.fileUploading.catagory,
          original_name: this.fileUploading.name
        });
      }
    }
  },
  methods: {
    ...mapMutations({
      _setShowCloseOutDialog: 'jobs/setShowCloseOutDialog',
      _setClosingConf: 'jobs/setClosingConf'
    }),

    ...mapActions({
      _updateBid: 'bids/updateBid',
      _uploadFile: 'uploads/uploadFile',
      _updateJob: 'jobs/updateJob',
      _setJob: 'jobs/setJob'
    }),

    closeDialog() {
      this._setShowCloseOutDialog(false);
      this._setClosingConf(false);
    },

    removeAtt(index) {
      const tempAttachments = [...this.job.attachments];
      this._updateJob({
        id: this.job.job_id,
        attachments: tempAttachments.splice(index, 1)
      }).then(() => {
        this.job.attachments.splice(index, 1);
      });
    },
    confirmClose() {
      const scrapPurchaseDetailValid = this.$refs.scrapPurchaseDetailRef.validate();

      const additionalScrapValid = this.$refs.additionalScrapRef.validate();

      const radioPurchaseEquipmentValid = this.job.recycle_specs.resale
        ? this.$refs.radioPurchaseEquipmentRef.validate()
        : true;

      let certDestructionRefValid = true;

      if (
        !Array.isArray(this.job.attachments) ||
        this.job.attachments.length <= 0
      ) {
        certDestructionRefValid = this.$refs.certDestructionRef.validate();
      } else {
        const alreadyUploadDestructionCert = this.job.attachments.find(
          item => item.catagory === 'cod'
        );

        if (!alreadyUploadDestructionCert) {
          certDestructionRefValid = this.$refs.certDestructionRef.validate();
        }
      }

      const customerFeeValid = this.job.bid_buy_out
        ? this.$refs.customerFeeRef.validate()
        : true;

      const finalDetailValid = this.$refs.finalDetailRef.validate();

      let purchaseEquipmentValid = true;
      if (this.job.has_purchase_equipment) {
        purchaseEquipmentValid = this.$refs.purchaseEquipmentRef.validate();

        if (
          !Array.isArray(this.job.attachments) ||
          this.job.attachments.length <= 0
        ) {
          purchaseEquipmentValid = this.$refs.purchaseInventoryRef.validate();
        } else {
          const alreadyUploadPurchaseInventory = this.job.attachments.find(
            item => item.catagory === 'purchase_inventory'
          );

          if (!alreadyUploadPurchaseInventory) {
            purchaseEquipmentValid = this.$refs.purchaseInventoryRef.validate();
          }
        }
      } else {
        this.job.resale_offer = null;

        if (Array.isArray(this.job.attachments)) {
          this.job.attachments = this.job.attachments.filter(
            item => item.catagory !== 'purchase_inventory'
          );
        }
      }

      if (!scrapPurchaseDetailValid) {
        console.log('scrapPurchaseDetailValid', scrapPurchaseDetailValid);
        return;
      }

      if (!additionalScrapValid) {
        console.log('additionalScrapValid', additionalScrapValid);
        return;
      }

      if (!radioPurchaseEquipmentValid) {
        console.log('radioPurchaseEquipmentValid', radioPurchaseEquipmentValid);
        return;
      }

      if (!purchaseEquipmentValid) {
        console.log('purchaseEquipmentValid', purchaseEquipmentValid);

        return;
      }

      if (!customerFeeValid) {
        this.customerFeeDisabled = false;
        console.log('customerFeeValid', customerFeeValid);
        return;
      }

      if (!finalDetailValid) {
        console.log('finalDetailValid', finalDetailValid);
        return;
      }

      if (!certDestructionRefValid) {
        console.log('certDestructionRefValid', certDestructionRefValid);
        return;
      }
      console.log('_setClosingConf', true);
      this._setClosingConf(true);
    },
    finalWeightUpdate(event) {
      this.job.final_weight = Number(this.job.final_weight2).toFixed(2);
    },
    isNumber(event) {
      var v = event.target.value + event.key;
      var f = v.split('.');
      //console.log("event " +v ,event);
      if (
        (!/\d/.test(event.key) && event.key !== '.') ||
        (f.length > 1 && f[1].length > 2)
      )
        return event.preventDefault();
    },
    checkFormValidity(refId) {
      if (!refId || !this.$refs[refId]) {
        return;
      }
      const isValid = this.$refs[refId].validate();

      if (!isValid) {
        this.invalidFields[refId] = true;
      } else {
        if (this.invalidFields[refId]) {
          delete this.invalidFields[refId];
        }
      }
    },
    async saveAndExit() {
      if (Object.keys(this.invalidFields).length > 0) {
        return;
      }

      this.rules.required = v => true;

      const finalWeightValid = this.$refs.finalWeightRef.validate();

      const additionalScrapValid = this.$refs.additionalScrapRef.validate();

      const resaleOfferValid = this.job.recycle_specs.resale
        ? this.$refs.purchaseEquipmentRef.validate()
        : true;

      const radioPurchaseEquipmentValid = this.job.recycle_specs.resale
        ? this.radioPurchaseEquipmentValid
        : true;

      const endDateValid = this.$refs.endDateRef.validate();

      const destructionDateValid = this.$refs.destructionDateRef.validate();

      let job = {};
      job.id = this.job.job_id;
      console.log(
        'this.job.final_weight: ' + finalWeightValid,
        this.job.final_weight2
      );
      if (finalWeightValid) {
        job.final_weight = Number(this.job.final_weight2).toFixed(2);
      }
      console.log('job.final_weight', job.final_weight);
      if (additionalScrapValid) {
        job.additional_scrap_proceeds = this.job.additional_scrap_proceeds
          ? parseFloat(this.job.additional_scrap_proceeds.replace(/,/g, ''))
          : null;
      }

      if (resaleOfferValid) {
        job.resale_offer = this.job.resale_offer;
      }

      if (radioPurchaseEquipmentValid) {
        job.has_purchase_equipment = this.job.has_purchase_equipment;
      }

      if (endDateValid) {
        // job.end_date = this.job.end_date;
        job.actual_pickup_date = this.job.actual_pickup_date;
      }

      if (destructionDateValid) {
        job.destruction_date = this.job.destruction_date;
      }

      job.attachments = this.job.attachments;

      if (job.has_purchase_equipment === false) {
        job.resale_offer = null;

        if (Array.isArray(job.attachments)) {
          job.attachments = job.attachments.filter(
            item => item.catagory !== 'purchase_inventory'
          );
        }
      }

      this.loading = true;

      const updates = [this._updateJob({ ...job, disableLoading: true })];

      if (this.customerFeeValid) {
        const options = {
          id: this.job.bid_id,
          current_bid_buy_out: this.job.current_bid_buy_out
        };

        updates.push(this._updateBid(options));
      } else {
        this.customerFeeDisabled = false;
      }

      await Promise.all(updates);

      this.loading = false;
      this._setShowCloseOutDialog(false);
    },
    uploadFile(e, type) {
      if (e && e.name) {
        this.fileUploading.file = e;
        this.fileUploading.name = e.name;
        this.fileUploading.catagory = type;
        this.fileUploading.type = e.type;
        let file = {
          file: e,
          original_name: e.name,
          type: type
        };
        this._uploadFile(file);
      } else if (!e) {
      }
    }
  }
};
</script>
<style scoped>
.wrapper {
  overflow: auto;
  padding-bottom: 20px;
}

.small-radio >>> label {
  font-size: 14px;
}

.v-card__subtitle {
  font-weight: 500 !important;
  font-size: 1.2em !important;
  padding-top: 0;
  padding-bottom: 0;
}

.small-subtitle {
  font-size: 0.8em !important;
  font-weight: 400 !important;
  padding-left: 20px;
}

@media only screen and (min-width: 960px) {
  .v-card__text {
    padding-left: 35px;
  }
}
</style>
