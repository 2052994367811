import api from "../api";

export function auth(user) {
	return api.post(`/auth`, user);
}

export function checkToken() {
	return api.get("/auth/check-token");
}
export function getCaptcha() {
	var u=api.defaults.baseURL+("/auth/captcha");
	console.log("api: "+u,api);
	return u;
}
export function forgotPassword(email,capt) {
	return api.post("/auth/forgot-password", { email,capt });
}

export function setNewPassword(user) {
  return api.post("/auth/reset-password", user)
}