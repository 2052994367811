<template>
	<div>
		<v-navigation-drawer
			v-if="showNav"
			v-model="navDrawer"
			dark
			app
			color="navBackground"
			:mini-variant="miniDrawer"
			width="180"
		>
			<v-list-item>
				<v-list-item-icon class="mr-0">
					<v-img
						contain
						height="50"
						:src="require('@/assets/Logo-R-Desktop@3x.png')"
					/>
				</v-list-item-icon>
				<v-list-item-title class="ml-0 pa-0 text-left float-left">
					<v-img
						contain
						transition="fade"
						width="150"
						height="50"
						class="float-left"
						:src="require('@/assets/Logo-Text-Desktop@3x.png')"
					/>
				</v-list-item-title>
			</v-list-item>
			<v-list nav>
				<v-list-item
					v-for="item in navItems"
					:key="item.title"
					:to="item.link"
					:disabled="item.coming_soon !== undefined"
					active-class="secondary activeLink"
					link
				>
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
						<v-list-item-subtitle v-if="item.coming_soon">{{
							item.coming_soon
						}}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar color="mainBg" clipped-right app>
			<v-btn
				v-if="showNav && $vuetify.breakpoint.lgAndUp"
				icon
				small
				@click.native="miniDrawer = !miniDrawer"
			>
				<v-fade-transition>
					<v-icon v-if="!miniDrawer">mdi-chevron-left</v-icon>
					<v-icon v-else>mdi-chevron-right</v-icon>
				</v-fade-transition>
			</v-btn>
			<v-img
				height="30px"
				max-width="40px"
				v-if="!showNav"
				class="mr-2"
				@click="$router.push('/')"
				contain
				:src="require('@/assets/rgx-black-192x192.png')"
			></v-img>
			<v-btn
				v-if="showNav && $vuetify.breakpoint.mdAndDown"
				icon
				@click.native="navDrawer = !navDrawer"
			>
				<v-icon>mdi-menu</v-icon>
			</v-btn>
			<v-toolbar-title class="primary--text">{{ pageTitle }}</v-toolbar-title>
			<v-spacer />
			<!-- <v-toolbar-title
				v-if="showNav && $vuetify.breakpoint.mdAndUp"
				class="grey--text"
				style="font-weight: 500;"
				>{{ _company.name.toUpperCase() }}</v-toolbar-title
			> -->
			<v-img
				height="30px"
				max-width="300px"
				v-if="_company && _company.icon_url && showNav && $vuetify.breakpoint.mdAndUp"
				contain
				float
				right
				:src="_company.icon_url"
			></v-img>
			<account-card v-if="showNav" @close="accountMenu = !accountMenu" />
		</v-app-bar>
	</div>
</template>
<script>
import AccountCard from "@/components/app/AccountCard.vue";
import { mapGetters } from "vuex";
export default {
	components: { AccountCard },
	data() {
		return {
			accountMenu: false,
			openLogin: false,
			toggle_exclusive: false,
			navDrawer: true,
			miniDrawer: false,
			normalNavItems: [
				{
					title: "Dashboard",
					icon: "mdi-view-dashboard",
					link: "/dashboard",
				},
				{
					title: "Jobs",
					icon: "mdi-briefcase",
					link: "/job",
				},
				{
					title: "Jobs History",
					icon: "mdi-briefcase-clock",
					link: "/history",
				},
				{
					title: "Sites",
					icon: "mdi-map",
					link: "/site",
				},
				{
					title: "Team",
					icon: "mdi-account-group",
					link: "/team",
				},
				{
					title: "Profile",
					icon: "mdi-account-cog",
					link: "/profile",
				},
      ],
      adminNavItems: [
				{
					title: "Dashboard",
					icon: "mdi-view-dashboard",
					link: "/dashboard",
				},
				{
					title: "Customers",
					icon: "mdi-briefcase",
					link: "/customer",
				},
				{
					title: "Vendors",
					icon: "mdi-account-group",
					link: "/vendor",
				},
				{
					title: "History",
					icon: "mdi-briefcase-clock",
					link: "/history",
				}
			],
		};
	},
	computed: {
		...mapGetters({
      _company: "company/getCompany",
		}),
		showNav() {
			if (
				this.$route.name &&
				this.$route.name !== "Welcome" &&
				this.$route.name !== "Registration" &&
				this.$route.name !== "Email Opt Out" &&
				this.$route.name !== "Forgot Password"
			) {
				return true;
			} else {
				return false;
			}
		},
		pageTitle() {
			return this.$route.name;
    },
    navItems() {
      if (this._company && this._company.type_id === 3) {
        return this.adminNavItems
      } else {
        return this.normalNavItems
      }
    }
	},
	methods: {
		goHome() {
			this.$router.push("/");
		},
	},
};
</script>
<style scoped>
.nav-title {
	word-break: break-all;
	margin: 0 auto;
	text-align: left;
	font-size: 28px !important;
	font-weight: 900;
	font-stretch: normal;
	font-style: italic;
}
.activeLink {
	border-radius: 3px;
	/* background-color: #4caf50; */
}
</style>
