<template>
	<v-card flat class="mx-auto" color="grey lighten-2" style="width: 100%;display:none">
		<v-card-subtitle class="mb-0 pb-0">Payment Account</v-card-subtitle>
		<v-row no-gutters>
			<v-col sm="3" height="100%;">
				<v-container class="fill-height">
					<v-col justify="center" align="center">
						<v-icon color="secondary" large>mdi-bank</v-icon>
						<p class="mb-0 primary--text" style="word-break: none;">
							{{ _company.payment_method }}
						</p>
					</v-col>
				</v-container>
			</v-col>
			<v-col sm="9">
				<v-container fill-height>
					<v-row no-gutters justify="center" align="center">
						<v-text-field
							:type="!edit ? 'password' : 'text'"
							height="29px"
							width="100px"
							class="ma-1"
							:disabled="!edit"
							@keyup.enter="$emit('save')"
							v-model="_company.account_number"
							:label="`Account #`"
							hide-details
						/>
						<v-text-field
							v-if="_company.routing_number"
							:type="!edit ? 'password' : 'text'"
							height="29px"
							class="ma-1"
							width="100px"
							:disabled="!edit"
							@keyup.enter="$emit('save')"
							v-model="_company.routing_number"
							:label="`Routing #`"
							hide-details
						/>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	props: {
		edit: Boolean,
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			_company: "company/getCompany",
		}),
	},
};
</script>
