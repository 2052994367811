<template>
  <v-card class="px-4" flat>
    <v-row class="pt-2">
      <stat-card :config="config[0]" />
      <stat-card :config="config[2]" />
      <stat-card :config="config[7]" />
    </v-row>
    <v-row class="px-3 pb-2">
      <v-row>
        <stat-card :config="config[3]" />
        <stat-card :config="config[4]" />
        <stat-card :config="config[10]" />
      </v-row>

      <v-row>
        <stat-card :config="config[5]" />
        <stat-card :config="config[6]" />
        <stat-card :config="config[11]" />
        <stat-card :config="config[12]" />
      </v-row>
    </v-row>
    <jobs-tabs />
    <jobs-table table-title="List" />
    <new-job-pre />
    <new-job-form />
  </v-card>
</template>
<script>
import JobsTable from "@/components/jobs/Table.vue";
import JobsTabs from "@/components/jobs/Tabs.vue";
import NewJobPre from "@/components/jobs/NewJobPre.vue";
import NewJobForm from "@/components/jobs/NewJobForm.vue";
import StatCard from "@/components/dashboard/admin/StatCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    StatCard,
    JobsTable,
    JobsTabs,
    NewJobPre,
    NewJobForm,
  },
  computed: {
    config() {
      return [
        {
          text: "OPEN JOBS",
          value: this._summary
            ? Number(this._summary.openJobCount) +
              Number(this._summary.openBidCount)
            : 0,
          textColor: "#E53934",
          borderColor: "#143E55",
          valueColor: "#4CAF50",
          padding: ["4", "3"],
        },
        {
          text: "OPEN BIDS",
          value: this._summary ? this._summary.openBidCount : 0,
          textColor: "#E53934",
          borderColor: "#143E55",
          valueColor: "#4CAF50",
          padding: ["4", "3"], //Group Statuses RGX-99
        },
        {
          text: "JOBS AWARDED",
          value: this._summary ? this._summary.awardedJobCount : 0,
          textColor: "#E53934",
          borderColor: "#143E55",
          valueColor: "#4CAF50",
          padding: ["4", "3"],
        },
        {
          text: "TOTAL NUMBER OF VENDORS",
          value: this._summary ? this._summary.vendorCount : 0,
          textColor: "#E53934",
          color: "#0091FF",
          borderColor: "#0091FF",
          labelColor: "#FFFFFF",
          valueColor: "#FFFFFF",
          padding: ["8", "6"],
          onClick: "vendor",
        },
        {
          text: "AVERAGE PRICE PER/LB",
          value: this._summary ? `$${this._summary.awardedBidPerPoundAvg}` : 0,
          textColor: "#E53934",
          color: "rgba(76,175,80,0.4)",
          borderColor: "#4CAF50",
          padding: ["8", "4"],
        },
        {
          text: "TOTAL NUMBER OF CUSTOMERS",
          value: this._summary ? this._summary.customerCount : 0,
          textColor: "#E53934",
          color: "#143E55",
          borderColor: "#143E55",
          labelColor: "#FFFFFF",
          valueColor: "#FFFFFF",
          padding: ["8", "6"],
          onClick: "customer",
        },
        {
          text: "LBS RECYCLED THIS MONTH",
          value: this._summary
            ? this.formatCommas(this._summary.monthWeight)
            : 0,
          textColor: "#E53934",
          color: "rgba(86,194,249,0.4)",
          borderColor: "#56C2F9",
          padding: ["8", "6"],
        },
        {
          text: "READY TO CLOSE",
          value: this._summary
            ? Number(this._summary.closingJobCount) +
              Number(this._summary.resaleJobCount)
            : 0,
          textColor: "#E53934",
          borderColor: "#143E55",
          valueColor: "#4CAF50",
          padding: ["4", "3"],
        },
        {
          text: "READY FOR P/O",
          value: this._summary ? this._summary.resaleJobCount : 0,
          textColor: "#E53934",
          borderColor: "#143E55",
          valueColor: "#4CAF50",
          padding: ["4", "3"], //Group Statuses RGX-99
        },
        {
          text: "UNDER DISPUTE",
          value: this._summary ? this._summary.disputedJobCount : 0,
          textColor: "#E53934",
          color: "#EBA5A3",
          borderColor: "#E53935",
          valueColor: "#E53935",
          padding: ["4", "3"],
        },
        {
          text: "AVERAGE PER/LB OFFER",
          value: this._summary ? `$${this._summary.bidPerPoundAvg}` : 0,
          textColor: "#E53934",
          color: "rgba(76,175,80,0.4)",
          borderColor: "#4CAF50",
          padding: ["8", "9"],
        },
        {
          text: "LIFETIME RECYCLED LBS",
          value: this._summary
            ? this.formatCommas(this._summary.totalWeight)
            : 0,
          textColor: "#E53934",
          color: "rgba(86,194,249,0.4)",
          borderColor: "#56C2F9",
          padding: ["8", "4"],
        },
        {
          text: "EMMISIONS OUTPUT (ALL)",
          value: this._summary ? `${this._summary.emmisionTotal}` : 0,
          unit: "(m/tn)",
          textColor: "#E53934",
          color: "rgba(85,77,173,0.4)",
          borderColor: "#554DAD",
          padding: ["8", "4"],
        },
      ];
    },
    ...mapGetters({
      _jobs: "jobs/getJobs",
      _bids: "bids/getBids",
      _summary: "dashboard/getSummary",
    }),
  },
  methods: {
    ...mapActions({
      _getSummary: "dashboard/acquireSummary",
    }),
    formatCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted() {
    this._getSummary();
  },
};
</script>
