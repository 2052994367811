<template>
  <div>
    <div class="title-bar">
      <v-card-title style="font-size: 26px" class="save--text">Bid List</v-card-title>
    </div>
    <v-slide-x-transition>
      <v-data-table v-if="_bids" :server-items-length="total" :options.sync="options" :headers="headers" :items="_bids"
        no-data-text="No Bids" :loading="_loading" @update:options="getBids()" multi-sort @click:row="handleClick"
        :item-class="rowClasses">
        <template #[`item.bid_id`]="{ item }">
          <v-tooltip top v-if="item.bid_status === 'confirm'">
            <template #activator="{ on, attrs }">
              <v-icon small color="#008cba !important" v-on="on" v-bind="attrs">mdi-alert-circle-outline
              </v-icon>
            </template>
            <span>Bid pending vendor confirmation</span>
          </v-tooltip>
          <v-icon small style="opacity: 0;" color="white" v-else>mdi-circle</v-icon>
          <span style="vertical-align: middle;" v-html="item.bid_id"></span>
        </template>
        <template #[`item.bid_service_charge`]="{ item }">
          <span style="color: red" v-html="item.bid_service_charge"></span>
        </template>
        <template #[`item.r2_cert`]="{ item }">
          {{ cer2Format(item.r2_cert) }}
        </template>
      </v-data-table>
      <v-banner v-else-if="this.$vuetify.breakpoint.mdAndUp" single-line transition="slide-y-transition">
        No Bids Found
        <template v-slot:actions="{}">
          <v-btn text color="primary" :loading="_loading" @click="getBids()">Retry</v-btn>
        </template>
      </v-banner>
      <v-banner v-else-if="this.$vuetify.breakpoint.smAndDown" transition="slide-y-transition">
        Sorry No Bids Found
        <template v-slot:actions="{}">
          <v-btn text color="primary" :loading="_loading" @click="getBids()">Retry</v-btn>
        </template>
      </v-banner>
    </v-slide-x-transition>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Options from '@/mixins/Options';
import config from '../../config';
export default {
  mixins: [Options],
  data() {
    return {
      page: 1,
      limit: 15,
      selected: null
    };
  },
  computed: {
    ...mapGetters({
      _bids: 'bids/getBids',
      _newBid: 'bids/getNewBid',
      _loading: 'bids/getLoading',
      _adminReview: 'bids/getAdminBidReview',
      _vendorReview: 'bids/getVendorBidReview',
      _customerReview: 'bids/getCustomerBidReview',
      _companyType: 'company/getCompanyType',
      _company: 'company/getCompany',
      _job: 'jobs/getCurrentJob'
    }),
    total() {
      return this._bids[0] ? parseInt(this._bids[0].total) : 0;
    },
    headers() {
      if (Array.isArray(this._bids) && this._bids.length > 0) {
        const keys = Object.keys(this._bids[0]);
        var headers = [];
        keys.forEach(i => {
          if (i !== 'comp_id' && i !== 'attachments' && i !== 'bid_status') {
            const obj = {
              text:
                i == 'r2_cert'
                  ? 'DATE CERT. EXPIRES'
                  : i.replace(/_/g, ' ').toUpperCase(), //date_cert._expires
              value: i,
              sortable:
                i == 'bid_id' ||
                i == 'date' ||
                i == 'vendor_name' ||
                i == 'bid_per_pound',
              filterable: true,
              divider: false,
              align: 'start',
              width:
                i === 'vendor' || i === 'location' || i === 'bid_id'
                  ? '80'
                  : '60'
            };
            if (obj.text !== 'TOTAL' && obj.text !== 'COMPANY ID') {
              headers.push(obj);
            }
          }
        });
        return headers;
      } else {
        return undefined;
      }
    },
    pagination() {
      return {
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10
      };
    }
  },
  watch: {
    _newBid() {
      this.getBids();
    },
    // _companyType() {
    //   this.getBids();
    // },
    _vendorReview(val) {
      if (!val) {
        this.getBids();
      }
    },
    _customerReview(val) {
      if (!val) {
        this.getBids();
      }
    },
    _companyType(val) {
      if (!val) {
        this.getBids();
      }
    }
  },
  created() {
    this.throttledMethod = _.debounce(() => {
      this.getBids();
    }, 1000);
    if (this._companyType) {
      this.getBids();
    } else {
      setTimeout(() => {
        this.getBids();
      }, 2000);
    }
  },
  methods: {
    ...mapActions({
      _getBids: 'bids/getAll',
      _getCurrentBid: 'bids/getCurrent'
    }),
    ...mapMutations({
      _setLoading: 'bids/toggleLoading',
      _setCustomerBidReview: 'bids/setCustomerBidReview',
      _setVendorBidReview: 'bids/setVendorBidReview',
      _setAdminBidReview: 'bids/setAdminBidReview',
      _setShowVendorBidReviewBox: 'bids/setVendorBidReviewBox'
    }),
    rowClasses(item) {
      if (item.bid_status === 'confirm') {
        return 'confirm-bid'; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    getBids() {
      this.options.job_id =
        this.$route.params.id && window.location.href.indexOf('vendor') < 0
          ? this.$route.params.id
          : this._job.job_id;
      console.log('this.$route.params.id  ', this.$route.params.id);
      console.log('  this._job.job_id', this._job.job_id);
      console.log('this.options', this.options);
      this._getBids(this.options).then(bids => {
        if (
          this._companyType === config.companyType.VENDOR &&
          Array.isArray(bids) &&
          bids.length > 0
        ) {
          this._getCurrentBid(bids[0].bid_id).then(currentBid => {
            if (
              currentBid.has_purchase_scrap === null &&
              currentBid.has_pickup_fee === null
            ) {
              this._setShowVendorBidReviewBox(false);
              return;
            }
            this._setShowVendorBidReviewBox(true);
          });
        } else {
          this._setShowVendorBidReviewBox(false);
        }
      });
    },
    clearSearch() {
      this.options.search = undefined;
      this.throttledMethod();
    },
    handleClick(value) {
      //debugger
      this._getCurrentBid(value.bid_id).then(currentBid => {
        if (
          currentBid.has_purchase_scrap === null &&
          currentBid.has_pickup_fee === null
        ) {
          this._setAdminBidReview(true);
          return;
        }

        if (this._companyType === config.companyType.VENDOR) {
          this._setVendorBidReview(true);
        } else if (this._companyType === config.companyType.CUSTOMER) {
          this._setCustomerBidReview(true);
        } else {
          this._setAdminBidReview(true);
        }
      });
    },
    cer2Format(d) {
      if (d == 'Not Certified') return d;
      var cer2 = moment(d, 'MM/DD/YYYY');
      var today = moment().format('MM/DD/YYYY');
      if (moment(today, 'MM/DD/YYYY') > cer2) return 'Not Certified';
      return d;
    }
  }
};
</script>
<style scoped>
.title {
  padding-left: 0;
}

.title-bar {
  display: flex;
}
</style>

<style>
.confirm-bid * {
  color: grey !important;
  font-style: italic;
}
</style>
