<template>
  <v-dialog
    v-model="_GoogleDocDialog"
    persistent
    @click:outside="clickoutside()"
    max-width="900px"
    max-height="600px"
  >
    <span
      class="nvgt round-button"
      id="prev"
      v-if="isDisabledLeft"
      @click="prev()"
    ></span>
    <span
      class="nvgt round-button"
      id="next"
      v-if="isDisabledRight"
      @click="next()"
    ></span>
    <span
      class="nvgt round-button"
      id="zoomin"
      onclick="if(document.getElementById('divimg').style.width!='100%') $('div#divimg').css({'width': (Number(document.getElementById('divimg').style.width.replace('%',''))+10)+'%' });"
      v-if="allowViewImage(_type)"
    >
      +
    </span>
    <span
      class="nvgt round-button"
      id="zoomout"
      onclick="if(document.getElementById('divimg').style.width!='20%')$('div#divimg').css({'width': (Number(document.getElementById('divimg').style.width.replace('%',''))-10)+'%'})"
      v-if="allowViewImage(_type)"
    >
      -
    </span>
    <v-card flat class="wrapper">
      <div class="btn-dismiss-dialog">
        <v-btn text @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-container class="pl-md-12" style="padding-right: 35px;">
        <v-card class="pa-4" flat>
          <div style="float:left;margin-top: 30px;">
            File name:
            <b>{{ _name }}</b>
          </div>
          <div style="float:right">
            <v-btn
              style=" margin-top: 25px"
              color="secondary"
              class="mr-2 elevation-0 mb-2"
              dark
              @click="reload()"
            >
              Reload
            </v-btn>
          </div>
        </v-card>
        <div
          id="divimg"
          style="max-height:550px;overflow-y: auto;clear:both;margin:0 auto;width:100%"
          v-if="allowViewImage(_type)"
        >
          <img id="imgshow" :src="_src" style="width:100%" />
        </div>
        <iframe
          id="iframe"
          v-if="allowViewFile(_type)"
          width="100%"
          height="750px"
          frameborder="0"
          style="border: 0"
          :src="
            `https://docs.google.com/viewer?embedded=true&url=` +
              _src +
              `&t=` +
              new Date()
          "
          allowfullscreen
        ></iframe>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    src: String,
    type: String
  },

  mounted() {
    let l = this._list;
    this.showPrevNext = l.length > 1;
  },
  data() {
    return {
      showPrevNext: false,
      allowViewImageTypes: ['jpg', 'png', 'jpeg', 'jpe', 'gif'],
      allowViewFileTypes: [
        'docx',
        'doc',
        'xls',
        'xlsx',
        'rtf',
        'ppt',
        'pptx',
        'pdf',
        'wmf',
        'emf',
        'txt',
        'csv'
      ]
    };
  },
  computed: {
    ...mapGetters({
      //   _file: "uploads/getFile",
      _GoogleDocDialog: 'googledoc/getShowGoogleDocDialog',
      _src: 'googledoc/getSrc',
      _type: 'googledoc/getType',
      _index: 'googledoc/getIndex',
      _list: 'googledoc/getList',
      _name: 'googledoc/getName'
    }),
    isDisabledLeft: function() {
      let l = this._list;
      let i = this._index;
      return l.length > 1 && i != 0;
    },
    isDisabledRight: function() {
      let l = this._list;
      let i = this._index;
      return l.length > 1 && i != l.length - 1;
    }
    // today() {
    //   return moment().format("YYYY-MM-DD");
    // },
    // job: {
    //   set(val) {
    //     this._setJob(val);
    //   },
    // getSrc() {
    //     return this.src;
    //   },
  },
  watch: {
    // _list(){
    //   let l=this._list;
    //   this.showPrevNext=l.length>1;
    // }
  },
  methods: {
    ...mapMutations({
      _setShowGoogleDocDialog: 'googledoc/setShowGoogleDocDialog',
      _setIndex: 'googledoc/setIndex'
    }),
    ...mapActions({
      // _updateBid: "bids/updateBid",
      // _uploadFile: "uploads/uploadFile",
      // _updateJob: "jobs/updateJob",
      // _setJob: "jobs/setJob",
    }),
    clickoutside() {
      console.log('clickoutside');
      this._setShowGoogleDocDialog(false);
      this._setShowGoogleDocDialog(true);
    },
    closeDialog() {
      this._setShowGoogleDocDialog(false);
      // this._setClosingConf(false);
      // window.location.href='/job';
      // console.log("This job is no longer open for bids. Redirecting to open job list view.");
      //this.$router.push("/job");
      //if (window.history.length > 2) {

      //    this.$router.go(-1);
      //}
    },
    reload() {
      // let i=this._index;
      // this._setIndex(i);
      // let s=this._src ;
      this._setShowGoogleDocDialog(false);
      this._setShowGoogleDocDialog(true);
    },
    prev() {
      //this._setShowGoogleDocDialog(false);
      let l = this._list;
      let i = this._index;
      this._setShowGoogleDocDialog(false);
      console.log('prev ' + i + '|' + this._src, l);
      if (i > 0) {
        let t = i - 1;
        this._setIndex(t);
        let s = this._src;
        //console.log("ok prev "+t +"|"+s,l);
      }
      //else{
      //   let t=l.length-1;
      //   this._setIndex(t);
      //   let s=this._src ;
      //}
      this._setShowGoogleDocDialog(true);
    },
    next() {
      this._setShowGoogleDocDialog(false);
      let l = this._list;
      let i = this._index;
      console.log('next ' + i + '|' + this._src, l);
      if (i < l.length - 1) {
        let t = i + 1;
        this._setIndex(t);
        let s = this._src;
        // console.log("ok next "+t +"|"+s,l);
      }
      //else{
      // let t=0;
      //  this._setIndex(t);
      //  let s=this._src ;
      //}
      this._setShowGoogleDocDialog(true);
    },
    allowViewImage(type) {
      return this.allowViewImageTypes.includes(type.toLowerCase());
    },
    allowViewFile(type) {
      return this.allowViewFileTypes.includes(type.toLowerCase());
    }
  }
};
</script>
<style>
.v-dialog-customize {
  border: 10px solid !important;
  border-color: #44546a !important;
}

.nvgt {
  position: absolute;
  top: 50%;
  height: 50px;
  width: 30px;
  opacity: 0.6;
  z-index: 99999;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.nvgt:hover {
  opacity: 0.9;
}

#prev {
  background: #2a2b49 url('/left.png') no-repeat center;
  background-size: cover;
  left: 20px;
}

#next {
  background: #2a2b49 url('/right.png') no-repeat center;
  background-size: cover;
  right: 20px;
}

#zoomin {
  background: #2a2b49 no-repeat center;
  background-size: cover;
  right: 48%;
  top: 90%;
}

#zoomout {
  background: #2a2b49 no-repeat center;
  background-size: cover;
  right: 52%;
  top: 90%;
}

/* arrow common style */
.round-button {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  /* border: 2px solid #f5f5f5;*/
  border-radius: 50%;
  color: #f5f5f5;
  text-align: center;
  text-decoration: none;
  background: #2a2b49;
  box-shadow: 0 0 3px gray;
  font-size: 20px;
  font-weight: bold;
}

/*.round-button:hover {
    background: #777555;
}*/
</style>
<style scoped>
.v-dialog--persistent {
  overflow: hidden;
}
</style>
