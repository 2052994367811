<template>
  <v-menu
    v-model="accountMenu"
    v-if="user?.id && _company?.type_id"
    :close-on-content-click="false"
    :nudge-bottom="50"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn rounded :color="color" depressed small v-on="on" class="pr-0">
        <div v-if="_company.type_id === 3" class="mr-3 white--text">
          <v-icon small class="left">mdi-shield-account-variant</v-icon>
          {{ customerType(_company.type_id) }}
        </div>
        <div v-else>
          {{ customerType(_company.type_id) }}
          <v-icon class="ml-2 ma-0 mr-1 float absolute right"
            >mdi-account-circle-outline</v-icon
          >
        </div>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item class="align-stretch pt-2">
          <v-row no-gutters class="d-flex flex-column flex-grow-0">
            <v-list-item-avatar
              tile
              style="position: relative;"
              width="50px"
              height="50px"
              class="ma-0 mb-1"
            >
              <v-img
                max-width="40px"
                max-height="40px"
                class="rounded-circle"
                v-if="user.icon_url"
                :src="user.icon_url"
              />
              <v-icon v-else x-large>mdi-account-circle</v-icon>
              <v-btn
                style="transform: translate(50%, 50%); position: absolute; bottom: 0; right: 0; background-color: white; width: 16px; height: 16px;"
                fab
                x-small
                color="secondary"
                class="white--text mr-2 mb-2"
                depressed
                @click="$refs.file.click()"
              >
                <v-icon x-small>mdi-upload-outline</v-icon>
                <input
                  ref="file"
                  type="file"
                  accept="image/*"
                  @change="uploadImg($event)"
                  style="display: none"
                />
              </v-btn>
            </v-list-item-avatar>
            <v-chip
              label
              small
              pill
              color="#a5d7a7"
              class="justify-center"
              v-if="userRole"
              >{{ userRole }}</v-chip
            >
          </v-row>

          <v-list-item-title
            class="d-flex flex-column flex-nowrap align-start px-4 py-0 align-self-stretch"
            :class="
              (user.phone || user.alt_phone) && fullName
                ? 'justify-space-between'
                : ''
            "
          >
            <div class="text-body-1" v-if="fullName">{{ fullName }}</div>
            <div class="text-body-1">
              {{ user.email }}
            </div>
            <div v-if="user.phone" class="text-body-1">
              {{ user.phone }}
            </div>
            <div v-else-if="user.alt_phone" class="text-body-1">
              {{ user.alt_phone }}
            </div>
          </v-list-item-title>

          <v-list-item-action class="ma-0 pl-8 pr-4 align-self-stretch">
            <v-btn
              fab
              color="secondary"
              class="white--text"
              x-small
              depressed
              @click="editUser"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item tag="a" @click="logOut">
          <v-list-item-avatar>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-title style="">Log Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
    <!-- v-show="showEditProfileDialog" -->
    <edit-profile-dialog
      ref="profileDlg"
      :visible="showEditProfileDialog"
      @userUpdated="onUserUpdated"
      @close="onEditProfileDlgClose"
      @change="onProfileChange"
      @emailConflicted="onEmailConflicted"
    />
    <warning-unsaved-change
      :isShow="showUnsavedWarning"
      :discardChanged="discardChanges"
      :saveChanges="saveChanges"
      @onCloseDialog="onWarningDlgClose"
    />
    <existed-email-warning-dialog
      :visible="showExistedEmailWarning"
      @close="showExistedEmailWarning = false"
    />
  </v-menu>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EditProfileDialog from './EditProfileDialog.vue';
import WarningUnsavedChange from '@/components/profile/WarningUnsavedChange.vue';
import ExistedEmailWarningDialog from '@/components/app/ExistedEmailWarningDialog.vue';
export default {
  components: {
    EditProfileDialog,
    WarningUnsavedChange,
    ExistedEmailWarningDialog
  },
  data() {
    return {
      accountMenu: false,
      hasCustomLogOutHandler: ['/profile'],
      showUnsavedWarning: false,
      showEditProfileDialog: false,
      showExistedEmailWarning: false,
      hasProfileChanges: false,
      user: undefined,
      unsavedIcon: false,
      rules: {
        image: [
          value =>
            !value ||
            value.size < 2000000 ||
            'Avatar size should be less than 2 MB!'
        ]
      }
    };
  },
  // mounted() {
  //   if (this._user) {
  //     this._getUser(this._user.id);
  //   }
  // },
  computed: {
    ...mapGetters({
      _user: 'user/getUser',
      // _userRole: 'user/getUserRole',
      _companyName: 'company/getCompanyName',
      _company: 'company/getCompany',
      _editedUser: 'user/getEditedUser',
      _userToEdit: 'user/getUserToEdit',
      _icon: 'user/getIconUrl',
      _loggedInUser: 'user/getLoggedInUser'
    }),
    userName() {
      return this.user?.user_name || '';
    },
    fullName() {
      if (!this.user) return '';

      const { first_name, last_name } = this.user;
      return `${first_name || ''} ${last_name || ''}`.trim();
    },
    color() {
      switch (this._company.type_id) {
        case 3:
          return '#ED8300';
        case 2:
          return 'secondary';
        default:
          return 'primary';
      }
    },
    userRole() {
      return this._loggedInUser.type_id === 1 ? 'Admin' : 'Standard';
    }
  },
  watch: {
    // _user(newVal) {
    //   if (this._user) {
    //     this._getUser(this._user.id);
    //   }
    // },
    _loggedInUser: {
      handler(newVal) {
        this.user = newVal;
      },
      deep: true,
      immediate: true
    },
    // when a new user icon is uploaded, update user info
    _icon() {
      if (!this.unsavedIcon) return;

      // update user avatar without showing success dialog
      this.$refs.profileDlg.update(false).then(() => {
        this.unsavedIcon = false;
        this.user.icon_url = this._user.icon_url;
      });
    }
  },
  methods: {
    ...mapActions({
      _logOut: 'authenticate/logOut',
      _toggleEditing: 'user/toggleEditing',
      _uploadFile: 'user/uploadIcon',
      _getUser: 'user/getById'
    }),
    ...mapMutations({
      _setPreLogOut: 'authenticate/setPreLogOut',
      _setUser: 'user/setUser',
      _setNoti: 'notification/setNotification',
      _setNotiMsg: 'notification/setNotificationMsg',
      _setNotiType: 'notification/setNotificationType'
    }),
    customerType(type) {
      if (type === 3) {
        return 'ADMIN';
      } else if (type === 2) {
        return 'VENDOR';
      } else if (type === 1) {
        return 'CUSTOMER';
      }
    },
    logOut() {
      this.$emit('close');
      // if current route does not have custom handler for logging out -> proceed to log out normally
      if (!this.hasCustomLogOutHandler.includes(this.$route.path)) {
        this._logOut();
      } else {
        this._setPreLogOut(true);
      }
    },
    editUser() {
      this.showEditProfileDialog = true;
    },
    onUserUpdated() {
      this._setUser(this._editedUser);
    },
    onEmailConflicted() {
      this.showUnsavedWarning = false;
      this.showEditProfileDialog = false;
      this.$refs.profileDlg.discard();
      this.showExistedEmailWarning = true;
    },
    showUploadUserIconError(msg) {
      this._setNoti(true);
      this._setNotiMsg(msg);
      this._setNotiType('error');
    },
    uploadImg(e) {
      const file = e.target.files[0];
      if (file?.['type'].split('/')[0] !== 'image') {
        this.showUploadUserIconError(
          'Invalid file type. Please upload an image.'
        );
        return;
      }

      this.unsavedIcon = true;
      this._uploadFile({ file, type: 'user_icon' });

      // the _icon watcher will be triggered once icon is uploaded
      // reason: FileReader.onload is async inside _uploadFile and we don't know when it's done
    },
    discardChanges() {
      if (!this.hasProfileChanges) {
        return;
      }

      this.showUnsavedWarning = false;
      this.showEditProfileDialog = false;
      this.$refs.profileDlg.discard();
    },
    saveChanges() {
      if (!this.hasProfileChanges) {
        return;
      }

      this.$refs.profileDlg.update();
      this.showUnsavedWarning = false;
      this.hasProfileChanges = false;
    },
    onWarningDlgClose(val) {
      this.showUnsavedWarning = val;
    },
    onEditProfileDlgClose() {
      if (!this.hasProfileChanges) {
        this.showEditProfileDialog = false;
        this.$refs.profileDlg.discard();
        return;
      }

      this.showUnsavedWarning = true;
    },
    onProfileChange(val) {
      this.hasProfileChanges = val;
    }
  }
};
</script>
