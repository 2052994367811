<template>
  <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey"></div>
</template>
<script>
export default {
  data() {
    return {
      //your google sitekey
      sitekey: '6LcSaFslAAAAAPMYdYYxMpGBFsytr4DhSGxBfQsA',
      // sitekey: '6Le8cmclAAAAAEKdgniexKAzge9yXhQ9nbSZF6j-',// huy's key
      vueWidgtId: 0
    };
  },
  methods: {
    //some vuejs methods
    //  expired_callback () {
    //    console.log("expired_callback");
    //     this.$emit('epx');
    //  },
    execute() {
      console.log('window.grecaptcha.execute');
      window.grecaptcha.execute(this.vueWidgtId);
    },
    reset() {
      //reset using vuejs
      console.log('window.grecaptcha.reset');
      window.grecaptcha.reset(this.vueWidgtId);
    },
    render() {
      //render Functions using vuejs
      if (window.grecaptcha) {
        this.vueWidgtId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.sitekey, //get your sitekey
          //size: 'invisible',
          callback: results => {
            // console.log("callback: (results)",results);
            this.$emit('verify', results);
            //this.reset() ;
          },
          'expired-callback': () => {
            //console.log("expired-callback ok");
            this.$emit('epx');
          }
        });
      }
    }
  },
  mounted() {
    this.render();
    //   if (window.grecaptcha) {
    //   this.vueWidgtId = grecaptcha.render( $('.g-recaptcha')[0], { sitekey : this.sitekey });
    //  }
  }
};
</script>
