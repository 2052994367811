<template>
	<v-card outlined>
		<v-row no-gutters>
			<v-subheader>Operating Hours</v-subheader>
			<v-spacer />
			<v-tooltip max-width="200" bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-icon color="secondary" class="pa-2" light v-bind="attrs" v-on="on"
						>mdi-information</v-icon
					>
				</template>
				<span
					>This is used to set/display your hours of operation. You can edit
					each line by clicking the pencil icon, and/or set the day to closed or
					open by toggeling the door icon.</span
				>
			</v-tooltip>
		</v-row>
		<v-simple-table :disabled="disabled">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">Actions</th>
						<th class="text-left">Day</th>
						<th class="text-left">Open</th>
						<th class="text-left">Close</th>
						<!-- <th>Open/Closed</th> -->
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(day, i) in company.hours_of_op"
						:key="i"
						:disabled="disabled"
					>
						<td class="flex-nowrap">
							<v-btn
								v-if="!day.closed"
								icon
								:disabled="disabled"
								@click="day.closed = !day.closed"
							>
								<v-icon small>mdi-door-open</v-icon>
							</v-btn>
							<v-btn
								icon
								:disabled="disabled"
								v-else
								@click="day.closed = !day.closed"
							>
								<v-icon small>mdi-door-closed</v-icon>
							</v-btn>
							<v-btn
								icon
								v-if="!disabled && !day.closed && !day.edit"
								:disabled="disabled"
								@click="day.edit = !day.edit"
							>
								<v-icon small>mdi-pencil</v-icon>
							</v-btn>
							<v-btn
								v-if="day.edit && !day.closed"
								icon
								@click.stop="day.edit = !day.edit"
							>
								<v-icon small>mdi-content-save</v-icon>
							</v-btn>
						</td>

						<td>{{ day.day }}</td>
						<td v-if="!day.edit && !day.closed" class="flex-nowrap">
							{{ formTime(day.open) }}
						</td>
						<td v-else-if="day.closed">--:--</td>
						<td v-else>
							<v-text-field
								v-model="day.open"
								:disabled="disabled"
								type="time"
								hide-details
								label="Open"
								@click.stop
								@keyup.enter="day.edit = false"
							></v-text-field>
						</td>
						<td v-if="!day.edit && !day.closed">{{ formTime(day.close) }}</td>
						<td v-else-if="day.closed">--:--</td>
						<td v-else>
							<v-text-field
								v-model="day.close"
								:disabled="disabled"
								type="time"
								hide-details
								label="Close"
								@click.stop
								@keyup.enter="day.edit = false"
							></v-text-field>
						</td>

						<!-- <td>{{ item.calories }}</td> -->
					</tr>
				</tbody>
			</template>
		</v-simple-table>
		<v-dialog v-model="masterTime" max-width="400px">
			<v-card flat>
				<v-card-title class="headline grey lighten-2">Master Time</v-card-title>
				<v-card-text class="mt-4">
					<v-row>
						<v-col sm="3">Set all days to:</v-col>
						<v-col>
							<v-menu
								ref="masterOpen"
								v-model="masterOpenPicker"
								:close-on-content-click="false"
								:nudge-right="40"
								:return-value.sync="masterOpen"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="masterOpen"
										hide-details
										label="Open"
										v-on="on"
									></v-text-field>
								</template>
								<v-time-picker
									v-if="masterOpenPicker"
									v-model="masterOpen"
									format="ampm"
									:ampm-in-title="true"
									full-width
									@click:minute="$refs.masterOpen.save(time)"
								></v-time-picker>
							</v-menu>
						</v-col>
						<v-col>
							<v-menu
								ref="masterClose"
								v-model="masterClosePicker"
								:close-on-content-click="false"
								:nudge-right="40"
								:return-value.sync="masterClose"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="masterClose"
										hide-details
										label="Close"
										v-on="on"
									></v-text-field>
								</template>
								<v-time-picker
									v-if="masterClosePicker"
									v-model="masterClose"
									format="ampm"
									:ampm-in-title="true"
									full-width
									@click:minute="$refs.masterClose.save(time)"
								></v-time-picker>
							</v-menu>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="primary">Set</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
export default {
	props: {
		disabled: Boolean,
		register: { type: Boolean, default: false },
	},
	data() {
		return {
			masterOpen: undefined,
			masterClose: undefined,
			masterClosePicker: false,
			masterOpenPicker: false,
			masterTime: false,
			defaultDays: [
				{
					day: "Sunday",
					edit: false,
					open: "08:00",
					close: "17:00",
					openpicker: false,
					closepicker: false,
					closed: false,
				},
				{
					day: "Monday",
					edit: false,
					open: "08:00",
					close: "17:00",
					openpicker: false,
					closepicker: false,
					closed: false,
				},
				{
					day: "Tuesday",
					edit: false,
					open: "08:00",
					close: "17:00",
					openpicker: false,
					closepicker: false,
					closed: false,
				},
				{
					day: "Wednesday",
					edit: false,
					open: "08:00",
					close: "17:00",
					openpicker: false,
					closepicker: false,
					closed: false,
				},
				{
					day: "Thursday",
					edit: false,
					open: "08:00",
					close: "17:00",
					openpicker: false,
					closepicker: false,
					closed: false,
				},
				{
					day: "Friday",
					edit: false,
					open: "08:00",
					close: "17:00",
					openpicker: false,
					closepicker: false,
					closed: false,
				},
				{
					day: "Saturday",
					edit: false,
					open: "08:00",
					close: "17:00",
					openpicker: false,
					closepicker: false,
					closed: false,
				},
			],
		};
	},
	watch: {
		disabled() {
			if (this.disabled) {
				this.company.hours_of_op.forEach((i) => {
					i.edit = false;
				});
			}
		},
		company() {
			this.company.hours_of_op = this.register
				? this.defaultDays
				: this.company.hours_of_op;
		},
	},
	computed: {
		...mapGetters({
			_newComp: "registration/getNewCompany",
			_loading: "registration/getLoading",
			_error: "registration/getError",
			_confId: "registration/getConfId",
			_company: "company/getCompany",
		}),
		company: {
			get() {
				return this.register ? this._newComp : this._company;
			},
			set(newVal) {
				this.register ? this._setNewComapny(newVal) : this._setCompany(newVal);
			},
		},
	},
	methods: {
		...mapMutations({
			_setNewCompany: "registration/setCompany",
			_setCompany: "company/setCompany",
		}),
		formTime(time) {
			return moment(time, "hh:mm").format("hh:mm A");
		},
	},
	mounted() {
		this.company.hours_of_op = this.register
			? this.defaultDays
			: this.company.hours_of_op;
	},
};
</script>
