<template>
  <div>
    <v-card flat v-if="_company" style="min-height: 130px; max-height: 130px">
      <v-card-title
        style="position: relative; padding: 0; max-width: 120px; max-height: 120px;"
      >
        <v-img
          position="bottom left"
          contain
          v-if="_company.icon_url"
          :src="_company.icon_url"
          style="position: relative; max-width: 120px; max-height: 120px;"
        />

        <v-col
          v-else
          cols="12"
          align="center"
          justify="center"
          class="d-flex flex-column align-center grey"
        >
          <v-avatar tile>
            <v-img
              position="top left"
              style="max-height: 120px; max-width: 120px;"
            >
              <v-icon>mdi-domain</v-icon>
            </v-img>
          </v-avatar>
          <span class="subtitle-2 font-weight-regular"
            >Upload your<br />
            own logo</span
          >
        </v-col>
        <v-btn
          style="transform: translate(50%, 50%); position: absolute; bottom: 0; right: 0; background-color: white; width:24px; height:24px"
          color="secondary"
          icon
          v-if="edit"
          @click="$refs.file.click()"
        >
          <v-icon medium>mdi-upload-circle</v-icon>
          <input
            ref="file"
            type="file"
            accept="*"
            @change="uploadImg($event)"
            style="display: none"
          />
        </v-btn>
      </v-card-title>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    edit: Boolean
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany'
    })
  },
  methods: {
    ...mapActions({
      _uploadLogo: 'company/uploadLogo'
    }),
    uploadImg(e) {
      this._uploadLogo({ file: e.target.files[0], type: 'company_icon' });
    }
  }
};
</script>
