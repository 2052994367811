<template>
  <div v-if="!_loading">
    <v-row no-gutters justify="start" align="center">
      <v-col sm="6" md="6">
        <v-btn text small class="my-3" @click="handleGoBack">
          <v-icon>mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </v-col>
    </v-row>
    <awarded />
  </div>
  <div v-else-if="_loading" class="ma-5">
    <skeleton-detail />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import SkeletonDetail from '@/components/skeleton/Detail.vue';

import Awarded from '@/components/jobs/AwardedDetails.vue';
export default {
  components: {
    Awarded,
    SkeletonDetail
  },

  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      _bidCompany: 'bids/getCurrentBidCompany',
      // _bid: "bids/getCurrentBid",
      _bid: 'bids/getCurrentBidInfo',
      _loading: 'jobs/loading',
      _user: 'user/getUser'
    }),
    total() {
      return (
        Number(this._job.bid_per_pound) * this._job.final_weight -
        (this._job.resale_offer !== null ? this._job.resale_offer : 0)
      );
    },
    buy_out_total() {
      return (
        Number(this._job.bid_buy_out) -
        (this._job.resale_offer !== null ? this._job.resale_offer : 0)
      );
    }
  },
  methods: {
    ...mapActions({
      _setJob: 'jobs/setJob',
      _setCurrent: 'jobs/setCurrent',
      _setBid: 'bids/getCurrentInfo'
    }),
    ...mapMutations({
      //  _setJob: "jobs/setCurrent",
      //  _setJob: "jobs/setJob",
      //  _setBid: "bids/getCurrentInfo",
    }),
    handleGoBack() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push('/job');
      }
      //window.location.href = '/history';
    }
  },
  watch: {
    _job() {
      if (this._job.bid_id) {
        console.log('watch this._job', this._job);
        this._setBid(this._job.bid_id);
      }
    },
    _user() {
      //  console.log("watch this._user",this._user);
      if (
        !this._job ||
        !this._job.job_id ||
        this._job.job_id != this.$route.params.id
      )
        this._setCurrent(this.$route.params.id);
    }
  },
  mounted() {
    // this._setJobCurrent(this.$route.params.id);
    // console.log("mounted this._job",this._job);
    if (
      ((!this._job || !this._job.job_id) && this._user) ||
      this._job.job_id != this.$route.params.id
    ) {
      console.log('mounted _setCurrent ', this.$route.params.id);
      this._setCurrent(this.$route.params.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  width: 80%;
}

.weight {
  grid-column: 1;
  grid-row: 1;
}

.pallets {
  grid-column: 1;
  grid-row: 2;
}

.recycling {
  grid-column: 3;
  grid-row: 1 / span 3;
}

.access {
  grid-column: 2;
  grid-row: 1;
}

.lift {
  grid-column: 2;
  grid-row: 2;
}

.title-font {
  font-family: Roboto;
  font-size: 17px;
  color: #2e2e2e;
}

.subtitle-font {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #5d5d5d;
}

.big-text-font {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #2e2e2e;
}

.vendor {
  grid-column: 1;
}

.address {
  grid-column: 2;
}

.phone {
  grid-column: 3;
}

.email {
  grid-column: 4;
}

.company-name {
  font-family: Roboto;
  font-size: 23px;
  color: #2e2e2e;
}

.center {
  align-self: center;
}

.link-color {
  color: #0091ff;
}

a {
  text-decoration: none;
}
</style>
