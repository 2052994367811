const companyProfileStatus = {
  complete: 1,
  incomplete: 2
};

const COMPANY_TYPE = {
  CUSTOMER: 1,
  VENDOR: 2,
  MASTER: 3 //admin
};

const JOB_STATUS = Object.freeze({
  OPEN: 'open',
  DRAFT: 'draft',
  PPO: 'ppo',
  DISPUTE: 'dispute',
  AWARDED: 'awarded',
  OPEN_BID: 'open_bid',
  AWARDED_BID: 'awarded_bid',
  RESALE: 'resale',
  CLOSING: 'closing',
  CLOSED: 'closed',
  REJECTED: 'rejected'
});

const BID_STATUS = Object.freeze({
  CONFIRM: 'confirm',
  AWARDED: 'awarded',
  REJECTED: 'rejected',
  OPEN: 'open'
});

const LOGISTICS_OPTIONS = Object.freeze({
  VENDOR_PICKUP: {
    title: 'Vendor Pickup',
    value: 1,
    reviewValue: 'Vendor Pickup'
  },
  CUSTOMER_DROPOFF: {
    title: 'Customer Drop-Off',
    value: 2,
    reviewValue: 'Customer Drop-Off'
  },
  EITHER: {
    title: 'Either',
    value: 3,
    reviewValue: 'Vendor Pickup or Customer Drop-Off'
  }
});

const EQUIPMENT_TRANSFER_LOGISTICS_OPTIONS = Object.freeze({
  PICKUP: {
    title: 'Pickup',
    value: 1,
    reviewValue: 'Pickup Date'
  },
  DROPOFF: {
    title: 'Drop-Off',
    value: 2,
    reviewValue: 'Drop-Off Date'
  }
});

const EXACT_REMOVE_DATE_OPTIONS = Object.freeze({
  EXACT_DATE: { title: 'Exact Date', value: 1, reviewValue: 'Exact Date' },
  PLUS_OR_MINUS_DATE: {
    title: '+/- 3 Days',
    value: 2,
    reviewValue: '+/- 3 Days'
  },
  FLEXIBLE_DATE: {
    title: 'Flexible Date',
    value: 3,
    reviewValue: 'Flexible Date'
  }
});

const ASSET_TYPE_LABEL = Object.freeze({
  network: 'Network (e.g, routers, switches, servers, firewalls)',
  //raw: 'Raw Meterials (e.g, plastics, metals, cables)',
  raw: 'Raw Materials (e.g, plastics, metals, cables)',
  mobile: 'Mobility (e.g, computers, cell phones, tablets, hotspots)',
  infr: 'Infrastructure (generators, HVAC, ladder rack etc)',
  other: 'Other'
});

const ASSET_TYPE_LABEL_REVIEW = Object.freeze({
  network: 'Network',
  // raw: 'Raw Meterials',
  raw: 'Raw Materials',
  mobile: 'Mobility',
  infr: 'Infrastructure',
  other: 'Other'
});

//RGX-437 poc,project_manager, inactive => inactive => typeId = 4
const USER_TYPE = Object.freeze({
  admin: 1,
  standard: 2,
  super: 3,
  poc: 4,
  project_manager: 4,
  inactive: 4
});

var ASSET_TYPE = {
  network: false,
  raw: false,
  mobile: false,
  infr: false,
  other_checked: false,
  other: ''
};

const DATA_TOOL_TIP = Object.freeze({});

const KEY = Object.freeze({
  Commodity_Report_Template: 'commodity_report_template',
  Equipment_List: 'equipment_list',
  Inventory: 'inventory',
  Misc: 'misc',
  Purchase_Inventory: 'purchase_inventory'
});

const functionKeys = Object.freeze([
  // F1-F12
  ...Array.from({ length: 12 }, (_, i) => 112 + i),
  // Special keys
  8, // Back - delete
  13, // Enter
  16, // Shift
  17, // Ctrl
  18, // Alt
  19, // Pause/Break
  20, // CapsLock
  27, // Escape
  32, // Space bar
  33, // Page Up
  34, // Page Down
  35, // End
  36, // Home
  37, // Left arrow
  38, // Up arrow
  39, // Right arrow
  40, // Down arrow
  45, // Insert
  46 // Delete
]);

const dateFormatTypes = Object.freeze({
  isoDate: 'YYYY-MM-DD',
  isoDateTime: 'YYYY-MM-DDTHH:mm:ss',
  isoDateTimeZ: 'YYYY-MM-DDTHH:mm:ssZ',
  rfc2822: 'ddd, DD MMM YYYY HH:mm:ss Z',
  shortDate: 'MM/DD/YYYY',
  shortDate2: 'DD/MM/YYYY',
  longDate: 'MMMM DD, YYYY',
  longDate2: 'DD MMMM YYYY',
  fullDateTime: 'MM/DD/YYYY HH:mm:ss',
  fullDateTime2: 'DD/MM/YYYY HH:mm:ss',
  fullDateTime3: 'MMMM DD, YYYY HH:mm:ss A',
  fullDateTime4: 'DD MMMM YYYY HH:mm:ss'
});

const ConstString = Object.freeze({
  NONE: 'none'
});

const CHECK_VENDOR_MESSAGE = Object.freeze({
  INVALID: 'Invalid Vendor Code',
  INACTIVE: 'Inactive Vendor',
  INELIGIBLE_VENDOR: 'Ineligible Vendor'
});

const VENDOR_STATUS = Object.freeze({
  ACTIVE: 'Active',
  NEW: 'New',
  ARCHIVE: 'Archive',
  ON_HOLD: 'On Hold',
  DELETE: 'Delete'
});

const ListOfUnits = Object.freeze([
  {
    unit: 'per device',
    value: 1
  },
  {
    unit: 'per pound',
    value: 2
  },
  {
    unit: 'per hour',
    value: 3
  },
  {
    unit: 'per half day',
    value: 4
  },
  {
    unit: 'per day',
    value: 5
  }
]);

export default {
  companyProfileStatus,
  companyType: COMPANY_TYPE,
  COMPANY_TYPE: COMPANY_TYPE,
  JOB_STATUS: JOB_STATUS,
  LOGISTICS_OPTIONS: LOGISTICS_OPTIONS,
  EXACT_REMOVE_DATE_OPTIONS: EXACT_REMOVE_DATE_OPTIONS,
  ASSET_TYPE: ASSET_TYPE,
  ASSET_TYPE_LABEL: ASSET_TYPE_LABEL,
  DATA_TOOL_TIP: DATA_TOOL_TIP,
  USER_TYPE: USER_TYPE,
  ASSET_TYPE_LABEL_REVIEW: ASSET_TYPE_LABEL_REVIEW,
  KEY: KEY,
  EQUIPMENT_TRANSFER_LOGISTICS_OPTIONS: EQUIPMENT_TRANSFER_LOGISTICS_OPTIONS,
  functionKeys: functionKeys,
  dateFormatTypes: dateFormatTypes,
  ConstString: ConstString,
  BID_STATUS: BID_STATUS,
  CHECK_VENDOR_MESSAGE: CHECK_VENDOR_MESSAGE,
  VENDOR_STATUS: VENDOR_STATUS,
  ListOfUnits: ListOfUnits
};
