<template>
  <v-card flat>
    <v-row no-gutters>
      <v-col sm="12" offset="3" md="6">
        <div style="margin-top: 20px; margin-left: 30px">
          <h1>Welcome to RGX!</h1>
          <h1 class="mb-7">Let's personalize the experience.</h1>
        </div>
        <v-stepper v-model="step" class="elevation-0" vertical tile non-linear flat>
          <v-stepper-step :complete="step > 1" step="1">
            Company Information
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-row class="d-flex flex-wrap">
              <v-col class="ml-15">
                <v-form v-model="addressValidated" ref="addressValidated">
                  <v-text-field
                    v-model="newCompany.name"
                    :rules="[companyNameRules.required]"
                    label="Company Name *"
                    prepend-icon="mdi-office-building"
                    required
                  ></v-text-field>
                  <VueGooglePlaces
                    :api-key="googleKey"
                    types="establishment"
                    enableGeolocation
                    version="3.41"
                    country="us"
                    @placechanged="onPlaceChanged"
                  >
                    <v-text-field
                      prepend-icon="mdi-map-search"
                      :label="`Address Finder...`"
                      :placeholder="finderPlaceholder"
                      v-model="address"
                    />
                  </VueGooglePlaces>

                  <!--  <v-text-field
                    label="Company Street Address *"
                    type="address"
                    :rules="[addressRules.required]"
                    dense
                    v-model="newCompany.street"
                    class="pr-2"
                  />-->

                  <v-row>
                    <v-col sm="8">
                      <v-text-field
                        label="Company Street Address *"
                        type="address"
                        :rules="[addressRules.required]"
                        dense
                        v-model="newCompany.street"
                        class="mt-5"
                      />
                    </v-col>
                    <v-col sm="4">
                      <v-text-field dense v-model="newCompany.suite" maxlength="10" label="Suite " class="mt-5" />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col sm="4">
                      <v-text-field :rules="[cityRules.required]" dense label="City *" v-model="newCompany.city" class="mt-5" />
                    </v-col>

                    <v-col sm="4">
                      <v-autocomplete
                        v-model="newCompany.state"
                        :items="usStates"
                        item-text="text"
                        item-value="value"
                        label="State *"
                        :rules="[stateRules.required]"
                      />
                    </v-col>
                    <v-col sm="4">
                      <v-text-field
                        dense
                        label="Zip Code *"
                        v-mask="'#####'"
                        :rules="[zipCodeRules.required, zipCodeRules.isCorrectFormat]"
                        v-model="newCompany.zip"
                        class="mt-5"
                      />
                    </v-col>
                  </v-row>
                  <p>Type of Business*</p>
                  <v-radio-group class="ml-5" v-model="newCompany.type_id" column :rules="[typeOfBussinessRules.required]" required>
                    <v-radio label="ITAD/E-Waste Vendor" value="2"></v-radio>
                    <v-radio label="Customer" value="1"></v-radio>
                  </v-radio-group>
                </v-form>
              </v-col>
            </v-row>

            <h2>User Information</h2>
            <v-row class="d-flex flex-wrap">
              <v-col class="ml-15">
                <v-form v-model="userValid" ref="userValid">
                  <v-text-field v-model="newUser.first_name" :rules="[firstNameRules.required]" label="First Name*" required></v-text-field>
                  <v-text-field v-model="newUser.last_name" :rules="[lastNameRules.required]" label="Last Name*" required></v-text-field>
                  <v-text-field
                    v-model="newUser.email"
                    ref="emailValid"
                    :rules="[emailRules.required, emailRules.patternMatch]"
                    label="Email*"
                    @blur="checkEmailExisted"
                    required
                  ></v-text-field>
                  <v-checkbox v-model="acceptTerms" :rules="[acceptTermsRules.required]">
                    <template v-slot:label>
                      <div>
                        I accept the
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              style="color: green"
                              target="_blank"
                              href="https://www.recyclegx.com/wp-content/uploads/2021/07/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf"
                              @click.stop
                              v-on="on"
                            >
                              Terms of service
                            </a>
                          </template>
                          Terms of service
                        </v-tooltip>
                        and
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a style="color: green" target="_blank" href="https://www.recyclegx.com/privacy-policy/" @click.stop v-on="on">
                              Privacy Policy.
                            </a>
                          </template>
                          Privacy Policy
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                </v-form>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn :loading="_loading" block x-large rounded :color="'success'" @click="confirmEmail">
                  <span>Confirm Email</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-step :complete="step > 2" step="2">
            Complete your account
          </v-stepper-step>
          <v-stepper-content step="2">
            <h1>Complete your account</h1>
            <h3>We have sent a code to {{ newUser.email }}.</h3>
            <h3 class="mb-7">Enter the code below to confirm your account.</h3>

            <v-card flat>
              <v-card-text>
                <v-form v-model="passwordValidated" ref="passwordValidated">
                  <v-text-field
                    v-model="newUser.conf_code"
                    label="Email Confirmation Code*"
                    :rules="[emailConfirmCodeRules.required]"
                    :error-messages="wrongConfCodeMsg"
                    prepend-icon="mdi-email-lock"
                    type="text"
                    v-mask="'#####'"
                    required
                  >
                    <template v-slot:append>
                      <div v-show="!_loading" class="resendCode" v-if="!resend" @click="resendCode">
                        <v-icon large>mdi-cached</v-icon>
                        <span>Resend Code</span>
                      </div>
                      <div v-else v-show="!_loading">
                        <v-icon large color="green">mdi-check-bold</v-icon>
                        <span>Code Sent</span>
                      </div>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="newUser.password"
                    :rules="[passRules.required, passRules.lengthAndSpecialChar]"
                    ref="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    label="Password*"
                    prepend-icon="mdi-key"
                    required
                    @click:append="show1 = !show1"
                    @keyup="checkPasswordMatch"
                  ></v-text-field>
                  <v-text-field
                    v-model="newUser.confirm_pass"
                    :rules="[confirmPassRules.required, passRules.confirmMatch]"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    label="Confirm Password*"
                    prepend-icon="mdi-key"
                    required
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col>
                <v-btn :loading="_loadingSubmit" block x-large rounded :color="'success'" @click="onSubmit">
                  <span>Create Account</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>

    <v-dialog v-model="showDiaglog" persistent width="700" @click:outside="closeDialog()">
      <v-card flat style="padding: 20px 50px" align="center" justify="space-around">
        <div class="btn-dismiss-dialog">
          <v-btn text @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-flex>
          <h1 class="mt-4">Good News!</h1>
          <h3>You already have an existing account.</h3>
          <v-btn class="mt-4" width="30%" large rounded color="success" @click="toLoginPage()">
            Login
          </v-btn>
          <v-btn class="mt-4" block rounded large color="success" @click="toRecoverPage">
            Recover Information
          </v-btn>
        </v-flex>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="vendor" persistent max-width="315px">
      <v-card flat>
        <v-card-title class="headline grey lighten-2">Continue?</v-card-title>
        <v-card-text class="mt-4">
          Vendors require further registration. We can walk you through this now, or continue to your dashboard and save
          this for later?
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="toVendor(`register/vendor`)">
            Continue
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="toDash()">Save For Later</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="vendor" persistent max-width="280px">
      <v-card class="pb-2 wrapper">
        <v-card-title class="dialog-header mb-3">
          <div class="image-container">
            <img style="width: 80px; height: 80px;" :src="require('@/assets/handshake-icon.png')" alt="handshake-icon.png" />
          </div>
        </v-card-title>
        <v-card-text class="dialog-content">
          <h1>Welcome to RGX!</h1>
          <span class="content  mt-6">Let's get your account profile</span>
          <br />
          <span class="content  mt-6">completed so you can start bidding</span>
        </v-card-text>
        <v-card-actions class="dialog-actions pb-4">
          <!-- <span class="pointer underline primary--text">Skip for Now</span> -->
          <a href="/profile" class="pointer underline primary--text">
            Skip for Now
          </a>
          <v-spacer></v-spacer>
          <v-btn class="pl-6 pr-6" :color="'success'" :outlined="false" @click="toUpdateVendorOnboarding" :loading="false" rounded>
            Let's Go
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-popup-v2
      v-if="isShowPopupDuplicateUser"
      :isShow="isShowPopupDuplicateUser"
      :title="'Duplicate Account'"
      :message="messagePopupDuplicateUser"
      :isHideButtonAction="true"
      :clickOutside="
        () => {
          isShowPopupDuplicateUser = false;
        }
      "
      :maxWidth="'320'"
      :isPersistent="false"
      :closePopup="
        () => {
          isShowPopupDuplicateUser = false;
        }
      "
    />
  </v-card>
</template>
<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ConfirmPopupV2 from '@/components/common/ConfirmPopupV2.vue';
import config from '@/config';
var UsaStates = require('usa-states').UsaStates;
export default {
  directives: {
    mask
  },
  components: {
    ConfirmPopupV2
  },
  data() {
    return {
      constants: config,
      address: '',
      finderPlaceholder: null,
      addressValidated: false,
      passwordValidated: false,
      hasError: false,
      userValid: false,
      paymentTypes: ['ACH', 'Wire'],
      vendor: false,
      create: false,
      acceptTerms: false,
      username: undefined,
      disabled: true,
      checkbox: false,
      step: 1,
      openModal: false,
      lazy: false,
      valid: true,
      resend: false,
      showDiaglog: false,
      wrongConfCodeMsg: null,
      confCodeInvalid: false,
      emailExisted: false,
      acceptTermsRules: {
        required: v => !!v || 'Please agree to the Terms of Service and Privacy Policy to continue.'
      },
      emailRules: {
        required: v => !!v || 'Email is required.',
        patternMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Please enter a valid email'
      },
      show1: false,
      show2: false,
      passRules: {
        required: v => !!v || 'Password is required.',
        confirmMatch: v => this.newUser.password === this.newUser.confirm_pass || 'The passwords you entered don’t match',
        lengthAndSpecialChar: v =>
          (v && v.length >= 8 && new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(v)) ||
          'Password must be atleast 8 characters in length including one special character'
      },
      confirmPassRules: {
        required: v => !!v || 'Confirm Password is required.'
      },
      companyNameRules: {
        required: v => !!v || 'Company name is required.'
      },
      typeOfBussinessRules: {
        required: v => !!v || 'Select type of bussiness.'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      phoneRules: {
        required: v => !!v || 'Phone is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v => new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) || 'Please enter a valid zip code'
      },
      emailConfirmCodeRules: {
        required: v => !!v || 'Confirmation code is required. Can’t find the email? Check your junk mail or ‘resend code.’'
      },
      firstNameRules: {
        required: v => !!v || 'First name is required.'
      },
      lastNameRules: {
        required: v => !!v || 'Last name is required.'
      },
      userNameRules: {
        required: v => !!v || 'Last name is required.'
      },
      taxIdRules: {
        required: v => !!v || 'EIN/Tax ID Number is required.'
      },
      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v =>
          (!new RegExp(/undefined/).test(this.newCompany.street) && this.newCompany.street !== undefined) || 'Full address is required'
      },
      usStates: new UsaStates({ includeTerritories: true }).states.map(state => {
        return {
          text: state.name + ' (' + state.abbreviation + ')',
          value: state.abbreviation
        };
      }),
      isShowPopupDuplicateUser: false,
      messagePopupDuplicateUser: `<div style="text-align:left;padding-left:10px;color: black">You have an existing inactive account.</div> <div style="text-align:left;padding-left:10px;color: black">Please contact <a href='mailto:info@recyclegx.com' style="color: #007bff; text-decoration: underline;">info@recyclegx.com</a> for more information.</div>`,
      userWillCreateInActive: false
    };
  },
  computed: {
    ...mapGetters({
      _newComp: 'registration/getNewCompany',
      _newUser: 'registration/getNewUser',
      _user: 'user/getUser',
      _loading: 'registration/getLoading',
      _getEmailExisted: 'registration/getEmailExisted',
      _loadingSubmit: 'registration/getLoadingSubmit',
      _confId: 'registration/getConfId',
      _error: 'registration/getError'
    }),
    googleKey() {
      return 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE';
    },
    passValidated() {
      return this.$refs['password'] ? this.$refs['password'].validate() : false;
    },
    newUser: {
      get() {
        return this._newUser;
      },
      set(newVal) {
        this._setUser(newVal);
      }
    },
    newCompany: {
      get() {
        return this._newComp;
      },
      set(newVal) {
        this._setCompany(newVal);
      }
    },
    validated() {
      if (
        this.addressValidated &&
        this.passwordValidated &&
        this.userValid &&
        this.newCompany.accepted_terms &&
        this.newCompany.type_id &&
        this._confId
      ) {
        return false;
      }
      return true;
    }
  },
  watch: {
    _user() {
      this.$refs.addressValidated.reset();
      this.$refs.userValid.reset();
      this.$refs.passwordValidated.reset();
    },

    resend() {
      if (this.resend) {
        setTimeout(() => (this.resend = false), 5000);
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.finderPlaceholder = ' ';
    }, 2000);
  },

  methods: {
    ...mapMutations({
      _setCompany: 'registration/setCompany',
      _setUser: 'registration/setUser',
      _setConfId: 'registration/setConfirmation'
    }),
    ...mapActions({
      _register: 'registration/basicRegistration',
      _checkIfEmailExisted: 'registration/checkIfEmailExisted',
      _verifyConfirmationCode: 'registration/verifyConfirmationCode',
      _confirmEmail: 'registration/confirmEmail'
    }),
    cancel() {
      this.newUser = {};
      this.newCompany = {};
      this.$router.push('/');
    },
    toLoginPage() {
      this.$router.push('/');
    },
    toRecoverPage() {
      this.$router.push('/?recover=true');
    },
    toDash() {
      this.$router.push('/profile');
    },
    toUpdateVendorOnboarding() {
      this.$router.push('/register/vendorOnboarding?new=true');
    },
    onPlaceChanged(e) {
      this.newCompany.street = e.street_number + ' ' + e.route;
      this.newCompany.city = e.locality;
      this.newCompany.state = e.administrative_area_level_1;
      this.newCompany.zip = e.postal_code;
      this.address = '';
      if (e.street_number !== undefined) {
        this.address = this.address.concat(e.street_number);
      }
      if (e.route !== undefined) {
        this.address = this.address.concat(' ' + e.route);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.locality !== undefined) {
        this.address = this.address.concat(e.locality);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.administrative_area_level_1 !== undefined) {
        this.address = this.address.concat(e.administrative_area_level_1);
      }
      if (e.postal_code !== undefined) {
        this.address = this.address.concat(' ' + e.postal_code);
      }
      this.address = this.address.concat(', USA');
    },
    onSubmit() {
      const passwordFormValid = this.$refs.passwordValidated.validate();

      if (!passwordFormValid) {
        this._loadingSubmit = false;
        return;
      }

      this._verifyConfirmationCode()
        .then(codeIsCorrect => {
          if (passwordFormValid && codeIsCorrect) {
            debugger;
            this.confCodeInvalid = false;
            this.wrongConfCodeMsg = null;

            let showVendorDialog = false;
            if (this.newCompany.type_id === '2') {
              showVendorDialog = true;
            }

            this._register().then(res => {
              if (res && res.status === 200) {
                if (showVendorDialog) {
                  this.vendor = true;
                  return;
                }
                this.vendor = false;
                this.newUser = {};
                this.newCompany = {};
                this._setConfId(undefined);

                this.toDash();
              }
            });
          }
          //  else {
          //   this.wrongConfCodeMsg = ['This is not the correct confirmation code.'];
          //   this.confCodeInvalid = true;
          // }
        })
        .catch(error => {
          this.wrongConfCodeMsg = ['This is not the correct confirmation code.'];
          this.confCodeInvalid = true;
        });
    },
    toVendor(page) {
      this.$router.push({ path: '/' + page + '?new=true' });
    },
    validate() {
      if (this.form.validate()) {
        this.validated = true;
      }
    },
    checkEmailExisted() {
      if (this.$refs.emailValid.validate()) {
        this._checkIfEmailExisted().then(res => {
          //console.log('emailExisted', emailExisted);
          console.log(this.constants);
          if (res.emailExisted) {
            if (res.type_id != this.constants.USER_TYPE.inactive) {
              this.emailExisted = this.showDiaglog = true;
              this.userWillCreateInActive = false;
            } else {
              this.emailExisted = this.isShowPopupDuplicateUser = true;
              this.userWillCreateInActive = true;
            }
          } else {
            this.emailExisted = false;
          }
          //this.emailExisted = this.showDiaglog = emailExisted;
        });
      }
    },
    confirmEmail() {
      const userFormValid = this.$refs.userValid.validate();
      const addressFormValid = this.$refs.addressValidated.validate();

      if (this.emailExisted) {
        if (this.userWillCreateInActive) {
          this.isShowPopupDuplicateUser = true;
        } else {
          this.showDiaglog = true;
        }
        return;
      }

      if (userFormValid && addressFormValid) {
        this._confirmEmail().then(() => (this.step += 1));
      }
    },

    resendCode() {
      this._confirmEmail().then(() => (this.resend = true));
    },

    closeDialog() {
      this.showDiaglog = false;
    },

    checkPasswordMatch() {
      if (this.newUser.confirm_pass) {
        this.$refs.passwordValidated.validate();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.terms {
  overflow-y: scroll;
}

a {
  text-decoration: none;
}
.resendCode {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}
.dialog-header {
  display: flex;
  justify-content: center;
}
</style>
