var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"white--text ma-2",attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? 'calc(50% - 16px)' : 'calc(100% - 16px)',"height":_vm.$vuetify.breakpoint.mdAndUp ? '300px' : '',"justify":"center","align":"center","outlined":""}},[_c('v-row',{staticClass:"flex-wrap no-gutters"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card-subtitle',[_vm._v("My Jobs ")])],1),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left',attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card-subtitle',[_vm._v("Since: "+_vm._s(_vm.companyStart))])],1)],1),_c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"no-gutters justify-space-between",class:_vm.$vuetify.breakpoint.mdAndUp ? 'd-flex flex-row' : 'd-flex flex-column'},[_c('v-sheet',{staticClass:"ma-2 text-center",attrs:{"height":"100%","width":_vm.$vuetify.breakpoint.mdAndUp
						? 'calc((100%/3) - 16px)'
						: 'calc(100% - 16px)',"color":"#fb8c00"}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"white--text row no-gutters display-3 pa-1 pb-0 font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'headline' : '',attrs:{"justify":"center","align":"center"}},[_c('ICountUp',{attrs:{"delay":_vm.delay,"endVal":Number(_vm.active),"options":_vm.options}})],1),_c('v-divider'),_c('v-row',{staticClass:"white--text row no-gutters headline pa-1 pt-0",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : '',attrs:{"justify":"center","align":"center"}},[_vm._v(" Jobs Out For Bid ")])],1)],1),_c('v-sheet',{staticClass:"ma-2",attrs:{"height":"100%","fill-height":"","width":_vm.$vuetify.breakpoint.mdAndUp
						? 'calc((100%/3) - 16px)'
						: 'calc(100% - 16px)',"color":"#ffc107"}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"white--text row no-gutters display-3 pa-1 pb-0 font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'headline ' : '',attrs:{"justify":"center","align":"center"}},[_c('ICountUp',{attrs:{"delay":_vm.delay,"endVal":Number(_vm.awarded),"options":_vm.options}})],1),_c('v-row',{staticClass:"white--text no-gutters row headline pa-1 pt-0",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : '',attrs:{"justify":"center","align":"center"}},[_vm._v(" Jobs In Progress ")])],1)],1),_c('v-sheet',{staticClass:"ma-2 pa-0",attrs:{"height":"90%","width":_vm.$vuetify.breakpoint.mdAndUp
						? 'calc((100%/3) - 16px)'
						: 'calc(100% - 16px)',"color":"#52b513"}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"white--text row no-gutters display-3 pa-1 pb-0 font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'headline' : '',attrs:{"justify":"center","align":"center"}},[_c('ICountUp',{attrs:{"delay":_vm.delay,"endVal":Number(_vm.closed),"options":_vm.options}})],1),_c('v-row',{staticClass:"white--text row no-gutters headline pa-1 pt-0",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : '',attrs:{"justify":"center","align":"center"}},[_vm._v(" Jobs Completed ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }