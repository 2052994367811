import { getAll, get } from "../../api";
import moment from "moment";

const state = {
  customers: undefined,
  customer: undefined,
  loading: false,
  customerFee: null,
  customerFeeLoading: false,
};

// getters
const getters = {
  getCustomers(state) {
    return state.customers;
  },
  getLoading(state) {
    return state.loading;
  },

  getCustomerFeeLoading(state) {
    return state.customerFeeLoading;
  },

  getCustomer(state) {
    return state.customer;
  },

  customerFee(state) {
    return state.customerFee;
  },

  customerFeeLoading(state) {
    return state.customerFeeLoading;
  },
};

const route = "/master/customer";

// actions
const actions = {
  acquireCustomers: async ({ commit }, payload) => {
    return new Promise((reject, resolve) => {
      commit("setLoading", true);
      const constructedRoute =
        route + "?options=" + encodeURIComponent(JSON.stringify(payload));
      getAll(constructedRoute)
        .then((res) => {
          commit("setCustomers", res.data.data);
          commit("setLoading", false);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCustomer: async ({ commit }, payload) => {
    commit("setLoading", true);
    commit("setCustomer", undefined);
    try {
      return get("/company/info", payload, "")
        .then(async (res) => {
          if (res.data.data.jobs) {
            res.data.data.jobs = await res.data.data.jobs.map((item) => {
              item.start_date_text = moment(item.start_date).format(
                "MM/DD/YYYY hh:mm A"
              );
              item.end_date_text = moment(item.end_date).format(
                "MM/DD/YYYY hh:mm A"
              );
              return item;
            });
          }
          commit("setCustomer", res.data.data);
          commit("setLoading", false);
        })
        .catch((err) => {
          commit("setCustomer", undefined);
          //commit("setError", err);
          commit("setLoading", false);
        });
    } catch (err) {
      //commit("setError", err);
      commit("setLoading", false);
    }
  },

  getCustomerFee: async ({ commit }, payload) => {
    commit("setFeeLoading", true);
    commit("setCustomerFee", undefined);
    try {
      return get("/master/transaction_fee", payload, "")
        .then(async (res) => {
          if (!res.data.data.customer_fee) {
            commit("setCustomerFee", null);
          }
          commit("setCustomerFee", res.data.data.customer_fee);
          commit("setFeeLoading", false);
        })
        .catch((err) => {
          commit("setCustomerFee", undefined);
          commit("setFeeLoading", false);
        });
    } catch (err) {
      commit("setFeeLoading", false);
    }
  },
};

// mutations
const mutations = {
  setCustomers(state, payload) {
    state.customers = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setCustomer(state, payload) {
    state.customer = payload;
  },
  setCustomerFee(state, payload) {
    state.customerFee = payload;
  },

  setFeeLoading(state, payload) {
    state.customerFeeLoading = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
