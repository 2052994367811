<template>
  <v-dialog
    v-if="_job"
    v-model="_showReasonRejectDialog"
    @click:outside="closeDialog()"
    max-width="550px"
  >
	<v-card class="pb-4">
			<v-card-title class="pr-0 pl-6">
				Reject Confirmation
				<v-spacer />
				<v-btn text @click="closeDialog()">
					close
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
      	<v-card class="text-center" flat>
         <v-card-subtitle>Select the reject reasons (*)</v-card-subtitle>
      		</v-card>
			<v-card class="pa-4" flat>
				<v-item-group>
					<v-col cols="12" sm="12">
						<v-form v-model="falsies" ref="falsies">
							<v-checkbox style="margin-top: -12px;margin-bottom: -12px;"
								v-for="(q, i) in questions"
								:key="i"
								@change="selectReason"
								v-model="q.answer"
								:label="q.question"
							></v-checkbox>
						</v-form>
						<v-checkbox style="margin-top: -12px;margin-bottom: -12px;"
							v-model="final.answer"
							 label="Other"
							@change="selectReason()"
						></v-checkbox>
             <v-text-field
                @change="selectReason()"
                  label="" placeholder="Enter the other reason"
                 v-if="final.answer"
                  v-model="final.question"
                  style="margin-top: 0px"
                />
					</v-col>
				</v-item-group>
			</v-card>
      <v-card class="pa-2" flat style="margin-top: -35px;">
      	<v-item-group>
					<v-col cols="12" sm="12">
         	<v-card class="text-center" flat>
         <v-card-subtitle> You've selected the reason(s)</v-card-subtitle>
      		</v-card>
 <v-textarea v-model="reasons"
    name="content"    
    rows="2" style="overflow-y: scroll;" :readonly="true"
  ></v-textarea>
         	</v-col>	</v-item-group>
      	</v-card>
			<v-card-actions>

       <v-btn rounded color="error" @click="closeDialog()"
          >Cancel</v-btn
        >
				<v-spacer />
				<v-btn rounded :disabled="!valid"
        color="secondary"
					@click="rejectOffer()"
					>Reject</v-btn
				>
			</v-card-actions>
		</v-card>



   
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  props: {
    confirm: {
      default: true,
      type: Boolean,
    },
  },
 data() {
		return {
      reasons:'',
			falsies: false,
			questions: [
				{
					question:
						"Additional scrap proceeds not correct",
					answer: false,
				},
				{
					question:
						"Equipment purchase price not approved",
					answer: false,
				},
				{
					question:
						"Final weight looks inaccurate",
					answer: false,
				},
				{
					question:
						"COD incomplete",
					answer: false,
				},
			],
			final: {
				question: "",
				answer: false,
			}
		};
	},

  mounted() {   
     console.log("_showReasonRejectDialog: ",this._showReasonRejectDialog);
       if (this.job) {
    //   console.log("mounted this._job ok: ",this._job);
      this._setBid(this.job.bid_id);
    } else {
      setTimeout(() => {
     //   console.log("mounted this._job not ok: ",this._job);
       if (this.job)  this._setBid(this.job.bid_id);
      },1000);
    }
  },

  computed: {
    ...mapGetters({ 
       _job: "jobs/getCurrentJob",
       _bid: "bids/getCurrentBidInfo",
      _showReasonRejectDialog: "jobs/getShowReasonRejectDialog", 
    }),
    valid() {
		  return this.reasons&&this.reasons!=""
		},
    today() {
      return moment().format("YYYY-MM-DD");
    },
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      },
    },
  },
  watch: {
    _file() {
      
    },
  },
  methods: {
    ...mapMutations({
      _setShowReasonRejectDialog: "jobs/setShowReasonRejectDialog",
      _setClosingConf: "jobs/setClosingConf",
    }),

    ...mapActions({
     _setBid: "bids/getCurrentInfo",
      _sendGridMail: "mail/sendGridMail",
      _updateJob: "jobs/updateJob",
      _setJob: "jobs/setJob",
    }),

    closeDialog() {
      this._setShowReasonRejectDialog(false);
      this._setClosingConf(false);
    },
    // resetOthers() {
		// 	this.questions.forEach((i) => {
		// 		i.answer = false;
		// 	});
       
    //    if(!this.final.answer)this.reasons="";
    //    else this.reasons=this.final.question;
		// },
    selectReason() {
      var t="";
			this.questions.forEach((i) => {
			if(i.answer)t+=i.question+"\n";
     

			});
      
      if(this.final.answer)  t+=this.final.question.trim()+"\n";  
      //console.log("t",t);
       this.reasons=t.trim().replaceAll("\n",", ");
		},
    confirmClose() {
      

      this._setClosingConf(true);
    },
    rejectOffer() {
      // if(!this.reasons||this.reasons=="")
      // {
      //   console.log("Please enter the reason to contine.");
      // }
      // console.log("rejected offer");
      // return;
     // console.log("job_close_out_rejected job",this.job);
     //   console.log("job_close_out_rejected bid info",this._bid);
      let j = {};
      j.status = "rejected";
      j.bid_status = "rejected";
      j.rejected_reason =this.reasons;  
      j.id = this.job.job_id;
      this._updateJob(j).then(async (res)=>{

        j.rejected_reason = "  - "+this.reasons.split(', ').join('<br>  - ');
      if(this.final.question){
        j.rejected_reason=j.rejected_reason.indexOf('- '+this.final.question)>=0? j.rejected_reason.replace('- '+this.final.question,"- Other ("+this.final.question+")"): j.rejected_reason.replace(this.final.question,"Other ("+this.final.question+")");
      }     
      j.type="job_close_out_rejected";

    //  console.log("send job_close_out_rejected",this._bid);

      j.to_email= this._bid.poc_email;
   //   alert("aaa");
      j.job_name=this.job.name;
      j.vendor_name=this._bid.name;
 

      await this._sendGridMail(j);

      });
    // alert("aa");
     


    },
     
  },
};
</script>
<style scoped>
.wrapper {
  overflow: auto;
  padding-bottom: 20px;
}

.small-radio >>> label {
  font-size: 14px;
}

.v-card__subtitle {
  font-weight: 500 !important;
  font-size: 1.2em !important;
  padding-top: 0;
  padding-bottom: 0;
}

.small-subtitle {
  font-size: 0.8em !important;
  font-weight: 400 !important;
  padding-left: 20px;
}

@media only screen and (min-width: 960px) {
  .v-card__text {
    padding-left: 35px;
  }
}
</style>
