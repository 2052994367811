<template>
  <v-card height="calc(100% - 16px)" class="ma-2 pa-2" outlined>
    <v-row class="d-flex flex-wrap no-gutters justify-space-between">
      <v-col cols="12" sm="12" class="ma-0 ma-0 px-0 py-1">
        <v-card-title class="py-0" style="color:#4CAF50;font-weight:bold">
          RGX Job {{ job.job_id }}: {{ job.name }}
        </v-card-title>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="px-0 py-1 pb-0 mb-0">
        <v-card-subtitle class="py-0">Description</v-card-subtitle>
        <v-card-text class="py-0">
          <!-- <div> {{ job.description || 'No Description' }}&nbsp; </div> -->
          <div>
            <RenderTextArea
              :textAreaData="job.description || 'No Description'"
            ></RenderTextArea>
          </div>
        </v-card-text>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        class="ma-0 ma-0 px-0 py-1"
        v-if="
          _compType !== config.COMPANY_TYPE.VENDOR ||
            (_compType === config.COMPANY_TYPE.MASTER &&
              job.status == config.JOB_STATUS.CLOSED)
        "
      >
        <v-card-text
          class="py-0"
          v-if="_compType !== config.COMPANY_TYPE.VENDOR"
        >
          {{ 'Internal Job # ' + (job.number || 'N/A') }}
        </v-card-text>
        <v-card-subtitle
          v-if="
            _compType === config.COMPANY_TYPE.MASTER &&
              job.status == config.JOB_STATUS.CLOSED
          "
          class="py-0"
        >
          Customer Name
        </v-card-subtitle>
        <v-card-text class="py-0">
          <div
            v-if="
              _compType === config.COMPANY_TYPE.MASTER &&
                job.status == config.JOB_STATUS.CLOSED
            "
          >
            {{ job.company_name }}
          </div>
        </v-card-text>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        md="4"
        class="px-0 py-1"
        v-if="_job.status == 'awarded' || job.status == 'rejected'"
      >
        <v-card-subtitle class="py-0">
          Equipment Removal Deadline
        </v-card-subtitle>
        <v-card-text class="pb-0 py-0">
          {{ getReviewValueByValue(config.LOGISTICS_OPTIONS, _job.logistics) }}
        </v-card-text>
        <v-card-text class="pb-0 py-0">
          {{
            `${formatDate(_job.end_date, 'MM/DD/YYYY')} ${getReviewValueByValue(
              config.EXACT_REMOVE_DATE_OPTIONS,
              _job.exact_removal_date
            )}`
          }}
        </v-card-text>
      </v-col>

      <v-col
        cols="12"
        v-if="job.final_weight"
        sm="12"
        md="4"
        class="ma-0 px-0 py-1"
      >
        <v-card-subtitle
          class="py-0"
          v-if="
            !isJobStatusInList(
              [
                config.JOB_STATUS.CLOSED,
                config.JOB_STATUS.CLOSING,
                config.JOB_STATUS.RESALE
              ],
              job.status
            )
          "
        >
          Final Weight
        </v-card-subtitle>
        <v-card-subtitle
          class="py-0"
          v-if="
            isJobStatusInList(
              [
                config.JOB_STATUS.CLOSED,
                config.JOB_STATUS.CLOSING,
                config.JOB_STATUS.RESALE
              ],
              job.status
            )
          "
        >
          Final Details
        </v-card-subtitle>
        <v-card-text v-if="job.final_weight" class="py-0">
          {{ job.final_weight + ' lbs' }}
          <br />
          {{
            isJobStatusInList(
              [
                config.JOB_STATUS.CLOSED,
                config.JOB_STATUS.CLOSING,
                config.JOB_STATUS.RESALE
              ],
              job.status
            )
              ? `${
                  job.actual_pickup_date == null ? '' : job.actual_pickup_date
                } ${getReviewValueByValue(
                  config.EQUIPMENT_TRANSFER_LOGISTICS_OPTIONS,
                  job.equipment_transfer_logistics
                )}`
              : ''
          }}
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="12" md="4" class="ma-0 px-0 py-1">
        <v-card-subtitle class="py-0">Job Site Location</v-card-subtitle>
        <v-card-text class="py-0">
          {{ job.street }}{{ job.suite ? ', Suite ' + job.suite : '' }}
          <br />
          {{ job.city + ', ' + job.state + ' ' + job.zip }}
          <br />
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="12" md="4" class="ma-0 px-0 py-1">
        <v-card-subtitle class="py-0">
          Project Manager Contact
        </v-card-subtitle>
        <v-card-text class="py-0">
          <!-- <div v-show="job.project_manager_fname || job.project_manager_lname">
            {{ job.project_manager_fname }} {{ job.project_manager_lname }}
          </div> -->
          <div>
            {{ job.project_manager_fname || '' }}
            {{ job.project_manager_lname || '' }}
          </div>
          <div>{{ job.project_manager_email }}</div>

          <div>{{ _job ? job.project_manager_phone : '' }}</div>
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="4" class="ma-0 px-0 py-1">
        <!-- <v-card-subtitle
          class="py-0"
          v-if="this._compType !== config.companyType.CUSTOMER"
        >
          Job Contact
        </v-card-subtitle> -->
        <v-card-subtitle class="py-0">
          Logistics Contact
        </v-card-subtitle>
        <v-card-text class="py-0">
          <!-- <div v-show="!!job.poc_fname || !!job.poc_fname">
            {{ job.poc_fname || '' }} {{ job.poc_lname || '' }}
          </div> -->
          <div>{{ job.poc_fname || '' }} {{ job.poc_lname || '' }}</div>
          <div>{{ job.poc_email }}</div>
          <div>
            {{ _job ? job.poc_phone : '' }}
          </div>
        </v-card-text>
      </v-col>

      <v-col
        v-if="
          (job.status == config.JOB_STATUS.AWARDED ||
            job.status == config.JOB_STATUS.REJECTED ||
            job.status == config.JOB_STATUS.RESALE ||
            job.status == config.JOB_STATUS.CLOSED ||
            job.status == config.JOB_STATUS.CLOSING) &&
            (_compType === config.COMPANY_TYPE.MASTER ||
              _compType === config.COMPANY_TYPE.CUSTOMER)
        "
        cols="12"
        sm="12"
        md="4"
        class="ma-0 px-0 py-1"
      >
        <v-card-subtitle class="py-0">
          Job Creator
        </v-card-subtitle>
        <v-card-text class="py-0">
          <div v-show="job.created_full_name">
            {{ job.created_full_name || '' }}
          </div>

          <div>{{ job.created_email || '' }}</div>

          <div>{{ job.created_phone || '' }}</div>
        </v-card-text>
      </v-col>

      <v-col
        v-if="job.status == 'awarded' || job.status == 'rejected'"
        cols="12"
        sm="12"
        md="4"
        class="ma-0 px-0 py-1"
      >
        <v-card-subtitle class="py-0">
          Vendor Personnel Restrictions
        </v-card-subtitle>
        <v-card-text class="py-0">
          {{ job.vendor_restrictions || 'No Restrictions' }}
        </v-card-text>
      </v-col>

      <v-col
        v-if="job.status == 'awarded' || job.status == 'rejected'"
        cols="12"
        sm="12"
        md="4"
        class="ma-0 px-0 py-1"
      >
        <v-card-subtitle class="py-0">
          Vehicle Access Restrictions
        </v-card-subtitle>
        <v-card-text class="py-0">
          {{ job.vehicle_restrictions || 'No Restrictions' }}
        </v-card-text>
      </v-col>

      <v-col
        v-if="job.status == 'awarded' || job.status == 'rejected'"
        cols="12"
        sm="12"
        md="4"
        class="ma-0 px-0 py-1"
      >
        <v-card-subtitle class="py-0">Lift Gate Required</v-card-subtitle>
        <v-card-text class="py-0">
          {{ job.lift_gate === true ? 'Yes' : 'No' }}
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="4" class="ma-0 px-0 py-1">
        <v-card-subtitle class="py-0">Equipment</v-card-subtitle>
        <v-card-text class="py-0">
          <!--If field is null display “Est lbs. not available”-->

          {{
            job.pallets
              ? 'Est ' + job.pallets + ' pallets'
              : 'Est pallets not available'
          }}
          <br />
          {{
            job.est_weight
              ? 'Est ' + job.est_weight + ' lbs.'
              : 'Est lbs. not available'
          }}
          <br />

          <!-- If field is null display “Est pallets not available”-->

          <!-- <span style="text-transform: capitalize;">{{ job.asset_type }}</span> -->
          <span style="text-transform: capitalize;">
            Asset types: {{ getAssetTypeReview(job.asset_type) }}
          </span>
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="4" class="px-0 py-1">
        <v-card-subtitle class="py-0">Recycling Specs</v-card-subtitle>
        <v-card-text class="py-0">
          Resale:
          {{ job.recycle_specs.resale === true ? 'Yes' : 'No' }}
        </v-card-text>
        <v-card-text class="py-0">
          Certified Recycle:
          {{ job.recycle_specs.certified_recycle === true ? 'Yes' : 'No' }}
        </v-card-text>

        <v-card-text v-if="job.commodity_report" class="py-0">
          Commodity Report Required:
          {{ job.commodity_report ? 'Yes' : 'No' }}
        </v-card-text>
      </v-col>
      <!--v-col cols="12" sm="12" md="4" class="ma-0 px-0 py-1">
        <v-card-subtitle class="py-0"> Contact Phone </v-card-subtitle>
        <v-card-text class="py-0">
          {{ _job ? job.poc_phone : "" }}
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="px-0 py-1">
        <v-card-subtitle class="py-0"> Bid Duration </v-card-subtitle>
        <v-card-text class="py-0">
          {{ _job ? job.bid_duration + " days" : "" }}
        </v-card-text>
      </v-col-->

      <!--v-col cols="12" sm="12" md="12" class="px-0 py-1">
        <v-card-subtitle class="py-0">Description</v-card-subtitle>
        <v-card-text class="py-0">
         <div> {{ job.description }}&nbsp;</div>
        </v-card-text>
      </v-col-->

      <!-- <v-col
        v-if="
          (job.status == config.JOB_STATUS.RESALE ||
            job.status == config.JOB_STATUS.CLOSED) &&
            (_compType === config.COMPANY_TYPE.MASTER ||
              _compType === config.COMPANY_TYPE.CUSTOMER)
        "
        cols="12"
        sm="4"
        md="4"
        class="px-0 py-1"
      >
        <v-card-subtitle class="py-0">Job Creator</v-card-subtitle>
        <v-card-text class="py-0">
          {{ job.created_full_name || '' }}
          <br />
          {{ job.created_email || '' }}
          <br />
          {{ job.created_phone || '' }}
          <br />
        </v-card-text>
      </v-col> -->

      <!-- Vu updated 10/11/2021 -->
      <v-col v-if="_compType === 3" cols="12" sm="4" md="4" class="px-0 py-1">
        <div class="py-0">&nbsp;</div>
        <v-card-text class="py-0">
          Scrap Fee:
          {{ _job.scrap_purchase_percentage }}% / ${{ _job.scrap_per_pound }}
        </v-card-text>
        <v-card-text class="py-0">
          Equipment Fee:
          {{ _job.equipment_purchase_percentage }}%
        </v-card-text>
      </v-col>
      <!-- END -->
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import RenderTextArea from '@/components/common/RenderTextArea.vue';
import _ from 'lodash';
import config from '@/config';
import * as Helpers from '@/helpers/helpers';
export default {
  components: {
    RenderTextArea
  },
  data() {
    return {
      config: config
    };
  },
  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      _loading: 'jobs/loading',
      _compType: 'company/getCompanyType'
    }),
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    }
  },
  methods: {
    ...Helpers,
    ...mapMutations({
      _setJob: 'jobs/setJob',
      _setUser: 'user/setUser'
    })
  }
};
</script>
<style>
div.v-card__subtitle.py-0 {
  font-size: 18px;
  font-weight: bold;
}
</style>
