import config from '../config';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  methods: {
    ...mapMutations({
      _setCurrentBid: 'bids/setCurrentBid'
    }),

    addBidInformation(bid, job, companyType, sites = [], users = []) {
      const data = {};
      data.hasJobWeight = job.weight ? true : false;

      const bidPerPound = parseFloat(
        (bid.bid_per_pound || '0').replace(/,/g, '')
      );
      const bidAllPurchase = parseFloat(
        (bid.bid_all_in_purchase || '0').replace(/,/g, '')
      );
      const bidBuyOut = parseFloat((bid.bid_buy_out || '0').replace(/,/g, ''));

      if (
        bid.has_purchase_scrap &&
        bidPerPound > 0 &&
        bid.has_pickup_fee &&
        bidBuyOut > 0
      ) {
        data.scenario = 1;
      }

      if (
        bid.has_purchase_scrap &&
        bidAllPurchase > 0 &&
        bid.has_pickup_fee &&
        bidBuyOut > 0
      ) {
        data.scenario = 2;
      }

      if (bid.has_purchase_scrap && !bid.has_pickup_fee) {
        data.scenario = 3;
      }

      if (!bid.has_purchase_scrap && bid.has_pickup_fee) {
        data.scenario = 4;
      }

      if (bid.has_purchase_scrap) {
        if (bidPerPound > 0) {
          data.bidType = true;
        } else if (bidAllPurchase > 0) {
          data.bidType = false;
        }
      }

      if (companyType === config.companyType.VENDOR) {
        const selectedSite = sites.find(site => site.site_id === bid.site_id);
        const selectedUser = users.find(user => user.user_id === bid.poc_id);
        if (selectedSite) {
          data.selectedSite = selectedSite;
        }

        if (selectedUser) {
          data.selectedUser = selectedUser;
        }
      }

      return data;
    }
  }
};
