import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      miscFile: undefined,
      deletedFile: undefined,
      arrayData: [],
      attachments: [],
      jobAttachments: [],
      uploadSuccess: false,
      fileUploading: {},
      miscAttachments: [],
      fileObject: {
        file: "new",
        original_name: undefined,
        type: "",
        catagory: undefined,
      },
      addFileCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      _file: "uploads/getFile",
      _jobFile: "uploads/getJobFile",
    }),
  },
  watch: {
    _jobFile() {
      if (!this.fileUploading || !this.fileUploading.file) {
        return;
      }
      console.log("set job file", this.fileUploading);
      if (this._jobFile.file.Key) {
        this.uploadSuccess = true;
        this.jobAttachments.push({
          key: this._jobFile.file.Key,
          location: this._jobFile.file.Location,
          file_type: this.fileUploading.type,
          catagory: this.fileUploading.catagory,
          original_name:  this._jobFile.name // this.fileUploading.file.name,
        });
      }
    },
    _file() {
      if (!this.fileUploading || !this.fileUploading.file) {
        return;
      }
      if (this._file.file.Key) {
        this.uploadSuccess = true;
        this.attachments.push({
          key: this._file.file.Key,
          location: this._file.file.Location,
          file_type: this.fileUploading.type,
          catagory: this.fileUploading.catagory,
          original_name: this.fileUploading.file.name,
        });
        this.miscFile = undefined;
      }
    },
  },
  methods: {
    ...mapActions({
      _uploadFile: "uploads/uploadFile",
      _uploadJobFile: "uploads/uploadJobFile",
    }),
    addFile() {
      this.addFileCount++;
    },
    uploadLogo(e) {
      let file = {
        file: e,
        original_name: e.name,
      };
      this._uploadLogo(file);
    },
    uploadFile(e, type) {
      if (e && e.name) {
        this.fileUploading.file = e;
        this.fileUploading.catagory = type;
        this.fileUploading.type = e.type;
        let file = {
          file: e,
          original_name: e.name,
          type: type,
        };
        this._uploadFile(file);
      } else if (!e) {
      }
    },
    uploadJobFile(e, type) {
     // console.log("uploadJobFile: "+type,e);
      if( Array.isArray(e)){//upload mutiple files
        console.log("upload mutiple files");
        for(let i=0;i<e.length;i++){
          let f=e[i];
           if (f && f.name) {
            this.fileUploading.file = f;
            this.fileUploading.catagory = type;
            this.fileUploading.type = f.type;
            let file = {
              file: f,
              original_name: f.name,
              type: type,
            };
            //console.log("upload mutiple files: "+i,file);
            this._uploadJobFile(file);
          }
        }
      }else{//only upload one file
        if (e && e.name) {
          this.fileUploading.file = e;
          this.fileUploading.catagory = type;
          this.fileUploading.type = e.type;
          let file = {
            file: e,
            original_name: e.name,
            type: type,
          };
          this._uploadJobFile(file);
        } else if (!e) {

        }
     
      }
     // console.log("attachments",  this.attachments);
    },
  },
};
