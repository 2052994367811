<template>
  <v-dialog v-model="_active" @click:outside="_setActive(false)" width="1200" content-class="modal-admin-job-detail"
    v-if="_job">
    <div class="btn-dismiss-dialog">
      <v-btn text @click="_setActive(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-layout class="d-flex column" v-if="!_loading && _compType">
      <v-divider />
      <awarded-details v-if="_job.status !== 'open'" />
      <v-card v-if="_job.status === 'open' && _compType" flat>
        <div class="col py-0">
          <v-card-title class="pb-0">Open Job Details</v-card-title>
        </div>
        <div class="col py-0">
          <div class="job-desc-actions">
            <v-card-subtitle class="mx-0 py-0">
              This job is currently open for bidding. You can either place a bid
              below or save it for later.
            </v-card-subtitle>

            <div class="admin-action-title-text-admin">Admin Actions</div>
            <v-btn class="text-center d-block btn-restore">RESTORE</v-btn>
            <v-btn class="text-center d-block btn-archive mt-2 mt-md-0">
              ARCHIVE
            </v-btn>
            <v-btn @click="openEditFees" class="text-center d-block btn-restore mt-2 mt-md-0">
              EDIT FEES
            </v-btn>
          </div>
        </div>
        <div class="col py-2 text-right" style="margin-bottom: -8px;">
          <span  style="font-size: 18px;font-weight: 700;color: #5d5d5d"> Bid End Date:</span> <span style="margin: 2px 4px;"> {{formatDate(_job.bid_due_date)}}</span>
        </div>
        <div class="" :class="$vuetify.breakpoint.lgAndUp
          ? 'd-flex row no-gutters flex-nowrap'
          : 'd-flex column flex-wrap'
          ">
          <v-col lg="8" xl="8" sm="12" md="12" class="pb-0 ma-0 order-0">
            <v-card v-if="_job" outlined height="calc(100% - 16px)" class="order-0 ma-2 pa-2">
              <v-row class="no-gutters">
                <v-col cols="12" class="ma-0 px-0 py-1">
                  <v-card-title class="pb-0" style="color:#4CAF50;font-weight:bold">
                    RGX Job {{ _job.job_id }}: {{ _job.name || `no name` }}
                  </v-card-title>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Equipment Removal Deadline
                  </v-card-subtitle>
                  <v-card-text class="pb-0 py-0">
                    {{
                      getReviewValueByValue(
                        config.LOGISTICS_OPTIONS,
                        _job.logistics
                      )
                    }}
                  </v-card-text>
                  <v-card-text class="pb-0 py-0">
                    {{
                      `${formatDate(_job.end_date)} ${getReviewValueByValue(
                        config.EXACT_REMOVE_DATE_OPTIONS,
                        _job.exact_removal_date
                      )}`
                    }}
                  </v-card-text>
                  <!-- <v-card-text class="pb-0">
                    {{ formatDate(_job.end_date) }}
                  </v-card-text> -->
                </v-col>
                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">Location</v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ `${_job.location} ${_job.zip}` }}
                  </v-card-text>
                  <!-- <v-card-text class="pb-0">{{ _job.location }}</v-card-text> -->
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Internal Job ID
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.number || 'N/A' }}
                  </v-card-text>
                </v-col> -->

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <!-- <v-card-subtitle class="pb-0 t1">
                    Bid Due Date
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.bid_due_date }}
                  </v-card-text> -->
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">Description</v-card-subtitle>
                  <v-card-text class="pb-0">
                    <!-- {{ _job.description || "No Description" }} -->
                    <RenderTextArea :textAreaData="_job.description || 'No Description'"></RenderTextArea>
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Vendor Personnel Restrictions
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.vendor_restrictions || 'No Restrictions' }}
                  </v-card-text>
                </v-col>

                <v-col v-if="true" cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Equipment
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    <!--If field is null display “Est lbs. not available”-->

                    {{
                      _job.pallets
                        ? 'Est ' + _job.pallets + ' pallets'
                        : 'Est pallets not available'
                    }}
                    <br />
                    {{
                      _job.est_weight
                        ? 'Est ' + _job.est_weight + ' lbs.'
                        : 'Est lbs. not available'
                    }}
                    <br />

                    <span style="text-transform: capitalize;">
                      Asset types: {{ getAssetTypeReview(_job.asset_type) }}
                    </span>
                  </v-card-text>
                </v-col>

                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Estimated Weight
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.weight || 'Not Available' }}
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Number of Pallets
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.pallets || 'Not Available' }}
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Asset Types
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ `${getAssetTypeReview(_job.asset_type)}` }}
                  </v-card-text>
                </v-col> -->

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Vehicle Access Restrictions
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.vehicle_restrictions || 'No Restrictions' }}
                  </v-card-text>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1" v-if="_job.recycle_specs">
                  <v-card-subtitle class="pb-0 t1">
                    Recycling Specs
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    Equipment Available for Resale:
                    {{
                      _job.recycle_specs && _job.recycle_specs.resale === true
                        ? 'Yes'
                        : 'No'
                    }}
                  </v-card-text>
                  <v-card-text v-if="_job.recycle_specs" class="py-0">
                    Vendor Certification Required:
                    {{
                      _job.recycle_specs.certified_recycle === true
                        ? 'Yes'
                        : 'No'
                    }}
                  </v-card-text>
                  <v-card-text v-if="_job.commodity_report === true" class="py-0">
                    Commodity Report Required:
                    {{ _job.commodity_report === true ? 'Yes' : 'No' }}
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Lift Gate Required
                  </v-card-subtitle>
                  <v-card-text class="pb-0">
                    {{ _job.lift_gate === true ? 'Yes' : 'No' }}
                  </v-card-text>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">Attachments</v-card-subtitle>
                  <!-- <div
                    v-for="(attachment, index) in _job.attachments"
                    :key="index"
                    class="px-2 pb-0"
                  >
                    <p class="attachment-indent mb-0 pl-4">
                      <a :href="attachment.location">
                        <v-icon v-if="attachment.location" class="small">
                          mdi-paperclip
                        </v-icon>
                      </a>
                      <a>
                        <span
                          class="link-color"
                          v-on:click="showGoogleDocIndex(index)"
                        >
                          {{ attachment.original_name }}
                        </span>
                      </a>

                      <v-btn icon v-on:click="showGoogleDocIndex(index)">
                        <v-icon color="secondary">mdi-file-find</v-icon>
                      </v-btn>
                    </p>
                  </div> -->
                  <v-list-item-group>
                    <v-list-item v-for="(att, index) in _job.attachments" :key="index">
                      <v-list-item-icon>
                        <v-icon color="secondary" v-if="att.location">
                          mdi-paperclip
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-on:click="showGoogleDocIndex(index)">
                          {{ att.original_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ att.catagory.replace(/_/g, ' ') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row no-gutters>
                          <v-btn icon :href="att.location" :download="att.original_name">
                            <v-icon color="secondary">mdi-download</v-icon>
                          </v-btn>
                          <v-btn icon v-on:click="showGoogleDocIndex(index)">
                            <v-icon color="secondary">mdi-file-find</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="false">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-paperclip</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-on:click="exportToPDF()">
                          RGX Pickup Receipt Job {{ _job.job_id }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          pickup receipt
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row no-gutters>
                          <v-btn icon v-on:click="exportToPDF()">
                            <v-icon color="secondary">mdi-download</v-icon>
                          </v-btn>
                          <v-btn icon v-on:click="exportToPDF()">
                            <v-icon color="secondary">mdi-file-find</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-subtitle class="pb-0 t1">
                    Equipment
                  </v-card-subtitle>
                  <v-card-text class="py-0">
                    {{
                      _job.est_weight
                        ? 'Est ' + _job.est_weight + ' lbs.'
                        : 'Est lbs. not available'
                    }}
                  </v-card-text>
                  <v-card-text class="py-0">
                    {{
                      _job.pallets
                        ? 'Est ' + _job.pallets + ' pallets'
                        : 'Est pallets not available'
                    }}
                  </v-card-text>
                  <v-card-text class="py-0">
                    {{ `Asset types: ${getAssetTypeReview(_job.asset_type)}` }}
                  </v-card-text>
                </v-col> -->
                <v-col cols="12" sm="6" md="4" class="ma-0 px-0 py-1">
                  <v-card-text class="pb-0">
                    Scrap Fee:
                    {{ _job.scrap_purchase_percentage }}% / ${{
                      _job.scrap_per_pound
                    }}
                  </v-card-text>

                  <v-card-text class="pb-0">
                    Equipment Fee:
                    {{ _job.equipment_purchase_percentage }}%
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col lg="4" xl="4" sm="12" md="12" class="pb-0 ma-0 order-0">
            <v-card v-if="_job" outlined height="calc(100% - 16px)" class="order-0 ma-2 pa-2">
              <v-row class="no-gutters pb-4">
                <v-col>
                  <v-card-subtitle class="pb-0 t1" style="text-align:center">
                    Internal Job Details
                  </v-card-subtitle>
                  <v-card-subtitle class="mx-0 text-center mt-0 pt-0">
                    Not visible to vendors bidding on the job
                  </v-card-subtitle>
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12" class="pb-6">
                  <v-card-subtitle class="subtitle-1 ml-0">
                    Job submitted by: {{ _job.created_email }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    Internal Job ID: {{ _job.number || 'N/A' }}
                  </v-card-subtitle>
                </v-col>

                <v-col cols="12" class="pb-6">
                  <v-card-subtitle class="pb-2 t1">
                    Project Manager Contact
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.project_manager_fname }}
                    {{ _job.project_manager_lname }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.project_manager_email }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.project_manager_phone }}
                  </v-card-subtitle>
                </v-col>

                <v-col cols="12" class="pb-6">
                  <v-card-subtitle class="pb-2 t1 ">
                    Logistics Contact
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.poc_fname }}
                    {{ _job.poc_lname }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.poc_email }}
                  </v-card-subtitle>
                  <v-card-subtitle class="subtitle-1 ml-0">
                    {{ _job.poc_phone }}
                  </v-card-subtitle>
                </v-col>

                <v-col cols="12" v-if="_job.vendorsAssignedInfo && _job.vendorsAssignedInfo.length > 0">
                  <v-card-subtitle class="pb-2 t1">
                    Vendor Identified
                  </v-card-subtitle>
                  <div v-for="vendor in _job.vendorsAssignedInfo" :key="vendor.id">
                    <v-card-subtitle class="subtitle-1 ml-0">
                      {{ vendor.id }} - {{ vendor.name }}
                    </v-card-subtitle>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </div>
        <v-card class="ma-2 mb-4 pa-2" outlined>
          <bid-table />
        </v-card>
      </v-card>
    </v-layout>
    <div v-else-if="_loading" class="ma-5">
      <skeleton-detail />
    </div>
    <dispute-confirm />
    <close-confirm />
    <bid-confirm />
    <bid-review />
    <job-closing-conf />

    <v-dialog v-model="editing" @click:outside="editing = false" max-width="400">
      <v-card v-if="editingJobFee">
        <div class="btn-dismiss-dialog">
          <v-btn text @click="editing = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title>Edit Job Fee</v-card-title>
        <v-card-text>
          <v-form v-model="editFormValid">
            <v-row no-gutters style="width: 80%; align-items: center" justify="center">
              <v-col cols="6">
                <v-card-subtitle>Scrap Fee</v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-text-field type="money" v-money="editingJobFee.scrap_purchase_percentage"
                  v-money.lazy="editingJobFee.scrap_purchase_percentage"
                  v-model="editingJobFee.scrap_purchase_percentage" :disabled="!editing" :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber
                  ]" append-outer-icon="%"></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-card-subtitle>Scrap Per Pound</v-card-subtitle>
              </v-col>

              <v-col cols="6">
                <v-text-field :disabled="!editing" type="money" append-outer-icon="mdi-currency-usd"
                  v-model="editingJobFee.scrap_per_pound" v-model.lazy="editingJobFee.scrap_per_pound"
                  v-money="editingJobFee.scrap_per_pound" :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber
                  ]"></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-card-subtitle>Equipment Fee</v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-text-field v-money.lazy="editingJobFee.equipment_purchase_percentage"
                  v-money="editingJobFee.equipment_purchase_percentage"
                  v-model="editingJobFee.equipment_purchase_percentage" :disabled="!editing" type="money"
                  append-outer-icon="%" :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber
                  ]"></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-space-between">
                  <v-btn color="error" rounded @click="cancelEditCustomerFee" class="ml-2 btn-company-info-archive">
                    Cancel
                  </v-btn>
                  <v-btn color="success" rounded :loading="feeLoading" :disabled="!editFormValid"
                    @click="updateCustomerFee" class="ml-2 btn-company-info-restore">
                    Save
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <google-doc />
  </v-dialog>
</template>
<script>
//import Info from "@/components/app/Info";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import SkeletonDetail from '@/components/skeleton/Detail.vue';
import BidTable from '@/components/bids/Table.vue';
import JobClosingConf from '@/components/jobs/JobClosingConf.vue';
import BidReview from '@/components/bids/Review.vue';
import BidConfirm from '@/components/bids/BidConfirm.vue';
import DisputeConfirm from '@/components/jobs/DisputeConfirm.vue';
import CloseConfirm from '@/components/jobs/CloseConfirm.vue';
import AwardedDetails from '@/components/jobs/AwardedDetails.vue';
import GoogleDoc from '@/components/app/GoogleDoc.vue';
import RenderTextArea from '@/components/common/RenderTextArea.vue';
import config from '@/config';
import * as Helpers from '@/helpers/helpers';
export default {
  components: {
    GoogleDoc,
    JobClosingConf,
    BidTable,
    BidReview,
    //JobClosing,
    BidConfirm,
    DisputeConfirm,
    CloseConfirm,
    SkeletonDetail,
    AwardedDetails,
    RenderTextArea
  },
  data() {
    return {
      editFormValid: false,
      editing: false,
      feeLoading: false,
      editingJobFee: {},
      bidJob: false,
      rules: {
        required: v => !!v || 'Required.',
        minFee: v => {
          return (
            parseFloat(v ? (v + '').replace(/,/g, '') : 0.1) >= 0.0 ||
            'Invalid Fee.'
          );
        },
        maxFee: v =>
          parseFloat(v ? v.replace(/,/g, '') : 0.1) <= 100.0 || 'Invalid Fee.',

        negativeNumber: v => !new RegExp(/-/g).test(v) || 'Invalid Fee'
      },
      config: config
    };
  },
  methods: {
    ...Helpers,
    ...mapMutations({
      _setActive: 'jobs/setAdminJobDetails',
      _setGoogleDoc: 'googledoc/setShowGoogleDocDialog',
      _setIndex: 'googledoc/setIndex',
      _setList: 'googledoc/setList'
    }),
    ...mapActions({
      _setJob: 'jobs/setCurrent',
      _updateJob: 'jobs/updateJob'
    }),
    mounted() {
      setTimeout(() => {
        console.log('setTimeout setjob');
        // this._setJob();
        this.editingJobFee = {};
        this.editingJobFee.scrap_purchase_percentage = this._job.scrap_purchase_percentage;
        this.editingJobFee.scrap_per_pound = this._job.scrap_per_pound;
        this.editingJobFee.equipment_purchase_percentage = this._job.equipment_purchase_percentage;
      }, 2000);
    },
    showGoogleDocIndex(index) {
      //  console.log("showGoogleDocIndex: "+index,this._bid.attachments);
      this._setList(this._job.attachments);
      this._setIndex(index);
      this._setGoogleDoc(true);
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    cancelEditCustomerFee() {
      this.editing = false;
      this.editingJobFee = {};
      this.editingJobFee.scrap_purchase_percentage = this._job.scrap_purchase_percentage;
      this.editingJobFee.scrap_per_pound = this._job.scrap_per_pound;
      this.editingJobFee.equipment_purchase_percentage = this._job.equipment_purchase_percentage;
    },
    openEditFees() {
      this.editing = true;
      //console.log("openEditFees this._job",this._job);
      if (this._job) {
        console.log('openEditFees this._job ' + this._job.job_id, this._job);
        console.log(
          'this._job.scrap_purchase_percentage ' +
          this._job.scrap_purchase_percentage
        );
        console.log('this._job.scrap_per_pound ' + this._job.scrap_per_pound);
        console.log(
          'this._job.equipment_purchase_percentage ' +
          this._job.equipment_purchase_percentage
        );

        console.log('11 this.editingJobFee', this.editingJobFee);
        //  this.editingJobFee = {
        this.editingJobFee = {};
        this.editingJobFee.scrap_purchase_percentage = this._job.scrap_purchase_percentage;
        this.editingJobFee.scrap_per_pound = this._job.scrap_per_pound;
        this.editingJobFee.equipment_purchase_percentage = this._job.equipment_purchase_percentage;
        setTimeout(() => {
          console.log('setTimeout editingJobFee');
          this.editingJobFee = {};
          this.editingJobFee.scrap_purchase_percentage = this._job.scrap_purchase_percentage;
          this.editingJobFee.scrap_per_pound = this._job.scrap_per_pound;
          this.editingJobFee.equipment_purchase_percentage = this._job.equipment_purchase_percentage;

          console.log('22 this.editingJobFee', this.editingJobFee);
        }, 1000);
      } else {
      }
    },
    updateCustomerFee() {
      this.feeLoading = true;
      this._updateJob({
        id: this._job.job_id,
        disableLoading: true,
        ...this.editingJobFee
      }).then(() => {
        this.feeLoading = false;
        this.editing = false;
      });
    }
  },

  computed: {
    ...mapGetters({
      _active: 'jobs/getAdminJobDetails',
      _job: 'jobs/getCurrentJob',
      _compType: 'company/getCompanyType',
      _loading: 'jobs/loading',
      _bid: 'bids/bid',
      _bidLoading: 'bids/getLoading',
      _newBid: 'bids/getNewBid',
      _bidConfirm: 'bids/confirm'
    })
  }
};
</script>
<style>
.modal-admin-job-detail {
  background-color: #ffffff !important;
  position: relative;
}

.md-justify-content-end {
  justify-content: flex-end;
}

.admin-action-title-text-admin {
  font-size: 0.875rem;
  font-weight: bold;
  display: flex;
  margin-right: 20px;
}

.btn-archive,
.btn-restore {
  font-size: 0.875rem !important;
  padding: 5px 20px;
  box-shadow: none;
  margin-right: 20px;
  color: #fff !important;
}

.btn-archive {
  border-radius: 30px;
  background: #6c757d !important;
}

.btn-restore {
  border-radius: 30px;
  background: rgb(0, 162, 8) !important;
}

.job-desc-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .md-justify-content-end {
    justify-content: center;
  }

  .job-desc-actions,
  .admin-actions-content {
    display: block !important;
  }

  .admin-action-title-text-admin {
    margin: 10px 0;
    justify-content: center;
    font-size: 1rem;
  }
}

.btn-dismiss-dialog {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9;
  width: auto;
}

.mx-0 {
  font-size: 14px !important;
  font-weight: normal !important;
}

div.v-card__subtitle.t1 {
  font-size: 18px;
  font-weight: bold;
}
</style>


<style scoped>
.subtitle-1 {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500 !important;
  line-height: 1.5rem;
}
</style>
