<template>
  <v-row class="mt-2 flex-nowrap" style="max-width: 100%" no-gutters>
    <v-sheet max-width="75%">
      <v-slide-group
        v-model="vendorTabFilter"
        :loading="_loading"
        mandatory
        show-arrows
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-slide-item
          v-for="(i, k) in adminTabs"
          :key="k"
          :value="i.value"
          v-slot:default="{ active, toggle }"
          active-class="primary disable-btn"
        >
          <v-btn
            :rounded="active"
            :text="!active"
            height="50"
            class="elevation-0 my-1 mr-4"
            @click="toggle"
            >{{ i.text }}</v-btn
          >
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      class="white--text my-2"
      fab
      absolute
      right
      top
      color="secondary"
      @click="_setJobPre(true)"
    >
      <v-icon>mdi-briefcase-plus</v-icon>
    </v-btn>
    <v-select
      filled
      class="elevation-0 primary ma-2 mt-5 mb-2"
      hide-details
      rounded
      dark
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="vendorTabFilter"
      :items="adminTabs"
    />
  </v-row>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Options from "@/mixins/Options";
export default {
  mixins: [Options],
  data() {
    return {
      adminTabs: [
        { value: "company", text: "Company List" },
        { value: "site", text: "Site List" },
      ],
    };
  },
  emits: ["switchTab"],
  computed: {
    vendorTabFilter: {
      get() {
        return localStorage.getItem("vendorTab");
      },
      set(newVal) {
        this.$emit("switchTab", newVal);
        localStorage.setItem("vendorTab", newVal);
      },
    },
  },
  methods: {
    ...mapActions({
      _getCompanies: "company/getAll",
      _getSites: "sites/getAll",
    }),
  },
  mounted() {
    if (localStorage.getItem("vendorTab")) {
      this.vendorTabFilter = localStorage.getItem("vendorTab");
    } else {
      localStorage.setItem("vendorTab", this.vendorTabFilter);
    }
  },
};
</script>
<style scoped>
.disable-btn {
  pointer-events: none;
}
</style>
