<template>
  <v-card flat class="">
    <v-container fluid fill-height>
      <v-row
        :class="
          $vuetify.breakpoint.mdAndUp
            ? 'd-flex flex-wrap flex-row no-gutters justify-space-between'
            : 'd-flex flex-wrap flex-column no-gutters justify-space-between'
        "
        :sort="true"
        justify="center"
        align="start"
        v-model="daschboardArray"
        draggable=".item'"
      >
        <single-data-card color="#fb8c00" icon="mdi-clipboard-list-outline" title="Available Jobs" :number="Number(availableJobs)" />
        <single-data-card title="Average $/lb Offer" :number="Number(perPound)" :dollar="true" color="#32c5ff" icon="mdi-scale-balance" />
        <tri-data-cards :active="_activeBids" :awarded="_awardedBids" :closed="_closedJobs" class="item" />
        <bar-chart class="item" />
        <bid-line-chart class="item" />
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import BarChart from '@/components/dashboard/graph/vendor/BarChart.vue';
import BidLineChart from '@/components/dashboard/graph/vendor/BidLineChart.vue';
import TriDataCards from '@/components/dashboard/graph/vendor/TriDataCards.vue';
import SingleDataCard from '@/components/dashboard/graph/vendor/SingleDataCard.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    BarChart,
    TriDataCards,
    SingleDataCard,
    BidLineChart
  },
  data() {
    return {
      daschboardArray: []
    };
  },
  mounted() {
    if (this._company) {
      this.getData();
    } else {
      setTimeout(() => {
        this.getData();
      }, 1000);
    }
  },
  methods: {
    ...mapActions({
      _getAvBids: 'dashboard/getVendorAvBids',
      _getAvailableJobs: 'dashboard/getVendorAvailableJobs',
      _getAwardedBids: 'dashboard/getVendorAwardedBids',
      _getActiveBids: 'dashboard/getVendorActiveBids',
      _getClosed: 'dashboard/getVendorClosed',
      _getPPM: 'dashboard/getVendorPPM',
      _getBPM: 'dashboard/getVendorBPM'
    }),
    getData() {
      this._getAvBids();
      this._getAvailableJobs();
      this._getActiveBids();
      this._getAwardedBids();
      this._getClosed();
      this._getPPM();
      this._getBPM();

      const myInterval = setInterval(() => {
        if (window.location.href.indexOf('?401') > 0) {
          clearInterval(myInterval);
          return;
        }
        this._getAvBids();
        this._getAvailableJobs();
        this._getActiveBids();
        this._getAwardedBids();
        this._getClosed();
        this._getPPM();
        this._getBPM();
      }, 300000);
    }
  },
  watch: {
    daschboardArray() {}
  },
  computed: {
    ...mapGetters({
      _activeBids: 'dashboard/activeBids',
      _awardedBids: 'dashboard/awardedBids',
      _availableJobs: 'dashboard/availableJobs',
      _closedJobs: 'dashboard/closed',
      _averageBids: 'dashboard/avBids',
      _company: 'company/getCompany',
      _loading: 'dashboard/loading'
    }),
    availableJobs() {
      if (this._availableJobs) {
        return this._availableJobs[0].count;
      } else {
        return 0;
      }
    },
    perPound() {
      if (this._averageBids) {
        return Number(this._averageBids.average_per_pound).toFixed(2);
      } else {
        return '';
      }
    }
  }
};
</script>
