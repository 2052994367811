<template>
  <v-card outlined class="ma-2 pa-4">
    <v-card-title class="pa-1">Job Close Out</v-card-title>
    <v-form ref="jobCloseOutForm" v-model="finalValid">
      <v-card-text class="pb-0" style="font-weight: 500">
        Scrap Purchase Details *
      </v-card-text>

      <v-text-field
        class="pl-8 mr-12 pr-12"
        label="Scrap Final Weight (lbs) *"
        v-model="job.final_weight"
        maxlength="9"
        ref="final_weight"
        v-on:keypress="isNumber($event)"
        :rules="[rules.required, rules.positiveNumber]"
        @blur="updateDataCloseOutForm('final_weight')"
        prepend-icon="mdi-weight-pound"
      ></v-text-field>

      <v-text-field
        class="pl-8 mr-12 pr-12 pb-0"
        type="money"
        maxlength="15"
        label="Additional Scrap Proceeds"
        ref="additional_scrap_proceeds"
        prepend-icon="mdi-currency-usd"
        v-model="job.additional_scrap_proceeds"
        v-model.lazy="job.additional_scrap_proceeds"
        v-money="job.additional_scrap_proceeds"
        :rules="[rules.negativeMoneyBid, rules.minMoney, rules.max]"
        @blur="updateDataCloseOutForm('additional_scrap_proceeds')"
      ></v-text-field>

      <div v-if="job.recycle_specs.resale">
        <v-card-text class="pb-0" style="font-weight: 500">
          Are you purchasing equipment? *
        </v-card-text>

        <v-radio-group
          @change="updateDataCloseOutForm('has_purchase_equipment')"
          class="pl-8"
          row
          v-model="job.has_purchase_equipment"
          :rules="[rules.radioRequired]"
          ref="has_purchase_equipment"
        >
          <v-radio class="small-radio" :value="true" label="Yes"></v-radio>
          <v-radio class="small-radio" :value="false" label="No"></v-radio>
        </v-radio-group>
      </div>

      <v-card-text class="pb-0 pt-0">
        <div v-if="job.has_purchase_equipment">
          <v-text-field
            type="money"
            maxlength="15"
            label="Purchase Offer *"
            prepend-icon="mdi-currency-usd"
            v-model="job.resale_offer"
            v-model.lazy="job.resale_offer"
            v-money="job.resale_offer"
            :rules="[
              rules.required,
              rules.negativeMoneyBid,
              rules.minMoney,
              rules.max
            ]"
            @change="updateDataCloseOutForm('resale_offer')"
          ></v-text-field>

          <v-file-input
            @change="uploadFile($event, 'purchase_inventory')"
            outlined
            dense
            label="Inventory of Equipment to Purchase *"
            :rules="
              !checkFileExistsByCategory('purchase_inventory') &&
              job.has_purchase_equipment
                ? [rules.required]
                : []
            "
            :value="currentPurchaseInventoryFile"
            class="mt-2"
          ></v-file-input>
        </div>
      </v-card-text>

      <v-col class="pl-0 ml-0 pt-0 pb-0" v-if="job.bid_buy_out">
        <v-card-text class="pb-0" style="font-weight: 500">
          Customer service fee*
        </v-card-text>
        <v-card-subtitle class="small-subtitle pt-0 pb-0">
          Fee can be reduced, not increased
        </v-card-subtitle>

        <v-card-text class="pl-8 pt-0 pb-0">
          <v-row no-gutters align="center">
            <v-col cols="6" md="3">
              <v-text-field
                type="money"
                :disabled="customerFeeDisabled"
                v-model="job.current_bid_buy_out"
                v-model.lazy="job.current_bid_buy_out"
                v-money="job.current_bid_buy_out"
                prepend-icon="mdi-currency-usd"
                :rules="[
                  rules.maxFee(job.bid_buy_out, job.current_bid_buy_out),
                  rules.required,
                  rules.negativeMoneyBid
                ]"
                @blur="updateDataCloseOutForm('current_bid_buy_out')"
                ref="current_bid_buy_out"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn
                icon
                color="secondary"
                class="elevation-0"
                rounded
                @click="customerFeeDisabled = !customerFeeDisabled"
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>

      <v-card-text class="pb-0" style="font-weight: 500">
        Equipment Transfer*
      </v-card-text>

      <v-radio-group
        @change="updateDataCloseOutForm('equipment_transfer_logistics')"
        class="pl-8"
        row
        v-model="job.equipment_transfer_logistics"
        :rules="[rules.required]"
        ref="equipment_transfer_logistics"
      >
        <v-radio
          class="small-radio"
          :value="config.EQUIPMENT_TRANSFER_LOGISTICS_OPTIONS.PICKUP.value"
          label="Pickup"
        ></v-radio>
        <v-radio
          class="small-radio"
          :value="config.EQUIPMENT_TRANSFER_LOGISTICS_OPTIONS.DROPOFF.value"
          label="Drop-off"
        ></v-radio>
      </v-radio-group>

      <v-menu
        ref="menu"
        v-model="pickUpMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            class="pl-8 mr-12 pr-12"
            v-model="job.actual_pickup_date"
            :rules="[rules.required, rules.isValidDate]"
            label="Equipment Transfer Date*"
            prepend-icon="mdi-calendar-blank"
            v-on="on"
            ref="actual_pickup_date"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="job.actual_pickup_date"
          no-title
          @change="updateDataCloseOutForm('actual_pickup_date')"
          @input="pickUpMenu = false"
        ></v-date-picker>
      </v-menu>

      <v-menu
        ref="menu"
        v-model="destructMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            class="pl-8 mr-12 pr-12"
            v-model="job.destruction_date"
            label="Destruction Date*"
            :rules="[rules.required, rules.isValidDate]"
            ref="destruction_date"
            prepend-icon="mdi-calendar-blank"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="job.destruction_date"
          :max="today"
          no-title
          @change="updateDataCloseOutForm('destruction_date')"
          @input="destructMenu = false"
        ></v-date-picker>
      </v-menu>

      <v-card
        flat
        class="mt-2 mb-6"
        v-if="job.commodity_report && !!job.commodity_report_link"
      >
        <v-row no-gutters>
          <v-icon class="mr-2">mdi-link</v-icon>
          <a
            :style="{
              color:
                this.clickedCompleteCommodityReport ||
                !this.startedCheckCompleteCommodityReport
                  ? 'green'
                  : 'red',
              fontSize: '16px',
              textDecoration: 'underline'
            }"
            @click="
              handleCommodityReportPopup(true), clickOnCompleteCommodityReport()
            "
          >
            Complete Commodity Report*
          </a>
        </v-row>
        <v-row
          no-gutters
          v-if="
            !this.clickedCompleteCommodityReport &&
              this.startedCheckCompleteCommodityReport
          "
        >
          <p
            class="ml-8"
            :style="{
              color: 'red',
              fontSize: '14px'
            }"
          >
            Report required to close.
          </p>
        </v-row>
      </v-card>

      <v-file-input
        class="mt-2 mr-12"
        @change="uploadFile($event, 'cod')"
        dense
        outlined
        ref="misc"
        @focus="codFocused = !codFocused"
        @blur="codFocused = !codFocused"
        :loading="codFocused ? _uploading : false"
        label="Certificate of Destruction Upload *"
        :rules="!checkFileExistsByCategory('cod') ? [rules.required] : []"
        :value="currentCodFile"
      ></v-file-input>

      <v-file-input
        v-if="!(job.commodity_report && job.commodity_report_link)"
        outlined
        dense
        :clearable="true"
        class="mt-2 mr-12"
        :label="
          job.commodity_report && !job.commodity_report_link
            ? 'Commodity Report*'
            : 'Commodity Report'
        "
        @change="uploadFile($event, 'commodity_report')"
        @click:clear="() => {}"
        :rules="
          job.commodity_report &&
          !job.commodity_report_link &&
          !checkFileExistsByCategory('commodity_report')
            ? [rules.required]
            : []
        "
        :value="currentCommodityReportFile"
      ></v-file-input>

      <v-file-input
        outlined
        dense
        :clearable="true"
        class="mt-2 mr-12"
        label="Miscellaneous Document"
        @change="uploadFile($event, 'miscellaneous')"
        @click:clear="() => {}"
        :value="currentMiscellaneousFile"
      ></v-file-input>

      <div v-if="job.attachments && job.attachments.length > 0">
        <v-card-text class="pb-0" style="font-weight: 500">
          Uploaded Files
        </v-card-text>
        <v-list-item
          class="mx-0 px-0"
          v-for="(i, k) in job.attachments.filter(
            w =>
              w.catagory == 'purchase_inventory' ||
              w.catagory == 'cod' ||
              w.catagory == 'commodity_report' ||
              w.catagory == 'miscellaneous'
          )"
          :key="k"
        >
          <v-list-item-icon>
            <v-icon color="secondary">mdi-paperclip</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ i.original_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ i.catagory.replace(/_/g, ' ') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-row cols="12">
        <v-col class="text-right mr-12">
          <v-btn color="secondary" @click="confirmClose">Review Final</v-btn>
        </v-col>
      </v-row>

      <!-- &nbsp;
      <v-btn
        id="btnCloseJobFake"
        rounded
        elevation="0"
        color="secondary"
        @click="openCloseOutDialog()"
      >
        Close Job
      </v-btn> -->
    </v-form>
    <PopupWithUrl
      :isVisible="isPopupVisible"
      :contentUrl="job.commodity_report_link"
      @update:isVisible="handleCommodityReportPopup"
    />
  </v-card>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import config from '@/config';
import _ from 'lodash';
import * as Helpers from '@/helpers/helpers';
import PopupWithUrl from '../common/PopupWithUrl.vue';

export default {
  components: {
    PopupWithUrl
  },
  data() {
    return {
      config: config,
      pickUpMenu: false,
      destructMenu: false,
      finalValid: false,
      rules: {
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        required: v => !!v || 'Required.',
        confirmMatch: v =>
          this.newUser.password === this.newUser.confirm_pass ||
          'The passwords you entered don’t match',
        length: v =>
          (v && v.length >= 8) ||
          'Passwords must be at least 8 characters in length',
        time: v =>
          moment(this.newJob.start_date).isBefore(
            moment(this.newJob.end_date)
          ) || 'Equipment due date must be later than bid due date',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(
            v
          ) || 'Passwords require at least one special character',
        positiveNumber: v => Math.sign(v) == 1 || 'Must be a positive number.',
        radioRequired: v => v !== null || 'Field is required to close.',
        maxFee(max, v) {
          return (
            parseFloat(v ? v.replace(/,/g, '') : 0.1) <=
              parseFloat(max ? max.replace(/,/g, '') : 0.1) ||
            `Value must be less than ${max}`
          );
        },
        codFileUploadRequired: v => !!v || 'Field is required to close.',
        jobCloseOutFileUploadRequired: v =>
          !!v || 'Field is required to close.',
        negativeMoneyBid: v =>
          !new RegExp(/-/g).test(v) || 'Must be a positive amount.',
        minMoney: v => {
          return (
            parseFloat(v ? (v + '').replace(/,/g, '') : 0.1) >= 0.0 ||
            'Must be a positive amount.'
          );
        },
        min: v => {
          return (
            parseFloat(v ? (v + '').replace(/,/g, '') : 0.1) > 0.0 ||
            'Must be a positive number.'
          );
        },
        max: v =>
          parseFloat(v ? v.replace(/,/g, '') : 0.1) <= 1000000000000.0 ||
          'Can not exceed $1000000000000',
        isValidDate: v => moment(v).isValid() || 'Invalid Date.'
      },
      customerFeeDisabled: true,
      isCloseOutJobFormValid: false,
      fileUploading: {},
      codFocused: false,
      currentCodFile: null,
      currentPurchaseInventoryFile: null,
      currentCommodityReportFile: null,
      currentMiscellaneousFile: null,
      clickedCompleteCommodityReport: false,
      startedCheckCompleteCommodityReport: false,
      isPopupVisible: false
    };
  },
  mounted() {
    if (this.job && this.job.destruction_date) {
      // this.job.destruction_date = this.job.destruction_date.substr(0, 10);
      this.job.destruction_date = moment(this.job.destruction_date).format(
        'YYYY-MM-DD'
      );
    }

    if (this.job && this.job.actual_pickup_date) {
      this.job.actual_pickup_date = moment(this.job.actual_pickup_date).format(
        'YYYY-MM-DD'
      );
    }

    // if (this.job && this.job.destruction_date) {
    //   this.job.destruction_date = this.job.destruction_date.substr(0, 10);
    // }

    if (this.job && Array.isArray(this.job.attachments)) {
      const purchaseInventoryFile = this.job.attachments.find(
        item => item.catagory === 'purchase_inventory'
      );

      const codCertFile = this.job.attachments.find(
        item => item.catagory === 'cod'
      );

      const commodityReportFile = this.job.attachments.find(
        item => item.catagory === 'commodity_report'
      );

      const miscellaneousFile = this.job.attachments.find(
        item => item.catagory === 'miscellaneous'
      );

      if (miscellaneousFile) {
        this.currentMiscellaneousFile = new File(
          [miscellaneousFile.original_name],
          miscellaneousFile.original_name,
          {
            type: miscellaneousFile.file_type
          }
        );
      }

      if (commodityReportFile) {
        this.currentCommodityReportFile = new File(
          [commodityReportFile.original_name],
          commodityReportFile.original_name,
          {
            type: commodityReportFile.file_type
          }
        );
      }

      if (purchaseInventoryFile) {
        this.currentPurchaseInventoryFile = new File(
          [purchaseInventoryFile.original_name],
          purchaseInventoryFile.original_name,
          {
            type: purchaseInventoryFile.file_type
          }
        );
      }

      if (codCertFile) {
        this.currentCodFile = new File(
          [codCertFile.original_name],
          codCertFile.original_name,
          {
            type: codCertFile.file_type
          }
        );
      }
    }
    //this.validateCloseOutJobForm();
  },
  watch: {
    finalValid() {
      this.$emit('finalValid', this.finalValid);
    },
    job() {
      if (this.job && this.job.destruction_date) {
        this.job.destruction_date = moment(this.job.destruction_date).format(
          'YYYY-MM-DD'
        );
      }

      if (this.job && this.job.actual_pickup_date) {
        this.job.actual_pickup_date = moment(
          this.job.actual_pickup_date
        ).format('YYYY-MM-DD');
      }
    },
    _file() {
      // if (!this._showCloseOutDialog) {
      //   return;
      // }

      if (!this.job.attachments) {
        this.job.attachments = [];
      }

      if (this._file.file.Key && this.fileUploading) {
        this.job.attachments.push({
          key: this._file.file.Key,
          location: this._file.file.Location,
          file_type: this.fileUploading.type,
          catagory: this.fileUploading.catagory,
          original_name: this.fileUploading.name
        });

        this.updateDataCloseOutForm('attachments');
      }
    }
    // 'job.actual_pickup_date'(newValue, oldValue) {
    //   debugger;
    //   // Chỉ gọi updateDataCloseOutForm nếu giá trị mới khác giá trị cũ
    //   if (oldValue === null && newValue !== null) {
    //     this.updateDataCloseOutForm('actual_pickup_date');
    //   }
    // },
    // 'job.destruction_date'(newValue, oldValue) {
    //   debugger;
    //   // Chỉ gọi updateDataCloseOutForm nếu giá trị mới khác giá trị cũ
    //   if (oldValue === null && newValue !== null) {
    //     this.updateDataCloseOutForm('destruction_date');
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      _file: 'uploads/getFile',
      _job: 'jobs/getCurrentJob',
      _loading: 'jobs/loading'
    }),
    today() {
      return moment().format('YYYY-MM-DD');
    },
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    }
    // checkValidCloseOutJobForm() {
    //   return this.$refs.jobCloseOutForm.validate();
    // }
  },
  methods: {
    ...Helpers,
    ...mapMutations({
      _setJob: 'jobs/setJob',
      _setShowCloseOutDialog: 'jobs/setShowCloseOutDialog',
      _setClosingConf: 'jobs/setClosingConf'
    }),
    ...mapActions({
      _updateBid: 'bids/updateBid',
      _updateBidForCloseOutForm: 'bids/updateBidForCloseOutForm',
      _uploadFile: 'uploads/uploadFile',
      _updateJob: 'jobs/updateJob',
      _updateJobForCloseOutForm: 'jobs/updateJobForCloseOutForm'
    }),
    openCloseOutDialog() {
      this._setShowCloseOutDialog(true);
    },
    isNumber(event) {
      var v = event.target.value + event.key;
      var f = v.split('.');
      // console.log("event " +v ,event);
      if (
        (!/\d/.test(event.key) && event.key !== '.') ||
        (f.length > 1 && f[1].length > 2)
      )
        return event.preventDefault();
    },
    saveFinalWeight() {
      // validate the weight
      if (this.job.final_weight && Math.sign(this.job.final_weight) == 1) {
        let _job = {};
        _job.id = this.job.job_id;
        _job.final_weight = Number(this.job.final_weight).toFixed(2);

        this._updateJobForCloseOutForm(_job);
      }
    },
    updateDataCloseOutForm(fieldName) {
      this.$nextTick(() => {
        if (
          _.isNil(this.job[fieldName]) ||
          _.isEmpty(this.job[fieldName].toString()) ||
          _.isNil(this.$refs[fieldName])
            ? false
            : !this.$refs[fieldName].validate()
        ) {
          return;
        }

        var allowSave = true;
        var payload = {
          id: this.job.job_id
        };

        if (fieldName === 'current_bid_buy_out') {
          if (!_.isEmpty(this.job.current_bid_buy_out)) {
            const options = {
              id: this.job.bid_id,
              current_bid_buy_out: this.job.current_bid_buy_out
            };
            this._updateBidForCloseOutForm(options);
          }
          return;
        }

        switch (fieldName) {
          case 'final_weight':
            if (Math.sign(this.job.final_weight) == 1) {
              this.job.final_weight = Number(this.job.final_weight2).toFixed(2);
              payload.final_weight = this.job.final_weight;
            } else {
              allowSave = false;
            }
            break;
          case 'additional_scrap_proceeds':
            payload.additional_scrap_proceeds = this.job
              .additional_scrap_proceeds
              ? parseFloat(this.job.additional_scrap_proceeds.replace(/,/g, ''))
              : null;
            break;
          case 'has_purchase_equipment':
            payload.has_purchase_equipment = this.job.has_purchase_equipment;

            if (!this.job.has_purchase_equipment) {
              this.job.resale_offer = null;
              this.job.attachments = this.job.attachments.filter(file => {
                return file.catagory !== config.KEY.Purchase_Inventory;
              });
              payload.resale_offer = null;
              payload.attachments = this.job.attachments;
            }
            break;
          // case 'actual_pickup_date':
          //   payload.actual_pickup_date = this.job.actual_pickup_date;
          //   break;
          // case 'destruction_date':
          //   payload.destruction_date = this.job.destruction_date;
          //   break;
          default:
            payload[fieldName] = this.job[fieldName];
        }

        if (allowSave) {
          this._updateJobForCloseOutForm(payload);
        }
      });
    },
    saveData() {
      // debugger;
      let _job = {};
      _job.id = this.job.job_id;

      // validate the weight
      // if(Math.sign(this.job.final_weight) == 1) {
      //   _job.final_weight = this.job.final_weight;
      // }
      if (this.job.actual_pickup_date) {
        _job.actual_pickup_date = this.job.actual_pickup_date;
      }

      if (this.job.destruction_date) {
        _job.destruction_date = this.job.destruction_date;
      }

      this._updateJob(_job);
    },
    uploadFile(e, type) {
      if (e && e.name) {
        this.fileUploading.file = e;
        this.fileUploading.name = e.name;
        this.fileUploading.catagory = type;
        this.fileUploading.type = e.type;
        let file = {
          file: e,
          original_name: e.name,
          type: type
        };
        this._uploadFile(file);
      } else if (!e) {
      }
    },
    validateCloseOutJobForm() {
      this.isCloseOutJobFormValid = this.$refs.jobCloseOutForm.validate();
    },
    checkFileExistsByCategory(category) {
      let files = this.job.attachments.filter(file => {
        return file.catagory == category;
      });
      return files.length > 0 ? true : false;
    },
    confirmClose() {
      this.startedCheckCompleteCommodityReport = true;

      let jobCloseOutFormIsValid =
        this.$refs.jobCloseOutForm.validate() &&
        (!this.job.commodity_report ||
          !this.job.commodity_report_link ||
          this.clickedCompleteCommodityReport);

      if (jobCloseOutFormIsValid) {
        this._setClosingConf(true);
      }
    },
    openPopup() {
      this.isPopupVisible = true;
    },
    openOverlayPopup(url, popupWidth = 700, popupHeight = 500) {
      // Calculate the position for the popup to appear in the center of the screen
      // const windowWidth =
      //   window.innerWidth ||
      //   document.documentElement.clientWidth ||
      //   screen.width;
      // const windowHeight =
      //   window.innerHeight ||
      //   document.documentElement.clientHeight ||
      //   screen.height;
      // const centeredLeft = (windowWidth - popupWidth) / 2;
      // const centeredTop = (windowHeight - popupHeight) / 2;
      // const options = `scrollbars=yes,status=yes,width=${popupWidth},height=${popupHeight},top=${centeredTop},left=${centeredLeft}`;
      // // Open the popup window
      // window.open(url, 'PopupTitle', options);
    },
    clickOnCompleteCommodityReport() {
      this.clickedCompleteCommodityReport = true;
    },
    handleCommodityReportPopup(value) {
      this.isPopupVisible = value;
    }
  }
};
</script>
<style>
.small-subtitle {
  font-size: 0.8em !important;
  font-weight: 400 !important;
  padding-left: 20px;
}
</style>
