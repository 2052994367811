<style>
.wrapper {
  text-align: center;
  border-style: solid;
  border-color: #ffff;
}

.btn-discard {
  color: #2e384e;
  border: solid 1px #2e384e;
}

.btn-save-changes {
  color: white !important;
  background-color: #2e384e !important;
}

.title {
  color: #0091ff !important;
  text-transform: uppercase;
}

.content {
  text-align: center;
}

.close-btn-container {
  position: absolute;
  right: 0;
  flex-grow: 0;
}
</style>
<template>
  <v-dialog
    persistent
    @click:outside="closePopup"
    v-model="isShow"
    max-width="400"
  >
    <v-card class="pb-4 wrapper">
      <v-card-title class="justify-center">
        <div>
          <img :src="require('@/assets/warning.png')" />
        </div>
        <div class="close-btn-container">
          <v-btn class="close-btn" text @click="closePopup">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card class="pa-1" flat>
        <h2 class="title">UNSAVED CHANGES</h2>
        <div class="content">
          <v-card-text>
            You are leaving the profile page with unsaved changes.
          </v-card-text>
        </div>
      </v-card>
      <v-card-actions flat>
        <v-row no-gutters class="mt-4 ma-4">
          <v-btn rounded class="btn-discard" @click="handleDiscardChange()">
            Discard Changes
          </v-btn>
          <v-spacer />
          <v-btn
            rounded
            class="btn-save-changes"
            :loading="isSaveChangesLoading"
            @click="handleSaveChanges()"
          >
            Save Changes
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    isShow: Boolean,
    saveChanges: Function,
    discardChanged: Function
  },
  data: function() {
    return {
      isSaveChangesLoading: false
    };
  },
  methods: {
    ...mapMutations({
      _setCompany: 'company/setCompany',
      _setUser: 'user/setUser',
      _setPreLogOut: 'authenticate/setPreLogOut'
    }),
    closePopup() {
      this.$emit('onCloseDialog', false);
    },
    handleSaveChanges() {
      this.isSaveChangesLoading = true;
      this.saveChanges();
      this.isSaveChangesLoading = false;
    },
    handleDiscardChange() {
      this.discardChanged();
    }
  }
};
</script>
