<template>
  <v-dialog
    @keydown.esc="clickOutside"
    :persistent="isPersistent"
    @click:outside="clickOutside"
    v-model="isShow"
    :max-width="maxWidth"
  >
    <v-card class="pb-2 wrapper">
      <v-card-title class="dialog-header mb-3">
        <div class="image-container">
          <img :src="require('@/assets/warning.png')" alt="Warning" class="warning-icon" />
        </div>
        <div v-if="closePopup != null" class="close-btn-container">
          <v-btn class="close-btn" text @click="closePopup">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="dialog-content">
        <h2 class="title">{{ title }}</h2>
        <div v-html="message" class="content  mt-6"></div>
      </v-card-text>
      <v-card-actions class="dialog-actions pb-4" v-if="!isHideButtonAction">
        <v-btn
          :color="colorUnConfirm"
          :outlined="isOutlinedUnConfirmButton"
          @click="handleUnConfirm"
          :loading="isLoadingUnConfirm"
          :class="[classUnConfirmButton]"
        >
          {{ titleButtonUnConfirm }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :color="colorConfirm"
          :outlined="isOutlinedConfirmButton"
          @click="handleConfirm"
          :loading="isLoadingConfirm"
          :class="[classConfirmButton]"
        >
          {{ titleButtonConfirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    message: {
      type: String,
      default: 'Are you sure?'
    },
    confirm: Function,
    unConfirm: Function,
    clickOutside: Function,
    closePopup: {
      type: Function,
      default: null // Cập nhật giá trị mặc định là null
    },
    colorConfirm: {
      type: String,
      default: 'primary'
    },
    colorUnConfirm: {
      type: String,
      default: 'primary'
    },
    titleButtonConfirm: {
      type: String,
      default: 'Continue'
    },
    titleButtonUnConfirm: {
      type: String,
      default: 'Cancel'
    },
    classConfirmButton: {
      type: String,
      default: ''
    },
    classUnConfirmButton: {
      type: String,
      default: ''
    },
    isOutlinedConfirmButton: {
      type: Boolean,
      default: false
    },
    isOutlinedUnConfirmButton: {
      type: Boolean,
      default: true
    },
    isPersistent: {
      type: Boolean,
      default: true
    },
    isHideButtonAction: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '420'
    }
  },
  data: function() {
    return {
      isLoadingConfirm: false,
      isLoadingUnConfirm: false
    };
  },
  watch: {
    isShow(newVal, oldVal) {
      this.isShowPopup = newVal;
    }
  },
  computed: {
    ...mapGetters({
      _jobLoading: 'jobs/loading'
    })
  },
  methods: {
    ...mapMutations({
      _setActiveWarningDialog: 'jobs/setShowWarningUnsavedChange',
      _setActiveEditForm: 'jobs/setEditNewJob',
      _setJob: 'jobs/setCurrentJob'
    }),
    ...mapActions({
      _close: 'jobs/updateJob'
    }),
    handleConfirm() {
      this.isLoadingConfirm = true;
      this.confirm();
    },
    handleUnConfirm() {
      this.isLoadingUnConfirm = true;
      this.unConfirm();
    }
  }
};
</script>

<style scoped>
.wrapper {
  text-align: center;
  border: solid 1px transparent;
}

.dialog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.dialog-content {
  text-align: center;
  white-space: pre-wrap;
}

.title {
  color: #0091ff;
  text-transform: uppercase;
}

.dialog-actions {
  justify-content: flex-end;
}

.close-btn-container {
  /* display: flex;
    justify-content: flex-end; */

  position: absolute;
  right: 0;
  /* top: 2; */
}

.image-container {
  margin: auto;
  flex-grow: 1;
  text-align: center;
}

.warning-icon {
  max-width: 100%;
  /* Đảm bảo ảnh phản hồi */
  height: auto;
  display: inline-block;
  /* Để áp dụng text-align từ container */
}

.close-btn-container {
  flex-grow: 0;
}
</style>
