<template>
	<v-container class="fill-height" style="height:100% !important;" fluid>
		<v-row
			align="center"
			justify="center"
			style="margin-top: 10%; height:calc(100vh- 150px);"
		>
			<v-col
				align="center"
				justify="center"
				width="400px;"
				style="height:calc(100vh- 150px);"
			>
				<v-card width="400px" flat>
					<v-img
						contain
						height="200"
						class="mb-6"
						:src="require('@/assets/rgx-black-lockup-1.png')"
					/>
					<v-subtitle class="text-center display-1"
						>Oops!<br />The page you are looking for doesn't exist.</v-subtitle
					>
					<v-btn class="my-8" color="primary" @click="$router.push('/profile')"
						>Get me out of here</v-btn
					>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	components: {},
	computed: {
		...mapGetters({
			_company: "company/getCompany",
		}),
	},
};
</script>
