/* eslint-disable no-unused-vars */
import { auth, checkToken, forgotPassword, setNewPassword, getCaptcha } from '../../api';
import VueRouter from '../../router';
import VueCookies from 'vue-cookies';
import Vue from 'vue';

Vue.use(VueCookies);

// initial customer state
const state = {
  loggedIn: false,
  error: undefined,
  user: { route: true },
  loading: false,
  capt: '',
  preLogOut: false
};

// getters
const getters = {
  getState: state => {
    return state.loggedIn;
  },
  getUser: state => {
    return state.user;
  },
  getLoading: state => {
    return state.loading;
  },
  getCapt: state => {
    return state.capt;
  },
  getPreLogOut: state => {
    return state.preLogOut;
  }
};

// actions
const actions = {
  setNewPassword: async ({ commit }, payload) => {
    commit('setLoading', true);
    try {
      return setNewPassword(payload).then(res => {
        commit('notification/setNotificationMsg', 'Password rest successfully', {
          root: true
        });
        commit('notification/setNotificationType', 'success', { root: true });
        commit('notification/setNotification', true, { root: true });
        commit('setLoading', false);
        VueRouter.push('/');
      });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', 'Password was not reset, please check your entry and try again', {
        root: true
      });
    }
  },
  forgotPassword: async ({ commit }, payload) => {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      forgotPassword(payload.email, payload.capt)
        .then(res => {
          let rs = res.response;

          if (!rs) {
            if (res.status == 200) {
              // huy updated => check status if error, show the message from api
              commit('notification/setNotificationMsg', res.data.message, {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });
              commit('notification/setNotification', true, { root: true });
              resolve(res);
              commit('setLoading', false);
            } else {
              // huy updated => show message from api

              commit('notification/setNotification', true, { root: true });
              commit('notification/setNotificationType', 'error', {
                root: true
              });
              commit('notification/setNotificationMsg', res.data.message, {
                root: true
              });
              commit('setLoading', false);
            }
          } else {
            if (rs.status == 200) {
              // huy updated => check status if error, show the message from api
              commit('notification/setNotificationMsg', rs.data.message, {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });
              commit('notification/setNotification', true, { root: true });
              resolve(rs);
              commit('setLoading', false);
            } else {
              // huy updated => show message from api

              commit('notification/setNotification', true, { root: true });
              commit('notification/setNotificationType', 'error', {
                root: true
              });
              commit('notification/setNotificationMsg', rs.data.message, {
                root: true
              });
              commit('setLoading', false);
            }
          }
        })
        .catch(err => {
          commit('notification/setNotificationMsg', 'Email provided was not found in our system. Please try again.', {
            root: true
          });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotification', true, { root: true });
          reject(err);
          commit('setLoading', false);
        });
    });
  },
  getCaptcha: () => {
    //new Promise((resolve, reject) => {
    //  getCaptcha()
    //    .then((res) => {

    //            resolve(res);
    //    })
    //});
    var v = getCaptcha();
    state.capt = v;
    console.log('call getCaptcha', v);
    return v;
    // return new Promise((resolve, reject) => {
    //   resolve(v);
    // })
  },
  logOut: async ({ commit, dispatch }) => {
    try {
      await VueCookies.remove('token');
      commit('jobs/setJobs', undefined, { root: true });
      commit('jobs/setJob', undefined, { root: true });
      commit('user/setUser', undefined, { root: true });
      commit('user/setUsers', undefined, { root: true });
      commit('sites/setSites', undefined, { root: true });
      commit('company/setCompany', undefined, { root: true });
      commit('company/setVendorCompanyProfileStatus', undefined, { root: true });
      //  console.log("setShowAccountStatusDialog false");
      if (window.location.href.indexOf('?401') < 0) commit('company/setShowAccountStatusDialog', false, { root: true });
      else commit('company/setShowAccountStatusDialog', true, { root: true });
      commit('setUser', { route: true });
      commit('toggleLogIn', false);
      commit('setPreLogOut', false);
      VueRouter.push('/');
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
    }
  },
  checkToken: async ({ commit, dispatch }) => {
    commit('setLoading', true);
    checkToken()
      .then(res => {
        commit('toggleLogIn', true);
        VueCookies.set('token', res.data.token);
        commit('user/setUser', res.data.user, { root: true });
        commit('user/setLoggedInUser', res.data.user, { root: true });
        commit('company/setCompany', res.data.company, { root: true });
        commit('company/setLoggedInCompany', res.data.company, { root: true });
        dispatch('company/getCompanyProfileStatus', {}, { root: true });
        commit('setLoading', false);
      })
      .catch(err => {
        if (VueRouter.history.current.name !== 'Registration') {
          dispatch('logOut');
        }
        commit('setError', err);
        commit('setLoading', false);
      });
  },
  authenticate: async ({ commit }, payload) => {
    commit('setLoading', true);
    const user = payload;
    return new Promise((resolve, reject) => {
      auth(user)
        .then(res => {
          try {
            // RGX-437
            if (res.status == 200 && res.data.isExistUser) {
              commit('setLoading', false);
              resolve(res);
            } else {
              commit('toggleLogIn', true);
              VueCookies.set('token', res.data.token);
              commit('user/setUser', res.data.user, { root: true });
              commit('user/setLoggedInUser', res.data.user, { root: true });
              commit('company/setCompany', res.data.company, { root: true });
              commit('company/setLoggedInCompany', res.data.company, {
                root: true
              });
              if (res.data.company.status != 'Active' && res.data.company.status != 'New' && window.location.href.indexOf('register') < 0) {
                console.log('setShowAccountStatusDialog true');
                commit('company/setShowAccountStatusDialog', true, {
                  root: true
                });
                commit('setLoading', false);
                return;
              }
              if (user.route) {
                const loginRedirectUrl = localStorage.getItem('loginRedirectUrl');
                VueRouter.push(loginRedirectUrl ? JSON.parse(loginRedirectUrl) : '/profile');
                localStorage.removeItem('loginRedirectUrl');
                commit('setLoading', false);
              }
              commit('notification/setNotificationMsg', 'Logged In', {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });
              commit('notification/setNotification', true, { root: true });
              resolve(res);
              commit('setLoading', false);
            }
          } catch (err) {
            commit('setLoading', false);
            commit('notification/setNotification', true, { root: true });
            commit('notification/setNotificationMsg', err.response.data.message, {
              root: true
            });
            commit('notification/setNotificationType', 'error', { root: true });
            reject(err);
          }
        })
        .catch(err => {
          commit('setError', err);
          commit('notification/setNotificationMsg', 'Email or password is incorrect. Please try again.', {
            root: true
          });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotification', true, { root: true });
          commit('setLoading', false);
          reject(err);
        });
    });
  },
  authMin: ({ commit }, payload) => {
    commit('setLoading', true);
    const user = payload;
    try {
      return auth(user)
        .then(res => {
          try {
            commit('toggleLogIn', true);
            commit('user/setUser', res.data.user, { root: true });
            commit('company/setCompany', res.data.company, { root: true });
            VueCookies.set('token', res.data.token);
            if (user.route) {
              VueRouter.push('/profile');

              commit('setLoading', false);
            }
            commit('setLoading', false);
          } catch (err) {
            commit('setLoading', false);
            commit('setError', err);
          }
        })
        .catch(err => {
          commit('setError', err);
          commit('setLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('setLoading', false);
    }
  },
  auToSetUserAndCompanyInfo: async ({ commit, dispatch }) => {
    try {
      commit('setLoading', true);
      const res = await checkToken();
      commit('user/setUser', res.data.user, { root: true });
      commit('company/setCompany', res.data.company, { root: true });
      commit('setLoading', false);
      commit('company/getCompanyProfileStatus');
      return {
        isSuccess: true,
        user: res.data.user,
        company: res.data.company
      };
    } catch (err) {
      commit('setError', err);
      commit('setLoading', false);
      return {
        isSuccess: false
      };
    }
  }
};

// mutations
const mutations = {
  setUser: (state, data) => {
    state.user = data;
  },
  toggleLogIn: (state, data) => {
    state.loggedIn = data;
  },
  setError: (state, data) => {
    state.error = data;
  },
  setLoading: (state, data) => {
    state.loading = data;
  },
  setPreLogOut: (state, data) => {
    state.preLogOut = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
