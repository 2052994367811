<template>
  <div :class="`no-padding pa-0 ${styles}`" v-html="formattedText"></div>
</template>

<script>
export default {
  name: 'RenderTextArea',
  props: {
    textAreaData: {
      type: String,
      required: true
    },
    styles: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedText() {
      return this.textAreaData.replace(/\n/g, '<br>');
    }
  }
};
</script>
