<template>
  <div>
    <v-dialog v-if="_job" v-model="_active" max-width="820" @click:outside="cancel">
      <v-card flat :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 100px)' : 'auto'">
        <v-toolbar flat>
          <v-card-title v-if="$vuetify.breakpoint.mdAndUp" @click="validateTest()">
            Edit Job
          </v-card-title>
          <v-spacer />
          <v-btn class="close-btn" text @click="cancel()">
            close
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-stepper v-model="step" class="elevation-0" :vertical="$vuetify.breakpoint.mdAndUp" tile flat>
          <v-stepper-header v-if="$vuetify.breakpoint.smAndDown" style="transition: 2s" elevation="0">
            <v-stepper-step :complete="step > 1" step="1" @click="step = 1">
              Job Overview
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2" @click="step = 2">
              Equipment
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3" @click="step = 3">
              Location
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 4" step="4" @click="step = 4">
              Review
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-step v-if="$vuetify.breakpoint.mdAndUp" :complete="step > 1" step="1">
            Job Details
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card flat class="pa-2">
              <v-card-title class="ma-0 pa-0" v-if="$vuetify.breakpoint.smAndDown">
                Job Details
              </v-card-title>
              <v-form ref="detailsForm" v-model="detailsForm">
                <v-text-field
                  :maxlength="60"
                  class="mt-4"
                  :rules="[rules.required, value => value && value.length <= 60]"
                  v-model="_job.name"
                  label="Job Name: Brief Description of Equipment*"
                  messages="Entice vendors with this short description of the equipment. Cannot include your company name. 60-character limit. Example: Routers, Switches, Cell Phones, Mixed IT Equipment"
                ></v-text-field>

                <div class="maxlength-box">
                  <label></label>
                  <label :class="{ 'red-text': _job.name && _job.name.length > 60 }">
                    {{ _job.name ? 60 - _job.name.length : 60 }}
                  </label>
                </div>
                <!-- <v-text-field :rules="[rules.required]" v-model="_job.name" label="Job Name*" class="my-3"
                  messages="Please do not include any portion of the company's name in this section."></v-text-field> -->

                <v-text-field
                  v-model="_job.number"
                  label="Internal Job ID"
                  messages="Job name or number for internal tracking."
                ></v-text-field>
                <!-- <v-text-field v-model="_job.number" label="Job Number"
                  messages="This field can be used for internal reference.  For example: PO Number, Region Code or anything else that will help you organize jobs for later reporting"></v-text-field> -->

                <v-textarea
                  class="mt-4"
                  v-model="_job.description"
                  label="Description"
                  filled
                  messages="Please include a brief description of the work to be performed and any specific requirements. Example: Please remove all identifying stickers/markers."
                ></v-textarea>

                <v-row no-gutters>
                  <v-col sm="12">
                    <v-radio-group label="Logistics*" required v-model="_job.logistics" :rules="[v => !!v || '']">
                      <v-radio
                        class="small-radio"
                        :value="config.LOGISTICS_OPTIONS.VENDOR_PICKUP.value"
                        :label="config.LOGISTICS_OPTIONS.VENDOR_PICKUP.title"
                      />
                      <v-radio
                        class="small-radio"
                        :value="config.LOGISTICS_OPTIONS.CUSTOMER_DROPOFF.value"
                        :label="config.LOGISTICS_OPTIONS.CUSTOMER_DROPOFF.title"
                      />
                      <v-radio
                        class="small-radio"
                        :value="config.LOGISTICS_OPTIONS.EITHER.value"
                        :label="config.LOGISTICS_OPTIONS.EITHER.title"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row no-gutters class="pt-6">
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="menu"
                      v-model="startMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="_job.bid_due_date"
                          label="Bids Due Date*"
                          :rules="[rules.required, rules.afterToday]"
                          prepend-icon="mdi-calendar-blank"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="_job.bid_due_date"
                        no-title
                        :min="today"
                        @input="startMenu = false"
                        @change="onChangeStartDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="menu"
                      v-model="endMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="_job.end_date"
                          label="Equipment Removal Date*"
                          prepend-icon="mdi-calendar-blank"
                          :rules="[rules.afterBidStart]"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="endDate"
                        v-model="_job.end_date"
                        no-title
                        :min="_job.bid_due_date"
                        @input="endMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="2"></v-col>
                  <v-col class="exact-removal-date" cols="12" sm="12" md="4">
                    <v-row no-gutters>
                      <v-radio-group
                        required
                        label="How exact is the removal date?*"
                        v-model="_job.exact_removal_date"
                        :rules="[v => !!v || '']"
                      >
                        <v-radio
                          class="small-radio"
                          :value="config.EXACT_REMOVE_DATE_OPTIONS.EXACT_DATE.value"
                          :label="config.EXACT_REMOVE_DATE_OPTIONS.EXACT_DATE.title"
                        />
                        <v-radio
                          class="small-radio"
                          :value="config.EXACT_REMOVE_DATE_OPTIONS.PLUS_OR_MINUS_DATE.value"
                          :label="config.EXACT_REMOVE_DATE_OPTIONS.PLUS_OR_MINUS_DATE.title"
                        />
                        <v-radio
                          class="small-radio"
                          :value="config.EXACT_REMOVE_DATE_OPTIONS.FLEXIBLE_DATE.value"
                          :label="config.EXACT_REMOVE_DATE_OPTIONS.FLEXIBLE_DATE.title"
                        />
                      </v-radio-group>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      label="Project Manager*"
                      persistent-hint
                      :items="_users"
                      :rules="[rules.required]"
                      item-text="user_name"
                      item-value="user_id"
                      v-model="_job.project_manager_id"
                      @change="pmChange"
                      :disabled="ProjectManagerExpand"
                      hint="This is the contact person responsible for the job from bidding through final approval."
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <div class="justify-end">
                      <v-btn
                        rounded
                        color="secondary"
                        height="35"
                        elevation="0"
                        :disabled="ProjectManagerExpand"
                        class="white--text my-2"
                        v-on:click="ProjectManagerExpand = !ProjectManagerExpand"
                      >
                        <v-icon>mdi-plus</v-icon>
                        Add New
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-expand-transition>
                      <div v-show="ProjectManagerExpand" class="mx-0">
                        <v-form ref="userForPMForm" v-model="userValid">
                          <v-text-field
                            v-show="false"
                            label="User Name"
                            v-model="ProjectManager.user_name"
                          ></v-text-field>
                          <div class="flex-container">
                            <v-text-field
                              label="First Name *"
                              :rules="[rules.required]"
                              v-model="ProjectManager.first_name"
                            ></v-text-field>
                            <v-spacer />
                            <v-text-field label="Last Name" v-model="ProjectManager.last_name"></v-text-field>
                          </div>
                          <v-text-field
                            label="Email *"
                            :rules="[rules.required, rules.emailMatch]"
                            v-model="ProjectManager.email"
                          ></v-text-field>
                          <div class="flex-container">
                            <v-text-field
                              label="Phone *"
                              v-mask="'(###)###-####x######'"
                              :rules="[rules.required, rules.phoneLength]"
                              v-model="ProjectManager.phone"
                            ></v-text-field>
                            <v-spacer />
                            <v-text-field
                              label="Alt. Phone"
                              v-mask="'(###)###-####'"
                              v-model="ProjectManager.alt_phone"
                            ></v-text-field>
                          </div>
                        </v-form>
                        <div class="justify-end">
                          <v-btn
                            rounded
                            color="error"
                            elevation="0"
                            height="35"
                            class="white--text mb-2 mr-2"
                            @click="ProjectManagerExpand = !ProjectManagerExpand"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            rounded
                            color="secondary"
                            :disabled="!userValid"
                            elevation="0"
                            height="35"
                            class="white--text mb-2"
                            @click="createUser(ProjectManager, config.USER_TYPE.project_manager, 'pm')"
                            :loading="_ProjectManagerLoading"
                          >
                            Save
                          </v-btn>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </v-form>
              <v-card flat>
                <v-card-actions class="pr-0">
                  <v-spacer />
                  <v-btn text :disabled="this._job.status === 'open' && !detailsForm" @click="step += 1">
                    Next
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="$vuetify.breakpoint.mdAndUp" :complete="step > 2" step="2">
            Equipment
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card flat height="100%" class="mx-0 pa-2">
              <v-card-title class="ma-0 mb-3 pa-0" v-if="$vuetify.breakpoint.smAndDown">
                Equipment
              </v-card-title>
              <v-card flat>
                <v-row v-if="_job.recycle_specs" no-gutters class="justify-space-between">
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-row class="justify-space-between" no-gutters>
                      <v-col sm="8">{{ specs[0].statement }}</v-col>
                      <v-col sm="2">
                        <tool-tip
                          :text="
                            `If the Vendor (during sorting/processing of
                              this equipment for recycling) discovers whole
                              units or parts that have resale value....do
                              you allow them to test, reset, wipe, inventory
                              and offer your company an additional fair
                              market purchase offer for this equipment when
                              closing this job? If ”No” is selected, all
                              equipment will be recycled and
                              destroyed.`
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row class="d-flex flex-row-nowrap" no-gutters>
                      <v-radio-group dense v-model="_job.recycle_specs.resale" :mandatory="true">
                        <v-radio dense label="yes" :value="true" />
                        <v-radio dense label="no" :value="false" />
                      </v-radio-group>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-row class="justify-space-between" no-gutters>
                      <v-col sm="8">{{ specs[1].statement }}</v-col>
                      <v-col sm="2">
                        <tool-tip
                          :text="
                            `Do you require the job be performed by a vendor
                              that is certified under the R2 or e-Steward
                              standard? Only vendors that have proven their
                              certification and are in good standing with
                              their certifying body will be able to bid on
                              this job if “YES” is selected. If “NO” is
                              selected, all vendors (certified AND non-
                              certified) will have the ability to view and bid
                              on this job.`
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-radio-group
                      dense
                      v-model="_job.recycle_specs.certified_recycle"
                      :mandatory="true"
                      @change="requireCertifiedRecycleChange"
                    >
                      <v-radio dense label="yes" :value="true" />
                      <v-radio dense label="no" :value="false" />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-text-field
                      v-model="_job.number_of_pallets"
                      type="number"
                      label="Number Of Pallets"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-text-field v-model="_job.est_weight" type="number" label="Estimated Weight"></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-card flat>
                    <v-row class="pb-5">
                      <v-card-subtitle>
                        <span :class="{ 'red-text': assetTypeIsError }">
                          Asset type (select all that apply)*
                        </span>
                      </v-card-subtitle>
                    </v-row>
                    <v-row no-gutters>
                      <v-item-group>
                        <v-checkbox
                          v-model="assetTypes.network"
                          :label="config.ASSET_TYPE_LABEL.network"
                          :class="['check-box']"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="assetTypes.raw"
                          :label="config.ASSET_TYPE_LABEL.raw"
                          :class="['check-box']"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="assetTypes.mobile"
                          :label="config.ASSET_TYPE_LABEL.mobile"
                          :class="['check-box']"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="assetTypes.infr"
                          :label="config.ASSET_TYPE_LABEL.infr"
                          :class="['check-box']"
                        ></v-checkbox>
                      </v-item-group>
                    </v-row>
                    <v-row no-gutters class="order-checkbox">
                      <v-col cols="12" sm="3" md="3">
                        <v-checkbox
                          @change="onOtherCheckBoxChanged()"
                          v-model="assetTypes.other_checked"
                          :label="config.ASSET_TYPE_LABEL.other"
                          :class="{
                            'error-checkbox': assetTypeIsError && assetTypes.other_checked
                          }"
                        ></v-checkbox>
                      </v-col>
                      <v-col v-show="assetTypes.other_checked" cols="12" sm="9" md="9">
                        <v-form ref="otherCheckbox">
                          <v-text-field
                            :maxlength="30"
                            v-model="assetTypes.other"
                            label="Add other asset type (limit 30 characters)"
                            :rules="[
                              assetTypes.other_checked
                                ? value => value && value.length <= 30
                                : value => value.length <= 30
                            ]"
                          ></v-text-field>
                        </v-form>
                        <div class="maxlength-box">
                          <label></label>
                          <label
                            :class="{
                              'red-text': assetTypes.other.length > 30
                            }"
                          >
                            {{ assetTypes.other ? 30 - assetTypes.other.length : 30 }}
                          </label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>

                <v-card-subtitle>
                  You may upload multiple files per category in a zip file or one at a time. Simply click the field to
                  add another file.
                </v-card-subtitle>
                <v-form v-model="equipValid" ref="equipForm">
                  <v-file-input
                    :rules="jobAttachments.length > 0 ? [] : [rules.required]"
                    multiple
                    dense
                    ref="equipmentList"
                    @change="uploadJobFile($event, 'equipment_list')"
                    @focus="equipFocused = !equipFocused"
                    :clearable="!_uploading || !equipFocused"
                    @blur="equipFocused = !equipFocused"
                    :loading="equipFocused ? _uploading : false"
                    label="Upload Equipment List*"
                  ></v-file-input>
                  <v-file-input
                    multiple
                    @change="uploadJobFile($event, 'photos')"
                    @focus="invFocused = !invFocused"
                    @blur="invFocused = !invFocused"
                    :loading="invFocused ? _uploading : false"
                    :clearable="!_uploading || !invFocused"
                    dense
                    label="Upload Inventory Photos if Available"
                  ></v-file-input>
                  <v-file-input
                    multiple
                    @change="uploadJobFile($event, 'misc')"
                    @focus="miscFocused = !miscFocused"
                    @blur="miscFocused = !miscFocused"
                    :loading="miscFocused ? _uploading : false"
                    :clearable="!_uploading || !miscFocused"
                    dense
                    label="Miscellaneous Document Upload"
                  ></v-file-input>
                  <v-card-subtitle>Uploaded Files</v-card-subtitle>
                  <v-card max-height="150" style="overflow-x: auto" class="my-1 pa-0" v-if="jobAttachments" flat>
                    <v-list-item
                      width="100%"
                      dense
                      v-for="(attachment, index) in jobAttachments"
                      :key="index"
                      v-show="attachment.catagory !== 'commodity_report_template'"
                    >
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-paperclip</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ attachment.original_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ attachment.catagory.replace(/_/g, ' ') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row no-gutters>
                          <v-btn icon :href="attachment.location" :download="attachment.original_name">
                            <v-icon color="secondary">mdi-download</v-icon>
                          </v-btn>
                          <v-btn icon @click.native="deleteFile(index)">
                            <v-icon color="error">mdi-trash-can</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                  <!-- <v-text-field v-model="_job.number_of_pallets" type="number" label="Number Of Pallets"></v-text-field>
                  <v-text-field v-model="_job.est_weight" type="number" label="Estimated Weight"></v-text-field> -->
                  <!-- <v-select :items="assetTypes" label="Asset Type*" v-model="_job.asset_type" item-text="key"
                    item-value="value" :rules="[rules.required]" /> -->
                </v-form>
                <v-card-actions fixed bottom class="pr-0">
                  <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
                  <v-btn text @click="step -= 1">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                  </v-btn>
                  <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                  <!-- Vu comment out
                  <v-btn
                    text
                    class="float-right"
                    :disabled="!equipValid || !equipList"                 
                    @click="step += 1"
                  >Next<v-icon>mdi-arrow-right</v-icon>
                  </v-btn> -->
                  <!-- <v-btn
                    text
                    :disabled="
                      (this._job.status === 'open' &&
                        (!equipValid ||
                          !equipList ||
                          (assetTypes.other_checked &&
                            (assetTypes.other.length > 30 ||
                              assetTypes.other.length == 0)))) ||
                        as
                    "
                    class="float-right"
                    @click="step += 1"
                  >
                    Next
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn> -->

                  <v-btn
                    text
                    :disabled="this._job.status === 'open' && (!equipValid || !equipList || assetTypeIsError)"
                    class="float-right"
                    @click="step += 1"
                  >
                    Next
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-stepper-content>

          <v-stepper-step v-if="$vuetify.breakpoint.mdAndUp" :complete="step > 3" step="3">
            Location
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-form ref="locationDetailForm" v-model="locDetailsValid">
              <div class="mx-0 pa-2">
                <!-- <v-select
                  :items="_sites"
                  item-text="name"
                  item-value="site_id"
                  :rules="[rules.required]"
                  label="Site*"
                  v-model="_job.site_id"
                  @change="siteChange"
                  :readonly="locationExpand"
                ></v-select> -->

                <v-autocomplete
                  :items="_sites"
                  item-text="name"
                  item-value="site_id"
                  :rules="[rules.required]"
                  label="Site*"
                  v-model="_job.site_id"
                  @change="siteChange"
                  :readonly="locationExpand"
                  :filter="customFilter"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <div style="font-size: 16px;">
                        {{ `${item.name}, ID ${item.site_id}` }}
                      </div>
                      <div style="font-size: 0.8em;  color: grey;">
                        {{ `${item.street}, ${item.city}, ${item.state} ${item.zip}` }}
                      </div>
                    </div>
                  </template>
                </v-autocomplete>

                <!-- <v-autocomplete
                  :items="_sites"
                  item-text="name"
                  item-value="site_id"
                  :filter="customFilter"
                  :rules="[rules.required]"
                  label="Site*"
                  v-model="_job.site_id"
                  @change="siteChange"
                  :readonly="locationExpand"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <div
                        v-html="
                          highlightItem(`${item.name}, ID ${item.site_id}`)
                        "
                      >
                        {{ `${item.name}, ID ${item.site_id}` }}
                      </div>
                      <div
                        style="font-size: 0.8em;  color: grey;"
                        v-html="
                          highlightItem(
                            `${item.street} ,${item.city} ,${item.state} ${item.zip}`
                          )
                        "
                      >
                        {{
                          `${item.street} ,${item.city} ,${item.state} ${item.zip}`
                        }}
                      </div>
                    </div>
                  </template>
                </v-autocomplete> -->

                <div class="justify-end" v-if="_job.status === 'draft'">
                  <v-btn
                    rounded
                    color="secondary"
                    height="35"
                    elevation="0"
                    class="white--text mb-2"
                    :disabled="locationExpand"
                    @click="locationExpand = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add Location
                  </v-btn>
                </div>
                <v-expand-transition>
                  <div v-show="locationExpand" class="mx-0 pa-0">
                    <v-form v-model="locationValid" ref="locationForm">
                      <v-text-field label="Site Name*" :rules="[rules.required]" v-model="site.name"></v-text-field>
                      <VueGooglePlaces
                        :api-key="googleApi"
                        types="geocode"
                        style="width: 100%"
                        version="3.41"
                        country="us"
                        @placechanged="onPlaceChanged"
                      >
                        <v-text-field
                          v-model="address"
                          prepend-icon="mdi-map-search"
                          label=""
                          :placeholder="finderPlaceholder"
                        />
                      </VueGooglePlaces>
                      <v-card class="pl-8" flat>
                        <v-row no-gutters>
                          <v-col sm="6">
                            <v-text-field
                              label="Address*"
                              :rules="[rules.required]"
                              dense
                              v-model="site.street"
                              class="pr-2"
                            />
                          </v-col>
                          <v-col sm="6">
                            <v-text-field :rules="[rules.required]" dense label="City*" v-model="site.city" />
                          </v-col>
                          <v-col sm="6">
                            <v-autocomplete
                              v-model="site.state"
                              :items="usStates"
                              item-text="text"
                              item-value="value"
                              label="State *"
                              :rules="[stateRules.required]"
                              class="pr-2"
                            />
                          </v-col>

                          <v-col sm="3">
                            <v-text-field
                              dense
                              label="Zip Code *"
                              v-mask="'#####'"
                              :rules="[zipCodeRules.required, zipCodeRules.isCorrectFormat]"
                              class="pr-2"
                              v-model="site.zip"
                              style="margin-top: 22px"
                            />
                          </v-col>
                          <v-col sm="3">
                            <v-text-field dense label="Suite" v-model="site.suite" style="margin-top: 22px" />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-form>
                    <div class="justify-end">
                      <v-btn
                        rounded
                        color="error"
                        elevation="0"
                        height="35"
                        class="white--text mb-2 mr-2"
                        @click="locationExpand = !locationExpand"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="secondary"
                        elevation="0"
                        :loading="_siteLoading"
                        height="35"
                        :disabled="!locationValid"
                        class="white--text mb-2"
                        @click="createSite"
                      >
                        Save
                      </v-btn>
                    </div>
                  </div>
                </v-expand-transition>

                <v-autocomplete
                  label="Logistics Point of Contact*"
                  persistent-hint
                  :items="_users"
                  :rules="[rules.required]"
                  item-text="user_name"
                  item-value="user_id"
                  v-model="_job.poc_id"
                  @change="pocChange"
                  :disabled="POCExpand"
                  hint="This will be the person at the pickup site contacted by the awarded vendor to arrange logistics"
                ></v-autocomplete>
                <div class="justify-end">
                  <v-btn
                    v-if="_job.status === 'draft'"
                    rounded
                    color="secondary"
                    height="35"
                    elevation="0"
                    :disabled="POCExpand"
                    class="white--text my-2"
                    v-on:click="POCExpand = !POCExpand"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add New
                  </v-btn>
                </div>
                <v-expand-transition>
                  <div v-show="POCExpand" class="mx-0">
                    <v-form ref="userForm" v-model="userValid">
                      <v-text-field v-show="false" label="User Name" v-model="POC.user_name"></v-text-field>
                      <div class="flex-container">
                        <v-text-field
                          label="First Name *"
                          :rules="[rules.required]"
                          v-model="POC.first_name"
                        ></v-text-field>
                        <v-spacer />
                        <v-text-field label="Last Name" v-model="POC.last_name"></v-text-field>
                      </div>

                      <v-text-field
                        label="Email *"
                        :rules="[rules.required, rules.emailMatch]"
                        v-model="POC.email"
                      ></v-text-field>
                      <div class="flex-container">
                        <v-text-field
                          label="Phone *"
                          v-mask="'(###)###-####x######'"
                          :rules="[rules.required, rules.phoneLength]"
                          v-model="POC.phone"
                        ></v-text-field>
                        <v-spacer />
                        <v-text-field
                          label="Alt. Phone"
                          v-mask="'(###)###-####'"
                          v-model="POC.alt_phone"
                        ></v-text-field>
                      </div>
                    </v-form>

                    <div class="justify-end">
                      <v-btn
                        rounded
                        color="error"
                        elevation="0"
                        height="35"
                        class="white--text mb-2 mr-2"
                        @click="POCExpand = !POCExpand"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="secondary"
                        :disabled="!userValid"
                        elevation="0"
                        height="35"
                        class="white--text mb-2"
                        @click="createUser(POC, config.USER_TYPE.poc, 'poc')"
                        :loading="_POCLoading"
                      >
                        Save
                      </v-btn>
                    </div>
                  </div>
                </v-expand-transition>

                <v-checkbox class="mt-0" v-model="_job.lift_gate" label="Check here if lift gate is required" />
                <v-textarea
                  v-model="_job.vehicle_restrictions"
                  label="Vehicle Access Restrictions"
                  height="100px"
                  class="mb-2"
                  filled
                  messages='Please include any vehicle restrictions at the pickup site.  For example: "Box truck only", "Short clearance vehicle only"'
                ></v-textarea>

                <v-textarea
                  label="Vendor Restrictions"
                  filled
                  height="100px"
                  messages="Please detail any personnel requirements for the Vendor who will be performing the pickup. For example: “Please bring a government issued ID"
                  class="mt-4"
                  v-model="_job.vendor_restrictions"
                ></v-textarea>

                <div class="uncommon-box theme--light">
                  <v-row>
                    <v-col cols="12" class="text-center mb-0 pb-0">
                      <h2>Uncommon Requests</h2>
                    </v-col>
                    <v-col cols="12" class="text-center mt-0 pt-0 bold text-gray text-center">
                      These situations are not common and often require an additional fee.
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0 text-center text-gray">
                      Do you want the vendor to attach a report, or do you have a link to an online survey?
                    </v-col>
                  </v-row>
                  <v-row class="commodity-report-box">
                    <v-col cols="12" sm="6" md="6">
                      <v-row no-gutters>
                        <v-checkbox
                          class="mt-0 pr-12"
                          v-model="_job.commodity_report"
                          label="Do you need a commodity report?"
                        />
                      </v-row>
                    </v-col>
                    <v-col class="mt-4" cols="12" sm="6" md="6" v-show="_job.commodity_report">
                      <v-row class="ml-2 mr-2">
                        Optional: Upload a template or more details about your commodity report requirements.
                      </v-row>
                      <v-row>
                        <v-file-input
                          multiple
                          :clearable="true"
                          outlined
                          dense
                          class="mt-2 mr-12"
                          label="Commodity Report Template"
                          @change="uploadJobFile($event, config.KEY.Commodity_Report_Template)"
                        ></v-file-input>
                      </v-row>
                      <v-row>
                        <span v-show="checkCategoryIsExists()" class="ml-2 mr-2">
                          Uploaded Files
                        </span>
                        <v-list-item
                          class="pl-0 mr-12"
                          width="100%"
                          dense
                          v-for="(attachment, index) in jobAttachments"
                          :key="index"
                          v-show="attachment.catagory === 'commodity_report_template'"
                        >
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-paperclip</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ attachment.original_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ attachment.catagory.replace(/_/g, ' ') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row no-gutters>
                              <v-btn icon :href="attachment.location" :download="attachment.original_name">
                                <v-icon color="secondary">mdi-download</v-icon>
                              </v-btn>
                              <v-btn icon @click.native="deleteFile(index)">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                      </v-row>
                      <v-row class="ml-2 mr-2">
                        Add a link to an online commodity form, if available.
                      </v-row>
                      <v-row>
                        <v-text-field
                          label=""
                          outlined
                          dense
                          class="mt-2 mr-12 commodity-report-link"
                          :rules="[rules.isValidUrl]"
                          v-model="_job.commodity_report_link"
                        >
                          <template v-slot:label>
                            <v-icon class="mr-2">mdi-link</v-icon>
                            Commodity Form URL
                          </template>
                        </v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-checkbox
                      :disabled="this._job.status === 'open'"
                      style="padding-top:20px"
                      @change="checkbox_alert_vendor()"
                      class="mt-0"
                      v-model="_job.send_mail_to_vendors"
                      label="Alert vendors when this job is open for bid."
                    />
                  </v-row>
                  <v-row no-gutters v-if="!this._job.send_mail_to_vendors">
                    <v-col cols="12" sm="6" md="6">
                      <v-checkbox
                        :disabled="this._job.status === 'open'"
                        class="mt-0"
                        @change="checkbox_vendor()"
                        v-model="vendor_code"
                        label="RGX Admin Only: Add Vendor Codes"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="vendor_code" class="pr-2">
                      <div>
                        <b-form-tags
                          :class="[
                            'pl-0',
                            'ml-0',
                            { 'b-form-tags-error': !vendorsAssignedValid || vendorsAssignedValid.length == 0 }
                          ]"
                          input-id="vendor-state-event"
                          v-model="vendorsAssigned"
                          :tag-validator="validatorVendorCode"
                          placeholder="Vendor Codes"
                          separator="[' ', ',', ';']"
                          @input="onChangeVendorAssigned"
                          remove-on-delete
                          duplicate-tag-text="Duplicate Vendor"
                          invalid-tag-text="Invalid Vendor Code"
                        ></b-form-tags>
                        <div
                          v-if="!vendorsAssignedValid || vendorsAssignedValid.length == 0"
                          class="v-text-field__details pt-2"
                        >
                          <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message" style="">Required.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6"></v-col>
                    <v-col cols="12" sm="6" md="6" v-if="vendor_code">
                      <tr v-for="vendor in vendorsAssignedInfo" :key="vendor.id" class="table-row-spacing">
                        <td style="text-align: right" :class="{ 'error--text': !vendor.isValid }">{{ vendor.id }}</td>
                        <td>
                          <i
                            :class="['mdi', vendor.isValid ? 'mdi-check secondary--text' : 'mdi-close error--text']"
                          ></i>
                        </td>
                        <td :class="{ 'error--text': !vendor.isValid }">
                          {{ vendor.isValid ? vendor.name : vendor.errorMessage }}
                        </td>
                      </tr>
                    </v-col>

                    <!-- <v-col cols="12" sm="2" md="2" v-if="vendor_code">
                      <v-text-field :disabled="this._job.status === 'open'" type="tel" placeholder="Vendor Code"
                        v-model="_job.vendor_id" @input="getVendor()" :maxlength="5" :rules="[onlyNumbersRule]"
                        @keypress="onlyNumber" style="margin-top:-14px;  margin-left:-34px"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="v-label" style="margin-top: 5px;" v-if="vendor_code">
                      <i class="mdi mdi-check secondary--text" v-if="vendor && !load_vendor"></i>
                      &nbsp;
                      <img src="/loading.gif" v-if="load_vendor" style="width: 75px;  margin-top: -25px;" />
                      <span class="error--text" v-if="_job.vendor_id && !vendor && !load_vendor">
                        {{ vendorErrorMsg }}
                      </span>
                      <span v-if="vendor && !load_vendor" v-html="vendor.name"></span>
                    </v-col> -->
                  </v-row>
                </div>
                <v-card flat>
                  <v-card-actions class="pr-0">
                    <v-spacer />
                    <v-btn text @click="step -= 1">
                      <v-icon>mdi-arrow-left</v-icon>
                      Back
                    </v-btn>
                    <!--
                    <v-btn
                      :disabled="!locDetailsValid"
                      text
                      class="float-right"
                      @click="finalStep()"
                    >Next<v-icon>mdi-arrow-right</v-icon>
                    </v-btn> -->
                    <v-btn
                      text
                      :disabled="this._job.status === 'open' && !locDetailsValid"
                      class="float-right"
                      @click="finalStep()"
                    >
                      Next
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-form>
          </v-stepper-content>

          <v-stepper-step v-if="$vuetify.breakpoint.mdAndUp" :complete="step > 4" step="4">
            Review
          </v-stepper-step>
          <!--
          <v-stepper-content step="4">
            <job-review
              v-if="step === 4"
              @step="step = $event"
              @post="submitJob"
              @cancel="cancel()"
              :spec="specs"
            /> -->
          <v-stepper-content step="4">
            <edit-job-review
              v-if="step === 4"
              @step="step = $event"
              @post="submitJob"
              @cancel="cancel()"
              @showConfirmDelete="showConfirmDelete()"
              @startValidate="startValidate()"
              @saveAsDraft="saveAsDraft()"
              :enablePostJob="enablePostJob"
              :spec="specs"
              :statusJob="this._job.status"
            />
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
    <warning-unsaved-change
      :isShow="isShowPopupUnsavedChanged"
      :discardChanged="discardChanged"
      :saveAsDraft="saveAsDraft"
    />
    <confirm-popup
      :isShow="isShowConfirmDeletePopup"
      :title="'DELETE PERMANENTLY'"
      :message="'This job will be permanently deleted.<br/>This <strong>cannot</strong> be undone.'"
      :titleButtonConfirm="'Delete'"
      :color="'error'"
      :confirm="confirmDeleteJob"
      :clickOutside="
        () => {
          this.isShowConfirmDeletePopup = false;
        }
      "
      :closePopup="
        () => {
          this.isShowConfirmDeletePopup = false;
        }
      "
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { mask } from 'vue-the-mask';
// import JobReview from "./NewJobReview.vue";
import WarningUnsavedChange from './WarningUnsavedChange.vue';
import ConfirmPopup from '@/components/common/ConfirmPopup.vue';
import EditJobReview from './EditJobReview.vue';
import Upload from '@/mixins/Upload';
import ToolTip from '@/components/app/ToolTip.vue';
import Dates from '@/mixins/Dates.js';
import config from '../../config';
import _ from 'lodash';
import * as Helpers from '@/helpers/helpers';
import { BFormTags } from 'bootstrap-vue';
var UsaStates = require('usa-states').UsaStates;
export default {
  props: {
    statusJob: String
  },
  mixins: [Upload, Dates],
  directives: {
    mask
  },
  components: {
    // JobReview,
    EditJobReview,
    WarningUnsavedChange,
    ConfirmPopup,
    ToolTip,
    BFormTags
  },
  data() {
    return {
      searchQuery: '',
      config: config,
      isAdmin: false,
      numberValue: '',
      onlyNumbersRule: v => /^\d*$/.test(v) || 'Only numbers are allowed',
      vendor_code: false,
      finderPlaceholder: null,
      locDetailsValid: false,
      userValid: false,
      address: '',
      invFocused: false,
      miscFocused: false,
      equipFocused: undefined,
      usStates: new UsaStates({ includeTerritories: true }).states.map(state => {
        return {
          text: state.name + ' (' + state.abbreviation + ')',
          value: state.abbreviation
        };
      }),
      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v =>
          (!new RegExp(/undefined/).test(this.site.street) && this.site.street !== undefined) ||
          'Full address is required'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v => new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) || 'Please enter a valid zip code'
      },
      rules: {
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        required: v => !!v || 'Required.',
        confirmMatch: v =>
          this.newUser.password === this.newUser.confirm_pass || 'The passwords you entered don’t match',
        length: v => (v && v.length >= 8) || 'Passwords must be at least 8 characters in length',
        phoneLength: v => (v && v.length >= 13) || 'Invalid phone number.',
        afterBidStart: v =>
          moment(this._job.bid_due_date).isBefore(moment(this._job.end_date)) ||
          'Equipment due date must be later than bid due date',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(v) ||
          'Passwords require at least one special character',
        afterToday: v => moment(v) >= moment() || "Must be later than today's date",
        isValidUrl: url => {
          return this.isValidHttpsUrl(url) || 'Please enter a valid website URL (e.g. https://www.recyclegx.com)';
        }
      },
      locationValid: false,
      detailsForm: false,
      showResale: false,
      showCert: false,
      equipValid: false,
      locationExpand: false,
      POCExpand: false,
      ProjectManagerExpand: false,
      jobName: undefined,
      euip: '',
      jobNumber: undefined,
      assetTypes: config.ASSET_TYPE,
      POC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: config.USER_TYPE.poc,
        company_id: this._company_id
      },
      ProjectManager: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: config.USER_TYPE.project_manager,
        company_id: this._company_id
      },
      show1: false,
      show2: false,
      passRules: {
        required: v => !!v || 'Required.',
        emailMatch: () => this.POC.password === this.POC.passwordConf || "Your passwords don't match"
      },
      POCChosen: undefined,
      jobDescription: undefined,
      numPallets: undefined,
      estimatedWeight: undefined,
      equip: undefined,
      inv: undefined,
      misc: undefined,
      role: [
        {
          text: 'admin',
          value: 1
        },
        {
          text: 'user',
          value: 2
        }
      ],
      site: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined,
        zip: undefined
      },
      step: 1,
      vendorRestrict: undefined,
      specs: [
        {
          statement: 'Is this equipment available for purchase?*',
          bool: false
        },
        {
          statement: 'Do you require certified recycling?*',
          bool: true
        }
      ],
      startDate: moment()
        .toISOString()
        .substr(0, 10),
      endDate: moment()
        .add(1, 'days')
        .toISOString(),
      startMenu: false,
      endMenu: false,
      startDateFormatted: this.formatDate(
        moment()
          .toISOString()
          .substr(0, 10)
      ),
      endDateFormatted: this.formatDate(
        moment()
          .add(1, 'days')
          .toISOString()
          .substr(0, 10)
      ),
      siteOptions: {
        // itemsPerPage: 15,
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['name'],
        sortDesc: [false]
      },
      userOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['user_name'],
        sortDesc: [false]
      },
      isShowPopupUnsavedChanged: false,
      enablePostJob: false,
      vendorErrorMsg: 'Invalid vendor code.',
      logisticsError: false,
      isFirstTime: true,
      isShowConfirmDeletePopup: false,
      vendorsAssigned: [],
      vendorsAssignedInfo: [],
      vendorsAssignedValid: [],
      allVendorIsValid: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.finderPlaceholder = ' ';
    }, 2000);
    this._getSites(this.siteOptions);
    this._getUsers(this.userOptions);
    this.isAdmin = this._companyType === config.companyType.MASTER;

    setTimeout(() => {
      if (_.isNil(this._job.project_manager_id) && !_.isNil(this._user)) {
        this._job.project_manager_id = this._user.id;
      }
    }, 3000);
  },
  computed: {
    ...mapGetters({
      _user: 'user/getUser',
      _active: 'jobs/getEditNewJob', // Vu edited
      _sites: 'sites/getSites',
      _company_id: 'company/getCompanyId',
      _users: 'user/getUsers',
      _file: 'uploads/getFile',
      _company: 'company/getCompany',
      _newPOC: 'user/getNewTeamUser',
      _newProjectManager: 'user/getNewTeamUser',
      _newSite: 'sites/createdSite',
      _siteLoading: 'sites/loading',
      _POCLoading: 'user/loading',
      _ProjectManagerLoading: 'user/loading',
      _jobLoading: 'jobs/loading',
      _jobLoadingForDraft: 'jobs/loadingForDraft',
      // _newJob: "jobs/getNewJob", // Vu comment out
      _job: 'jobs/getCurrentJob', // Vu added
      _uploading: 'uploads/loading',
      _companyType: 'company/getCompanyType',
      _vendor: 'company/getVendor'
    }),
    vendor: {
      get() {
        return this._vendor;
      },
      set(val) {
        this._setVendor(val);
      }
    },
    user: {
      get() {
        return this._user;
      },
      set(val) {
        this._setUser(val);
      }
    },
    /* Vu comment out
    newJob: {
      get() {
        return this._newJob;
      },
      set(val) {
        this._setNewJob(val);
      },
    }, */
    googleApi() {
      return 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE'; //process.env.VUE_APP_GOOGLE_API_KEY;
    },
    mobileMargin() {
      if (this.step < 3) {
        return '0 -40px 0 0';
      } else if (this.step > 6) {
        return '0 0 0 -40px';
      } else {
        return '0 -20px 0 -20px';
      }
    },
    bidDuration() {
      // let a = moment(this._job.bid_due_date || moment());
      // let b = moment(this._job.end_date || moment());
      let a = moment(this._job.bid_due_date || moment());
      let b = moment(this._job.end_date || moment());
      return b.diff(a, 'days');
    },
    equipList() {
      let cod = this.jobAttachments.filter(o => o.catagory === 'equipment_list');
      return cod.length > 0 ? true : false;
    },
    assetTypeIsError() {
      const isValidAssetType = Object.keys(this.assetTypes).some(key => key !== 'other' && this.assetTypes[key]);
      if (this.assetTypes.other_checked) {
        return this.assetTypes.other === '';
      }
      return !isValidAssetType;
    }
  },
  watch: {
    _users() {
      switch (this.typeOfNewUser) {
        case 'poc':
          this._job.poc_id = this._newPOC.newUser.id;
          break;
        case 'pm':
          this._job.project_manager_id = this._newProjectManager.newUser.id;
          break;
        default:
        // code block
      }
    },
    _sites() {
      if (this._newSite) {
        this._job.site_id = this._newSite.id;
        this._job.site_name = this._sites.find(x => x.site_id === this._job.site_id).name;
      }
    },
    address() {
      this.site.address = this.site.address ? this.address.street : this.site.address;
    },
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    upPhoto: {
      handler: function(val) {
        this._uploadFile(this.upPhoto);
      },
      deep: true
    },
    upEquipment: {
      handler: function(val) {
        this._uploadFile(this.upEquipment);
      },
      deep: true
    },
    /*
    newJob: {
      handler() {
        if (!this._job.rec_spec) {
          this._job.rec_spec = { resale: false, certified_recycle: false };
        }
      },
      deep: true,
    }, */
    upMis: {
      handler: function(val) {
        this._uploadFile(this.upMis);
      },
      deep: true
    },
    _file: {
      handler: function(val) {
        if (!this._active) {
          return;
        }

        if (this._file.type === 'Photo') {
          this.upPhotoBase64 = this._file.file;
        } else if (this._file.type === 'Equipment') {
          this.upEquipmentBase64 = this._file.file;
        } else if (this._file.type === 'Miscellaneous') {
          this.upMisBase64 = this._file.file;
        }
      },
      deep: true
    },
    _job: {
      async handler(val, oldVal) {
        setTimeout(() => {
          if (this._active) {
            this.$refs.detailsForm.validate();
            this.$refs.equipForm.validate();
            this.$refs.locationDetailForm.validate();
            this.$refs.locationForm.validate();
            this.$refs.userForm.validate();

            this.$nextTick(() => {
              this.$refs.otherCheckbox.validate();
            });
          }
        }, 100);

        // start fix issue sometime site_is is undefined
        setTimeout(() => {
          if (_.isNil(this._job.site_id) && !_.isNil(this._job.site_id_bk) && !!this._job.site_name) {
            this._job.site_id = this._job.site_id_bk;
            //delete this._job.site_id_bk;
          }
        }, 2000);
        // end fix issue sometime site_is is undefined

        if (this.isFirstTime) {
          this.isFirstTime = false;
          if (this.isAdmin || this.statusJob === 'open') {
            this.step = 4;
            this.enablePostJob = true;
          } else {
            this.step = 1;
          }
        }

        this.vendorsAssigned = this._job.vendor_codes.length ? this._job.vendor_codes : [];
        this.vendor_code = this._job.vendor_codes.length ? true : false;
        await this.onChangeVendorAssigned();

        // if (this._job.vendor_codes.length > 0) {
        //   this.vendorsAssigned = this._job.vendor_codes;

        //   // this.vendorsAssignedInfo = this._job.vendorsAssignedInfo.map(vendor => {
        //   //   return {
        //   //     isValid: true,
        //   //     ...vendor
        //   //   };
        //   // })
        //   // this.vendorsAssignedValid = this._job.vendor_codes;
        //   this.vendor_code = true;
        //   // this._getVendorById(this._job.vendor_id).then(res => {
        //   //   if (!res) {
        //   //     this.vendorErrorMsg = 'Invalid vendor code.';
        //   //     this._job.vendor_name = '';
        //   //   } else {
        //   //     this.vendorErrorMsg = 'Vendor Inactive.';
        //   //     this._job.vendor_name = res.data.name;
        //   //   }
        //   //   this.load_vendor = false;
        //   // });
        // } else {
        //   //this.vendor = null;
        // }

        const { bid_due_date, end_date, pallets } = this._job || {};

        if (pallets) {
          this._job.number_of_pallets = pallets;
        }

        if (bid_due_date && bid_due_date.indexOf('/') >= 0) {
          this._job.bid_due_date = moment(bid_due_date, 'MM/DD/YYYY').format('YYYY-MM-DD');
        }

        if (end_date && end_date.indexOf('/') >= 0) {
          this._job.end_date = moment(end_date, 'MM/DD/YYYY').format('YYYY-MM-DD');
        }

        if (Array.isArray(this._job.attachments)) {
          this.jobAttachments = this._job.attachments;
        }

        this.assetTypes = this._job.asset_type;

        //handle require file
        // if(this.attachment.length > 0){
        //   this.fileRules = [this.rules.required];
        // }
        // else{
        //   this.fileRules = [this.rules.required]
        // }
      }
    },
    _company: {
      handler: function() {
        this._getSites(this.siteOptions);
        this._getUsers(this.userOptions);
      },
      deep: true
    },
    _newPOC: {
      handler: function() {
        //this._job.poc_id = this._newPOC.id;
        this.$refs.userForm.reset();
        this.POCExpand = false;
        this._getUsers(this.userOptions);
      },
      deep: true
    },
    _newProjectManager: {
      handler: function() {
        //this._job.project_manager_id = this._newProjectManager.id;
        this.$refs.userForPMForm.reset();
        this.ProjectManagerExpand = false;
        this._getUsers(this.userOptions);
      },
      deep: true
    },
    _newSite: {
      handler: function(val) {
        this._getSites(this.siteOptions);
        /*
        setTimeout(() => {
          this._job.site_id = val.id;
        }, 2000);
        */
        this.locationExpand = false;
        this.$refs.locationForm.reset();
        this.locationExpand = false;
        this.$refs.locationForm.reset();
      },
      deep: true
    },
    'site.state': function() {
      this.site.state = this.site.state ? this.site.state.toUpperCase() : this.site.state;
    },
    jobAttachments: {
      handler() {
        // this._job.attachments = this.jobAttachments;
      },
      deep: true
    },
    '_job.logistics': function(val) {
      this.logisticsError = !val;
    }
  },
  methods: {
    ...Helpers,
    ...mapMutations({
      _setUser: 'user/setUser',
      _setVendor: 'company/setVendor',
      _setActive: 'jobs/setEditNewJob',
      _setNewJob: 'jobs/setNewJob',
      _setShowWarningUnsavedChange: 'jobs/setShowWarningUnsavedChange'
    }),
    ...mapActions({
      _getSites: 'sites/getAll',
      _getUsers: 'user/getAll',
      _createSite: 'sites/createSite',
      _createUser: 'user/createUser',
      _createJob: 'jobs/createJob',
      _updateJob: 'jobs/updateJob',
      _uploadFile: 'uploads/uploadFile',
      _sendMail: 'mail/sendMail',
      _getVendorById: 'company/getVendorById',
      _deleteJob: 'jobs/deleteJob',
      _getCompanyByListId: 'company/getCompanyByListId'
    }),
    onlyNumber($event) {
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes($event.key)) {
        $event.preventDefault();
      }
    },
    finalStep() {
      this.step += 1;

      this._job.vendor_codes = this.vendor_code ? this.vendorsAssignedValid : null;
      this._job.vendorsAssignedInfo = this.vendor_code ? this.vendorsAssignedInfo.filter(vendor => vendor.isValid) : [];

      this.enablePostJob = this.allowPostJob();

      this._job.bid_due_date === '' ? null : this._job.bid_due_date;
      this._job.end_date = this._job.end_date == '' ? null : this._job.end_date;
      this._job.asset_type = this.assetTypes;
    },
    validateTest() {
      this.$refs.detailsForm.validate();
    },
    onPlaceChanged(e) {
      this.site.street = e.street_number + ' ' + e.route;
      this.site.city = e.locality;
      this.site.state = e.administrative_area_level_1;
      this.site.zip = e.postal_code;
      this.address = '';
      if (e.street_number !== undefined) {
        this.address = this.address.concat(e.street_number);
      }
      if (e.route !== undefined) {
        this.address = this.address.concat(' ' + e.route);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.locality !== undefined) {
        this.address = this.address.concat(e.locality);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.administrative_area_level_1 !== undefined) {
        this.address = this.address.concat(e.administrative_area_level_1);
      }
      if (e.postal_code !== undefined) {
        this.address = this.address.concat(' ' + e.postal_code);
      }
      this.address = this.address.concat(', USA');
    },
    setState() {
      if (this.site.state.length > 1) {
        this.site.state = this.site.state.substring(0, 1);
      }
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format('MM/DD/YYYY');
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return moment(date).format('YYYY-MM-DD');
    },
    submitJob() {
      let pocUsers = this._users.filter(user => user.user_id == this._job.poc_id);
      if (pocUsers.length > 0) {
        this._job.poc_email = pocUsers[0].email;
      }

      const payload = { ...this._job };

      payload.isSendMail = payload.status === 'draft';
      payload.status = payload.status === 'draft' ? 'open' : payload.status;

      payload.bid_due_date = this._job.bid_due_date;
      payload.id = payload.job_id;

      payload.rec_spec = { ...this._job.recycle_specs };

      payload.vendor_id = this.vendor_code && this.vendor ? this.vendor.id : null;

      this.removeNoNeedField(payload, false);

      return this._updateJob(payload).then(() => {
        this._setActive(false);
        setTimeout(() => {
          this.$router.go();
        }, 3000);
      });
    },
    cancel() {
      this._setActive(false);
      this._job.bid_due_date = this._job.bid_due_date === '' ? null : this._job.bid_due_date;
      this._job.end_date = this._job.end_date == '' ? null : this._job.end_date;
      if (
        this._job.status === 'draft' &&
        this._companyType !== config.companyType.MASTER &&
        this._companyType !== config.companyType.VENDOR
      ) {
        this.isShowPopupUnsavedChanged = true;
      } else {
        this._setActive(false);
        this.$router.go('/job');
      }
    },
    showConfirmDelete() {
      this.isShowConfirmDeletePopup = true;
    },
    createSite() {
      this._createSite(this.site).then(() => {});
    },
    createUser_old() {
      if (this.POC.email !== undefined) {
        this.POC.type_id = 4;
        this.POC.password = 'N/A';
        var u = this.POC;
        u.createdEmail = this._user.email;
        u.createdUser = this._user.first_name + ' ' + (this._user.last_name ? this._user.last_name : '');
        u.user_name = this.POC.email;
        // u.send_email_set_password=true;//==> remove send the email activate_account to new user
        this._createUser(u).then(() => {});
      } else {
        return;
      }
    },
    createUser(newUser, userType, typeNameOfUser) {
      if (newUser.email !== undefined) {
        this.typeOfNewUser = typeNameOfUser;
        newUser.type_id = userType;
        newUser.password = 'N/A';
        newUser.createdEmail = this._user.email;
        newUser.createdUser = this._user.first_name + ' ' + (this._user.last_name ? this._user.last_name : '');
        newUser.user_name = newUser.email;
        this._createUser(newUser).then(() => {});
      }
    },
    deleteFile(index) {
      this.jobAttachments.splice(index, 1);
    },
    siteChange() {
      this._job.site_name = this._sites.find(x => x.site_id === this._job.site_id).name;
    },
    pocChange() {
      // let selectedUser = this._users.find(x => x.user_id === this._job.poc_id);
      // if (selectedUser) {
      //   this._job.poc_user_name = selectedUser.user_name;
      //   this._job.poc_email = selectedUser.email;
      //   this._job.poc_phone = selectedUser.phone;
      //   this._job.poc_alt_phone = selectedUser.alt_phone;
      // } else {
      //   this._job.poc_user_name = '';
      //   this._job.poc_email = '';
      //   this._job.poc_phone = '';
      //   this._job.poc_alt_phone = '';
      // }
    },
    pmChange() {
      // let selectedUser = this._users.find(
      //   x => x.user_id === this._job.project_manager_id
      // );
      // if (selectedUser) {
      //   this._job.pm_user_name = selectedUser.user_name;
      //   this._job.pm_email = selectedUser.email;
      //   this._job.pm_phone = selectedUser.phone;
      //   this._job.pm_alt_phone = selectedUser.alt_phone;
      // } else {
      //   this._job.pm_user_name = '';
      //   this._job.pm_email = '';
      //   this._job.pm_phone = '';
      //   this._job.pm_alt_phone = '';
      // }
    },
    checkbox_vendor() {
      if (this.vendor_code) this._job.send_mail_to_vendors = !this.vendor_code;
    },
    checkbox_alert_vendor() {
      if (this._job.send_mail_to_vendors) {
        this.vendor_code = false;
        this._setVendor(null);
        this._job.vendor_id = null;
      }
    },
    saveAsDraft() {
      this.isShowPopupUnsavedChanged = false;
      this._job = {
        rec_spec: {
          resale: null,
          certified_recycle: null
        },
        vendor_id: null,
        //send_mail_to_vendors: false,
        site_id: null,
        name: null,
        number: null,
        description: null,
        bid_due_date: null,
        end_date: null,
        attachments: [],
        number_of_pallets: null,
        est_weight: null,
        asset_type: null,
        lift_gate: false,
        vehicle_restrictions: null,
        vendor_restrictions: null,
        poc_id: null,
        project_manager_id: null,
        bid_duration: null,
        company_id: Number(this._company_id),
        phone: null,
        alt_phone: null,
        poc_email: null,
        company_name: null,
        company_email: null,
        bid_status: null,
        status: 'draft',
        vendor_codes: this.vendor_code ? this.vendorsAssignedValid : null,
        ...this._job
      };

      const payload = { ...this._job };

      payload.bid_due_date = this._job.bid_due_date;
      payload.id = payload.job_id;

      payload.rec_spec = { ...this._job.recycle_specs };

      this.removeNoNeedField(payload);

      return this._updateJob(payload).then(() => {
        this._setActive(false);
        this.$router.go();
      });
    },
    discardChanged() {
      this._setActive(false);
      this.$refs.detailsForm.reset();
      this.$refs.equipForm.reset();
      this.$refs.locationDetailForm.reset();
      this.$refs.locationForm.reset();
      this.$refs.userForm.reset();
      this.isShowPopupUnsavedChanged = false;
      this.isFirstTime = true;
    },
    startValidate() {
      this.$refs.detailsForm.validate();
      this.$refs.equipForm.validate();
      this.$refs.locationDetailForm.validate();
      this.$refs.locationForm.validate();
    },
    onChangeStartDate() {
      this.$refs.detailsForm.validate();
      if (!this.detailsForm) {
        this.$refs.endDate.focus();
      }
    },
    onOtherCheckBoxChanged(value) {
      if (!value) {
        this.assetTypes.other = '';
      }
      this.$nextTick(() => {
        this.$refs.otherCheckbox.validate();
      });
    },
    clearReportTemplate(category) {
      let jobAttachments = this.jobAttachments;
      for (let i = 0; i < jobAttachments.length; i++) {
        if (this.jobAttachments[i].catagory === category) {
          this.jobAttachments.splice(i, 1);
        }
      }
    },
    checkCategoryIsExists(category) {
      let jobAttachments = this.jobAttachments;
      let att = jobAttachments.filter(attachment => {
        return attachment.catagory === category;
      });
      return att && att.length > 0;
    },
    removeNoNeedField(payload, isSaveDraft = true) {
      if (isSaveDraft) {
        delete payload.company_name;
        delete payload.company_email;
        delete payload.poc_email;
      }
      delete payload.job_id;
      delete payload.recycle_specs;
      delete payload.bid_id;
      delete payload.bid_per_pound;
      delete payload.bid_buy_out;
      delete payload.weight;
      delete payload.date;
      delete payload.pallets;
      delete payload.poc_phone;
      delete payload.poc_fname;
      delete payload.poc_lname;
      delete payload.poc_alt_phone;

      delete payload.poc_user_name;
      delete payload.site_name;
      delete payload.city;
      delete payload.zip;
      delete payload.state;
      delete payload.street;
      delete payload.location;
      delete payload.saved;
      delete payload.vendor_name;
      return payload;
    },
    allowPostJob() {
      let formIsValid = this.detailsForm && this.equipValid && this.equipList && this.locDetailsValid;
      let vendorIsValid = !this.vendor_code || this.vendorsAssignedValid.length > 0;
      return formIsValid && vendorIsValid && !this.assetTypeIsError;
    },
    handleJobNameChange(event) {
      if (this._job.name.length == 60 && !this.isFunctionKey(event.keyCode)) {
        event.preventDefault();
        // this._job.name = this._job.name.substr(0, 59);
      }
    },
    confirmDeleteJob() {
      this._deleteJob(this._job.job_id).then(() => {
        this.isShowConfirmDeletePopup = false;
        this._setActive(false);
        // setTimeout(() => {
        //   this.$router.go('/job');
        // }, 500);
        setTimeout(() => {
          if (this.$router.currentRoute.path === '/job') {
            location.reload();
          } else {
            this.$router.replace('/job').catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });
          }
        }, 500);
      });
    },
    customFilter(item, queryText, itemText) {
      itemText = `${item.name}, ID ${item.site_id} ${item.street}, ${item.city}, ${item.state} ${item.zip}`;
      return itemText.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    validatorVendorCode(vendorCode) {
      const regex = /^\d{1,5}$/; //only allow 5 char
      return regex.test(vendorCode);
    },
    onChangeVendorAssigned: _.debounce(async function() {
      let customerNeedCert = this._job.recycle_specs.certified_recycle;
      let vendorResponse = await this._getCompanyByListId({
        ids: this.vendorsAssigned,
        companyType: config.COMPANY_TYPE.VENDOR
      });
      this.vendorsAssignedInfo = [];
      this.vendorsAssignedValid = [];
      this.allVendorIsValid = true;
      for (let vendorId of this.vendorsAssigned) {
        let vendor = vendorResponse.find(item => item.id == vendorId);
        if (vendor) {
          if (vendor.status == this.config.VENDOR_STATUS.ACTIVE) {
            if (customerNeedCert) {
              if (vendor.has_cert) {
                this.vendorsAssignedInfo.push({
                  isValid: true,
                  ...vendor
                });
                this.vendorsAssignedValid.push(vendorId);
              } else {
                this.vendorsAssignedInfo.push({
                  isValid: false,
                  id: vendorId,
                  errorMessage: this.config.CHECK_VENDOR_MESSAGE.INELIGIBLE_VENDOR
                });
                this.allVendorIsValid = false;
              }
            } else {
              this.vendorsAssignedInfo.push({
                isValid: true,
                ...vendor
              });
              this.vendorsAssignedValid.push(vendorId);
            }
          } else {
            this.allVendorIsValid = false;
            this.vendorsAssignedInfo.push({
              isValid: false,
              id: vendorId,
              errorMessage: this.config.CHECK_VENDOR_MESSAGE.INACTIVE
            });
          }
          // if (vendor.status == this.config.VENDOR_STATUS.ACTIVE) {
          //   this.vendorsAssignedInfo.push({
          //     isValid: true,
          //     ...vendor
          //   });
          //   this.vendorsAssignedValid.push(vendorId);
          // } else {
          //   this.allVendorIsValid = false;
          //   this.vendorsAssignedInfo.push({
          //     isValid: false,
          //     id: vendorId,
          //     errorMessage: this.config.CHECK_VENDOR_MESSAGE.INACTIVE
          //   });
          // }
        } else {
          this.allVendorIsValid = false;
          this.vendorsAssignedInfo.push({
            isValid: false,
            id: vendorId,
            errorMessage: this.config.CHECK_VENDOR_MESSAGE.INVALID
          });
        }
      }
    }, 500),
    requireCertifiedRecycleChange() {
      this.onChangeVendorAssigned();
    }
  }
};
</script>
<style lang="scss">
.flex-container {
  display: flex;
  align-items: center;
}

.no-padding {
  padding-left: 0;
}

.my-radio-buttons {
  transform: translateY(-9px) scale(0.6) translateX(17px);
  height: 10px;
}

.border-bottom {
  border-bottom: 1px solid gray;
}

.location-card {
  border: none;
}

.mx-auto {
  padding-right: 37px;
}

.close-btn {
  padding: 0;
}

.justify-end {
  display: flex;
  justify-items: end;
}

.top-padding {
  padding-top: 20px;
}

.mobile-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.cancel-btn {
  padding-top: 40px;
  display: flex;
  justify-items: stretch;
}

.stop-scroll {
  overflow-y: hidden;
}

.add-scroll {
  overflow-y: auto;
}

.autocomplete-field {
  font-weight: 400;
}

.exact-removal-date {
  //padding-top: 5% !important;
}

.check-box {
  margin-top: -11px;
  margin-bottom: -11px;
}

.order-checkbox {
  margin-top: -11px !important;
}

.uncommon-box {
  width: 100%;
  border-radius: 5px;
  padding: 15px 5px 15px 10px;
  min-height: 310px;
  margin-top: 10px;
  background-color: #f0f0f0;
}

.commodity-report-box {
  min-height: 120px;
}

.maxlength-box {
  display: flex;
  justify-content: space-between;
}

.card-subtitle-review {
  color: #5d5d5d;
  font-size: 1.25em;
  font-weight: bold;
}

.error-checkbox .v-input__control {
  border-color: 1px solid #e53935 !important;
}

.error-checkbox .v-label {
  color: #e53935 !important;
}

.gray-background {
  background-color: #f0f0f0;
}

.v-input--is-error .v-radio .v-icon {
  color: inherit !important;
}

.v-input--is-error .v-label {
  color: inherit !important;
}
</style>

<style scoped>
/*-------fix conflict css with bootstrap-vue--------- */
/* label {
  margin-bottom: .0rem !important;
}

a:hover {
  text-decoration: none !important;
  outline: 0;
}

small.text-muted {
  padding-left: 25px;
  color: #e53935 !important
}

.d-block.invalid-feedback {
  padding-left: 25px;
  color: #e53935 !important
} */

@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';

/* -------- end fix conflict css with bootstrap-vue------*/
.commodity-report-link {
  width: 100px !important;
  padding-left: 30px !important;
}

.text-gray {
  color: gray;
}

.bold {
  font-weight: bold;
}

.table-row-spacing td {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 16px;
}

.table-row-spacing td tr {
  padding-right: 10px;
}

b-form-tags {
  padding-left: 0px;
  margin-left: 0px;
}

.b-form-tags-error {
  border-color: #e53935 !important;
}

.b-form-tags-error:focus,
.b-form-tags-error:focus-within {
  border-color: #e53935 !important;
}

.b-form-tags {
  border: 1px solid #a2a2a2;
  background-color: #f0f0f0;
  outline: none;
  box-shadow: none;
}

.b-form-tags:focus,
.b-form-tags:focus-within {
  caret-color: rgba(0, 0, 0, 0.87) !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
  border: 2px solid rgba(0, 0, 0, 0.87) !important;
}

.b-form-tags:hover {
  caret-color: #5d5d5d;
  border-color: #5d5d5d;
  border: 1px solid #5d5d5d;
}
</style>
