<template>
<div>
	<div v-if="_company" class="flex row no-gutters pb-4">
		<v-img
			contain
      v-if="_company.icon_url"
			:src="_company.icon_url"
			:style="
				$vuetify.breakpoint.mdAndUp
					? 'max-height: 200px; max-width: 300px; margin: 0 auto;'
					: 'max-height: 100px; max-width: 300px; margin: 0 auto;'
			"
		></v-img>
		<v-col v-else cols="12" align="center" justify="center">
			<v-avatar tile>
				<v-img
					:style="
						$vuetify.breakpoint.mdAndUp
							? 'max-height: 200px; max-width: 300px; margin: 0 auto;'
							: 'max-height: 100px; max-width: 300px; margin: 0 auto;'
					"
				>
					<v-icon x-large>mdi-domain</v-icon>
				</v-img>
			</v-avatar>
			<span class="mx-auto">Upload your own logo</span>
		</v-col>
		<v-btn absolute left icon x-small v-if="edit" @click="$refs.file.click()">
			<v-icon>mdi-image-edit-outline</v-icon>
			<input
				ref="file"
				type="file"
				accept="*"
				@change="uploadImg($event)"
				style="display: none"
			/>
		</v-btn>
	</div>
</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
	props: {
		edit: Boolean,
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			_company: "company/getCompany",
		}),
	},
	methods: {
		...mapActions({
			_uploadLogo: "company/uploadLogo",
		}),
		uploadImg(e) {
			this._uploadLogo({ file: e.target.files[0], type: "company_icon" });
		},
	},
};
</script>
