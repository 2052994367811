<template>
  <div>
    <mobile v-if="$vuetify.breakpoint.smAndDown" />
    <desktop v-if="$vuetify.breakpoint.mdAndUp" />
  </div>
</template>
<script>
import Mobile from "./Mobile";
import Desktop from "./Desktop";
export default {
  components: {
    Mobile,
    Desktop,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
<style scoped></style>
