import { create, upload } from '../../api';

// initial job state
const state = {
  loading: false,
  file: undefined,
  jobFile: undefined,
  error: undefined,
  showBulkUploadDialog: false
};

// getters
const getters = {
  getFile: state => {
    return state.file;
  },
  getJobFile: state => {
    return state.jobFile;
  },
  loading: state => {
    return state.loading;
  },
  getShowBulkUploadDialog: state => {
    return state.showBulkUploadDialog;
  }
};

const route = '/media/upload';

// actions
const actions = {
  createJob: async ({ commit }, payload) => {
    try {
      return create(route, payload)
        .then(res => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Create failed', {
            root: true
          });
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
    }
  },
  uploadJobFile: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    const reader = new FileReader();
    reader.readAsDataURL(payload.file);
    try {
      reader.onload = () =>
        upload(route, payload.original_name, reader.result).then(res => {
          commit(
            'notification/setNotificationMsg',
            'File/image uploaded successfully',
            {
              root: true
            }
          );
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('setJobFile', {
            file: res.data.data,
            type: payload.type,
            name: payload.original_name
          });
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotificationMsg', 'Error uploading file/image', {
        root: true
      });
      commit('toggleLoading', false);
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  },

  uploadFile: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    const reader = new FileReader();
    reader.readAsDataURL(payload.file);
    try {
      reader.onload = () =>
        upload(route, payload.original_name, reader.result).then(res => {
          commit(
            'notification/setNotificationMsg',
            'File/image uploaded successfully',
            {
              root: true
            }
          );
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('setFile', { file: res.data.data, type: payload.type });
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotificationMsg', 'Error uploading file/image', {
        root: true
      });
      commit('toggleLoading', false);
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  }
};

// mutations
const mutations = {
  setJobFile: (state, job) => {
    console.log('setJobFile', job);
    console.log('setJobFile state', state);
    state.jobFile = job;
  },
  setFile: (state, file) => {
    state.file = file;
  },
  setError: (state, err) => {
    state.error = err;
  },
  toggleLoading: (state, loading) => {
    state.loading = loading;
  },
  setShowBulkUploadDialog: (state, bulkUploadPopup) => {
    state.showBulkUploadDialog = bulkUploadPopup;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
