<template>
  <v-card class="pa-4" flat>
    <v-row no-gutters>
      <v-spacer />
      <create-site :edit="editSite" />
      <v-btn rounded height="48" v-if="$vuetify.breakpoint.mdAndUp" color="secondary" @click.native="_toggleCreate(true)">
        <v-icon>mdi-plus</v-icon>
        Add New Site
      </v-btn>
      <v-btn
        fab
        absolute
        right
        top
        color="secondary"
        class="white--text my-2"
        @click="_toggleCreate(true)"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-icon>mdi-domain-plus</v-icon>
      </v-btn>
    </v-row>
    <site-table tableTitle="Sites List" @editSite="editSiteTrigger($event)" />
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';
import SiteTable from '@/components/site/Table.vue';
import CreateSite from '@/components/site/Create.vue';
export default {
  components: {
    SiteTable,
    CreateSite
  },
  data() {
    return {
      addSite: false,
      editSite: false
    };
  },
  methods: {
    ...mapActions({
      _toggleCreate: 'sites/toggleCreate'
    }),
    editSiteTrigger(site) {
      this.addSite = true;
      this.editSite = true;
    }
  }
};
</script>
