<template>
  <v-card v-if="$vuetify.breakpoint.mdAndUp" no-gutters flat class="pl-2">
    <v-card-title v-if="!isHideTitle" class="pb-6 mb-0 ml-0 pl-0 ">Services and Prices</v-card-title>
    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandRecyclingChanges">
        <span :class="{ mdi: true, 'mdi-chevron-up': true, 'rotate-180': isExpandedRecyclingChanges }"></span>
        <h3 class="pt-2">Recycling Charges</h3>
      </div>
      <div :class="{ 'expandable-content': true, show: isExpandedRecyclingChanges }">
        <v-card no-gutters flat class="table-data">
          <v-form ref="recycling-charge-form" lazy-validation v-bind:disabled="isDisableAllForm">
            <v-row no-gutters class="table-header">
              <v-col cols="12" sm="3" class="header-item">
                <span></span>
              </v-col>
              <v-col cols="12" sm="3" class="header-item">
                <span></span>
              </v-col>
              <v-col cols="12" sm="3" class="header-item">
                <span>Unit Price</span>
              </v-col>
              <v-col cols="12" sm="3" class="header-item">
                <span>Unit</span>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              :class="['body-row', item.order % 2 == 1 ? 'background-gray' : '']"
              v-for="(item, key) in sortedRecyclingChanges"
              :key="key"
            >
              <v-col flat cols="12" sm="3" class="body-item-title">
                <span>{{ item.title }}</span>
              </v-col>
              <v-col cols="12" sm="3" class="body-item pt-0 pb-0 mt-0 mb-0">
                <v-radio-group v-model="item.answer" row @change="handleInputChange(item, 'answer')">
                  <v-radio color="success" label="Yes" :value="true"></v-radio>
                  <v-radio color="success" label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="3" class="body-item">
                <v-text-field
                  v-show="!!item.answer"
                  class="custom-icon custom-background"
                  outlined
                  v-model="item.unit_price"
                  hide-details
                  dense
                  prepend-inner-icon="mdi-currency-usd"
                  v-money="money"
                  type="money"
                  maxlength="10"
                  @blur="
                    formatPrice(item, 'unit_price');
                    debouncedOnChangeCompanyInfo();
                  "
                ></v-text-field>
                <v-text-field
                  v-show="!item.answer"
                  disabled
                  outlined
                  hide-details
                  dense
                  :value="null"
                  class="custom-background"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="body-item">
                <v-select
                  :disabled="!item.answer"
                  outlined
                  v-model="item.unit"
                  :items="units"
                  item-text="unit"
                  item-value="value"
                  dense
                  hide-details
                  @change="debouncedOnChangeCompanyInfo()"
                  class="custom-background"
                ></v-select>
                <!-- <v-select
                  disabled
                  v-show="!item.answer"
                  outlined
                  :items="units"
                  item-text="unit"
                  item-value="value"
                  dense
                  hide-details
                ></v-select> -->
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </div>

    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandRecyclingService">
        <span :class="{ mdi: true, 'mdi-chevron-up': true, 'rotate-180': isExpandedRecyclingService }"></span>
        <h3 class="pt-2">Recycling Services</h3>
      </div>
      <div :class="{ 'expandable-content': true, show: isExpandedRecyclingService }">
        <div>
          <v-row no-gutters>
            <p class="subtitle">Select all services you can provide and your unit pricing.</p>
          </v-row>
        </div>
        <v-card no-gutters flat class="table-data">
          <v-form ref="recycling-services-form" lazy-validation v-bind:disabled="isDisableAllForm">
            <v-row no-gutters class="table-header">
              <v-col cols="12" sm="4" class="header-item">
                <!-- <span>Service</span> -->
              </v-col>
              <v-col cols="12" sm="2" class="header-item"></v-col>
              <v-col cols="12" sm="3" class="header-item">
                <span>Unit Price</span>
              </v-col>
              <v-col cols="12" sm="3" class="header-item">
                <span>Unit</span>
              </v-col>
            </v-row>
            <template v-for="(service, key) in Object.values(sortedRecyclingService)">
              <v-row v-if="service.content.offsite" :key="key + '-offsite'" no-gutters class="body-row">
                <v-col cols="12" sm="3" rowspan="2" class="body-item-title service-title-offsite">
                  <span>{{ service.title }}</span>
                </v-col>
                <v-col cols="12" sm="1" class="body-item background-gray">
                  <span class="off-site-title">Offsite</span>
                </v-col>
                <v-col cols="12" sm="2" class="body-item background-gray d-flex align-center">
                  <v-switch
                    class="mt-0"
                    v-model="service.content.offsite.answer"
                    inset
                    color="success"
                    label=""
                    value="true"
                    hide-details
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="3" class="body-item background-gray">
                  <v-text-field
                    v-show="!!service.content.offsite.answer"
                    class="custom-background custom-icon"
                    outlined
                    v-model="service.content.offsite.unit_price"
                    hide-details
                    dense
                    v-money="money"
                    type="money"
                    maxlength="10"
                    prepend-inner-icon="mdi-currency-usd"
                    @blur="formatPrice(service.content.offsite, 'unit_price'), debouncedOnChangeCompanyInfo()"
                  ></v-text-field>

                  <v-text-field
                    disabled
                    v-show="!service.content.offsite.answer"
                    outlined
                    hide-details
                    dense
                    :value="null"
                    class="custom-background"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="body-item background-gray">
                  <v-select
                    :disabled="!service.content.offsite.answer"
                    outlined
                    v-model="service.content.offsite.unit"
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                    class="custom-background"
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-select>

                  <!-- <v-select
                    disabled
                    v-show="!service.content.offsite.answer"
                    outlined
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                  ></v-select> -->
                </v-col>
              </v-row>
              <v-row v-if="service.content.onsite" :key="key" no-gutters class="body-row">
                <v-col cols="12" sm="3" rowspan="2" class="body-item-title service-title-onsite">
                  <span v-if="!service.content.offsite">{{ service.title }}</span>
                </v-col>
                <v-col cols="12" sm="1" class="body-item">
                  <span class="on-site-title">Onsite</span>
                </v-col>
                <v-col cols="12" sm="2" class="body-item">
                  <v-switch
                    class="mt-0"
                    v-model="service.content.onsite.answer"
                    inset
                    color="success"
                    label=""
                    :value="true"
                    hide-details
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="3" class="body-item">
                  <v-text-field
                    v-show="!!service.content.onsite.answer"
                    class="custom-icon"
                    outlined
                    v-model="service.content.onsite.unit_price"
                    hide-details
                    dense
                    v-money="money"
                    type="money"
                    maxlength="10"
                    prepend-inner-icon="mdi-currency-usd"
                    @blur="formatPrice(service.content.onsite, 'unit_price'), debouncedOnChangeCompanyInfo()"
                  ></v-text-field>

                  <v-text-field v-show="!service.content.onsite.answer" disabled outlined hide-details dense :value="null"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="body-item">
                  <v-select
                    :disabled="!service.content.onsite.answer"
                    outlined
                    v-model="service.content.onsite.unit"
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-select>

                  <!-- <v-select
                    v-show="!service.content.onsite.answer"
                    disabled
                    outlined
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                  ></v-select> -->
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-card>
      </div>
    </div>

    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandLogistics">
        <span :class="{ mdi: true, 'mdi-chevron-up': true, 'rotate-180': isExpandedLogistics }"></span>
        <h3 class="pt-2">Logistics</h3>
      </div>
      <div :class="{ 'expandable-content': true, show: isExpandedLogistics }">
        <v-card no-gutters flat class="table-data">
          <v-form ref="form" lazy-validation v-bind:disabled="isDisableAllForm">
            <v-row no-gutters class="table-header" v-if="false">
              <v-col cols="12" sm="3" class="header-item">
                <span>Service</span>
              </v-col>
              <v-col cols="12" sm="2" class="header-item">
                <span>Answer</span>
              </v-col>
              <v-col cols="12" sm="7" class="header-item">
                <span>Note</span>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              :class="['body-row', item.order % 2 == 1 ? 'background-gray' : '']"
              v-for="(item, key) in sortedLogistics"
              :key="key"
            >
              <v-col cols="12" sm="3" class="body-item-title">
                <span>{{ item.title }}</span>
              </v-col>
              <v-col cols="12" sm="3" class="body-item">
                <v-radio-group v-model="item.answer" row @change="debouncedOnChangeCompanyInfo()">
                  <v-radio color="success" label="Yes" :value="true"></v-radio>
                  <v-radio color="success" label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" class="body-item">
                <v-text-field
                  label="Note:"
                  @change="debouncedOnChangeCompanyInfo()"
                  class="pl-4"
                  v-model="item.note"
                  hide-details
                  dense
                  maxlength="120"
                  :disabled="item.answer == null || !!item.answer == false"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </div>
  </v-card>
  <v-card flat v-else>
    <v-card-title v-if="!isHideTitle" class="pb-6 mb-0 ml-0 pl-0 ">Services and Prices</v-card-title>

    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandRecyclingChanges">
        <span :class="{ mdi: true, 'mdi-chevron-up': true, 'rotate-180': isExpandedRecyclingChanges }"></span>
        <h3 class="pt-2">Recycling Charges</h3>
      </div>
      <div :class="{ 'expandable-content': true, show: isExpandedRecyclingChanges }">
        <v-card no-gutters flat class="table-data">
          <v-form ref="recycling-charge-form" lazy-validation v-bind:disabled="isDisableAllForm">
            <v-row
              no-gutters
              :class="['body-row', item.order % 2 == 1 ? 'border-bottom-gray background-gray' : 'background-gray']"
              v-for="(item, key) in sortedRecyclingChanges"
              :key="key"
            >
              <v-col flat cols="12" sm="12" class="body-item-title">
                <span>{{ item.title }}</span>
              </v-col>
              <v-col cols="12" sm="12" class="body-item pt-0 pb-0 mt-0 mb-0">
                <v-radio-group v-model="item.answer" row @change="handleInputChange(item, 'answer')">
                  <v-radio color="success" label="Yes" :value="true"></v-radio>
                  <v-radio color="success" label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>

              <!-- <v-row no-gutters> -->
              <v-col flat cols="5" sm="6" class="body-item">
                <span>Unit Price</span>
              </v-col>
              <v-col flat cols="7" sm="6" class="body-item">
                <v-text-field
                  v-show="!!item.answer"
                  class="custom-icon"
                  outlined
                  v-model="item.unit_price"
                  hide-details
                  dense
                  prepend-inner-icon="mdi-currency-usd"
                  v-money="money"
                  type="money"
                  maxlength="10"
                  @blur="
                    formatPrice(item, 'unit_price');
                    debouncedOnChangeCompanyInfo();
                  "
                ></v-text-field>

                <v-text-field disabled v-show="!item.answer" outlined hide-details dense :value="null"></v-text-field>
              </v-col>
              <!-- </v-row> -->

              <!-- <v-row no-gutters> -->
              <v-col flat cols="5" sm="6" class="body-item">
                <span>Unit</span>
              </v-col>
              <v-col cols="7" sm="6" class="body-item">
                <v-select
                  :disabled="!item.answer"
                  outlined
                  v-model="item.unit"
                  :items="units"
                  item-text="unit"
                  item-value="value"
                  dense
                  hide-details
                  @change="debouncedOnChangeCompanyInfo()"
                ></v-select>

                <!-- <v-select
                  v-show="!item.answer"
                  disabled
                  outlined
                  :items="units"
                  item-text="unit"
                  item-value="value"
                  dense
                  hide-details
                ></v-select> -->
              </v-col>
              <!-- </v-row> -->
              <v-col cols="12" sm="12" v-if="key != sortedRecyclingChanges.length - 1">
                <hr class="break-line" />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </div>

    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandRecyclingService">
        <span :class="{ mdi: true, 'mdi-chevron-up': true, 'rotate-180': isExpandedRecyclingService }"></span>
        <h3 class="pt-2">Recycling Services</h3>
      </div>
      <div :class="{ 'expandable-content': true, show: isExpandedRecyclingService }">
        <div>
          <v-row no-gutters>
            <p class="subtitle">Select all services you can provide and your unit pricing.</p>
          </v-row>
        </div>
        <v-card no-gutters flat class="table-data">
          <v-form ref="recycling-services-form" lazy-validation v-bind:disabled="isDisableAllForm">
            <template v-for="(service, key) in Object.values(sortedRecyclingService)">
              <v-row v-if="service.content.offsite" :key="key + '-offsite'" no-gutters class="body-row body-row-gray">
                <v-col cols="12" sm="12" rowspan="2" class="body-item-title">
                  <span>{{ service.title }}</span>
                </v-col>
                <v-col cols="5" sm="6" class="body-item background-gray">
                  <span class="off-site-title">Offsite</span>
                </v-col>
                <v-col cols="7" sm="6" class="body-item background-gray d-flex align-center justify-center">
                  <v-switch
                    class="mt-0"
                    v-model="service.content.offsite.answer"
                    inset
                    color="success"
                    label=""
                    value="true"
                    hide-details
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-switch>
                </v-col>
                <v-col cols="5" sm="6" class="body-item background-gray">
                  <span>Unit Price</span>
                </v-col>
                <v-col cols="7" sm="6" class="body-item background-gray">
                  <v-text-field
                    v-show="!!service.content.offsite.answer"
                    class="custom-background custom-icon"
                    outlined
                    v-model="service.content.offsite.unit_price"
                    hide-details
                    dense
                    v-money="money"
                    type="money"
                    maxlength="10"
                    prepend-inner-icon="mdi-currency-usd"
                    @blur="formatPrice(service.content.offsite, 'unit_price'), debouncedOnChangeCompanyInfo()"
                  ></v-text-field>

                  <v-text-field disabled v-show="!service.content.offsite.answer" outlined hide-details dense :value="null"></v-text-field>
                </v-col>

                <v-col cols="5" sm="6" class="body-item background-gray">
                  <span>Unit</span>
                </v-col>
                <v-col cols="7" sm="6" class="body-item background-gray">
                  <v-select
                    :disabled="!service.content.offsite.answer"
                    outlined
                    v-model="service.content.offsite.unit"
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                    class="custom-background"
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-select>

                  <!-- <v-select
                    v-show="!service.content.offsite.answer"
                    disabled
                    outlined
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                  ></v-select> -->
                </v-col>
              </v-row>
              <v-row v-if="service.content.onsite" :key="key" no-gutters class="body-row-white">
                <v-col v-if="!service.content.offsite" cols="12" sm="12" rowspan="2" class="body-item-title service-title-onsite">
                  <span>{{ service.title }}</span>
                </v-col>
                <v-col cols="5" sm="6" class="body-item">
                  <span class="on-site-title">Onsite</span>
                </v-col>
                <v-col cols="7" sm="6" class="body-item justify-center">
                  <v-switch
                    class="mt-0"
                    v-model="service.content.onsite.answer"
                    inset
                    color="success"
                    label=""
                    :value="true"
                    hide-details
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-switch>
                </v-col>
                <v-col cols="5" sm="6" class="body-item">
                  <span>Unit Price</span>
                </v-col>
                <v-col cols="7" sm="6" class="body-item">
                  <v-text-field
                    v-show="!!service.content.onsite.answer"
                    class="custom-icon"
                    outlined
                    v-model="service.content.onsite.unit_price"
                    hide-details
                    dense
                    v-money="money"
                    type="money"
                    maxlength="10"
                    prepend-inner-icon="mdi-currency-usd"
                    @blur="formatPrice(service.content.onsite, 'unit_price'), debouncedOnChangeCompanyInfo()"
                  ></v-text-field>

                  <v-text-field disabled v-show="!service.content.onsite.answer" outlined hide-details dense :value="null"></v-text-field>
                </v-col>
                <v-col cols="5" sm="6" class="body-item">
                  <span>Unit</span>
                </v-col>
                <v-col cols="7" sm="6" class="body-item">
                  <v-select
                    :disabled="!service.content.onsite.answer"
                    outlined
                    v-model="service.content.onsite.unit"
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                    @change="debouncedOnChangeCompanyInfo()"
                  ></v-select>

                  <!-- <v-select
                    disabled
                    v-show="!service.content.onsite.answer"
                    outlined
                    :items="units"
                    item-text="unit"
                    item-value="value"
                    dense
                    hide-details
                  ></v-select> -->
                </v-col>

                <v-col cols="12" sm="12" v-if="key != sortedRecyclingService.length - 1">
                  <hr class="break-line" />
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-card>
      </div>
    </div>

    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandLogistics">
        <span :class="{ mdi: true, 'mdi-chevron-up': true, 'rotate-180': isExpandedLogistics }"></span>
        <h3 class="pt-2">Logistics</h3>
      </div>
      <div :class="{ 'expandable-content': true, show: isExpandedLogistics }">
        <v-card no-gutters flat class="table-data">
          <v-form ref="form" lazy-validation v-bind:disabled="isDisableAllForm">
            <v-row
              no-gutters
              :class="['body-row', item.order % 2 == 1 ? 'background-gray' : 'background-gray']"
              v-for="(item, key) in sortedLogistics"
              :key="key"
            >
              <v-col cols="12" sm="3" class="body-item-title">
                <span>{{ item.title }}</span>
              </v-col>
              <v-col cols="12" sm="3" class="body-item">
                <v-radio-group v-model="item.answer" row @change="debouncedOnChangeCompanyInfo()">
                  <v-radio color="success" label="Yes" :value="true"></v-radio>
                  <v-radio color="success" label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" class="body-item">
                <v-textarea
                  rows="2"
                  style=" max-height:60px;padding-bottom: 10px;"
                  label="Note: "
                  @change="debouncedOnChangeCompanyInfo()"
                  v-model="item.note"
                  hide-details
                  class="pb-4 mb-2"
                  maxlength="120"
                  :disabled="item.answer == null || !!item.answer == false"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" v-if="key != sortedLogistics.length - 1">
                <hr class="break-line" />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import config from '@/config';
import _ from 'lodash';
import { mask } from 'vue-the-mask';
// import '../../utils/currency-format'
import Vue from 'vue';

export default {
  props: {
    isUpdateWhenChange: {
      type: Boolean,
      default: false
    },
    isHideTitle: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    await this._auToSetUserAndCompanyInfo();
    this.recycling_changes = this._company.services_and_prices.recycling_changes;
    this.recycling_service = this._company.services_and_prices.recycling_service;
    this.logistics = this._company.services_and_prices.logistics;

    this.isDisableAllForm = this._user.type_id != this.config.USER_TYPE.admin;
  },
  watch: {},
  data() {
    return {
      config: config,
      valid: false,
      units: config.ListOfUnits,
      recycling_changes: {},
      recycling_service: {},
      logistics: {},
      initializedRecyclingChanges: false,
      initializedRecyclingService: false,
      initializedLogistics: false,
      isDisableAllForm: false,
      rules: {
        positiveNumber: v => Math.sign(v) == 1 || 'Must be a positive number.'
      },
      isExpandedRecyclingChanges: true,
      isExpandedRecyclingService: true,
      isExpandedLogistics: true,
      money: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  computed: {
    sortedRecyclingChanges() {
      return Object.values(this.recycling_changes).sort((a, b) => a.order - b.order);
    },
    sortedRecyclingService() {
      return Object.values(this.recycling_service).sort((a, b) => a.order - b.order);
    },
    sortedLogistics() {
      return Object.values(this.logistics).sort((a, b) => a.order - b.order);
    },
    ...mapGetters({
      _company_id: 'company/getCompanyId',
      _file: 'uploads/getFile',
      _loading: 'company/getLoading',
      _uploading: 'uploads/loading',
      _company: 'company/getCompany',
      _user: 'user/getUser'
    })
  },
  methods: {
    ...mapActions({
      _updateCompany: 'company/updateCompany',
      _uploadFile: 'uploads/uploadFile',
      _getCompany: 'company/getCurrent',
      _auToSetUserAndCompanyInfo: 'authenticate/auToSetUserAndCompanyInfo',
      _checkToken: 'authenticate/checkToken',
      _setUser: 'user/setUser'
    }),
    ...mapMutations({
      _setCompany: 'company/setCompany'
      //_setOriginalCompany: 'company/setOriginalCompany'
    }),
    isNumber(event) {
      var v = event.target.value + event.key;
      var f = v.split('.');
      //console.log("event " +v ,event);
      if ((!/\d/.test(event.key) && event.key !== '.') || (f.length > 1 && f[1].length > 2)) return event.preventDefault();
    },
    async updateCompanyInfo() {
      let payload = this._company;
      payload.services_and_prices = {
        recycling_changes: this.recycling_changes,
        recycling_service: this.recycling_service,
        logistics: this.logistics
      };

      if (
        Object.keys(payload.services_and_prices.recycling_changes).length === 0 ||
        Object.keys(payload.services_and_prices.recycling_service).length === 0 ||
        Object.keys(payload.services_and_prices.logistics).length === 0
      ) {
        alert('An error occurred. Please try again');
        window.location.reload();
      }

      let res = await this._updateCompany(payload);
      //this._setCompany(res.data);
      //this._setOriginalCompany(payload);
    },
    formatPrice(content, field) {
      if (content[field] !== null && content[field] !== '') {
        content[field] = parseFloat(content[field]).toFixed(2);
      }
    },
    async onChangeCompanyInfo() {
      if (this.isUpdateWhenChange) {
        await this.updateCompanyInfo();
      }
    },
    handleInputChange(item, field) {
      if (field === 'answer') {
        if (item.answer) {
          // item.unit = 0.0;
          //item.unit_price = '0.00';
        } else {
          // item.unit = null;
          // item.unit_price = null;
        }
      }
      this.debouncedOnChangeCompanyInfo();
    },
    toggleExpandRecyclingChanges() {
      this.isExpandedRecyclingChanges = !this.isExpandedRecyclingChanges;
    },
    toggleExpandRecyclingService() {
      this.isExpandedRecyclingService = !this.isExpandedRecyclingService;
    },
    toggleExpandLogistics() {
      this.isExpandedLogistics = !this.isExpandedLogistics;
    }
  },
  created() {
    this.debouncedOnChangeCompanyInfo = _.debounce(this.onChangeCompanyInfo, 600);
  }
};
</script>
<style scoped>
.table-data {
  border: 3px solid rgb(208, 204, 204);
}
.header-item {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  font-weight: bold;
  align-items: center;
  border: 1px solid #ddd;
  justify-content: center;
  display: flex;
}

.body-row {
}

.body-item {
  padding: 10px 17px 10px 17px !important;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body-item-title {
  padding: 10px 17px 10px 17px !important;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  background-color: white;
}

.hight-light-row {
  background-color: #babec8 !important;
}

.table-header {
}

.hight-light-row {
  background-color: #f9f9f9;
}

.service-title-onsite {
  padding-left: 15px !important;
  border: 1px solid #ddd;
  border-top: 0px !important;
  display: flex;
  align-items: end;
}

.service-title-offsite {
  padding-left: 15px !important;
  border: 1px solid #ddd;
  border-bottom: 0px !important;
  display: flex;
  align-items: start;
}

.body-item-title.service-title-offsite.col-sm-3.col-12 {
  align-items: self-end;
  margin-bottom: -20px;
  z-index: 1;
}

@media only screen and (max-width: 960px) {
  .body-item-title.service-title-offsite.col-sm-3.col-12 {
    align-items: center;
    margin-bottom: 20px;
  }
}

.body-item-title.service-title-onsite.col-sm-3.col-12 {
  align-items: center;
}

.body-item-title >>> .service-title-offsite {
  background-color: red !important;
}

.background-gray {
  background-color: #dee1e6;
}

.custom-background .v-input__control .v-input__slot {
  background-color: white !important;
}

.custom-background >>> .v-input__slot {
  background-color: white !important;
}

.off-site-title {
  padding: 8px;
  background-color: #143e55;
  border-radius: 8px;
  color: white;
}

.on-site-title {
  padding: 8px;
  /* background-color: #143e55; */
  border-radius: 8px;
  border: 1px solid #143e55;
}

.custom-icon >>> .v-icon {
  font-size: 20px;
}

.custom-icon >>> .v-input__prepend-inner {
  padding-right: 0px;
  margin-right: 0px;
}
.custom-icon >>> .v-text-field__slot {
  margin-left: -6px;
}

.expandable-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.expandable-header .mdi {
  font-size: 24px; /* Larger font size for the arrow */
  font-weight: bold; /* Bolder weight for the arrow */
  margin-right: 8px;
  transition: transform 0.3s;
}
.expandable-header .mdi.rotate-180 {
  transform: rotate(180deg);
}
.expandable-content {
  display: none;
  margin-left: 16px;
}
.expandable-content.show {
  display: block;
}

.v-input--is-label-active >>> .v-input--switch__thumb {
  color: #f9f9f9 !important;
}
.v-input--is-label-active >>> .v-input--switch__track {
  opacity: 1;
}

@media (max-width: 600px) {
  .body-item-title {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center !important;
    text-align: center;
  }
  .body-item {
    /* background-color: #dee1e6; */
    justify-content: start;
    /* border: unset !important; */
  }

  .body-row-white {
    background-color: white !important;
  }
  .body-row-gray {
    background-color: #dee1e6 !important;
  }

  .body-item-mobile {
    background-color: #dee1e6;
    justify-content: start;
  }

  .break-line {
    border-bottom: 4px solid rgb(194, 188, 188);
    border-top: 3px solid rgb(194, 188, 188);
  }

  .body-item >>> .v-text-field--outlined {
    background-color: white !important;
  }
}
</style>
