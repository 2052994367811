<template>
  <div>
    <div v-if="_loading && !_users">
      <v-skeleton-loader ref="skeleton" :boilerplate="false" type="table" class="mx-0"></v-skeleton-loader>
    </div>
    <div v-else>
      <div class="titleBar">
        <v-card-title
          v-if="$vuetify.breakpoint.mdAndUp"
          style="font-size:26px;"
          class="save--text"
          v-html="tableTitle"
        ></v-card-title>

        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-text-field
          v-model="search"
          style="max-width: 300px; margin: 16px;"
          label="Search Users"
          :append-icon="search ? `mdi-close` : `mdi-magnify`"
          @keyup="throttledMethod()"
          @click:append="clearSearch()"
        />
      </div>
      <v-slide-x-transition>
        <v-data-table
          v-if="_users && _users.length > 0"
          height="calc(100vh - 350px)"
          :search="options.search"
          :options.sync="options"
          :server-items-length="total"
          no-data-text="No users right now, create one!"
          :headers="headers"
          :items="_users"
          :loading="_loading"
          multi-sort
          @update:options="getUsers()"
          @click:row="handleClick"
        >
          <template #item.user_type="{ item }">
            <div class="d-flex align-center">
              <div class="icon-container" style="min-width: 24px; display: flex; justify-content: center;">
                <v-icon
                  v-if="item.user_type === config.USER_TYPE.admin || item.user_type === config.USER_TYPE.standard"
                  color="green"
                  small
                >
                  mdi-check-circle
                </v-icon>
              </div>
              <div>
                <span v-if="item.user_type === config.USER_TYPE.admin">Admin</span>
                <span v-else-if="item.user_type === config.USER_TYPE.standard">Standard</span>
                <span v-else-if="item.user_type === config.USER_TYPE.inactive">Inactive</span>
              </div>
            </div>
          </template>
        </v-data-table>

        <v-banner v-else single-line transition="slide-y-transition">
          Sorry No Users Found
          <template v-slot:actions="{}">
            <v-btn text color="primary" :loading="_loading" @click="getUsers()">Retry</v-btn>
          </template>
        </v-banner>
      </v-slide-x-transition>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Options from '@/mixins/Options';
import config from '@/config';
export default {
  mixins: [Options],
  props: {
    tableTitle: String
  },
  data() {
    return {
      config: config,
      //userTypes: USER_TYPE,
      search: undefined,
      editingUser: undefined,
      editUser: undefined,
      page: 1,
      limit: 15,
      OpenUsers: 'Open Users',
      filterStart: null,
      filterEnd: null,
      weightRange: [null, null],
      palletRange: [null, null],
      saved: 'All',
      specSearch: null,
      locationSearch: null,
      mileRange: [null, null]
    };
  },
  computed: {
    ...mapGetters({
      _editing: 'user/editing',
      _users: 'user/getUsers',
      _loading: 'user/loading',
      _companyId: 'company/getCompanyId',
      _newUser: 'user/getNewTeamUser',
      _editedUser: 'user/getEditedUser',
      _delUser: 'user/deletedUser',
      _user: 'user/getUser'
    }),
    total() {
      return this._users[0] ? parseInt(this._users[0].total) : 0;
    },
    // headers() {
    //   if (this._users) {
    //     const keys = Object.keys(this._users[0]);
    //     var headers = [];
    //     const allowKeys = ['user_id', 'full_name', 'email', 'phone', 'user_type'];
    //     keys.forEach(i => {
    //       const index = allowKeys.indexOf(i);
    //       if (index !== -1) {
    //         const obj = {
    //           text: i == 'full_name' ? 'NAME' : i.replace(/_/g, ' ').toUpperCase(),
    //           value: i,
    //           sortable: true,
    //           filterable: true,
    //           divider: false,
    //           align: 'start',
    //           width: i === 'id' ? '75' : '200',
    //           index: index
    //         };
    //         headers.push(obj);
    //       }
    //     });

    //     headers.sort((a, b) => a.index - b.index);

    //     // headers.forEach(header => {
    //     //   delete header.order;
    //     // });

    //     return headers;
    //   } else {
    //     return undefined;
    //   }
    // },
    headers() {
      if (this._users) {
        const keys = Object.keys(this._users[0]);
        const allowKeys = ['user_id', 'full_name', 'email', 'phone', 'user_type'];

        // Lọc các keys cho phép và tạo headers
        const headers = allowKeys
          .filter(key => keys.includes(key))
          .map(key => ({
            text: key === 'full_name' ? 'NAME' : key.replace(/_/g, ' ').toUpperCase(),
            value: key,
            sortable: true,
            filterable: true,
            divider: false,
            align: 'start',
            width: key === 'id' ? '75' : '200',
            index: allowKeys.indexOf(key)
          }));

        headers.sort((a, b) => a.index - b.index);

        return headers;
      } else {
        return undefined;
      }
    },
    pagination() {
      return {
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10
      };
    },
    compId() {
      return this._companyId || 0;
    }
  },
  watch: {
    _editing() {
      if (!this._editing) {
        this._getUsers(this.options);
      }
    },
    _delUser() {
      if (Object.keys(this._delUser).length > 0) {
        this._getUsers(this.options);
      }
    },
    _newUser() {
      if (this._newUser.email) {
        this._getUsers(this.options);
      }
    },
    compId() {
      this._getUsers(this.options);
    }
  },
  mounted() {
    this.getUsers();
    this.throttledMethod = _.debounce(() => {
      this.getUsers();
    }, 1000);
  },
  methods: {
    ...mapActions({
      _getUsers: 'user/getAll',
      _toggleEditing: 'user/toggleEditing'
    }),
    ...mapMutations({
      _setLoading: 'user/toggleLoading'
    }),
    clearSearch() {
      this.search = undefined;
      this.getUsers();
    },
    getUsers() {
      let options = this.options;
      options.search = this.search;
      this._getUsers(options);
    },
    handleClick(value) {
      if (this._user.type_id === 1) {
        this._toggleEditing({ toggle: true, user: value });
      }
    }
  }
};
</script>

<style scoped>
.titleBar {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.highlighted-button {
  width: 112px;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
}
.gray-button {
  color: #5d5d5d;
  background-color: transparent !important;
  height: 16px;
  font-size: 14px;
}
.mobile-button {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
  color: #ffffff !important;
}
</style>
