<template>
  <v-card flat>
    <v-card-subtitle class="caption text-left px-0 mt-0 mb-0 pb-0 pt-0"
      >This page is subject to and incorporates by reference the Recycle Global
      Exchange Transaction Terms and Conditions and Terms of Services
      Agreements, and constitutes part of a Statement of Work described
      therein.  
      </v-card-subtitle
    >
    <v-row no-gutters class="pa-0 ma-0">
      <v-checkbox v-model="accepted" :disabled="disabled_chk" class="pa-0 mt-3 ml-0" dense inverse>
        <template v-slot:label>
          <div>
            I accept the 
            <a
              style="color: green"
              target="_blank"
              href="https://www.recyclegx.com/wp-content/uploads/2021/07/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf"
              @click.stop
            >
              Terms of Service.
            </a>
          </div>
        </template>
      </v-checkbox>

      <!-- <v-checkbox
        class="pa-0 mt-3 ml-0"
        dense
        inverse
        :label="`I Accept`"
        v-model="accepted"
      />
      <v-spacer />
      <v-btn
        class="mx-0 mt-3 pa-0"
        download
        text
        small
        :href="`/pdf/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf`"
        ><v-icon small class="mr-1">mdi-download</v-icon> Terms &
        Conditions</v-btn
      > -->
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    disabled_chk: String, 
  },
  data() {
    return {
      accepted: false 
    };
  },
  watch: {
    accepted() {
      this.$emit("accepted");
    },
    // disabled_chk() {
    //   this.$emit("disabled_chk");
    // },
  },
};
</script>
