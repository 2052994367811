<template>
  <v-card flat>
    <v-card outlined style="min-height: 200px; height: calc(100vh / 2)">
      <pdf-viewer
        v-if="isProfile"
        :interactable="interactable"
        :docPath="
          require('@/assets/pdf/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf')
        "
        @scrollToBottom="$emit('scrollToBottom')"
        @agree="$emit('agree')"
      />
      <iframe
        v-else
        height="100%"
        width="100%"
        :src="
          require('@/assets/pdf/RGX-End-User-Terms-and-Conditions-Services-Agreement-21-7-1.pdf')
        "
      />
    </v-card>
  </v-card>
</template>

<script>
import PdfViewer from '@/components/common/PdfViewer.vue';

export default {
  components: {
    PdfViewer
  },
  props: {
    isProfile: Boolean,
    interactable: Boolean
  }
};
</script>
