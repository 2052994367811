import * as config from '../config/index';
import moment from 'moment';

const assetTypeLabelReview = config.default.ASSET_TYPE_LABEL_REVIEW;
const functionKeys = config.default.functionKeys;
const dateFormatTypes = config.default.dateFormatTypes;

export function getReviewValueByValue(object, value) {
  for (let key in object) {
    if (object[key].value === value) {
      return object[key].reviewValue;
    }
  }
  return '';
}

// export function getAssetTypeReview(assetType) {
//   let returnValue = '';
//   for (let key in assetType) {
//     if (assetType[key] === true && key !== 'other' && key !== 'other_checked') {
//       returnValue = `${returnValue}${returnValue !== '' ? ',' : ''} ${
//         assetTypeLabelReview[key]
//       }`;
//     }
//   }
//   if (assetType.other_checked) {
//     if (assetType.other !== '') {
//       returnValue = !_.isEmpty(returnValue)
//         ? `${returnValue}, ${assetTypeLabelReview.other} : ${assetType.other}`
//         : `${assetTypeLabelReview.other} : ${assetType.other}`;
//     } else {
//       returnValue = !_.isEmpty(returnValue)
//         ? `${returnValue}, ${assetTypeLabelReview.other}`
//         : `${assetTypeLabelReview.other}`;
//     }
//   }
//   return returnValue;
// }

export function getAssetTypeReview(assetType) {
  const labels = Object.entries(assetType).reduce((accumulator, [key, value]) => {
    // Add label if value is true and key is not 'other' or 'other_checked'
    if (value === true && key !== 'other' && key !== 'other_checked') {
      accumulator.push(assetTypeLabelReview[key]);
    }
    return accumulator;
  }, []);

  // Handle 'other' and 'other_checked' outside the loop
  if (assetType.other_checked) {
    const otherLabel = assetType.other !== '' ? `${assetTypeLabelReview.other}: ${assetType.other}` : assetTypeLabelReview.other;
    labels.push(otherLabel);
  }

  return labels.join(', ');
}

export function addBusinessDays(date, daysToAdd) {
  let count = 0;
  while (count < daysToAdd) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      // Skip weekends (0 is Sunday, 6 is Saturday)
      count++;
    }
  }
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

export function addDays(date, daysToAdd) {
  let count = 0;
  while (count < daysToAdd) {
    date.setDate(date.getDate() + 1);
    count++;
  }
  // Format as 'YYYY-MM-DD' without using toISOString
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

export function getUserById(users, id) {
  return users.find(u => u.user_id === id);
}
export function getSiteById(sites, id) {
  return sites.find(x => x.site_id === id);
}

export function formatDate(date, format = dateFormatTypes.shortDate) {
  return moment(date).format(format);
}
export function isFunctionKey(keyCode) {
  console.log(functionKeys);
  return functionKeys.includes(keyCode);
}

export function getFilesByCategory(attachments, type) {
  let resultAttachments = [];
  for (let attachment of attachments) {
    if (attachment.catagory == type) {
      resultAttachments.push(attachment);
    }
  }
  return resultAttachments;
}

export function isJobStatusInList(listStatus, jobStatus) {
  return listStatus.includes(jobStatus);
}

export function isValidHttpsUrl(string) {
  if (!string) return true;
  if (string.indexOf('.') < 9 || string.indexOf('.') > string.length - 3) {
    return false;
  }
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false; // Nếu không phải là URL hợp lệ, trả về false
  }

  return url.protocol === 'https:'; // Kiểm tra xem giao thức có phải là HTTPS không
}

export function mapCategoryAttachments(category) {
  if (!category) {
    return;
  }
  switch (category) {
    case 'resale':
      return 'Certificate of Resale';
    default:
      return category.charAt(0).toUpperCase() + category.slice(1);
  }
}
