<template>
  <v-card outlined height="calc(100% - 16px)" class="ma-2">
    <v-toolbar flat>
      <v-toolbar-title class="" style="font-weight: 500">
        Transaction Final Details
      </v-toolbar-title>
      <v-spacer />
      <v-row
        no-gutters
        class="mt-2"
        v-if="
          (_job.status === 'closing' || _job.status === 'resale') &&
            _company.type_id === 1
        "
      >
        <v-spacer />
        <v-btn color="error" class="mr-2" @click="openReasonRejectDialog()">
          Reject
        </v-btn>
        <v-btn color="secondary" @click="acceptOffer()">Accept</v-btn>
      </v-row>
    </v-toolbar>
    <v-divider />
    <!-- Vu updated the file  10/04/2021 -->
    <v-card flat class="pa-4">
      <v-row style="padding: 10px 0px 10px 0px">
        <v-col sm="12" class="ma-0 pa-0">
          <v-card-text class="py-0" style="font-weight: 500">
            Vendor Purchase Offer
          </v-card-text>
        </v-col>
      </v-row>
      <!-- 1st row -->
      <v-row
        v-if="job.bid_per_pound !== null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Initial Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text class="pa-0 text-center v-card__subtitle">
            Price/lb Bid
          </v-card-text>
          <v-card-text class="v-card__text v-card__subtitle text-center">
            {{ job.bid_per_pound }}
          </v-card-text>
        </div>
        <div>
          <v-card-text style="margin-top: 5px" class="v-card__subtitle">
            x
          </v-card-text>
        </div>
        <div>
          <v-card-text class="pa-0 v-card__subtitle">
            Final Weight
          </v-card-text>
          <v-card-text class="v-card__subtitle text-center">
            {{ job.final_weight || 0 }}
          </v-card-text>
        </div>
        <div>
          <v-card-text style="margin-top: 5px" class="v-card__subtitle">
            =
          </v-card-text>
        </div>
        <div>
          <v-card-text
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div>{{ numberWithCommas02(priceBeforeResale) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 1A row -->
      <v-row
        v-if="job.bid_all_in_purchase !== null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Initial Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div>
              {{
                job.bid_all_in_purchase
                  ? job.bid_all_in_purchase
                  : Number(0.0).toFixed(2)
              }}
            </div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 1B row -->
      <v-row
        v-if="job.bid_per_pound == null && job.bid_all_in_purchase == null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Initial Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            {{ Number(0.0).toFixed(2) }}
          </v-card-text>
        </div>
      </v-row>
      <!-- 2nd row  -->
      <v-row
        v-if="
          job.additional_scrap_proceeds !== null &&
            parseFloat(job.additional_scrap_proceeds) !== 0
        "
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Additional Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div>{{ numberWithCommas02(job.additional_scrap_proceeds) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 3rd row -->
      <v-row
        v-if="
          job.recycle_specs.resale == true && job.has_purchase_equipment == true
        "
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Equipment Purchase Offer
          </v-card-text>
        </div>
        <v-card-text
          v-if="job.resale_offer !== null && parseFloat(job.resale_offer) !== 0"
          class="v-card__subtitle text-right"
          style="width: 140px"
        >
          <div style="float: left">$</div>
          <div>
            {{ job.resale_offer }}
          </div>
        </v-card-text>
        <v-card-text
          v-if="job.resale_offer == null || Number(job.resale_offer) == 0"
          class="v-card__subtitle text-right"
          style="width: 140px"
        >
          <div style="float: left">$</div>
          <div>{{ Number(0.0).toFixed(2) }}</div>
        </v-card-text>
      </v-row>
      <!-- empty row with border bottom -->
      <v-row
        no-gutters
        class="justify-space-between pa-0 border-bottom" style="border-bottom: 1px solid gray!important;"
      ></v-row>
      <!-- 4th row -->
      <v-row
        no-gutters
        class="justify-space-between pa-0 border-top border-bottom" style="border-bottom: 1px solid gray!important;"
      >
        <div>
          <v-card-text class="v-card__subtitle" style="font-weight: 500">
            Subtotal Vendor Purchase Offer
          </v-card-text>
        </div>
        <div>
          <v-card-text class="v-card__subtitle text-right" style="width: 140px">
            <div style="float: left">$</div>
            <div>
              {{ numberWithCommas02(calculateSubtotalVendorPO(job)) }}
            </div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 5th row -->
      <v-row style="padding: 0px 0px 10px 0px">
        <v-col sm="12" class="ma-0 pa-0">
          <v-card-text class="py-0" style="font-weight: 500">
            Customer Expenses and Fees
          </v-card-text>
        </v-col>
      </v-row>
      <!-- 6th row -->
      <v-row
        v-if="job.bid_buy_out !== null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Customer Service Charge
          </v-card-text>
        </div>
        <div>
          <v-card-text class="v-card__subtitle text-right" style="width: 140px">
            <div style="float: left">$</div>
            <div style="color: red;">
              ({{
                job.current_bid_buy_out
                  ? job.current_bid_buy_out
                  : job.bid_buy_out
              }})
            </div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 7th row -->
      <v-row no-gutters class="justify-space-between pa-0">
        <div>
          <v-card-text class="v-card__subtitle">
            RGX Scrap Transaction Fee
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="parseFloat(calculateScrapTXFee(job)) !== 0"
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div style="color: red;">
              ({{ numberWithCommas02(calculateScrapTXFee(job)) }})
            </div>
          </v-card-text>
          <v-card-text
            v-if="parseFloat(calculateScrapTXFee(job)) === 0"
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div>{{ calculateScrapTXFee(job) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 8th row -->
      <v-row
        no-gutters
        class="justify-space-between pa-0"
        v-if="
          job.recycle_specs.resale == true && job.has_purchase_equipment == true
        "
      >
        <div>
          <v-card-text class="v-card__subtitle">
            RGX Equipment Transaction Fee
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="parseFloat(calculateEquipmentTXFee(job)) !== 0"
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div style="color: red;">
              ({{ numberWithCommas02(calculateEquipmentTXFee(job)) }})
            </div>
          </v-card-text>
          <v-card-text
            v-if="parseFloat(calculateEquipmentTXFee(job)) === 0"
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div>{{ calculateEquipmentTXFee(job) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- empty row with border bottom -->
      <v-row
        no-gutters
        class="justify-space-between pa-0 border-bottom"  style="border-bottom: 1px solid gray!important;"
      ></v-row>
      <!-- 9th row -->
      <v-row no-gutters class="justify-space-between pa-0 border-bottom"  style="border-bottom: 1px solid gray!important;">
        <div>
          <v-card-text class="v-card__subtitle" style="font-weight: 500">
            Subtotal Customer Expenses and Fees
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="
              parseFloat(
                calculateSubtotalCustomerFee(
                  job.current_bid_buy_out,
                  calculateScrapTXFee(job),
                  calculateEquipmentTXFee(job)
                )
              ) !== 0
            "
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div style="color: red;">
              ({{
                numberWithCommas02(
                  calculateSubtotalCustomerFee(
                    job.current_bid_buy_out,
                    calculateScrapTXFee(job),
                    calculateEquipmentTXFee(job)
                  )
                )
              }})
            </div>
          </v-card-text>
          <v-card-text
            v-if="
              parseFloat(
                calculateSubtotalCustomerFee(
                  job.current_bid_buy_out,
                  calculateScrapTXFee(job),
                  calculateEquipmentTXFee(job)
                )
              ) === 0
            "
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div>
              {{
                numberWithCommas02(
                  calculateSubtotalCustomerFee(
                    job.current_bid_buy_out,
                    calculateScrapTXFee(job),
                    calculateEquipmentTXFee(job)
                  )
                )
              }}
            </div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 10th row -->
      <v-row
        v-if="
          calculateTotal(
            calculateSubtotalVendorPO(job),
            calculateSubtotalCustomerFee(
              job.current_bid_buy_out,
              calculateScrapTXFee(job),
              calculateEquipmentTXFee(job)
            )
          ) < 0
        "
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text
            style="
              padding: 16px 0px 0px 5px;
              text-transform: uppercase;
              font-weight: 900;"
          >
            Total You Owe
          </v-card-text>
        </div>
        <div>
          <v-card-text
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div style="color: red; font-weight: 900">
              ({{
                numberWithCommas02(
                  calculateTotal(
                    calculateSubtotalVendorPO(job),
                    calculateSubtotalCustomerFee(
                      job.current_bid_buy_out,
                      calculateScrapTXFee(job),
                      calculateEquipmentTXFee(job)
                    )
                  ) * -1
                )
              }})
            </div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 10A row -->
      <v-row
        v-if="
          calculateTotal(
            calculateSubtotalVendorPO(job),
            calculateSubtotalCustomerFee(
              job.current_bid_buy_out,
              calculateScrapTXFee(job),
              calculateEquipmentTXFee(job)
            )
          ) >= 0
        "
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text
            style="
              padding: 16px 0px 0px 5px;
              text-transform: uppercase;
              font-weight: 900;"
          >
            Total You Will Receive
          </v-card-text>
        </div>
        <div>
          <v-card-text
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div style="color: green; font-weight: 900">
              {{
                numberWithCommas02(
                  calculateTotal(
                    calculateSubtotalVendorPO(job),
                    calculateSubtotalCustomerFee(
                      job.current_bid_buy_out,
                      calculateScrapTXFee(job),
                      calculateEquipmentTXFee(job)
                    )
                  )
                )
              }}
            </div>
          </v-card-text>
        </div>
      </v-row>
    </v-card>
    <!--
    <v-row v-if="confirm" class="d-flex flex-row-nowrap no-gutters px-2">
      <v-btn
        rounded
        block
        :disabled="!cod || !finalValid"
        color="primary"
        class="submit-btn"
        :loading="_loading"
        @click="confirmClose"
        >Confirm</v-btn
      >
    </v-row>
    -->
  </v-card>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  components: {},
  props: {
    finalValid: Boolean,
    confirm: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      // _bid: "bids/getCurrentBidInfo",
      // _loading: "jobs/loading",
      _company: 'company/getCompany',
      _showReasonRejectDialog: 'jobs/getShowReasonRejectDialog'
    }),
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    },
    /*
    bid: {
      set(val) {
        this._setBid(bid);
      },
      get() {
        return this._bid;
      },
    }, */
    total() {
      if (this.job.bid_per_pound === null) {
        return (
          Number(this.job.bid_buy_out) +
          Number((this.job.resale_offer || '0').replace(/,/g, ''))
        ).toFixed(2);
      } else {
        return this.job.resale_offer
          ? (
              Number(this.priceBeforeResale) +
              Number((this.job.resale_offer || '0').replace(/,/g, ''))
            ).toFixed(2)
          : this.priceBeforeResale;
      }
    },
    cod() {
      let cod = this.job.attachments.filter(o => o.catagory === 'cod');
      return cod.length > 0 ? true : false;
    },
    priceBeforeResale() {
      let price = this.job.final_weight * this.job.bid_per_pound;
      return this.job.final_weight !== undefined ? price.toFixed(2) : 0.0;
    }
  },
  methods: {
    ...mapActions({
      _updateJob: 'jobs/updateJob',
      _sendEmailClosedJobInfo: 'jobs/sendEmailClosedJobInfo'
    }),
    ...mapMutations({
      _setJob: 'jobs/setJob',
      _setBid: 'bids/setCurrentBidInfo',
      _setShowReasonRejectDialog: 'jobs/setShowReasonRejectDialog'
    }),
    acceptOffer() {
      let job = {};
      job.status = 'closed';
      job.bid_status = 'closed';
      job.id = this.job.job_id;
      // <begin> Vu updated 11/7/2021 for RGX-149 (added closed_date)
      var today = new Date();
      job.closed_date = today.toISOString();
      // <end>
      this._updateJob(job).then(() => {
        this._sendEmailClosedJobInfo(job.id);
        this.$router.push('/history');
      });
    },
    // rejectOffer() { moved to jobReasonRejectDialog.vue
    //   console.log("rejected offer");
    //   return;
    //   let job = {};
    //   job.status = "rejected";
    //   job.bid_status = "rejected";
    //   job.id = this.job.job_id;
    //   this._updateJob(job);
    // },

    openReasonRejectDialog() {
      console.log('xx _setShowReasonRejectDialog true');
      this._setShowReasonRejectDialog(true);
    },

    // confirmClose() {
    //   this.$emit("confirm");
    //},
    numberWithCommas(x) {
      if (x == null) {
        return '0.00';
      }

      // let v = (Math.round(x * 100) / 100).toFixed(2);
      // v = v.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // return x >= 0 ? v : "(" + v.replace("-", "") + ")";
      let v = Number(x.toString().replaceAll(',', ''));
      let y = v.toLocaleString('en-US', { minimumFractionDigits: 2 });
      return v >= 0 ? y : '(' + y.replace('-', '') + ')';
    },
    numberWithCommas02(x) {
      if (x == null) {
        return '0.00';
      }

      // let v = (Math.round(x * 100) / 100).toFixed(2);
      // v = v.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // return v;

      // let v = Number(x.replaceAll(',',''));
      // let y = v.toLocaleString('en-US', {minimumFractionDigits: 2})
      return Number(x.toString().replaceAll(',', '')).toLocaleString('en-US', {
        minimumFractionDigits: 2
      });
    },
    calculateSubtotalVendorPO(jobObject) {
      // Initial Bid + Additional Scrap Proceeds + Equipment Purchase
      let subTotal = 0;
      // debugger;
      if (jobObject.bid_per_pound) {
        if (jobObject.final_weight) {
          let price =
            Number(jobObject.final_weight) * Number(jobObject.bid_per_pound);
          subTotal = Number(subTotal) + Number(price);
        }
      } else {
        if (jobObject.bid_all_in_purchase) {
          let bidAllIn = jobObject.bid_all_in_purchase.replace(/,/g, '');
          // initialBid = Number(bidAllIn).toFixed(2);
          subTotal = Number(subTotal) + Number(bidAllIn);
        }
      }

      if (jobObject.additional_scrap_proceeds) {
        let additionalScrapProceeds = jobObject.additional_scrap_proceeds.replace(
          /,/g,
          ''
        );
        subTotal = Number(subTotal) + Number(additionalScrapProceeds);
      }

      if (jobObject.resale_offer) {
        let equipmentPurchaseOffer = jobObject.resale_offer.replace(/,/g, '');
        subTotal = Number(subTotal) + Number(equipmentPurchaseOffer);
      }
      // return the sum
      return subTotal.toFixed(2);
    },
    calculateScrapTXFee(jobObject) {
      console.log("jobObject",jobObject);
      // debugger;
      // init
      let subTotal = 0;
      let scrapPurchasePercentage = 0;
      let scrapPerPound = 0;

      if (jobObject) {
        // calculate sub total
        /*
        if (bidObject.info.initial_scrap_proceeds) {
          subTotal = Number(subTotal) + Number(bidObject.info.initial_scrap_proceeds);
        } 
        */

        if (jobObject.bid_per_pound) {
          if (jobObject.final_weight) {
            let price =
              Number(jobObject.final_weight) * Number(jobObject.bid_per_pound);
            subTotal = Number(subTotal) + Number(price).toFixed(2);
          }
        } else {
          if (jobObject.bid_all_in_purchase) {
            let bidAllIn = jobObject.bid_all_in_purchase.replace(/,/g, '');
            // initialBid = Number(bidAllIn).toFixed(2);
            subTotal = Number(subTotal) + Number(bidAllIn).toFixed(2);
          }
        }

        if (jobObject.additional_scrap_proceeds) {
          subTotal =
            Number(subTotal) +
            Number(jobObject.additional_scrap_proceeds.replace(/,/g, ''));
        }
      }

      // get scrap rate from job
      if (jobObject.scrap_purchase_percentage && jobObject.scrap_per_pound) {
        scrapPurchasePercentage = jobObject.scrap_purchase_percentage;
        scrapPerPound = jobObject.scrap_per_pound;
      }
      /*
      else {
        // get scrap rate from bid
        if(bidObject.info.customer_configs && bidObject.info.systems_configs) {   
          if(bidObject.info.customer_configs.scrap_purchase_percentage 
              && bidObject.info.customer_configs.scrap_per_pound) {
            scrapPurchasePercentage = bidObject.info.customer_configs.scrap_purchase_percentage;
            scrapPerPound = bidObject.info.customer_configs.scrap_per_pound; 
          } else {
            scrapPurchasePercentage = bidObject.info.systems_configs.scrap_purchase_percentage;
            scrapPerPound = bidObject.info.systems_configs.scrap_per_pound; 
          }
        } 
      }*/

      let txFee1 = (subTotal * Number(scrapPurchasePercentage)) / 100;
      let txFee2 = 0;
      if (jobObject.final_weight) {
        txFee2 = Number(jobObject.final_weight) * scrapPerPound;
      }

      // return the scrap transaction fee
      return txFee1 > txFee2 ? txFee1.toFixed(2) : txFee2.toFixed(2);
    },
    calculateEquipmentTXFee(jobObject) {
      // debugger;
      // init
      let equipPurchasePercentage = 0;
      let equipmentTXFee = 0;

      if (jobObject) {
        // get equipment rate from job
        if (jobObject.equipment_purchase_percentage) {
          equipPurchasePercentage = jobObject.equipment_purchase_percentage;
        }
        /*
	      else {
          // get equipment rate from bid
          if(bidObject.info.customer_configs && bidObject.info.systems_configs) {
            if(bidObject.info.customer_configs.equipment_purchase_percentage) {
              equipPurchasePercentage = bidObject.info.customer_configs.equipment_purchase_percentage; 
            }
            else {
              equipPurchasePercentage = bidObject.info.systems_configs.equipment_purchase_percentage;               
            }
          }
        } */
      } // end if

      if (jobObject.resale_offer) {
        equipmentTXFee =
          (equipPurchasePercentage *
            Number(jobObject.resale_offer.replace(/,/g, ''))) /
          100;
      }
      // return the fee
      return equipmentTXFee.toFixed(2);
    },
    calculateSubtotalCustomerFee(
      custServiceCharge,
      scrapTXFee,
      equipmentTXFee
    ) {
      let subTotal = 0;
      // debugger;
      if (custServiceCharge) {
        custServiceCharge = custServiceCharge.replace(/,/g, '');
        subTotal = Number(subTotal) + Number(custServiceCharge);
      }

      if (scrapTXFee) {
        subTotal = Number(subTotal) + Number(scrapTXFee);
      }

      if (equipmentTXFee) {
        subTotal = Number(subTotal) + Number(equipmentTXFee);
      }
      // return the sum
      return subTotal.toFixed(2);
    },
    calculateTotal(subTotalVendor, subTotalCustomer) {
      let total = 0;

      if (subTotalVendor && subTotalCustomer) {
        total = Number(subTotalVendor) - Number(subTotalCustomer);
      } else if (!subTotalVendor && subTotalCustomer) {
        total = Number(subTotalCustomer) * -1;
      } else if (subTotalVendor && !subTotalCustomer) {
        total = Number(subTotalVendor);
      } else {
        // both null, do nothing
      }

      // return
      return total.toFixed(2);
    }
  }
};
</script>
