<template>
  <v-dialog v-model="active" v-if="_bid" max-width="400" @click:outside="deleteBid = false">
    <div v-if="_companyType == 2 && _bid.status === 'open'">
      <v-card>
        <v-container flat v-if="!deleteBid">
          <div class="header">
            <v-card-title class="pl-4 pr-0">
              Bid Overview
              <v-spacer />
              <v-btn text icon @click="
                active = false;
              deleteBid = false;
              ">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <div justify="center" align="center">
              <!-- <v-card color class="ma-5 bid-overview" outlined> -->
              <v-card-text style="padding-left: 0; padding-top: 0; padding-bottom: 0">
                <div v-if="
                  this._job.logistics == 2 &&
                  !this._bid.has_pickup_fee &&
                  !this._bid.has_purchase_scrap
                ">
                  <v-row align="center" justify="center" class="my-md-1 justify-space-around">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor Bid All-in
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              color: #e53935;
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              0.00
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              0.00
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="
                  !(
                    this._job.logistics == 2 &&
                    !this._bid.has_pickup_fee &&
                    !this._bid.has_purchase_scrap
                  )
                ">
                  <!-- Job has no weight for Case 1a and Case 3a -->
                  <v-row v-if="
                    shouldShow() === 'CASE_1a' || shouldShow() === 'CASE_3a'
                  " align="center" justify="center" class="my-md-1 justify-space-around">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Min. Purchase Bid
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              color: #e53935;
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{
                                `(${_bid.bidType
                                  ? _bid.bid_per_pound
                                  : _bid.bid_all_in_purchase
                                })`
                              }}
                            </div>
                            <div style="align-self: center; font-size: 0.9em" class="pl-2" v-if="_bid.bidType">
                              Price/lb
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Case 1-->
                  <v-row v-if="shouldShow() === 'CASE_1'" align="center" justify="center"
                    class="my-md-1 justify-space-around">
                    <v-col cols="12" style="padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1">
                            Price/lb Bid
                          </v-card-title>
                        </v-col>

                        <v-col cols="6" sm="4" class="pr-1" :class="!_bid.hasJobWeight ? 'alignSelfCenter' : null">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="font-size: 1.2em">
                              {{ _bid.bid_per_pound }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Price/lb
                            </div>
                          </v-layout>

                          <v-layout>
                            <div style="font-size: 1.2em">
                              <v-icon x-small class="pl-2">mdi-close</v-icon>
                              {{
                                numberWithCommas(
                                  _job.weight ? _job.weight.slice(0, -4) : 1
                                )
                              }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Est. lb
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>
                        <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 18px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              color: #e53935;
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{
                                '(' +
                                numberWithCommas(
                                  calculate(
                                    _bid.bid_per_pound,
                                    _job.weight ? _job.weight.slice(0, -4) : 1
                                  ).toFixed(2)
                                ) +
                                ')'
                              }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 32px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.hasJobWeight" cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col cols="6" sm="4" style="align-self: center">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Case 3 -->
                  <v-row v-if="shouldShow() === 'CASE_3'" align="center" justify="center"
                    class="my-md-1 justify-space-around">
                    <v-col cols="12" style="padding-bottom: 0" v-if="_bid.bidType">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1">
                            Price/lb Bid
                          </v-card-title>
                        </v-col>

                        <v-col cols="5" sm="4" class="pr-1" :class="!_bid.hasJobWeight ? 'alignSelfCenter' : null">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="font-size: 1.2em">
                              {{ _bid.bid_per_pound }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Price/lb
                            </div>
                          </v-layout>

                          <v-layout>
                            <div style="font-size: 1.2em; pl-xs-3">
                              <v-icon x-small class="pl-sm-2">mdi-close</v-icon>
                              {{
                                numberWithCommas(
                                  _job.weight ? _job.weight.slice(0, -4) : 1
                                )
                              }}
                            </div>
                            <div style="font-size: 0.9em; padding-top: 2px" class="pl-2">
                              Est. lb
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.9em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 18px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              color: #e53935;
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{
                                numberWithCommas(
                                  calculate(
                                    _bid.bid_per_pound,
                                    _job.weight ? _job.weight.slice(0, -4) : 1
                                  ).toFixed(2)
                                )
                              }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="!_bid.bidType" cols="12" style="padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor Bid All-in
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div :class="_bid.scenario !== 4 ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateVendorBid() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col v-if="_bid.bidType" cols="12" style="padding-top: 0" sm="6">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="!_bid.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="4" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="!_bid.bidType" cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col cols="4" sm="4" style="align-self: center">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Case 2, Case 4 -->
                  <v-row v-if="
                    shouldShow() === 'CASE_2' || shouldShow() === 'CASE_4'
                  " align="center" justify="center" class="my-md-1 justify-space-around">
                    <v-col cols="12" style="padding-bottom: 0px">
                      <v-row no-gutters>
                        <v-col cols="10" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Vendor Bid All-in
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Min. Purchase Price
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div :class="_bid.scenario !== 4 ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateVendorBid() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Customer
                          </v-card-title>
                          <v-card-subtitle class="mt-0" style="
                            font-size: 0.8em;
                            padding-top: 0;
                            text-align: left;
                            line-height: 0.9em;
                          ">
                            Max. Fees
                          </v-card-subtitle>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 33px">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ numberWithCommas(_bid.bid_buy_out) }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="padding-top: 0">
                      <v-row no-gutters>
                        <v-col cols="6" sm="6">
                          <v-card-title class="subtitle-1" style="padding-bottom: 0">
                            Net Estimate
                          </v-card-title>
                        </v-col>

                        <v-col cols="4" sm="5" style="align-self: center">
                          <v-layout>
                            <v-icon>mdi-currency-usd</v-icon>
                            <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null
                              " style="
                              font-size: 1.2em;
                              border-bottom: 1px solid black;
                            ">
                              {{ calculateNetEstimate() }}
                            </div>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <!-- </v-card> -->

              <v-card-title class="subtitle-1 pb-0">Site</v-card-title>
              <v-card-subtitle class="subtitle-2 mt-0" style="text-align: left">
                {{ _bid.site_name }}
              </v-card-subtitle>

              <v-card-title class="subtitle-1 pb-0">
                Point of Contact
              </v-card-title>
              <v-card-subtitle class="subtitle-2 mt-0" style="text-align: left">
                {{ _bid.poc_username || _bid.poc_email }}
              </v-card-subtitle>

              <v-card-actions>
                <v-btn color="error" rounded :disabled="_bid.company_id !== _company.id" @click="deleteBid = true">
                  Delete Bid
                </v-btn>
                <v-spacer />
                <v-btn color="primary" rounded @click="close()">Continue</v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-container>
      </v-card>
      <v-card flat v-if="deleteBid">
        <v-card-title>Are You Sure?</v-card-title>
        <v-card-text>Deleting this bid will be irreversable.</v-card-text>
        <v-card-actions>
          <v-btn color="primary" rounded @click="close()">CANCEL</v-btn>
          <v-spacer />
          <v-btn rounded color="error" @click="removeBid()">Delete Bid</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import config from '../../config';
// import tc from "@/components/app/Terms&Conditions.vue";
import BidInformation from '@/mixins/BidInformation';
export default {
  /*
  components: {
    tc,
  }, */
  mixins: [BidInformation],
  data() {
    return {
      accepted: false,
      deleteBid: false,
      bidPOC: undefined,
      awardLoading: false,
      rejectLoading: false,
      netEstTextColorRed: false
    };
  },

  computed: {
    ...mapGetters({
      _active: 'bids/getVendorBidReview',
      _bid: 'bids/getCurrentBid',
      _bids: 'bids/getBids',
      _bidCompany: 'bids/getCurrentBidCompany',
      _job: 'jobs/getCurrentJob',
      _company: 'company/getCompany',
      _companyType: 'company/getCompanyType',
      _loading: 'bids/getLoading'
    }),
    active: {
      get() {
        return this._active;
      },
      set(val) {
        this._activateBidReview(val);
      }
    }
    // _bidPOC() {
    //   return this._user ?
    //     this._user(this._bid.poc_id)
    //     : null;
    // }
  },
  watch: {
    _bid() {
      if (this._companyType === config.companyType.VENDOR && !this.bidPOC) {
        this.bidPOC = this._user(this._job.poc_id);
      }
    },

    _active() {
      if (
        this._companyType === config.companyType.VENDOR &&
        this._bid &&
        !this._bid.scenario
      ) {
        const information = this.addBidInformation(
          this._bid,
          this._job,
          this._companyType
        );

        this._setCurrentBid({
          ...this._bid,
          ...information
        });
      }
    },

    _job() {
      if (this._job.status === 'awarded') {
        this.active = false;
      }
    }
  },
  methods: {
    ...mapMutations({
      _activateBidReview: 'bids/toggleVendorReview',
      _setLoading: 'jobs/toggleLoading'
    }),
    ...mapActions({
      _updateBid: 'bids/updateBid',
      _updateJob: 'jobs/updateJob',
      _sendEmailAwardedJobInfo: 'jobs/sendEmailAwardedJobInfo',
      _removeBid: 'bids/deleteBid',
      _user: 'user/getById'
    }),
    removeBid() {
      this._removeBid(this._bid.id).then(res => {
        this.close();
        setTimeout(() => {
          this.active = false;
        }, 200);
      });
      // this._removeBid(this._bid.id);
      // if (this._bids.length <= 1) {
      //   this._updateJob({
      //     id: this._bid.job_id,
      //     status: 'open',
      //     bid_status: 'none'
      //   }).then(() => {
      //     this.active = false;
      //   });
      // }
      // this.close();
    },
    close() {
      this.deleteBid = false;
      this.active = false;
    },
    handleReject() {
      // this._setLoading(true);
      this.rejectLoading = true;
      var options = {
        id: this._bid.id,
        status: 'rejected'
      };
      this._updateBid(options).then(() => {
        this.active = false;
        this.rejectLoading = false;
      });
    },
    async handleAward() {
      this.awardLoading = true;
      var options = {
        id: this._bid.id,
        status: 'awarded',
        vendor_attachments: this._bid.attachments || []
      };
      this._updateBid(options).then(() => {
        for (var i = 0; i < this._bids.length; i++) {
          if (this._bids[i].bid_id !== this._bid.id) {
            options = {
              id: this._bids[i].bid_id,
              status: 'rejected'
            };
            this._updateBid(options);
          }
        }
        this._updateJob({
          id: this._bid.job_id,
          status: 'awarded',
          bid_status: 'awarded'
        }).then(() => {
          this._sendEmailAwardedJobInfo({
            id: this._bid.job_id,
            vendor_id: this._bid.company_id
          });
          this.awardLoading = false;
        });
      });
    },
    // Vu added 9/18/21
    numberWithCommas(x) {
      if (x == null) {
        return '0';
      }
      // return x ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "";
      return x
        ? Number(x.toString().replaceAll(',', '')).toLocaleString('en-US', {
          minimumFractionDigits: 2
        })
        : '';
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    calculate(str1, str2) {
      const rs = Number(str1.replace(/,/g, '')) * Number(str2 || 1);
      return rs;
    },
    calculateVendorBid() {
      // debugger;
      if (!this._bid) {
        return;
      }

      let number = this._bid.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;

      if (number == null) {
        return '0';
      }

      // return this.numberWithCommas(number);
      return this._bid.scenario !== 4
        ? '(' + this.numberWithCommas(number) + ')'
        : this.numberWithCommas(number);
    },
    calculateNetEstimate() {
      //debugger;
      if (!this._bid || !this._job) {
        return;
      }

      // init
      // this.netEstTextColorRed = false;
      let numberAsString = this._bid.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;

      let number = numberAsString
        ? parseFloat(numberAsString.replace(/,/g, ''))
        : 0;

      // const bidBuyOut = parseFloat(this._bid.bid_buy_out.replace(/,/g, ""));
      const bidBuyOut = this._bid.bid_buy_out
        ? parseFloat(this._bid.bid_buy_out.replace(/,/g, ''))
        : 0;

      const unit = this._job.weight
        ? parseFloat(this._job.weight.slice(0, -4))
        : 1;

      if (this._bid.scenario === 1) {
        number = number * unit;
      }

      if (this._bid.scenario === 3 && this._bid.bidType) {
        number = number * unit;
      }

      const net = bidBuyOut - number;
      if (net < 0 && this._bid.scenario !== 4) {
        this.netEstTextColorRed = true;
        return '(' + this.numberWithCommas(Math.abs(net).toFixed(2)) + ')';
      }
      /*
      if (net < 0 && this._bid.scenario !== 4) {
        this.netEstTextColorRed = false;
        return this.numberWithCommas(Math.abs(net).toFixed(2));
      }*/

      this.netEstTextColorRed = false;
      return this.numberWithCommas(net.toFixed(2));
      // this.netEstTextColorRed = true;
      // return "(" + this.numberWithCommas(net.toFixed(2)) + ")";
    },
    // check case to show BID REVIEW
    shouldShow() {
      if (!this._bid) {
        return '';
      }

      if (this._bid.scenario === 1) {
        if (!this._bid.hasJobWeight) {
          return 'CASE_1a';
        }
        return 'CASE_1';
      }

      if (this._bid.scenario === 2) {
        return 'CASE_2';
      }

      if (this._bid.scenario === 3) {
        if (this._bid.bidType && !this._bid.hasJobWeight) {
          return 'CASE_3a';
        }

        return 'CASE_3';
      }

      if (this._bid.scenario === 4) {
        return 'CASE_4';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.flex-container {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  width: 80%;
}

.location {
  grid-column: 1;
  grid-row: 1;
}

.number {
  grid-column: 2;
  grid-row: 1;
}

.duration {
  grid-column: 3;
  grid-row: 1;
}

.weight {
  grid-column: 1;
  grid-row: 2;
}

.pallets {
  grid-column: 1;
  grid-row: 3;
}

.recycling {
  grid-column: 2;
  grid-row: 2 / span 2;
}

.attachments {
  grid-column: 3;
  grid-row: 2 / span 2;
}

.access {
  grid-column: 1;
  grid-row: 4;
}

.lift {
  grid-column: 3;
  grid-row: 4;
}

.title-font {
  font-family: Roboto;
  font-size: 17px;
  color: #2e2e2e;
}

.subtitle-font {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #5d5d5d;
}

.vendor {
  grid-column: 1;
}

.address {
  grid-column: 2;
}

.phone {
  grid-column: 3;
}

.email {
  grid-column: 4;
}

.company-name {
  font-family: Roboto;
  font-size: 23px;
  color: #2e2e2e;
}

.center {
  align-self: center;
}

.text-center {
  text-align: center;
}

.link-color {
  color: #0091ff;
}

a {
  text-decoration: none;
}

.textColorRed {
  color: #e53935;
}

.alignSelfCenter {
  align-self: center;
}

.subtitle-1 {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
}
</style>
