<template>
  <v-dialog @click:outside="active = false" width="600" v-model="active">
    <v-card class="py-4 d-flex flex-column">
      <v-card-text>
        Thanks for submitting a new job through RGX. It looks like you require
        additional steps before your job can be submitted. A member of the RGX
        "Green Glove Services" team will contact you shortly to assist.
      </v-card-text>
      <div class="d-flex justify-end">
        <v-btn text @click="active = false">
          Close
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      _active: 'jobs/getGreenGloveAlert'
    }),
    active: {
      get() {
        return this._active;
      },
      set(val) {
        this._setActive(val);
      }
    }
  },
  methods: {
    ...mapMutations({
      _setActive: 'jobs/setGreenGloveAlert'
    })
  }
};
</script>
