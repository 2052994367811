<template>
  <div v-if="_loading">
    <skeleton-detail />
  </div>
  <v-card v-else class="pa-4 vendor-detail" flat>
    <div>
      <div>
        <div class="vendor-detail-section vendor-detail-section-1">
          <v-row>
            <v-col md="7" class="vendor-company-information">
              <div class="d-flex">
                <v-icon
                  @click="$router.push('/vendor')"
                  class="vendor-detail-back"
                  >mdi-chevron-left</v-icon
                >
                <v-card-title
                  v-html="`COMPANY INFORMATION`"
                  class="save--text pt-3 pb-2"
                ></v-card-title>
              </div>
              <div class="vendor-company-information-content h-100 pb-5">
                <v-row>
                  <v-col cols="12" md="5" lg="5" xl="5" class="py-0">
                    <img
                      :src="_vendor.icon_url || require('@/assets/Bitmap.jpg')"
                      class="w-100 pt-4 px-2"
                    />
                    <div class="pb-3 pt-4 px-2">
                      <label class="m-0">Address</label>
                      <span class="d-block"
                        ><div>
                          {{ _vendor.street
                          }}{{
                            _vendor.suite ? ', Suite ' + _vendor.suite : ''
                          }}
                        </div>
                        <div>
                          {{ _vendor.city }}, {{ _vendor.state }}
                          {{ _vendor.zip }}
                        </div></span
                      >
                    </div>
                    <div class="pb-3 px-2">
                      <label class="m-0"
                        >Are you R2 or e-Stewards certified?</label
                      >
                      <span
                        class="d-block"
                        v-if="
                          _vendor.r2_cert === null ||
                            _vendor.r2_cert.cert === false
                        "
                        >No</span
                      >
                      <span class="d-block" v-else>Yes</span>
                    </div>
                    <div class="pb-3 px-2">
                      <label class="m-0"
                        >Do you have a Certificate of Resale?</label
                      >
                      <span
                        class="d-block"
                        v-if="
                          _vendor.resale === null || _vendor.resale === false
                        "
                        >No</span
                      >
                      <span class="d-block" v-else>Yes</span>
                    </div>
                  </v-col>
                  <v-col cols="12" md="7" lg="7" xl="7">
                    <div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Company Name</label>
                        <span class="d-block">{{ _vendor.name }}</span>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Customer Since</label>
                        <span class="d-block">{{
                          formatDate(_vendor.updated_at)
                        }}</span>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Company Email</label>
                        <a
                          :href="'mailto:' + _vendor.email"
                          class="m-0 d-block"
                          >{{ _vendor.email }}</a
                        >
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Company Phone </label>
                        <span class="d-block">{{ _vendor.phone }}</span>
                      </div>
                      <div
                        class="pb-3 px-2"
                        v-if="_vendor.r2_cert && _vendor.r2_cert.date"
                      >
                        <label class="m-0">Certification Expiration</label>
                        <span class="d-block">{{
                          formatMMDDYYYY(_vendor.r2_cert.date)
                        }}</span>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Billing Address</label>
                        <div class="d-block">
                          <div
                            v-if="_vendor.billing_site_id && _vendor.s_street"
                          >
                            {{ _vendor.s_street
                            }}{{
                              _vendor.s_suite
                                ? ', Suite ' + _vendor.s_suite
                                : ''
                            }}
                          </div>
                          <div v-if="_vendor.billing_site_id && _vendor.s_city">
                            {{ _vendor.s_city }}, {{ _vendor.s_state }}
                            {{ _vendor.s_zip }}
                          </div>
                          <div
                            v-if="!_vendor.billing_site_id || !_vendor.s_street"
                          >
                            Not Available
                          </div>
                        </div>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Billing Contact</label>
                        <div class="d-block">
                          <div>
                            {{ _vendor.first_name }} {{ _vendor.last_name }}
                          </div>
                          <div>{{ _vendor.user_email }}</div>
                          <div>{{ _vendor.user_phone }}</div>
                          <div
                            v-if="
                              !_vendor.billing_team_id || !_vendor.user_email
                            "
                          >
                            Not Available
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" md="5" lg="5" xl="5">
              <v-row
                v-if="_compType === 3"
                class="pt-2 pb-2 justify-content-center align-items-center"
              >
                <v-col
                  cols="12"
                  md="5"
                  lg="5"
                  xl="5"
                  class="vendor-detail-title-text-admin"
                >
                  Admin Actions
                </v-col>
                <v-col cols="12" md="7" lg="7" xl="7" class="pt-1 pb-2">
                  <div
                    id="divSelectStatus"
                    class="d-flex md-justify-content-center"
                    style="width: 130px;"
                  >
                    <v-select
                      id="selectStatus"
                      v-on:change="openCompanyRejectDialog()"
                      v-model="selectedStatus"
                      :items="
                        _vendor.status == 'Archive'
                          ? status_items2
                          : _vendor.status == 'Delete'
                          ? ['Delete']
                          : status_items
                      "
                      style="border-radius: 30px; width:100px; border: 1px solid; height:33px"
                    >
                      <template v-slot:selection="{ item }">
                        <span
                          id="SelectedStatusActive"
                          v-bind:style="[
                            item == 'Active'
                              ? { color: '#4caf50' }
                              : item == 'On Hold'
                              ? { color: '#ED8300' }
                              : item == 'New'
                              ? { color: '#554DAD' }
                              : item == 'Archive'
                              ? { color: '#0091ff' }
                              : { color: 'red' }
                          ]"
                          >{{ item }}</span
                        >
                      </template>
                      <template #item="{item}">
                        <span
                          v-bind:style="[
                            item == 'Active'
                              ? { color: '#4caf50' }
                              : item == 'On Hold'
                              ? { color: '#ED8300' }
                              : item == 'New'
                              ? { color: '#554DAD' }
                              : item == 'Archive'
                              ? { color: '#0091ff' }
                              : { color: 'red' }
                          ]"
                          >{{ item }}</span
                        >
                      </template>
                    </v-select>
                    <!--v-btn class="btn-company-info-archive">ARCHIVE</v-btn>
                    <v-btn class="btn-company-info-restore">RESTORE</v-btn-->
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="pt-1 pb-2"
                  v-if="_vendor.status_note"
                >
                  <div style="line-height: 25px;">
                    <b>Status Change Reason (s):</b> <br /><span
                      style="color:#5d5d5d"
                      v-html="_vendor.status_note"
                    >
                    </span>
                  </div>
                </v-col>
              </v-row>
              <div v-if="_compType !== 3" style="height: 52px"></div>
              <v-simple-table class="simple-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Day</th>
                      <th class="text-left">Open</th>
                      <th class="text-left">Close</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in _vendor.hours_of_op"
                      :key="index"
                    >
                      <td>{{ item.day }}</td>
                      <td>{{ formTime(item.open) }}</td>
                      <td>{{ formTime(item.close) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
        <div class="vendor-detail-section vendor-detail-section-2">
          <v-row style="padding-top: 2rem;"  >
            <v-col
              cols="12"
              md="7"
              lg="7"
              xl="7"
              class="pt-0 pb-0 vendor-detail-left vendor-detail-abilities"
            >
              <v-simple-table class="simple-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="100%">Vendor Abilities  </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in _vendor.vendor_abilities"
                      :key="index"
                    >
                      <td>{{ item.statement }}</td>
                      <td class="vendor-detail-abilities-tr">
                        <div class="vendor-detail-abilities-yes-no">
                          <label
                            v-bind:class="{
                              'vendor-circle-black': item.able,
                              'vendor-circle-white': !item.able
                            }"
                            class="d-flex align-items-center"
                          >
                            <span></span>
                            Yes
                          </label>
                          <label
                            v-bind:class="{
                              'vendor-circle-black': !item.able,
                              'vendor-circle-white': item.able
                            }"
                            class="d-flex align-items-center"
                          >
                            <span></span>
                            No
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
              md="5"
              lg="5"
              xl="5"
              class="pt-0 pb-0 vendor-detail-right vendor-detail-documents"
            >
              <v-simple-table class="simple-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="100%">Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in _vendor.attachments"
                      :key="index"
                    >
                      <td width="20">
                        <v-icon color="#0eb45a" dense>mdi-paperclip</v-icon>
                      </td>
                      <td>
                        <a
                          target="_blank"
                          class="d-block"
                          @click="showGoogleDocIndex(index)"
                          >{{ item.original_name }}</a
                        >
                        <span>{{ mapCategory(item.catagory) }}</span>
                      </td>
                      <td width="5" style="padding: 0;">
                        <v-btn
                          icon
                          :href="item.location"
                          :download="item.original_name"
                          ><v-icon color="#0eb45a">mdi-download</v-icon></v-btn
                        >
                      </td>
                      <td width="5" style="padding-left: 0;">
                        <v-btn icon @click="showGoogleDocIndex(index)">
                          <v-icon color="secondary">mdi-file-find</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
        <div class="vendor-detail-section vendor-detail-section-3">
          <v-card-title
            v-html="`JOB LIST`"
            class="save--text px-0"
          ></v-card-title>
          <div class="job-list">
            <v-spacer />
            <div class="justify-end">
              <v-text-field
                v-model="search"
                style="max-width: 300px; margin: 16px"
                label="Search"
                append-icon="mdi-magnify"
              />
            </div>
            <v-data-table
              :headers="jobHeaders"
              :items="_vendor._jobs"
              :search="search"
              hide-default-footer
              class="table-no-border"
              @click:row="handleClick"
            >
              <template v-slot:item.location="{ item }">
                {{ item.site.city }}, {{ item.site.state }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ item.status | capitalize }}
              </template>
            </v-data-table>
          </div>
        </div>

        <div class="vendor-detail-section vendor-detail-section-4">
          <v-row>
            <v-col
              cols="12"
              md="7"
              lg="7"
              xl="7"
              class="pt-0 pb-0 vendor-detail-left"
            >
              <v-card-title
                v-html="`TEAM INFORMATION`"
                class="save--text px-0"
              ></v-card-title>
              <v-data-table
                :headers="teamInfoHeaders"
                :items="_getUsers"
                :loading="_loading_user"
                hide-default-footer
                class="table-no-border"
                @click:row="handleClickTeam"
              >
                <template v-slot:item.user_type="{ item }">
                  {{ getUserTypeText(item.user_type) }}
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="5"
              lg="5"
              xl="5"
              class="pt-0 pb-0 vendor-detail-right"
            >
              <v-card-title
                v-html="`SITE LIST`"
                class="save--text px-0"
              ></v-card-title>
              <v-data-table
                :headers="siteHeaders"
                :items="_getSites"
                :loading="_loading_site"
                hide-default-footer
                class="table-no-border"
                @click:row="handleClickSite"
              ></v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <admin-job-detail /><company-reject-dialog />
    <create-site :edit="editSite" />
    <create-user @close="closeCreate()" />
    <google-doc />
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import SkeletonDetail from '@/components/skeleton/Detail.vue';
import AdminJobDetail from '@/components/jobs/AdminJobDetail';
import CreateSite from '@/components/site/Create.vue';
import CreateUser from '@/components/team/CreateUser.vue';
import CompanyRejectDialog from '@/components/dashboard/admin/CompanyRejectDialog.vue';
import GoogleDoc from '@/components/app/GoogleDoc.vue';
export default {
  components: {
    SkeletonDetail,
    AdminJobDetail,
    CreateSite,
    CreateUser,
    CompanyRejectDialog,
    GoogleDoc
  },
  data() {
    return {
      status_items: ['Active', 'On Hold', 'Archive'],
      status_items2: ['Active', 'On Hold', 'Archive', 'Delete'],
      selectedStatus: null,
      jobHeaders: [
        {
          text: 'ID#',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        { text: 'Job Name', value: 'name' },
        { text: 'Date Open', value: 'start_date_text' },
        { text: 'Date Closed', value: 'end_date_text' },
        { text: 'Status', value: 'status' },
        { text: 'Location', value: 'location' }
      ],
      teamInfoHeaders: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'User Type', value: 'user_type' }
      ],
      siteHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Location', value: 'location' }
      ],
      search: undefined,
      editSite: false,
      edit: false
    };
  },
  watch: {
    _vendor() {
      if (Array.isArray(this._vendor.attachments)) {
        this._vendor.attachments = this._vendor.attachments.filter(
          att => att.catagory
        );
      }
    }
  },
  computed: {
    ...mapGetters({
      _vendor: 'vendors/getVendor',
      _getUsers: 'user/getUsersCompany',
      _compType: 'company/getCompanyType',
      _getSites: 'sites/getSites',
      _loading: 'vendors/getLoading',
      _loading_user: 'user/loading',
      _loading_site: 'sites/loading'
    })
  },
  async mounted() {
    this._getVendor(this.$route.params.id).then(rs => {
      console.log('current _vendor', this._vendor);
      if (this._vendor.status == 'New') this.status_items.push('New');
      this.selectedStatus = this._vendor.status;
    });
    let payload = {
      itemsPerPage: 99999,
      page: 1,
      sortBy: ['user_id'],
      sortDesc: [true],
      company_id: this.$route.params.id
    };
    this._setUsersCompany(payload);
    let option_site = {
      itemsPerPage: 99999,
      page: 1,
      company_id: this.$route.params.id
    };
    this._setResults(option_site);
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY hh:mm A');
    },
    formatMMDDYYYY(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    formTime(time) {
      return moment(time, 'hh:mm').format('hh:mm A');
    },
    handleClick(value) {
      console.log('handleClick', value);
      if (this._compType === 3) {
        this._setJob(value.id);
        this._setAdminJobDetails(true);
      } else {
        this.$router.push('/job/' + value.id);
      }
    },
    handleClickSite(value) {
      if (this._compType === 3) {
        this._toggleEdit({ id: value.site_id, toggle: true });
      }
    },
    handleClickTeam(value) {
      if (this._compType === 3) {
        this._toggleEditing({ toggle: true, user: value });
      }
    },
    closeCreate() {
      this.edit = false;
    },
    mapCategory(category) {
      if (!category) {
        return;
      }
      switch (category) {
        case 'resale':
          return 'Certificate of Resale';
        default:
          return category.charAt(0).toUpperCase() + category.slice(1);
      }
    },
    getUserTypeText(type) {
      let text;
      switch (type) {
        case 1:
          text = 'Admin';
          break;
        case 3:
          text = 'Supper';
          break;
        case 2:
          text = 'Standard';
          break;
        default:
          text = 'Poc';
          break;
      }
      return text;
    },
    ...mapActions({
      _getVendor: 'vendors/getVendor',
      _setUsersCompany: 'user/getAllCompanyId',
      _setResults: 'sites/getResults',
      _setJob: 'jobs/setCurrent',
      _toggleEdit: 'sites/toggleEdit',
      _toggleEditing: 'user/toggleEditing'
    }),
    ...mapMutations({
      _setLoading: 'jobs/toggleLoading',
      _setAdminJobDetails: 'jobs/setAdminJobDetails',
      _setShowCompanyRejectDialog: 'company/setShowCompanyRejectDialog',
      _setCompanyStatusChange: 'company/setCompanyStatusChange',
      _setGoogleDoc: 'googledoc/setShowGoogleDocDialog',
      _setIndex: 'googledoc/setIndex',
      _setList: 'googledoc/setList'
    }),
    openCompanyRejectDialog() {
      console.log('_setShowCompanyRejectDialog(true)');
      this._setCompanyStatusChange(this.selectedStatus);
      this._setShowCompanyRejectDialog(true);
    },
    showGoogleDocIndex(index) {
      this._setList(this._vendor.attachments);
      this._setIndex(index);
      this._setGoogleDoc(true);
    }
  }
};
</script>

<style>
/*
.v-text-field > .v-input__control > .v-input__slot::before{
  width:0px
}
div#divSelectStatus.d-flex.md-justify-content-center div.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select div.v-input__control div.v-input__slot div.v-select__slot div.v-select__selections
{
  display:block;margin-top: -5px;
}
 #SelectedStatusActive{
 margin-top: 20px;
margin-left: 10px;
}
*/
.vendor-detail .w-100 {
  width: 100% !important;
}
.vendor-detail-section {
  margin-bottom: 3rem;
}
.vendor-detail-title-text-admin {
  font-size: 1.5rem;
}

.vendor-detail .pt-0 {
  padding-top: 0 !important;
}
.vendor-detail .pb-0 {
  padding-bottom: 0 !important;
}
.simple-table,
.vendor-company-information-content,
.vendor-detail-section-4 .v-data-table {
  border: 1px solid #acacac;
  border-radius: 4px;
}
.vendor-company-information-content {
  height: calc(100% - 52px);
}
.vendor-detail .v-data-table.table-no-border td,
.vendor-detail .table-no-border th,
.vendor-detail .table-no-border .v-data-footer {
  border: 0 !important;
}
.vendor-company-information label {
  font-weight: bold;
}
.vendor-detail .btn-company-info-archive,
.vendor-detail .btn-company-info-restore {
  padding: 5px 20px;
  box-shadow: none;
}
.vendor-detail .btn-company-info-archive {
  border-radius: 30px;
  background: #e53935 !important;
  color: #fff;
  margin-right: 20px;
}
.vendor-detail .btn-company-info-restore {
  border-radius: 30px;
  background: rgb(0, 162, 8) !important;
  color: #fff;
}
.vendor-detail .m-0 {
  margin: 0;
}
.vendor-detail .justify-content-center {
  justify-content: center;
}
.vendor-detail .align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.job-list {
  border-radius: 4px;
  border: 1px solid #acacac;
}
.vendor-detail-documents table td {
  border-bottom: 0 !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.vendor-circle-black span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #000000;
  border: 1px solid #000;
  margin-right: 8px;
}
.vendor-circle-white span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #000;
  margin-right: 8px;
}
.vendor-detail .v-card__title {
  font-size: 26px;
}
.vendor-detail .vendor-detail-back {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .vendor-detail-right {
    padding-left: 30px;
  }
  /* .vendor-detail-left {
    padding-right: 0px;
  } */
  .vendor-detail-abilities-tr {
    width: 150px;
  }
  .vendor-detail-abilities-yes-no {
    display: flex;
    justify-content: space-between;
  }
  .vendor-detail-section-4 .v-data-table {
    height: calc(100% - 64px);
  }
  .vendor-detail-section-2 .v-data-table {
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .md-justify-content-center {
    justify-content: center;
  }
  .vendor-detail-abilities-yes-no label {
    margin-bottom: 5px;
  }
}
</style>
