<template>
<div style="height: calc(100vh - 64px); width: 100%; display: flex; align-items: center; justify-content: center; flex-wrap: wrap">
  <div style="text-align: center">
    <h2 class="d-block" style="font-size: 1.5rem; font-weight: normal">We'll miss you!</h2>
    <p>You have <strong>unsubscribed</strong> now.</p>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      _user: "user/getUser",
    })
  },
  data() {
    return {}
  },
  mounted() {
    let params = {
      userEmail: '',
      userId: '',
    }
    let query = this.$route.query;
    if( query.hasOwnProperty('userEmail') ){
      params.userEmail = query.userEmail
    }
    if( query.hasOwnProperty('userId') ){
      params.userId = query.userId
    }
    this._sendmail(params);
  },
  methods: {
    ...mapActions({
      _sendmail: "user/emailOptOut",
    }),
  }
}
</script>

<style scoped>

</style>