<template>
  <v-dialog
     
    v-model="_JobNoLongerOpenDialog"
    @click:outside="closeDialog()"
    max-width="400px" content-class="v-dialog-customize"
  >
    <v-card flat class="wrapper">
      <div class="btn-dismiss-dialog">
        <v-btn text @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-container class="pl-md-8">
        <v-card flat>
          <div style="text-align:center;margin-left:50px;margin-right:50px"><img style="width:36px" src="/warning-icon.png"><br>
          <span style="color:#838ef2;font-size:20px;font-weight:bold">This job is no longer <br>open for bids.</span>
          </div>

          
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>
 
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  props: {
    // confirm: {
    //   default: true,
    //   type: Boolean,
    // },
  },
 

  mounted() {
    
  },

  computed: {
    ...mapGetters({
   //   _file: "uploads/getFile",
      _JobNoLongerOpenDialog: "jobs/getShowJobNoLongerOpenDialog",
   //   _job: "jobs/getCurrentJob",
   //   _uploading: "uploads/loading",
    }),
    // today() {
    //   return moment().format("YYYY-MM-DD");
    // },
    // job: {
    //   set(val) {
    //     this._setJob(val);
    //   },
    //   get() {
    //     return this._job;
    //   },
    // },
  },
  watch: {
     
  },
  methods: {
    ...mapMutations({
      _setShowJobNoLongerOpenDialog: "jobs/setShowJobNoLongerOpenDialog",
      _setClosingConf: "jobs/setClosingConf",
    }),

    ...mapActions({
     // _updateBid: "bids/updateBid",
     // _uploadFile: "uploads/uploadFile",
     // _updateJob: "jobs/updateJob",
     // _setJob: "jobs/setJob",
    }),

    closeDialog() {
      this._setShowJobNoLongerOpenDialog(false);
      this._setClosingConf(false);  
      console.log("This job is no longer open for bids. Redirecting to open job list view.");
      this.$router.push("/job");
        //if (window.history.length > 2) {
        
        //    this.$router.go(-1);
        //}

    },

    
  },
};
</script>
<style>
.v-dialog-customize{
       border: 10px solid !important;
       border-color: #44546a !important;
    }
</style>
