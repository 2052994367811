<template>
  <v-card outlined height="calc(100% - 16px)" class="ma-2">
    <v-toolbar flat>
      <v-toolbar-title class="">Vendor Bid Details</v-toolbar-title>
      <v-spacer />
      <v-row
        no-gutters
        class="mt-2"
        v-if="
          (_job.status === 'closing' || _job.status === 'resale') &&
            _company.type_id === 1
        "
      >
        <v-spacer />
        <v-btn color="error" class="mr-2" @click="rejectOffer()">Reject</v-btn>
        <v-btn color="secondary" @click="acceptOffer()">Accept</v-btn>
      </v-row>
    </v-toolbar>
    <v-divider />
    <!-- <v-card  flat  v-if="job.recycle_specs.resale == false && job.bid_per_pound !== null"  class="pa-4" > huy removed rgx-133-->
    <v-card flat class="pa-4">
      <v-simple-table style="width:100%">
        <tr>
          <td colspan="7" style="font-weight:500">Vendor Purchase Offer</td>
        </tr>
        <tr style="height:60px;border-bottom: 1px solid">
          <td style="padding-left:10px">Initial Scrap Proceeds</td>
          <td class="text-center">
            <span v-if="bid.info.show_detail_initial_scrap_proceeds">
              Price/lb Bid
              <br />
              {{ numberWithCommas(bid.bid_per_pound) }}
            </span>
          </td>
          <td class="text-center">
            <span v-if="bid.info.show_detail_initial_scrap_proceeds">x</span>
          </td>
          <td class="text-center">
            <span v-if="bid.info.show_detail_initial_scrap_proceeds">
              Final Weight
              <br />
              {{ bid.final_weight || 0 }}
            </span>
          </td>
          <td class="text-center" style="width: 40px;">
            <span v-if="bid.info.show_detail_initial_scrap_proceeds">=</span>
          </td>
          <td>$</td>
          <td style="width: 80px;" class="text-right">
            {{ numberWithCommas(bid.info.initial_scrap_proceeds) }}
          </td>
        </tr>

        <tr style="height:51px;">
          <td colspan="7" style="font-weight:500">
            Customer Expenses and Fees
          </td>
        </tr>

        <tr style="height:35px;" v-if="bid.info.scenario != 3">
          <td colspan="5" style="padding-left:10px">Customer Service Charge</td>
          <td>$</td>
          <td style="width: 80px;" class="text-right">
            <span
              v-if="
                !bid.info.customer_service_charge ||
                  bid.info.customer_service_charge == 0
              "
            >
              {{ numberWithCommas(bid.info.customer_service_charge) }}
            </span>
            <font color="red" v-if="bid.info.customer_service_charge > 0">
              ({{ numberWithCommas(bid.info.customer_service_charge) }})
            </font>
          </td>
        </tr>
        <tr style="height:51px;border-bottom: 1px solid">
          <td colspan="5" style="padding-left:10px">RGX Transaction Fee</td>
          <td>$</td>
          <td style="width: 80px;" class="text-right">
            <span v-if="bid.info.rgx_transaction_fee == 0">
              {{ numberWithCommas(bid.info.rgx_transaction_fee) }}
            </span>
            <font color="red" v-if="bid.info.rgx_transaction_fee > 0">
              ({{ numberWithCommas(bid.info.rgx_transaction_fee) }})
            </font>
          </td>
        </tr>

        <tr style="height:51px;">
          <td colspan="5">
            <span
              style=" text-transform: uppercase;font-weight:900"
              v-if="
                bid.info.total_you_owe <= 0 && bid.info.total_vendor_owes <= 0
              "
            >
              Total You Owe
            </span>
            <span
              style=" text-transform: uppercase;font-weight:900"
              v-if="bid.info.total_vendor_owes > 0"
            >
              Total You Will Receive
            </span>
          </td>
          <td>$</td>
          <td style="width: 80px;" class="text-right">
            <span
              v-if="
                bid.info.total_you_owe == 0 && bid.info.total_vendor_owes <= 0
              "
            >
              <font style="font-weight:900">
                {{ numberWithCommas(bid.info.total_you_owe) }}
              </font>
            </span>
            <span v-if="bid.info.total_you_owe < 0">
              <font color="red" style="font-weight:900">
                {{ numberWithCommas(bid.info.total_you_owe) }}
              </font>
            </span>

            <span
              v-if="
                bid.info.total_vendor_owes > 0 && bid.info.total_you_owe <= 0
              "
            >
              <font color="green" style="font-weight:900">
                {{ numberWithCommas(bid.info.total_vendor_owes) }}
              </font>
            </span>
          </td>
        </tr>
      </v-simple-table>

      <!-- old code 
   <v-row  style="padding:10px 0px 10px 0px">
         <v-col sm="12" class="ma-0 pa-0"> 
          <v-card-text class="py-0"  style="font-weight:500">
            Vendor Purchase Offer
          </v-card-text>
          </v-col>
     </v-row> 
     
      <v-row  style="padding:10px 0px 10px 0px" v-if="bid.info.show_detail_initial_scrap_proceeds">
        <div>
          <v-card-text class="v-card__subtitle"> Initial Scrap Proceeds </v-card-text>
        </div> <div>
          <v-card-text class="pa-0 text-center v-card__subtitle"> Price/lb Bid </v-card-text>
          <v-card-text class="v-card__subtitle">
            {{  numberWithCommas(bid.bid_per_pound) }}
          </v-card-text>
        </div>
       

        <div>
          <v-card-text style="margin-top:5px" class="v-card__subtitle"> x </v-card-text>
        </div>
        <div>
          <v-card-text class="pa-0 v-card__subtitle"> Final Weight </v-card-text>
          <v-card-text class="v-card__subtitle text-center">
            {{ (bid.final_weight || 0 ) }}  
          </v-card-text>
        </div>
       
        <div>
          <v-card-text style="margin-top:5px" class="v-card__subtitle"> = </v-card-text>
        </div>
        <div>
        
          <v-card-text style="margin-top:5px;width: 140px;"   class="v-card__subtitle text-right"><div style="float:left">$</div>  {{ numberWithCommas( bid.info.initial_scrap_proceeds) }} </v-card-text>
        </div>
     
        </v-row> 
      <v-row v-if="!bid.info.show_detail_initial_scrap_proceeds" no-gutters class="justify-space-between pa-0 border-bottom">
        
        <div>
          <v-card-text class="v-card__subtitle"> Initial Scrap Proceeds </v-card-text>
        </div>
        <div>
          <v-card-text class="pa-0 v-card__subtitle">   </v-card-text>
          <v-card-text class="num-font pa-0 v-card__subtitle">
       
          </v-card-text>
        </div>

        <div>
          <v-card-text style="margin-top:5px" class="v-card__subtitle">   </v-card-text>
        </div>

        <div>
          <v-card-text class="pa-0 text-center v-card__subtitle">  </v-card-text>
          <v-card-text class="v-card__subtitle">
           
          </v-card-text>
        </div>
        <div>
          <v-card-text style="margin-top:5px" class="v-card__subtitle">   </v-card-text>
        </div>
        <div>
        
          <v-card-text style="margin-top:5px;width: 140px;"   class="v-card__subtitle text-right"><div style="float:left">$</div>  {{ numberWithCommas( bid.info.initial_scrap_proceeds )}} </v-card-text>
        </div>
        
      </v-row>    
       <v-row style="padding: 0px 0px 10px 0px;"   >
         <v-col sm="12" class="ma-0 pa-0"> 
          <v-card-text class="py-0" style="font-weight:500">
           Customer Expenses and Fees 
          </v-card-text>
          </v-col>
     </v-row>

   
      <v-row no-gutters class="justify-space-between pa-0 border-bottom" >
        
        <div>
          <v-card-text v-if="bid.info.scenario!=3" class="v-card__subtitle"> Customer Service Charge  </v-card-text>  
           <v-card-text class="v-card__subtitle"> RGX Transaction Fee  </v-card-text>
        </div>
         
        <div>
         <v-card-text v-if="bid.info.scenario!=3" id="customer_service_charge" class="v-card__subtitle text-right" style="width: 140px;"><div style="float:left">$</div> <span v-if="!bid.info.customer_service_charge||bid.info.customer_service_charge==0"> {{numberWithCommas( bid.info.customer_service_charge)}}</span> <font color='red' v-if="bid.info.customer_service_charge>0"> ({{  numberWithCommas( bid.info.customer_service_charge)  }}) </font> </v-card-text>
 
         <v-card-text id="rgx_transaction_fee" class="v-card__subtitle text-right" style="width: 140px;"><div style="float:left">$</div> <span v-if="bid.info.rgx_transaction_fee==0"> {{numberWithCommas( bid.info.rgx_transaction_fee)}}</span>  <font color='red' v-if="bid.info.rgx_transaction_fee>0">({{   numberWithCommas( bid.info.rgx_transaction_fee )  }})</font>  </v-card-text>
        </div>
        
      </v-row> 
     
     <v-row no-gutters class="justify-space-between pa-0  ">
        
        <div >
          <v-card-text  style="padding: 16px 0px 0px 5px;text-transform: uppercase;font-weight:900"  v-if="(bid.info.total_you_owe<=0&&bid.info.total_vendor_owes<=0)" > Total You Owe  </v-card-text>  
           <v-card-text   style="padding: 16px 0px 0px 5px;text-transform: uppercase;font-weight:900"  v-if="bid.info.total_vendor_owes>0" >  Total You Will Receive   </v-card-text>
        </div>
         
        <div>
                   <v-card-text v-if="bid.info.total_you_owe==0&&bid.info.total_vendor_owes<=0" style="margin-top:5px;;width: 140px;"  class="v-card__subtitle text-right"><div style="float:left">$</div><font  style="font-weight:900">  {{    numberWithCommas(bid.info.total_you_owe) }} </font> </v-card-text>
         <v-card-text v-if="bid.info.total_you_owe<0" style="margin-top:5px;;width: 140px;"  class="v-card__subtitle text-right"><div style="float:left">$</div><font color="red" style="font-weight:900"> {{    numberWithCommas(bid.info.total_you_owe) }} </font> </v-card-text>
 
          <v-card-text v-if="bid.info.total_vendor_owes>0&&bid.info.total_you_owe<=0" style="margin-top:5px;;width: 140px;"   class="v-card__subtitle text-right"><div style="float:left">$</div><font color="green" style="font-weight:900">  {{   numberWithCommas( bid.info.total_vendor_owes ) }}</font> </v-card-text>
        </div>
       
      </v-row> 
end old case-->
      <!--
      <v-row
        class="d-flex row flex-nowrap justify-space-between no-gutters pa-0"
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="headline num-font text-left ma-0 px-0">
            Total
          </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="headline text-right ma-0 px-0">
            {{ "$" + total }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      v-if="job.recycle_specs.resale == false && job.bid_buy_out !== null"
      class="d-flex row no-gutters border-bottom"
    >
      <span class="headline mt-0 pa-0"> Total </span>
      <v-spacer />
      <span class="headline">
        {{ "$" + job.bid_buy_out }}
      </span>
    </v-card>
    <v-card
      flat
      v-if="job.recycle_specs.resale == false && job.bid_buy_out !== null"
      class="px-4 pt-4"
    >
      <v-row
        class="
          d-flex
          row
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            All In Offer
          </v-card-text>
        </v-col>
        <v-spacer />
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="text-right ma-0 px-0">
            ${{ job.bid_buy_out ? job.bid_buy_out.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row
        class="
          d-flex
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            Purchase Offer
          </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="warning--text text-right ma-0 px-0">
            - ${{ job.resale_offer ? job.resale_offer.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="d-flex row flex-nowrap no-gutters pa-0">
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="subtitle-2 mt-0 px-0"> Total </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0 text-right">
          <v-card-text v-if="job.resale_offer" class="headline px-0">
            {{
              "$" +
              (
                Number(job.bid_buy_out) -
                Number(job.resale_offer.replace(/,/g, ""))
              ).toFixed(2)
            }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      v-if="job.recycle_specs.resale == true && job.bid_buy_out !== null"
      class="px-4 pt-4"
    >
      <v-row
        class="
          d-flex
          row
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            All In Offer
          </v-card-text>
        </v-col>
        <v-spacer />
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="text-right ma-0 px-0">
            ${{ job.bid_buy_out ? job.bid_buy_out.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row
        class="
          d-flex
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            Purchase Offer
          </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="warning--text text-right ma-0 px-0">
            - ${{ job.resale_offer ? job.resale_offer.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="d-flex row flex-nowrap no-gutters pa-0">
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="subtitle-2 mt-0 px-0"> Total </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0 text-right">
          <v-card-text v-if="job.resale_offer" class="headline px-0">
            {{
              "$" +
              (
                Number(job.bid_buy_out) -
                Number(job.resale_offer.replace(/,/g, ""))
              ).toFixed(2)
            }}
          </v-card-text>
        </v-col>
      </v-row>
      -->
    </v-card>

    <!--
    <v-row v-if="confirm" class="d-flex flex-row-nowrap no-gutters px-2">
      <v-btn
        rounded
        block
        :disabled="!cod || !finalValid"
        color="primary"
        class="submit-btn"
        :loading="_loading"
        @click="confirmClose"
        >Confirm</v-btn
      >
    </v-row> -->
  </v-card>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  props: {
    finalValid: Boolean,
    confirm: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      _bid: 'bids/getCurrentBidInfo',
      // _loading: "jobs/loading",
      _company: 'company/getCompany'
    }),
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    },
    bid: {
      set(val) {
        this._setBid(bid);
      },
      get() {
        return this._bid;
      }
    },
    total() {
      if (this.job.bid_per_pound === null) {
        return (
          Number(this.job.bid_buy_out) +
          Number((this.job.resale_offer || '0').replace(/,/g, ''))
        ).toFixed(2);
      } else {
        return this.job.resale_offer
          ? (
              Number(this.priceBeforeResale) +
              Number((this.job.resale_offer || '0').replace(/,/g, ''))
            ).toFixed(2)
          : this.priceBeforeResale;
      }
    },
    cod() {
      let cod = this.job.attachments.filter(o => o.catagory === 'cod');
      return cod.length > 0 ? true : false;
    },
    priceBeforeResale() {
      let price = this.job.final_weight * this.job.bid_per_pound;
      return this.job.final_weight !== undefined ? price.toFixed(2) : 0.0;
    }
  },
  methods: {
    ...mapActions({
      _updateJob: 'jobs/updateJob',
      _sendEmailClosedJobInfo: 'jobs/sendEmailClosedJobInfo'
    }),
    ...mapMutations({
      _setJob: 'jobs/setJob',
      _setBid: 'bids/setCurrentBidInfo'
    }),
    acceptOffer() {
      let job = {};
      job.status = 'closed';
      job.bid_status = 'closed';
      job.id = this.job.job_id;
      this._updateJob(job).then(() => {
        this._sendEmailClosedJobInfo(job.id);
        this.$router.push('/history');
      });
    },
    rejectOffer() {
      let job = {};
      job.status = 'dispute';
      job.bid_status = 'dispute';
      job.id = this.job.job_id;
      this._updateJob(job);
    },
    confirmClose() {
      this.$emit('confirm');
    },
    numberWithCommas(x) {
      if (x == null) {
        return '0.00';
      }

      // let v= (Math.round(x * 100) / 100).toFixed(2);
      // v= v.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      // return x>=0?v : "("+v.replace("-","")+")";
      let v = Number(x.toString().replaceAll(',', ''));
      let y = v.toLocaleString('en-US', { minimumFractionDigits: 2 });
      return v >= 0 ? y : '(' + y.replace('-', '') + ')';
    }
  }
};
</script>
