<template>
  <v-dialog max-width="500" v-model="_siteDialog" @click:outside="closeDialog()">
    <v-card flat v-if="!confEdit && !confDelete">
      <div class="btn-dismiss-dialog">
        <v-btn text @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        <span class="headline" v-if="_compType === 3">Site</span>
        <span class="headline" v-else>{{ !_edit ? `Add A New ` : `Edit` }} Site</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="siteForm" v-model="valid">
          <v-text-field
            v-model="site.name"
            :rules="[rules.required]"
            prepend-icon="mdi-home-city"
            label="Site Name *"
            required
          ></v-text-field>
          <v-row no-gutters>
            <!-- <v-col cols="12" sm="2" class="pt-0">
							<v-text-field
								v-model="timezone"
								disabled
								label="Timezone"
								prepend-icon="mdi-briefcase-clock"
							/>
						</v-col> -->
            <v-col cols="12" sm="6" class="pr-2">
              <v-text-field v-model="site.open" type="time" label="Open Time" prepend-icon="mdi-clock" />
            </v-col>
            <v-col cols="12" sm="6" class="pl-2">
              <v-text-field type="time" v-model="site.close" label="Close Time" prepend-icon="mdi-clock" />
            </v-col>
          </v-row>
          <VueGooglePlaces
            :api-key="googleKey"
            enableGeolocation
            version="3.41"
            style="width: 100%"
            country="us"
            types="address"
            @placechanged="onPlaceChanged"
          >
            <v-text-field prepend-icon="mdi-map-marker" :label="`Address Finder...`" :placeholder="finderPlaceholder" v-model="address" />
          </VueGooglePlaces>
          <v-card class="pl-8" flat>
            <v-row no-gutters>
              <v-col cols="6" sm="9">
                <v-text-field label="Address *" :rules="[addressRules.required]" dense v-model="site.street" class="pr-2" />
              </v-col>
              <v-col sm="3">
                <v-text-field :rules="[cityRules.required]" dense label="City *" v-model="site.city" />
              </v-col>
              <!--<v-col sm="6">
                <v-text-field
                  dense
                  label="State *"
                  :rules="[stateRules.required]"
                  v-model="site.state"
                  class="pr-2"
                />
              </v-col>-->

              <v-col cols="6" sm="6">
                <v-autocomplete
                  v-model="site.state"
                  :items="usStates"
                  item-text="text"
                  item-value="value"
                  label="State *"
                  :rules="[stateRules.required]"
                  class="pr-2"
                />
              </v-col>

              <v-col cols="6" sm="3">
                <v-text-field
                  dense
                  label="Zip Code *"
                  v-mask="'#####'"
                  :rules="[zipCodeRules.required, zipCodeRules.isCorrectFormat]"
                  class="pr-2"
                  v-model="site.zip"
                  style="margin-top: 22px"
                />
              </v-col>
              <v-col cols="4" sm="3">
                <v-text-field dense label="Suite" v-model="site.suite" style="margin-top: 22px" />
              </v-col>
            </v-row>
          </v-card>
          <v-row></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!_edit && _compType !== 3">
        <v-btn rounded color="error" @click="closeDialog()">Cancel</v-btn>
        <v-spacer />
        <v-btn rounded color="secondary" :disabled="!valid" :loading="_loading" @click="_createSite(site)">
          Create Site
        </v-btn>
      </v-card-actions>

      <v-card-actions v-if="_edit && _compType !== 3">
        <v-btn rounded color="error" @click="confDelete = !confDelete">
          Delete Site
        </v-btn>
        <v-spacer />
        <v-btn rounded :disabled="!valid" color="secondary" :loading="_loading" @click="updateSite()">
          Update Site
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card flat v-else-if="!confDelete && confEdit">
      <v-card-title>Site {{ !_edit ? `Created` : `Edited` }} Successfully!</v-card-title>
      <v-card-text v-if="!_edit">
        Verify and/or edit all details at the Sites page.
      </v-card-text>
      <v-card-text v-else>You have successfully edited site {{ _currentSite.name }}. Be sure to review any changes.</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn rounded color="secondary" @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
    <v-card flat v-else-if="confDelete && !confEdit">
      <v-card-title>Are you sure?</v-card-title>
      <v-card-text>Your about to delete {{ site.name }}</v-card-text>
      <v-card-actions>
        <v-btn rounded color="secondary" @click="closeDialog()">
          Keep Site
        </v-btn>
        <v-spacer />
        <v-btn rounded color="error" :loading="_loading" @click="_deleteSite(site.id)">
          Delete Site
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
var UsaStates = require('usa-states').UsaStates;
export default {
  directives: {
    mask
  },
  props: {
    edit: Boolean
  },
  components: {},
  data() {
    return {
      address: '',
      finderPlaceholder: null,
      manualEntry: true,
      confEdit: false,
      confDelete: false,
      openTime: false,
      closeTime: false,
      createConfirm: false,
      locationName: undefined,
      streetAdd: undefined,
      city: undefined,
      zip: undefined,
      locationType: undefined,
      state: undefined,
      usStates: new UsaStates({ includeTerritories: true }).states.map(state => {
        return {
          text: state.name + ' (' + state.abbreviation + ')',
          value: state.abbreviation
        };
      }),
      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v => (!new RegExp(/undefined/).test(this.site.street) && this.site.street !== undefined) || 'Full address is required'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v => new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) || 'Please enter a valid zip code'
      },
      rules: {
        required: v => !!v || 'Required.',
        zip: v => new RegExp(/^\d{5}(?:[-\s]\d{4})?$/).test(v) || 'Please enter a valid zip code',
        time: v => moment(this.site.open, 'HH:mm').isBefore(moment(this.site.close, 'HH:mm')) || 'Close time must come after open time',
        address: v => (!new RegExp(/undefined/).test(this.site.street) && this.site.street !== undefined) || 'Full address is required'
      },
      valid: false,
      siteOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['name'],
        sortDesc: [false]
      }
    };
  },
  computed: {
    ...mapGetters({
      _edit: 'sites/edit',
      _currentSite: 'sites/currentSite',
      _siteDialog: 'sites/siteDialog',
      _createdSite: 'sites/createdSite',
      _editedSite: 'sites/editedSite',
      _siteToEdit: 'sites/siteToEdit',
      _siteToCreate: 'sites/siteToCreate',
      _loading: 'sites/loading',
      _compType: 'company/getCompanyType',
      _company: 'company/getCompany'
    }),
    openHour: {
      get() {
        return moment(this.site.open).format('hh:mm A');
      },
      set(newVal) {
        this.site.open = newVal;
      }
    },
    googleKey() {
      return 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE'; // process.env.VUE_APP_GOOGLE_API_KEY;
    },
    site: {
      get() {
        return this._currentSite;
      },
      set(newVal) {
        this._setCurrentSite(newVal);
      }
    },
    timezone() {
      // eslint-disable-next-line camelcase
      var zipcode_to_timezone = require('zipcode-to-timezone');
      var tz = zipcode_to_timezone.lookup(this.site.zip);
      return tz;
    },
    validated() {
      if (this.locationName && this.streetAdd && this.city && this.zip && this.locationType && this.states) {
        return false;
      }
      return true;
    }
  },
  watch: {
    _editedSite() {
      var query_path = window.location.href.replace(window.location.origin, '');
      console.log('vo _editedSite', query_path == '/profile');
      //  console.log("vo _editedSite",window.location.href.replace(window.location.origin, ''))
      if (Object.keys(this._editedSite).length > 0) {
        this.confEdit = true;
        this.$refs.siteForm.reset();
      }
    },
    async _createdSite() {
      console.log('vo _createdSite');
      if (Object.keys(this._createdSite).length > 0) {
        this.confEdit = true;
        this.$refs.siteForm.reset();
      }
      await this._getSitesForDropdown(this.siteOptions);
    },
    _siteDialog() {
      console.log('vo _siteDialog', this._siteDialog);
      if (!this._siteDialog) {
        setTimeout(() => {
          this.confEdit = false;
          this.confDelete = false;
          this._toggleEdit(false);
        }, 1000);
      } else {
        setTimeout(() => {
          this.finderPlaceholder = ' ';
        }, 2000);
      }
    }
  },
  methods: {
    ...mapActions({
      _updateSite: 'sites/updateSite',
      _deleteSite: 'sites/deleteSite',
      _createSite: 'sites/createSite',
      _getSite: 'sites/getById',
      _sendEmailAccountingWhenBillingUpdated: 'jobs/sendEmailAccountingWhenBillingUpdated',
      _getSitesForDropdown: 'sites/getAllSitesForDropdown'
    }),
    ...mapMutations({
      _toggleEdit: 'sites/toggleEdit',
      _toggleDialog: 'sites/toggleDialog',
      _setCurrentSite: 'sites/setCurrentSite'
    }),
    format() {
      this.site.close = moment(this.site.close).format('hh:mm a');
    },
    formatOpen() {
      this.site.open = moment(this.site.close).format('hh:mm a');
    },
    siteAddress() {
      return this.site.street + ' ' + this.site.city + ', ' + this.site.state + ' ' + this.site.zip;
    },
    onPlaceChanged(e) {
      this.site.street = e.street_number + ' ' + e.route;
      this.site.city = e.locality;
      this.site.state = e.administrative_area_level_1;
      this.site.zip = e.postal_code;
      this.address = '';
      if (e.street_number !== undefined) {
        this.address = this.address.concat(e.street_number);
      }
      if (e.route !== undefined) {
        this.address = this.address.concat(' ' + e.route);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.locality !== undefined) {
        this.address = this.address.concat(e.locality);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.administrative_area_level_1 !== undefined) {
        this.address = this.address.concat(e.administrative_area_level_1);
      }
      if (e.postal_code !== undefined) {
        this.address = this.address.concat(' ' + e.postal_code);
      }
      this.address = this.address.concat(', USA');
    },
    validate() {
      if (this.form.validate()) {
        this.validated = true;
      }
    },
    closeDialog() {
      if (this.$refs.siteForm) {
        this.$refs.siteForm.reset();
      }
      this._toggleDialog(false);
    },
    async updateSite() {
      let resUpdateSite = await this._updateSite(this.site);
      const site_id = resUpdateSite.data.id;
      if (site_id == this._company.billing_site_id) {
        this._sendEmailAccountingWhenBillingUpdated();
      }
    }
  }
};
</script>
