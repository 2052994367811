<template>
  <div>
    <v-dialog
      @click:outside="emitClosePopup"
      v-model="isVisible"
      max-width="900"
      v-if="!!contentUrl"
    >
      <v-card flat class="pt-0 pb-4" style="overflow: hidden;">
        <v-row>
          <div class="btn-dismiss-dialog dialog-header">
            <v-btn text @click="emitClosePopup">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-row>
        <v-row style="padding-top: 40px;">
          <div>
            <iframe :src="contentUrl" width="900" height="580"></iframe>
          </div>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    contentUrl: String
  },
  methods: {
    emitClosePopup() {
      this.$emit('update:isVisible', false); // Phát ra sự kiện để component cha cập nhật trạng thái isVisible
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmation {
  font-size: 26px;
  font-weight: bold;
  color: #4caf50;
}
.subtitle-1 {
  font-weight: 500;
  font-size: 1.3em !important;
  padding-left: 0;
  padding-bottom: 0;
}

.subtitle-3 {
  padding-left: 0;
  padding-top: 5px;
  padding-bottom: 0;
}

// .theme--light.v-sheet--outlined {
//   border: none !important;
// }

// .v-toolbar__title {
//   padding-left: 24px;
// }
// .v-toolbar__content {
//   padding: 0;
// }

.dialog-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  text-align: right;
  width: 100%;
}
</style>
