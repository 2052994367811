<template>
  <div>
    <v-dialog v-model="isVisible" max-width="520" @click:outside="closePopup()">
      <v-card flat :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 100px)' : 'auto'" class="pt-2">
        <v-toolbar flat>
          <v-card-title class="pl-6" v-if="$vuetify.breakpoint.mdAndUp" @click="validateTest()">
            Internal Job Details
          </v-card-title>
          <v-spacer />
          <v-btn class="close-btn" text @click="closePopup()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="internalJobDetailsForm" v-model="internalJobDetailsForm" class="pl-10 pr-10 pb-8">
          <v-text-field
            v-model="tempJob.number"
            label="Internal Job ID"
            messages="Job name or number for internal tracking."
          ></v-text-field>
          <v-row no-gutters class="pt-4 pb-0 mb-0">
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                label="Project Manager*"
                persistent-hint
                :items="_users"
                :rules="[rules.required]"
                item-text="user_name"
                item-value="user_id"
                v-model="tempJob.project_manager_id"
                :disabled="ProjectManagerExpand"
                hint="This is the contact person responsible for the job from bidding through final approval."
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <div class="justify-end">
                <v-btn
                  rounded
                  color="secondary"
                  height="35"
                  elevation="0"
                  :disabled="ProjectManagerExpand"
                  class="white--text my-2"
                  v-on:click="ProjectManagerExpand = !ProjectManagerExpand"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-expand-transition>
                <div v-show="ProjectManagerExpand" class="mx-0">
                  <v-form ref="userForPMForm" v-model="userValid">
                    <v-text-field v-show="false" label="User Name" v-model="ProjectManager.user_name"></v-text-field>
                    <div class="flex-container">
                      <v-text-field
                        label="First Name *"
                        :rules="[rules.required]"
                        v-model="ProjectManager.first_name"
                      ></v-text-field>
                      <v-spacer />
                      <v-text-field label="Last Name" v-model="ProjectManager.last_name"></v-text-field>
                    </div>
                    <v-text-field
                      label="Email *"
                      :rules="[rules.required, rules.emailMatch]"
                      v-model="ProjectManager.email"
                    ></v-text-field>
                    <div class="flex-container">
                      <v-text-field
                        label="Phone *"
                        v-mask="'(###)###-####x######'"
                        :rules="[rules.required, rules.phoneLength]"
                        v-model="ProjectManager.phone"
                      ></v-text-field>
                      <v-spacer />
                      <v-text-field
                        label="Alt. Phone"
                        v-mask="'(###)###-####'"
                        v-model="ProjectManager.alt_phone"
                      ></v-text-field>
                    </div>
                  </v-form>
                  <div class="justify-end">
                    <v-btn
                      rounded
                      color="error"
                      elevation="0"
                      height="35"
                      class="white--text mb-2 mr-2"
                      @click="ProjectManagerExpand = !ProjectManagerExpand"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="secondary"
                      :disabled="!userValid"
                      elevation="0"
                      height="35"
                      class="white--text mb-2"
                      @click="createUser(ProjectManager, config.USER_TYPE.project_manager, 'pm')"
                      :loading="_ProjectManagerLoading"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>

          <v-row class="pt-0 mt-0 pb-0 mb-0">
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                required
                label="Logistics Point of Contact*"
                persistent-hint
                :items="_users"
                :rules="[rules.required]"
                item-text="user_name"
                item-value="user_id"
                v-model="tempJob.poc_id"
                :disabled="POCExpand"
                hint="This will be the person at the pickup site contacted by the awarded vendor to arrange logistics"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pb-0 mb-0">
              <div class="justify-end">
                <v-btn
                  rounded
                  color="secondary"
                  height="35"
                  elevation="0"
                  :disabled="POCExpand"
                  class="white--text pb-0 mb-0"
                  v-on:click="POCExpand = !POCExpand"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-expand-transition>
                <div v-show="POCExpand" class="mx-0">
                  <v-form ref="userForm" v-model="userValid">
                    <v-text-field v-show="false" label="User Name" v-model="POC.user_name"></v-text-field>
                    <div class="flex-container">
                      <v-text-field
                        label="First Name *"
                        :rules="[rules.required]"
                        v-model="POC.first_name"
                      ></v-text-field>
                      <v-spacer />
                      <v-text-field label="Last Name" v-model="POC.last_name"></v-text-field>
                    </div>
                    <v-text-field
                      label="Email *"
                      :rules="[rules.required, rules.emailMatch]"
                      v-model="POC.email"
                    ></v-text-field>
                    <div class="flex-container">
                      <v-text-field
                        label="Phone *"
                        v-mask="'(###)###-####x######'"
                        :rules="[rules.required, rules.phoneLength]"
                        v-model="POC.phone"
                      ></v-text-field>
                      <v-spacer />
                      <v-text-field label="Alt. Phone" v-mask="'(###)###-####'" v-model="POC.alt_phone"></v-text-field>
                    </div>
                  </v-form>

                  <div class="justify-end">
                    <v-btn
                      rounded
                      color="error"
                      elevation="0"
                      height="35"
                      class="white--text mb-0 mr-2"
                      @click="POCExpand = !POCExpand"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="secondary"
                      :disabled="!userValid"
                      elevation="0"
                      height="35"
                      class="white--text mb-0"
                      @click="createUser(POC, config.USER_TYPE.poc, 'poc')"
                      :loading="_POCLoading"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
          <v-row class="pt-0 mt-0" v-if="tempJob.checked_vendor">
            <v-col cols="12" sm="4" md="4" class="align-self-center pr-0">
              <v-checkbox class="pr-0 mr-0" v-model="vendor_code" label="Vendor Codes"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="8" md="8" v-if="vendor_code" class="pr-2">
              <div style="margin-top:10px">
                <b-form-tags
                  required
                  :class="[
                    'pl-0',
                    'ml-0',
                    { 'b-form-tags-error': !vendorsAssignedValid || vendorsAssignedValid.length == 0 }
                  ]"
                  input-id="vendor-state-event"
                  v-model="vendorsAssigned"
                  :tag-validator="validatorVendorCode"
                  placeholder="Vendor Codes"
                  separator="[' ', ',', ';']"
                  @input="onChangeVendorAssigned"
                  remove-on-delete
                  duplicate-tag-text="Duplicate Vendor"
                  invalid-tag-text="Invalid Vendor Code"
                ></b-form-tags>
                <div
                  v-if="!vendorsAssignedValid || vendorsAssignedValid.length == 0"
                  class="v-text-field__details pt-2"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message" style="">Required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="4" v-if="vendor_code"></v-col>
            <v-col cols="12" sm="8" md="8" v-if="vendor_code" class="pt-0 mt-0">
              <tr v-for="vendor in vendorsAssignedInfo" :key="vendor.id" class="table-row-spacing">
                <td style="text-align: right" :class="{ 'error--text': !vendor.isValid }">
                  {{ vendor.id }}
                </td>
                <td>
                  <i :class="['mdi', vendor.isValid ? 'mdi-check secondary--text' : 'mdi-close error--text']"></i>
                </td>
                <td :class="{ 'error--text': !vendor.isValid }">
                  {{ vendor.isValid ? vendor.name : vendor.errorMessage }}
                </td>
              </tr>
            </v-col>
          </v-row>
          <v-divider />
          <v-card flat>
            <v-row no-gutters class="mt-4 ma-3">
              <span class="error-title pointer mt-2" @click="closePopup()">
                Cancel
              </span>
              <v-spacer />
              <div>
                <v-btn
                  :disabled="!isValidForm"
                  rounded
                  :loading="_jobLoading"
                  color="secondary"
                  height="35"
                  class="white--text"
                  @click="saveChanges()"
                >
                  Save
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
// import config from '../../config';
import _ from 'lodash';
import config from '@/config';
import { mask } from 'vue-the-mask';
import { BFormTags } from 'bootstrap-vue';
export default {
  directives: {
    mask
  },
  components: {
    BFormTags
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    job: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      config: config,
      tempJob: { ...this.job },
      userOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['user_name'],
        sortDesc: [false]
      },
      rules: {
        onlyNumbersRule: v => /^\d*$/.test(v) || 'Only numbers are allowed',
        required: v => !!v || 'Required.',
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        phoneLength: v => (v && v.length >= 13) || 'Invalid phone number.'
      },
      ProjectManagerExpand: false,
      ProjectManager: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: config.USER_TYPE.project_manager,
        company_id: this._company_id
      },
      userValid: false,
      POCExpand: false,
      POC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: config.USER_TYPE.poc,
        company_id: this._company_id
      },
      typeOfNewUser: null,
      isValidForm: false,
      stableJob: {},
      vendor_code: false,
      vendorsAssigned: [],
      vendorsAssignedInfo: [],
      vendorsAssignedValid: [],
      allVendorIsValid: true
    };
  },
  computed: {
    ...mapGetters({
      _users: 'user/getUsers',
      _jobLoading: 'jobs/loading',
      _POCLoading: 'user/loading',
      _ProjectManagerLoading: 'user/loading',
      _user: 'user/getUser',
      _newPOC: 'user/getNewTeamUser',
      _newProjectManager: 'user/getNewTeamUser'
    }),
    user: {
      get() {
        return this._user;
      },
      set(val) {
        this._setUser(val);
      }
    }
  },
  mounted() {
    if (this.tempJob.vendor_codes && this.tempJob.checked_vendor) {
      this.vendorsAssigned = this.tempJob.vendor_codes;
      this.vendorsAssignedInfo = this.tempJob.vendorsAssignedInfo.map(vendor => {
        return {
          isValid: true,
          ...vendor
        };
      });
      this.vendorsAssignedValid = this.tempJob.vendor_codes;
      this.vendor_code = true;
    }
    this.stableJob = _.cloneDeep(this.tempJob);
    this.$nextTick(() => {
      this.isValidForm = this.$refs.internalJobDetailsForm.validate();
    });
  },
  watch: {
    _users() {
      switch (this.typeOfNewUser) {
        case 'poc':
          this.tempJob.poc_id = this._newPOC.newUser.id;
          break;
        case 'pm':
          this.tempJob.project_manager_id = this._newProjectManager.newUser.id;
          break;
        default:
        // code block
      }
    },
    _newPOC: {
      handler: function() {
        //this._job.poc_id = this._newPOC.id;
        this.$refs.userForm.reset();
        this.POCExpand = false;
        this._getUsers(this.userOptions);
      },
      deep: true
    },
    _newProjectManager: {
      handler: function() {
        //this._job.project_manager_id = this._newProjectManager.id;
        this.$refs.userForPMForm.reset();
        this.ProjectManagerExpand = false;
        this._getUsers(this.userOptions);
      },
      deep: true
    },
    tempJob: {
      handler(newVal) {
        this.validateForm();
      },
      deep: true
    },
    vendor_code: {
      handler(newVal) {
        this.validateForm();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      _updateJob: 'jobs/updateJob',
      _getVendorById: 'company/getVendorById',
      _createUser: 'user/createUser',
      _newUser: 'user/getNewTeamUser',
      _getUsers: 'user/getAll',
      _getCompanyByListId: 'company/getCompanyByListId'
    }),
    onlyNumber($event) {
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes($event.key)) {
        $event.preventDefault();
      }
    },
    createUser: async function(newUser, userType, typeNameOfUser) {
      if (newUser.email !== undefined) {
        this.typeOfNewUser = typeNameOfUser;
        newUser.type_id = userType;
        newUser.password = 'N/A';
        newUser.createdEmail = this._user.email;
        newUser.createdUser = this._user.first_name + ' ' + (this._user.last_name ? this._user.last_name : '');
        newUser.user_name = newUser.email;
        await this._createUser(newUser);
      }
    },
    saveChanges: async function() {
      let payload = {
        ...this.tempJob,
        vendor_codes: this.vendor_code ? this.vendorsAssignedValid : null
      };

      if (payload.vendor_codes) {
        let oldVendorCodes = this.stableJob.vendor_codes.map(vendor => vendor.toString());
        let newVendorCodes = payload.vendor_codes.map(vendor => vendor.toString());
        let vendorAdded = newVendorCodes.filter(element => !oldVendorCodes.includes(element));

        if (vendorAdded.length > 0) {
          payload.isSendMailWhenChangeVendorId = true;
          payload.listVendorId = vendorAdded.join(',');
        }
      }
      let res = await this._updateJob(payload);
      this.closePopup();
    },
    closePopup() {
      this.$emit('close');
    },
    validatorVendorCode(vendorCode) {
      const regex = /^\d{1,5}$/; //only allow 5 char
      return regex.test(vendorCode);
    },
    onChangeVendorAssigned: _.debounce(async function() {
      let customerNeedCert = this.tempJob.required_cert;
      let vendorResponse = await this._getCompanyByListId({
        ids: this.vendorsAssigned,
        companyType: config.COMPANY_TYPE.VENDOR
      });
      this.vendorsAssignedInfo = [];
      this.vendorsAssignedValid = [];
      this.allVendorIsValid = true;
      for (let vendorId of this.vendorsAssigned) {
        let vendor = vendorResponse.find(item => item.id == vendorId);
        if (vendor) {
          if (vendor.status == this.config.VENDOR_STATUS.ACTIVE) {
            if (customerNeedCert) {
              if (vendor.has_cert) {
                this.vendorsAssignedInfo.push({
                  isValid: true,
                  ...vendor
                });
                this.vendorsAssignedValid.push(vendorId);
              } else {
                this.vendorsAssignedInfo.push({
                  isValid: false,
                  id: vendorId,
                  errorMessage: this.config.CHECK_VENDOR_MESSAGE.INELIGIBLE_VENDOR
                });
                this.allVendorIsValid = false;
              }
            } else {
              this.vendorsAssignedInfo.push({
                isValid: true,
                ...vendor
              });
              this.vendorsAssignedValid.push(vendorId);
            }
          } else {
            this.allVendorIsValid = false;
            this.vendorsAssignedInfo.push({
              isValid: false,
              id: vendorId,
              errorMessage: this.config.CHECK_VENDOR_MESSAGE.INACTIVE
            });
          }
          // if (vendor.status == this.config.VENDOR_STATUS.ACTIVE) {
          //   this.vendorsAssignedInfo.push({
          //     isValid: true,
          //     ...vendor
          //   });
          //   this.vendorsAssignedValid.push(vendorId);
          // } else {
          //   this.allVendorIsValid = false;
          //   this.vendorsAssignedInfo.push({
          //     isValid: false,
          //     id: vendorId,
          //     errorMessage: this.config.CHECK_VENDOR_MESSAGE.INACTIVE
          //   });
          // }
        } else {
          this.allVendorIsValid = false;
          this.vendorsAssignedInfo.push({
            isValid: false,
            id: vendorId,
            errorMessage: this.config.CHECK_VENDOR_MESSAGE.INVALID
          });
        }
      }
      this.validateForm();
    }, 500),
    validateForm() {
      this.isValidForm =
        this.$refs.internalJobDetailsForm.validate() && (!this.vendor_code || this.vendorsAssignedValid.length > 0);
    }
  }
};
</script>

<style scoped lang="scss">
/*-------fix conflict css with bootstrap-vue--------- */
label {
  margin-bottom: 0rem !important;
}

a:hover {
  text-decoration: none !important;
  outline: 0;
}

small.text-muted {
  padding-left: 25px;
  color: #e53935 !important;
}

.d-block.invalid-feedback {
  padding-left: 25px;
  color: #e53935 !important;
}

// @import '~bootstrap/dist/css/bootstrap.css';
// @import '~bootstrap-vue/dist/bootstrap-vue.css';

/*-------- end fix conflict css with bootstrap-vue------*/

div.v-card__subtitle.t0 {
  font-size: 18px;
  font-weight: bold;
}

.card-subtitle-review {
  color: #5d5d5d;
  font-size: 1.25em;
  font-weight: bold;
}

.table-row-spacing td {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 16px;
}

.table-row-spacing td tr {
  padding-right: 10px;
}

b-form-tags {
  padding-left: 0px;
  margin-left: 0px;
}

.b-form-tags-error {
  border-color: #e53935 !important;
}

.b-form-tags-error:focus,
.b-form-tags-error:focus-within {
  border-color: #e53935 !important;
}

.b-form-tags {
  color: #5d5d5d !important;
}

.b-form-tags:focus,
.b-form-tags:hover,
.b-form-tags:focus-within {
  border-color: #143e55;
  caret-color: #143e55;
  outline: none;
  box-shadow: none;
}
</style>
