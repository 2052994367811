import api from "../api";

export function createCompany(company) {
  return api.post(`/company`, company);
}

export function getCompanyByListId(payload) {
  return api.post(`/company/getByListId`, payload);
} 
// export function updateCompanyStatus(company) {
//   return api.put(`/company/status`, company);
// }