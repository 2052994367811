<template>
  <v-dialog persistent v-model="visible" max-width="500" @click:outside="close">
    <v-card v-if="!showSuccessDialog">
      <div class="btn-dismiss-dialog">
        <v-btn text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>Edit User</v-card-title>
      <v-card-text>
        <v-form ref="userForm" v-model="valid">
          <v-text-field
            label="User ID"
            v-show="false"
            prepend-icon="mdi-account-outline"
            v-model="_user.id"
          />
          <v-text-field
            label="First Name"
            prepend-icon="mdi-account-circle"
            v-model="_user.first_name"
          />
          <v-text-field
            label="Last Name"
            prepend-icon="mdi-account-circle-outline"
            v-model="_user.last_name"
          />
          <v-text-field
            label="Email"
            required
            :rules="[rules.email]"
            prepend-icon="mdi-email"
            v-model="_user.email"
          />
          <v-text-field
            label="Phone"
            v-mask="'(###)###-####x######'"
            :rules="[rules.phoneLength]"
            prepend-icon="mdi-phone"
            v-model="_user.phone"
          />
          <v-text-field
            label="Alt. Phone"
            v-mask="'(###)###-####'"
            prepend-icon="mdi-phone"
            v-model="_user.alt_phone"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          color="secondary"
          :disabled="!valid"
          :loading="_loading"
          @click.native="update"
        >
          Edit User
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        User Edited Successfully!
      </v-card-title>
      <div>
        <v-card-text>
          You have successfully edited user {{ _user.user_name }}.
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn rounded color="secondary" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
export default {
  directives: {
    mask
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      showSuccessDialog: false,
      hasChanges: false,
      rules: {
        phoneLength: v =>
          !v || (v && v.length >= 13) || 'Invalid phone number.',
        email: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.'
      },
      valid: false
    };
  },
  computed: {
    ...mapGetters({
      _user: 'user/getUser',
      _companyId: 'company/getCompanyId',
      _loading: 'user/loading',
      _company: 'company/getCompany',
      _edited: 'user/getEditedUser',
      _loggedInUser: 'user/getLoggedInUser'
    })
  },
  watch: {
    _user: {
      handler(newVal) {
        const changed = this.isUserModified(this._loggedInUser, newVal);
        const valid = this.$refs.userForm?.validate();
        const hasChanges = valid && changed;
        this.emitChanges(hasChanges);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      _editUser: 'user/updateUser',
      _sendEmailAccountingWhenBillingUpdated:
        'jobs/sendEmailAccountingWhenBillingUpdated'
    }),
    ...mapMutations({
      _setUser: 'user/setUser',
      _setLoggedInUser: 'user/setLoggedInUser'
    }),
    isUserModified(v1, v2) {
      const keys = ['first_name', 'last_name', 'phone', 'alt_phone', 'email'];
      const o1 = keys.reduce((acc, k) => ({ ...acc, [k]: v1[k] }), {});
      const o2 = keys.reduce((acc, k) => ({ ...acc, [k]: v2[k] }), {});
      return !_.isEqual(o1, o2);
    },
    discard() {
      this._setUser(_.cloneDeep(this._loggedInUser));
    },
    emitChanges(val) {
      this.hasChanges = val;
      this.$emit('change', this.hasChanges);
    },
    update(showSuccessDialog) {
      this._editUser(this._user).then(res => {
        const emailConflictedErr = res.response?.status === 409;
        if (emailConflictedErr) {
          this.$emit('emailConflicted');
          return;
        }

        this.emitChanges(false);
        this.showSuccessDialog = showSuccessDialog ?? true;
        this._setLoggedInUser(this._edited);
        this._setUser(this._edited);
        this.$emit('userUpdated');

        const user_id = res.data.id;
        if (user_id == this._company.billing_team_id) {
          this._sendEmailAccountingWhenBillingUpdated();
        }
      });
    },
    close() {
      this.showSuccessDialog = false;
      this.$emit('close');
    }
  }
};
</script>
