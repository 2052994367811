<template>
	<v-card flat class="infoBox">
		<v-list class="infoContainer">
			<v-list-item two-line>
				<v-list-item-content v-if="!edit">
					<v-list-item-subtitle>Company Name</v-list-item-subtitle>
					<v-list-item-title>{{ _company.name }}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-content v-else>
					<v-list-item-subtitle>
						<v-text-field
							height="29px"
							@keyup.enter="$emit('save')"
							v-model="_company.name"
							:autofocus="edit"
							min-width="200"
							hide-details
							label="Company Name"
						></v-text-field>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line>
				<v-list-item-content v-if="!edit">
					<v-list-item-subtitle>Address</v-list-item-subtitle>
					<v-list-item-title>{{ _company.street }}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-content v-else>
					<v-list-item-subtitle>
						<v-text-field
							height="29px"
							@keyup.enter="$emit('save')"
							v-model="_company.street"
							min-width="100%"
							hide-details
							label="Address"
						></v-text-field>
					</v-list-item-subtitle>
				</v-list-item-content>
				
			</v-list-item>
		</v-list>

		<v-list class="infoContainer">
			<v-list-item two-line>
				<v-list-item-content v-if="!edit">
					<v-list-item-subtitle>City</v-list-item-subtitle>
					<v-list-item-title>{{ _company.city }}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-content v-else>
					<v-list-item-subtitle>
						<v-text-field
							height="29px"
							@keyup.enter="$emit('save')"
							v-model="_company.city"
							min-width="100%"
							hide-details
							label="City"
						></v-text-field>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line>
				<v-list-item-content v-if="!edit">
					<v-list-item-subtitle>Suite</v-list-item-subtitle>
					<v-list-item-title>{{ _company.suite }}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-content v-else>
					<v-list-item-subtitle>
						<v-text-field
							height="29px"
							@keyup.enter="$emit('save')"
							v-model="_company.suite" maxlength="10"
							min-width="100%"
							hide-details
							label="Suite"
						></v-text-field>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-list class="infoContainer">
			<v-list-item two-line>
				<v-list-item-content v-if="!edit">
					<v-list-item-subtitle>State</v-list-item-subtitle>
					<v-list-item-title>{{ _company.state }}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-content v-else>
					<v-list-item-subtitle>
						<v-text-field
							height="29px"
							@keyup.enter="$emit('save')"
							v-model="_company.state"
							min-width="100%"
							hide-details
							label="State"
						></v-text-field>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line>
				<v-list-item-content v-if="false">
					<v-list-item-subtitle>Country</v-list-item-subtitle>
					<v-list-item-title>{{ _company.country }}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-content v-if="!edit">
					<v-list-item-subtitle>Zip</v-list-item-subtitle>
					<v-list-item-title>{{ _company.zip }}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-content v-else>
					<v-list-item-subtitle>
						<v-text-field
							height="29px"
							@keyup.enter="$emit('save')"
							v-model="_company.zip"
							min-width="100%"
							hide-details
							label="Zip"
						></v-text-field>
					</v-list-item-subtitle>
				</v-list-item-content>
				
				<!-- <v-list-item-content v-else>
					<v-list-item-subtitle>
						<v-text-field
							height="29px"
							@keyup.enter="$emit('save')"
							v-model="_company.country"
							min-width="100%"
							hide-details
							label="Country"
						></v-text-field>
					</v-list-item-subtitle>
				</v-list-item-content> -->
			</v-list-item>
		</v-list>
		
	</v-card> 
</template>
<script>
import { mapGetters } from "vuex";
export default {
	props: {
		edit: Boolean,
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			_company: "company/getCompany",
		}),
	},
};
</script>
<style scoped>
.infoBox {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* padding: 4px; */
}
.infoContainer {
	min-width: calc((100%-8px) / 3);
}

a {
	text-decoration: none;
}

@media only screen and (max-width: 960px) {
	.infoContainer {
		min-width: calc(100%-8px);
		padding: 0px;
	}
	.infoBox {
		flex-direction: column;
	}
}
</style>
