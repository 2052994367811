<template>
  <v-card no-gutters flat class="pl-2">
    <div class="expandable-section">
      <div
        class="expandable-header"
        @click="toggleExpandBillingLocationAndContact"
      >
        <span
          :class="{
            mdi: true,
            'mdi-chevron-up': true,
            'rotate-180': isExpandedBillingLocationAndContact
          }"
        ></span>
        <h3 class="pt-2">Billing Location and Contact</h3>
      </div>
      <div
        :class="{
          'expandable-content': true,
          show: isExpandedBillingLocationAndContact
        }"
      >
        <div style="padding-right:20px">
          <v-row align="center">
            <v-col lg="4" xl="4" sm="12" md="12" class="pl-12">
              <v-autocomplete
                class="small-radio"
                :items="_sitesForDropdown"
                item-text="name"
                :error-messages="siteErrorMessages"
                label="Billing Address"
                return-object
                :readonly="locationExpand"
                v-model="selectedSite"
                :filter="customFilter"
              >
                <template v-slot:item="{ item }">
                  <div>
                    <div style="font-size: 16px;">
                      {{ `${item.name}, ID ${item.site_id}` }}
                    </div>
                    <div style="font-size: 0.8em;  color: grey;">
                      {{
                        `${item.street}, ${item.city}, ${item.state} ${item.zip}`
                      }}
                    </div>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              lg="6"
              xl="6"
              sm="12"
              md="12"
              class="d-flex justify-start align-center"
            >
              <v-btn
                rounded
                style="width:140px"
                small
                color="secondary"
                :disabled="locationExpand"
                @click="locationExpand = true"
              >
                <v-icon>mdi-plus</v-icon>
                Add Location
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6" xl="6" sm="12" md="12" class="pl-12">
              <v-expand-transition>
                <div v-show="locationExpand" class="mx-0 pa-2">
                  <v-form v-model="locationValid" ref="locationForm">
                    <v-text-field
                      label="Site Name*"
                      :rules="[rules.required]"
                      v-model="site.name"
                    ></v-text-field>
                    <VueGooglePlaces
                      :api-key="googleApi"
                      types="geocode"
                      style="width: 100%"
                      version="3.41"
                      country="us"
                      @placechanged="onPlaceChanged"
                    >
                      <v-text-field
                        v-model="address"
                        prepend-icon="mdi-map-search"
                        label="Address Finder..."
                        :placeholder="finderPlaceholder"
                      />
                    </VueGooglePlaces>
                    <v-card class="pl-8" flat>
                      <v-row no-gutters style="margin-top: 18px;">
                        <v-col cols="6" sm="6">
                          <v-text-field
                            label="Address *"
                            :rules="[addressRules.required]"
                            dense
                            v-model="site.street"
                            class="pr-2"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            :rules="[cityRules.required]"
                            dense
                            label="City *"
                            v-model="site.city"
                          />
                        </v-col>

                        <v-col cols="6" sm="6">
                          <v-autocomplete
                            v-model="site.state"
                            :items="usStates"
                            item-text="text"
                            item-value="value"
                            label="State *"
                            :rules="[stateRules.required]"
                            class="pr-2"
                          />
                        </v-col>

                        <v-col cols="6" sm="3">
                          <v-text-field
                            dense
                            label="Zip Code *"
                            v-mask="'#####'"
                            :rules="[
                              zipCodeRules.required,
                              zipCodeRules.isCorrectFormat
                            ]"
                            class="pr-2"
                            v-model="site.zip"
                            style="margin-top: 22px"
                          />
                        </v-col>
                        <v-col sm="3">
                          <v-text-field
                            dense
                            label="Suite"
                            v-model="site.suite"
                            style="margin-top: 22px"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                  <div class="justify-end">
                    <v-btn
                      rounded
                      color="error"
                      elevation="0"
                      height="35"
                      class="white--text mb-2 mr-2"
                      @click="cancelSiteCreation"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="secondary"
                      elevation="0"
                      :loading="_siteLoading"
                      height="35"
                      :disabled="!locationValid"
                      class="white--text mb-2"
                      @click="createSite"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
              </v-expand-transition>
            </v-col>
            <v-col cols="6"></v-col>
          </v-row>
        </div>
        <div style="padding-right:20px">
          <v-row align="center">
            <v-col lg="4" xl="4" sm="12" md="12" class="pl-12">
              <v-autocomplete
                class="small-radio"
                label="Billing Contact"
                :items="_usersForDropdown"
                :error-messages="userErrorMessages"
                item-text="user_name"
                return-object
                v-model="selectedUser"
                :disabled="POCExpand"
              ></v-autocomplete>
            </v-col>
            <v-col
              lg="6"
              xl="6"
              sm="12"
              md="12"
              class="d-flex justify-start align-center"
            >
              <v-btn
                style="width:140px;float:right"
                rounded
                small
                color="secondary"
                :disabled="POCExpand"
                @click="POCExpand = !POCExpand"
              >
                <v-icon>mdi-plus</v-icon>
                Add Contact
              </v-btn>
              <!-- <v-btn v-if="!POCExpand" rounded style="float:right;margin-right:5px" small color="error" @click="changeContact = false">
                  Cancel
                </v-btn> -->
            </v-col>
          </v-row>
          <v-row>
            <v-expand-transition>
              <v-row>
                <v-col cols="12" sm="6" class="pl-12">
                  <div v-show="POCExpand" class="mx-0 pa-2">
                    <v-form ref="userForm" v-model="userValid">
                      <v-text-field
                        v-show="false"
                        label="User Name"
                        v-model="POC.user_name"
                      ></v-text-field>
                      <div class="flex-container">
                        <v-text-field
                          label="First Name *"
                          :rules="[rules.required]"
                          v-model="POC.first_name"
                        ></v-text-field>
                        <v-spacer style="padding:5px" />
                        <v-text-field
                          label="Last Name"
                          v-model="POC.last_name"
                        ></v-text-field>
                      </div>
                      <v-text-field
                        label="Email *"
                        :rules="[rules.required, rules.emailMatch]"
                        v-model="POC.email"
                      ></v-text-field>
                      <div class="flex-container">
                        <v-text-field
                          label="Phone *"
                          v-mask="'(###)###-####x######'"
                          :rules="[rules.required, rules.phoneLength]"
                          v-model="POC.phone"
                        ></v-text-field>
                      </div>
                      <v-text-field
                        label="Alt. Phone"
                        v-mask="'(###)###-####'"
                        v-model="POC.alt_phone"
                      ></v-text-field>
                    </v-form>

                    <div class="justify-end"></div>
                    <div class="justify-end">
                      <v-btn
                        rounded
                        color="error"
                        elevation="0"
                        height="35"
                        class="white--text mb-2 mr-2"
                        @click="POCExpand = !POCExpand"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="secondary"
                        :disabled="!userValid"
                        elevation="0"
                        height="35"
                        class="white--text mb-2"
                        @click="createUser"
                        :loading="_POCLoading"
                      >
                        Save
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-expand-transition>
          </v-row>
        </div>
      </div>
    </div>

    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandAddTeamMembers">
        <span
          :class="{
            mdi: true,
            'mdi-chevron-up': true,
            'rotate-180': isExpandedAddTeamMembers
          }"
        ></span>
        <h3 class="pt-2">Add Team Members</h3>
      </div>
      <div
        :class="{ 'expandable-content': true, show: isExpandedAddTeamMembers }"
      >
        <div class="pb-4">
          <v-row>
            <v-col lg="6" xl="6" sm="12" md="12">
              <p class="subtitle">Build your team of users</p>
            </v-col>
            <v-col lg="2" xl="6" sm="12" md="12">
              <v-btn
                style="width:180px;"
                rounded
                small
                color="secondary"
                @click="addUser()"
              >
                <v-icon>mdi-plus</v-icon>
                Add Team Member
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div style="overflow-x: auto;">
          <table style="border-collapse: collapse;">
            <thead>
              <tr>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ACCOUNT TYPE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in _usersForDropdown" :key="user.email">
                <td>{{ user.first_name }} {{ user.last_name }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <div class="d-flex align-center">
                    <div
                      class="icon-container"
                      style="min-width: 24px; display: flex; justify-content: center;"
                    >
                      <v-icon
                        v-if="
                          user.user_type === config.USER_TYPE.admin ||
                            user.user_type === config.USER_TYPE.standard
                        "
                        color="green"
                        small
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>
                    <div>
                      <span v-if="user.user_type === config.USER_TYPE.admin"
                        >Admin</span
                      >
                      <span
                        v-else-if="user.user_type === config.USER_TYPE.standard"
                        >Standard</span
                      >
                      <span
                        v-else-if="user.user_type === config.USER_TYPE.inactive"
                        >Inactive</span
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="expandable-section">
      <div class="expandable-header" @click="toggleExpandAddSites">
        <span
          :class="{
            mdi: true,
            'mdi-chevron-up': true,
            'rotate-180': isExpandedAddSites
          }"
        ></span>
        <h3 class="pt-2">Add Sites</h3>
      </div>
      <div :class="{ 'expandable-content': true, show: isExpandedAddSites }">
        <div class="pb-4">
          <v-row>
            <v-col lg="6" xl="6" sm="12" md="12">
              <p class="subtitle">Add all of your facility locations</p>
            </v-col>
            <v-col lg="6" xl="6" sm="12" md="12">
              <v-btn
                style="width:180px;"
                rounded
                small
                color="secondary"
                @click="addSite"
              >
                <v-icon>mdi-plus</v-icon>
                Add New Site
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- <div style="overflow-x: auto;">
          <table>
            <thead>
              <tr>
                <th>Site Name</th>
                <th>Site Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="site in _sitesForDropdown" :key="site.site_id">
                <td>{{ site.name }}</td>
                <td>
                  <span>{{ site.street }}</span>
                  <br />
                  <span>{{ `${site.city}, ${site.state} ${site.zip}` }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <div style="overflow-x: auto; width: 100%;">
          <table class="table">
            <thead>
              <tr>
                <th style="padding: 8px; text-align: left;">Site Name</th>
                <th style="padding: 8px; text-align: left;">Site Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="site in _sitesForDropdown" :key="site.site_id">
                <td style="padding: 8px;">{{ site.name }}</td>
                <td style="padding: 8px;">
                  <span>{{ site.street }}</span>
                  <br />
                  <span>{{ `${site.city}, ${site.state} ${site.zip}` }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <create-site :edit="true" v-if="isShowCreateSite" />
    <create-user
      v-if="isShowCreateUser"
      :create="createUser"
      :add="addNewUser"
      :editMe="editUser"
      :editUser="editableUser"
      @close="closeCreateUser()"
    />
  </v-card>
</template>
<script>
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
//import ToolTip from "@/components/app/ToolTip.vue";
import CreateSite from '@/components/site/Create.vue';
import CreateUser from '@/components/team/CreateUser.vue';
var UsaStates = require('usa-states').UsaStates;
import config from '../../config';
export default {
  directives: {
    mask
  },
  components: {
    CreateUser,
    CreateSite
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany',
      _sitesForDropdown: 'sites/getSitesForDropdown',
      _usersForDropdown: 'user/getUsersForDropdown',
      _newTeamUser: 'user/getNewTeamUser',
      _newSite: 'sites/createdSite',
      _compId: 'company/getCompanyId',
      _siteLoading: 'sites/loading',
      _POCLoading: 'user/loading',
      _editedSite: 'sites/editedSite',
      _editedUser: 'user/getEditedUser',
      _deletedSite: 'sites/deletedSite',
      _deletedUser: 'user/deletedUser',
      _user: 'user/getUser',
      _users: 'user/getUsers'
    })
  },
  data() {
    return {
      config: config,
      selectedSite: null,
      selectedUser: null,
      locationExpand: false,
      POCExpand: false,
      siteErrorMessages: [],
      changeLocation: false,
      edit: false,
      locationValid: false,
      userValid: false,
      editSite: false,
      addNewSite: false,
      addNewUser: false,
      editUser: false,
      editableUser: undefined,
      defaultPOC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: undefined,
        company_id: this._compId
      },
      POC: {
        phone: '',
        alt_phone: '',
        email: '',
        first_name: '',
        last_name: '',
        user_name: '',
        password: '',
        passwordConf: '',
        icon_url: '',
        type_id: undefined,
        company_id: this._compId
      },
      defaultSite: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined
      },
      site: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined
      },
      siteOptions: {
        // itemsPerPage: 15,
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['name'],
        sortDesc: [false]
      },
      userOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['user_name'],
        sortDesc: [false]
      },
      radioRules: {
        required: v => v !== null || 'This is a required field.'
      },
      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v =>
          (!new RegExp(/undefined/).test(this.site.street) &&
            this.site.street !== undefined) ||
          'Full address is required'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v =>
          new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) ||
          'Please enter a valid zip code'
      },
      rules: {
        payVal: v => Number(v) <= 10.0 || 'Can not exceed $10.00/lb',
        paidVal: v =>
          String(v).replace(/,/g, '') <= 1000000000000.0 ||
          'Can not exceed $1000000000000.00',
        negativeBid: v => !new RegExp(/-/g).test(v) || 'Invalid bid',
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        required: v => !!v || 'This is a required field.',
        confirmMatch: v =>
          this.newUser.password === this.newUser.confirm_pass ||
          'The passwords you entered don’t match',
        length: v =>
          (v && v.length >= 8) ||
          'Passwords must be at least 8 characters in length',
        phoneLength: v => (v && v.length >= 13) || 'Invalid phone number.',
        afterBidStart: v =>
          moment(this.newJob.start_date).isBefore(
            moment(this.newJob.end_date)
          ) || 'Equipment due date must be later than bid due date',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(
            v
          ) || 'Passwords require at least one special character',
        afterToday: v =>
          moment(v) > moment() || "Must be later than today's date"
      },
      usStates: new UsaStates({ includeTerritories: true }).states.map(
        state => {
          return {
            text: state.name + ' (' + state.abbreviation + ')',
            value: state.abbreviation
          };
        }
      ),
      googleApi: 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE',
      address: '',
      userErrorMessages: null,
      finderPlaceholder: null,
      isExpandedBillingLocationAndContact: true,
      isExpandedAddTeamMembers: true,
      isExpandedAddSites: true,
      isShowCreateSite: true,
      isShowCreateUser: true
      // isDisableAllForm: true
    };
  },
  async mounted() {
    await this._auToSetUserAndCompanyInfo();
    await this._getSitesForDropdown(this.siteOptions);
    await this._getUsersForDropdown(this.userOptions);

    let currentSite = this._company.billing_team_id
      ? this._sitesForDropdown.filter(
          site => site.site_id == this._company.billing_site_id
        )[0]
      : null;
    this.selectedSite = currentSite;

    let currentUser = this._company.billing_team_id
      ? this._usersForDropdown.filter(
          user => user.user_id == this._company.billing_team_id
        )[0]
      : null;
    this.selectedUser = currentUser;
    await this._getUsers(this.userOptions);

    // this.isDisableAllForm = this._user.type_id != this.config.USER_TYPE.admin;
  },
  methods: {
    ...mapActions({
      _toggleDialog: 'sites/toggleDialog',
      _toggleEdit: 'sites/toggleEdit',
      _getSitesForDropdown: 'sites/getAllSitesForDropdown',
      _getCompany: 'company/getCurrent',
      _getUsersForDropdown: 'user/getAllUserForDropdown',
      _createSite: 'sites/createSite',
      _createUser: 'user/createUser',
      _toggleEditing: 'user/toggleEditing',
      _auToSetUserAndCompanyInfo: 'authenticate/auToSetUserAndCompanyInfo',
      _getUsers: 'user/getAll',
      _getSites: 'sites/getAll',
      _updateCompany: 'company/updateCompany',
      _toggleCreateSite: 'sites/toggleCreate'
    }),
    ...mapMutations({
      _toggleCreateUser: 'user/toggleCreate',
      _setUsers: 'user/setUsers',
      //: 'company/setOriginalCompany',
      _setCompany: 'company/setCompany'
    }),
    onPlaceChanged(e) {
      this.site.street = e.street_number + ' ' + e.route;
      this.site.city = e.locality;
      this.site.state = e.administrative_area_level_1;
      this.site.zip = e.postal_code;
      this.address = '';
      if (e.street_number !== undefined) {
        this.address = this.address.concat(e.street_number);
      }
      if (e.route !== undefined) {
        this.address = this.address.concat(' ' + e.route);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.locality !== undefined) {
        this.address = this.address.concat(e.locality);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.administrative_area_level_1 !== undefined) {
        this.address = this.address.concat(e.administrative_area_level_1);
      }
      if (e.postal_code !== undefined) {
        this.address = this.address.concat(' ' + e.postal_code);
      }
      this.address = this.address.concat(', USA');
    },
    cancelSiteCreation() {
      this.$refs.locationForm.reset();
      this.locationExpand = !this.locationExpand;
    },
    createSite() {
      this.isShowCreateSite = false;
      this._createSite(this.site).then(async () => {
        this.locationExpand = false;
        this.site = this.defaultSite;
        this.$refs.locationForm.reset();

        this._getSites(this.siteOptions);
        await this._getSitesForDropdown(this.siteOptions);
        //  this.selectedSite=this._newTeamUser.newUser;
        console.log('_newSite', this._newSite);
        this.selectedSite = this._newSite;
        this.selectedSite.site_id = this._newSite.id;
        this._company.billing_site_id = this.selectedSite.site_id;

        this.isShowCreateSite = true;
      });
    },
    cancelUserCreation() {
      this.$refs.userForm.reset();
      this.POCExpand = !this.POCExpand;
    },
    createUser() {
      if (this.POC.email !== undefined) {
        this.POC.type_id = 4;
        this.POC.password = 'N/A';
        this.POC.createdEmail = this._user.email;
        this.POC.createdUser =
          this._user.first_name +
          ' ' +
          (this._user.last_name ? this._user.last_name : '');
        // this.user.companyName=this._companyName;
        this.POC.user_name = this.POC.email;
        //  this.POC.send_email_set_password=true;//==> remove send the email activate_account to new user
        this.isShowCreateUser = false;
        this._createUser(this.POC).then(({ success }) => {
          this.isShowCreateUser = true;
          if (!success) {
            return;
          }
          this.POC = this.defaultPOC;
          this.POCExpand = !this.POCExpand;
          this.$refs.userForm.reset();

          this._getUsersForDropdown(this.userOptions);
          console.log('_newTeamUser', this._newTeamUser);
          this.selectedUser = this._newTeamUser.newUser;
          this.selectedUser.user_id = this._newTeamUser.newUser.id;
          this._company.billing_team_id = this.selectedUser.user_id;
        });
      }
    },
    closeCreateUser() {
      // this._getUsers();
      this.editUser = false;
    },
    addUser() {
      this._toggleCreateUser(true);
      //this.addNewSite = true;
    },
    addSite() {
      //alert(123123);

      this._toggleCreateSite({ toggle: true });
      //this._toggleEditSite({ toggle: true });
      //this.editSite = true;
      //this._toggleEdit({ id: value.site_id, toggle: true });
    },
    customFilter(item, queryText, itemText) {
      itemText = `${item.name}, ID ${item.site_id} ${item.street}, ${item.city}, ${item.state} ${item.zip}`;
      return itemText.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    async updateCompanyInfo() {
      let payload = this._company;
      payload.billing_site_id = this.selectedSite?.site_id || null;
      payload.billing_team_id = this.selectedUser?.user_id || null;
      let resUpdate = await this._updateCompany(payload);
      //this._setCompany(resUpdate.data);
      // this._setOriginalCompany(resUpdate.data);
    },
    toggleExpandBillingLocationAndContact() {
      this.isExpandedBillingLocationAndContact = !this
        .isExpandedBillingLocationAndContact;
    },
    toggleExpandAddTeamMembers() {
      this.isExpandedAddTeamMembers = !this.isExpandedAddTeamMembers;
    },
    toggleExpandAddSites() {
      this.isExpandedAddSites = !this.isExpandedAddSites;
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 600px) {
  table {
    width: 100% !important;
    border-collapse: collapse;
    border-radius: 10px;
  }
}

table {
  width: 60%;
  border-collapse: collapse;
  border-radius: 10px;
}
th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
th {
  background-color: white;
  height: 50px;
}
// tr:nth-child(even) {
//   background-color: #f9f9f9;
// }

tr:nth-child(odd) {
  background-color: #dee1e6;
}

tr {
  height: 45px !important;
}
tr:hover {
  background-color: #f1f1f1;
}

.expandable-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.expandable-header .mdi {
  font-size: 24px; /* Larger font size for the arrow */
  font-weight: bold; /* Bolder weight for the arrow */
  margin-right: 8px;
  transition: transform 0.3s;
}
.expandable-header .mdi.rotate-180 {
  transform: rotate(180deg);
}
.expandable-content {
  display: none;
  margin-left: 16px;
}
.expandable-content.show {
  display: block;
}
</style>
