<template>
	<div>
		<div v-if="_loading && !_sites">
			<v-skeleton-loader
				ref="skeleton"
				:boilerplate="false"
				type="table"
				class="mx-0"
			></v-skeleton-loader>
		</div>
		<div v-else>
			<div class="titleBar">
				<v-card-title
					v-html="tableTitle"
					style="font-size:26px;"
          class="save--text"
					v-if="$vuetify.breakpoint.mdAndUp"
				></v-card-title>

				<v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
				<v-text-field
					v-model="search"
					style="max-width: 300px; margin: 16px;"
					label="Search"
					:append-icon="search ? `mdi-close` : `mdi-magnify`"
					@keyup="throttledMethod()"
					@click:append="clearSearch()"
				/>
			</div>
			<v-slide-x-transition>
				<v-data-table
					v-if="_sites && _sites.length > 0"
					height="calc(100vh - 350px)"
					:options.sync="options"
					:server-items-length="total"
					no-data-text="No sites right now, create one!"
					:headers="headers"
					:items="_sites"
					:loading="_loading"
					@update:options="_getSites(options)"
					@click:row="handleClick"
					multi-sort
				>
					<template #[`item.actions`]="{ item }">
						<v-btn icon small @click.native="editSite(item)">
							<v-icon color="primary">mdi-pencil</v-icon>
						</v-btn>
						<v-btn small icon @click="deleteSiteTrigger(item)">
							<v-icon color="warning">mdi-delete</v-icon>
						</v-btn>
					</template>
				</v-data-table>

				<v-banner v-else single-line transition="slide-y-transition">
					Sorry No Sites Found
					<template v-slot:actions="{}">
						<v-btn text color="primary" :loading="_loading" @click="getSites()"
							>Retry</v-btn
						>
					</template>
				</v-banner>
			</v-slide-x-transition>
			<v-dialog v-model="deleteSite" max-width="300">
				<v-card>
					<v-card-title>Are You Sure?</v-card-title>
					<v-card-text
						>This action will permanently delete "{{
							siteDeleting.name
						}}".</v-card-text
					>
					<v-card-actions>
						<v-btn color="primary" rounded>keep site</v-btn>
						<v-spacer />
						<v-btn color="error" rounded>delete</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Options from "@/mixins/Options";
export default {
	mixins: [Options],
	props: {
		tableTitle: String,
	},
	data() {
		return {
			search: undefined,
			siteDeleting: { name: "" },
			deleteSite: false,
			page: 1,
			limit: 15,
			OpenSites: "Open Sites",
			filterStart: null,
			filterEnd: null,
			weightRange: [null, null],
			palletRange: [null, null],
			saved: "All",
			specSearch: null,
			locationSearch: null,
			mileRange: [null, null],
		};
	},
	computed: {
		...mapGetters({
			_sites: "sites/getSites",
			_loading: "sites/loading",
			_companyId: "company/getCompanyId",
			_currentSite: "sites/currentSite",
			_siteDialog: "sites/siteDialog",
		}),
		total() {
			return this._sites[0] ? parseInt(this._sites[0].total) : 0;
		},
		headers() {
			if (this._sites) {
				const keys = Object.keys(this._sites[0]);
				var headers = [];
				keys.forEach((i) => {
					if (i !== "created_at" && i !== "updated_at" && i !== "total") {
						const obj = {
							text: i.replace(/_/g, " ").toUpperCase(),
							value: i,
							sortable: true,
							filterable: true,
							divider: false,
							align: "start",
							width: i === "id" ? "75" : "200",
						};
						headers.push(obj);
					}
				});
				return headers;
			} else {
				return undefined;
			}
		},
		pagination() {
			return {
				page: this.options.page || 1,
				limit: this.options.itemsPerPage || 10,
			};
		},
		compId() {
			return this._companyId || 0;
		},
	},
	watch: {
		_siteDialog() {
			console.log(" vo watch _siteDialog",this._siteDialog);
			if (!this._siteDialog) {
				this._getSites(this.options);
			}
		},
		compId() {
			this._getSites(this.options);
		},
		tableTitle() {
			this._getSites(this.options);
		},
	},
	mounted() {
		this._getSites(this.options);
		this.throttledMethod = _.debounce(() => {
			this.getSites();
		}, 1000);
	},
	methods: {
		...mapActions({
			_getSites: "sites/getAll",
			_getSite: "sites/getCurrent",
			_toggleEdit: "sites/toggleEdit",
		}),
		...mapMutations({
			_setLoading: "sites/toggleLoading",
		}),
		editSite(item) {
			this._getSite(item.site_id);
			this.$emit("editSite", item);
		},
		deleteSiteTrigger(item) {
			this.deleteSite = true;
			this.siteDeleting = item;
			this.$emit("editSite", item);
		},
		clearSearch() {
			this.search = undefined;
			this.getSites();
		},
		handleClick(value) {
			 console.log("vo handleClick _toggleEdit",value);
			this._toggleEdit({ id: value.site_id, toggle: true });
		},
		getSites() {
			let options = this.options;
			options.search = this.search;
			this._getSites(options);
		},
	},
};
</script>

<style scoped>
.titleBar {
	width: 100%;
	display: flex;
	flex-direction: row;
}
.highlighted-button {
	width: 112px;
	height: 48px;
	border-radius: 24px;
	background-color: #4caf50 !important;
	padding: 0px 20px !important;
	margin: 5px;
}
.gray-button {
	color: #5d5d5d;
	background-color: transparent !important;
	height: 16px;
	font-size: 14px;
}
.mobile-button {
	width: 100%;
	height: 48px;
	border-radius: 24px;
	background-color: #4caf50 !important;
	padding: 0px 20px !important;
	margin: 5px;
	color: #ffffff !important;
}
</style>
