<template>
	<v-card
		:width="
			$vuetify.breakpoint.mdAndUp ? 'calc(50% - 16px)' : 'calc(100% - 16px)'
		"
		:height="$vuetify.breakpoint.mdAndUp ? '300px' : ''"
		v-if="total > 0"
		class="mx-2 my-2 pa-0"
		outlined
	>
		<v-row class="flex-wrap no-gutters">
			<v-col class="text-left" cols="12" sm="12" md="6">
				<v-card-subtitle>Recycled Pounds Per Month</v-card-subtitle>
			</v-col>
			<v-col
				:class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left'"
				cols="12"
				sm="12"
				md="6"
			>
				<v-card-subtitle>Total: {{ total }} lbs</v-card-subtitle>
			</v-col>
		</v-row>
		<v-spacer />
		<div style="height: 100%; margin: 8px;">
			<v-sparkline
				:value="values"
				:gradient="gradient"
				:smooth="radius || false"
				style="width: 100%; height: 80%; padding: 4px"
				:padding="padding"
				auto-line-width
				:labels="finalLables"
				:stroke-linecap="lineCap"
				:gradient-direction="gradientDirection"
				:fill="false"
				:type="type"
				auto-draw
				:show-labels="showLabels"
			>
				<template v-slot:label="item">
					{{ item.value + " " + values[item.index] + " lbs" }}
				</template>
			</v-sparkline>
		</div>
	</v-card>
	<!-- <apexchart
			width="100%"
			type="bar"
			:options="options"
			:series="series"
		></apexchart> -->
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
const gradients = [["#8cd65d", "rgba(140, 214, 93, 0.28)"]];
export default {
	data: () => ({
		showLabels: true,
		lineWidth: 20,
		labelSize: 7,
		radius: 0,
		padding: 8,
		lineCap: "round",
		gradient: gradients[0],
		labels: [
			"Jan",
			"Feb",
			"March",
			"April",
			"May",
			"June",
			"July",
			"Aug",
			"Sept",
			"Oct",
			"Nov",
			"Dec",
		],
		gradientDirection: "top",
		gradients,
		fill: false,
		type: "bar",
		autoLineWidth: false,
	}),
	computed: {
		...mapGetters({
			_poundsPerMonth: "dashboard/poundsPerMonth",
		}),
		values() {
			if (this._poundsPerMonth) {
				let values = this._poundsPerMonth
					.filter((o) => o.m && o.y)
					.map((o) => {
						return Number(o.recycled_pounds);
					});
				if (values.length < 2) {
					values.unshift(0);
				}
				return values;
			} else {
				return [];
			}
		},
		total() {
			let totalList = this._poundsPerMonth
				? this._poundsPerMonth
						.filter((o) => o.m && o.y)
						.map((o) => {
							return Number(o.recycled_pounds);
						})
				: [];
			let total = 0;
			totalList.forEach((i) => {
				total += i;
			});
			return total;
		},
		finalLables() {
			if (this._poundsPerMonth) {
				let labels = this._poundsPerMonth
					.filter((o) => o.m && o.y)
					.map((o) => {
						return this.labels[o.m - 1] + " : ";
					});
				if (labels.length < 2) {
					labels.unshift(
						this.labels[
							this._poundsPerMonth.filter((o) => o.m && o.y)[0].m - 2
						] + " : "
					);
				}
				return labels;
			} else {
				return [];
			}
		},
	},
	methods: {
		...mapActions({}),
	},
	mounted() {},
};
</script>
