<template>
  <v-col>
    <v-card 
      outlined 
      :color="config.color" 
      height="60" 
      @click="handleClick"
      :style="`border: 1px solid ${config.borderColor};`"
    >
      <div class="d-flex justify-space-between align-center">
        <div width="20">
          <v-card-text 
            :class="$vuetify.breakpoint.lgAndDown ? `
              pl-${config.padding[0] / 2} 
              pr-${config.padding[1] - 1} 
              py-0
              font-weight-medium
            ` : `
              pl-${config.padding[0]} 
              pr-${config.padding[1]} 
              font-weight-medium
            `" 
            :style="`color: ${config.labelColor}`"
          >
            {{ config.text }}
          </v-card-text>
        </div>
        <div class="justify-end" :class="config.unit ? 'text-truncate' : ''">
          <v-card-text 
            :class="$vuetify.breakpoint.lgAndDown ? `
              pr-${config.padding[0] / 2} 
              pl-${config.padding[1] - 1} 
              font-weight-bold
            ` : `
              pr-${config.padding[0]} 
              pl-${config.padding[1]} 
              font-weight-bold
            `" 
            :style="`font-size:22px; color: ${config.valueColor}`"
          >
            {{ config.value }}
            <span style="font-size: 14px;">
              {{ config.unit}}
            </span>
          </v-card-text>
        </div>
      </div>
    </v-card>
  </v-col>
</template>
<script>
export default {
  props: [ "config" ],
  methods: {
    handleClick() {
      if (this.config.onClick) {
        this.$router.push(`/${this.config.onClick}`)
      }
    }
  }
  
}
</script>