<template>
  <v-card flat>
    <div v-if="_company.type_id !== 2" class="pa-2">
      <v-row no-gutters>
        <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 12">
          <v-card-title class="primary--text mt-2"
            >Company Information
          </v-card-title>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <v-col
          :cols="$vuetify.breakpoint.smAndUp ? 6 : 12"
          class="d-flex flex-column justify-center"
        >
          <!-- :class="$vuetify.breakpoint.smAndUp ? 'float-right' : 'float-left'" -->
          <account-btns
            :txt="text"
            v-if="_user && _user.type_id === 1"
            :loading="_loading"
            :edit="edit"
            @edit="(edit = true), setCompanyDataBeforeEdit()"
            @save="updateCompany()"
            @cancel="cancelEdit()"
          />
        </v-col>
      </v-row>
      <v-card class="d-flex no-gutters flex-wrap justify-space-between" flat>
        <v-col class="fill-height align-center pa-2" md="6" sm="12">
          <v-card outlined>
            <v-card color="grey lighten-2" flat tile class="pa-2">
              <company-logo class="mt-4" :edit="edit" />
              <v-divider class="mt-3" v-if="$vuetify.breakpoint.smAndDown" />
            </v-card>
            <company-info class="mt-4" :edit="edit" @save="updateCompany()" />
            <company-billing :edit="edit" />
          </v-card>
          <payment-method class="mt-4" @save="updateCompany()" :edit="edit" />
        </v-col>
        <v-col sm="12" md="6" class="pa-2">
          <hour-card
            @set="_company.hours_of_op = $event"
            :days="_company.hours_of_op"
          />
        </v-col>
      </v-card>
    </div>

    <!-- VENDOR custom UI -->
    <div v-else>
      <v-row no-gutters>
        <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 12">
          <v-card-title class="pa-0 mt-2 section-title"
            >Company Information
          </v-card-title>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <v-col
          :cols="$vuetify.breakpoint.smAndUp ? 6 : 12"
          :class="$vuetify.breakpoint.smAndUp ? 'float-right' : 'float-left'"
        >
          <account-btns
            :txt="text"
            v-if="_user && _user.type_id === 1"
            :loading="_loading"
            :edit="edit"
            @edit="(edit = true), setCompanyDataBeforeEdit()"
            @save="updateCompany()"
            @cancel="cancelEdit()"
          />
        </v-col>
      </v-row>
      <v-card class="d-flex flex-column mb-4" flat>
        <vendor-company-info
          class="mt-4"
          :edit="edit"
          @save="updateCompany()"
        />
        <vendor-company-billing :edit="edit" />
      </v-card>
      <v-row class="d-flex flex-column" no-gutters>
        <certified
          ref="certified"
          class="mb-8"
          :edit="edit"
          :setCertHasError="setCertHasError"
          @r2CertError="onR2CertError"
        />
        <vendor-docs @remove="onAttachmentRemoved" :edit="edit" class="mb-8" />
        <vendor-eula
          class="my-4"
          :edit="edit"
          :acceptedEula="acceptedEula"
          @agree="onEulaAgreed"
        />
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import AccountBtns from './AccountBtns.vue';
import HourCard from '@/components/app/HourTable.vue';
import VendorEula from './VendorEula.vue';
import VendorDocs from './VendorDocs.vue';
import PaymentMethod from './PaymentMethod.vue';
import CompanyInfo from './CompanyInfo.vue';
import CompanyBilling from './CompanyBilling.vue';
import CompanyLogo from './CompanyLogo.vue';
import Certified from './Certified.vue';
import VendorCompanyInfo from './VendorCompanyInfo.vue';
import VendorCompanyBilling from './VendorCompanyBilling.vue';
import _ from 'lodash';
import axios from 'axios';

export default {
  components: {
    HourCard,
    PaymentMethod,
    CompanyInfo,
    CompanyBilling,
    CompanyLogo,
    AccountBtns,
    Certified,

    // VENDOR custom components
    VendorCompanyInfo,
    VendorCompanyBilling,
    VendorEula,
    VendorDocs
  },
  data() {
    return {
      text: 'Company',
      edit: false,
      logoImg: undefined,
      certHasError: false,
      r2CertError: false,
      companyDataFirstTime: {},
      hasChanges: false
    };
  },
  computed: {
    ...mapGetters({
      _user: 'user/getUser',
      _company: 'company/getCompany',
      _file: 'uploads/getFile',
      _loading: 'company/getLoading',
      _edited: 'company/getEdited',
      _loggedInCompany: 'company/getLoggedInCompany'
    }),
    company: {
      get() {
        return this._company;
      },
      set(newVal) {
        this._setCompany(newVal);
      }
    },
    acceptedEula() {
      if (!this._loggedInCompany?.id) {
        this._checkToken();
      }

      return this._loggedInCompany?.vendor_eula;
    }
  },
  methods: {
    ...mapActions({
      _updateCompany: 'company/updateCompany',
      _uploadLogo: 'company/uploadLogo',
      _checkToken: 'authenticate/checkToken',
      _sendEmailAccountingWhenBillingUpdated:
        'jobs/sendEmailAccountingWhenBillingUpdated',
      _getCurrent: 'company/getCurrent'
    }),
    async cancelEdit() {
      this.edit = false;
      this.certHasError = false;
      this.r2CertError = false;
      if (this._company.type_id === 2) {
        this.$refs.certified.resetR2State();
        this.$refs.certified.resetResaleState();
      }
      this._checkToken();
    },
    // toggleEdit() {
    //   this.edit = false;
    //   this.certHasError = false;
    //   this.r2CertError = false;
    // },
    ...mapMutations({
      _setCompany: 'company/setCompany',
      _setLoggedInCompany: 'company/setLoggedInCompany'
    }),
    async updateCompany() {
      if (this.certHasError || this.r2CertError) {
        return;
      }

      // this.emitChanges(false);
      // this.company.resale =
      //   this.company.resale || this.checkIfAttachmentUploaded("resale");

      if (this.company.vendor_eula) {
        // get ip
        const ipRes = await axios.get('https://api.ipify.org?format=json');
        this.company.accepted_ip = ipRes.data.ip;
        this.company.accepted_by = this._user.id;
        this.company.accepted_at = new Date();
      }

      this._updateCompany(this.company).then(() => {
        this.emitChanges(false);
        this.edit = false;
        this.certHasError = false;
        this.r2CertError = false;
        this._getCurrent(this.company.id).then(() => {
          this._setLoggedInCompany(this.company);
          this.emitChanges(false);
        });

        let needSendMail = this.needSendMailWhenBillingContactChange();
        if (needSendMail) {
          this._sendEmailAccountingWhenBillingUpdated();
        }
      });
    },
    setCertHasError(value) {
      this.certHasError = value;
    },
    onR2CertError(value) {
      this.r2CertError = value;
    },

    checkIfAttachmentUploaded(type) {
      if (
        !Array.isArray(this.company.attachments) ||
        this.company.attachments.length <= 0
      ) {
        return false;
      }

      let found = false;
      this.company.attachments.map(v => {
        if (v.catagory === type) {
          found = true;
        }
      });
      return found;
    },
    setCompanyDataBeforeEdit() {
      this.companyDataFirstTime = {
        billing_site_id: this._company.billing_site_id,
        billing_team_id: this._company.billing_team_id
      };
    },
    needSendMailWhenBillingContactChange() {
      const billingSiteId = this.company.billing_site_id || null;
      const billingTeamId = this.company.billing_team_id || null;
      const originalBillingSiteId =
        this.companyDataFirstTime.billing_site_id || null;
      const originalBillingTeamId =
        this.companyDataFirstTime.billing_team_id || null;
      if (
        billingSiteId != originalBillingSiteId ||
        billingTeamId != originalBillingTeamId
      ) {
        return true;
      } else {
        return false;
      }
    },
    isCompanyModified(v1, v2) {
      const keys = [
        'name',
        'city',
        'state',
        'street',
        'suite',
        'zip',
        'billing_site_id',
        'billing_team_id',
        'hours_of_op',
        'r2_cert',
        'resale',
        'vendor_eula',
        'attachments',
        'icon_url'
      ];
      const o1 = {
        ...keys.reduce((acc, k) => ({ ...acc, [k]: v1[k] }), {}),
        billing_site_id: v1.billing_site_id || null,
        billing_team_id: v1.billing_team_id || null,
        resale: v1.resale || false,
        r2_cert: v1.r2_cert || { cert: false }
      };
      const o2 = {
        ...keys.reduce((acc, k) => ({ ...acc, [k]: v2[k] }), {}),
        billing_site_id: v2.billing_site_id || null,
        billing_team_id: v2.billing_team_id || null,
        resale: v2.resale || false,
        r2_cert: v2.r2_cert || { cert: false }
      };
      return !_.isEqual(o1, o2);
    },
    emitChanges(val) {
      this.hasChanges = val;
      this.$emit('change', this.hasChanges);
    },
    onAttachmentRemoved() {
      this.$refs.certified.checkR2Validity();
      this.$refs.certified.checkValidity();
    },
    async onEulaAgreed() {
      const tempComp = _.cloneDeep(this._loggedInCompany);
      tempComp.vendor_eula = true;

      // get ip
      const ipRes = await axios.get('https://api.ipify.org?format=json');
      tempComp.accepted_ip = ipRes.data.ip;
      tempComp.accepted_by = this._user.id;
      tempComp.accepted_at = new Date();

      this._updateCompany(tempComp).then(() => {
        this._setLoggedInCompany(tempComp);
        this.company.vendor_eula = tempComp.vendor_eula;
        this.company.accepted_ip = tempComp.accepted_ip;
        this.company.accepted_by = tempComp.accepted_by;
        this.company.accepted_at = tempComp.accepted_at;
      });
    }
  },
  watch: {
    _file: {
      handler() {
        if (this._file.type === 'company_icon') {
          this.company.icon_url = this._file.file.Location;
        }
      },
      deep: true
    },
    company: {
      handler(newVal) {
        const newObj = JSON.parse(JSON.stringify(newVal));
        const hasChanges = this._loggedInCompany
          ? this.isCompanyModified(this._loggedInCompany, newObj)
          : this.hasChanges;
        this.emitChanges(hasChanges);
      },
      deep: true
      // immediate: true
    }
  }
};
</script>
<style scoped>
.infoBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 4px; */
}
.infoContainer {
  min-width: calc((100%-8px) / 3);
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 960px) {
  .infoContainer {
    min-width: calc(100%-8px);
    padding: 0px;
  }
  .infoBox {
    flex-direction: column;
  }
}
</style>
