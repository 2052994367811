<template>
  <div v-if="_company?.type_id">
    <!-- <v-row
      no-gutters
      flat
      style="background-color: #f5e9d0;"
      v-if="profileIsCompleted != config.companyProfileStatus.complete && _company.type_id == config.COMPANY_TYPE.VENDOR"
    >
      <v-banner class="warning-confirm-bid-banner d-flex align-center pt-2 pb-1" style="min-height: 60px;">
        <v-icon color="warning" class="mr-2 pb-2">mdi-alert-outline</v-icon>
        <span v-if="_user.type_id == config.USER_TYPE.admin" style="font-size: 1.1rem; line-height: 30px; ">
          Account Incomplete: Your company profile must be completed before bidding.
          <a href="./register/VendorOnboarding" style="text-decoration: underline; font-weight: bold;">
            Complete your profile.
          </a>
        </span>
        <span v-else-if="_user.type_id == config.USER_TYPE.standard" style="font-size: 1.1rem;line-height: 30px;">
          Account Incomplete: Your company profile must be completed before bidding. Contact your company admin to provide the required
          information.
        </span>
      </v-banner>
    </v-row> -->
    <v-card flat v-if="_company.type_id !== 2">
      <company-information
        ref="companyInfo"
        @change="onCompanyChange"
      ></company-information>
    </v-card>
    <warning-unsaved-change
      :isShow="isShowPopupUnsavedChanged"
      :discardChanged="discardChanged"
      :saveChanges="saveChanges"
      @onCloseDialog="onCloseDialog"
    />
    <v-card
      flat
      v-if="_company.type_id === 2"
      :class="$vuetify.breakpoint.smAndUp ? 'pa-8' : 'pa-2'"
    >
      <v-sheet max-width="75%" v-if="$vuetify.breakpoint.smAndUp">
        <v-slide-group v-model="tab" mandatory>
          <v-slide-item
            v-for="(t, _idx) in tabs"
            :key="t.key"
            :value="t.key"
            v-slot:default="{ active, toggle }"
            active-class="primary disable-btn"
          >
            <v-btn
              :rounded="active"
              :text="!active"
              height="50"
              class="elevation-0 my-1 mr-4"
              @click="toggle"
              >{{ t.title }}</v-btn
            >
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <v-select
        style="border-radius: 28px;"
        v-else
        filled
        flat
        class="elevation-0 primary ma-2 mt-5 mb-2"
        hide-details
        rounded
        dark
        v-model="tab"
        :items="tabs"
        item-text="title"
        item-value="key"
      />
      <company-information
        v-if="tab === 'overview'"
        ref="companyInfo"
        @change="onCompanyChange"
      />
      <vendor-onboarding-step2
        :isHideTitle="true"
        :isUpdateWhenChange="true"
        v-if="tab === 'services_and_prices'"
        ref="step2"
        @nextStep="() => {}"
      />
    </v-card>
  </div>
</template>
<script>
import CompanyInformation from '@/components/profile/CompanyInformation.vue';
import WarningUnsavedChange from '@/components/profile/WarningUnsavedChange.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VendorOnboardingStep2 from '@/components/registration/VendorOnboardingStep2.vue';
import config from '@/config';
export default {
  components: {
    CompanyInformation,
    WarningUnsavedChange,
    VendorOnboardingStep2
  },
  data() {
    const init = {
      isCompanyChanged: false,
      isShowPopupUnsavedChanged: false,
      navToPath: { path: '/profile' },
      tabs: [
        { title: 'Overview', key: 'overview' },
        { title: 'Services and Prices', key: 'services_and_prices' }
      ],
      profileIsCompleted: true,
      config: config,
      firstLoad: true
    };
    init['tab'] = init.tabs[0].key;
    return init;
  },
  async mounted() {
    //await this._auToSetUserAndCompanyInfo();
    // setTimeout(async () => {
    //   this.profileIsCompleted = await this._getCompanyProfileStatus();
    // }, 500);
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany',
      _preLogOut: 'authenticate/getPreLogOut',
      _loggedIn: 'authenticate/getState',
      _user: 'user/getUser',
      _companyId: 'company/getCompanyId'
    }),
    hasUnsavedChanges() {
      return this.isCompanyChanged;
    },
    isPreLogOut: {
      get() {
        return this._preLogOut;
      },
      set(val) {
        this._setPreLogOut(val);
      }
    }
  },
  beforeRouteLeave(to, _from, next) {
    if (!this._loggedIn) {
      next();
      return;
    }

    this.navToPath = to;
    if (this._company) {
      if (this._company.type_id === 3) {
        this.isShowPopupUnsavedChanged = false;
        this.isCompanyChanged = false;
        this.$refs.companyInfo.cancelEdit();
        next();
        return;
      }
    }
    if (this.hasUnsavedChanges) {
      this.isShowPopupUnsavedChanged = true;
      return;
    }
    next();
  },
  watch: {
    isPreLogOut(newVal) {
      if (this._company) {
        if (this._company.type_id === 3) {
          this._logOut();
        }
      }
      if (newVal) {
        if (this.hasUnsavedChanges) {
          this.isShowPopupUnsavedChanged = true;
          return;
        }
        this._logOut();
      }
    },
    _company: {
      handler: async function(newValue) {
        // if (this.firstLoad) {
        //   //await this._auToSetUserAndCompanyInfo();
        //   this.firstLoad = false;
        // }
        setTimeout(async () => {
          this.profileIsCompleted = await this._getCompanyProfileStatus();
        }, 2000);
        //this.profileIsCompleted = await this._getCompanyProfileStatus();
      },
      immediate: true // Optional: triggers the watcher immediately on component mount
    }
  },
  methods: {
    ...mapActions({
      _logOut: 'authenticate/logOut',
      _getCompanyProfileStatus: 'company/getCompanyProfileStatus',
      _auToSetUserAndCompanyInfo: 'authenticate/auToSetUserAndCompanyInfo'
    }),
    ...mapMutations({
      _setPreLogOut: 'authenticate/setPreLogOut'
    }),
    onCompanyChange(val) {
      this.isCompanyChanged = val;
    },
    discardChanged() {
      if (!this.hasUnsavedChanges) {
        return;
      }

      this.isShowPopupUnsavedChanged = false;
      this.isCompanyChanged = false;
      this.$refs.companyInfo.cancelEdit().then(() => {
        if (this.isPreLogOut) {
          this._logOut();
        } else if (this.navToPath.path !== '/profile') {
          this.$router.push(this.navToPath);
        }
      });
    },
    saveChanges() {
      if (!this.hasUnsavedChanges) {
        return;
      }

      // this.$refs.companyInfo.toggleEdit();
      if (this.isCompanyChanged) {
        this.$refs.companyInfo.updateCompany().then(() => {
          this.isShowPopupUnsavedChanged = false;
          // this.isCompanyChanged = false;
        });
      }
      this._setPreLogOut(false);
    },
    onCloseDialog(val) {
      this.isShowPopupUnsavedChanged = val;
      this._setPreLogOut(false);
    }
  }
};
</script>
<style>
.section-title {
  font-size: 1.1rem !important;
  font-weight: 500;
}
</style>
