<template>
  <v-app :style="cssProps">
    <navigation />
    <v-main
      class="mainBg"
      :style="
        mainPage
          ? `background:url(` + require('@/assets/Bitmap.jpg') + '); background-position: center; background-size: cover; height: 100vh;'
          : ''
      "
    >
      <v-card
        class="mx-2 my-8 pa-0"
        :flat="$route.name === 'Welcome' || $route.name === 'Forgot Password'"
        :color="$route.name !== 'Welcome' && $route.name !== 'Forgot Password' ? '' : 'transparent'"
        min-height="calc(100% - 64px)"
        width="calc(100% - 16px)"
      >
        <v-row no-gutters flat style="background-color: #f5e9d0;" v-if="isShowProfileIncomplete">
          <v-banner class="warning-confirm-bid-banner d-flex align-center pt-2 pb-1" style="min-height: 60px;">
            <v-icon color="warning" class="mr-2 pb-2">mdi-alert-outline</v-icon>
            <span v-if="_user.type_id == config.USER_TYPE.admin" style="font-size: 1.1rem; line-height: 30px; ">
              Account Incomplete: Your company profile must be completed before bidding.
              <a href="../register/VendorOnboarding" style="text-decoration: underline; font-weight: bold;">
                Complete your profile.
              </a>
            </span>
            <span v-else-if="_user.type_id == config.USER_TYPE.standard" style="font-size: 1.1rem;line-height: 30px;">
              Account Incomplete: Your company profile must be completed before bidding. Contact your company admin to provide the required
              information.
            </span>
          </v-banner>
        </v-row>
        <router-view transition="fade-transition" />
      </v-card>
    </v-main>
    <v-snackbar v-model="_notification" :color="_notificationType === 'error' ? 'error' : 'success'">
      <template>
        {{ _notificationMsg }}
      </template>
    </v-snackbar>
    <v-footer color="primary" app />
  </v-app>
</template>

<script>
import Navigation from '@/components/app/Navigation';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import config from '@/config';
export default {
  name: 'App',
  components: {
    Navigation
  },
  data: () => ({
    config: config,
    isShowProfileIncomplete: false
  }),
  created() {
    if (this.$route.name !== 'Forgot Password' && this.$route.name !== 'Registration' && this.$route.name !== 'Email Opt Out') {
      this._checkToken();
    }

    this.$router.afterEach((to, from) => {
      this.checkShowInCompletedProfile();
    });
  },
  methods: {
    ...mapActions({
      _checkToken: 'authenticate/checkToken',
      _getCompanyProfileStatus: 'company/getCompanyProfileStatus'
    }),
    ...mapMutations({
      _setNotification: 'notification/setNotification'
    }),
    checkShowInCompletedProfile() {
      try {
        console.log('11111', this._company, this._vendorCompanyProfileStatus);

        let isInRegisterRouter = this.$route.path.includes('/register');
        let is404Page = this.$route.path.includes('/404');
        let is401Page = this.$route.path.includes('/401');
        let isRootPage = this.$route.path === '/';

        let inIgnorePage = isInRegisterRouter || is404Page || is401Page || isRootPage;

        if (!!this._vendorCompanyProfileStatus && !!this._company && !inIgnorePage) {
          let isVendor = this._company.type_id === config.COMPANY_TYPE.VENDOR;
          let isInComplete = this._vendorCompanyProfileStatus == config.companyProfileStatus.incomplete;

          this.isShowProfileIncomplete = isVendor && isInComplete;
        } else {
          this.isShowProfileIncomplete = false;
        }
      } catch (e) {
        this.isShowProfileIncomplete = false;
      }
    }
  },
  async mounted() {
    if (this._vendorCompanyProfileStatus) {
      //alert('this._vendorCompanyProfileStatus 1');
      this.checkShowInCompletedProfile();
    }
  },
  computed: {
    ...mapGetters({
      _notification: 'notification/getNotification',
      _notificationMsg: 'notification/getNotificationMsg',
      _notificationType: 'notification/getNotificationType',
      _company: 'company/getCompany',
      _vendorCompanyProfileStatus: 'company/getVendorCompanyProfileStatus',
      _user: 'user/getUser'
    }),
    cssProps() {
      return {
        '--secondary-color': this.$vuetify.theme.secondary
      };
    },
    mainPage() {
      return this.$route.name === 'Welcome' || this.$route.name === 'Forgot Password';
    }
  },
  watch: {
    _notification() {
      if (this._notification === true) {
        setTimeout(() => {
          this._setNotification(false);
        }, 3000);
      }
    },
    _notificationType() {
      console.log('_notificationType change', this._notificationType);
    },
    _vendorCompanyProfileStatus() {
      if (this._vendorCompanyProfileStatus) {
        this.checkShowInCompletedProfile();
      } else {
        this.isShowProfileIncomplete = false;
      }
    }
  }
};
</script>
<style>
td {
  cursor: pointer;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 17px !important;
}
.theme--light.v-card .v-card__subtitle,
.theme--light.v-label,
.theme--light.v-data-table thead tr th,
.theme--light.v-subheader,
.theme--light.v-card > .v-card__text,
.theme--light.v-list-item .v-list .item__subtitle,
.theme--light.v-application .text--secondary,
.theme--light.v-messages,
.theme--light.v-counter {
  color: #5d5d5d !important;
}
</style>

<style scoped>
.v-banner >>> .v-banner__wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom: none !important;
}
</style>
