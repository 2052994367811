<template>
  <v-dialog v-model="_create" max-width="500" @click:outside="closeCreate">
    <v-card v-if="!copy && !deleteMe">
      <div class="btn-dismiss-dialog">
        <v-btn text @click="closeCreate()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        {{
          !_editing
            ? `Create New User`
            : _compType === 3
            ? `User Information`
            : `Edit User`
        }}
      </v-card-title>
      <v-card-text>
        <v-form ref="userForm" v-model="valid">
          <v-text-field
            label="User ID"
            v-show="false"
            prepend-icon="mdi-account-outline"
            v-model="user.id"
          />
          <v-text-field
            label="First Name"
            prepend-icon="mdi-account-circle"
            v-model="user.first_name"
          />
          <v-text-field
            label="Last Name"
            prepend-icon="mdi-account-circle-outline"
            v-model="user.last_name"
          />
          <v-text-field
            v-if="showpass"
            label="User Name"
            prepend-icon="mdi-account"
            v-model="user.user_name"
          />
          <v-text-field
            label="Email"
            required
            :rules="[rules.email]"
            prepend-icon="mdi-email"
            v-model="user.email"
          />
          <v-text-field
            label="Phone"
            v-mask="'(###)###-####x######'"
            :rules="[rules.phoneLength]"
            prepend-icon="mdi-phone"
            v-model="user.phone"
          />
          <v-text-field
            label="Alt. Phone"
            v-mask="'(###)###-####'"
            prepend-icon="mdi-phone"
            v-model="user.alt_phone"
          />
          <!--
          {{user.password}}:{{add}}
          <v-text-field
            v-if="add && !_editing"
            v-model="user.password"
            :rules="[rules.required, rules.length, rules.specialChar]"
            label="Temporary Password"
            prepend-icon="mdi-lock"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
          ></v-text-field>
          <v-text-field
            v-if="add && !_editing"
            v-model="user.passwordConf"
            :rules="[rules.required, rules.passMatch]"
            label="Temporary Password Confirmation"
            prepend-icon="mdi-lock"
            :append-icon="showPassConf ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassConf ? 'text' : 'password'"
            @click:append="showPassConf = !showPassConf"
          ></v-text-field>
          -->
          <!-- :error-messages="confPassword ? `Passwords do not match, please confirm` : undefined" -->
          <!-- :error="confPassword" -->
          <v-select
            v-model="user.type_id"
            v-if="_currUser && _currUser.user_type !== 2"
            label="User Type"
            :items="userRoles"
            :rules="[rules.required]"
            :disabled="_compType === 3"
            item-text="type"
            item-value="type_id"
            prepend-icon="mdi-badge-account"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="_compType !== 3">
        <v-btn
          v-if="_editing"
          rounded
          color="error"
          @click.native="deleteUser()"
        >
          Delete User
        </v-btn>
        <v-btn v-else rounded color="error" @click.native="closeCreate()">
          cancel
        </v-btn>
        <v-spacer />
        <v-btn
          rounded
          color="secondary"
          :disabled="!valid"
          :loading="_loading"
          @click.native="createUser()"
        >
          {{ !_editing ? `Create` : `Edit` }} Team Member
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="copy && !deleteMe">
      <v-card-title
        >User {{ !_editing ? `Created` : `Edited` }} Successfully!</v-card-title
      >
      <div v-if="!editSuccess">
        <v-card-subtitle
          v-if="userTypeJustCreated != config.USER_TYPE.inactive"
        >
          <!--Please check the email to set new password for this user.-->
          A link has been sent for your team member to create a password.
          <br />

          Please let them know to check their email. If they can’t find the
          email have them check their junk mail or use “forgot password" to
          resend a link.
        </v-card-subtitle>
        <v-card-text v-if="false">
          <span style="display:none"
            >User Name: {{ this._newCreatedUser.user_name }}</span
          >
          <br />
          Email: {{ this._newCreatedUser.email }}
          <br />
          <span style="display:none">Password: {{ this.savedPw }}</span>
        </v-card-text>
      </div>
      <div v-else>
        <v-card-text>
          You have successfully edited team member {{ _userToEdit.user_name }}.
          Be sure to have them review any changes.
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn rounded color="secondary" @click="closeCreate">Close</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="deleteMe">
      <v-card-title
        >Are you sure you want to delete team member
        {{ _userToEdit.user_name }}?</v-card-title
      >
      <v-card-text>
        This action will be permanent. The user will be deleted and will no
        longer have access to this account.
      </v-card-text>
      <v-card-actions>
        <v-btn rounded color="secondary" @click="closeCreate()">Cancel</v-btn>
        <v-spacer />
        <v-btn
          rounded
          color="error"
          :loading="_loading"
          @click="deleteConfirm()"
        >
          Delete User
        </v-btn>
      </v-card-actions>
    </v-card>
    <existed-email-warning-dialog
      :visible="showExistedEmailWarning"
      :create="isCreate"
      @close="
        showExistedEmailWarning = false;
        isCreate = false;
      "
    />
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import Options from '@/mixins/Options';
import config from '@/config';
import ExistedEmailWarningDialog from '@/components/app/ExistedEmailWarningDialog.vue';
export default {
  mixins: [Options],
  directives: {
    mask
  },
  props: {
    editing: Boolean,
    add: Boolean
  },
  components: {
    ExistedEmailWarningDialog
  },
  data() {
    return {
      config: config,
      showpass: false,
      deleteMe: false,
      editSuccess: false,
      copy: false,
      showPass: false,
      email: undefined,
      password: 'P@ssword911',
      user_name: undefined,
      showPassConf: false,
      passwordConf: 'P@ssword911',
      savedPw: undefined,
      userRoles: [
        { type: 'Admin', type_id: 1 },
        { type: 'Standard', type_id: 2 },
        { type: 'Inactive', type_id: 4 }
      ],
      rules: {
        required: v => !!v || 'Required.',
        passMatch: v =>
          this.user.password === this.user.passwordConf ||
          'The passwords you entered don’t match',
        length: v =>
          (v && v.length >= 8) ||
          'Passwords must be at least 8 characters in length',
        phoneLength: v =>
          !v || (v && v.length >= 13) || 'Invalid phone number.',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(
            v
          ) || 'Passwords require at least one special character',
        email: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.'
      },
      valid: false,
      userOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['user_name'],
        sortDesc: [false]
      },
      userTypeJustCreated: undefined,
      showExistedEmailWarning: false,
      isCreate: false
    };
  },
  computed: {
    ...mapGetters({
      // _getUser: "user/getUser",
      _userToEdit: 'user/getUserToEdit',
      _editing: 'user/editing',
      _create: 'user/create',
      _currUser: 'user/getUser',
      _companyId: 'company/getCompanyId',
      //_companyName: "company/getCompanyName",
      _newUser: 'user/getNewUser',
      _compType: 'company/getCompanyType',
      _loading: 'user/loading',
      _newCreatedUser: 'user/getNewTeamUser',
      _company: 'company/getCompany',
      _loggedInUser: 'user/getLoggedInUser',
      _user: 'user/getUser'
    }),
    user: {
      get() {
        return this._newUser;
      },
      set(newValue) {
        this._setNewUser(newValue);
      }
    },
    confPassword() {
      if (this.user.password !== this.user.passwordConf) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    _editing() {
      if (this._editing) {
        this.user = this._userToEdit;
      } else {
        this.user = {};
      }
    },
    _newCreatedUser() {
      this.savedPw = this.user.password;
      this.user = {};
      this.copyCreds();
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      _createUser: 'user/createUser',
      _editUser: 'user/updateUser',
      _deleteUser: 'user/deleteUser',
      _sendEmailAccountingWhenBillingUpdated:
        'jobs/sendEmailAccountingWhenBillingUpdated',
      _getUsers: 'user/getAll',
      _getUsersForDropdown: 'user/getAllUserForDropdown'
    }),
    ...mapMutations({
      _setNewUser: 'user/setNew',
      _toggleCreate: 'user/toggleCreate',
      _toggleEditing: 'user/toggleEditing',
      _setLoggedInUser: 'user/setLoggedInUser',
      _setUser: 'user/setUser'
    }),
    cancel() {
      this.$refs.form.reset();
    },
    deleteUser() {
      this.deleteMe = true;
    },
    copyCreds() {
      this.copy = true;
    },
    createUser() {
      if (!this._editing) {
        if (this.user.email !== undefined) {
          // if (this.user.password === this.user.passwordConf) {
          /*
[Company Name] → {{ company_name }}

[Full name of new team member verify] → {{ user_full_name }}

[Full name of user who added them to the team] → {{ user_full_name_added_member}}

[Set Your Password] -> {{ reset_password_link }}

[email of user who added them to the team] → {{ email_added_member}}
*/
          console.log('this._currUser', this._currUser);
          this.user.createdEmail = this._currUser.email;
          this.user.createdUser =
            this._currUser.first_name +
            ' ' +
            (this._currUser.last_name ? this._currUser.last_name : '');
          // this.user.companyName=this._companyName;
          this.user.password = 'P@ssword911';
          this.user.passwordConf = 'P@ssword911';
          this.user.user_name = this.user.email;
          this.user.send_email_set_password = true;
          this.userTypeJustCreated = this.user.type_id;
          this._createUser(this.user).then(async res => {
            const emailConflictedErr = res.response?.status === 409;
            if (emailConflictedErr) {
              this.closeCreate();
              this.isCreate = true;
              this.showExistedEmailWarning = true;
              return;
            }
            this.getUserForListTeam();

            await this._getUsersForDropdown(this.userOptions);
          });
          // } else {
          //   return;
          // }
        } else {
          return;
        }
      } else {
        this._editUser(this.user).then(async res => {
          const emailConflictedErr = res.response?.status === 409;
          if (emailConflictedErr) {
            this.closeCreate();
            this.isCreate = false;
            this.showExistedEmailWarning = true;
            return;
          }

          this.editSuccess = true;
          this.copyCreds();

          const user_id = res.data.id;
          if (user_id === this._loggedInUser.id) {
            this._setLoggedInUser(this.user);
          }
          if (user_id === this._user.id) {
            this._setUser(this.user);
          }
          if (user_id == this._company.billing_team_id) {
            this._sendEmailAccountingWhenBillingUpdated();
          }

          await this._getUsersForDropdown(this.userOptions);
        });
      }
    },
    deleteConfirm() {
      this._deleteUser(this._userToEdit.id).then(res => {
        this.closeCreate();
      });
    },
    closeCreate() {
      if (this.$refs.userForm) {
        this.$refs.userForm.reset();
      }
      this._toggleEditing({ toggle: false, user: {} });
      this._toggleCreate(false);
      setTimeout(() => {
        this.editSuccess = false;
        this.savedPw = undefined;
        this.copy = false;
        this.deleteMe = false;
      }, 1000);
    },
    getUserForListTeam() {
      let options = this.options;
      options.search = '';
      options.page = 1;
      options.itemsPerPage = 10;
      this._getUsers(options);
    }
  }
};
</script>
