<template>
  <v-dialog max-width="500" v-model="visible" @click:outside="close()">
    <v-card flat>
      <div class="btn-dismiss-dialog">
        <v-btn text @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        <span class="headline">
          Edit Address
        </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <VueGooglePlaces
            :api-key="googleKey"
            enableGeolocation
            version="3.41"
            style="width: 100%"
            country="us"
            types="address"
            @placechanged="onPlaceChanged"
          >
            <v-text-field
              prepend-icon="mdi-map-marker"
              :label="`Address Finder...`"
              :placeholder="finderPlaceholder"
              v-model="address"
            />
          </VueGooglePlaces>
          <v-card class="pl-8" flat>
            <v-row no-gutters>
              <v-col sm="9">
                <v-text-field
                  label="Address *"
                  :rules="[addressRules.required]"
                  dense
                  v-model="company.street"
                  class="pr-2"
                />
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :rules="[cityRules.required]"
                  dense
                  label="City *"
                  v-model="company.city"
                />
              </v-col>

              <v-col sm="6">
                <v-autocomplete
                  v-model="company.state"
                  :items="usStates"
                  item-text="text"
                  item-value="value"
                  label="State *"
                  :rules="[stateRules.required]"
                  class="pr-2"
                />
              </v-col>

              <v-col sm="3">
                <v-text-field
                  dense
                  label="Zip Code *"
                  v-mask="'#####'"
                  :rules="[zipCodeRules.required, zipCodeRules.isCorrectFormat]"
                  class="pr-2"
                  v-model="company.zip"
                  style="margin-top: 22px"
                />
              </v-col>
              <v-col sm="3">
                <v-text-field
                  dense
                  label="Suite"
                  v-model="company.suite"
                  style="margin-top: 22px"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-row></v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn rounded :disabled="!valid" color="secondary" @click="update()">
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
import _ from 'lodash';
var UsaStates = require('usa-states').UsaStates;
const COMMA = ',';
export default {
  directives: {
    mask
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      company: {},
      address: '',
      finderPlaceholder: null,
      usStates: new UsaStates({ includeTerritories: true }).states.map(
        state => ({
          text: state.name + ' (' + state.abbreviation + ')',
          value: state.abbreviation
        })
      ),
      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v => v !== undefined || 'Full address is required'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v =>
          new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) ||
          'Please enter a valid zip code'
      },
      valid: false
    };
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany'
    }),
    googleKey() {
      // TODO: use env
      return 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE'; // process.env.VUE_APP_GOOGLE_API_KEY;
    }
  },
  watch: {
    _company: {
      handler(val) {
        if (!val || !Object.keys(val).length) {
          return;
        }
        this.company = _.cloneDeep(val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onPlaceChanged(e) {
      const {
        street_number,
        route,
        locality,
        administrative_area_level_1,
        postal_code
      } = e;
      const street = [street_number, route].filter(Boolean).join(' ');
      const city = locality || '';
      const state = administrative_area_level_1 || '';
      const zip = postal_code || '';
      this.company = { ...this.company, street, city, state, zip };
      const country = 'USA';
      const address = [street, COMMA, city, COMMA, state, zip, COMMA, country]
        .filter(Boolean)
        .join(' ');
      this.address = this.standardizeAddress(address);
    },
    trimChar(str, char) {
      const regex = new RegExp(`^${char}+|${char}+$`, 'g');
      return str.replace(regex, '');
    },
    standardizeAddress(addr) {
      return this.trimChar(addr, COMMA)
        .trim()
        .replace(' ,', ',');
    },
    close() {
      this.reset();
      this.$emit('close');
    },
    reset() {
      // reset address finder
      this.address = '';
      // reset company metadata
      const keys = ['street', 'city', 'state', 'zip', 'suite'];
      keys.forEach(k => (this.company[k] = this._company[k]));
    },
    update() {
      const keys = ['street', 'city', 'state', 'zip', 'suite'];
      keys.forEach(k => (this._company[k] = this.company[k]));
      this.close();
    }
  }
};
</script>
