<template>
  <div>
    <v-dialog
      persistent
      v-model="isVisible"
      max-width="820"
      @click:outside="showConfirmUnSaveChangePopup"
    >
      <v-card
        flat
        :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 100px)' : 'auto'"
      >
        <v-toolbar flat>
          <v-card-title
            v-if="$vuetify.breakpoint.mdAndUp"
            @click="validateTest()"
          >
            Edit Job
          </v-card-title>
          <v-spacer />
          <v-btn class="close-btn" text @click="showConfirmUnSaveChangePopup">
            close
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-stepper
          v-model="step"
          class="elevation-0"
          :vertical="$vuetify.breakpoint.mdAndUp"
          tile
          flat
        >
          <v-stepper-header
            v-if="$vuetify.breakpoint.smAndDown"
            style="transition: 2s"
            elevation="0"
          >
            <v-stepper-step :complete="step > 1" step="1" @click="step = 1">
              Job Overview
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2" @click="step = 2">
              Equipment
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3" @click="step = 3">
              Location
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 4" step="4" @click="step = 4">
              Review
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-step
            v-if="$vuetify.breakpoint.mdAndUp"
            :complete="step > 1"
            step="1"
          >
            Job Details
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card flat class="pa-2">
              <v-card-title
                class="ma-0 pa-0"
                v-if="$vuetify.breakpoint.smAndDown"
              >
                Job Details
              </v-card-title>
              <v-form ref="detailsForm" v-model="detailsForm">
                <v-text-field
                  :maxlength="60"
                  class="mt-4"
                  :rules="[
                    rules.required,
                    value => value && value.length <= 60
                  ]"
                  v-model="_specsJob.name"
                  label="Job Name: Brief Description of Equipment*"
                  messages="Entice vendors with this short description of the equipment. Cannot include your company name. 60-character limit. Example: Routers, Switches, Cell Phones, Mixed IT Equipment"
                ></v-text-field>

                <div class="maxlength-box">
                  <label></label>
                  <label
                    :class="{
                      'red-text': _specsJob.name && _specsJob.name.length > 60
                    }"
                  >
                    {{ _specsJob.name ? 60 - _specsJob.name.length : 60 }}
                  </label>
                </div>
                <v-textarea
                  class="mt-4"
                  v-model="_specsJob.description"
                  label="Description"
                  filled
                  messages="Please include a brief description of the work to be performed and any specific requirements. Example: Please remove all identifying stickers/markers."
                ></v-textarea>

                <v-row no-gutters>
                  <v-col sm="12">
                    <v-radio-group
                      label="Logistics*"
                      required
                      v-model="_specsJob.logistics"
                      :rules="[v => !!v || '']"
                    >
                      <v-radio
                        class="small-radio"
                        :value="config.LOGISTICS_OPTIONS.VENDOR_PICKUP.value"
                        :label="config.LOGISTICS_OPTIONS.VENDOR_PICKUP.title"
                      />
                      <v-radio
                        class="small-radio"
                        :value="config.LOGISTICS_OPTIONS.CUSTOMER_DROPOFF.value"
                        :label="config.LOGISTICS_OPTIONS.CUSTOMER_DROPOFF.title"
                      />
                      <v-radio
                        class="small-radio"
                        :value="config.LOGISTICS_OPTIONS.EITHER.value"
                        :label="config.LOGISTICS_OPTIONS.EITHER.title"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="menu"
                      v-model="endMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="_specsJob.end_date"
                          label="Equipment Removal Date*"
                          prepend-icon="mdi-calendar-blank"
                          :rules="[rules.afterBidStart]"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="endDate"
                        v-model="_specsJob.end_date"
                        no-title
                        :min="_specsJob.bid_due_date"
                        @input="endMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="2"></v-col>
                  <v-col class="exact-removal-date" cols="12" sm="12" md="4">
                    <v-row no-gutters>
                      <v-radio-group
                        required
                        label="How exact is the removal date?*"
                        v-model="_specsJob.exact_removal_date"
                        :rules="[v => !!v || '']"
                      >
                        <v-radio
                          class="small-radio"
                          :value="
                            config.EXACT_REMOVE_DATE_OPTIONS.EXACT_DATE.value
                          "
                          :label="
                            config.EXACT_REMOVE_DATE_OPTIONS.EXACT_DATE.title
                          "
                        />
                        <v-radio
                          class="small-radio"
                          :value="
                            config.EXACT_REMOVE_DATE_OPTIONS.PLUS_OR_MINUS_DATE
                              .value
                          "
                          :label="
                            config.EXACT_REMOVE_DATE_OPTIONS.PLUS_OR_MINUS_DATE
                              .title
                          "
                        />
                        <v-radio
                          class="small-radio"
                          :value="
                            config.EXACT_REMOVE_DATE_OPTIONS.FLEXIBLE_DATE.value
                          "
                          :label="
                            config.EXACT_REMOVE_DATE_OPTIONS.FLEXIBLE_DATE.title
                          "
                        />
                      </v-radio-group>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
              <v-card flat>
                <v-card-actions class="pr-0">
                  <v-spacer />
                  <v-btn
                    text
                    :disabled="this._specsJob.status === 'open' && !detailsForm"
                    @click="step += 1"
                  >
                    Next
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-stepper-content>

          <v-stepper-step
            v-if="$vuetify.breakpoint.mdAndUp"
            :complete="step > 2"
            step="2"
          >
            Equipment
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card flat height="100%" class="mx-0 pa-2">
              <v-card-title
                class="ma-0 mb-3 pa-0"
                v-if="$vuetify.breakpoint.smAndDown"
              >
                Equipment
              </v-card-title>
              <v-card flat>
                <v-row
                  v-if="_specsJob.recycle_specs"
                  no-gutters
                  class="justify-space-between"
                >
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-row class="justify-space-between" no-gutters>
                      <v-col sm="8">{{ specs[0].statement }}</v-col>
                      <v-col sm="2">
                        <tool-tip
                          :text="
                            `If the Vendor (during sorting/processing of
                              this equipment for recycling) discovers whole
                              units or parts that have resale value....do
                              you allow them to test, reset, wipe, inventory
                              and offer your company an additional fair
                              market purchase offer for this equipment when
                              closing this job? If ”No” is selected, all
                              equipment will be recycled and
                              destroyed.`
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row class="d-flex flex-row-nowrap" no-gutters>
                      <v-radio-group
                        dense
                        v-model="_specsJob.recycle_specs.resale"
                        :mandatory="true"
                      >
                        <v-radio dense label="yes" :value="true" />
                        <v-radio dense label="no" :value="false" />
                      </v-radio-group>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-row class="justify-space-between" no-gutters>
                      <v-col sm="8">{{ specs[1].statement }}</v-col>
                      <v-col sm="2">
                        <tool-tip
                          :text="
                            `Do you require the job be performed by a vendor
                              that is certified under the R2 or e-Steward
                              standard? Only vendors that have proven their
                              certification and are in good standing with
                              their certifying body will be able to bid on
                              this job if “YES” is selected. If “NO” is
                              selected, all vendors (certified AND non-
                              certified) will have the ability to view and bid
                              on this job.`
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-radio-group
                      dense
                      v-model="_specsJob.recycle_specs.certified_recycle"
                      :mandatory="true"
                    >
                      <v-radio dense label="yes" :value="true" />
                      <v-radio dense label="no" :value="false" />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-text-field
                      v-model="_specsJob.number_of_pallets"
                      type="number"
                      label="Number Of Pallets"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pa-2">
                    <v-text-field
                      v-model="_specsJob.est_weight"
                      type="number"
                      label="Estimated Weight"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-card flat>
                    <v-row class="pb-5">
                      <v-card-subtitle>
                        <span :class="{ 'red-text': assetTypeIsError }">
                          Asset type (select all that apply)*
                        </span>
                      </v-card-subtitle>
                    </v-row>
                    <v-row no-gutters>
                      <v-item-group>
                        <v-checkbox
                          v-model="assetTypes.network"
                          :label="config.ASSET_TYPE_LABEL.network"
                          :class="['check-box']"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="assetTypes.raw"
                          :label="config.ASSET_TYPE_LABEL.raw"
                          :class="['check-box']"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="assetTypes.mobile"
                          :label="config.ASSET_TYPE_LABEL.mobile"
                          :class="['check-box']"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="assetTypes.infr"
                          :label="config.ASSET_TYPE_LABEL.infr"
                          :class="['check-box']"
                        ></v-checkbox>
                      </v-item-group>
                    </v-row>
                    <v-row no-gutters class="order-checkbox">
                      <v-col cols="12" sm="3" md="3">
                        <v-checkbox
                          @change="onOtherCheckBoxChanged()"
                          v-model="assetTypes.other_checked"
                          :label="config.ASSET_TYPE_LABEL.other"
                          :class="{
                            'error-checkbox':
                              assetTypeIsError && assetTypes.other_checked
                          }"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        v-show="assetTypes.other_checked"
                        cols="12"
                        sm="9"
                        md="9"
                      >
                        <v-form ref="otherCheckbox">
                          <v-text-field
                            :maxlength="30"
                            v-model="assetTypes.other"
                            label="Add other asset type (limit 30 characters)"
                            :rules="[
                              assetTypes.other_checked
                                ? value => value && value.length <= 30
                                : value => value.length <= 30
                            ]"
                          ></v-text-field>
                        </v-form>
                        <div class="maxlength-box">
                          <label></label>
                          <label
                            :class="{
                              'red-text': assetTypes.other.length > 30
                            }"
                          >
                            {{
                              assetTypes.other
                                ? 30 - assetTypes.other.length
                                : 30
                            }}
                          </label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>

                <v-card-subtitle>
                  You may upload multiple files per category in a zip file or
                  one at a time. Simply click the field to add another file.
                </v-card-subtitle>
                <v-form v-model="equipValid" ref="equipForm">
                  <v-file-input
                    :rules="jobAttachments.length > 0 ? [] : [rules.required]"
                    multiple
                    dense
                    ref="equipmentList"
                    @change="uploadJobFile($event, 'equipment_list')"
                    @focus="equipFocused = !equipFocused"
                    :clearable="!_uploading || !equipFocused"
                    @blur="equipFocused = !equipFocused"
                    :loading="equipFocused ? _uploading : false"
                    label="Upload Equipment List*"
                  ></v-file-input>
                  <v-file-input
                    multiple
                    @change="uploadJobFile($event, 'photos')"
                    @focus="invFocused = !invFocused"
                    @blur="invFocused = !invFocused"
                    :loading="invFocused ? _uploading : false"
                    :clearable="!_uploading || !invFocused"
                    dense
                    label="Upload Inventory Photos if Available"
                  ></v-file-input>
                  <v-file-input
                    multiple
                    @change="uploadJobFile($event, 'misc')"
                    @focus="miscFocused = !miscFocused"
                    @blur="miscFocused = !miscFocused"
                    :loading="miscFocused ? _uploading : false"
                    :clearable="!_uploading || !miscFocused"
                    dense
                    label="Miscellaneous Document Upload"
                  ></v-file-input>
                  <v-card-subtitle>Uploaded Files</v-card-subtitle>
                  <v-card
                    max-height="150"
                    style="overflow-x: auto"
                    class="my-1 pa-0"
                    v-if="jobAttachments"
                    flat
                  >
                    <v-list-item
                      width="100%"
                      dense
                      v-for="(attachment, index) in jobAttachments"
                      :key="index"
                      v-show="
                        attachment.catagory !== 'commodity_report_template'
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-paperclip</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ attachment.original_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ attachment.catagory.replace(/_/g, ' ') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row no-gutters>
                          <v-btn
                            icon
                            :href="attachment.location"
                            :download="attachment.original_name"
                          >
                            <v-icon color="secondary">mdi-download</v-icon>
                          </v-btn>
                          <v-btn icon @click.native="deleteFile(index)">
                            <v-icon color="error">mdi-trash-can</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </v-form>
                <v-card-actions fixed bottom class="pr-0">
                  <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
                  <v-btn text @click="step -= 1">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                  </v-btn>
                  <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                  <v-btn
                    text
                    :disabled="
                      this._specsJob.status === 'open' &&
                        (!equipValid || !equipList || assetTypeIsError)
                    "
                    class="float-right"
                    @click="step += 1"
                  >
                    Next
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-stepper-content>

          <v-stepper-step
            v-if="$vuetify.breakpoint.mdAndUp"
            :complete="step > 3"
            step="3"
          >
            Location
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-form ref="locationDetailForm" v-model="locDetailsValid">
              <div class="mx-0 pa-2">
                <v-autocomplete
                  :items="_sites"
                  item-text="name"
                  item-value="site_id"
                  :rules="[rules.required]"
                  label="Site*"
                  v-model="_specsJob.site_id_bk"
                  @change="siteChange"
                  :readonly="locationExpand"
                  :filter="customFilter"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <div style="font-size: 16px;">
                        {{ `${item.name}, ID ${item.site_id}` }}
                      </div>
                      <div style="font-size: 0.8em;  color: grey;">
                        {{
                          `${item.street}, ${item.city}, ${item.state} ${item.zip}`
                        }}
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
                <div class="justify-end">
                  <v-btn
                    rounded
                    color="secondary"
                    height="35"
                    elevation="0"
                    class="white--text mb-2"
                    :disabled="locationExpand"
                    @click="locationExpand = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add Location
                  </v-btn>
                </div>
                <v-expand-transition>
                  <div v-show="locationExpand" class="mx-0 pa-0">
                    <v-form v-model="locationValid" ref="locationForm">
                      <v-text-field
                        label="Site Name*"
                        :rules="[rules.required]"
                        v-model="site.name"
                      ></v-text-field>
                      <VueGooglePlaces
                        :api-key="googleApi"
                        types="geocode"
                        style="width: 100%"
                        version="3.41"
                        country="us"
                        @placechanged="onPlaceChanged"
                      >
                        <v-text-field
                          v-model="address"
                          prepend-icon="mdi-map-search"
                          label=""
                          :placeholder="finderPlaceholder"
                        />
                      </VueGooglePlaces>
                      <v-card class="pl-8" flat>
                        <v-row no-gutters>
                          <v-col sm="6">
                            <v-text-field
                              label="Address*"
                              :rules="[rules.required]"
                              dense
                              v-model="site.street"
                              class="pr-2"
                            />
                          </v-col>
                          <v-col sm="6">
                            <v-text-field
                              :rules="[rules.required]"
                              dense
                              label="City*"
                              v-model="site.city"
                            />
                          </v-col>
                          <v-col sm="6">
                            <v-autocomplete
                              v-model="site.state"
                              :items="usStates"
                              item-text="text"
                              item-value="value"
                              label="State *"
                              :rules="[stateRules.required]"
                              class="pr-2"
                            />
                          </v-col>

                          <v-col sm="3">
                            <v-text-field
                              dense
                              label="Zip Code *"
                              v-mask="'#####'"
                              :rules="[
                                zipCodeRules.required,
                                zipCodeRules.isCorrectFormat
                              ]"
                              class="pr-2"
                              v-model="site.zip"
                              style="margin-top: 22px"
                            />
                          </v-col>
                          <v-col sm="3">
                            <v-text-field
                              dense
                              label="Suite"
                              v-model="site.suite"
                              style="margin-top: 22px"
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-form>
                    <div class="justify-end">
                      <v-btn
                        rounded
                        color="error"
                        elevation="0"
                        height="35"
                        class="white--text mb-2 mr-2"
                        @click="locationExpand = !locationExpand"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="secondary"
                        elevation="0"
                        :loading="_siteLoading"
                        height="35"
                        :disabled="!locationValid"
                        class="white--text mb-2"
                        @click="createSite"
                      >
                        Save
                      </v-btn>
                    </div>
                  </div>
                </v-expand-transition>

                <v-checkbox
                  class="mt-0"
                  v-model="_specsJob.lift_gate"
                  label="Check here if lift gate is required"
                />
                <v-textarea
                  v-model="_specsJob.vehicle_restrictions"
                  label="Vehicle Access Restrictions"
                  height="100px"
                  class="mb-2"
                  filled
                  messages='Please include any vehicle restrictions at the pickup site.  For example: "Box truck only", "Short clearance vehicle only"'
                ></v-textarea>

                <v-textarea
                  label="Vendor Restrictions"
                  filled
                  height="100px"
                  messages="Please detail any personnel requirements for the Vendor who will be performing the pickup. For example: “Please bring a government issued ID"
                  class="mt-4"
                  v-model="_specsJob.vendor_restrictions"
                ></v-textarea>

                <div class="uncommon-box theme--light">
                  <v-row>
                    <v-col cols="12" class="text-center mb-0 pb-0">
                      <h2>Uncommon Requests</h2>
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-center mt-0 pt-0 bold text-gray text-center"
                    >
                      These situations are not common and often require an
                      additional fee.
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0 text-center text-gray">
                      Do you want the vendor to attach a report, or do you have
                      a link to an online survey?
                    </v-col>
                  </v-row>
                  <v-row class="commodity-report-box">
                    <v-col cols="12" sm="6" md="6">
                      <v-row no-gutters>
                        <v-checkbox
                          class="mt-0 pr-12"
                          v-model="_specsJob.commodity_report"
                          label="Do you need a commodity report?"
                        />
                      </v-row>
                    </v-col>
                    <v-col
                      class="mt-4"
                      cols="12"
                      sm="6"
                      md="6"
                      v-show="_specsJob.commodity_report"
                    >
                      <v-row class="ml-2 mr-2">
                        Optional: Upload a template or more details about your
                        commodity report requirements.
                      </v-row>
                      <v-row>
                        <v-file-input
                          multiple
                          :clearable="true"
                          outlined
                          dense
                          class="mt-2 mr-12"
                          label="Commodity Report Template"
                          @change="
                            uploadJobFile(
                              $event,
                              config.KEY.Commodity_Report_Template
                            )
                          "
                        ></v-file-input>
                      </v-row>
                      <v-row>
                        <span
                          v-show="checkCategoryIsExists()"
                          class="ml-2 mr-2"
                        >
                          Uploaded Files
                        </span>
                        <v-list-item
                          class="pl-0 mr-12"
                          width="100%"
                          dense
                          v-for="(attachment, index) in jobAttachments"
                          :key="index"
                          v-show="
                            attachment.catagory === 'commodity_report_template'
                          "
                        >
                          <v-list-item-icon>
                            <v-icon color="secondary">mdi-paperclip</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ attachment.original_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ attachment.catagory.replace(/_/g, ' ') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row no-gutters>
                              <v-btn
                                icon
                                :href="attachment.location"
                                :download="attachment.original_name"
                              >
                                <v-icon color="secondary">mdi-download</v-icon>
                              </v-btn>
                              <v-btn icon @click.native="deleteFile(index)">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                      </v-row>
                      <v-row class="ml-2 mr-2">
                        Add a link to an online commodity form, if available.
                      </v-row>
                      <v-row>
                        <v-text-field
                          label=""
                          outlined
                          dense
                          class="mt-2 mr-12 commodity-report-link"
                          :rules="[rules.isValidUrl]"
                          v-model="_specsJob.commodity_report_link"
                        >
                          <template v-slot:label>
                            <v-icon class="mr-2">mdi-link</v-icon>
                            Commodity Form URL
                          </template>
                        </v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <v-card flat>
                  <v-card-actions class="pr-0">
                    <v-spacer />
                    <v-btn text @click="step -= 1">
                      <v-icon>mdi-arrow-left</v-icon>
                      Back
                    </v-btn>
                    <v-btn
                      text
                      :disabled="
                        this._specsJob.status === 'open' && !locDetailsValid
                      "
                      class="float-right"
                      @click="finalStep()"
                    >
                      Next
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-form>
          </v-stepper-content>

          <v-stepper-step
            v-if="$vuetify.breakpoint.mdAndUp"
            :complete="step > 4"
            step="4"
          >
            Review
          </v-stepper-step>
          <v-stepper-content step="4">
            <edit-job-review-for-critical-job-specs
              v-if="step === 4"
              @step="step = $event"
              @post="submitJob"
              @cancel="cancel()"
              :enablePostJob="enablePostJob"
              :spec="specs"
              :statusJob="this._specsJob.status"
            />
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
    <confirm-popup-v2
      v-if="isShowConfirmUnSaveChangePopup"
      :isShow="isShowConfirmUnSaveChangePopup"
      :title="'UNSAVED CHANGES'"
      :message="'You are leaving the job form with unsaved changes.'"
      :titleButtonConfirm="'Continue Editing'"
      :titleButtonUnConfirm="'DISCARD CHANGES'"
      :confirm="continueEdit"
      :unConfirm="cancelUnSaveChange"
      :clickOutside="continueEdit"
      :isPersistent="false"
    />
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import ConfirmPopupV2 from '@/components/common/ConfirmPopupV2.vue';
// import ConfirmPopup from '@/components/common/ConfirmPopup.vue';
import EditJobReviewForCriticalJobSpecs from '@/components/jobs/EditJobReviewForCriticalJobSpecs.vue';
import Upload from '@/mixins/Upload';
import ToolTip from '@/components/app/ToolTip.vue';
import Dates from '@/mixins/Dates.js';
import config from '@/config';
import _ from 'lodash';
import * as Helpers from '@/helpers/helpers';
import { del } from 'vue';
var UsaStates = require('usa-states').UsaStates;
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    _specsJob: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [Upload, Dates],
  directives: {
    mask
  },
  components: {
    EditJobReviewForCriticalJobSpecs,
    //WarningUnsavedChangePopup,
    // ConfirmPopup,
    ConfirmPopupV2,
    ToolTip
  },
  data() {
    return {
      searchQuery: '',
      config: config,
      isAdmin: false,
      numberValue: '',
      onlyNumbersRule: v => /^\d*$/.test(v) || 'Only numbers are allowed',
      load_vendor: false,
      vendor_code: false,
      finderPlaceholder: null,
      locDetailsValid: false,
      userValid: false,
      address: '',
      invFocused: false,
      miscFocused: false,
      equipFocused: undefined,
      usStates: new UsaStates({ includeTerritories: true }).states.map(
        state => {
          return {
            text: state.name + ' (' + state.abbreviation + ')',
            value: state.abbreviation
          };
        }
      ),
      addressRules: {
        required: v => !!v || 'Address is required.',
        address: v =>
          (!new RegExp(/undefined/).test(this.site.street) &&
            this.site.street !== undefined) ||
          'Full address is required'
      },
      stateRules: {
        required: v => !!v || 'State is required.'
      },
      cityRules: {
        required: v => !!v || 'City is required.'
      },
      zipCodeRules: {
        required: v => !!v || 'Zip Code is required.',
        isCorrectFormat: v =>
          new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(v) ||
          'Please enter a valid zip code'
      },
      rules: {
        emailMatch: v =>
          new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(v) || 'Invalid e-mail.',
        required: v => !!v || 'Required.',
        confirmMatch: v =>
          this.newUser.password === this.newUser.confirm_pass ||
          'The passwords you entered don’t match',
        length: v =>
          (v && v.length >= 8) ||
          'Passwords must be at least 8 characters in length',
        phoneLength: v => (v && v.length >= 13) || 'Invalid phone number.',
        afterBidStart: v =>
          moment(this._specsJob.bid_due_date).isBefore(
            moment(this._specsJob.end_date)
          ) || 'Equipment due date must be later than bid due date',
        specialChar: v =>
          new RegExp(/[\s~`!@#$%\^&*+=\-\[\]\\‘;,/{}|\\“:<>\?()\._]/g).test(
            v
          ) || 'Passwords require at least one special character',
        afterToday: v =>
          moment(v) >= moment() || "Must be later than today's date",
        isValidUrl: url => {
          return (
            this.isValidHttpsUrl(url) ||
            'Please enter a valid website URL (e.g. https://www.recyclegx.com)'
          );
        }
      },
      locationValid: false,
      detailsForm: false,
      showResale: false,
      showCert: false,
      equipValid: false,
      locationExpand: false,
      jobName: undefined,
      euip: '',
      jobNumber: undefined,
      assetTypes: config.ASSET_TYPE,
      show1: false,
      show2: false,
      passRules: {
        required: v => !!v || 'Required.',
        emailMatch: () =>
          this.POC.password === this.POC.passwordConf ||
          "Your passwords don't match"
      },
      POCChosen: undefined,
      jobDescription: undefined,
      numPallets: undefined,
      estimatedWeight: undefined,
      equip: undefined,
      inv: undefined,
      misc: undefined,
      role: [
        {
          text: 'admin',
          value: 1
        },
        {
          text: 'user',
          value: 2
        }
      ],
      site: {
        name: undefined,
        address: undefined,
        city: undefined,
        state: undefined,
        zip: undefined
      },
      step: 5,
      vendorRestrict: undefined,
      specs: [
        {
          statement: 'Is this equipment available for purchase?*',
          bool: false
        },
        {
          statement: 'Do you require certified recycling?*',
          bool: true
        }
      ],
      startDate: moment()
        .toISOString()
        .substr(0, 10),
      endDate: moment()
        .add(1, 'days')
        .toISOString(),
      startMenu: false,
      endMenu: false,
      startDateFormatted: this.formatDate(
        moment()
          .toISOString()
          .substr(0, 10)
      ),
      endDateFormatted: this.formatDate(
        moment()
          .add(1, 'days')
          .toISOString()
          .substr(0, 10)
      ),
      siteOptions: {
        // itemsPerPage: 15,
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['name'],
        sortDesc: [false]
      },
      userOptions: {
        itemsPerPage: 99999,
        page: 1,
        search: undefined,
        sortBy: ['user_name'],
        sortDesc: [false]
      },
      isShowPopupUnsavedChanged: false,
      enablePostJob: false,
      vendorErrorMsg: 'Invalid vendor code.',
      logisticsError: false,
      isFirstTime: true,
      isShowConfirmDeletePopup: false,
      stableJob: {},
      isShowConfirmUnSaveChangePopup: false
    };
  },
  async mounted() {
    this.stableJob = _.cloneDeep(this._specsJob);
    this.$nextTick(() => {
      let siteInfo = this._sites.find(
        x => x.site_id === this._specsJob.site_id_bk
      );
      this._specsJob.site_id = this._specsJob.site_id_bk;
      this._specsJob.siteInfo = siteInfo;
      this.step = 4;
    });

    setTimeout(() => {
      this.finderPlaceholder = ' ';
    }, 2000);
    this._getSites(this.siteOptions);
    this._getUsers(this.userOptions);
    this.isAdmin = this._companyType === config.companyType.MASTER;

    setTimeout(() => {
      if (this.isVisible) {
        this.$nextTick(() => {
          this.$refs.detailsForm.validate();
          this.$refs.equipForm.validate();
          this.$refs.locationDetailForm.validate();
          this.$refs.locationForm.validate();
          this.$refs.userForm.validate();
          this.$refs.otherCheckbox.validate();
        });
      }
    }, 100);

    // start fix issue sometime site_is is undefined
    //this._specsJob.site_id = this._specsJob.site_id_bk;
    // if (
    //   _.isNil(this._specsJob.site_id) &&
    //   !_.isNil(this._specsJob.site_id_bk) &&
    //   !!this._specsJob.site_name
    // ) {
    //   this._specsJob.site_id = this._specsJob.site_id_bk;
    //   delete this._specsJob.site_id_bk;
    // }
    // setTimeout(() => {
    //   this._specsJob.site_id = this._specsJob.site_id_bk;
    //   if (_.isNil(this._specsJob.site_id) && !_.isNil(this._specsJob.site_id_bk)) {
    //     this._specsJob.site_id = this._specsJob.site_id_bk;
    //     delete this._specsJob.site_id_bk;
    //   }
    // }, 1000);

    // end fix issue sometime site_is is undefined

    // if (this.isFirstTime) {
    //   this.isFirstTime = false;
    //   if (this.isAdmin || this.statusJob === 'open') {
    //     this.step = 4;
    //     this.enablePostJob = true;
    //   } else {
    //     this.step = 1;
    //   }
    // }

    if (this._specsJob.vendor_id) {
      this.vendor_code = true;
      this._getVendorById(this._specsJob.vendor_id).then(res => {
        if (!res) {
          this.vendorErrorMsg = 'Invalid vendor code.';
          this._specsJob.vendor_name = '';
        } else {
          this.vendorErrorMsg = 'Vendor Inactive.';
          this._specsJob.vendor_name = res.data.name;
        }
        this.load_vendor = false;
      });
    } else {
      this.vendor = null;
    }

    const { bid_due_date, end_date, pallets } = this._specsJob || {};

    if (pallets) {
      this._specsJob.number_of_pallets = pallets;
    }

    if (bid_due_date && bid_due_date.indexOf('/') >= 0) {
      this._specsJob.bid_due_date = moment(bid_due_date, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      );
    }

    if (end_date && end_date.indexOf('/') >= 0) {
      this._specsJob.end_date = moment(end_date, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      );
    }

    if (Array.isArray(this._specsJob.attachments)) {
      this.jobAttachments = this._specsJob.attachments;
    }

    this.assetTypes = this._specsJob.asset_type;
  },
  computed: {
    ...mapGetters({
      _user: 'user/getUser',
      _active: 'jobs/getEditNewJob', // Vu edited
      _sites: 'sites/getSites',
      _company_id: 'company/getCompanyId',
      _users: 'user/getUsers',
      _file: 'uploads/getFile',
      _company: 'company/getCompany',
      _newSite: 'sites/createdSite',
      _siteLoading: 'sites/loading',
      _jobLoading: 'jobs/loading',
      _jobLoadingForDraft: 'jobs/loadingForDraft',
      _specsJob: 'jobs/getCurrentJob', // Vu added
      _uploading: 'uploads/loading',
      _companyType: 'company/getCompanyType',
      _vendor: 'company/getVendor',
      _bids: 'bids/getBids'
    }),
    vendor: {
      get() {
        return this._vendor;
      },
      set(val) {
        this._setVendor(val);
      }
    },
    user: {
      get() {
        return this._user;
      },
      set(val) {
        this._setUser(val);
      }
    },
    googleApi() {
      return 'AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE'; //process.env.VUE_APP_GOOGLE_API_KEY;
    },
    mobileMargin() {
      if (this.step < 3) {
        return '0 -40px 0 0';
      } else if (this.step > 6) {
        return '0 0 0 -40px';
      } else {
        return '0 -20px 0 -20px';
      }
    },
    bidDuration() {
      let a = moment(this._specsJob.bid_due_date || moment());
      let b = moment(this._specsJob.end_date || moment());
      return b.diff(a, 'days');
    },
    equipList() {
      let cod = this.jobAttachments.filter(
        o => o.catagory === 'equipment_list'
      );
      return cod.length > 0 ? true : false;
    },
    assetTypeIsError() {
      const isValidAssetType = Object.keys(this.assetTypes).some(
        key => key !== 'other' && this.assetTypes[key]
      );
      if (this.assetTypes.other_checked) {
        return this.assetTypes.other === '';
      }
      return !isValidAssetType;
    }
  },
  watch: {
    _sites() {
      if (this._newSite && this._newSite.id) {
        console.log('111111_33444', this._newSite);
        this._specsJob.site_id = this._newSite.id;
        this._specsJob.site_id_bk = this._newSite.id;
      }
    },
    address() {
      this.site.address = this.site.address
        ? this.address.street
        : this.site.address;
    },
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    upPhoto: {
      handler: function(val) {
        this._uploadFile(this.upPhoto);
      },
      deep: true
    },
    upEquipment: {
      handler: function(val) {
        this._uploadFile(this.upEquipment);
      },
      deep: true
    },
    upMis: {
      handler: function(val) {
        this._uploadFile(this.upMis);
      },
      deep: true
    },
    _file: {
      handler: function(val) {
        if (!this._active) {
          return;
        }

        if (this._file.type === 'Photo') {
          this.upPhotoBase64 = this._file.file;
        } else if (this._file.type === 'Equipment') {
          this.upEquipmentBase64 = this._file.file;
        } else if (this._file.type === 'Miscellaneous') {
          this.upMisBase64 = this._file.file;
        }
      },
      deep: true
    },
    _specsJob: {
      handler(val, oldVal) {
        // setTimeout(() => {
        //   if (this._active) {
        //     this.$refs.detailsForm.validate();
        //     this.$refs.equipForm.validate();
        //     this.$refs.locationDetailForm.validate();
        //     this.$refs.locationForm.validate();
        //     this.$refs.userForm.validate();
        //     this.$nextTick(() => {
        //       this.$refs.otherCheckbox.validate();
        //     });
        //   }
        // }, 100);
        // // start fix issue sometime site_is is undefined
        // setTimeout(() => {
        //   if (
        //     _.isNil(this._specsJob.site_id) &&
        //     !_.isNil(this._specsJob.site_id_bk) &&
        //     !!this._specsJob.site_name
        //   ) {
        //     this._specsJob.site_id = this._specsJob.site_id_bk;
        //     delete this._specsJob.site_id_bk;
        //   }
        // }, 2000);
        // // end fix issue sometime site_is is undefined
        // if (this.isFirstTime) {
        //   this.isFirstTime = false;
        //   if (this.isAdmin || this.statusJob === 'open') {
        //     this.step = 4;
        //     this.enablePostJob = true;
        //   } else {
        //     this.step = 1;
        //   }
        // }
        // if (this._specsJob.vendor_id) {
        //   this.vendor_code = true;
        //   this._getVendorById(this._specsJob.vendor_id).then(res => {
        //     if (!res) {
        //       this.vendorErrorMsg = 'Invalid vendor code.';
        //       this._specsJob.vendor_name = '';
        //     } else {
        //       this.vendorErrorMsg = 'Vendor Inactive.';
        //       this._specsJob.vendor_name = res.data.name;
        //     }
        //     this.load_vendor = false;
        //   });
        // } else {
        //   this.vendor = null;
        // }
        // const { bid_due_date, end_date, pallets } = this._specsJob || {};
        // if (pallets) {
        //   this._specsJob.number_of_pallets = pallets;
        // }
        // if (bid_due_date && bid_due_date.indexOf('/') >= 0) {
        //   this._specsJob.bid_due_date = moment(bid_due_date, 'MM/DD/YYYY').format(
        //     'YYYY-MM-DD'
        //   );
        // }
        // if (end_date && end_date.indexOf('/') >= 0) {
        //   this._specsJob.end_date = moment(end_date, 'MM/DD/YYYY').format(
        //     'YYYY-MM-DD'
        //   );
        // }
        // if (Array.isArray(this._specsJob.attachments)) {
        //   this.jobAttachments = this._specsJob.attachments;
        // }
        // this.assetTypes = this._specsJob.asset_type;
      }
    },
    _company: {
      handler: function() {
        this._getSites(this.siteOptions);
        this._getUsers(this.userOptions);
      },
      deep: true
    },
    _newSite: {
      handler: function(val) {
        this._getSites(this.siteOptions);

        this.locationExpand = false;
        this.$refs.locationForm.reset();
        this.locationExpand = false;
        this.$refs.locationForm.reset();
      },
      deep: true
    },
    'site.state': function() {
      this.site.state = this.site.state
        ? this.site.state.toUpperCase()
        : this.site.state;
    },
    jobAttachments: {
      handler() {
        this._specsJob.attachments = this.jobAttachments;
      },
      deep: true
    },
    '_specsJob.logistics': function(val) {
      this.logisticsError = !val;
    }
    // isVisible(newVal) {
    //   if (newVal) {
    //     document.body.style.overflow = 'auto';
    //   } else {
    //     document.body.style.overflow = 'hidden';
    //   }
    // }
  },
  methods: {
    ...Helpers,
    ...mapMutations({
      _setUser: 'user/setUser',
      _setVendor: 'company/setVendor',
      _setActive: 'jobs/setEditNewJob',
      _setNewJob: 'jobs/setNewJob',
      _setShowWarningUnsavedChange: 'jobs/setShowWarningUnsavedChange'
    }),
    ...mapActions({
      _getSites: 'sites/getAll',
      _getUsers: 'user/getAll',
      _createSite: 'sites/createSite',
      _createUser: 'user/createUser',
      _createJobHistory: 'jobs/createJobHistory',
      _updateJob: 'jobs/updateJob',
      _uploadFile: 'uploads/uploadFile',
      _sendMail: 'mail/sendMail',
      _getVendorById: 'company/getVendorById',
      _deleteJob: 'jobs/deleteJob',
      _getBids: 'bids/getAll',
      _setJob_v2: 'jobs/setCurrent_v2'
    }),
    onlyNumber($event) {
      if (
        !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes($event.key)
      ) {
        $event.preventDefault();
      }
    },
    finalStep() {
      this.step += 1;
      if (!this.vendor_code || !(this.vendor && !this.load_vendor)) {
        this._specsJob.vendor_id = null;
      }

      let siteInfo = this._sites.find(
        x => x.site_id === this._specsJob.site_id_bk
      );
      this._specsJob.site_id = this._specsJob.site_id_bk;
      this._specsJob.siteInfo = siteInfo;

      this.enablePostJob = this.allowPostJob();

      this._specsJob.bid_due_date === '' ? null : this._specsJob.bid_due_date;
      this._specsJob.end_date == '' ? null : this._specsJob.end_date;
      this._specsJob.asset_type = this.assetTypes;
    },
    validateTest() {
      this.$refs.detailsForm.validate();
    },
    onPlaceChanged(e) {
      this.site.street = e.street_number + ' ' + e.route;
      this.site.city = e.locality;
      this.site.state = e.administrative_area_level_1;
      this.site.zip = e.postal_code;
      this.address = '';
      if (e.street_number !== undefined) {
        this.address = this.address.concat(e.street_number);
      }
      if (e.route !== undefined) {
        this.address = this.address.concat(' ' + e.route);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.locality !== undefined) {
        this.address = this.address.concat(e.locality);
      }
      if (this.address !== '') {
        this.address = this.address.concat(', ');
      }
      if (e.administrative_area_level_1 !== undefined) {
        this.address = this.address.concat(e.administrative_area_level_1);
      }
      if (e.postal_code !== undefined) {
        this.address = this.address.concat(' ' + e.postal_code);
      }
      this.address = this.address.concat(', USA');
    },
    setState() {
      if (this.site.state.length > 1) {
        this.site.state = this.site.state.substring(0, 1);
      }
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format('MM/DD/YYYY');
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return moment(date).format('YYYY-MM-DD');
    },
    async submitJob() {
      let pocUsers = this._users.filter(
        user => user.user_id == this._specsJob.poc_id
      );
      if (pocUsers.length > 0) {
        this._specsJob.poc_email = pocUsers[0].email;
      }

      var payload = { ...this._specsJob };

      payload.isSendMail = payload.status === 'draft';
      payload.status = payload.status === 'draft' ? 'open' : payload.status;

      payload.bid_due_date = this._specsJob.bid_due_date;
      payload.id = payload.job_id;

      payload.rec_spec = { ...this._specsJob.recycle_specs };
      payload.vendor_id =
        this.vendor_code && this.vendor ? this.vendor.id : null;

      this.removeNoNeedField(payload, false);

      let dataHistory = this.handleJobHistory();
      return this._updateJob(payload).then(async res => {
        if (
          res.status == 200 &&
          // this._specsJob.bid_status != config.ConstString.NONE &&
          dataHistory.isChanged
        ) {
          delete dataHistory.isChanged; // remove isChanged key
          dataHistory.currentJob = payload;
          await this._createJobHistory(dataHistory);
        }
        this._setActive(false);

        // this.$emit('close');
        // let dataReq = {
        //   payload: this._specsJob.job_id,
        //   disableLoading: true
        // };
        // await this._setJob_v2(dataReq);
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      });
    },
    getVendor: _.debounce(function() {
      if (this._specsJob.vendor_id) {
        this.load_vendor = true;
        this._getVendorById(this._specsJob.vendor_id).then(res => {
          if (!res) {
            this.vendorErrorMsg = 'Invalid vendor code.';
            this._specsJob.vendor_name = '';
          } else {
            this.vendorErrorMsg = 'Vendor Inactive.';
            this._specsJob.vendor_name = res.data.name;
          }
          this.load_vendor = false;
        });
      } else {
        this.vendor = null;
        this.load_vendor = false;
      }
    }, 800),
    createSite() {
      this._createSite(this.site).then(() => {});
    },
    createUser_old() {
      if (this.POC.email !== undefined) {
        this.POC.type_id = 4;
        this.POC.password = 'N/A';
        var u = this.POC;
        u.createdEmail = this._user.email;
        u.createdUser =
          this._user.first_name +
          ' ' +
          (this._user.last_name ? this._user.last_name : '');
        u.user_name = this.POC.email;
        this._createUser(u).then(() => {});
      } else {
        return;
      }
    },
    createUser(newUser, userType) {
      if (newUser.email !== undefined) {
        this.typeOfNewUser = userType;
        newUser.type_id = userType;
        newUser.password = 'N/A';
        newUser.createdEmail = this._user.email;
        newUser.createdUser =
          this._user.first_name +
          ' ' +
          (this._user.last_name ? this._user.last_name : '');
        newUser.user_name = newUser.email;
        this._createUser(newUser).then(() => {});
      }
    },
    deleteFile(index) {
      this.jobAttachments.splice(index, 1);
    },
    siteChange() {
      //this._specsJob.site_id = this._specsJob.site_id_bk;
    },
    pocChange() {},
    pmChange() {},
    discardChanged() {
      this._setActive(false);
      this.$refs.detailsForm.reset();
      this.$refs.equipForm.reset();
      this.$refs.locationDetailForm.reset();
      this.$refs.locationForm.reset();
      this.$refs.userForm.reset();
      this.isShowPopupUnsavedChanged = false;
      this.isFirstTime = true;
    },
    startValidate() {
      this.$refs.detailsForm.validate();
      this.$refs.equipForm.validate();
      this.$refs.locationDetailForm.validate();
      this.$refs.locationForm.validate();
    },
    onChangeStartDate() {
      this.$refs.detailsForm.validate();
      if (!detailsForm) {
        this.$refs.endDate.focus();
      }
    },
    onOtherCheckBoxChanged(value) {
      if (!value) {
        this.assetTypes.other = '';
      }
      this.$nextTick(() => {
        this.$refs.otherCheckbox.validate();
      });
    },
    clearReportTemplate(category) {
      let jobAttachments = this.jobAttachments;
      for (let i = 0; i < jobAttachments.length; i++) {
        if (this.jobAttachments[i].catagory === category) {
          this.jobAttachments.splice(i, 1);
        }
      }
    },
    checkCategoryIsExists(category) {
      let jobAttachments = this.jobAttachments;
      let att = jobAttachments.filter(attachment => {
        return attachment.catagory === category;
      });
      return att && att.length > 0;
    },
    removeNoNeedField(payload, isSaveDraft = true) {
      if (isSaveDraft) {
        delete payload.company_name;
        delete payload.company_email;
        delete payload.poc_email;
      }
      delete payload.job_id;
      delete payload.recycle_specs;
      delete payload.bid_id;
      delete payload.bid_per_pound;
      delete payload.bid_buy_out;
      delete payload.weight;
      delete payload.date;
      delete payload.pallets;
      delete payload.poc_phone;
      delete payload.poc_fname;
      delete payload.poc_lname;
      delete payload.poc_alt_phone;

      delete payload.poc_user_name;
      delete payload.site_name;
      delete payload.city;
      delete payload.zip;
      delete payload.state;
      delete payload.street;
      delete payload.location;
      delete payload.saved;
      delete payload.vendor_name;
      return payload;
    },
    allowPostJob() {
      var invalidVendor = true;
      if (this.vendor_code) {
        invalidVendor =
          !this.vendor_code ||
          (this.vendor_code &&
            (this._specsJob.vendor_id == '' ||
              this._specsJob.vendor_id == null ||
              this._specsJob.vendor_id == undefined)) ||
          (this.vendor_code && !!this._vendor && !!this._specsJob.vendor_id);
      }

      if (
        this.detailsForm &&
        this.equipValid &&
        this.equipList &&
        this.locDetailsValid &&
        invalidVendor &&
        !this.assetTypeIsError
      ) {
        return true;
      }
      return false;
    },
    handleJobNameChange(event) {
      if (
        this._specsJob.name.length == 60 &&
        !this.isFunctionKey(event.keyCode)
      ) {
        event.preventDefault();
        // this._specsJob.name = this._specsJob.name.substr(0, 59);
      }
    },
    confirmDeleteJob() {
      this._deleteJob(this._specsJob.job_id).then(() => {
        this.isShowConfirmDeletePopup = false;
        this._setActive(false);
        setTimeout(() => {
          if (this.$router.currentRoute.path === '/job') {
            location.reload();
          } else {
            this.$router.replace('/job').catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });
          }
        }, 500);
      });
    },
    buildSiteItemText(item) {
      return `${item.name}, ID ${item.site_id} ${item.street}, ${item.city}, ${item.state} ${item.zip}`;
    },
    customFilter(item, queryText, itemText) {
      itemText = `${item.name}, ID ${item.site_id} ${item.street}, ${item.city}, ${item.state} ${item.zip}`;
      return itemText.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    handleJobHistory() {
      var fieldNeedToCompare = [
        'id',
        'name',
        'description',
        'number_of_pallets',
        'est_weight',
        'asset_type',
        'lift_gate',
        'vehicle_restrictions',
        'rec_spec',
        'site_id',
        'vendor_restrictions',
        'end_date',
        'attachments',
        'bid_due_date',
        'logistics',
        'exact_removal_date',
        'commodity_report',
        'commodity_report_link'
      ];

      var isChanged = false;
      var infoJobChanged = {};
      var infoJobPrevious = {};
      for (let fieldName of fieldNeedToCompare) {
        //recycle_specs
        // if (fieldName == 'site_id') {
        //   if (this.stableJob.site_id == 'undefined' || this._specsJob.site_id == 'undefined')
        //     continue;
        // } else
        if (fieldName == 'rec_spec') {
          if (
            !_.isEqual(
              this.stableJob.recycle_specs.resale,
              this._specsJob.recycle_specs.resale
            )
          ) {
            isChanged = true;

            infoJobChanged[
              'rec_spec.resale'
            ] = this._specsJob.recycle_specs.resale;

            infoJobPrevious[
              'rec_spec.resale'
            ] = this.stableJob.recycle_specs.resale;
          }
          if (
            !_.isEqual(
              this.stableJob.recycle_specs.certified_recycle,
              this._specsJob.recycle_specs.certified_recycle
            )
          ) {
            isChanged = true;

            infoJobChanged[
              'rec_spec.certified_recycle'
            ] = this._specsJob.recycle_specs.certified_recycle;

            infoJobPrevious[
              'rec_spec.certified_recycle'
            ] = this.stableJob.recycle_specs.certified_recycle;
          }
        } else if (
          !_.isEqual(this.stableJob[fieldName], this._specsJob[fieldName])
        ) {
          isChanged = true;
          infoJobChanged[fieldName] = this._specsJob[fieldName];
          infoJobPrevious[fieldName] = this.stableJob[fieldName];
        }
      }
      return {
        job_id: this._specsJob.job_id,
        history: {
          new: infoJobChanged,
          old: infoJobPrevious
        },
        isChanged: isChanged
      };
    },
    async cancel() {
      // this.$emit('close');
      //this.$router.go();
      let dataReq = {
        payload: this._specsJob.job_id,
        disableLoading: true
      };
      await this._setJob_v2(dataReq);
      this.$emit('close');
    },
    showConfirmUnSaveChangePopup() {
      this.isShowConfirmUnSaveChangePopup = true;
      this.isVisible = true;
    },
    async cancelUnSaveChange() {
      this.isShowConfirmUnSaveChangePopup = false;
      await this.cancel();
    },
    continueEdit() {
      this.isVisible = true;
      this.isShowConfirmUnSaveChangePopup = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.flex-container {
  display: flex;
  align-items: center;
}

.no-padding {
  padding-left: 0;
}

.my-radio-buttons {
  transform: translateY(-9px) scale(0.6) translateX(17px);
  height: 10px;
}

.border-bottom {
  border-bottom: 1px solid gray;
}

.location-card {
  border: none;
}

.mx-auto {
  padding-right: 37px;
}

.close-btn {
  padding: 0;
}

.justify-end {
  display: flex;
  justify-items: end;
}

.top-padding {
  padding-top: 20px;
}

.mobile-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.cancel-btn {
  padding-top: 40px;
  display: flex;
  justify-items: stretch;
}

.stop-scroll {
  overflow-y: hidden;
}

.add-scroll {
  overflow-y: auto;
}

.autocomplete-field {
  font-weight: 400;
}

.exact-removal-date {
  //padding-top: 5% !important;
}

.check-box {
  margin-top: -11px;
  margin-bottom: -11px;
}

.order-checkbox {
  margin-top: -11px !important;
}

.uncommon-box {
  width: 100%;
  border-radius: 5px;
  padding: 15px 5px 15px 10px;
  min-height: 150px;
  margin-top: 10px;
  background-color: #f0f0f0;
}

.commodity-report-box {
  // min-height: 100px;
}

.maxlength-box {
  display: flex;
  justify-content: space-between;
}

.card-subtitle-review {
  color: #5d5d5d;
  font-size: 1.25em;
  font-weight: bold;
}

.error-checkbox .v-input__control {
  border-color: 1px solid #e53935 !important;
}

.error-checkbox .v-label {
  color: #e53935 !important;
}

.gray-background {
  background-color: #f0f0f0;
}

.v-input--is-error .v-radio .v-icon {
  color: inherit !important;
}

.v-input--is-error .v-label {
  color: inherit !important;
}

.commodity-report-link {
  width: 100px !important;
  padding-left: 30px !important;
}

.text-gray {
  color: gray;
}

.bold {
  font-weight: bold;
}
</style>
