<template>
  <div style="height: 40px;">
    <v-row
      v-if="!edit"
      no-gutters
      class="mb-4"
      :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'start'"
      align="center"
    >
      <!-- <v-spacer /> -->
      <v-btn back color="secondary" rounded @click.native="$emit('edit')">
        <!--v-icon>mdi-square-edit-outline</v-icon-->
        Edit {{ tt }}
      </v-btn>
    </v-row>
    <v-row
      v-else
      no-gutters
      class="mb-4"
      :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'start'"
      align="center"
    >
      <v-alert
        dense
        v-if="$vuetify.breakpoint.mdAndUp"
        transition="fade"
        border="bottom"
        icon="mdi-clock"
        type="info"
        :value="time"
        class="mr-3"
      >
        Auto saving in 10 seconds...
      </v-alert>

      <v-alert
        dense
        border="bottom"
        class="mr-3 mb-0"
        v-if="$vuetify.breakpoint.mdAndUp"
        transition="fade"
        icon="mdi-pencil"
        color="green lighten-2"
        outlined
        :value="editing"
      >
        Editing...
      </v-alert>
      <!-- <v-spacer /> -->
      <v-btn color="error" icon class="elevation-0 mr-1" @click="cancel()">
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        class="elevation-0"
        :loading="loading"
        icon
        @click="save()"
      >
        <v-icon>mdi-content-save-edit</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: { edit: Boolean, txt: String, loading: Boolean },
  data() {
    return {
      tt: this.txt,
      time: false,
      editing: false,
      autoSave: undefined,
      timeWarn: undefined
    };
  },
  watch: {
    edit() {
      //console.log("this.tt="+this.tt+". this.txt="+this.txt);
      //this.tt= this.txt;
      if (this.edit) {
        this.editing = true;
        this.autoSave = setTimeout(() => {
          this.timesUp();
        }, 120000);
      }
    }
  },
  computed: {
    ...mapGetters({
      _company: 'company/getCompany',
      _loading: 'company/getLoading'
    })
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.editing = false;
      this.time = false;
      clearTimeout(this.autoSave);
      if (this.timeWarn) {
        clearTimeout(this.timeWarn);
      }
    },
    save() {
      this.$emit('save');
      this.editing = false;
      this.time = false;
      clearTimeout(this.autoSave);
      if (this.timeWarn) {
        clearTimeout(this.timeWarn);
      }
    },
    timesUp() {
      this.editing = false;
      this.time = true;
      this.timeWarn = setTimeout(() => {
        if (this.edit) {
          this.$emit('save');
          this.time = false;
        }
      }, 10000);
    }
  }
};
</script>
