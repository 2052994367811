const state = {
  showGoogleDocDialog: false,
  src: '',
  name: '',
  type: undefined,
  index: 0,
  list: []
};

const getters = {
  getShowGoogleDocDialog: state => {
    return state.showGoogleDocDialog;
  },
  getSrc: state => {
    return state.src;
  },
  getType: state => {
    return state.type;
  },
  getIndex: state => {
    return state.index;
  },
  getList: state => {
    return state.list;
  },
  getName: state => {
    return state.name;
  }
};
const actions = {};
const mutations = {
  setShowGoogleDocDialog: (state, googledocPopup) => {
    // let t=state.src.split('.');
    // console.log("t: "+state.src, t);
    // state.type=t[t.length-1];
    //console.log("setShowGoogleDocDialog: "+state.src, state.type);
    state.showGoogleDocDialog = googledocPopup;
  },
  setSrc: (state, src) => {
    let t = state.src.split('.');
    console.log('t: ' + state.src, t);
    state.type = t[t.length - 1];
    state.src = src;
  },
  setList: (state, list) => {
    state.list = list;
  },
  setIndex: (state, i) => {
    console.log('list: ' + i, state.list);
    state.index = i;
    state.src = state.list[i].location;
    state.name = state.list[i].original_name;
    let t = state.src.split('.');
    state.type = t[t.length - 1];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
