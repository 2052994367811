<template>
	<div v-if="_compType">
		<v-row
			v-if="(_compType && _compType === 1) || _compType === 3"
			class="mt-2 flex-nowrap"
			style="max-width: 100%"
			no-gutters
		>
			<v-sheet max-width="75%">
				<v-slide-group
					v-model="typeFilter"
					:loading="_loading"
					mandatory
					show-arrows
					v-if="$vuetify.breakpoint.mdAndUp"
				>
					<v-slide-item 
						v-for="(i, k) in customerTabs"
						:key="k"
						:value="i.value"
						v-slot:default="{ active, toggle }"
						active-class="primary disable-btn"
					>
						<v-btn :disabled="company_status=='On Hold' && (i.value=='open' || i.value=='draft')"
							:rounded="active"
							:text="!active"
							height="50"
							class="elevation-0 my-1 mr-4"
							@click="toggle"
							>{{ i.text }}</v-btn
						>
					</v-slide-item>
				</v-slide-group>
			</v-sheet>
			<v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
			<v-btn :disabled="company_status=='On Hold'"
				rounded
				color="secondary"
				height="50"
				class="white--text"
				@click="_setJobPre(true)"
				v-if="$vuetify.breakpoint.mdAndUp && _compType !== 3"
				><v-icon class="mr-1">mdi-briefcase-plus</v-icon> CREATE JOB</v-btn
			>
			<v-btn
				v-if="$vuetify.breakpoint.smAndDown"
				class="white--text my-2"
				fab
				absolute
				right
				top
				color="secondary"
				@click="_setJobPre(true)"
			>
				<v-icon>mdi-briefcase-plus</v-icon>
			</v-btn>
			<v-select
				filled
				class="elevation-0 primary ma-2 mt-5 mb-2"
				hide-details
				rounded
				dark
				v-if="$vuetify.breakpoint.smAndDown"
				v-model="typeFilter"
				:items="customerTabs"
			/>
		</v-row>
		<v-row
			v-if="_compType === 2"
			class="mt-2 flex-nowrap"
			style="max-width: 100%"
			no-gutters
		>
			<v-sheet max-width="100%" v-if="$vuetify.breakpoint.mdAndUp">
				<v-slide-group
					v-model="typeFilter"
					:loading="_loading"
					mandatory
					show-arrows
				>
					<v-slide-item
						v-for="(i, k) in vendorTabs"
						:key="k"
						:value="i.value"
						v-slot:default="{ active, toggle }"
						active-class="primary disable-btn"
					>
						<v-btn :disabled="company_status=='On Hold'&&(i.value=='draft'||i.value=='open')"
							:rounded="active"
							:text="!active"
							height="50"
							class="elevation-0 my-1 mr-4"
							@click="toggle"
							>{{ i.text }}</v-btn
						>
					</v-slide-item>
				</v-slide-group>
			</v-sheet>
			<v-select
				filled
				class="elevation-0 primary ma-2 mb-4"
				hide-details
				rounded
				block
				dark
				v-else
				v-model="typeFilter"
				:items="vendorTabs"
			/>
		</v-row>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Options from "@/mixins/Options";
export default {
	mixins: [Options],
	data() {
		return {  company_status:'',
			customerTabs: [
			    { value: "draft", text: "Job Drafts" },
				{ value: "open", text: "Open Jobs" },
				//{ value: "open_bid", text: "Open Bids" },
				{ value: "awarded", text: "Awarded Jobs" },
				{ value: "closing", text: "Ready To Close" },
				//{ value: "resale", text: "Pending Purchase Offer" },
				//{ value: "dispute", text: "Under Dispute" }, //huy.tran removed RGX-86
			],
			vendorTabs: [
				{ value: "open", text: "Open Jobs" },
				//{ value: "open_bid", text: "Open Bids" },
				{ value: "awarded", text: "Awarded Jobs" },
				{ value: "closing", text: "Waiting For Close" },
				//{ value: "resale", text: "Pending Purchase Aproval" },
				//{ value: "dispute", text: "Under Dispute" },//huy.tran removed RGX-86
			],
			
		};
	},
	computed: {
		...mapGetters({
			 _company: "company/getCompany",
			_compType: "company/getCompanyType",
			_type: "jobs/getStatus",
			_loading: "jobs/loading",
		}),
		typeFilter: {
			get() {
				return this._type;
			},
			set(newVal) {
				this._setType(newVal);
			},
		},
	},
	watch: {
		typeFilter(newVal, old) {
			localStorage.setItem("jobTab", newVal);
		},
	},
	methods: {
		...mapMutations({
			_setType: "jobs/setStatus",
			_setJobPre: "jobs/setJobPreCheck",
		}),
		...mapActions({
			_getJobs: "jobs/getAll",
		}),
	},
	// updated() {
	// 	this.options.tabName = this.typeFilter;
	// },
	mounted() { 
		this.company_status= this._company.status;
		  console.log("mounted this.company_status",this.company_status);

		if (localStorage.getItem("jobTab")) {
			this.typeFilter = localStorage.getItem("jobTab");
		} else {
			localStorage.setItem("jobTab", this.typeFilter);
		}
		if(this.company_status=='On Hold')this.typeFilter="awarded";
      	 setTimeout(() => {
      if(!this.company_status){
		this.company_status= this._company.status;
		// console.log("this.company_status",this.company_status);

		if (localStorage.getItem("jobTab")) {
			this.typeFilter = localStorage.getItem("jobTab");
		} else {
			localStorage.setItem("jobTab", this.typeFilter);
		}
		if(this.company_status=='On Hold')this.typeFilter="awarded";
	  }	
    	},2000);

		// if(this.company_status=='On Hold' ){
		// 	this.vendorTabs= [
		// 		{ value: "awarded", text: "Awarded Jobs" },
		// 		{ value: "closing", text: "Waiting For Close" },
		// 	];
		// }
	},
};
</script>
<style scoped>
.disable-btn {
	pointer-events: none;
}
</style>
