<template>
	<div>
		<v-row class="pt-5 justify-space-between" no-gutters>
			<v-skeleton-loader
				ref="heading"
				type="card-heading"
				class="mx-0"
        width="25%"
			></v-skeleton-loader>
			<v-spacer />
			<v-skeleton-loader
				ref="skeleton"
				type="actions"
				class="mx-0"
        width="50%"
			></v-skeleton-loader>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="6" class="pa-2">
				<v-skeleton-loader
					ref="heading"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" sm="12" md="3" class="pa-2">
				<v-skeleton-loader
					ref="skeleton"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" sm="12" md="3" class="pa-2">
				<v-skeleton-loader
					ref="skeleton"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="6" class="pa-2">
				<v-skeleton-loader
					ref="heading"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" sm="12" md="3" class="pa-2">
				<v-skeleton-loader
					ref="skeleton"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" sm="12" md="3" class="pa-2">
				<v-skeleton-loader
					ref="skeleton"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="6" class="pa-2">
				<v-skeleton-loader
					ref="heading"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" sm="12" md="3" class="pa-2">
				<v-skeleton-loader
					ref="skeleton"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
			<v-col cols="12" sm="12" md="3" class="pa-2">
				<v-skeleton-loader
					ref="skeleton"
					type="paragraph"
					class="mx-0"
				></v-skeleton-loader>
			</v-col>
		</v-row>
		<v-skeleton-loader
			ref="skeleton"
			type="table"
			width="100%"
			class="mt-5 pt-5 mx-0"
		></v-skeleton-loader>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
};
</script>
