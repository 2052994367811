/* eslint-disable camelcase */
import { createCompany, update, createUser, get, getAll, remove, upload, getCompanyByListId } from '../../api';
import config from '../../config';

// initial customer state
const state = {
  company: {
    vendor_eula: undefined
  },
  companies: undefined,
  vendor: undefined,
  error: undefined,
  loading: false,
  showCompanyRejectDialog: false,
  showAccountStatusDialog: false,
  currentCompanyStatus: '',
  updateCompanyStatusMessage: '',
  editedCompany: {},
  ListOfUnits: [],
  loggedInCompany: {
    vendor_eula: undefined
  },
  vendorCompanyProfileStatus: null
};

// getters
const getters = {
  getShowReasonRejectDialog: state => {
    //huy added
    return state.showReasonRejectDialog;
  },
  getCompanies: state => {
    return state.companies;
  },
  getVendor: state => {
    return state.vendor;
  },
  getCompanyName: state => {
    return state.company ? state.company.name : undefined;
  },
  getCompany: state => {
    if (state.company && Array.isArray(state.company.attachments)) {
      state.company.attachments = state.company.attachments.filter(att => att.catagory);
    }
    return state.company;
  },
  getCompanyId: state => {
    return state.company ? state.company.id : undefined;
  },
  getCompanyType: state => {
    return state.company ? state.company.type_id : 1;
  },
  getLoading: state => {
    return state.loading;
  },
  edited: state => {
    return state.editedCompany;
  },
  getShowCompanyRejectDialog: state => {
    //huy added
    return state.showCompanyRejectDialog;
  },
  getShowAccountStatusDialog: state => {
    //huy added
    return state.showAccountStatusDialog;
  },
  getCurrentCompanyStatus: state => {
    //huy added
    return state.currentCompanyStatus;
  },
  getUpdateCompanyStatusMessage: state => {
    //huy added
    return state.updateCompanyStatusMessage;
  },
  getLoggedInCompany: state => {
    if (state.loggedInCompany && Array.isArray(state.loggedInCompany.attachments)) {
      state.loggedInCompany.attachments = state.loggedInCompany.attachments.filter(att => att.catagory);
    }
    return state.loggedInCompany;
  },
  getVendorCompanyProfileStatus: state => {
    return state.vendorCompanyProfileStatus;
  }
};

const route = '/company';

// actions
const actions = {
  getCurrent: async ({ commit, dispatch }, payload) => {
    try {
      return get(route, payload)
        .then(res => {
          commit('setCompany', res.data.data); // need to check object
          dispatch('getCompanyProfileStatus', null, { root: true });
        })
        .catch(err => {
          commit('setCompanies', undefined);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
    }
  },
  getVendorById: async ({ commit }, payload) => {
    try {
      return get(route, payload)
        .then(res => {
          if (
            res.data.data.type_id == 2 &&
            res.data.data.status != 'Delete' &&
            res.data.data.status != 'Archive' &&
            res.data.data.status != 'On Hold'
          ) {
            commit('setVendor', res.data.data);
          } else {
            // need to check object
            commit('setVendor', undefined);
          }
          return res.data;
        })
        .catch(err => {
          commit('setVendor', undefined);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
    }
  },
  getAll: async ({ commit, payload }) => {
    commit('toggleLoading', true);
    const constructedRoute = route + '?options=' + encodeURIComponent(JSON.stringify(payload));
    try {
      return getAll(constructedRoute)
        .then(res => {
          commit('setCompanies', res.data); // need to chack object
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setCompanies', undefined);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  uploadLogo: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    const reader = new FileReader();
    reader.readAsDataURL(payload.file);
    try {
      reader.onload = () =>
        upload('/media/upload', payload.file.name, reader.result).then(res => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', 'New company logo uploaded', {
            root: true
          });
          commit('notification/setNotificationType', 'success', {
            root: true
          });
          commit('setLogo', res.data.data.Location);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', 'Error uploading file/image', {
        root: true
      });
      commit('toggleLoading', false);
    }
  },
  createCompany: async ({ state, commit, dispatch }, payload) => {
    const company = {
      company_name: payload.company_name,
      company_street: payload.company_street,
      company_city: payload.company_city,
      company_state: payload.company_state,
      company_zip: payload.company_zip,
      business_type: payload.business_type
    };
    const user = {
      // company_id: payload.id,
      username: payload.username,
      email: payload.email,
      password: payload.password,
      phone_number: payload.phone_number
    };
    try {
      console.log('tao company', company);
      const companyRes = await createCompany(company);
      commit('setCompany', companyRes.data.data);
      dispatch('fetchCompany', payload.id);
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', 'Create failed', {
        root: true
      });
    }
    try {
      console.log('tao user', user);
      const userRes = await createUser(user);
      commit('setUser', userRes.data.data);
      dispatch('fetchUser', payload);
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', 'Create failed', {
        root: true
      });
    }
  },
  removeCompany: async ({ commit }, payload) => {
    return remove(route, payload);
  },
  updateCompany: async ({ commit }, payload) => {
    commit('toggleLoading', true);

    try {
      return update(route, payload)
        .then(res => {
          console.log('update company successfully');
          commit('notification/setNotificationType', 'success', { root: true });
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('toggleLoading', false);
          // commit('setCompany', res.data.data);
          return res.data;
          // commit("notification/setNotificationType", "success", { root: true });
          // commit("setEdited", res.data.data);
          // commit("toggleLoading", true);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Update failed', {
            root: true
          });
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  },
  updateCompanyStatus: async ({ commit }, payload) => {
    console.log('call update company status', payload);
    commit('toggleLoading', true);

    try {
      //payload.id=payload.company_id;
      return update(route, payload)
        .then(res => {
          console.log('update company status successfully', res);
          //console.log("update company status successfully t",typeof(res));

          if (res.data) {
            if (res.data.message)
              commit('notification/setNotificationMsg', res.data.message, {
                root: true
              });
          } else {
            if (res.toString().indexOf('Error:') >= 0) {
              console.log('error', res);
              commit('company/setUpdateCompanyStatusMessage', res.toString(), {
                root: true
              });
              return;
            }
          }
          setTimeout(() => {
            if (payload.status == 'Delete') window.location.href = window.location.href.replace(payload.id, '');
          }, 2200);
          console.log('error 2', res.toString().indexOf('Error:'));
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('company/setUpdateCompanyStatusMessage', 'ok', { root: true });
          commit('toggleLoading', false);
        })
        .catch(err => {
          console.log('updateCompanyStatus err', err);
          // commit("notification/setNotification", true, { root: true });
          if (err.response && err.response.data && err.response.data.message)
            commit('notification/setNotificationMsg', err.response.data.message, {
              root: true
            });
          // commit("notification/setNotificationType", "error", { root: true });
          commit('toggleLoading', false);
          // if(err.response &&err.response.data&&err.response.data.message)
          commit('company/setUpdateCompanyStatusMessage', err, { root: true });
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', 'Update failed', {
        root: true
      });
      commit('toggleLoading', false);
    }
  },
  updateCustomerFee: async ({ commit }, payload) => {
    return update(route, payload);
  },
  getCompanyProfileStatus: ({ commit }) => {
    //#region new validate company profile
    const certChecked = state.company.r2_cert && state.company.r2_cert.cert;
    if (
      !state.company.vendor_eula ||
      certChecked === null ||
      !Array.isArray(state.company.attachments || state.company.attachments?.length <= 0)
    ) {
      commit('setVendorCompanyProfileStatus', config.companyProfileStatus.incomplete);
      return config.companyProfileStatus.incomplete;
    }

    if (state.company.type_id !== 2) {
      commit('setVendorCompanyProfileStatus', config.companyProfileStatus.complete);
      return config.companyProfileStatus.complete;
    }

    const w9Uploaded = checkIfAttachmentUploaded(state.company.attachments, 'w9');
    const insuranceUploaded = checkIfAttachmentUploaded(state.company.attachments, 'insurance');
    const certUploaded = checkIfAttachmentUploaded(state.company.attachments, 'certification');

    let resaleIsValid = true;
    if (state.company.resale == true) {
      resaleIsValid = checkIfAttachmentUploaded(state.company.attachments, 'resale');
    }

    if (certChecked) {
      const allCertUploaded = w9Uploaded && insuranceUploaded && certUploaded && resaleIsValid;
      let res = allCertUploaded ? config.companyProfileStatus.complete : config.companyProfileStatus.incomplete;
      commit('setVendorCompanyProfileStatus', res);
      return res;
    }

    let res =
      insuranceUploaded && w9Uploaded && resaleIsValid ? config.companyProfileStatus.complete : config.companyProfileStatus.incomplete;
    commit('setVendorCompanyProfileStatus', res);
    return res;

    //#endregion

    //#region old validate company profile
    // const certChecked = state.company.r2_cert && state.company.r2_cert.cert;
    // if (
    //   !state.company.vendor_eula ||
    //   certChecked === null ||
    //   !Array.isArray(state.company.attachments || state.company.attachments.length <= 0)
    // ) {
    //   return config.companyProfileStatus.incomplete;
    // }
    // if (state.company.type_id !== 2) {
    //   return config.companyProfileStatus.complete;
    // }
    // if (!Array.isArray(state.company.vendor_abilities) || state.company.vendor_abilities.length <= 0) {
    //   return config.companyProfileStatus.incomplete;
    // }
    // let profileStatus = config.companyProfileStatus.complete;
    // state.company.vendor_abilities.forEach(statement => {
    //   if (!statement.hasOwnProperty('able') || statement.able === undefined) {
    //     profileStatus = config.companyProfileStatus.incomplete;
    //     return;
    //   }
    // });
    // if (profileStatus === config.companyProfileStatus.incomplete) {
    //   return profileStatus;
    // }
    // const w9Uploaded = checkIfAttachmentUploaded(state.company.attachments, 'w9');
    // const insuranceUploaded = checkIfAttachmentUploaded(state.company.attachments, 'insurance');
    // const certUploaded = checkIfAttachmentUploaded(state.company.attachments, 'miscellaneous');
    // if (certChecked) {
    //   const allCertUploaded = w9Uploaded && insuranceUploaded && certUploaded;
    //   return allCertUploaded ? config.companyProfileStatus.complete : config.companyProfileStatus.incomplete;
    // }
    // return insuranceUploaded && w9Uploaded ? config.companyProfileStatus.complete : config.companyProfileStatus.incomplete;
    //#endregion
  },
  getCompanyByListId: async ({ commit }, payload) => {
    try {
      const res = await getCompanyByListId(payload);
      return res.data.data;
    } catch (err) {
      console.error('Failed to fetch companies:', err);
      commit('setError', err);
      throw err;
    }
  }
};

// mutations
const mutations = {
  setEdited: (state, edit) => {
    state.company = edit;
  },
  setLogo: (state, logo) => {
    state.company.icon_url = logo;
  },
  setCompany: (state, company) => {
    state.company = company;
  },
  setVendor: (state, vendor) => {
    state.vendor = vendor;
  },
  setCompanies: (state, company) => {
    state.company = company;
  },
  setError: (state, err) => {
    state.error = err;
  },
  toggleLoading: (state, loading) => {
    state.loading = loading;
  },
  setClosingConf: (state, closingConf) => {
    console.log('setClosingConf', closingConf);
    state.closingConf = closingConf;
  },
  setShowCompanyRejectDialog: (state, boolean) => {
    //huy added
    console.log('company.js setShowCompanyRejectDialog', boolean);
    state.showCompanyRejectDialog = boolean;
  },
  setShowAccountStatusDialog: (state, boolean) => {
    //huy added
    console.log('company.js setShowAccountStatusDialog', boolean);
    state.showAccountStatusDialog = boolean;
  },
  setCompanyStatusChange: (state, s) => {
    //huy added
    console.log('company.js setCompanyStatusChange', s);
    state.currentCompanyStatus = s;
    console.log('company.js currentCompanyStatus', state.currentCompanyStatus);
  },
  setUpdateCompanyStatusMessage: (state, m) => {
    //huy added
    console.log('company.js setUpdateCompanyStatusMessage', m);
    state.updateCompanyStatusMessage = m;
    // console.log("company.js setUpdateCompanyStatusMessage",m );
  },
  setLoggedInCompany: (state, data) => {
    state.loggedInCompany = JSON.parse(JSON.stringify(data));
  },
  setVendorCompanyProfileStatus: (state, status) => {
    console.log('1111111', status);
    //alert('setVendorCompanyProfileStatus ww', status);
    state.vendorCompanyProfileStatus = status;
  }
};

//HELPER function
const checkIfAttachmentUploaded = (attachments, type) => {
  if (!Array.isArray(attachments) || attachments.length <= 0) {
    return false;
  }

  let found = false;
  attachments.map(v => {
    if (v.catagory === type) {
      found = true;
    }
  });
  return found;
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
