<template>
  <div>
    <v-card v-if="_job" flat>
      <v-row v-if="_compType === 3" no-gutters class="align-center mt-6">
        <v-col cols="6">
          <v-card-title>
            <span v-if="_job.status === 'awarded'">Awarded Job</span>
            <span
              v-if="
                _job.status === 'closing' ||
                  _job.status === 'resale' ||
                  _job.status === 'dispute'
              "
            >
              Ready to Close
            </span>
            <span v-if="_job.status === 'closed'">Closed Job</span>
          </v-card-title>
        </v-col>

        <v-col cols="6" class="d-flex justify-end align-center">
          <v-card-title>Admin Actions</v-card-title>
          <v-btn class="btn-archive">ARCHIVE</v-btn>
          <v-btn class="btn-archive ml-2" @click="openEditFees" color="success">
            EDIT FEES
          </v-btn>
        </v-col>
      </v-row>

      <!-- 
    <v-card-title v-if="_compType !== 3">
      {{ statusTitle(_job.status) }} Details
    </v-card-title>
    <v-card-subtitle v-if="_compType !== 3" class="mx-0"
      >The Job is currently in a state that has no available actions for you.
      Feel free to contact the customer or vendor to follow up.
    </v-card-subtitle> 
    -->
      <!-- Huy updated title for RGX-158 -->

      <v-card-title
        v-if="_job.status === 'rejected' && _job.bid_status === 'rejected'"
        class="ma-2 pa-2"
        style="color:red"
      >
        Reopened job
      </v-card-title>
      <v-card
        v-if="_job.status === 'rejected' && _job.bid_status === 'rejected'"
        height="calc(100% - 16px);"
        style="border-color:red"
        class="ma-2 pa-2"
        outlined
      >
        <v-row class="d-flex flex-wrap no-gutters justify-space-between">
          <v-col cols="12" sm="12" class="ma-0 ma-0 px-0 py-1">
            <v-card-text class="py-0" v-if="_bid">
              The customer has rejected this job’s closing details. The
              reason(s) provided are:
              <br />
              {{ _bid.rejected_reason }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>

      <!-- Vu updated title for RGX-135 & RGX-136 -->
      <v-card-title v-if="_compType === 1 && _job.status === 'awarded'">
        Awarded Details
      </v-card-title>
      <v-card-subtitle
        v-if="_compType === 1 && _job.status === 'awarded'"
        class="mx-0"
      >
        The Job is currently in a state that has no available actions for you.
        Feel free to contact the customer or vendor to follow up.
      </v-card-subtitle>
      <v-card-title
        v-if="(_compType === 1 || _compType === 2) && _job.status === 'closed'"
      >
        Closed Job
      </v-card-title>
      <v-card-title
        v-if="
          _compType === 1 &&
            (_job.status === 'resale' ||
              _job.status === 'closing' ||
              _job.status === 'dispute')
        "
      >
        Ready to Close
      </v-card-title>
      <v-card-subtitle
        v-if="
          _compType === 1 &&
            (_job.status === 'resale' ||
              _job.status === 'closing' ||
              _job.status === 'dispute')
        "
        class="mx-0"
      >
        The job is ready for you to accept or reject the final details and close
        the transaction.
      </v-card-subtitle>

      <v-card-title
        v-if="
          _compType === 2 &&
            (_job.status === 'resale' ||
              _job.status === 'closing' ||
              _job.status === 'dispute')
        "
      >
        Waiting to Close
      </v-card-title>
      <v-card-subtitle
        v-if="
          _compType === 2 &&
            (_job.status === 'resale' ||
              _job.status === 'closing' ||
              _job.status === 'dispute')
        "
        class="mx-0"
      >
        The job is currently waiting for the customer to accept the final
        details and close the transaction.
      </v-card-subtitle>
      <!-- END -->

      <v-row no-gutters>
        <v-col cols="12" sm="6" class="ma-0 pa-0">
          <closing-details />
        </v-col>
        <v-col v-if="_bid" cols="12" sm="6">
          <v-card height="calc(100% - 16px)" class="ma-2 pa-2" outlined>
            <v-row
              class="d-flex flex-wrap no-gutters justify-space-between"
              justify="center"
              align="start"
              no-gutters
            >
              <v-col cols="12" sm="3" md="4" class="ma-0 px-0 py-1">
                <v-card-subtitle class="py-0">Vendor</v-card-subtitle>
                <v-card-text class="py-0">
                  {{ _bid.name ? _bid.name : null }}
                </v-card-text>
                <!--
                <v-card-text class="py-0" v-if="_bid.site_id || _bid.street">
                  {{ _bid.site_id ? _bid.site_street  : _bid.street }} <br />
                  {{ _bid.site_id ? _bid.site_city + ", " + _bid.site_state + " " + _bid.site_zip
                                  : _bid.city + ", " + _bid.state + " " + _bid.zip
                  }}
                </v-card-text> -->
                <v-card-text class="py-0" v-if="_bid.site_id">
                  {{
                    _bid.site_suite
                      ? _bid.site_street + ', Suite ' + _bid.site_suite
                      : _bid.site_street
                  }}
                  <br />
                  {{
                    _bid.site_city +
                      ', ' +
                      _bid.site_state +
                      ' ' +
                      _bid.site_zip
                  }}
                </v-card-text>
                <v-card-text class="py-0" v-if="!_bid.site_id && _bid.street">
                  {{
                    _bid.suite
                      ? _bid.street + ', Suite ' + _bid.suite
                      : _bid.street
                  }}
                  <br />
                  {{ _bid.city + ', ' + _bid.state + ' ' + _bid.zip }}
                </v-card-text>
                <br />

                <!-- <v-card-subtitle class="py-0"> Logistics Contact 2222 </v-card-subtitle>
                <v-card-text class="py-0" v-if="_bid.poc_id && _bid.poc_first_name && _bid.poc_last_name
                  ">
                  {{
                    _bid.poc_id && _bid.poc_first_name
                    ? _bid.poc_first_name + " " + _bid.poc_last_name
                    : null
                  }}
                </v-card-text> -->

                <v-card-subtitle class="py-0">Job Contact</v-card-subtitle>
                <v-card-text
                  class="py-0"
                  v-if="
                    _bid.poc_id && _bid.poc_first_name && _bid.poc_last_name
                  "
                >
                  {{
                    _bid.poc_id && _bid.poc_first_name
                      ? _bid.poc_first_name + ' ' + _bid.poc_last_name
                      : null
                  }}
                </v-card-text>
                <v-card-text
                  class="py-0"
                  v-if="
                    _bid.poc_id && _bid.poc_first_name && !_bid.poc_last_name
                  "
                >
                  {{
                    _bid.poc_id && _bid.poc_first_name
                      ? _bid.poc_first_name
                      : null
                  }}
                </v-card-text>
                <v-card-text class="py-0">
                  {{ _bid.poc_id ? _bid.poc_email : _bid.email }}
                </v-card-text>
                <v-card-text class="py-0">
                  {{ _bid.poc_id ? _bid.poc_phone : _bid.phone }}
                </v-card-text>
              </v-col>

              <v-col cols="12" sm="12" md="8" class="ma-0 px-0 py-1">
                <v-card-subtitle class="py-0">
                  Vendor Attachments
                  <!--ok 123-->
                </v-card-subtitle>
                <v-card-text class="py-0">
                  <v-list-item-group
                    v-if="_bid.attachments && _bid.attachments.length > 0"
                  >
                    <v-list-item
                      v-for="(attachment, index) in _bid.attachments"
                      :key="index"
                    >
                      <v-list-item-icon>
                        <v-icon color="secondary" v-if="attachment.location">
                          mdi-paperclip
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-on:click="showGoogleDocIndex(index)"
                        >
                          {{ attachment.original_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ attachment.catagory.replace(/_/g, ' ') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row no-gutters>
                          <v-btn
                            icon
                            :href="attachment.location"
                            :download="attachment.original_name"
                          >
                            <v-icon color="secondary">mdi-download</v-icon>
                          </v-btn>
                          <v-btn icon v-on:click="showGoogleDocIndex(index)">
                            <v-icon color="secondary">mdi-file-find</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="false">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-paperclip</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-on:click="exportToPDF()">
                          RGX Pickup Receipt Job {{ _job.job_id }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          pickup receipt
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row no-gutters>
                          <v-btn icon v-on:click="exportToPDF()">
                            <v-icon color="secondary">mdi-download</v-icon>
                          </v-btn>
                          <v-btn icon v-on:click="exportToPDF()">
                            <v-icon color="secondary">mdi-file-find</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="order-0 pa-0 ma-0">
          <job-docs />
        </v-col>
        <!-- Huy updated for RGX-133 -->
        <v-col
          v-if="
            (_compType === 1 || _compType === 3) &&
              _job.status === 'awarded' &&
              _bid.info.scenario >= 0
          "
          cols="12"
          sm="6"
          class="ma-0 pa-0"
        >
          <closing-total-new :finalValid="false" :confirm="false" />
        </v-col>

        <!-- Vu updated for RGX-135, RGX-133, RGX-136 
        <v-col
          v-if="
            (_compType === 1 || _compType === 3) &&
            _job.status === 'awarded' &&
            _bid.info.scenario === 0
          "
          cols="12"
          sm="6"
          class="ma-0 pa-0"
        >
          <closing-total :finalValid="false" :confirm="false" />
        </v-col>

        <v-col
          v-if="
            (_compType === 1 || _compType === 3) &&
            (_job.status === 'closing' ||
              _job.status === 'resale' ||
              _job.status === 'dispute' ||
              _job.status === 'closed') &&
              _bid.info.scenario === 0"
          cols="12"
          sm="6"
          class="ma-0 pa-0"
        >
          <closing-total :finalValid="false" :confirm="false" />
        </v-col>
        <v-col
          v-if="
            (_compType === 1 || _compType === 3) &&
            (_job.status === 'closing' ||
              _job.status === 'resale' ||
              _job.status === 'dispute' ||
              _job.status === 'closed') &&
            _bid.info.scenario >= 0"
          cols="12"
          sm="6"
          class="ma-0 pa-0"
        >
          <customer-closing-total :finalValid="false" :confirm="false" />
        </v-col> -->
        <v-col
          v-if="
            (_compType === 1 || _compType === 3) &&
              (_job.status === 'closing' ||
              _job.status === 'resale' ||
              _job.status === 'dispute' ||
              _job.status === 'rejected' || //huy added rgx-158
                _job.status === 'closed')
          "
          cols="12"
          sm="6"
          class="ma-0 pa-0"
        >
          <div v-if="!transFinalDetailsLoading">
            <customer-closing-total :finalValid="false" :confirm="false" />
          </div>
          <div v-else-if="transFinalDetailsLoading" class="ma-5">
            <skeleton-detail />
          </div>
        </v-col>
        <!-- for Vendors -->
        <v-col v-if="_compType === 2" cols="12" sm="6" class="ma-0 pa-0">
          <vendor-closing-total :finalValid="false" :confirm="false" />
        </v-col>
        <!-- END -->
        <v-col
          v-if="_bid && !(_job.status === 'closed')"
          cols="12"
          sm="12"
          class="order-0 pa-0 ma-0"
        >
          <google-map :bidAddress="bidAddress" :jobAddress="jobAddress" />
          <google-doc />
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="editing"
      @click:outside="editing = false"
      max-width="400"
    >
      <v-card v-if="editingJobFee">
        <div class="btn-dismiss-dialog">
          <v-btn text @click="editing = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title>Edit Job Fee</v-card-title>
        <v-card-text>
          <v-form v-model="editFormValid">
            <v-row
              no-gutters
              style="width: 80%; align-items: center"
              justify="center"
            >
              <v-col cols="6">
                <v-card-subtitle>Scrap Fee</v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  type="money"
                  v-money="editingJobFee.scrap_purchase_percentage"
                  v-model.lazy="editingJobFee.scrap_purchase_percentage"
                  v-model="editingJobFee.scrap_purchase_percentage"
                  :disabled="!editing"
                  :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber
                  ]"
                  append-outer-icon="%"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-card-subtitle>Scrap Per Pound</v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :disabled="!editing"
                  type="money"
                  append-outer-icon="mdi-currency-usd"
                  v-model="editingJobFee.scrap_per_pound"
                  v-model.lazy="editingJobFee.scrap_per_pound"
                  v-money="editingJobFee.scrap_per_pound"
                  :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-card-subtitle>Equipment Fee</v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model.lazy="editingJobFee.equipment_purchase_percentage"
                  v-money="editingJobFee.equipment_purchase_percentage"
                  v-model="editingJobFee.equipment_purchase_percentage"
                  :disabled="!editing"
                  type="money"
                  append-outer-icon="%"
                  :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-space-between">
                  <v-btn
                    color="error"
                    rounded
                    @click="cancelEditCustomerFee"
                    class="ml-2 btn-company-info-archive"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="success"
                    rounded
                    :loading="feeLoading"
                    :disabled="!editFormValid"
                    @click="updateCustomerFee"
                    class="ml-2 btn-company-info-restore"
                  >
                    Save
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <job-reason-reject-dialog />
    <div style="display:none">
      <div id="element-to-convert">
        RGX Pickup Receipt Job {{ _job.job_id }}
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
//import ClosingTotal from "./JobClosingTotal.vue";
import ClosingTotalNew from './JobClosingTotalNew.vue'; // Huy added
import VendorClosingTotal from './VendorJobClosingTotal.vue'; // Vu added
import CustomerClosingTotal from './CustomerJobClosingTotal.vue'; // Vu added
import SkeletonDetail from '@/components/skeleton/Detail.vue'; // Vu added
import ClosingDetails from './JobClosingDetails.vue';
import JobDocs from '@/components/jobs/JobDocs.vue';
import GoogleMap from '@/components/app/GoogleMap.vue';
import GoogleDoc from '@/components/app/GoogleDoc.vue';
import JobReasonRejectDialog from '@/components/jobs/JobReasonRejectDialog.vue'; //huy added
export default {
  components: {
    JobDocs,
    // ClosingTotal,
    ClosingTotalNew,
    ClosingDetails,
    GoogleMap,
    GoogleDoc,
    VendorClosingTotal,
    CustomerClosingTotal,
    SkeletonDetail,
    JobReasonRejectDialog
  },
  data() {
    return {
      editFormValid: false,
      editing: false,
      editingJobFee: null,
      feeLoading: false,
      transFinalDetailsLoading: true, // Vu added
      rules: {
        required: v => !!v || 'Required.',
        minFee: v => {
          return (
            parseFloat(v ? (v + '').replace(/,/g, '') : 0.1) >= 0.0 ||
            'Invalid Fee.'
          );
        },
        maxFee: v =>
          parseFloat(v ? v.replace(/,/g, '') : 0.1) <= 100.0 || 'Invalid Fee.',

        negativeNumber: v => !new RegExp(/-/g).test(v) || 'Invalid Fee'
      }
    };
  },
  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      _bid: 'bids/getCurrentBidInfo',
      _compType: 'company/getCompanyType',
      _loading: 'jobs/loading'
    }),

    bidAddress() {
      if (this._bid && this._bid.site_name) {
        return (
          this._bid.site_street +
          ' ' +
          this._bid.site_city +
          ', ' +
          this._bid.site_state +
          ' ' +
          this._bid.site_zip
        );
      } else {
        return (
          this._bid.street +
          ' ' +
          this._bid.city +
          ', ' +
          this._bid.state +
          ' ' +
          this._bid.zip
        );
      }
    },
    jobAddress() {
      return (
        this._job.street +
        ' ' +
        this._job.city +
        ', ' +
        this._job.state +
        ' ' +
        this._job.zip
      );
    },
    total() {
      return (
        Number(this._job.bid_per_pound) * this._job.final_weight -
        (this._job.resale_offer !== null ? this._job.resale_offer : 0)
      );
    },
    buy_out_total() {
      return (
        Number(this._job.bid_buy_out) -
        (this._job.resale_offer !== null ? this._job.resale_offer : 0)
      );
    }
  },
  methods: {
    ...mapActions({
      _setBid: 'bids/getCurrentInfo',
      _updateJob: 'jobs/updateJob'
    }),
    ...mapMutations({
      _setGoogleDoc: 'googledoc/setShowGoogleDocDialog',
      _setSrc: 'googledoc/setSrc',
      _setIndex: 'googledoc/setIndex',
      _setList: 'googledoc/setList'
    }),
    statusTitle(s) {
      let title = s === 'dispute' ? 'disputed' : s;
      title = title === 'resale' ? 'purchase Offer' : title;
      return title.charAt(0).toUpperCase() + title.slice(1);
    },
    cancelEditCustomerFee() {
      this.editing = false;
      this.editingJobFee = null;
    },
    exportToPDF() {
      //	html2pdf(document.getElementById('element-to-convert'));
      html2pdf()
        .from(document.getElementById('element-to-convert'))
        .toPdf()
        .output('datauristring')
        .then(function(pdfAsString) {
          // The PDF has been converted to a Data URI string and passed to this function.
          // Use pdfAsString however you like (send as email, etc)! For instance:
          console.log(pdfAsString);
        });
    },

    openEditFees() {
      this.editing = true;
      if (this._job) {
        this.editingJobFee = {
          scrap_purchase_percentage: this._job.scrap_purchase_percentage,
          scrap_per_pound: this._job.scrap_per_pound,
          equipment_purchase_percentage: this._job.equipment_purchase_percentage
        };
      }
    },

    updateCustomerFee() {
      this.feeLoading = true;
      this.editing = true;
      this._updateJob({
        id: this._job.job_id,
        disableLoading: true,
        ...this.editingJobFee
      }).then(() => {
        this.feeLoading = false;
        this.editing = false;
      });
    },
    showGoogleDoc(src) {
      // console.log("google doc src",src);
      this.attSrc = src;
      this._setSrc(src);
      this._setGoogleDoc(true);
    },
    showGoogleDocIndex(index) {
      //  console.log("showGoogleDocIndex: "+index,this._bid.attachments);
      this._setList(this._bid.attachments);
      this._setIndex(index);
      this._setGoogleDoc(true);
    }
  },
  mounted() {
    if (this._job) {
      //   console.log("mounted this._job ok: ",this._job);
      this._setBid(this._job.bid_id);
    } else {
      setTimeout(() => {
        //   console.log("mounted this._job not ok: ",this._job);
        if (this._job) this._setBid(this._job.bid_id);
      }, 1000);
    }
    // Vu added 10/19
    setTimeout(() => {
      this.transFinalDetailsLoading = false;
    }, 1500);
  }
};
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  width: 80%;
}

.location {
  grid-column: 1;
  grid-row: 1;
}

.number {
  grid-column: 2;
  grid-row: 1;
}

.duration {
  grid-column: 3;
  grid-row: 1;
}

.weight {
  grid-column: 1;
  grid-row: 2;
}

.pallets {
  grid-column: 1;
  grid-row: 3;
}

.recycling {
  grid-column: 2;
  grid-row: 2 / span 2;
}

.access {
  grid-column: 3;
  grid-row: 2;
}

.lift {
  grid-column: 3;
  grid-row: 3;
}

.sub {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #5d5d5d;
}

.vendor {
  grid-column: 1;
}

.address {
  grid-column: 2;
}

.phone {
  grid-column: 3;
}

.email {
  grid-column: 4;
}

.company-name {
  font-family: Roboto;
  font-size: 23px;
  color: #2e2e2e;
}

.center {
  align-self: center;
}

.text-center {
  text-align: center;
}

.link-color {
  color: #0091ff;
}

a {
  text-decoration: none;
}
</style>
