import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import Welcome from '@/views/Welcome.vue';
import Registration from '@/views/Registration.vue';
import CompanySites from '@/views/Site.vue';
import Team from '@/views/Team.vue';
import Profile from '@/views/Profile.vue';
import Dashboard from '@/views/Dashboard.vue';
import Jobs from '@/views/Jobs.vue';
import Customers from '@/views/Customers.vue';
import CustomerDetails from '@/views/CustomerInfomation.vue';
import VendorDetail from '@/views/VendorDetail.vue';
import Vendors from '@/views/Vendors.vue';
import JobDetail from '@/views/JobDetail.vue';
import JobHistory from '@/views/JobHistory.vue';
import JobHistoryDetails from '@/views/JobHistoryDetails.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import OPTOutEmail from '@/views/OptOutEmail';
import NotFound from '@/views/NotFound.vue';
import store from '../_store/index.js';

Vue.use(VueRouter);

const routes = [
  { path: '/404', component: NotFound },
  { path: '*', redirect: '/404' },
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/user/email-opt-out',
    name: 'Email Opt Out',
    component: OPTOutEmail
  },
  {
    path: '/forgot-password/:code',
    name: 'Forgot Password',
    component: ForgotPassword
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register/:type',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/site',
    name: 'Sites',
    component: CompanySites,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/job',
    name: 'Jobs',
    component: Jobs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/history',
    name: 'Jobs History',
    component: JobHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer',
    name: 'Customers',
    component: Customers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer/:id',
    name: 'Customers Details',
    component: CustomerDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vendor',
    name: 'Vendors',
    component: Vendors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vendor/:id',
    name: 'Vendor Detail',
    component: VendorDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/history/:id',
    name: 'Job History Details',
    component: JobHistoryDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/job/:id',
    name: 'Job Detail',
    component: JobDetail,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
});

// authentication check
router.beforeEach((to, from, next) => {
  const loggedIn = store.state.authenticate.loggedIn;
  const token = VueCookies.isKey('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (loggedIn || token) {
      next();
    } else {
      localStorage.setItem('loginRedirectUrl', JSON.stringify(to));
      router.push('/');
    }
  } else {
    next();
  }
});

export default router;
