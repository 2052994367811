<template>
  <v-card class="pa-4" flat>
    <jobs-history-table table-title="Jobs History" />
  </v-card>
</template>
<script>
import JobsHistoryTable from "@/components/jobs/HistoryTable.vue";
// import NewJobPre from "@/components/jobs/NewJobPre.vue";
// import NewJobForm from "@/components/jobs/NewJobForm.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    JobsHistoryTable
    // NewJobPre,
    // NewJobForm
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      _type: "jobs/getStatus",
      _loading: "jobs/loading",
      _newJob: "jobs/getJobPreCheck"
    }),
    typeFilter: {
      get() {
        return this._type;
      },
      set(newVal) {
        this._setType(newVal);
      }
    },
    title() {
      switch (this.typeFilter) {
        case "open":
          return "Open Jobs";
        case "draft":
          return "Job Drafts";
        case "ppo":
          return "Pending Purchase Offer";
        case "dispute":
          return "Under Dispute";
        case "awarded":
          return "Awarded Jobs";
        case "open_bid":
          return "Open Bids";
        case "awarded_bid":
          return "Awarded Bids";
        case "resale":
          return "Ready for Resale";
        case "closing":
          return "Ready to Close";
        default:
          return "Open Jobs";
      }
    }
  },
  methods: {
    ...mapMutations({
      _setType: "jobs/setStatus",
      _setNewJob: "jobs/setJobPreCheck"
    }),
    createNewJob() {
      this._setNewJob(true);
    }
  }
};
</script>
