<template>
	<div>
		<v-list-item
			v-for="(a, k) in _job.attachments"
			:key="k"
			dense
			:href="a.location"
			target="_blank"
      class="px-1"
		>
			<v-list-item-avatar>
				<v-icon color="secondary">mdi-paperclip</v-icon>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>{{ a.original_name }}</v-list-item-title>
				<v-list-item-subtitle>{{
					a.catagory.replace(/_/g, " ")
				}}</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	props: {},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			_job: "jobs/getCurrentJob",
		}),
	},
};
</script>
