import { getAll, get, update, create, remove } from "../../api";
import router from "@/router";
// import { stat, Stats } from "fs";

// initial job state
const state = {
	bpm: undefined,
	avBids: undefined,
	availableJobs: undefined,
	activeBids: undefined,
	awardedBids: undefined,
	closed: undefined,
	poundsPerMonth: undefined,
	loadingArray: [],
  summary: undefined
};

// getters
const getters = {
	loading: (state) => {
		return state.loadingArray;
	},
	confirm: (state) => {
		return state.confirm;
	},
	bpm: (state) => {
		return state.bpm;
	},
	avBids: (state) => {
		return state.avBids;
	},
	closed: (state) => {
		return state.closed;
	},
	availableJobs: (state) => {
		return state.availableJobs;
	},
	activeBids: (state) => {
		return state.activeBids;
	},
	poundsPerMonth: (state) => {
		return state.poundsPerMonth;
	},
	awardedBids: (state) => {
		return state.awardedBids;
	},
	getLoading: (state) => {
		return state.loading;
	},
  getSummary: (state) => {
    return state.summary
  }
};

const route = "/dashboard/bpm";

// actions
const actions = {
	getCustomerPPM: async ({ commit, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");
		try {
			return get("/job/customer/ppm", payload)
				.then((res) => {
					commit("setPoundsPerMonth", res.data.data);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
				});
		} catch (err) {
			commit("setError", err);
		}
	},
	getCustomerClosed: async ({ commit, rootState }, payload) => {
		commit("appendLoading", "ppm");

		payload = rootState.company.company.id;
		try {
			return get("/job/closed/customer", payload)
				.then((res) => {
					commit("setClosed", res.data.data[0].count);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getCustomerAwardedJobs: async ({ commit, dispatch, rootState }, payload) => {
		commit("appendLoading", "ppm");

		payload = rootState.company.company.id;
		try {
			return get("/job/awarded", payload)
				.then((res) => {
					commit("setAwardedBids", res.data.data[0].count);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getCustomerActiveBids: async ({ commit, dispatch, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");

		try {
			return get("/job/active", payload)
				.then((res) => {
					commit("setActiveBids", res.data.data[0].count);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
		}
	},
	getCustomerBPM: async ({ commit, dispatch, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");

		try {
			return get("/job/revenue", payload)
				.then((res) => {
					commit("setBPM", res.data.data);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getCustomerAvBids: async ({ commit, dispatch, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");

		try {
			return get("/bid/avbid", payload)
				.then((res) => {
					commit("setAverageBids", res.data.data[0]);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getCustomerAvailableJobs: async (
		{ commit, dispatch, rootState },
		payload
	) => {
		try {
			payload = rootState.company.company.r2_cert.cert;
		} catch (err) {
			return err;
		}
		commit("appendLoading", "ppm");

		try {
			return get("/job/available", payload)
				.then((res) => {
					commit("setAvailableJobs", res.data.data);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getVendorPPM: async ({ commit, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");
		try {
			return get("/job/ppm", payload)
				.then((res) => {
					commit("setPoundsPerMonth", res.data.data);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
				});
		} catch (err) {
			commit("setError", err);
		}
	},
	getVendorClosed: async ({ commit, rootState }, payload) => {
		commit("appendLoading", "ppm");

		payload = rootState.company.company.id;
		try {
			return get("/job/closed", payload)
				.then((res) => {
					commit("setClosed", res.data.data[0].count);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getVendorAwardedBids: async ({ commit, dispatch, rootState }, payload) => {
		commit("appendLoading", "ppm");

		payload = rootState.company.company.id;
		try {
			return get("/bid/awarded", payload)
				.then((res) => {
					commit("setAwardedBids", res.data.data[0].count);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getVendorActiveBids: async ({ commit, dispatch, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");

		try {
			return get("/bid/active", payload)
				.then((res) => {
					commit("setActiveBids", res.data.data[0].count);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
		}
	},
	getVendorBPM: async ({ commit, dispatch, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");

		try {
			return get("/bid/bpm", payload)
				.then((res) => {
					commit("setBPM", res.data.data);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getVendorAvBids: async ({ commit, dispatch, rootState }, payload) => {
		payload = rootState.company.company.id;
		commit("appendLoading", "ppm");

		try {
			return get("/bid/avbid", payload)
				.then((res) => {
					commit("setAverageBids", res.data.data[0]);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
	getVendorAvailableJobs: async ({ commit, dispatch, rootState }, payload) => {
		payload = rootState.company.company.r2_cert.cert;
		commit("appendLoading", "ppm");

		try {
			return get("/job/available", payload)
				.then((res) => {
					commit("setAvailableJobs", res.data.data);
					commit("removeLoading");
				})
				.catch((err) => {
					commit("setError", err);
					commit("removeLoading");
				});
		} catch (err) {
			commit("setError", err);
			commit("removeLoading");
		}
	},
  acquireSummary: async ({ commit }, payload) => {
    return new Promise((reject, resolve) => {
      const constructedRoute = `master/summary`
      getAll(constructedRoute)
        .then(res => {
          commit('setSummary', res.data.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};

// mutations
const mutations = {
	appendLoading: (state, loading) => {
		state.loadingArray.push(loading);
	},
	removeLoading: (state) => {
		state.loadingArray.splice(0, 1);
	},
	toggleReview: (state, review) => {
		state.bidReview = review;
	},
	toggleConfirm: (state, confirm) => {
		state.confirm = confirm;
	},
	toggleLoading: (state, loading) => {
		state.loading = loading;
	},
	setClosed: (state, bid) => {
		state.closed = bid;
	},
	setBPM: (state, bpm) => {
		state.bpm = bpm;
	},
	setPoundsPerMonth: (state, ppm) => {
		state.poundsPerMonth = ppm;
	},
	setAverageBids: (state, bids) => {
		state.avBids = bids;
	},
	setActiveBids: (state, bids) => {
		state.activeBids = bids;
	},
	setAwardedBids: (state, bids) => {
		state.awardedBids = bids;
	},
	setAvailableJobs: (state, bids) => {
		state.availableJobs = bids;
	},
	setCurrentBid: (state, currentBid) => {
		state.currentBid = currentBid;
	},
	setError: (state, err) => {
		state.error = err;
	},
	setBidReview: (state, bidReview) => {
		state.bidReview = bidReview;
	},
	setCurrentBidCompany: (state, currentBidCompany) => {
		state.currentBidCompany = currentBidCompany;
	},
  setSummary: (state, summary) => {
    state.summary = summary;
  }
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
