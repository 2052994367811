import Vue from "vue";
import Vuex from "vuex";
import company from "./modules/company";
import sites from "./modules/sites";
import user from "./modules/user";
import jobs from "./modules/jobs";
import bids from "./modules/bids";
import vendors from "./modules/vendors";
import customers from "./modules/customers";
import uploads from "./modules/uploads";
import registration from "./modules/registration";
import googledoc from "./modules/googledoc";
import authenticate from "./modules/authentication";
import notification from "./modules/notification";
import VueCookies from "vue-cookies";
import dashboard from "./modules/dashboard";
import mail from "./modules/mail";
Vue.use(VueCookies);
Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		company,
		user,
		jobs,
		sites,
		registration,googledoc,
		authenticate,
		bids,
		uploads,
		notification,
		dashboard,
    vendors,
    customers,
		mail
	},
});

export default store;
