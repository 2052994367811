<template>
  <v-card :height="`${_bid.status != this.config.BID_STATUS.CONFIRM ? '100%' : 'auto'}`" outlined>
    <div v-if="bidInformation">
      <v-row justify="center" class="bidTitleRow">
        <v-card-title class="pl-4 pr-0 pb-0 justify-center bidTitle" style="padding-top: 8px">
          Bid Overview
        </v-card-title>
        <div class="bidInterestBtns">
          <v-btn icon small text v-if="_job.interest === true" color="green" class="mr-2" :loading="_loading"
            title="Remove Thumbs Up" dark @click="toggleInterest(null)">
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
          <v-btn icon small text v-else color="grey" class="mr-2" :loading="_loading" title="Thumbs Up" dark
            @click="toggleInterest(true)">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>

          <v-btn icon small text v-if="_job.interest === false" color="red" class="mr-2" :loading="_loading"
            title="Remove Thumbs Down" dark @click="toggleInterest(null)">
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
          <v-btn icon small text v-else color="grey" class="mr-2" :loading="_loading" title="Thumbs Down" dark
            @click="toggleInterest(false)">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
        </div>
      </v-row>
      <v-row no-gutters class="pb-0 mb-0">
        <v-card-title class="subtitle-1 pb-0 mb-0" style="padding-top: 15px">
          Bid Date
          <v-card-subtitle>
            {{ formatDate(_bid.modified_at) }}
          </v-card-subtitle>
        </v-card-title>
      </v-row>
      <!-- Job has no weight for Case 1a and Case 3a -->
      <div v-if="
        this._job.logistics == 2 &&
        !this._bid.has_pickup_fee &&
        !this._bid.has_purchase_scrap
      ">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-row justify="center" class="my-md-1 justify-space-around">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Vendor Bid All-in
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Min. Purchase Price
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="
                        color: #e53935;
                        font-size: 1.2em;
                        border-bottom: 1px solid black;
                      ">
                        0.00
                      </div>
                      <!-- <div
                        style="
                        align-self: center;
                        font-size: 0.9em;
                        padding-top: 4px;
                      "
                        class="pl-2"
                      >
                        Price/lb
                      </div> -->
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" style="padding-top: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Customer
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Max. Fees
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em; border-bottom: 1px solid black">
                        0.00
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-if="
        !(
          this._job.logistics == 2 &&
          !this._bid.has_pickup_fee &&
          !this._bid.has_purchase_scrap
        )
      ">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-row v-if="shouldShow() === 'CASE_1a' || shouldShow() === 'CASE_3a'" align="center" justify="center"
              class="my-md-1 justify-space-around">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Vendor
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Min. Purchase Bid
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="
                        color: #e53935;
                        font-size: 1.2em;
                        border-bottom: 1px solid black;
                      ">
                        {{
                          `(${bidInformation.bidType
                            ? _bid.bid_per_pound
                            : _bid.bid_all_in_purchase
                          })`
                        }}
                      </div>
                      <div style="
                        align-self: center;
                        font-size: 0.9em;
                        padding-top: 4px;
                      " class="pl-2" v-if="bidInformation.bidType">
                        Price/lb
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" style="padding-top: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Customer
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Max. Fees
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="6" sm="5" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ numberWithCommas(_bid.bid_buy_out) }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Case 1-->
            <v-row v-if="shouldShow() === 'CASE_1'" align="center" justify="center"
              class="my-md-1 justify-space-around">
              <v-col cols="12" style="padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1">Price/lb Bid</v-card-title>
                  </v-col>

                  <v-col cols="6" sm="4" class="pr-1" :class="!bidInformation.hasJobWeight ? 'alignSelfCenter' : null
                    ">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em">
                        {{ _bid.bid_per_pound }}
                      </div>
                      <div style="font-size: 0.9em; padding-top: 4px" class="pl-2">
                        Price/lb
                      </div>
                    </v-layout>

                    <v-layout style="width:144px!important">
                      <div style="font-size: 1.2em">
                        <v-icon x-small class="pl-2">mdi-close</v-icon>
                        {{
                          numberWithCommas(
                            _job.weight ? _job.weight.slice(0, -4) : 1
                          )
                        }}
                      </div>
                      <div style="font-size: 0.9em; padding-top: 4px" class="pl-2">
                        Est. lb
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Vendor
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Min. Purchase Price
                    </v-card-subtitle>
                  </v-col>
                  <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 18px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="
                        color: #e53935;
                        font-size: 1.2em;
                        border-bottom: 1px solid black;
                      ">
                        {{
                          '(' +
                          numberWithCommas(
                            calculate(
                              _bid.bid_per_pound,
                              _job.weight ? _job.weight.slice(0, -4) : 1
                            ).toFixed(2)
                          ) +
                          ')'
                        }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Customer
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Max. Fees
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="6" sm="4" style="align-self: center; padding-bottom: 32px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ numberWithCommas(_bid.bid_buy_out) }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="bidInformation.hasJobWeight" cols="12" style="padding-top: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Net Estimate
                    </v-card-title>
                  </v-col>

                  <v-col cols="6" sm="4" style="align-self: center">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null"
                        style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ calculateNetEstimate() }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Case 3 -->
            <v-row v-if="shouldShow() === 'CASE_3'" align="center" justify="center"
              class="my-md-1 justify-space-around">
              <v-col cols="12" style="padding-bottom: 0" v-if="bidInformation.bidType">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1">Price/lb Bid</v-card-title>
                  </v-col>

                  <v-col cols="5" sm="6" class="pr-1" :class="!bidInformation.hasJobWeight ? 'alignSelfCenter' : null
                    ">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em">
                        {{ _bid.bid_per_pound }}
                      </div>
                      <div style="font-size: 0.9em; padding-top: 4px" class="pl-2">
                        Price/lb
                      </div>
                    </v-layout>

                    <v-layout style="width:144px!important">
                      <div style="font-size: 1.2em; pl-xs-3">
                        <v-icon x-small class="pl-sm-2">mdi-close</v-icon>
                        {{
                          numberWithCommas(
                            _job.weight ? _job.weight.slice(0, -4) : 1
                          )
                        }}
                      </div>
                      <div style="font-size: 0.9em; padding-top: 4px" class="pl-2">
                        Est. lb
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="bidInformation.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Vendor
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.9em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Min. Purchase Price
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 18px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="
                        color: #e53935;
                        font-size: 1.2em;
                        border-bottom: 1px solid black;
                      ">
                        {{
                          numberWithCommas(
                            calculate(
                              _bid.bid_per_pound,
                              _job.weight ? _job.weight.slice(0, -4) : 1
                            ).toFixed(2)
                          )
                        }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="!bidInformation.bidType" cols="12" style="padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Vendor Bid All-in
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Min. Purchase Price
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div :class="bidInformation.scenario !== 4 ? 'textColorRed' : null
                        " style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ calculateVendorBid() }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="bidInformation.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Customer
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Max. Fees
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="5" sm="4" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ numberWithCommas(_bid.bid_buy_out) }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="bidInformation.bidType" cols="12" style="padding-top: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Net Estimate
                    </v-card-title>
                  </v-col>

                  <v-col v-if="bidInformation.bidType" cols="6" style="padding-top: 0" sm="6">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div :class="netEstTextColorRed ? 'textColorRed' : null"
                        style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ calculateNetEstimate() }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="!bidInformation.bidType" cols="12" style="padding-top: 0; padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Customer
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Max. Fees
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="4" sm="4" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ numberWithCommas(_bid.bid_buy_out) }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="!bidInformation.bidType" cols="12" style="padding-top: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Net Estimate
                    </v-card-title>
                  </v-col>

                  <v-col cols="4" sm="4" style="align-self: center">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div :class="netEstTextColorRed ? 'textColorRed' : null"
                        style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ calculateNetEstimate() }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Case 2, Case 4 -->
            <v-row v-if="shouldShow() === 'CASE_2' || shouldShow() === 'CASE_4'" align="center" justify="center"
              class="my-md-1 justify-space-around">
              <!-- <v-col cols="12" class="pb-0">
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <v-card-title class="subtitle-1">
                      Bid Date<v-card-subtitle style="line-height: 0.8em;">
                        {{ formatDate(_bid.modified_at) }}
                      </v-card-subtitle>
                    </v-card-title>
                  </v-col>
                </v-row>
              </v-col> -->
              <v-col cols="12" style="padding-bottom: 0px">
                <v-row no-gutters>
                  <v-col cols="10" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Vendor Bid All-in
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Min. Purchase Price
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 30px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div :class="bidInformation.scenario !== 4 ? 'textColorRed' : null
                        " style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ calculateVendorBid() }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Customer
                    </v-card-title>
                    <v-card-subtitle class="mt-0" style="
                      font-size: 0.8em;
                      padding-top: 0;
                      text-align: left;
                      line-height: 0.9em;
                    ">
                      Max. Fees
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="4" sm="5" style="align-self: center; padding-bottom: 33px">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ numberWithCommas(_bid.bid_buy_out) }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" style="padding-top: 0">
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-card-title class="subtitle-1" style="padding-bottom: 0">
                      Net Estimate
                    </v-card-title>
                  </v-col>

                  <v-col cols="4" sm="5" style="align-self: center">
                    <v-layout>
                      <v-icon>mdi-currency-usd</v-icon>
                      <div v-if="_bid.bid_buy_out" :class="netEstTextColorRed ? 'textColorRed' : null"
                        style="font-size: 1.2em; border-bottom: 1px solid black">
                        {{ calculateNetEstimate() }}
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col cols="6" sm="6" class="pb-0 mb-0">
          <v-card-title class="subtitle-1 pb-0 mb-0">
            Site and Distance
          </v-card-title>
        </v-col>
        <v-col cols="6" sm="6" class="pb-0 mb-0">
          <div style="font-size: 1.2em;">
            {{ _bid.distance || 'No Distance Avail.' }}
          </div>
        </v-col>
      </v-row>

      <v-card-subtitle class="subtitle-2 mt-0 pt-2" style="text-align: left">
        <v-card-text class="no-padding pa-0">
          {{ `${_bid.site_name}, ID ${_bid.site_id}` }}
        </v-card-text>
        <v-card-text class="no-padding pa-0">
          {{
            `${_bid.site_street}, ${_bid.site_city}, ${_bid.site_state} ${_bid.site_zip}`
          }}
        </v-card-text>
      </v-card-subtitle>

      <v-card-title class="subtitle-1 pb-0">Point of Contact</v-card-title>
      <v-card-subtitle class="subtitle-2 mt-0" style="text-align: left">
        {{ _bid.poc_username || _bid.poc_email }}
      </v-card-subtitle>

      <v-card-actions v-if="_bid.status != 'confirm'">
        <v-spacer></v-spacer>
        <v-btn color="error" rounded :disabled="_bid.company_id !== _company.id" @click="deleteBid = true">
          Delete Bid
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn style="width: 110px;" color="primary" rounded :disabled="_bid.company_id !== _company.id"
          @click="editBid()">
          Edit
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-card-actions v-if="_bid.status == 'confirm'">
        <v-spacer v-if="isHideButtonConfirm"></v-spacer>
        <span class="error-title pointer mt-2 pl-2" @click="deleteBid = true">
          DELETE
        </span>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn style="width: 110px;" color="primary" rounded :disabled="_bid.company_id !== _company.id"
          @click="editBid()">
          Edit
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn style="width: 110px;" v-if="_bid.status == 'confirm' && !isHideButtonConfirm" color="secondary" rounded
          :loading="_loading" :disabled="_bid.company_id !== _company.id" @click="confirmBid()">
          Confirm
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="deleteBid" max-width="400">
        <v-card style="background-color: white">
          <v-card-title>Are You Sure?</v-card-title>
          <v-card-text>Deleting this bid will be irreversable.</v-card-text>
          <v-card-actions>
            <v-btn color="primary" rounded @click="close()">CANCEL</v-btn>
            <v-spacer />
            <v-btn rounded color="error" @click="removeBid()">Delete Bid</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else-if="!bidInformation">
      <skeleton-detail />
    </div>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import config from '../../config';
import SkeletonDetail from '@/components/skeleton/Detail.vue';
import BidInformation from '@/mixins/BidInformation';
import * as Helpers from '../../helpers/helpers';
import _ from 'lodash';
export default {
  mixins: [BidInformation],
  components: {
    SkeletonDetail
  },
  data() {
    return {
      config: config,
      accepted: false,
      deleteBid: false,
      bidPOC: undefined,
      awardLoading: false,
      rejectLoading: false,
      netEstTextColorRed: false,
      bidInformation: null,
      isHideButtonConfirm: false
    };
  },
  computed: {
    ...mapGetters({
      _active: 'bids/getVendorBidReview',
      _bid: 'bids/getCurrentBid',
      _bids: 'bids/getBids',
      _bidCompany: 'bids/getCurrentBidCompany',
      _job: 'jobs/getCurrentJob',
      _company: 'company/getCompany',
      _companyType: 'company/getCompanyType',
      _loading: 'bids/getLoading',
      _showVendorReviewBox: 'bids/getVendorBidReviewBox',
      _currentUser: 'user/getUser',
      _bidConfirmInfo: 'bids/bid',
    }),
    active: {
      get() {
        return this._active;
      },
      set(val) {
        this._activateBidReview(val);
      }
    },
    bidConfirmInfo: {
      get() {
        return this._bidConfirmInfo;
      },
      set(newVal) {
        this._setBid(newVal);
      }
    }
  },
  watch: {
    _bid(newValue) {
      if (this._companyType === config.companyType.VENDOR && !this.bidPOC) {
        this.bidPOC = this._user(this._job.poc_id);
      }

      if (this._companyType === config.companyType.VENDOR && this._bid) {
        if (
          !this.bidInformation ||
          this.bidInformation.bidId !== this._bid.id
        ) {
          const information = this.addBidInformation(
            this._bid,
            this._job,
            this._companyType
          );
          this.bidInformation = { bidId: this._bid.id, ...information };
        }
      }
      this.checkStatusHideButtonConfirm();

    },
    _job() {
      if (this._job.status === 'awarded') {
        this.active = false;
      }

      this.checkStatusHideButtonConfirm();
    }
  },

  methods: {
    ...Helpers,
    ...mapMutations({
      _activateBidReview: 'bids/toggleVendorReview',
      _setLoading: 'jobs/toggleLoading',
      _setShowVendorBidReviewBox: 'bids/setVendorBidReviewBox',
      _toggleConfirm: 'bids/toggleConfirm',
      _setBid: 'bids/setBid'
    }),
    ...mapActions({
      _updateBid: 'bids/updateBid',
      _updateJob: 'jobs/updateJob',
      _sendEmailAwardedJobInfo: 'jobs/sendEmailAwardedJobInfo',
      _removeBid: 'bids/deleteBid',
      _user: 'user/getById',
      _upsertJobInterest: 'jobs/upsertJobVendorInterest',
      _setJob: 'jobs/setCurrent',
      _setJob_v2: 'jobs/setCurrent_v2'
    }),
    removeBid() {
      this._removeBid(this._bid.id).then(res => {
        this.close();
        setTimeout(() => {
          window.location.reload();
          //this._setShowVendorBidReviewBox(false);
        }, 200);
      });
      // if (this._bids.length <= 1) {
      //   this._updateJob({
      //     id: this._bid.job_id,
      //     status: 'open',
      //     bid_status: 'none'
      //   }).then(() => {
      //     this._setShowVendorBidReviewBox(false);
      //     window.location.reload();
      //   });
      // }
      this.close();
    },
    close() {
      this.deleteBid = false;
      this.active = false;
    },
    handleReject() {
      // this._setLoading(true);
      this.rejectLoading = true;
      var options = {
        id: this._bid.id,
        status: 'rejected'
      };
      this._updateBid(options).then(() => {
        this.active = false;
        this.rejectLoading = false;
      });
    },
    async handleAward() {
      this.awardLoading = true;
      var options = {
        id: this._bid.id,
        status: 'awarded',
        vendor_attachments: this._bid.attachments || []
      };
      this._updateBid(options).then(() => {
        for (var i = 0; i < this._bids.length; i++) {
          if (this._bids[i].bid_id !== this._bid.id) {
            options = {
              id: this._bids[i].bid_id,
              status: 'rejected'
            };
            this._updateBid(options);
          }
        }
        this._updateJob({
          id: this._bid.job_id,
          status: 'awarded',
          bid_status: 'awarded'
        }).then(() => {
          this._sendEmailAwardedJobInfo({
            id: this._bid.job_id,
            vendor_id: this._bid.company_id
          });
          this.awardLoading = false;
        });
      });
    },
    // Vu added 9/18/21
    numberWithCommas(x) {
      if (x == null) {
        return '0';
      }

      // return x ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "";
      return x
        ? Number(x.toString().replaceAll(',', '')).toLocaleString('en-US', {
          minimumFractionDigits: 2
        })
        : '';
    },
    // formatDate(date) {
    //   return moment(date).format('MM/DD/YYYY');
    // },
    calculate(str1, str2) {
      const rs = Number(str1.replace(/,/g, '')) * Number(str2 || 1);
      return rs;
    },
    calculateVendorBid() {
      if (!this._bid) {
        return;
      }

      let number = this.bidInformation.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;

      if (number == null) {
        return '0';
      }

      // return this.numberWithCommas(number);
      return this.bidInformation.scenario !== 4
        ? '(' + this.numberWithCommas(number) + ')'
        : this.numberWithCommas(number);
    },
    calculateNetEstimate() {
      //debugger;
      if (!this._bid || !this._job) {
        return;
      }

      // init
      // this.netEstTextColorRed = false;
      let numberAsString = this.bidInformation.bidType
        ? this._bid.bid_per_pound
        : this._bid.bid_all_in_purchase;

      let number = numberAsString
        ? parseFloat(numberAsString.replace(/,/g, ''))
        : 0;

      // const bidBuyOut = parseFloat(this._bid.bid_buy_out.replace(/,/g, ""));
      const bidBuyOut = this._bid.bid_buy_out
        ? parseFloat(this._bid.bid_buy_out.replace(/,/g, ''))
        : 0;

      const unit = this._job.weight
        ? parseFloat(this._job.weight.slice(0, -4))
        : 1;

      if (this.bidInformation.scenario === 1) {
        number = number * unit;
      }

      if (this.bidInformation.scenario === 3 && this.bidInformation.bidType) {
        number = number * unit;
      }

      const net = bidBuyOut - number;
      if (net < 0 && this.bidInformation.scenario !== 4) {
        this.netEstTextColorRed = true;
        return '(' + this.numberWithCommas(Math.abs(net).toFixed(2)) + ')';
      }
      /*
      if (net < 0 && this.bidInformation.scenario !== 4) {
        this.netEstTextColorRed = false;
        return this.numberWithCommas(Math.abs(net).toFixed(2));
      }*/

      this.netEstTextColorRed = false;
      return this.numberWithCommas(net.toFixed(2));
      // this.netEstTextColorRed = true;
      // return "(" + this.numberWithCommas(net.toFixed(2)) + ")";
    },
    // check case to show BID REVIEW
    shouldShow() {
      if (!this._bid || !this.bidInformation) {
        return '';
      }

      if (this.bidInformation.scenario === 1) {
        if (!this.bidInformation.hasJobWeight) {
          return 'CASE_1a';
        }
        return 'CASE_1';
      }

      if (this.bidInformation.scenario === 2) {
        return 'CASE_2';
      }

      if (this.bidInformation.scenario === 3) {
        if (this.bidInformation.bidType && !this.bidInformation.hasJobWeight) {
          return 'CASE_3a';
        }

        return 'CASE_3';
      }

      if (this.bidInformation.scenario === 4) {
        return 'CASE_4';
      }
    },
    async toggleInterest(v) {
      this._upsertJobInterest({
        company_id: this._company.id,
        job_id: this._job.job_id,
        user_id: this._currentUser.id,
        interest: v
      }).then(async () => {
        let resData = {
          payload: this.$route.params.id,
          disableLoading: true
        };
        await this._setJob_v2(resData);
      });
    },
    editBid() {
      this.$emit('show-vendor-review-box')
    },
    confirmBid() { // confirm not show popup review
      //handle data for confirm bid
      this._loading = true;
      if (this._bid) {
        var bidInfo = {
          ...this._bid,
          isEditBid: true,
          onlyClickConfirm: true,
          status: 'open',
          modified_at: new Date()
        }

        let pickUpCharge = bidInfo.has_pickup_fee;
        let bidType = _.isNil(bidInfo.bid_all_in_purchase) && !_.isNil(bidInfo.bid_per_pound) ? true : false;

        bidInfo.hasJobWeight = this._job.weight ? true : false;
        bidInfo.bidType = bidType;
        if (
          bidInfo.has_purchase_scrap &&
          bidType &&
          bidInfo.bid_per_pound > 0 &&
          pickUpCharge &&
          bidInfo.bid_buy_out > 0
        ) {
          bidInfo.scenario = 1;
          delete bidInfo.bid_all_in_purchase;
        }

        if (
          bidInfo.has_purchase_scrap &&
          !bidType &&
          bidInfo.bid_all_in_purchase > 0 &&
          pickUpCharge &&
          bidInfo.bid_buy_out > 0
        ) {
          bidInfo.scenario = 2;
          delete bidInfo.bidPerPound;
        }

        if (bidInfo.has_purchase_scrap && !pickUpCharge) {
          bidInfo.scenario = 3;
          bidInfo.bid_buy_out = '0';
        }

        if (!bidInfo.has_purchase_scrap && pickUpCharge) {
          bidInfo.scenario = 4;
          bidInfo.bid_all_in_purchase = '0';
          bidInfo.bid_per_pound = '0';
        }

        this._setBid(bidInfo);
        let data = bidInfo;
        //handle remove data
        if (data.has_purchase_scrap) {
          if (data.bidType) {
            data.bid_all_in_purchase = null;
          }
          else {
            data.bid_per_pound = null;
          }
        }

        if (data.selectedSite) {
          delete data.selectedSite;
        }

        if (data.selectedUser) {
          delete data.selectedUser;
        }

        if (data.scenario === 2) {
          //delete data.bid_per_pound;
          data.bid_per_pound = null;
        }

        if (data.scenario === 3) {
          data.bid_buy_out = null;

          if (data.bidType) {
            //delete data.bid_all_in_purchase;
            data.bid_all_in_purchase = null;
          } else {
            //delete data.bid_per_pound;
            data.bid_per_pound = null;
          }
        }

        if (data.scenario === 4) {
          // delete data.bid_all_in_purchase;
          // delete data.bid_per_pound;

          data.bid_all_in_purchase = null;
          data.bid_per_pound = null;

        }

        if (data.scenario) {
          delete data.scenario;
        }

        delete data.hasJobWeight;
        delete data.bidType;

        if (data.bid_buy_out !== null) {
          data.current_bid_buy_out = data.bid_buy_out;
        }

        //handle case Drop-Off with two no choice
        if (
          this._job.logistics == 2 &&
          !this._bid.has_pickup_fee &&
          !this._bid.has_purchase_scrap
        ) {
          data.bid_all_in_purchase = null;
          data.bid_per_pound = null;
          data.bid_buy_out = null;
        }
        //end remove data 

        this._updateBid(data).then(res => {
          setTimeout(() => {
            this.$router.go();
            this._loading = false;
          }, 1000);
        })
      }
      //this._toggleConfirm(true);
    },
    checkStatusHideButtonConfirm() {
      if (this._job && this._bid && this._bid.job_id == this._job.job_id && this._bid.company_id == this._company.id && this._job.logistics != 2) {
        let biddingToPurchase = this._bid?.has_purchase_scrap;
        let pickUpCharge = this._bid?.has_pickup_fee;

        if (!biddingToPurchase && !pickUpCharge) {
          this.isHideButtonConfirm = true;
        }
      }
      else {
        this.isHideButtonConfirm = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.flex-container {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  width: 80%;
}

.location {
  grid-column: 1;
  grid-row: 1;
}

.number {
  grid-column: 2;
  grid-row: 1;
}

.duration {
  grid-column: 3;
  grid-row: 1;
}

.weight {
  grid-column: 1;
  grid-row: 2;
}

.pallets {
  grid-column: 1;
  grid-row: 3;
}

.recycling {
  grid-column: 2;
  grid-row: 2 / span 2;
}

.attachments {
  grid-column: 3;
  grid-row: 2 / span 2;
}

.access {
  grid-column: 1;
  grid-row: 4;
}

.lift {
  grid-column: 3;
  grid-row: 4;
}

.title-font {
  font-family: Roboto;
  font-size: 17px;
  color: #2e2e2e;
}

.subtitle-font {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #5d5d5d;
}

.vendor {
  grid-column: 1;
}

.address {
  grid-column: 2;
}

.phone {
  grid-column: 3;
}

.email {
  grid-column: 4;
}

.company-name {
  font-family: Roboto;
  font-size: 23px;
  color: #2e2e2e;
}

.center {
  align-self: center;
}

.text-center {
  text-align: center;
}

.link-color {
  color: #0091ff;
}

a {
  text-decoration: none;
}

.textColorRed {
  color: #e53935;
}

.alignSelfCenter {
  align-self: center;
}

.subtitle-1 {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
}

.deleteConfirm {
  color: red;
}

.bidTitle {
  text-align: center !important;
  max-width: 275px;
  margin: 0;
  font-size: 26px;
  position: absolute;
  left: 50%;
  font-weight: 500;
  transform: translateX(-50%);
}

.bidTitleRow {
  padding: 40px 0 15px;
  position: relative;
}

.bidInterestBtns {
  margin-left: auto;
  padding-right: 20px;
}
</style>
