<template>
  <div v-if="_loading">
    <skeleton-detail />
  </div>
  <v-card v-else class="pa-4 customer-detail" flat>
    <div>
      <div>
        <div class="customer-detail-section customer-detail-section-1">
          <v-row>
            <v-col md="7" class="vendor-company-information">
              <div class="d-flex">
                <v-icon
                  @click="$router.push('/customer')"
                  class="customer-detail-back"
                  >mdi-chevron-left</v-icon
                >
                <v-card-title
                  v-html="`COMPANY INFORMATION`"
                  class="save--text pt-3 pb-2"
                ></v-card-title>
              </div>
              <div
                v-if="_customer"
                class="vendor-company-information-content h-100 pb-5"
              >
                <v-row>
                  <v-col cols="12" md="5" lg="5" xl="5" class="py-0">
                    <img
                      :src="
                        _customer.icon_url || require('@/assets/Bitmap.jpg')
                      "
                      class="w-100 pt-4 px-2"
                    />
                    <div class="pb-3 pt-4 px-2">
                      <label class="m-0">Address</label>
                      <span class="d-block"
                        ><div>{{ _customer.street }}{{_customer.suite?', Suite '+_customer.suite:''}}</div>
                        <div>
                          {{ _customer.city }}, {{ _customer.state }}
                          {{ _customer.zip }}
                        </div></span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" md="7" lg="7" xl="7">
                    <div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Company Name</label>
                        <span class="d-block">{{ _customer.name }}</span>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Customer Since</label>
                        <span class="d-block">{{
                          formatDate(_customer.updated_at)
                        }}</span>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Company Email</label>
                        <a
                          :href="'mailto:' + _customer.email"
                          class="m-0 d-block"
                          >{{ _customer.email }}</a
                        >
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Company Phone</label>
                        <span class="d-block">{{ _customer.phone }}</span>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Billing Address</label>
                        <div class="d-block">
                         <div v-if="_customer.billing_site_id && _customer.s_street">{{ _customer.s_street }}{{_customer.s_suite?', Suite '+_customer.s_suite:''}}</div>
                          <div v-if="_customer.billing_site_id && _customer.s_city">
                                    {{ _customer.s_city }}, {{ _customer.s_state }}
                                    {{ _customer.s_zip }}
                          </div>
                          <div  v-if="!_customer.billing_site_id||!_customer.s_street">Not Available</div>
                        </div>
                      </div>
                      <div class="pb-3 px-2">
                        <label class="m-0">Billing Contact</label>
                        <div class="d-block">
                         <div>{{_customer.first_name}} {{_customer.last_name}}</div>
                         <div>{{_customer.user_email}}</div><div>{{_customer.user_phone}}</div>
                        <div  v-if="!_customer.billing_team_id||!_customer.user_email">Not Available</div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col md="5">
              <v-row v-if="_compType === 3" class="pt-2 pb-2">
                <v-col
                  cols="12"
                  md="4"
                  class="customer-detail-title-text-admin"
                >
                  Admin Actions
                </v-col>
                <v-col cols="12" md="7" lg="7" xl="7" class="pt-1 pb-2">
                  <div id="divSelectStatus" class="d-flex md-justify-content-center" style="width: 230px;margin-top: 3px;">
                   <v-select   id="selectStatus" v-on:change="openCompanyRejectDialog()" v-model="selectedStatus" :items="_customer.status=='Archive'?status_items2:(_customer.status=='Delete'?['Delete']:status_items)" style="border-radius: 30px; width:100px; border: 1px solid; height:33px">
                     <template v-slot:selection="{ item }">
                       <span id="SelectedStatusActive"
                      v-bind:style= "[item=='Active' ?  {'color':'#4caf50'} : (item=='On Hold' ? {'color':'#ED8300'} : (item=='New' ? {'color':'#554DAD'} : (item=='Archive' ? {'color':'#0091ff'} : {'color':'red'})))]"
                      >{{item}}</span>
                   </template>
                    <template  #item="{item}">
                      <span 
                      v-bind:style= "[item=='Active' ?  {'color':'#4caf50'} : (item=='On Hold' ? {'color':'#ED8300'} : (item=='New' ? {'color':'#554DAD'} : (item=='Archive' ? {'color':'#0091ff'} : {'color':'red'})))]"
                      >{{item}}</span>
                    </template>
                    </v-select>
                    <!--v-btn class="btn-company-info-archive" @click="openCompanyRejectDialog()">ARCHIVE</v-btn>
                    <v-btn class="btn-company-info-archive">ARCHIVE</v-btn>
                    <v-btn class="btn-company-info-restore">RESTORE</v-btn-->
                    <v-btn :disabled="_customer.status=='Delete'"
                      @click="editing = true"
                      class="ml-3 btn-company-info-restore"
                      >EDIT FEES</v-btn
                    >
                   
 
                  </div>
                
                </v-col> 
                  <v-col cols="12" md="12" lg="12" xl="12" class="pt-1 pb-2" v-if="_customer.status_note"> 
                  <div style="line-height: 25px;"><b>Status Change Reason (s):</b>
                  <br><span style="color:#5d5d5d" v-html="_customer.status_note"> </span></div> 
                   </v-col>
              </v-row>

              <div
                v-if="_compType === 3 && editingCustomerFee"
                class="customer-fee simple-table"
              >
                <v-card>
                  <v-container fluid>
                    <label>Transaction Fees</label>
                    <v-row no-gutters style="width: 90%; align-items: center">
                      <v-col cols="6" md="5">
                        <v-card-subtitle class="subtitle-4"
                          >Scrap Fee</v-card-subtitle
                        >
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-card-subtitle class="subtitle-2">
                          {{ _customerFee.scrap_purchase_percentage }}% / $
                          {{ _customerFee.scrap_per_pound }}</v-card-subtitle
                        >
                      </v-col>

                      <v-spacer />

                      <v-col cols="6" md="5">
                        <v-card-subtitle class="subtitle-4"
                          >Equipment Fee</v-card-subtitle
                        >
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-card-subtitle class="subtitle-2">
                          {{
                            _customerFee.equipment_purchase_percentage
                          }}%</v-card-subtitle
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>

              <div v-if="_compType !== 3" style="height: 52px"></div>
              <v-simple-table class="simple-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Day</th>
                      <th class="text-left">Open</th>
                      <th class="text-left">Close</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in _customer.hours_of_op"
                      :key="index"
                    >
                      <td>{{ item.day }}</td>
                      <td>{{ formTime(item.open) }}</td>
                      <td>{{ formTime(item.close) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>

        <div class="customer-detail-section customer-detail-section-3">
          <v-card-title
            v-html="`JOB LIST`"
            class="save--text px-0"
          ></v-card-title>
          <div class="job-list">
            <v-spacer />
            <div class="justify-end">
              <v-text-field
                v-model="search"
                style="max-width: 300px; margin: 16px"
                label="Search"
                append-icon="mdi-magnify"
              />
            </div>
            <v-data-table
              :headers="jobHeaders"
              :items="_customer.jobs"
              :search="search"
              hide-default-footer
              class="table-no-border"
              @click:row="handleClick"
            >
              <template v-slot:item.status="{ item }">
                {{ item.status | capitalize }}
              </template>
              <template v-slot:item.location="{ item }">
                {{ item.site.city }}, {{ item.site.state }}
              </template>
            </v-data-table>
          </div>
        </div>

        <div class="customer-detail-section customer-detail-section-4">
          <v-row>
            <v-col
              cols="12"
              md="7"
              lg="7"
              xl="7"
              class="pt-0 pb-0 customer-detail-left"
            >
              <v-card-title
                v-html="`TEAM INFORMATION`"
                class="save--text px-0"
              ></v-card-title>
              <v-data-table
                :headers="teamInfoHeaders"
                :items="_getUsers"
                :loading="_loading_user"
                hide-default-footer
                class="table-no-border"
                @click:row="handleClickTeam"
              >
                <template v-slot:item.user_type="{ item }">
                  {{ getUserTypeText(item.user_type) }}
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="5"
              lg="5"
              xl="5"
              class="pt-0 pb-0 customer-detail-right"
            >
              <v-card-title
                v-html="`SITE LIST`"
                class="save--text px-0"
              ></v-card-title>
              <v-data-table
                :headers="siteHeaders"
                :items="_getSites"
                :loading="_loading_site"
                hide-default-footer
                class="table-no-border"
                @click:row="handleClickSite"
              ></v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <admin-job-detail />
    <create-site :edit="editSite" />
    <create-user @close="closeCreate()" />

    <v-dialog
      v-model="editing"
      @click:outside="editing = false"
      max-width="400"
    >
      <v-card>
        <div class="btn-dismiss-dialog">
          <v-btn text @click="editing = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title>Edit Customer Fee</v-card-title>
        <v-card-text>
          <v-form v-model="editFormValid">
            <v-row
              no-gutters
              style="width: 80%; align-items: center"
              justify="center"
            >
              <v-col cols="6">
                <v-card-subtitle>Scrap Fee</v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  type="money"
                  v-money.lazy="editingCustomerFee.scrap_purchase_percentage"
                  v-money="editingCustomerFee.scrap_purchase_percentage"
                  v-model="editingCustomerFee.scrap_purchase_percentage"
                  :disabled="!editing"
                  :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber,
                  ]"
                  append-outer-icon="%"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-card-subtitle>Scrap Per Pound</v-card-subtitle>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  :disabled="!editing"
                  type="money"
                  append-outer-icon="mdi-currency-usd"
                  v-model="editingCustomerFee.scrap_per_pound"
                  v-model.lazy="editingCustomerFee.scrap_per_pound"
                  v-money="editingCustomerFee.scrap_per_pound"
                  :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber,
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-card-subtitle>Equipment Fee</v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  type="money"
                  v-money.lazy="
                    editingCustomerFee.equipment_purchase_percentage
                  "
                  v-money="editingCustomerFee.equipment_purchase_percentage"
                  v-model="editingCustomerFee.equipment_purchase_percentage"
                  :disabled="!editing"
                  append-outer-icon="%"
                  :rules="[
                    rules.required,
                    rules.minFee,
                    rules.maxFee,
                    rules.negativeNumber,
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-space-between">
                  <v-btn
                    color="error"
                    rounded
                    @click="cancelEditCustomerFee"
                    class="ml-2 btn-company-info-archive"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    rounded
                    :disabled="!editFormValid"
                    :loading="feeLoading"
                    @click="updateCustomerFee"
                    class="ml-2 btn-company-info-restore"
                    >Save</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog> <company-reject-dialog />
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import SkeletonDetail from "@/components/skeleton/Detail.vue";
import AdminJobDetail from "@/components/jobs/AdminJobDetail";
import CreateSite from "@/components/site/Create.vue";
import CreateUser from "@/components/team/CreateUser.vue";
import CompanyRejectDialog from "@/components/dashboard/admin/CompanyRejectDialog.vue";
export default {
  components: {
    SkeletonDetail,
    AdminJobDetail,
    CreateSite,
    CreateUser,
    CompanyRejectDialog
  },
  data() {
    return {
      status_items: ["Active", "On Hold", "Archive"],
      status_items2: ["Active", "On Hold", "Archive", "Delete"],
      editFormValid: false,
      feeLoading: false,
      editing: false,
      customerFee: null,
      editingCustomerFee: null,
      selectedStatus: null,
      jobHeaders: [
        {
          text: "ID#",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Job Name", value: "name" },
        { text: "Date Open", value: "start_date_text" },
        { text: "Date Closed", value: "end_date_text" },
        { text: "Status", value: "status" },
        { text: "Location", value: "location" },
      ],
      teamInfoHeaders: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "User Type", value: "user_type" },
      ],
      siteHeaders: [
        { text: "Name", value: "name" },
        { text: "Location", value: "location" },
      ],
      search: undefined,
      editSite: false,
      edit: false,
      dataJobs: [],

      rules: {
        required: (v) => !!v || "Required.",
        minFee: (v) => {
          return (
            parseFloat(v ? (v + "").replace(/,/g, "") : 0.1) >= 0.0 ||
            "Invalid Fee."
          );
        },
        maxFee: (v) =>
          parseFloat(v ? v.replace(/,/g, "") : 0.1) <= 100.0 || "Invalid Fee.",

        negativeNumber: (v) => !new RegExp(/-/g).test(v) || "Invalid Fee",
      },
    };
  },
  computed: {
    ...mapGetters({
      _customer: "customers/getCustomer",
      _customerFee: "customers/customerFee",
      _getUsers: "user/getUsersCompany",
      _compType: "company/getCompanyType",
      _getSites: "sites/getSites",
      _loading: "customers/getLoading",
      _customerFeeLoading: "customers/customerFeeLoading",
      _loading_user: "user/loading",
      _loading_site: "sites/loading",
      _company: "company/getCompany",
    }),
  },
  async mounted() {
    this._getCustomer(this.$route.params.id).then(
      (rs) =>  {console.log("current _customer", this._customer);
    this.selectedStatus=this._customer.status;}
    );
    this._getCustomerFee(this.$route.params.id).then(
      (rs) => (this.editingCustomerFee = { ...this._customerFee })
    );

    let payload = {
      itemsPerPage: 99999,
      page: 1,
      sortBy: ["user_id"],
      sortDesc: [true],
      company_id: this.$route.params.id,
    };
    this._setUsersCompany(payload);
    let option_site = {
      itemsPerPage: 99999,
      page: 1,
      company_id: this.$route.params.id,
    };
    this._setResults(option_site);
   
  },
  methods: {
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY hh:mm A");
    },
    formTime(time) {
      return moment(time, "hh:mm").format("hh:mm A");
    },
    handleClick(value) {
      if (this._compType === 3) {
        this._setJob(value.id);
        this._setAdminJobDetails(true);
      } else {
        this.$router.push("/job/" + value.id);
      }
    },
    handleClickSite(value) {
      this._toggleEdit({ id: value.site_id, toggle: true });
    },
    handleClickTeam(value) {
      if (this._compType === 3) {
        this._toggleEditing({ toggle: true, user: value });
      }
    },
    closeCreate() {
      this.edit = false;
    },
    getUserTypeText(type) {
      return type === 1 ? "Admin" : "Standard";
    },
    ...mapActions({
      _getCustomer: "customers/getCustomer",
      _getCustomerFee: "customers/getCustomerFee",
      _setUsersCompany: "user/getAllCompanyId",
      _setResults: "sites/getResults",
      _setJob: "jobs/setCurrent",
      _toggleEdit: "sites/toggleEdit",
      _toggleEditing: "user/toggleEditing",
      _updateCustomerFee: "company/updateCustomerFee",
      _updateCompany: "company/updateCompany",
    }),
    ...mapMutations({
      _setLoading: "jobs/toggleLoading",
      _setAdminJobDetails: "jobs/setAdminJobDetails",
      _setShowCompanyRejectDialog: "company/setShowCompanyRejectDialog",
      _setCompanyStatusChange:"company/setCompanyStatusChange",
    }),
    openCompanyRejectDialog() {
       console.log("_setShowCompanyRejectDialog(true)");
       this._setCompanyStatusChange(this.selectedStatus);
       this._setShowCompanyRejectDialog(true);
     },
   
    async updateCustomerFee() {
      try {
        this.feeLoading = true;
        await this._updateCustomerFee({
          id: this.$route.params.id,
          ...this.editingCustomerFee,
        });

        await this._getCustomerFee(this.$route.params.id);

        this.editingCustomerFee = { ...this._customerFee };

        this.feeLoading = false;
        this.editing = false;
      } catch (error) {
        this.feeLoading = false;
        console.log(error);
      }
    },

    cancelEditCustomerFee() {
      this.editing = false;
      this.editingCustomerFee = { ...this._customerFee };
    },
  },
};
</script>

<style>
 #SelectedStatusActive{
 margin-top: 20px;
margin-left: 10px;
}
 div#divSelectStatus.d-flex.md-justify-content-center div.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select div.v-input__control div.v-input__slot::before
 {
  width:0px
 }
 div#divSelectStatus.d-flex.md-justify-content-center div.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select div.v-input__control div.v-input__slot div.v-select__slot div.v-select__selections
{
  display:block;margin-top: -5px;
}
 div#divSelectStatus.d-flex.md-justify-content-center div.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select div.v-input__control div.v-input__slot div.v-select__slot div.v-input__append-inner
 {
  margin-top: -6px;
}
 div#divSelectStatus.d-flex.md-justify-content-center div.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.theme--light.v-text-field.v-text-field--is-booted.v-select.primary--text div.v-input__control div.v-input__slot div.v-select__slot div.v-input__append-inner
{
  margin-top: -6px;
}
.customer-detail .w-100 {
  width: 100% !important;
}
.customer-detail-section {
  margin-bottom: 3rem;
}
.customer-detail-title-text-admin {
  font-size: 1.5rem;
}

.customer-detail .pt-0 {
  padding-top: 0 !important;
}
.customer-detail .pb-0 {
  padding-bottom: 0 !important;
}
.simple-table,
.vendor-company-information-content,
.customer-detail-section-4 .v-data-table {
  border: 1px solid #acacac;
  border-radius: 4px;
}

.customer-fee {
  border: 1px solid #acacac;
  margin-bottom: 10px;
}

.vendor-company-information-content {
  height: calc(100% - 52px);
}
.customer-detail .v-data-table.table-no-border td,
.customer-detail .table-no-border th,
.customer-detail .table-no-border .v-data-footer {
  border: 0 !important;
}
.vendor-company-information label {
  font-weight: bold;
}

.customer-fee label {
  font-weight: bold;
}

.customer-detail .btn-company-info-archive,
.customer-detail .btn-company-info-restore {
  padding: 5px 20px;
  box-shadow: none;
}
.customer-detail .btn-company-info-archive {
  border-radius: 30px;
  background: #e53935 !important;
  color: #fff;
  margin-right: 20px;
}
.customer-detail .btn-company-info-restore {
  border-radius: 30px;
  background: rgb(0, 162, 8) !important;
  color: #fff;
}
.customer-detail .m-0 {
  margin: 0;
}
.customer-detail .justify-content-center {
  justify-content: center;
}
.customer-detail .align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.job-list {
  border-radius: 4px;
  border: 1px solid #acacac;
}
.customer-detail-documents table td {
  border-bottom: 0 !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.vendor-circle-black span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #000000;
  margin-right: 8px;
}
.customer-detail .v-card__title {
  font-size: 26px;
}
.customer-detail .customer-detail-back {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .customer-detail-right {
    padding-left: 30px;
  }
  .customer-detail-left {
    padding-right: 30px;
  }
  .customer-detail-abilities-tr {
    width: 150px;
  }
  .customer-detail-abilities-yes-no {
    display: flex;
    justify-content: space-between;
  }
  .customer-detail-section-4 .v-data-table {
    height: calc(100% - 64px);
  }
  .customer-detail-section-2 .v-data-table {
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .md-justify-content-center {
    justify-content: center;
  }
  .customer-detail-abilities-yes-no label {
    margin-bottom: 5px;
  }
}
</style>
