<template>
  <v-card outlined>
    <v-stepper v-model="currentStep" class="pb-4">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep == 1" step="1">
          Step 1: Required Fields
          <v-icon :color="this.currentStep == 1 ? 'success' : ''">
            mdi-chevron-right
          </v-icon>
        </v-stepper-step>
        <v-stepper-step :complete="currentStep == 2" step="2">
          Step 2: Optional - Services
          <v-icon :color="this.currentStep == 2 ? 'success' : ''">
            mdi-chevron-right
          </v-icon>
        </v-stepper-step>
        <v-stepper-step :complete="currentStep == 3" step="3">
          Step 3: Optional - Users and Locations
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <vendor-onboarding-step1 ref="step1" @nextStep="handleNextStep" @updateCountCompletedStep1="updateCountCompletedStep1" />
          <div class="button-container pointer">
            <a
              href="/profile"
              class="pointer underline primary--text d-flex align-end btn-save-and-exit"
              v-if="countCompletedStep1 <= 0 && this.$route.query.new"
            >
              Skip for Now
            </a>
            <span
              @click="saveAndExitStep(1)"
              class="pointer underline primary--text d-flex align-end btn-save-and-exit"
              v-if="countCompletedStep1 > 0 || !this.$route.query.new"
            >
              Save and Exit  
            </span>
            <v-btn
              :loading="buttonLoading"
              color="secondary pointer"
              @click="handleSaveAndContinueStep1"
              :disabled="countCompletedStep1 <= 0"
            >
              Save and Continue
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <vendor-onboarding-step2 ref="step2" @nextStep="handleNextStep" />
          <div class="button-container pointer">
            <span href="" @click="saveAndExitStep(2)" class="pointer underline primary--text d-flex align-end btn-save-and-exit">
              Save and Exit
            </span>
            <div class="right-buttons pointer">
              <v-btn outlined color="primary" @click="backToPreviousStep">
                <v-icon left>mdi-arrow-left</v-icon>
                Back
              </v-btn>
              <v-btn class="pointer" :loading="buttonLoading" color="secondary" @click="handleSaveAndContinueStep2">
                Save and Continue
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <vendor-onboarding-step3 ref="step3" @nextStep="handleNextStep" />
          <div class="button-container pointer">
            <span href="" @click="saveAndExitStep(3)" class="pointer underline primary--text d-flex align-end btn-save-and-exit">
              Save and Exit
            </span>
            <div class="right-buttons pointer">
              <v-btn outlined color="primary" @click="backToPreviousStep">
                <v-icon left>mdi-arrow-left</v-icon>
                Back
              </v-btn>
              <v-btn class="pointer" :loading="buttonLoading" color="secondary" @click="handleSaveAndContinueStep3">
                Finish and View Jobs
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import upload from '../../mixins/Upload';
import eula from '@/components/app/TCView.vue';
import VendorOnboardingStep1 from '@/components/registration/VendorOnboardingStep1.vue';
import VendorOnboardingStep2 from '@/components/registration/VendorOnboardingStep2.vue';
import VendorOnboardingStep3 from '@/components/registration/VendorOnboardingStep3.vue';
import config from '@/config';
export default {
  directives: {
    mask
  },
  components: {
    //eula
    VendorOnboardingStep1,
    VendorOnboardingStep2,
    VendorOnboardingStep3
  },
  mixins: [upload],
  data() {
    return {
      config: config,
      currentStep: 1,
      countCompletedStep1: 0,
      buttonLoading: false,
      isDisableAllForm: true
    };
  },
  async mounted() {
    await this._auToSetUserAndCompanyInfo();
    if (this._user.type_id != this.config.USER_TYPE.admin) {
      setTimeout(() => {
        this.$router.push('/profile');
      }, 1000);
    }
  },
  computed: {
    ...mapGetters({
      _user: 'user/getUser'
    })
  },
  methods: {
    ...mapActions({
      _auToSetUserAndCompanyInfo: 'authenticate/auToSetUserAndCompanyInfo'
    }),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    },
    handleNextStep() {
      this.currentStep++;
      this.scrollToTop();
    },
    backToPreviousStep() {
      this.currentStep--;
      this.scrollToTop();
    },
    handleSubmit() {
      this.submitVendorOnboarding();
    },
    async handleSaveAndContinueStep1() {
      this.buttonLoading = true;
      let res = await this.$refs.step1.updateCompanyInfo();
      this.buttonLoading = false;
      setTimeout(() => {
        this.handleNextStep();
      }, 500);
    },
    updateCountCompletedStep1(count) {
      this.countCompletedStep1 = count;
    },
    async handleSaveAndContinueStep2() {
      this.buttonLoading = true;
      let res = await this.$refs.step2.updateCompanyInfo();
      this.buttonLoading = false;
      setTimeout(() => {
        this.handleNextStep();
      }, 500);
    },
    async saveAndExitStep(step) {
      switch (step) {
        case 1:
          await this.$refs.step1.updateCompanyInfo();
          break;
        case 2:
          await this.$refs.step2.updateCompanyInfo();
          break;
        case 3:
          await this.$refs.step3.updateCompanyInfo();
          break;
      }

      setTimeout(() => {
        this.$router.push('/profile');
      }, 500);
    },
    async handleSaveAndContinueStep3() {
      this.buttonLoading = true;
      let res = await this.$refs.step3.updateCompanyInfo();
      this.buttonLoading = false;
      setTimeout(() => {
        this.$router.push('/job');
      }, 500);
    }
  }
};
</script>
<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.underline {
  text-decoration: underline;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Aligns 'Save and Exit' to the left and the group to the right */
  align-items: center; /* Vertically centers the buttons */

  padding-top: 20px;
}

.button-link {
  background: none;
  border: none;
  color: blue; /* Sets the primary color */
  text-decoration: underline;
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 16px; /* Sets font size */
}

.right-buttons {
  display: flex; /* Ensures buttons are next to each other */
  gap: 30px; /* Adds space between 'Save and Continue' and 'Back' */
}

.btn-save-and-exit {
  /* font-size: 1.2rem; */
  font-size: 17px;
  padding-left: 20px;
}

.v-stepper >>> .v-stepper__header {
  box-shadow: none;
}

.v-stepper >>> .v-stepper__label {
  font-weight: bold;
}

.v-stepper__step--active >>> .v-stepper__label {
  color: #4caf50 !important;
}

.v-stepper__step--active {
  background: #f2fff2;
}

@media only screen and (min-width: 960px) {
  .v-stepper >>> .v-stepper__step__step {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .v-stepper >>> .v-stepper__step__step {
    background-color: #4caf50 !important;
  }

  .button-container {
    flex-direction: column;
    align-items: stretch;
  }

  .right-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .right-buttons v-btn {
    width: 100%;
  }

  .btn-save-and-exit {
    justify-content: center;
    padding-bottom: 10px;
  }
}
</style>
