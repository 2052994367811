<template>
  <div>
    <component :is="currentResitration + `-registration`"></component>
  </div>
</template>
<script>
import BasicRegistration from '@/components/registration/basic/Base.vue';
import VendorRegistration from '@/components/registration/Vendor.vue';
import UserRegistration from '@/components/registration/User.vue';
import VendorOnboardingRegistration from '@/components/registration/VendorOnboarding.vue';
export default {
  components: {
    BasicRegistration,
    VendorRegistration,
    UserRegistration,
    VendorOnboardingRegistration
  },
  data() {
    return {};
  },
  computed: {
    currentResitration() {
      return this.$route.params.type;
    }
  }
};
</script>
