import { getAll, get, update, create, remove } from '../../api';
import router from '@/router';

// initial job state
const state = {
  bids: undefined,
  newBid: undefined,
  currentBid: undefined,
  currentBidInfo: undefined,
  currentBidCompany: {},
  loading: false,
  error: undefined,
  adminBidReview: false,
  vendorBidReview: false,
  vendorBidReviewBox: null,
  customerBidReview: false,
  confirm: false,
  bid: {},
  showJobBidNoLongerOpenDialog: false //huy added
};

// getters
const getters = {
  confirm: state => {
    return state.confirm;
  },
  bid: state => {
    return state.bid;
  },
  getNewBid: state => {
    return state.newBid;
  },
  getBids: state => {
    return state.bids;
  },
  getCurrentBidInfo: state => {
    return state.currentBidInfo;
  },
  getCurrentBid: state => {
    return state.currentBid;
  },
  getCurrentBidCompany: state => {
    return state.currentBidCompany;
  },
  getLoading: state => {
    return state.loading;
  },
  getAdminBidReview: state => {
    return state.adminBidReview;
  },
  getVendorBidReview: state => {
    return state.vendorBidReview;
  },
  getVendorBidReviewBox: state => {
    return state.vendorBidReviewBox;
  },
  getCustomerBidReview: state => {
    return state.customerBidReview;
  },
  getShowJobBidNoLongerOpenDialog: state => {
    return state.showJobBidNoLongerOpenDialog;
  }
};

const route = '/bid';

// actions
const actions = {
  deleteBid: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    try {
      return remove(route, payload)
        .then(res => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('toggleLoading', false);
          return res;
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Delete failed', {
            root: true
          });

          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', 'Delete failed', {
        root: true
      });
      commit('toggleLoading', false);
    }
  },
  getCurrent: async ({ commit, dispatch }, payload) => {
    try {
      return get(route, payload)
        .then(res => {
          commit('setCurrentBid', res.data.data);
          dispatch('getCurrentBidCompany', { id: res.data.data.company_id });
          return res.data.data;
        })
        .catch(err => {
          commit('setCurrentBid', undefined);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
    }
  },
  getCurrentInfo: async ({ commit, dispatch }, payload) => {
    try {
      console.log('getCurrentInfo payload', payload);
      if (payload) {
        return get(route + '/info', payload)
          .then(res => {
            commit('setCurrentBidInfo', res.data.data);
            dispatch('getCurrentBidCompany', { id: res.data.data.company_id });
            return res.data.data;
          })
          .catch(err => {
            commit('setCurrentBidInfo', undefined);
            commit('setError', err);
          });
      } else {
        console.log('getCurrentInfo payload null');
        commit('setCurrentBidInfo', {});
        return {};
      }
    } catch (err) {
      commit('setError', err);
    }
  },
  getAll: async ({ commit, rootState }, payload) => {
    try {
      payload.company_id = rootState.company.company.id;
      payload.type_id = rootState.company.company.type_id;
      payload.job_id = payload.hasOwnProperty('job_id')
        ? payload.job_id
        : router.currentRoute.params.id;
    } catch (err) {
      return err;
    }
    commit('toggleLoading', true);
    const constructedRoute =
      route + '?options=' + encodeURIComponent(JSON.stringify(payload));
    try {
      return getAll(constructedRoute)
        .then(res => {
          commit('setBids', res.data.bids);
          commit('toggleLoading', false);
          return res.data.bids;
        })
        .catch(err => {
          commit('setBids', []);
          commit('toggleLoading', false);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getCurrentBidCompany: async ({ commit, dispatch }, payload) => {
    try {
      return get('/company', payload.id)
        .then(res => {
          commit('setCurrentBidCompany', res.data.data);
        })
        .catch(err => {
          commit('setCurrentBidCompany', undefined);
          commit('setError', err);
        });
    } catch (err) {
      commit('setError', err);
      commit('setCurrentBidCompany', undefined);
    }
  },
  createBid: ({ commit, dispatch, rootState }, payload) => {
    commit('toggleLoading', true);

    let company_address =
      rootState.bids.bid.selectedSite.site_address ||
      rootState.company.company.street +
      ', ' +
      rootState.company.company.city +
      ', ' +
      rootState.company.company.state +
      ', ' +
      rootState.company.company.zip;
    payload.company_address = company_address;
    return new Promise((resolve, reject) => {
      create(route, payload)
        .then(res => {
          let rs = res.response;
          if (!rs) {
            if (res.status == 200) {
              let job = {};
              job.id = router.currentRoute.params.id;
              job.bid_status = 'open';
              dispatch('jobs/updateJob', job, { root: true });

              // huy updated => check status if error, show the message from api
              commit('notification/setNotification', true, { root: true });

              commit('notification/setNotificationMsg', res.data.message, {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });
              commit('setNewBid', res.data.data);

              commit('toggleConfirm', false);
              commit('toggleLoading', false);
              resolve(res);
            } else {
              // huy updated => show message from api
              // commit("notification/setNotificationMsg", res.data.message, {
              //   root: true,
              // });
              // commit("notification/setNotification", true, { root: true });
              // commit("notification/setNotificationType", "error", { root: true });
              // commit("toggleLoading", false);
              commit('toggleConfirm', false);
              commit('toggleLoading', false);
              commit('setShowJobBidNoLongerOpenDialog', true);
            }
          } else {
            if (rs.status == 200) {
              // huy updated => check status if error, show the message from api
              commit('notification/setNotification', true, { root: true });

              commit('notification/setNotificationMsg', rs.data.message, {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });
              commit('setNewBid', rs.data.data);
              let job = {};
              job.id = router.currentRoute.params.id;
              job.bid_status = 'open';
              dispatch('jobs/updateJob', job, { root: true });
              commit('toggleConfirm', false);
              commit('toggleLoading', false);
              resolve(rs);
            } else {
              // huy updated => show message from api
              /* commit("notification/setNotificationMsg", rs.data.message, {
                   root: true,
                 });
                 commit("notification/setNotification", true, { root: true });
                 commit("notification/setNotificationType", "error", { root: true });         
                 */

              commit('toggleConfirm', false);
              commit('toggleLoading', false);
              console.log('error from api: ', rs.data.message);
              commit('setShowJobBidNoLongerOpenDialog', true);
            }
          }
        })
        .catch(err => {
          console.log('Error Creating Bid', err);
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Error Creating Bid', {
            root: true
          });
          //   if (err.response) {
          //     if ( err.response.message) {
          //       commit(
          //         "notification/setNotificationMsg",
          //         err.response.message,
          //         {
          //           root: true,
          //         }
          //       );
          //     }else{
          //     commit(
          //       "notification/setNotificationMsg",
          //       err.response.data.message,
          //       {
          //         root: true,
          //       }
          //     );
          //   }
          //  }
          //  else {
          //     commit("notification/setNotificationMsg", "Error Creating Bid", {
          //       root: true,
          //     });
          //   }
          commit('toggleLoading', false);
          reject(err);
        });
    });
  },
  updateBid: async ({ commit, rootState }, payload) => { // user for reject bid
    commit('toggleLoading', true);
    if (payload.isEditBid && !payload.onlyClickConfirm) {
      let company_address =
        rootState.bids.bid.selectedSite.site_address ||
        rootState.company.company.street +
        ', ' +
        rootState.company.company.city +
        ', ' +
        rootState.company.company.state +
        ', ' +
        rootState.company.company.zip;
      payload.company_address = company_address;

      //payload.needCalculateDistance = true;
      payload.hasInfoDistance = true;
    }

    return new Promise((resolve, reject) => {
      update(route, payload)
        .then(res => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('toggleLoading', false);
          resolve(res);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Update failed', {
            root: true
          });
          commit('toggleLoading', false);
          reject(err);
        });
    });
  },
  updateBidForCloseOutForm: async ({ commit }, payload) => {
    //commit('toggleLoading', true);
    return new Promise((resolve, reject) => {
      update(route, payload)
        .then(res => {
          // commit('notification/setNotification', true, { root: true });
          // commit('notification/setNotificationMsg', res.data.message, {
          //   root: true
          // });
          // commit('notification/setNotificationType', 'success', { root: true });
          // commit('toggleLoading', false);
          resolve(res);
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Update failed', {
            root: true
          });
          commit('toggleLoading', false);
          reject(err);
        });
    });
  }
};

// mutations
const mutations = {
  toggleAdminReview: (state, review) => {
    state.adminBidReview = review;
  },
  toggleCustomerReview: (state, review) => {
    state.customerBidReview = review;
  },
  toggleVendorReview: (state, review) => {
    state.vendorBidReview = review;
  },
  toggleConfirm: (state, confirm) => {
    state.confirm = confirm;
  },
  toggleLoading: (state, loading) => {
    state.loading = loading;
  },
  setNewBid: (state, bid) => {
    state.newBid = bid;
  },
  setBid: (state, bid) => {
    state.bid = bid;
  },
  setBids: (state, bids) => {
    state.bids = bids;
  },
  setCurrentBid: (state, currentBid) => {
    state.currentBid = currentBid;
  },
  setCurrentBidInfo: (state, currentBidInfo) => {
    state.currentBidInfo = currentBidInfo;
  },
  setError: (state, err) => {
    state.error = err;
  },
  setAdminBidReview: (state, adminBidReview) => {
    state.adminBidReview = adminBidReview;
  },
  setVendorBidReview: (state, vendorBidReview) => {
    state.vendorBidReview = vendorBidReview;
  },
  setVendorBidReviewBox: (state, vendorBidReviewBox) => {
    state.vendorBidReviewBox = vendorBidReviewBox;
  },
  setCustomerBidReview: (state, customerBidReview) => {
    state.customerBidReview = customerBidReview;
  },
  setCurrentBidCompany: (state, currentBidCompany) => {
    state.currentBidCompany = currentBidCompany;
  },
  setShowJobBidNoLongerOpenDialog: (state, boolean) => {
    //huy added
    console.log('call setShowJobBidNoLongerOpenDialog', boolean);
    state.showJobBidNoLongerOpenDialog = boolean;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
