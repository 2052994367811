import { getAll, create, update, remove, get, upload } from '../../api';
import VueRouter from '@/router';

// initial customer state
const state = {
  user: undefined,
  users: undefined,
  usersForDropdown: [],
  users_company: undefined,
  loading: false,
  error: undefined,
  create: false,
  editing: false,
  userToEdit: {},
  newUser: {},
  newTeamUser: {},
  editedUser: {},
  deletedUser: {},
  loggedInUser: {},
  iconUrl: undefined
};

// getters
const getters = {
  editing: state => {
    return state.editing;
  },
  create: state => {
    return state.create;
  },
  deletedUser: state => {
    return state.deletedUser;
  },
  getNewUser: state => {
    return state.newUser;
  },
  getNewTeamUser: state => {
    return state.newTeamUser;
  },
  getUserToEdit: state => {
    return state.userToEdit;
  },
  getEditedUser: state => {
    return state.editedUser;
  },
  getUser: state => {
    return state.user;
  },
  getUsers: state => {
    return state.users;
  },
  getUsersForDropdown: state => {
    return state.usersForDropdown;
  },
  getUsersCompany: state => {
    return state.users_company;
  },
  getUserRole: state => {
    return state.user && state.user.type_id === 1 ? 'Admin' : 'Standard';
  },
  loading: state => {
    return state.loading;
  },
  getIconUrl: state => {
    return state.iconUrl;
  },
  getLoggedInUser: state => {
    return state.loggedInUser;
  }
};

const route = '/user';

// actions
const actions = {
  uploadIcon: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    const reader = new FileReader();
    reader.readAsDataURL(payload.file);
    try {
      reader.onload = () =>
        upload('/media/upload', payload.file.name, reader.result).then(res => {
          commit('notification/setNotification', true, { root: true });
          commit(
            'notification/setNotificationMsg',
            'User image/icon uploaded successfully',
            {
              root: true
            }
          );
          commit('notification/setNotificationType', 'success', {
            root: true
          });
          commit('setIcon', res.data.data.Location);
          commit('setIconUrl', res.data.data.Location);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('notification/setNotificationMsg', 'Error uploading file/image', {
        root: true
      });

      commit('toggleLoading', false);
    }
  },
  getById: async ({ commit }, payload) => {
    commit('toggleLoading', true);

    try {
      return get(route, payload)
        .then(res => {
          commit('setUserToEdit', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getAll: async ({ commit, rootState }, payload) => {
    try {
      commit('toggleLoading', true);
      payload.company_id = rootState.company.company.id;
      const constructedRoute =
        route + '?options=' + encodeURIComponent(JSON.stringify(payload));
      return getAll(constructedRoute)
        .then(res => {
          commit('setUsers', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotificationMsg', err.response.data.message, {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('setUsers', []);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      // commit("notification/setNotification", true, { root: true });
      // commit("notification/setNotificationMsg", err.response.data.message, {
      // 	root: true,
      // });
      // commit("notification/setNotificationType", "error", { root: true });
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getAllUserForDropdown: async ({ commit, rootState }, payload) => {
    try {
      commit('toggleLoading', true);
      payload.company_id = rootState.company.company.id;
      const constructedRoute =
        route + '?options=' + encodeURIComponent(JSON.stringify(payload));
      return await getAll(constructedRoute)
        .then(res => {
          commit('setUsersForDropdown', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotificationMsg', err.response.data.message, {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('setUsersForDropdown', []);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      // commit("notification/setNotification", true, { root: true });
      // commit("notification/setNotificationMsg", err.response.data.message, {
      // 	root: true,
      // });
      // commit("notification/setNotificationType", "error", { root: true });
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  getAllCompanyId: async ({ commit, rootState }, payload) => {
    try {
      commit('toggleLoading', true);
      commit('setUsersCompany', undefined);
      const constructedRoute =
        route + '?options=' + encodeURIComponent(JSON.stringify(payload));
      return getAll(constructedRoute)
        .then(res => {
          commit('setUsersCompany', res.data.data);
          commit('toggleLoading', false);
        })
        .catch(err => {
          commit('notification/setNotificationMsg', err.response.data.message, {
            root: true
          });
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('setUsersCompany', []);
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      // commit("notification/setNotification", true, { root: true });
      // commit("notification/setNotificationMsg", err.response.data.message, {
      // 	root: true,
      // });
      // commit("notification/setNotificationType", "error", { root: true });
      commit('setError', err);
      commit('toggleLoading', false);
    }
  },
  deleteUser: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    try {
      return remove(route, payload)
        .then(res => {
          let rs = res.response;
          //console.log("res",res);
          //console.log("rs",res);
          if (!rs) {
            // huy updated
            if (res.status == 200) {
              commit('notification/setNotification', true, { root: true });
              commit('notification/setNotificationMsg', res.data.message, {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });

              commit('setDeletedUser', res.data.data);
              commit('toggleLoading', false);
            } else {
              // huy updated => show message from api

              commit('notification/setNotificationMsg', res.data.message, {
                root: true
              });
              commit('notification/setNotification', true, { root: true });
              commit('notification/setNotificationType', 'error', {
                root: true
              });
              commit('toggleLoading', false);
            }
          } else {
            if (rs.status == 200) {
              commit('notification/setNotification', true, { root: true });
              commit('notification/setNotificationMsg', rs.data.message, {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });

              commit('setDeletedUser', rs.data.data);
              commit('toggleLoading', false);
            } else {
              // huy updated => show message from api

              commit('notification/setNotificationMsg', rs.data.message, {
                root: true
              });
              commit('notification/setNotification', true, { root: true });
              commit('notification/setNotificationType', 'error', {
                root: true
              });
              commit('toggleLoading', false);
            }
          }
        })
        .catch(err => {
          commit('notification/setNotificationMsg', err.response.data.message, {
            root: true
          });

          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Delete failed', {
            root: true
          });
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  },
  updateUser: async ({ commit }, payload) => {
    commit('toggleLoading', true);
    delete payload.total;
    delete payload.created_at;
    delete payload.updated_at;
    try {
      return update(route, payload)
        .then(res => {
          if (res.response?.status === 409) {
            commit('notification/setNotification', true, { root: true });
            commit('notification/setNotificationType', 'error', { root: true });
            commit('notification/setNotificationMsg', 'Update failed', {
              root: true
            });

            commit('toggleLoading', false);
            return res;
          }

          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'success', { root: true });
          commit('notification/setNotificationMsg', res.data.message, {
            root: true
          });

          commit('setEditedUser', res.data.data);
          commit('toggleLoading', false);
          return res.data;
        })
        .catch(err => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          // commit("notification/setNotificationMsg", err.response.message, { root: true,});
          commit('notification/setNotificationMsg', 'Update failed', {
            root: true
          });

          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('notification/setNotification', true, { root: true });
      commit('notification/setNotificationMsg', err.response.data.message, {
        root: true
      });
      commit('notification/setNotificationType', 'error', { root: true });
      commit('toggleLoading', false);
    }
  },
  createUser: async ({ commit, rootState, dispatch }, payload) => {
    let user = payload;
    user.company_id = rootState.company.company.id;
    if (user.passwordConf && user.password !== user.passwordConf) {
      commit('setError', 'Passwords do not match');
      return;
    }
    commit('toggleLoading', true);
    return create(route, user)
      .then(res => {
        if (res.response?.status === 409) {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationType', 'error', { root: true });
          commit('notification/setNotificationMsg', 'Create failed', {
            root: true
          });
          commit('toggleLoading', false);
          return res;
        }
        if (!res.data) {
          throw new Error('User was not created successfully');
        }

        commit('notification/setNotificationMsg', res.data.message, {
          root: true
        });

        commit('notification/setNotification', true, { root: true });
        commit('notification/setNotificationType', 'success', { root: true });
        commit('setNewCreated', res.data.data);
        commit('toggleLoading', false);
        // var userOptions = {
        //   itemsPerPage: 15,
        //   page: 1,
        //   search: undefined,
        //   sortBy: ["user_id"],
        //   sortDesc: [true],
        // };
        // dispatch("getAll", userOptions);
        return { success: true };
      })
      .catch(err => {
        commit('notification/setNotification', true, { root: true });
        commit('notification/setNotificationType', 'error', { root: true });
        commit('notification/setNotificationMsg', 'Create failed', {
          root: true
        });
        // commit("notification/setNotificationMsg", err.message, {
        //   root: true,
        // });

        commit('toggleLoading', false);
        return { success: false, ...err };
      });
    // } catch (err) {
    // 	commit("notification/setNotification", true, { root: true });
    // 	commit("notification/setNotificationMsg", err.response.data.message, { root: true });
    // 	commit("notification/setNotificationType", "error", { root: true });
    // 	commit("toggleLoading", false);
    // }
  },
  toggleEditing: async ({ commit, dispatch }, payload) => {
    commit('toggleLoading', true);
    if (Object.keys(payload.user).length > 0) {
      dispatch('getById', payload.user.user_id).then(() => {
        commit('toggleEditing', payload);
        commit('toggleCreate', payload.toggle);
        commit('toggleLoading', false);
      });
    }
  },
  emailOptOut: async ({ commit }, payload) => {
    let _route = route + '/email-opt-out';
    return get(
      _route,
      `?userEmail=${payload.userEmail}&userId=${payload.userId}`
    )
      .then(res => {})
      .catch(err => {});
  },
  findById: async ({ commit }, payload) => {
    commit('toggleLoading', true);

    try {
      return get(route, payload)
        .then(res => {
          commit('toggleLoading', false);
          return res.data.data;
        })
        .catch(err => {
          commit('setError', err);
          commit('toggleLoading', false);
        });
    } catch (err) {
      commit('setError', err);
      commit('toggleLoading', false);
    }
  }
};

// mutations
const mutations = {
  setIcon: (state, icon) => {
    state.user.icon_url = icon;
  },
  toggleCreate: (state, create) => {
    state.create = create;
  },
  setUser: (state, user) => {
    state.user = user;
  },
  setDeletedUser: (state, user) => {
    state.deletedUser = user;
  },
  setUsers: (state, users) => {
    state.users = users;
  },
  setUsersForDropdown: (state, users) => {
    state.usersForDropdown = users;
  },
  setUsersCompany: (state, users) => {
    state.users_company = users;
  },
  toggleLoading: (state, loading) => {
    state.loading = loading;
  },
  setError: (state, error) => {
    state.error = error;
  },
  setNew: (state, user) => {
    state.newUser = user;
  },
  setNewCreated: (state, user) => {
    state.newTeamUser = user;
  },
  setEditedUser: (state, user) => {
    state.editedUser = user;
  },
  toggleEditing: (state, edit) => {
    state.editing = edit.toggle;
  },
  setUserToEdit: (state, user) => {
    state.userToEdit = user;
  },
  setIconUrl: (state, url) => {
    state.iconUrl = url;
  },
  setLoggedInUser: (state, user) => {
    state.loggedInUser = JSON.parse(JSON.stringify(user));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
