<template>
  <div>
    <div v-if="_loading && (!Array.isArray(_jobs) || _jobs.length <= 0)">
      <v-skeleton-loader
        ref="skeleton"
        :boilerplate="false"
        type="table"
        class="mx-0"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <div class="titleBar">
        <v-card-title
          v-html="tableTitle"
          style="font-size: 26px"
          class="save--text"
          v-if="$vuetify.breakpoint.smAndUp"
        ></v-card-title>

        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <v-text-field
          v-model="search"
          style="max-width: 300px; margin: 16px"
          label="Search"
          :append-icon="search ? `mdi-close` : `mdi-briefcase-search`"
          @keyup="throttledMethod()"
          @click:append="clearSearch()"
        />
      </div>
      <v-slide-x-transition>
        <v-data-table
          height="calc(100vh - 350px)"
          v-if="_jobs"
          :options.sync="options"
          :server-items-length="total"
          no-data-text="No jobs right now, create one!"
          :headers="headers"
          :items="_jobs"
          :loading="_loading"
          multi-sort
          @update:options="_getJobs(options)"
          @click:row="handleClick"
          @update:sort-by="handleSortBy"
          @update:sort-desc="handleSortDesc"
          @update:page="handleUpdatePage"
          @update:items-per-page="handleUpdateItemPage"
        >
          <template #[`item.saved`]="{ item }">
            <v-icon v-if="item.saved">mdi-star</v-icon>
          </template>
          <template #[`item.bid_status`]="{ item }">
            <span v-html="item.bid_status"></span>
          </template>
          <template #[`item.interest`]="{ item }">
            <v-icon color="green" v-if="item.interest === true"
              >mdi-thumb-up</v-icon
            >
            <v-icon color="red" v-if="item.interest === false"
              >mdi-thumb-down</v-icon
            >
          </template>
          <template #[`item.reopened`]="{ item }">
            <span v-html="item.reopened"></span>
          </template>
        </v-data-table>

        <v-banner v-else single-line transition="slide-y-transition">
          Sorry No Jobs Found
          <template v-slot:actions="{}">
            <v-btn
              text
              color="primary"
              :loading="_loading"
              @click="throttledMethod()"
            >
              Retry
            </v-btn>
          </template>
        </v-banner>
      </v-slide-x-transition>
    </div>

    <admin-job-detail />
    <edit-job-form :statusJob="'draft'" />
  </div>
</template>

<script>
import _ from 'lodash';
import AdminJobDetail from '@/components/jobs/AdminJobDetail';
import EditJobForm from '@/components/jobs/EditJobForm.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Options from '@/mixins/Options';
export default {
  mixins: [Options],
  props: {
    tableTitle: String
  },
  components: {
    AdminJobDetail,
    EditJobForm
  },
  data() {
    return {
      search: undefined,
      pageSize: 0,
      pageItems: 0,
      page: 1,
      limit: 15,
      selected: null,
      OpenJobs: 'Open Jobs',
      filterStart: null,
      filterEnd: null,
      weightRange: [null, null],
      palletRange: [null, null],
      saved: 'All',
      specSearch: null,
      locationSearch: null,
      mileRange: [null, null]
    };
  },
  computed: {
    ...mapGetters({
      _jobs: 'jobs/getJobs',
      _loading: 'jobs/loading',
      _companyId: 'company/getCompanyId',
      _companyType: 'company/getCompanyType',
      _active: 'jobs/getCreateNewJob',
      _status: 'jobs/getStatus'
    }),
    total() {
      return this._jobs[0] ? parseInt(this._jobs[0].total) : 0;
    },

    headers() {
      if (Array.isArray(this._jobs) && this._jobs.length > 0) {
        const keys = Object.keys(this._jobs[0]);
        var headers = [];
        var itemIndex = 0;
        keys.forEach(i => {
          if (
            i !== 'created_at' &&
            i !== 'updated_at' &&
            i !== 'total' &&
            (this._companyType !== 1 || i !== 'saved')
          ) {
            const obj = {
              text: i.replace(/_/g, ' ').toUpperCase(),
              value: i,
              sortable: true,
              filterable: true,
              divider: false,
              align: 'start',
              width: 'auto'
            };
            switch (i) {
              case 'job_id':
                obj.width = '120';
                break;
              case 'equip_transfer_date':
                obj.width = '240';
                break;
              case 'reopened' || 'state' || 'dist':
                obj.width = '160';
                break;
              case 'active_bids_pending':
                obj.width = '150';
                break;
              default:
                obj.width = '200';
            }
            headers.push(obj);
            this.itemIndex++;
          }
        });
        return headers;
      } else {
        return undefined;
      }
    },
    pagination() {
      return {
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10
      };
    },
    compId() {
      return this._companyId || 0;
    }
  },
  watch: {
    _active() {
      this.getData();
    },
    tableTitle() {
      this.options.sortBy = [];
      this.options.sortDesc = [];
    },
    compId() {
      this.getData();
    },
    _status() {
      this.options.page = 1;
      this.options.itemsPerPage = 15;
      this.options.sortBy = [];
      this.options.sortDesc = [];
      if (this.$route.query) {
        this.$router.replace({ query: {} }).catch(() => {});
      }
      this.getData();
    }
    // search() {
    // 	this.throttledMethod();
    // },
  },
  mounted() {
    const temp = this.$route.query;
    const query = { ...temp };
    if (!query) {
      return;
    }
    if (query['pageSize']) {
      this.options.page = query['pageSize'] * 1;
      delete query['pageSize'];
    }
    if (query['pageItems']) {
      this.options.itemsPerPage = query['pageItems'] * 1;
      delete query['pageItems'];
    }
    this.options.sortBy = Object.keys(query);
    this.options.sortDesc = Object.values(query).map(item =>
      item === 'true' || item === true ? true : false
    );

    setTimeout(() => {
      this._getJobs(this.options).then(() => {});
    }, 1000);
    this.throttledMethod = _.debounce(() => {
      this.getData();
    }, 1000);
  },
  methods: {
    ...mapActions({
      _getJobs: 'jobs/getAll',
      _setJob: 'jobs/setCurrent'
    }),
    ...mapMutations({
      _setLoading: 'jobs/toggleLoading',
      _setAdminJobDetails: 'jobs/setAdminJobDetails',
      _setActiveEditForm: 'jobs/setEditNewJob'
    }),
    getData() {
      let options = this.options;
      options.search = this.search;
      this._getJobs(options);
    },
    clearSearch() {
      this.search = '';
      this.getData();
    },
    handleClick(value) {
      if (this.options.status !== 'draft') {
        if (this._companyType === 3) {
          this._setJob(value.job_id);
          this._setAdminJobDetails(true);
        } else {
          const params = {};

          if (this.pageSize) {
            params['pageSize'] = this.pageSize;
          }

          if (this.pageItems) {
            params['pageItems'] = this.pageItems;
          }

          this.options.sortBy.forEach((item, index) => {
            params[item] = this.options.sortDesc[index];
          });

          this.$router.replace({ query: params }).catch(() => {});

          this.$router.push('/job/' + value.job_id);
        }
      } else {
        // this._setJob(value.job_id).then((_rs) => (
        //   this._setActiveEditForm(true);
        // ))
        this._setJob(value.job_id).then(rs => {
          this._setActiveEditForm(true);
        });
        // this._setJob(value.job_id);
        // setTimeout(()=>{
        //   this._setActiveEditForm(true);
        // },5000)
      }
    },
    handleSortBy(value) {
      //console.log("handleSortBy",value);
      if (!this._loading) this.options.sortBy = value;
    },
    handleSortDesc(value) {
      if (!this._loading) this.options.sortDesc = value;
    },
    handleUpdatePage(value) {
      this.pageSize = value;
    },
    handleUpdateItemPage(value) {
      this.pageItems = value;
    }
  }
};
</script>

<style scoped>
.titleBar {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.highlighted-button {
  width: 112px;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
}

.gray-button {
  color: #5d5d5d;
  background-color: transparent !important;
  height: 16px;
  font-size: 14px;
}

.mobile-button {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
  color: #ffffff !important;
}
</style>
