<template>
	<v-card
		:width="
			$vuetify.breakpoint.mdAndUp ? 'calc(50% - 16px)' : 'calc(100% - 16px)'
		"
		:height="$vuetify.breakpoint.mdAndUp ? '300px' : ''"
		class="white--text ma-2"
		justify="center"
		align="center"
		outlined
	>
		<v-row class="flex-wrap no-gutters">
			<v-col class="text-left" cols="12" sm="12" md="6">
				<v-card-subtitle>My Jobs </v-card-subtitle>
			</v-col>
			<v-col
				:class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left'"
				cols="12"
				sm="12"
				md="6"
			>
				<v-card-subtitle>Since: {{ companyStart }}</v-card-subtitle>
			</v-col>
		</v-row>
		<v-container fluid fill-height class="mt-0 pt-0">
			<v-row
				class="no-gutters justify-space-between"
				:class="
					$vuetify.breakpoint.mdAndUp ? 'd-flex flex-row' : 'd-flex flex-column'
				"
			>
				<v-sheet
					height="100%"
					:width="
						$vuetify.breakpoint.mdAndUp
							? 'calc((100%/3) - 16px)'
							: 'calc(100% - 16px)'
					"
					color="#fb8c00"
					class="ma-2 text-center"
				>
					<v-container fluid fill-height>
						<v-row
							class="white--text row no-gutters display-3 pa-1 pb-0 font-weight-bold"
							:class="$vuetify.breakpoint.smAndDown ? 'headline' : ''"
							justify="center"
							align="center"
						>
							<ICountUp
								:delay="delay"
								:endVal="Number(active)"
								:options="options"
							/>
						</v-row>
						<v-divider />

						<v-row
							class="white--text row no-gutters headline pa-1 pt-0"
							:class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"
							justify="center"
							align="center"
						>
							Jobs Out For Bid
						</v-row>
					</v-container>
				</v-sheet>
				<v-sheet
					height="100%"
					fill-height
					:width="
						$vuetify.breakpoint.mdAndUp
							? 'calc((100%/3) - 16px)'
							: 'calc(100% - 16px)'
					"
					color="#ffc107"
					class="ma-2"
				>
					<v-container fluid fill-height>
						<v-row
							class="white--text row no-gutters display-3 pa-1 pb-0 font-weight-bold"
							:class="$vuetify.breakpoint.smAndDown ? 'headline ' : ''"
							justify="center"
							align="center"
						>
							<ICountUp
								:delay="delay"
								:endVal="Number(awarded)"
								:options="options"
							/>
						</v-row>
						<v-row
							class="white--text  no-gutters row headline pa-1 pt-0"
							:class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"
							justify="center"
							align="center"
						>
							Jobs In Progress
						</v-row>
					</v-container>
				</v-sheet>
				<v-sheet
					height="90%"
					:width="
						$vuetify.breakpoint.mdAndUp
							? 'calc((100%/3) - 16px)'
							: 'calc(100% - 16px)'
					"
					color="#52b513"
					class="ma-2 pa-0"
				>
					<v-container fluid fill-height>
						<v-row
							class="white--text  row no-gutters display-3 pa-1 pb-0 font-weight-bold"
							:class="$vuetify.breakpoint.smAndDown ? 'headline' : ''"
							justify="center"
							align="center"
						>
							<ICountUp
								:delay="delay"
								:endVal="Number(closed)"
								:options="options"
							/>
						</v-row>
						<v-row
							class="white--text row no-gutters headline pa-1 pt-0"
							:class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"
							justify="center"
							align="center"
						>
							Jobs Completed
						</v-row>
					</v-container>
				</v-sheet>
			</v-row>
		</v-container>
	</v-card>
</template>
<script>
const gradients = [["#8cd65d", "rgba(140, 214, 93, 0.28)"]];
import ICountUp from "vue-countup-v2";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
	props: {
		active: { type: String, default: "0" },
		awarded: { type: String, default: "0" },
		closed: { type: String, default: "0" },
	},
	components: { ICountUp },
	data: () => ({
		delay: 1000,
		endVal: 120500,
		options: {
			useEasing: true,
			useGrouping: true,
			separator: ",",
			decimal: ".",
			prefix: "",
			suffix: "",
		},
		showLabels: true,
		lineWidth: 20,
		labelSize: 7,
		radius: 0,
		padding: 8,
		lineCap: "round",
		gradient: gradients[0],
		labels: ["March", "April", "May", "June", "July"],
		value: [200, 675, 410, 390, 310],
		gradientDirection: "top",
		gradients,
		fill: false,
		type: "bar",
		autoLineWidth: false,
	}),
	methods: {
		onReady: function(instance, CountUp) {
			const that = this;
			instance.update(that.endVal + 100);
		},
	},
	computed: {
		...mapGetters({
			_company: "company/getCompany",
		}),
		companyStart() {
			return moment(this._company.created_at).format("MM/DD/YYYY");
		},
	},
};
</script>
<style></style>
