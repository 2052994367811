<template>
  <v-card outlined height="calc(100% - 16px)" class="ma-2 pa-2">
    <iframe
      width="100%"
      height="400px"
      frameborder="0"
      style="border: 0"
      :src="`https://www.google.com/maps/embed/v1/directions?key=AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE
              &destination=${bidAddress}&&origin=${jobAddress}`"
      allowfullscreen
    >
    </iframe>
  </v-card>
</template>
<script>
export default {
  props: {
    jobAddress: String,
    bidAddress: String,
  },
  data() {
    return {};
  },
  computed: {
    googleKey() {
      return "AIzaSyCc9VZ8L5qORFvGbS3Lyw5b9JyqNdKRyUE"; //	return process.env.VUE_APP_GOOGLE_API_KEY;
    },
  },
};
</script>
