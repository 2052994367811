<template>
  <v-card
    outlined
    height="calc(100% - 16px)"
    class="ma-2 vendorJobClosingTotal"
  >
    <v-toolbar flat>
      <v-toolbar-title class="" style="font-weight: 500">
        Transaction Final Details
      </v-toolbar-title>
      <!--
      <v-spacer />
      <v-row
        no-gutters
        class="mt-2"
        v-if="
          (_job.status === 'closing' || _job.status === 'resale') &&
          _company.type_id === 1
        "
      >
        <v-spacer />
        <v-btn color="error" class="mr-2" @click="rejectOffer()">Reject</v-btn>
        <v-btn color="secondary" @click="acceptOffer()">Accept</v-btn> 
      </v-row> -->
    </v-toolbar>
    <v-divider />
    <v-card flat class="pa-4">
      <!-- First row -->
      <v-row style="padding: 10px 0px 10px 0px">
        <v-col sm="12" class="ma-0 pa-0">
          <v-card-text class="py-0" style="font-weight: 500">
            Vendor Purchase Offer
          </v-card-text>
        </v-col>
      </v-row>

      <!-- second row -->
      <v-row
        v-if="job.bid_per_pound !== null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Initial Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text class="pa-0 text-center v-card__subtitle">
            Price/lb Bid
          </v-card-text>
          <v-card-text class="v-card__text v-card__subtitle text-center">
            {{ job.bid_per_pound }}
          </v-card-text>
        </div>
        <div>
          <v-card-text style="margin-top: 5px" class="v-card__subtitle">
            x
          </v-card-text>
        </div>
        <div>
          <v-card-text class="pa-0 v-card__subtitle">
            Final Weight
          </v-card-text>
          <v-card-text class="v-card__subtitle text-center">
            {{ job.final_weight || 0 }}
          </v-card-text>
        </div>
        <div>
          <v-card-text style="margin-top: 5px" class="v-card__subtitle">
            =
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="job.final_weight > 0"
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div style="color: red">
              {{ '(' + numberWithCommas02(priceBeforeResale) + ')' }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="job.final_weight <= 0"
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div>{{ numberWithCommas02(priceBeforeResale) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- second row 2A -->
      <v-row
        v-if="job.bid_all_in_purchase !== null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Initial Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="job.bid_all_in_purchase !== null"
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div style="color: red">
              {{ '(' + job.bid_all_in_purchase + ')' }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="job.bid_all_in_purchase == null"
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            <div>{{ Number(0.0).toFixed(2) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- second row 2B -->
      <v-row
        v-if="job.bid_per_pound == null && job.bid_all_in_purchase == null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Initial Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text
            style="margin-top: 5px; width: 140px"
            class="v-card__subtitle text-right"
          >
            <div style="float: left">$</div>
            {{ Number(0.0).toFixed(2) }}
          </v-card-text>
        </div>
      </v-row>
      <!-- 3rd row -->
      <v-row no-gutters class="justify-space-between pa-0">
        <div>
          <v-card-text class="v-card__subtitle">
            Additional Scrap Proceeds
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="
              job.additional_scrap_proceeds !== null &&
                job.additional_scrap_proceeds != 0
            "
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div style="color: red">
              {{ '(' + job.additional_scrap_proceeds + ')' }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="
              job.additional_scrap_proceeds == null ||
                job.additional_scrap_proceeds == 0
            "
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div>{{ Number(0.0).toFixed(2) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 4th row -->
      <v-row
        v-if="job.recycle_specs.resale == true"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Equipment Purchase Offer
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="
              job.resale_offer !== null && parseFloat(job.resale_offer) !== 0
            "
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div style="color: red">
              {{ '(' + job.resale_offer + ')' }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="job.resale_offer == null || Number(job.resale_offer) == 0"
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div>{{ Number(0.0).toFixed(2) }}</div>
          </v-card-text>
        </div>
      </v-row>
      <!-- empty row with border bottom -->
      <v-row
        no-gutters
        class="justify-space-between pa-0 border-bottom" style="border-bottom: 1px solid gray!important;"
      ></v-row>
      <!-- 5th row -->
      <v-row
        no-gutters
        class="justify-space-between pa-0 border-top border-bottom" style="border-bottom: 1px solid gray!important;"
      >
        <div>
          <v-card-text class="v-card__subtitle" style="font-weight: 500">
            Subtotal Vendor Purchase Offer
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="
              job.final_weight > 0 ||
                job.bid_all_in_purchase !== null ||
                job.additional_scrap_proceeds !== null ||
                job.resale_offer !== null
            "
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div
              v-if="
                calculateSubtotal(
                  job.bid_per_pound,
                  job.final_weight,
                  job.bid_all_in_purchase,
                  job.additional_scrap_proceeds,
                  job.resale_offer
                ) > 0
              "
              style="color: red"
            >
              {{
                '(' +
                  numberWithCommas02(
                    calculateSubtotal(
                      job.bid_per_pound,
                      job.final_weight,
                      job.bid_all_in_purchase,
                      job.additional_scrap_proceeds,
                      job.resale_offer
                    )
                  ) +
                  ')'
              }}
            </div>
            <div
              v-if="
                calculateSubtotal(
                  job.bid_per_pound,
                  job.final_weight,
                  job.bid_all_in_purchase,
                  job.additional_scrap_proceeds,
                  job.resale_offer
                ) == 0
              "
            >
              {{
                numberWithCommas02(
                  calculateSubtotal(
                    job.bid_per_pound,
                    job.final_weight,
                    job.bid_all_in_purchase,
                    job.additional_scrap_proceeds,
                    job.resale_offer
                  )
                )
              }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="
              job.final_weight <= 0 &&
                job.bid_all_in_purchase == null &&
                job.additional_scrap_proceeds == null &&
                job.resale_offer == null
            "
            class="v-card__subtitle text-right"
            style="width: 140px"
          >
            <div style="float: left">$</div>
            <div>
              {{
                numberWithCommas02(
                  calculateSubtotal(
                    job.bid_per_pound,
                    job.final_weight,
                    job.bid_all_in_purchase,
                    job.additional_scrap_proceeds,
                    job.resale_offer
                  )
                )
              }}
            </div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 6th row -->
      <v-row v-if="job.bid_buy_out !== null" style="padding: 10px 0px 10px 0px">
        <v-col sm="12" class="ma-0 pa-0">
          <v-card-text class="py-0" style="font-weight: 500">
            Customer Expense
          </v-card-text>
        </v-col>
      </v-row>
      <!-- 7th row -->
      <v-row
        v-if="job.bid_buy_out !== null"
        no-gutters
        class="justify-space-between pa-0"
      >
        <div>
          <v-card-text class="v-card__subtitle">
            Customer Service Charge
          </v-card-text>
        </div>
        <div>
          <v-card-text class="v-card__subtitle text-right" style="width: 140px">
            <div style="float: left">$</div>
            {{
              job.current_bid_buy_out
                ? job.current_bid_buy_out
                : job.bid_buy_out
            }}
          </v-card-text>
        </div>
      </v-row>
      <!-- 8th row TOTAL -->
      <v-row
        v-if="
          calculateTotal(
            calculateSubtotal(
              job.bid_per_pound,
              job.final_weight,
              job.bid_all_in_purchase,
              job.additional_scrap_proceeds,
              job.resale_offer
            ),
            job.current_bid_buy_out
          ) <= 0
        "
        no-gutters
        class="justify-space-between ma-0 pa-0"
      >
        <div>
          <v-card-text
            class="v-card__subtitle"
            style="
              padding: 16px 0px 0px 5px;
              text-transform: uppercase;
              font-weight: 900;
            "
          >
            TOTAL CUSTOMER OWES
          </v-card-text>
        </div>
        <div>
          <v-card-text class="v-card__subtitle text-right" style="width: 140px">
            <div style="float: left">$</div>
            <div style="color: green; font-weight: 900">
              {{
                numberWithCommas02(
                  (
                    calculateTotal(
                      calculateSubtotal(
                        job.bid_per_pound,
                        job.final_weight,
                        job.bid_all_in_purchase,
                        job.additional_scrap_proceeds,
                        job.resale_offer
                      ),
                      job.current_bid_buy_out
                    ) * -1
                  ).toFixed(2)
                )
              }}
            </div>
          </v-card-text>
        </div>
      </v-row>
      <!-- 8A row TOTAL -->
      <v-row
        v-if="
          calculateTotal(
            calculateSubtotal(
              job.bid_per_pound,
              job.final_weight,
              job.bid_all_in_purchase,
              job.additional_scrap_proceeds,
              job.resale_offer
            ),
            job.current_bid_buy_out
          ) > 0
        "
        no-gutters
        class="justify-space-between ma-0 pa-0"
      >
        <div>
          <v-card-text
            class="v-card__subtitle"
            style="
              padding: 16px 0px 0px 5px;
              text-transform: uppercase;
              font-weight: 900;
            "
          >
            TOTAL YOU OWE
          </v-card-text>
        </div>
        <div>
          <v-card-text class="v-card__subtitle text-right" style="width: 140px">
            <div style="float: left">$</div>
            <div style="color: red; font-weight: 900">
              {{
                '(' +
                  numberWithCommas02(
                    calculateTotal(
                      calculateSubtotal(
                        job.bid_per_pound,
                        job.final_weight,
                        job.bid_all_in_purchase,
                        job.additional_scrap_proceeds,
                        job.resale_offer
                      ),
                      job.current_bid_buy_out
                    )
                  ) +
                  ')'
              }}
            </div>
          </v-card-text>
        </div>
      </v-row>
    </v-card>

    <!--
    <v-card flat v-if="job.recycle_specs.resale == true && job.bid_per_pound !== null" class="ma-2">
      <div
        class="
          d-flex
          row
          flex-nowrap
          justify-space-between
          no-gutters
          pa-0
          border-bottom
        "
      >
        <div>
          <v-card-text class="pa-0"> Final Wt. </v-card-text>
          <v-card-text class="num-font pa-0">
            {{ job.final_weight || 0 }} lbs
          </v-card-text>
        </div>

        <div>
          <v-card-text class=""> x </v-card-text>
        </div>

        <div>
          <v-card-text class="pa-0 text-center"> Bid (per lb) </v-card-text>
          <v-card-text class="num-font pa-0 text-center">
            {{ "$" + job.bid_per_pound }}
          </v-card-text>
        </div>

        <div>
          <v-card-text class=""> = </v-card-text>
        </div>

        <div>
          <v-card-text class="pa-0 text-right"> Total </v-card-text>
          <v-card-text class="pa-0 text-right">
            {{ "$" + priceBeforeResale || 0 }}
          </v-card-text>
        </div> 
      </div>
      
      <v-row
        class="
          d-flex
          row
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            Purchase Offer
          </v-card-text>
        </v-col>
        <v-spacer />
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="warning--text text-right ma-0 px-0">
            ${{ job.resale_offer ? job.resale_offer.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row
        class="d-flex row flex-nowrap justify-space-between no-gutters pa-0"
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="headline num-font text-left ma-0 px-0">
            Total
          </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="headline text-right ma-0 px-0">
            {{ "$" + total }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card> -->

    <!--
    <v-card
      flat
      v-if="job.recycle_specs.resale == false && job.bid_per_pound !== null"
      class="pa-4"
    >
      <v-row no-gutters class="justify-space-between pa-0 border-bottom">
        <div>
          <v-card-text class="pa-0"> Final Wt. </v-card-text>
          <v-card-text class="num-font pa-0">
            {{ job.final_weight || 0 }} lbs
          </v-card-text>
        </div>

        <div>
          <v-card-text class=""> x </v-card-text>
        </div>

        <div>
          <v-card-text class="pa-0 text-center"> Bid (per lb) </v-card-text>
          <v-card-text class="num-font pa-0 text-center">
            {{ "$" + job.bid_per_pound }}
          </v-card-text>
        </div>
        <div>
          <v-card-text class=""> = </v-card-text>
        </div>

        <div>
          <v-card-text class="pa-0 text-right"> Total </v-card-text>
          <v-card-text class="pa-0 text-right">
            {{ "$" + priceBeforeResale || 0 }}
          </v-card-text>
        </div>
      </v-row>
      <v-row
        class="d-flex row flex-nowrap justify-space-between no-gutters pa-0"
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="headline num-font text-left ma-0 px-0">
            Total
          </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="headline text-right ma-0 px-0">
            {{ "$" + total }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    -->
    <!--
    <v-card
      flat
      v-if="job.recycle_specs.resale == false && job.bid_buy_out !== null"
      class="px-4 pt-4"
    >
      <v-row
        class="
          d-flex
          row
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            All In Offer
          </v-card-text>
        </v-col>
        <v-spacer />
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="text-right ma-0 px-0">
            ${{ job.bid_buy_out ? job.bid_buy_out.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row
        class="
          d-flex
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            Purchase Offer
          </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="warning--text text-right ma-0 px-0">
            - ${{ job.resale_offer ? job.resale_offer.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="d-flex row flex-nowrap no-gutters pa-0">
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="subtitle-2 mt-0 px-0"> Total </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0 text-right">
          <v-card-text v-if="job.resale_offer" class="headline px-0">
            {{
              "$" +
              (
                Number(job.bid_buy_out) -
                Number(job.resale_offer.replace(/,/g, ""))
              ).toFixed(2)
            }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    -->
    <!-- 
    <v-card
      flat
      v-if="job.recycle_specs.resale == true && job.bid_buy_out !== null"
      class="px-4 pt-4"
    >
      <v-row
        class="
          d-flex
          row
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            All In Offer
          </v-card-text>
        </v-col>
        <v-spacer />
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="text-right ma-0 px-0">
            ${{ job.bid_buy_out ? job.bid_buy_out.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row
        class="
          d-flex
          flex-nowrap
          no-gutters
          justify-space-between
          pa-0
          border-bottom
        "
      >
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="resale-font text-left ma-0 px-0">
            Purchase Offer
          </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="warning--text text-right ma-0 px-0">
            - ${{ job.resale_offer ? job.resale_offer.replace(/,/g, "") : 0 }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="d-flex row flex-nowrap no-gutters pa-0">
        <v-col sm="6" class="ma-0 pa-0">
          <v-card-text class="subtitle-2 mt-0 px-0"> Total </v-card-text>
        </v-col>
        <v-col sm="6" class="ma-0 pa-0 text-right">
          <v-card-text v-if="job.resale_offer" class="headline px-0">
            {{
              "$" +
              (
                Number(job.bid_buy_out) -
                Number(job.resale_offer.replace(/,/g, ""))
              ).toFixed(2)
            }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    -->

    <!--
    <v-row v-if="confirm" class="d-flex flex-row-nowrap no-gutters px-2">
      <v-btn
        rounded
        block
        :disabled="!cod || !finalValid"
        color="primary"
        class="submit-btn"
        :loading="_loading"
        @click="confirmClose"
        >Confirm</v-btn
      >
    </v-row>
    -->
  </v-card>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  props: {
    finalValid: Boolean,
    confirm: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      _job: 'jobs/getCurrentJob',
      // _loading: "jobs/loading",
      _company: 'company/getCompany'
    }),
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    },
    total() {
      if (this.job.bid_per_pound === null) {
        return (
          Number(this.job.bid_buy_out) +
          Number(this.job.resale_offer.replace(/,/g, ''))
        ).toFixed(2);
      } else {
        return this.job.resale_offer
          ? (
              Number(this.priceBeforeResale) +
              Number(this.job.resale_offer.replace(/,/g, ''))
            ).toFixed(2)
          : this.priceBeforeResale;
      }
    },
    cod() {
      let cod = this.job.attachments.filter(o => o.catagory === 'cod');
      return cod.length > 0 ? true : false;
    },
    priceBeforeResale() {
      let price = this.job.final_weight * this.job.bid_per_pound;
      return this.job.final_weight !== undefined ? price.toFixed(2) : 0.0;
    }
  },
  methods: {
    ...mapActions({
      _updateJob: 'jobs/updateJob',
      _sendEmailClosedJobInfo: 'jobs/sendEmailClosedJobInfo'
    }),
    ...mapMutations({
      _setJob: 'jobs/setJob'
    }),
    acceptOffer() {
      let job = {};
      job.status = 'closed';
      job.bid_status = 'closed';
      job.id = this.job.job_id;
      this._updateJob(job).then(() => {
        this._sendEmailClosedJobInfo(job.id);
        this.$router.push('/history');
      });
    },
    rejectOffer() {
      let job = {};
      job.status = 'dispute';
      job.bid_status = 'dispute';
      job.id = this.job.job_id;
      this._updateJob(job);
    },
    confirmClose() {
      this.$emit('confirm');
    },
    calculateSubtotal(
      bidPerPound,
      finalWeight,
      bidAllIn,
      additionalScrapProceeds,
      equipmentPurchaseOffer
    ) {
      // Initial Bid + Additional Scrap Proceeds + Equipment Purchase
      let initialBid = 0;
      let subTotal = 0;
      //debugger;
      if (bidPerPound) {
        if (finalWeight) {
          let price = Number(finalWeight) * Number(bidPerPound);
          initialBid = price.toFixed(2);
        }
      } else {
        if (bidAllIn) {
          bidAllIn = bidAllIn.replace(/,/g, '');
          initialBid = Number(bidAllIn).toFixed(2);
        }
      }

      subTotal = Number(subTotal) + Number(initialBid);
      if (additionalScrapProceeds) {
        additionalScrapProceeds = additionalScrapProceeds.replace(/,/g, '');
        subTotal = Number(subTotal) + Number(additionalScrapProceeds);
      }
      if (equipmentPurchaseOffer) {
        equipmentPurchaseOffer = equipmentPurchaseOffer.replace(/,/g, '');
        subTotal = Number(subTotal) + Number(equipmentPurchaseOffer);
      }
      // return the sum
      return subTotal.toFixed(2);
    },
    calculateTotal(totalVendorPurchaseOffer, custServiceCharge) {
      //debugger;
      let finalTotal = 0.0;

      if (totalVendorPurchaseOffer && custServiceCharge) {
        custServiceCharge = custServiceCharge.replace(/,/g, '');
        finalTotal =
          Number(totalVendorPurchaseOffer) - Number(custServiceCharge);
      } else {
        if (totalVendorPurchaseOffer && !custServiceCharge) {
          // finalTotal = Number(totalVendorPurchaseOffer) * -1;
          finalTotal = Number(totalVendorPurchaseOffer);
        } else if (!totalVendorPurchaseOffer && custServiceCharge) {
          custServiceCharge = custServiceCharge.replace(/,/g, '');
          finalTotal = Number(custServiceCharge);
        } else {
          // both null, do nothing
        }
      }
      // return the final total
      return finalTotal.toFixed(2);
    },
    numberWithCommas02(x) {
      if (x == null) {
        return '0.00';
      }

      // let v = (Math.round(x * 100) / 100).toFixed(2);
      // v = v.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      // return v;
      return Number(x.toString().replaceAll(',', '')).toLocaleString('en-US', {
        minimumFractionDigits: 2
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.textColorRed {
  color: #e53935;
}

.subtitle-1 {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
}
</style>
