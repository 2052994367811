<template>
	<v-card
		:width="
			$vuetify.breakpoint.mdAndUp ? 'calc(50% - 16px)' : 'calc(100% - 16px)'
		"
		class="mx-2 my-2 pa-4"
		justify="center"
		align="center"
		outlined
	>
		<v-row class="flex-wrap no-gutters">
			<v-col cols="12" sm="12" md="2">
				<v-sheet :color="color" height="100%" class="ma-1 pa-0">
					<v-container fluid fill-height>
						<v-icon dark x-large style="margin: 0 auto;">{{ icon }}</v-icon>
					</v-container>
				</v-sheet>
			</v-col>
			<v-col class="text-left" cols="12" sm="12" md="6"> </v-col>
			<v-col
				:class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'"
				cols="12"
				sm="12"
				md="4"
			>
				<v-card-subtitle class="text-no-wrap">
					{{ title }}
					<br />
					<span class="display-1 font-weight-bold">
						<ICountUp
							:delay="delay"
							:endVal="Number(number)"
							:options="options"
						/>
					</span>
				</v-card-subtitle>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
const gradients = [["#8cd65d", "rgba(140, 214, 93, 0.28)"]];
import ICountUp from "vue-countup-v2";

export default {
	props: {
		color: String,
		icon: String,
		title: String,
    number: Number,
		dollar: Boolean,
	},
	components: { ICountUp },
	data: () => ({
		delay: 1000,
		endVal: 120500,
		showLabels: true,
		lineWidth: 20,
		labelSize: 7,
		radius: 0,
		padding: 8,
		lineCap: "round",
		gradient: gradients[0],
		labels: ["March", "April", "May", "June", "July"],
		value: [200, 675, 410, 390, 310],
		gradientDirection: "top",
		gradients,
		fill: false,
		type: "bar",
		autoLineWidth: false,
	}),
	computed: {
		options() {
			return {
				useEasing: true,
				useGrouping: true,
				separator: ",",
				decimal: ".",
				prefix: this.dollar ? "$" : "",
				suffix: "",
				decimalPlaces: this.dollar ? 2 : 0,
			};
		},
	},
};
</script>
