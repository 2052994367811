<template>
  <v-dialog
    persistent
    @click:outside="clickOutside"
    v-model="isShow"
    max-width="420"
  >
    <v-card class="pb-2 wrapper">
      <v-card-title class="dialog-header mb-3">
        <div class="image-container">
          <img
            :src="require('@/assets/warning.png')"
            alt="Warning"
            class="warning-icon"
          />
        </div>
        <div class="close-btn-container">
          <v-btn class="close-btn" text @click="closePopup">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="dialog-content">
        <h2 class="title">{{ title }}</h2>
        <div v-html="message" class="content  mt-6"></div>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <v-btn
          :color="color"
          @click="handleConfirm"
          :loading="isLoadingConfirm"
          class="mr-3"
        >
          {{ titleButtonConfirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  props: {
    isShow: Boolean,
    title: String,
    message: String,
    confirm: Function,
    clickOutside: Function,
    closePopup: Function,
    color: String,
    titleButtonConfirm: String
  },
  data: function() {
    return {
      isLoadingConfirm: false
    };
  },
  watch: {
    isShow(newVal, oldVal) {
      this.isShowPopup = newVal;
    }
  },
  computed: {
    ...mapGetters({
      _jobLoading: 'jobs/loading'
    })
  },
  methods: {
    ...mapMutations({
      _setActiveWarningDialog: 'jobs/setShowWarningUnsavedChange',
      _setActiveEditForm: 'jobs/setEditNewJob',
      _setJob: 'jobs/setCurrentJob'
    }),
    ...mapActions({
      _close: 'jobs/updateJob'
    }),
    handleConfirm() {
      this.isLoadingConfirm = true;
      this.confirm();
    }
  }
};
</script>

<style scoped>
.wrapper {
  text-align: center;
  border: solid 1px transparent;
}

.dialog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.dialog-content {
  text-align: center;
  white-space: pre-wrap;
}

.title {
  color: #0091ff;
  text-transform: uppercase;
}

.dialog-actions {
  justify-content: flex-end;
}
.close-btn-container {
  /* display: flex;
  justify-content: flex-end; */

  position: absolute;
  right: 0;
  /* top: 2; */
}

.image-container {
  margin: auto;
  flex-grow: 1;
  text-align: center;
}

.warning-icon {
  max-width: 100%; /* Đảm bảo ảnh phản hồi */
  height: auto;
  display: inline-block; /* Để áp dụng text-align từ container */
}
.close-btn-container {
  flex-grow: 0;
}
</style>
