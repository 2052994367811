import {
  sendGridMail
} from "../../api";
const actions = {
  sendMail: async ({ commit }, data) => {
    try {
      // 
    } catch (error) {
      // 
    }
  },
  // sendGrid: async ({ commit }, data) => {
  //   try {
  //     // 
  //   } catch (error) {
  //     // 
  //   }
  // },
  sendGridMail: async ({ commit }, payload) => {
    console.log("sendGridMail call",payload);
    commit("toggleLoading", true);
    return new Promise((resolve, reject) => {
      sendGridMail(payload.type, payload)
        .then((res) => {
          commit("notification/setNotification", true, { root: true });
          commit("notification/setNotificationMsg", res.data.message, {
            root: true,
          });
          commit("notification/setNotificationType", "success", { root: true });
          commit("toggleLoading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("notification/setNotification", true, { root: true });
          commit("notification/setNotificationMsg", err.response.data.message, {
            root: true,
          });
          commit("notification/setNotificationType", "error", { root: true });
          commit("toggleLoading", false);
          reject(err);
        });
    });
  },
}
export default {
  namespaced: true,
	actions,
};
