import axios from 'axios';
import VueCookies from 'vue-cookies';
import Vue from 'vue';
import router from '@/router';
import store from '@/_store';
Vue.use(VueCookies);
var base = 'https://d37i0b8bpp3i2i.cloudfront.net'; //prod
//var base ='https://d34qdveikxo0sw.cloudfront.net'; //vndev
//  process.env.VUE_APP_API_BASE_URL || 
//var base = 'https://d34w6ueioybsv8.cloudfront.net'; //staging

const api = axios.create({
  baseURL: base,
  headers: {
    'x-api-key': process.env.VUE_APP_API_KEY
  }
});
api.interceptors.request.use(
  config => {
    config.headers.authorization = VueCookies.isKey('token')
      ? VueCookies.get('token')
      : null;

    /*
       let url=(location.pathname+location.search).substr(1);
       if(url.substr(0,3)=="job"||url.substr(0,7)=="history"||url.substr(0,9)=="dashboard")
       {
         $.LoadingOverlay("show"); //huy add show spinner when ajax call for 3 pages: job, history, dashboard
       }
      */
    //debugger;
    return config;
  },
  error => {
    if (error.response.status === 401) {
      // store.dispatch("logOut");
      router.push('/?401');
    }
    $.LoadingOverlay('hide'); //huy add hide spinner
    return error;
  }
);

api.interceptors.response.use(
  response => {
    $.LoadingOverlay('hide'); //huy add hide spinner when response completed
    return response;
  },
  error => {
    //  console.log("401 log",error.response.status === 401);
    if (error.response.status === 401) {
      // store.dispatch("logOut");
      router.push('/?401');
    }
    $.LoadingOverlay('hide'); //huy add hide spinner
    return error;
    // return Promise.reject(error);
  }
);

export default api;
