<template>
  <v-dialog persistent @click:outside="close" v-model="visible" max-width="350">
    <v-card class="pb-4 wrapper">
      <v-card-title class="justify-center">
        <div>
          <img :src="require('@/assets/warning.png')" />
        </div>
      </v-card-title>
      <v-card class="pa-1" flat>
        <h2 class="primary--text">
          {{ create ? 'Create New User Failed' : 'User Edit Failed' }}
        </h2>
        <div class="content">
          <v-card-text class="px-9 py-2 text-left">
            This email is already connected to another account.
          </v-card-text>
        </div>
      </v-card>
      <v-card-actions flat>
        <v-row no-gutters class="mt-4 ma-4">
          <v-btn color="secondary" class="btn-discard ml-auto" @click="close">
            Close
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    create: Boolean
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.wrapper {
  text-align: center;
  border-style: solid;
  border-color: #ffff;
}

.btn-discard {
  color: #2e384e;
  border: solid 1px #2e384e;
}

.btn-save-changes {
  color: white !important;
  background-color: #2e384e !important;
}

.close-btn-container {
  position: absolute;
  right: 0;
  flex-grow: 0;
}
</style>
