<template>
  <div>
    <div class="mx-0">
      <v-card-title class="ma-0 pa-0" v-if="$vuetify.breakpoint.smAndDown">
        New Job Review
      </v-card-title>
      <div class="flex-container">
        <v-card-subtitle class="primary--text no-padding card-subtitle-review">
          Job Overview
        </v-card-subtitle>
        <v-spacer />
        <v-btn text x-small color="primary" @click="$emit('step', 1)">
          <v-icon size="15">mdi-pencil</v-icon>
          Edit
        </v-btn>
      </div>
      <v-card-subtitle class="no-padding pa-0 under-line">
        <span :class="{ 'error-title': !newJob.name }">
          Job Name*
        </span>
        <!-- Job Name -->
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ newJob.name ? newJob.name : '' }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <!-- Job Number -->
        Internal Job ID
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ newJob.number ? newJob.number : 'none' }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Description
      </v-card-subtitle>
      <!-- <v-card-text class="no-padding pa-0">{{
				newJob.description ? newJob.description : "none"  
			}}</v-card-text> -->
      <v-card-text class="no-padding pa-0">
        <RenderTextArea :textAreaData="newJob.description || 'none'"></RenderTextArea>
      </v-card-text>

      <!-- <div class="flex-container top-padding">
				<v-card-subtitle class="primary--text no-padding under-line">Job Readiness</v-card-subtitle>
				<v-spacer />
				<v-btn text x-small color="primary" @click="$emit('step', 1)">
					<v-icon size="15">mdi-pencil</v-icon>Edit
				</v-btn>
			</div> -->
      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !newJob.logistics }">
          Logistics*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ getReviewValueByValue(config.LOGISTICS_OPTIONS, newJob.logistics) }}
      </v-card-text>

      <!-- <v-card-subtitle class="no-padding pt-2 pb-0 under-line">Vendor Restrictions</v-card-subtitle>
			<v-card-text class="no-padding pa-0">{{
				newJob.vendor_restrictions
			}}</v-card-text> -->
      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !bidDueDateIsValid }">
          Bid Due Date*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        <v-icon>mdi-calendar-blank</v-icon>
        {{ newJob.start_date && formatDate(newJob.start_date) }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{
          'error-title': !equipmentRemovalDateIsValid
        }">
          Equipment Removal Date*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        <span>
          {{
            getReviewValueByValue(
              config.EXACT_REMOVE_DATE_OPTIONS,
              newJob.exact_removal_date
            )
          }}
        </span>
      </v-card-text>
      <v-card-text class="no-padding pa-0">
        <v-icon>mdi-calendar-blank</v-icon>
        {{ newJob.end_date && formatDate(newJob.end_date) }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !newJob.project_manager_id }">
          Project Manager Email*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ getUserEmailById(newJob.project_manager_id) }}
      </v-card-text>

      <div class="flex-container top-padding">
        <v-card-subtitle class="primary--text no-padding card-subtitle-review">
          Equipment
        </v-card-subtitle>
        <v-spacer />
        <v-btn text x-small color="primary" @click="$emit('step', 2)">
          <v-icon size="15">mdi-pencil</v-icon>
          Edit
        </v-btn>
      </div>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Resale?*
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ newJob.rec_spec && newJob.rec_spec.resale === true ? 'Yes' : 'No' }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Certified Recycling Required?*
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{
          newJob.rec_spec && newJob.rec_spec.certified_recycle === true
            ? 'Yes'
            : 'No'
        }}
      </v-card-text>

      <v-card-subtitle class="no-padding pa-0 pt-2 under-line">
        Number of Pallets
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ newJob.number_of_pallets }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Estimated Weight (lbs.)
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">{{ newJob.est_weight }}</v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{
          'error-title': !checkAssetTypeRequired(newJob.asset_type)
        }">
          Asset Type*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ getAssetTypeReview(newJob.asset_type) }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{
          'error-title':
            !newJob.attachments ||
            getFilesByCategory(newJob.attachments, config.KEY.Equipment_List)
              .length == 0
        }">
          Uploaded Documents*
        </span>
      </v-card-subtitle>
      <v-card max-height="200" style="overflow-x:auto;" v-if="newJob.attachments" flat>
        <v-list-item v-for="(attachment, index) in newJob.attachments" :key="index"
          v-show="attachment.catagory !== 'commodity_report_template'">
          <v-list-item-icon>
            <v-icon color="secondary" v-if="attachment.location">
              mdi-paperclip
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ attachment.original_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ attachment.catagory.replace(/_/g, ' ')
              }}{{
                attachment.catagory === config.KEY.Equipment_List ? '*' : ''
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row no-gutters>
              <v-btn icon :href="attachment.location" :download="attachment.original_name">
                <v-icon color="secondary">mdi-download</v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-card>

      <div class="flex-container top-padding">
        <v-card-subtitle class="primary--text no-padding card-subtitle-review">
          Location
        </v-card-subtitle>
        <v-spacer />
        <v-btn text x-small color="primary" @click="$emit('step', 3)">
          <v-icon size="15">mdi-pencil</v-icon>
          Edit
        </v-btn>
      </div>
      <v-card-subtitle class="no-padding pa-0 under-line">
        <span :class="{ 'error-title': !siteName }">Site Name*</span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">{{ siteName }}</v-card-text>

      <!-- <div class="flex-container top-padding">
				<v-card-subtitle class="primary--text no-padding under-line">Vehicle Access</v-card-subtitle>
				<v-spacer />
				<v-btn text x-small color="primary" @click="$emit('step', 4)">
					<v-icon size="15">mdi-pencil</v-icon>Edit
				</v-btn>
			</div> -->

      <!-- <div class="flex-container top-padding">
				<v-card-subtitle class="primary--text no-padding under-line">Point of Contact</v-card-subtitle>
				<v-spacer />
				<v-btn text x-small color="primary" @click="$emit('step', 3)">
					<v-icon size="15">mdi-pencil</v-icon>Edit
				</v-btn>
			</div> -->
      <!-- <v-card-subtitle class="no-padding pa-0 under-line">POC User Name</v-card-subtitle>
			<v-card-text class="no-padding pa-0">{{
				poc ? poc.user_name : undefined
			}}</v-card-text>
			<v-card-subtitle class="no-padding pt-2 pb-0 under-line">Company</v-card-subtitle>
			<v-card-text class="no-padding pa-0">{{
				_company ? _company.name : undefined
			}}</v-card-text>
			<v-card-subtitle class="no-padding pt-2 pb-0 under-line">Phone</v-card-subtitle> 
			<v-card-subtitle class="no-padding pt-2 pb-0 under-line">Alt. Phone</v-card-subtitle> 
			<v-card-text class="no-padding pa-0">{{ !poc ? "" : poc.alt_phone || "N/A" }}</v-card-text> -->
      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        <span :class="{ 'error-title': !poc }">
          Logistics POC Email*
        </span>
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ poc ? poc.email : undefined }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pb-0 under-line">
        Lift Gate Required?
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ newJob.lift_gate ? 'Yes' : 'No' }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pa-0 under-line">
        Vehicle Asset Restrictions
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ newJob.vehicle_restrictions }}
      </v-card-text>

      <v-card-subtitle class="no-padding pt-2 pa-0 under-line">
        Vendor Restrictions
      </v-card-subtitle>
      <v-card-text class="no-padding pa-0">
        {{ newJob.vendor_restrictions }}
      </v-card-text>
      <!-- <div class="flex-container top-padding">
				<v-card-subtitle class="primary--text no-padding review-title">Recycling Specifications</v-card-subtitle>
				<v-spacer />
				<v-btn text x-small color="primary" @click="$emit('step', 1)">
					<v-icon size="15">mdi-pencil</v-icon>Edit
				</v-btn>
			</div> -->
      <div>
        <div class="flex-container top-padding" v-if="newJob.commodity_report || !newJob.send_mail_to_vendors">
          <v-card-subtitle class="primary--text no-padding card-subtitle-review">
            Uncommon Request
          </v-card-subtitle>
          <v-spacer />
          <!-- <v-btn text x-small color="primary" @click="$emit('step', 3)">
            <v-icon size="15">mdi-pencil</v-icon>
            Edit
          </v-btn> -->
        </div>

        <div v-if="newJob.commodity_report">
          <v-card-subtitle class="no-padding pt-2 pb-0 review-title under-line">
            Commodity Report?
          </v-card-subtitle>
          <v-card-text class="no-padding pa-0">
            {{ newJob.commodity_report ? 'Yes' : 'No' }}
          </v-card-text>
        </div>

        <v-card max-height="200" style="overflow-x:auto;" v-if="newJob.attachments" flat>
          <v-list-item v-for="(attachment, index) in newJob.attachments" :key="index"
            v-show="attachment.catagory === 'commodity_report_template'">
            <v-list-item-icon>
              <v-icon color="secondary" v-if="attachment.location">
                mdi-paperclip
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ attachment.original_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ attachment.catagory.replace(/_/g, ' ') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row no-gutters>
                <v-btn icon :href="attachment.location" :download="attachment.original_name">
                  <v-icon color="secondary">mdi-download</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-card>

        <v-card flat>
          <a :style="{
            color: this.isValidHttpsUrl(newJob.commodity_report_link)
              ? 'green'
              : 'red',
            marginLeft: '70px'
          }" target="_blank" :href="newJob.commodity_report_link" v-on="on">
            {{
              this.isValidHttpsUrl(newJob.commodity_report_link)
                ? newJob.commodity_report_link
                : 'Invalid URL'
            }}
          </a>
        </v-card>

        <!-- <v-card-subtitle class="no-padding pt-2 pb-0 ">Alert vendors when this job is open for bid</v-card-subtitle> -->
        <div v-if="!newJob.send_mail_to_vendors">
          <v-card-subtitle class="no-padding pt-2 pb-0 review-title under-line">
            Alert Vendor?
          </v-card-subtitle>
          <v-card-text class="no-padding pa-0">
            {{ newJob.send_mail_to_vendors ? 'Yes' : 'No' }}
          </v-card-text>
        </div>
        <div v-if="newJob.vendor_codes">
          <v-card-subtitle class="no-padding pt-2 pb-0 review-title under-line">
            <span :class="[{ 'error-title': newJob.vendor_codes.length == 0 }]">Vendor
              Codes*</span>
          </v-card-subtitle>
          <div>
            <tr v-for="vendor in newJob.vendorsAssignedInfo" :key="vendor.id" class="table-row-spacing">
              <td style="text-align:right;padding-right: 5px;">{{ vendor.id }}</td>
              <td> - {{ vendor.name }} </td>
            </tr>
          </div>
        </div>
      </div>
      <terms-conditions class="pt-8" @accepted="accepted = !accepted" />
      <v-divider class="my-4" />
      <v-card flat>
        <v-row no-gutters class="mt-4 ma-3">
          <!-- <v-btn 
            rounded
            color="error"
            @click="$emit('cancel')"
          >
            Cancel
          </v-btn> -->
          <span class="error-title pointer mt-2" @click="$emit('cancel')">
            Delete
          </span>
          <v-spacer />
          <div>
            <v-btn v-if="!isHideBtnSaveDraft" rounded :loading="_jobLoading" color="#2e384e" height="35"
              class="white--text mr-3" @click="
                $emit('saveAsDraft');
              isHideBtnPostJob = true;
              ">
              Save Draft
            </v-btn>
            <v-btn v-if="!isHideBtnPostJob" rounded :disabled="!accepted || !enablePostJob" :loading="_jobLoading"
              color="secondary" height="35" class="white--text" @click="
                $emit('post');
              isHideBtnSaveDraft = true;
              ">
              Post Job
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </div>
    <v-progress-linear indeterminate color="green" v-if="loading"></v-progress-linear>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import TermsConditions from '@/components/app/Terms&Conditions.vue';
import { get } from '@/api';
import config from '@/config';
import * as Helpers from '@/helpers/helpers';
import RenderTextArea from '@/components/common/RenderTextArea.vue';

export default {
  props: {
    spec: Array,
    enablePostJob: Boolean
  },
  components: {
    TermsConditions,
    RenderTextArea
  },
  data() {
    return {
      siteName: undefined,
      user: undefined,
      loading: false,
      accepted: false,
      isHideBtnPostJob: false,
      isHideBtnSaveDraft: false,
      config: config,
      assetTypeLabelReview: config.ASSET_TYPE_LABEL_REVIEW
    };
  },
  mounted() {
    if (this.newJob.site_id && !this.siteName) {
      this.loading = true;
      get('/site', this.newJob.site_id).then(res => {
        this.siteName = res.data.data.name;
      });
    }
    if (this.newJob.poc_id && !this.user) {
      this.loading = true;
      get('/user', this.newJob.poc_id).then(res => {
        this.user = res.data.data;
        setTimeout(() => {
          this.loading = false;
        }, 5000);
      });
    }
  },
  methods: {
    ...Helpers,
    getUserName(id) {
      return id;
    },
    getUserEmailById(id) {
      const user = this._users.find(u => u.user_id === id);
      return user ? user.email : '';
    },
    getUserPhone(id) {
      return id;
    },
    getUserAltPhone(id) {
      return id;
    },
    submitJob() {
      return;
    },
    checkAssetTypeRequired(assetType) {
      if (assetType.other_checked && assetType.other.length === 0) {
        return false;
      }
      for (let key in assetType) {
        if (assetType[key] === true) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    newJob: {
      handler() {
        if (this.newJob.site_id && !this.siteName) {
          get('/site', this.newJob.site_id).then(res => {
            this.siteName = res.data.data.name;
          });
        }
        if (this.newJob.poc_id && !this.user) {
          get('/user', this.newJob.poc_id).then(res => {
            this.user = res.data.data;
          });
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      _jobLoading: 'jobs/loading',
      _newJob: 'jobs/getNewJob',
      _company: 'company/getCompany',
      _users: 'user/getUsers',
      _vendor: 'company/getVendor'
    }),
    poc() {
      return this.user;
    },
    newJob() {
      return this._newJob;
    },
    getSiteName() {
      return this.siteName;
    },
    bidDueDateIsValid() {
      return (
        !_.isNil(this._newJob.start_date) &&
        moment(this._newJob.start_date) >= moment()
      );
    },
    equipmentRemovalDateIsValid() {
      return (
        moment(this._newJob.start_date).isBefore(
          moment(this._newJob.end_date)
        ) && !!this._newJob.exact_removal_date
      );
    }
  }
};
</script>

<style>
.under-line {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.error-title {
  text-decoration: underline;
  color: #e53935;
}


.card-subtitle-review {
  color: #5d5d5d;
  /* font-size: 1.25em; */
  font-weight: bold;
  font-size: 17px;
  text-transform: uppercase;
}

.table-row-spacing td {
  padding-bottom: 8px;
  padding-right: 16px;
}

.table-row-spacing td tr {
  padding-right: 10px;
}
</style>
