import api from "../api";
export function sendGridMail(type,data) {
  console.log("call api sendGridMail: "+type,data );
  return api.post(`/media/email/sendgrid/${type}`, data);
}
export function sendMail(job) {
  return api.post(`/user/job`, job);
}

export function checkEmailExist(email) {
  return api.get(`/user/checkEmailExistsHandler?email=${email}`);
}
