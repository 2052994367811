/* eslint-disable no-unused-vars */
import { createCompany, createUser, confirmEmail, verifyCode, checkEmailExist } from '../../api';
import VueCookies from 'vue-cookies';
// initial customer state
const state = {
  newCompany: {
    zip: ''
  },
  newUser: {
    conf_code: ''
  },
  confId: undefined,
  loading: false,
  loadingSubmit: false,
  error: false,
  registered: false,
  emailExisted: false
};

// getters
const getters = {
  getHours: state => {
    return state.newCompany.hours || undefined;
  },
  getNewCompany: state => {
    return state.newCompany;
  },
  getNewUser: state => {
    return state.newUser;
  },
  getError: state => {
    return state.error;
  },
  getLoading: state => {
    return state.loading;
  },
  getLoadingSubmit: state => {
    return state.loadingSubmit;
  },
  getConfId: state => {
    return state.confId;
  },
  getEmailExisted: state => {
    return state.emailExisted;
  }
};

// actions
const actions = {
  confirmEmail: async ({ commit }) => {
    commit('toggleLoading', true);
    const confirm = {
      email: state.newUser.email,
      user_name: state.newUser.first_name + ' ' + state.newUser.last_name
    };
    try {
      const email = await confirmEmail(confirm);
      let token = email.data.data.token;
      await VueCookies.set('token', token);
      commit('setConfirmation', email.data.data.conf_id);
      commit('toggleLoading', false);
    } catch (err) {
      // console.log(err);
      commit('toggleLoading', false);
    }
  },
  checkIfEmailExisted: async ({ commit }) => {
    commit('toggleLoading', true);

    const userEmail = state.newUser.email ? encodeURIComponent(state.newUser.email) : '';

    try {
      const res = await checkEmailExist(userEmail);

      if (res && res.status === 200) {
        const emailExisted = res.data.data.emailExisted || false;
        commit('setEmailExisted', emailExisted);
        commit('toggleLoading', false);
        var response = {
          emailExisted: emailExisted,
          type_id: emailExisted ? res.data?.data?.type_id : null
        };
        return response;
      }
      commit('toggleLoading', false);
      return false;
    } catch (err) {
      // console.log(err);
      commit('toggleLoading', false);
    }
  },

  verifyConfirmationCode: async ({ commit }) => {
    commit('toggleLoadingSubmit', true);

    const code = {
      conf_id: state.confId,
      conf_code: state.newUser.conf_code
    };

    try {
      const res = await verifyCode(code);

      if (res && res.status === 200) {
        return true;
      }

      commit('toggleLoadingSubmit', false);
      return false;
    } catch (err) {
      commit('toggleLoadingSubmit', false);
      throw error;
    }
  },

  basicRegistration: async ({ state, commit, dispatch }, payload) => {
    commit('toggleLoadingSubmit', true);
    state.newUser.user_name = state.newUser.first_name + ' ' + state.newUser.last_name;
    state.newCompany.user = state.newUser;
    return new Promise((resolve, reject) => {
      createCompany(state.newCompany)
        .then(company => {
          const compId = company.data.data.id;
          const type = company.data.data.type_id;
          const newUser = state.newUser;
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', 'New Company Created', {
            root: true
          });
          commit('notification/setNotificationType', 'success', {
            root: true
          });
          newUser.company_id = compId;
          newUser.type_id = 1;
          createUser(newUser)
            .then(() => {
              const user = {
                email: newUser.email,
                password: newUser.password,
                route: type === 1
              };
              commit('notification/setNotification', true, {
                root: true
              });
              commit('notification/setNotificationMsg', 'New User Created', {
                root: true
              });
              commit('notification/setNotificationType', 'success', {
                root: true
              });
              dispatch('authenticate/authenticate', user, {
                root: true
              }).then(res => {
                commit('notification/setNotification', true, {
                  root: true
                });
                commit('notification/setNotificationMsg', 'Account Created Succesfully, Now Logged In', {
                  root: true
                });
                commit('notification/setNotificationType', 'success', {
                  root: true
                });
                commit('toggleLoadingSubmit', false);
                resolve(res);
              });
            })
            .catch(err => {
              dispatch('company/removeCompany', compId, { root: true });
              commit('setConfirmation', undefined);
              commit('notification/setNotification', true, {
                root: true
              });
              commit('notification/setNotificationMsg', 'User could not be created, duplicate email.', {
                root: true
              });
              commit('notification/setNotificationType', 'error', {
                root: true
              });
              commit('toggleLoadingSubmit', false);
              reject(err);
            });
        })
        .catch(() => {
          commit('notification/setNotification', true, { root: true });
          commit('notification/setNotificationMsg', 'Company not created successfully, please try again.', {
            root: true
          });
          commit('notification/setNotificationType', 'error', {
            root: true
          });
          commit('toggleLoadingSubmit', false);
        });
    });
  }
};

// mutations
const mutations = {
  toggleLoading: (state, data) => {
    state.loading = data;
  },
  toggleLoadingSubmit: (state, data) => {
    state.loadingSubmit = data;
  },
  setCompany: (state, data) => {
    state.newCompany = data;
  },
  setError: (state, err) => {
    state.error = err;
  },
  setUser: (state, data) => {
    state.newUser = data;
  },
  setConfirmation: (state, data) => {
    // console.log("id", data);
    state.confId = data;
  },
  setEmailExisted: (state, data) => {
    state.emailExisted = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
