<template>
  <div>
    <div v-if="_loading && !_jobs">
      <v-skeleton-loader
        ref="skeleton"
        :boilerplate="false"
        type="table"
        class="mx-0"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <div class="titleBar">
        <v-card-title
          v-html="`Closed Jobs`"
          style="font-size: 26px"
          class="save--text"
          v-if="$vuetify.breakpoint.smAndUp"
        ></v-card-title>

        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <v-text-field
          v-model="search"
          style="max-width: 300px; margin: 16px"
          label="Search"
          :append-icon="search ? `mdi-close` : `mdi-briefcase-search`"
          @keyup="throttledMethod()"
          @click:append="clearSearch()"
        />
        <v-btn
          v-if="_companyType == 3"
          style=" margin-top: 25px"
          color="secondary"
          class="mr-2 elevation-0 mb-2"
          :loading="_loading"
          dark
          @click="exportData()"
        >
          Export History
        </v-btn>
      </div>
      <v-slide-x-transition>
        <v-data-table
          height="calc(100vh - 350px)"
          v-if="_jobs"
          v-model="selected"
          :options.sync="options"
          :server-items-length="total"
          no-data-text="No jobs right now, create one!"
          :headers="headers"
          :items="_jobs"
          :loading="_loading"
          multi-sort
          @update:options="_getJobs(options)"
          @click:row="handleClick"
          @update:sort-by="handleSortBy"
          @update:sort-desc="handleSortDesc"
          @update:page="handleUpdatePage"
          @update:items-per-page="handleUpdateItemPage"
        >
          <template #[`item.saved`]="{ item }">
            <v-icon v-if="item.saved">mdi-star</v-icon>
          </template>
        </v-data-table>

        <v-banner v-else single-line transition="slide-y-transition">
          Sorry No Jobs Found
          <template v-slot:actions="{ dismiss }">
            <v-btn text color="primary" @click="dismiss">Dismiss</v-btn>
            <v-btn
              text
              color="primary"
              :loading="_loading"
              @click="throttledMethod()"
            >
              Retry
            </v-btn>
          </template>
        </v-banner>
      </v-slide-x-transition>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Options from '@/mixins/Options';
export default {
  mixins: [Options],
  props: {
    tableTitle: String
  },
  data() {
    return {
      search: undefined,
      pageSize: 0,
      pageItems: 0,
      page: 1,
      limit: 15,
      selected: null,
      OpenJobs: 'Open Jobs',
      filterStart: null,
      filterEnd: null,
      weightRange: [null, null],
      palletRange: [null, null],
      saved: 'All',
      specSearch: null,
      locationSearch: null,
      mileRange: [null, null]
    };
  },
  computed: {
    ...mapGetters({
      _jobs: 'jobs/getJobHistory',
      _loading: 'jobs/loading',
      _companyId: 'company/getCompanyId',
      _companyType: 'company/getCompanyType'
    }),
    total() {
      return this._jobs && this._jobs[0] ? this._jobs[0].total : 0;
    },
    headers() {
      if (this._jobs) {
        const keys = Object.keys(this._jobs[0]);
        var headers = [];
        keys.forEach(i => {
          if (i !== 'created_at' && i !== 'updated_at' && i !== 'total') {
            const obj = {
              text: i.replace(/_/g, ' ').toUpperCase(),
              value: i,
              sortable: true,
              filterable: true,
              divider: false,
              align: 'start',
              width: i === 'id' ? '75' : '200'
            };
            headers.push(obj);
          }
        });
        return headers;
      } else {
        return undefined;
      }
    },
    pagination() {
      return {
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10
      };
    },
    compId() {
      return this._companyId || 0;
    }
  },
  watch: {
    compId() {
      console.log('compId');
      this._getJobs(this.options);
    },
    tableTitle() {
      console.log('tableTitle');
      this._getJobs(this.options);
    }
  },
  mounted() {
    this.getData().then(() => {
      const temp = this.$route.query;
      const query = { ...temp };
      if (!query) {
        return;
      }
      if (query['pageSize']) {
        this.options.page = query['pageSize'] * 1;
        delete query['pageSize'];
      }
      if (query['pageItems']) {
        this.options.itemsPerPage = query['pageItems'] * 1;
        delete query['pageItems'];
      }
      this.options.sortBy = Object.keys(query);
      this.options.sortDesc = Object.values(query).map(item =>
        item === 'true' ? true : false
      );
    });
    this.throttledMethod = _.debounce(() => {
      this.getData();
    }, 1000);
  },
  methods: {
    ...mapActions({
      _getJobs: 'jobs/getHistory',
      _exportJobs: 'jobs/exportHistory'
    }),
    ...mapMutations({
      _setLoading: 'jobs/toggleLoading'
    }),
    getData() {
      console.log('getData');
      let options = this.options;
      options.search = this.search;
      //if(!this._loading)
      return this._getJobs(options);
    },
    exportData() {
      let _export = {};
      _export.export = true;
      let rs = this._exportJobs(_export);
      // console.log("_exportJobs",rs);
      // let csv=JSONToCSVConvertor(rs.data,'csv report',true,true);
      // console.log("csv",csv);
    },
    clearSearch() {
      this.search = undefined;
      this.getData();
    },
    handleClick(value) {
      const params = {};

      if (this.pageSize) {
        params['pageSize'] = this.pageSize;
      }

      if (this.pageItems) {
        params['pageItems'] = this.pageItems;
      }

      this.options.sortBy.forEach((item, index) => {
        params[item] = this.options.sortDesc[index];
      });

      this.$router.replace({ query: params }).catch(() => {});

      this.$router.push('/history/' + value.job_id);
    },
    handleSortBy(value) {
      //console.log("handleSortBy2",value);
      if (!this._loading) this.options.sortBy = value;
    },
    handleSortDesc(value) {
      if (!this._loading) this.options.sortDesc = value;
    },
    handleUpdatePage(value) {
      this.pageSize = value;
    },
    handleUpdateItemPage(value) {
      this.pageItems = value;
    }
  }
};
</script>

<style scoped>
.titleBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.highlighted-button {
  width: 112px;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
}
.gray-button {
  color: #5d5d5d;
  background-color: transparent !important;
  height: 16px;
  font-size: 14px;
}
.mobile-button {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #4caf50 !important;
  padding: 0px 20px !important;
  margin: 5px;
  color: #ffffff !important;
}
</style>
