<template>
  <div>
    <v-card class="" flat>
      <v-row
        v-if="!(_job.status === 'rejected' && _job.bid_status === 'rejected')"
      >
        <v-col cols="auto" class="mr-auto">
          <!-- Vu commented out
          <v-card-title
            >{{
              _job.recycle_specs.resale === true ? "Purchase" : "Closing"
            }}
            Offer</v-card-title
          > -->
          <v-card-title>Awarded Job</v-card-title>
          <v-card-subtitle class="mx-0">
            Enter the job final details below. When you are ready to finalize
            the transaction, click the Close Job button.
          </v-card-subtitle>
        </v-col>
        <!--
        <v-col cols="auto" class="mr-2">
          <v-btn
            rounded
            elevation="0"
            color="secondary"
            @click="openCloseOutDialog()"
            >Close Job</v-btn
          >
        </v-col> -->
      </v-row>

      <v-row class="d-flex flex-column-wrap no-gutters">
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="ma-0 pa-0"
          :class="$vuetify.breakpoint.mdAndUp ? 'order-0' : 'order-0'"
        >
          <job-closing-details />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="6"
          class="ma-0 pa-0"
          :class="$vuetify.breakpoint.mdAndUp ? 'order-1' : 'order-1'"
        >
          <job-docs />
        </v-col>
      </v-row>
      <v-row class="d-flex flex-column-wrap no-gutters">
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="ma-0 pa-0"
          :class="$vuetify.breakpoint.mdAndUp ? 'order-0' : 'order-0'"
        >
          <job-closing-final-details @finalValid="finalValid = $event" />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="6"
          class="ma-0 pa-0"
          :class="$vuetify.breakpoint.mdAndUp ? 'order-1' : 'order-1'"
        >
          <job-closing-total
            :finalValid="finalValid"
            @confirm="confirmClose()"
          />
        </v-col>

        <!-- Google Map
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ma-0 pa-0"
          :class="$vuetify.breakpoint.mdAndUp ? 'order-1' : 'order-1'"
        >
          <google-maps :bidAddress="bidAddress" :jobAddress="jobAddress" />
        </v-col> -->
      </v-row>

      <!-- BEGIN Updated for RGX-160 : huy moved btnCloseJob-->
      <v-row class="d-flex flex-column-wrap no-gutters">
        <v-col cols="auto" class="pa-2">
          <v-btn
            id="btnCloseJob"
            v-if="false"
            rounded
            elevation="0"
            color="secondary"
            @click="openCloseOutDialog()"
          >
            Close Job
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ma-0 pa-0"
          :class="$vuetify.breakpoint.mdAndUp ? 'order-1' : 'order-1'"
        >
          <google-maps :bidAddress="bidAddress" :jobAddress="jobAddress" />
        </v-col>
      </v-row>
      <!-- END Update for RGX-160 -->
    </v-card>

    <job-close-out-dialog @confirm="confirmClose()" />
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import JobDocs from '@/components/jobs/JobDocs.vue';
import JobClosingDetails from '@/components/jobs/JobClosingDetails.vue';
import JobClosingFinalDetails from '@/components/jobs/JobClosingFinalDetails.vue';
import JobCloseOutDialog from '@/components/jobs/JobCloseOutDialog.vue';
// import JobClosingTotal from "@/components/jobs/JobClosingTotal.vue";
import JobClosingTotal from '@/components/jobs/VendorJobClosingTotal.vue';
import GoogleMaps from '@/components/app/GoogleMap.vue';

export default {
  components: {
    JobClosingDetails,
    JobClosingFinalDetails,
    JobClosingTotal,
    JobDocs,
    GoogleMaps,
    JobCloseOutDialog
  },
  props: {},
  data() {
    return {
      offer: 0,
      offerValue: 0,
      finalValid: false
    };
  },
  computed: {
    ...mapGetters({
      _file: 'uploads/getFile',
      _job: 'jobs/getCurrentJob',
      _uploading: 'uploads/loading',
      _bid: 'bids/getCurrentBidInfo'
    }),
    job: {
      set(val) {
        this._setJob(val);
      },
      get() {
        return this._job;
      }
    },
    bidAddress() {
      if (this._bid) {
        if (this._bid.site_name) {
          return (
            this._bid.site_street +
            (this._bid.site_suite
              ? ', Suite ' + this._bid.site_suite + ', '
              : '') +
            ' ' +
            this._bid.site_city +
            ', ' +
            this._bid.site_state +
            ' ' +
            this._bid.site_zip
          );
        } else {
          return (
            this._bid.street +
            (this._bid.suite ? ', Suite ' + this._bid.suite + ', ' : '') +
            ' ' +
            this._bid.city +
            ', ' +
            this._bid.state +
            ' ' +
            this._bid.zip
          );
        }
      }
      // return empty bid address
      return '';
    },
    jobAddress() {
      if (this.job) {
        return (
          this.job.street +
          (this.job.site_suite ? ', Suite ' + this.job.site_suite + ', ' : '') +
          ' ' +
          this.job.city +
          ', ' +
          this.job.state +
          ' ' +
          this.job.zip
        );
      } else {
        return '';
      }
    }
  },
  methods: {
    ...mapMutations({
      _setClosingConf: 'jobs/setClosingConf',
      _setJob: 'jobs/setJob'
      //   _setShowCloseOutDialog: "jobs/setShowCloseOutDialog",//160: moved -- huy
    }),
    ...mapActions({
      _setBid: 'bids/getCurrentInfo',
      _uploadFile: 'uploads/uploadFile',
      _updateJob: 'jobs/updateJob'
    }),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return moment(date).format('MM/DD/YYYY');
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    confirmClose() {
      this._setClosingConf(true);
    }
    // openCloseOutDialog() {
    //   this._setShowCloseOutDialog(true);//160: huy moved
    // },
  },
  watch: {
    offer() {
      if (this.offer[0] !== '$') {
      }
      this.offerValue = this.offer.match(/[0-9]+/g).join(['']);
    }
  },
  mounted() {
    console.log('mounted this.job', this.job);
    if (this.job && this.job.bid_id) this._setBid(this.job.bid_id);
  }
};
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.box-one {
  grid-row: 1 / span 3;
  grid-column: 1;
}

.box-two-resale {
  grid-row: 1 / span 5;
  grid-column: 2;
}

.box-two {
  grid-row: 1 / span 3;
  grid-column: 2;
}

.box-three {
  grid-row: 4 / span 6;
  grid-column: 1;
}

.job-name {
  grid-row: 1;
  grid-column: 1 / span 3;
}

.pickup-location {
  grid-row: 1;
  grid-column: 4;
}

.bid-id {
  grid-row: 2;
  grid-column: 1;
}

.customer-name {
  grid-row: 2;
  grid-column: 2;
}

.estimated-weight {
  grid-row: 2;
  grid-column: 3;
}

.bid-per-pound {
  grid-row: 2;
  grid-column: 4;
}

.num-font {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #2e2e2e !important;
}

.resale-font {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #2e2e2e;
}

.green-font {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #4caf50;
}

.red-font {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  color: #e53935;
}

.submit-btn {
  justify-self: end;
}

.subtitle-font {
  font-family: Roboto;
  font-size: 17px;
  color: #2e2e2e;
}

.text-font {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #5d5d5d;
}

.border-bottom {
  margin: 0 0;
  border-bottom: 1px solid #d1d1d1;
}

.text-right {
  text-align: right;
}
</style>
